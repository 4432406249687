import { Pipe, PipeTransform } from "@angular/core";
import { DiscountType } from "../shop.modals";
import { Pricing } from "../../retail-code-setup/retail-discount-types/discount-types.model";


@Pipe({
    name: 'discountPipe',
    pure: false
})
export class discountPipe implements PipeTransform {
    transform(items: DiscountType[], isPercentage: boolean , isTicketDiscount:boolean): any[] {
        if (!items) {
            return items;
        }
        if(isTicketDiscount) {
           return items.filter(discount => discount.isPercentage == isPercentage && ( (discount.pricing == Pricing.Fixed && discount.amount > 0) || discount.pricing == Pricing.Open )) ;
        }
        return items.filter(discount => discount.isPercentage == isPercentage);
    }
}

