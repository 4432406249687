import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { HttpServiceCall, HttpMethod, KeyValuePair } from 'src/app/shared/service/http-call.service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { SPAConfig } from 'src/app/core/config/SPA-config';
import { AppointmentUtilities } from 'src/app/shared/utilities/appointment-utilities';
import { AppointmentpopupService } from 'src/app/shared/service/appointmentpopup.service';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { AppointmentBusiness } from 'src/app/shared/business/appointment-break-business';
import { appointmentService } from 'src/app/shared/service/appointment.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { BaseResponse } from 'src/app/shared/business/shared.modals';
import { AlertMessagePopupComponent } from 'src/app/shared/alert-message-popup/alert-message-popup.component';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { ImageProcessorService } from 'src/app/shared/service/image-processor-service';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { SlideInformationService } from 'src/app/shared/slide-information/slide-information.service';
import { WaitListService } from 'src/app/shared/addwaitlist-popup/waitlist-popup.service';
import { PMSSessionData } from 'src/app/core/core.model';
import { GraphService } from 'src/app/shared/service/graph.service';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { takeUntil } from 'rxjs/operators';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { TableOptions } from 'src/app/common/Models/ag-models';
import { SpaWizardService } from 'src/app/appointment/spa-wizard/spa-wizard.service';

@Component({
  selector: 'app-link-appointments',
  templateUrl: './link-appointments.component.html',
  styleUrls: ['./link-appointments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkAppointmentsComponent implements OnInit,OnDestroy {
  AppointmentStatus: any = [];
  @Output() popupclosed: EventEmitter<any> = new EventEmitter();
  @Output() linkedAppointmentsData: EventEmitter<any> = new EventEmitter();
  @Input() GridRefresh: boolean;
  @Input() ClientIds: any;
  @Input() set bindLinkingAppointments(value) {
    this.tableoptionsLinked = { ...this.tableoptionsLinked };
    this.tableoptionsUnLinked = { ...this.tableoptionsUnLinked };
    if (this.tableoptionsUnLinked != null && this.tableoptionsUnLinked.length > 0) {
      this.tableoptionsUnLinked.forEach(itemLoc => {
        if (itemLoc.locationId == 0) {
          itemLoc.location = this.localization.captions.setting.Offsite;
        }
      });
    }
  }
  existingClientIds = [];
  placeholderFormat: string;
  commonCaptions: any;
  nonSetting: boolean = false;
  tableMaxHeight = 400;
  headerOptions = [];
  tableContent = [];
  options: TableOptions;

  selectedstatusArr: any = [];
  FormGrp: UntypedFormGroup;
  defaultFromDt: Date;
  defaultToDt: Date;
  minToDate: Date;
  minFromDate: Date;
  maxTodate: Date;
  maxFromDate: Date;
  apidata: any;
  selectedId: any = 0;
  selectedValue: any = "0";
  selectedType: any = "0";
  gridData: any[] = [];
  gridDataTemp: any[] = [];
  selectedService: any = "All";
  selectedPackage: any = "All";
  selectedStaff: any = "All";
  selectedLocation: any = "All";
  selectedStatus: any = "All";
  appointmentInfo: any;
  spaservices: any = [];
  spaStatus: any[] = [];
  packages: any = [];
  staffs: any = [];
  locations: any = [];
  status: any = [];
  captions: any;
  gridheader: any[];
  captionsBookApp: any;
  action: any;
  EditData: any;
  cancelReason: any = 0;
  cancelAppointments: any = [];
  cancelReasons: any = [];
  cancel: boolean = false;
  SearchText: any = '';
  RISstatus: { data: { appointmentId: number; }[]; status: string; };
  popupsOpened: number = 0;
  notInitiatable: any = [];
  successfullyInitiated: any = [];
  fromStatus: string;
  isTrackNoShow: boolean;
  reinstateMultipackOpted: boolean;
  reinstateApptObj: any;
  reinstateAllObj: any;
  clientMultiPacks: any;
  availableMultipacks: any;
  isReinstateAll: boolean;
  autoCompleteSubscription: Subscription;
  actionDialogSubscription: Subscription;
  reinstateSubscription: Subscription;
  changeStatusSubscribe: Subscription;
  appDialogSubscription: Subscription;
  alertSubscription: Subscription;
  disableReinstateFlag: boolean = false;
  requestUid = '';
  timer = null;
  propertyName: string;
  propertyDate: string;
  appointmentId: any;
  pmsData: PMSSessionData;
  isSearchDisabled: boolean;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  tableoptionsLinked: any;
  tableoptionsUnLinked: any;
  linkedAppointments: any = [];
  unLinkedAppointments: any = [];
  selectedUnLinkedAppointments: any = [];
  selectedLinkedAppointments: any = [];
  checkAllLinkedAppointments = true;
  checkSameClientLinkedAppointments = true;
  interval = null;
  floatLabel:string;
  floatLabelNever:string;

  constructor(private Form: UntypedFormBuilder, private http: HttpServiceCall, private dialog: MatDialog, private utils: SpaUtilities, private spaconfig: SPAConfig, private imgService: ImageProcessorService,
    private appointmentUtils: AppointmentUtilities, private appointmentPopupService: AppointmentpopupService, public SlideService: SlideInformationService, public appointmentbusiness: AppointmentBusiness, public _appointmentService: appointmentService,
    public localization: SpaLocalization, private breakPoint: BreakPointAccess, private PropertyInfo: SpaPropertyInformation, private waitListService: WaitListService, private graphService: GraphService
    , private _retailPropertyInfoService: RetailPropertyInformation, private _quickLoginUtil: QuickLoginUtilities,
    public spaWizardService: SpaWizardService) {
    this.placeholderFormat = this.localization.inputDateFormat;
    this.commonCaptions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }
  searchFilter: any = [];
  filteredOptions: Observable<string[]>;
  autocomplete = new UntypedFormControl();
  isStatusDisabled: boolean;

  ngOnInit() {
    this.nonSetting = false;
    this.captions = this.localization.captions.appointmentSearch;
    this.captionsBookApp = this.localization.captions.bookAppointment;

    this.spaStatus = [
      { value: GlobalConst.AppointmentStatus.Canceled, text: this.captions.Cancelled },
      { value: GlobalConst.AppointmentStatus.CheckIn, text: this.captions.CheckedIn },
      { value: GlobalConst.AppointmentStatus.CheckOut, text: this.captions.CheckedOut },
      { value: GlobalConst.AppointmentStatus.NoShow, text: this.captions.NoShow },
      { value: GlobalConst.AppointmentStatus.Scheduled, text: this.captions.Scheduled }];

    this.AppointmentStatus = [{ "id": 0, "name": this.captions.All, "code": "ALL" }, { "id": 1, "name": this.captions.Scheduled, "code": "RESV" }, { "id": 2, "name": this.captions.CheckedIn, "code": "CKIN" }, { "id": 3, "name": this.captions.CheckedOut, "code": "CKOUT" },
    { "id": 4, "name": this.captions.Cancelled, "code": "CANC" }, { "id": 5, "name": this.captions.NoShow, "code": "NOSHOW" }];

    this.RefreshGrid();

    this.defaultFromDt = this.PropertyInfo.CurrentDate;
    this.defaultToDt = this.PropertyInfo.CurrentDate;

    this.FormGrp = this.Form.group({
      FromTime: this.defaultFromDt,
      EndTime: this.defaultToDt,
      searchtext: '',
      services: '',
      package: '',
      staff: '',
      location: '',
      status: ''
    });
    this.LoadServices();
    this.LoadLocations();
    this.staffs = this._appointmentService.allTherapistDetail;
    //this.LoadTherapists();
    this.LoadPackages();
    this.populateTableInfo();

    this.autoCompleteSubscription = this.autocomplete.valueChanges
      .subscribe(searchData => {
        let canInvokeSearch = false;

        if (!isNaN(searchData)) {
          canInvokeSearch = true;
        }
        else if (searchData.length > 2) {
          canInvokeSearch = true;
        }
        this.requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
        if (canInvokeSearch) {
          if (this.timer) {
            clearTimeout(this.timer); //cancel the previous timer.
            this.timer = null;
          }
          this.timer = setTimeout(this.makeSearchCall.bind(this), 1000, searchData);
        }
      });
    this.options = {
      defaultsortingColoumnKey: 'clientName',
      defaultSortOrder: 'asc',
      showTotalRecords: false,
      columnFreeze: {
        firstColumn: true,
        lastColumn: false
      }
    };
    this.LoadGrid();
    this.LoadSearchResults();
  }
  async LoadGrid() {

    const tempLinkedAppt = this.spaWizardService.groupAppointments && this.spaWizardService.groupAppointments.length > 0 ?
      this.spaWizardService.groupAppointments
        .filter(x => x.appointment.appointmentDetail.linkedAppointmentID)
        .map(x => x.appointment.appointmentDetail.linkedAppointmentID) : [];
    const linkedAppointmentIds = this.appointmentPopupService.linkedAppointmentId ? [this.appointmentPopupService.linkedAppointmentId]
      : tempLinkedAppt;
    this.appointmentPopupService.linkedAppointmentId = linkedAppointmentIds.join();
    this.linkedAppointments = [];
    this.linkedAppointments = await this.appointmentPopupService.loadLinkedAppointments();
    this.appointmentPopupService.isLinkedAppointmentsLoaded = true;
    this.RefreshGrid();
  }


  OnFromDateValueChange($event) {

    let todate: Date;
    todate = this.FormGrp.controls["EndTime"].value;
    if (todate < $event.value) {
      this.FormGrp.controls["EndTime"].setValue($event.value);
    }

    this.minToDate = this.utils.getDate($event.value);
  }

  async makeSearchCall(name: string) {
    this.searchFilter = [];
    if (name && name.length > 0) {
      const searchResponse = await this._appointmentService.searchAppointmentClients(name, this.requestUid);
      let response = [];
      if (searchResponse && searchResponse[0].result) {
        response = searchResponse[0].result;
      }
      if (searchResponse && searchResponse.length > 1 && searchResponse[1].result) {
        let clientValues: any[] = searchResponse[1].result.map(x => {
          return {
            id: x.clientDetail.id, value: x.clientDetail.firstName + " "
              + x.clientDetail.lastName, type: "Client", requestUid: x.requestUid,
              email: x.emails, phoneNumber: x.phoneNumbers,address: x.addresses
          }
        })
        response = response.concat(clientValues);
      }
      var responseUid = "";
      if (response && response.length > 0) {
        responseUid = response[0].requestUid;
      }
      if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
        this.searchFilter = response;
      }
    }
  }

  BindGridDataLinked() {
    this.linkedAppointments = this.getUnique(this.linkedAppointments);
    let filteredValues = [];
    const selectedAppointments = this.getAppointmentId();
    this.linkedAppointments = this.linkedAppointments.filter(x => !selectedAppointments.find(y => y === x.appointmentId));
    this.appointmentPopupService.linkedAppointmentUpdated = [...this.linkedAppointments];
    this.linkedAppointmentsData.emit(this.appointmentPopupService.linkedAppointmentUpdated);
    if (this.checkAllLinkedAppointments) {
      filteredValues = this.linkedAppointments.filter(x => !selectedAppointments.find(y => y === x.appointmentId));
    }

    if (this.checkSameClientLinkedAppointments) {
      const tempClientList = this.appointmentPopupService.recordsArray ? this.appointmentPopupService.recordsArray.map(x => x.clientDetail.id) : [this.appointmentPopupService.clientId];
      const clientIds = (this.ClientIds && this.ClientIds.length > 0) ? this.ClientIds : tempClientList;
      if (clientIds && clientIds.length > 0) {
        if (filteredValues.length > 0) {
          const clientAppointments = filteredValues.filter(x => clientIds.find(y => y == x.clientId));
          clientAppointments.forEach(x => {
            filteredValues = filteredValues.filter(y => !selectedAppointments.find(t => t === x.appointmentId));
            filteredValues = [...filteredValues];
          });
        }
        else {
          filteredValues = this.linkedAppointments.filter(x => clientIds.find(y => !selectedAppointments.find(o => o === x.appointmentId) && y == x.clientId));
        }
      }
    }


    filteredValues.forEach(x => {
      x.appointmentTime = this.utils.FormatAppointmentDateTime(x.startTime, x.endTime);
      x.statusCode = this.utils.getstatusDisplayName(x.status);
      if (x.locationId == 0) {
        x.location = this.localization.captions.setting.Offsite;
      }
    })

    this.headerOptions = [{ "title": this.captions.Name, "jsonkey": "clientName" },
    { "title": this.captions.Service, "jsonkey": "serviceName" },
    { "title": this.captions.Package, "jsonkey": "packageName" },
    { "title": this.captions.Staff, "jsonkey": "staffName" },
    { "title": this.captions.AppointmentTime, "jsonkey": "appointmentTime", "sortcolumn": "startTime", "sortcolumndatatype": "Date" },
    { "title": this.captions.Location, "jsonkey": "location" },
    { "title": this.captions.Status, "jsonkey": "statusCode", "displayType": "icon" },
    { "title": this.captions.AppointmentID, "jsonkey": "appointmentId", "alignType": 'left' },
    { "title": this.captions.CancelID, "jsonkey": "cancelId" },
    { "title": this.captions.RetailTransactionNo, "jsonkey": "retailTransaction", "alignType": 'left' },
    { "title": this.captions.ConfirmationNo, "jsonkey": "confirmationId" },
    { "title": this.captions.LinkedAppointmentID, "jsonkey": "linkedAppointmentID" }];

    this.tableContent = filteredValues;
    return [{
      TableHdrData: [{ "title": this.captions.Name, "jsonkey": "clientName" },
      { "title": this.captions.Service, "jsonkey": "serviceName" },
      { "title": this.captions.Package, "jsonkey": "packageName" },
      { "title": this.captions.Staff, "jsonkey": "staffName" },
      { "title": this.captions.AppointmentTime, "jsonkey": "appointmentTime", "sortcolumn": "startTime", "sortcolumndatatype": "Date" },
      { "title": this.captions.Location, "jsonkey": "location" },
      { "title": this.captions.Status, "jsonkey": "statusCode", "displayType": "icon" },
      { "title": this.captions.AppointmentID, "jsonkey": "appointmentId", "alignType": 'left' },
      { "title": this.captions.CancelID, "jsonkey": "cancelId" },
      { "title": this.captions.RetailTransactionNo, "jsonkey": "retailTransaction", "alignType": 'left' },
      { "title": this.captions.ConfirmationNo, "jsonkey": "confirmationId" },
      { "title": this.captions.LinkedAppointmentID, "jsonkey": "linkedAppointmentID" }],
      TablebodyData: filteredValues,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      EnableActions: false,
      EditMoreOption: true,
      SelectRows: true,
      IsCommission: true,
      Searchable: false,
      TableSearchText: "",
      isEditOptionRemove: true,
      Sortable: 'description',
      TableDraggable: false,
      IsReadOnly: false,
      isEllipsis: false
    }];
  }
  getAppointmentId(): any {

    if (this.appointmentPopupService.existingAppointmentsRef && this.appointmentPopupService.existingAppointmentsRef.length > 0) {
      return this.appointmentPopupService.existingAppointmentsRef.map(x => x.id);
    }
    else if (this.SlideService && this.SlideService.fieldData && this.SlideService.fieldData.appointmentId) {
      return [this.SlideService.fieldData.appointmentId];
    }
    else if (this.spaWizardService.groupAppointments && this.spaWizardService.groupAppointments.length > 0) {
      return this.spaWizardService.groupAppointments.map(x => x.appointment.appointmentDetail.id)
    }
    return [];
  }



  BindGridDataUnLinked() {
    this.unLinkedAppointments = this.getUnique(this.unLinkedAppointments);
    this.unLinkedAppointments.map(x => {
      x.appointmentTime = this.utils.FormatAppointmentDateTime(x.startTime, x.endTime);
      x.statusCode = this.utils.getstatusDisplayName(x.status);
    });
    const selectedAppointments = this.getAppointmentId();
    if (this.unLinkedAppointments.length > 0 && selectedAppointments && selectedAppointments.length > 0) {
      this.unLinkedAppointments = this.unLinkedAppointments.filter(x => !selectedAppointments.find(y => y === x.appointmentId));
    }

    if (this.unLinkedAppointments != null && this.unLinkedAppointments.length > 0) {
      this.unLinkedAppointments.forEach(element => {
        if (element.locationId == 0) {
          element.location = this.localization.captions.setting.Offsite;
        }
      });
    }
    return [{
      TableHdrData: [{ "title": this.captions.Name, "jsonkey": "clientName" },
      { "title": this.captions.Service, "jsonkey": "serviceName" },
      { "title": this.captions.Package, "jsonkey": "packageName" },
      { "title": this.captions.Staff, "jsonkey": "staffName" },
      { "title": this.captions.AppointmentTime, "jsonkey": "appointmentTime", "sortcolumn": "startTime", "sortcolumndatatype": "Date" },
      { "title": this.captions.Location, "jsonkey": "location" },
      { "title": this.captions.Status, "jsonkey": "statusCode", "displayType": "icon" },
      { "title": this.captions.AppointmentID, "jsonkey": "appointmentId", "alignType": 'left' },
      { "title": this.captions.CancelID, "jsonkey": "cancelId" },
      { "title": this.captions.RetailTransactionNo, "jsonkey": "retailTransaction", "alignType": 'left' },
      { "title": this.captions.ConfirmationNo, "jsonkey": "confirmationId" },
      { "title": this.captions.LinkedAppointmentID, "jsonkey": "linkedAppointmentID" }],
      TablebodyData: this.unLinkedAppointments,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      EnableActions: false,
      EditMoreOption: true,
      SelectRows: true,
      IsCommission: true,
      Searchable: false,
      TableSearchText: "",
      isEditOptionRemove: true,
      Sortable: 'description',
      TableDraggable: false,
      IsReadOnly: false,
      isEllipsis: false
    }];

  }


  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) {
    switch (callDesc) {
      case "GetAllSpaService": {
        this.spaservices = result.result ? result.result : [];
      } break;
      case "GetAllLoc": {
        this.locations = result.result ? result.result : [];
      } break;
      case "GetAllTherapist": {
        this.staffs = result.result ? result.result : [];
      } break;
      case "GetSpaPackages": {
        this.packages = result.result ? result.result : [];
      } break;
      case "AppointmentSearchClientFilter": {
        var responseUid = "";
        if (result.result != null)
          responseUid = result.result[0].requestUid;

        if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
          this.searchFilter = result.result ? result.result : [];
        }
      } break;
      case "LinkedAppointments": {
        this.unLinkedAppointments = result.result ? result.result : [];
        this.tableoptionsUnLinked = this.BindGridDataUnLinked();
        this.selectedLinkedAppointments = [];
      }
    }
  }


  LoadLocations() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetAllLoc",
      method: HttpMethod.Get,
      showError: true,
      extraParams: [false]
    });
  }
  LoadServices() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetAllSpaService",
      method: HttpMethod.Get,
      showError: true,
      extraParams: [false]
    });
  }
  LoadTherapists() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetAllTherapist",
      method: HttpMethod.Get,
      showError: true,
      extraParams: [false]
    });
  }

  LoadPackages() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetSpaPackages",
      method: HttpMethod.Get,
      showError: true,
      extraParams: [false]
    });
  }

  LoadSearchResults() {
    let _Id: any = this.selectedId;
    this.gridData = [];
    this.gridDataTemp = [];
    if (this.SearchText == '') {
      _Id = 0;
    }
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "LinkedAppointments",
      uriParams: {
        isLinked: false,
      },
      method: HttpMethod.Post,
      showError: true,
      body: {
        linkedAppointmentsID: "",
        search: this.SearchText,
        selectedValue: this.selectedId != 0 ? { id: this.selectedId, search: this.selectedValue, type: this.selectedType } : null,
        fromDate: this.localization.convertDateObjToAPIdate(this.FormGrp.value.FromTime),
        toDate: this.localization.convertDateObjToAPIdate(this.FormGrp.value.EndTime),
        status: this.formArray(this.FormGrp.value.status),
        services: this.formArray(this.FormGrp.value.services),
        packages: this.formArray(this.FormGrp.value.package),
        staffs: this.formArray(this.FormGrp.value.staff),
        locations: this.formArray(this.FormGrp.value.location)
      },
      extraParams: [false]
    });
  }

  formArray(input: string) {
    if (input == "" || input == "All") {
      return [];
    }
    else {
      return [input];
    }
  }

  getSearchValue($event: any) {
    this.selectedId = $event.value.id;
    this.selectedValue = $event.value.value;
    this.selectedType = $event.value.type;
    var clientSearchData = {
      id: this.selectedId,
      value: this.selectedValue,
      type: this.selectedType
    };

    this.SearchText = clientSearchData;
  }

  OnServiceChange($evt: any) {
    this.selectedService = $evt.value;
  }
  OnTherapistChange($evt: any) {
    this.selectedStaff = $evt.value;
  }
  OnLocationChange($evt: any) {
    this.selectedLocation = $evt.value;
    this.FormGrp.controls["locations"].setValue = $evt.value;
  }
  OnStatusChange($evt: any) {
    this.selectedStatus = $evt.value;
    this.FormGrp.controls["status"].setValue = $evt.value;
  }

  selectedFilter() {

    let statusArr = [];

    if (this.selectedstatusArr.length == 0) {
      this.AppointmentStatus.forEach(result => { statusArr.push(result.code) });

    }
    else if (this.selectedstatusArr.length > 0) {
      for (let parent of this.selectedstatusArr) {
        for (let child of this.AppointmentStatus) {
          if (child.id == parent.id) {
            statusArr.push(child.code);
          }
        }
      }

    }
    let newSearchResultsArr: any = [];
    for (let element of this.gridDataTemp) {
      if (statusArr.includes(element.statusCode)) {
        newSearchResultsArr.push(element);
      }
    }
    this.gridData = newSearchResultsArr ? newSearchResultsArr : [];

    this.apidata = [{
      TableHdrData: this.gridheader,
      TablebodyData: this.gridData,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.DescriptionLocationCommissionon,
      EnableActions: false,
      SelectRows: false,
      Sortable: "appointmentTime",
      SelectedSettingId: GlobalConst.GridType.appointmentsearch,
      Searchable: false,
      EditMoreOption: true,
      PMSData: this.pmsData
    }];

    this.cancel = this.gridData.some(x => { return x.status != 'RESV' ? false : true });
  }

  search(event?: any, src?: any) {
    this.LoadSearchResults();
  }
  searchAppointments(event?: any, src?: any) {
    this.gridData = [];
    let fromDate: any;
    let endDate: any;
    if (src == 'from') {
      fromDate = this.utils.GetFormattedDate(event);
      endDate = this.utils.GetFormattedDate(this.FormGrp.controls["EndTime"].value);
    } else if (src == 'to') {
      fromDate = this.utils.GetFormattedDate(this.FormGrp.controls["FromTime"].value);
      endDate = this.utils.GetFormattedDate(event);
    }
    else {
      fromDate = this.utils.GetFormattedDate(this.FormGrp.controls["FromTime"].value);
      endDate = this.utils.GetFormattedDate(this.FormGrp.controls["EndTime"].value);
    }

    for (let index = 0; index < this.gridDataTemp.length; index++) {
      const element = this.gridDataTemp[index];
      let stDate: any = this.utils.GetFormattedDate(element.startDate);
      if (stDate >= fromDate && stDate <= endDate) {
        this.gridData.push(element);
      }
    }


    this.apidata = [{
      TableHdrData: this.gridheader,
      TablebodyData: this.gridData,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.DescriptionLocationCommissionon,
      EnableActions: false,
      SelectRows: false,
      Sortable: "appointmentTime",
      SelectedSettingId: GlobalConst.GridType.appointmentsearch,
      Searchable: false,
      EditMoreOption: true,
      PMSData: this.pmsData
    }];

    this.selectedFilter();
  }

  filter(data, event, src) {

    let filter_fromtime = this.FormGrp.controls['FromTime'].value;
    let filter_endtime = this.FormGrp.controls['EndTime'].value;
    if (src == 'from')
      filter_fromtime = event ? event : 'All';
    if (src == 'to')
      filter_endtime = event ? moment(event).add(23, 'h').add(59, 'm').add(59, 's') : 'All';
    let filter_searcgtext = this.FormGrp.controls['searchtext'].value;
    let filter_serv = this.FormGrp.controls['services'].value == 'All' ? this.spaservices : [this.FormGrp.controls['services'].value];
    let filter_package = this.FormGrp.controls['package'].value == 'All' ? this.packages : [this.FormGrp.controls['package'].value];
    let filter_staff = this.FormGrp.controls['staff'].value == 'All' ? this.staffs : [this.FormGrp.controls['staff'].value];
    let filter_location = this.FormGrp.controls['location'].value == 'All' ? this.locations : [this.FormGrp.controls['location'].value];
    let filter_status = (this.selectedstatusArr.length == 0) ? this.AppointmentStatus : this.selectedstatusArr;
    return data.filter(result => {
      return (result['appointmentid'].toLowerCase().includes(filter_searcgtext.toLowerCase())
        || result['name'].toLowerCase().includes(filter_searcgtext.toLowerCase())) &&
        filter_serv.indexOf(result['service']) != -1 &&
        filter_package.indexOf(result['package']) != -1 &&
        filter_staff.indexOf(result['staff']) != -1 &&
        filter_location.indexOf(result['location']) != -1 &&
        filter_status.indexOf(result['status']) != -1 &&
        this.isContains(filter_status, result['status']) != -1 &&
        ((filter_fromtime == "All" || filter_fromtime == "" ? true : filter_fromtime <= moment(result['appointmentstarttime'])) &&
          (filter_endtime == "All" || filter_endtime == "" ? true : filter_endtime >= moment(result['appointmentendtime'])));
    });
  }
  isContains(coll, obj) {
    let index = -1;
    if (coll) {
      index = coll.findIndex(x => { return x.name == obj });
    }
    return index;
  }

  populateTableInfo() {
    let stayActivityId = this.pmsData ? this.pmsData.activityId : null;
    let _propertyDate = this.utils.convertDateFormat(this.PropertyInfo.CurrentDate);
    let searchBy = {
      "Id": this.selectedId, 
      "type": this.selectedType, 
      "value": this.selectedValue, 
    } 
    let bodyObj = {
      "fromDate": this.utils.formatDate(this.defaultFromDt), 
      "toDate": this.utils.formatDate(this.defaultToDt), 
      "propertyDate": _propertyDate,
      "serviceId": 0, 
      "packageId": 0 ,
      "staffId": 0, 
      "locationId": 0,
      "guestTypeId":0, 
      "searchBy": searchBy,
      "stayActivityId": stayActivityId
    };
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "SearchAppointments",
      method: HttpMethod.Put,
      uriParams: {
       
      },
      body: bodyObj,
      showError: true,
      extraParams: [false]
    });
  }

  ReInstate(appDetail, ChangeLocation, ChangeTherapist, TherapistsToBeChanged?, TempHoldID?) {
    this.reinstateMultipackOpted = false;
    this.isReinstateAll = false;
    this.reinstateApptObj = {
      AppointmentId: appDetail.appointmentDetail.id,
      AppointmentDetail: appDetail.appointmentDetail,
      IsLocationChange: ChangeLocation,
      IsTherapistChange: ChangeTherapist,
      TherapistsToBeChanged: TherapistsToBeChanged,
      TempHoldId: TempHoldID
    };
    this._appointmentService.GetMultiPacksForClients([appDetail.appointmentDetail.clientId], this.successCallback.bind(this), this.utils.errorCallback.bind(this));

  }

  ReinstateAppointment(appDetail, ChangeLocation, ChangeTherapist, TherapistsToBeChanged?, TempHoldID?) {
    if (this._retailPropertyInfoService.getQuickIdConfig && this._retailPropertyInfoService.getQuickIdConfig.QUICKID_APPOINTMENTREINSTATE) {
      const quickLoginDialogRef = this._quickLoginUtil.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          this.ReInstate(appDetail, ChangeLocation, ChangeTherapist, TherapistsToBeChanged, TempHoldID);
        }
      });
    } else {
      this.ReInstate(appDetail, ChangeLocation, ChangeTherapist, TherapistsToBeChanged, TempHoldID);
    }

  }

  ReinstateAppointmentCallback(result, extraParams?: any) {
    if (result.toLowerCase() == "YES".toLowerCase()) {
      this.reinstateMultipackOpted = true;
    }
    let bodyObj = extraParams[0].bodyObj
    let appDetail = extraParams[0].appDetail
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "ReInstateAppointment",
      method: HttpMethod.Put,
      body: bodyObj,
      showError: true,
      extraParams: appDetail
    });

  }

  ReinstateAllAppointmentCallback(result, extraParams?: any) {
    this.reinstateMultipackOpted = false;
    let bodyObj = extraParams[0].bodyObj
    let appDetail = extraParams[0].appDetail
    if (result.toLowerCase() == "YES".toLowerCase()) {
      this.reinstateMultipackOpted = true;
    }
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "ReinstateAllAppointment",
      method: HttpMethod.Put,
      body: bodyObj,
      showError: true,
      extraParams: appDetail
    });

  }

  DeletecallBack(result) {
    this.popupclosed.emit(result);
  }

  GetAppointmentInfo(appointmentId: number) {
    let uriParam = { id: appointmentId };
    this.InvokeServiceCall('GetAppointmentById', GlobalConst.Host.schedule, HttpMethod.Get, uriParam);
  }

  UndoCheckin() {
    let uriParam = { id: this.SlideService.fieldData.appointmentId };
    this.InvokeServiceCall('UndoCheckInAppointment', GlobalConst.Host.schedule, HttpMethod.Put, uriParam);
  }



  private RefreshGrid() {
    this.tableoptionsLinked = this.BindGridDataLinked();
    this.tableoptionsUnLinked = this.BindGridDataUnLinked();
    this.selectedLinkedAppointments = [];
    this.selectedUnLinkedAppointments = [];
  }

  CheckInCheckOut() {
    let params = { isNoShow: false };
    let body: number[] = [this.SlideService.fieldData.appointmentId];
    this.InvokeServiceCall('CheckinAppointment', GlobalConst.Host.schedule, HttpMethod.Put, params, body);
  }

  InvokeServiceCall(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  openMessage = (actionType, frm, callback?, extraParams?, appointments?) => {
    this.disableReinstateFlag = true;
    let data;
    if (actionType == 'error' && frm == 'cancel') {
      data = { headername: this.captions.Oops, headerIcon: 'icon-error-icon', headerMessage: this.captions.NotAllAppointmentsCancelled, buttonName: this.captions.okay, type: 'message' }
    }
    else if (actionType == 'success' && frm == 'cancel') {
      data = { headername: this.captions.Success, headerIcon: 'icon-success-icon', headerMessage: this.captions.AppointmentsCancelledSuccessfully, buttonName: this.captions.continue, type: 'message' }
    }
    else if (actionType == 'success' && frm == 'reinstate') {
      data = { headername: this.captions.Success, headerIcon: 'icon-success-icon', headerMessage: this.captions.AppointmentsReinstatedSuccessfully, buttonName: this.captions.continue, type: 'message' }
    }
    else if (actionType == 'allfail' && frm == 'reinstate') {
      data = { headername: this.captions.Oops, headerIcon: 'icon-error-icon', headerMessage: this.captions.AppointmentsCannotbeResinstatedPleaseRebookManually, buttonName: this.captions.okay, type: 'message' }
    }
    else if (actionType == 'somefail' && frm == 'reinstate') {
      data = { headername: this.captions.Oops, headerIcon: 'icon-error-icon', headerMessage: this.captions.SomeAppointmentsCannotbeResinstatedPleaseRebookManually, buttonName: this.captions.okay, type: 'message' }
    }
    else if (actionType == 'failone' && frm == 'reinstate') {
      data = { headername: this.captions.Oops, headerIcon: 'icon-error-icon', headerMessage: this.captions.AppointmentCannotbeResinstatedPleaseRebookManually, buttonName: this.captions.okay, type: 'message' }
    }
    else if (actionType == 'locationerror' && frm == 'reinstate') {
      data = { headername: this.captions.Oops, headerIcon: 'icon-error-icon', headerMessage: this.captions.Appointmentcannotbereinstatedduetounavailabilityoflocation, buttonName: this.captions.okay, type: 'message' }
    }
    else if (actionType == 'therapisterror' && frm == 'reinstate') {
      data = { headername: this.captions.Oops, headerIcon: 'icon-error-icon', headerMessage: this.captions.Appointmentcannotbereinstatedduetounavailabilityoftherapist, buttonName: this.captions.okay, type: 'message' }
    }
    else if (actionType == 'locationtherapisterror' && frm == 'reinstate') {
      data = { headername: this.captions.Oops, headerIcon: 'icon-error-icon', headerMessage: this.captions.Appointmentcannotbereinstatedduetounavailabilityoflocationandtherapist, buttonName: this.captions.okay, type: 'message' }
    }
    data.type = 'message';
    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: data,
      disableClose: true
    });
    this.alertSubscription = dialogRef.afterClosed().subscribe(result => {
      this.disableReinstateFlag = false;
      if (callback) {
        callback(extraParams, appointments);
      }
    })
  }

  ngOnDestroy() {
    this.popupsOpened = 0;
    this.interval = null;
  }

  IsAuthorized(id: GlobalConst.ActionType): boolean {
    let ActionType = GlobalConst.ActionType;
    let isUserAuthorized: boolean = true;
    let breakpointNumber: number[] = [];
    switch (id) {
      case ActionType.changeservice:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.ChangeService);
        break;
      case ActionType.cancel:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.CancelAppointment);
        break;
      case ActionType.checkin:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.CheckInAppointment);
        break;
      case ActionType.undocheckin:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.UndoCheckIn);
        break;
      case ActionType.checkout:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.CheckOutAppointment);
        break;
      case ActionType.checkin_checkout:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.CheckIn_CheckOutAppointment);
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.CheckInAppointment);
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.CheckOutAppointment);
        break;
      case ActionType.copy:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.CopyAppointment);
        break;
      case ActionType.move:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.MoveAppointment);
        break;
      case ActionType.recurring:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.BookRecurringAppointments);
        break;
      case ActionType.swap:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.SwapAppointment);
        break;
      case ActionType.overbook:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.OverbookTherapist);
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.OverbookLocation);
        break;
      case ActionType.adddeposit:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.AddDeposit);
        break;
      case ActionType.linkmultipack:
        if (!this.SlideService.fieldData.clientMultiPackId) {
          breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.LinkMultipack);
        }
        else {
          breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.UnlinkMultipack);
        }
        break;
      case ActionType.serviceaddon:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.ServiceAddOn);
        break;
      case ActionType.booklunch:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.BookLunch);
        break;
      case ActionType.edit:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.EditAppointment);
        break;
      case ActionType.recap:
        breakpointNumber.push(GlobalConst.SPAScheduleBreakPoint.RecapAppointment);
        break;
      case ActionType.print:
        breakpointNumber.push(GlobalConst.ReportBreakPoint.GuestAppointments);
        break;
    }
    if (breakpointNumber.length > 0) {
      isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
    }
    return isUserAuthorized;
  }

  displayFn(searchVal: any) {
    if (searchVal) { return searchVal.value; }
  }

  IsAuthorizedForStatusChange(): boolean {
    let breakpointNumber: number[] = [GlobalConst.SPAScheduleBreakPoint.ChangeAppointmentStatus];
    return this.breakPoint.CheckForAccess(breakpointNumber)
  }

  BlockActionIfPreviousDay(): boolean {
    if (this.utils.GetDateWithoutTime(this.SlideService.fieldData.startDTTM) >= this.utils.GetDateWithoutTime(this.PropertyInfo.CurrentDate)) {
      return true
    }
    else {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captionsBookApp.ActionNotAvailableforAppointmentStatus)
      return false
    }
  }

  UndoCheckoutCallBack(res) {
    this.RefreshGrid();
  }
  reset() {
    if (this.pmsData) {
      this.pmsData = null;
      this.utils.removeSession(GlobalConst.PMSDATA);
      this.disableSearchControls(false);
    }
    this.FormGrp = this.Form.group({
      FromTime: this.defaultFromDt,
      EndTime: this.defaultToDt,
      searchtext: '',
      services: 'All',
      package: 'All',
      staff: 'All',
      location: 'All',
      status: 'All',


    });
    this.selectedstatusArr = [];
    this.selectedId = 0;
    this.selectedType = "0";
    this.selectedValue = "0";
    this.minToDate = this.defaultFromDt;
    this.minFromDate = this.maxFromDate = this.maxTodate = null;
    this.LoadSearchResults();
  }

  disableSearchControls(bFlag: boolean) {
    if (bFlag) {
      this.FormGrp.get('FromTime').disable();
      this.FormGrp.get('EndTime').disable();
      this.FormGrp.get('searchtext').disable();
      this.FormGrp.get('services').disable();
      this.FormGrp.get('package').disable();
      this.FormGrp.get('staff').disable();
      this.FormGrp.get('location').disable();
      this.FormGrp.get('status').disable();
      this.isStatusDisabled = true;
      this.isSearchDisabled = true;
      this.autocomplete.disable();
    }
    else {
      this.FormGrp.get('FromTime').enable();
      this.FormGrp.get('EndTime').enable();
      this.FormGrp.get('searchtext').enable();
      this.FormGrp.get('services').enable();
      this.FormGrp.get('package').enable();
      this.FormGrp.get('staff').enable();
      this.FormGrp.get('location').enable();
      this.FormGrp.get('status').enable();
      this.isStatusDisabled = false;
      this.isSearchDisabled = false;
      this.autocomplete = new UntypedFormControl();
    }
  }

  CurrentRowSelectedEmitter(event, isLinked: boolean) {
    const selectedRows = event.SelectedRow.length ? event.SelectedRow : [event.SelectedRow];
    if (isLinked) {
      selectedRows.map(item => {
        const currentItem = this.selectedLinkedAppointments.find(c => c.appointmentId === item.appointmentId);
        if (currentItem && !event.event.checked) {
          this.selectedLinkedAppointments = this.selectedLinkedAppointments.filter(x => x.appointmentId != currentItem.appointmentId);
        }
        else {
          this.selectedLinkedAppointments.push(item);
        }
      });
    }
    else {
      selectedRows.map(item => {
        const currentItem = this.selectedUnLinkedAppointments.find(c => c.appointmentId === item.appointmentId);
        if (currentItem && !event.event.checked) {
          this.selectedUnLinkedAppointments = this.selectedUnLinkedAppointments.filter(x => x.appointmentId != currentItem.appointmentId);
        }
        else {
          this.selectedUnLinkedAppointments.push(item);
        }
      });
    }
  }

  linkAppointments() {
    if (this.selectedUnLinkedAppointments && this.selectedUnLinkedAppointments.length > 0) {
      let unlinkedAppointments = [...this.unLinkedAppointments];
      let linkAppointments = [...this.linkedAppointments];


      this.selectedUnLinkedAppointments.map(x => {
        unlinkedAppointments = unlinkedAppointments.filter(c => c.appointmentId != x.appointmentId);
        const linkedAppointment = linkAppointments.find(c => c.appointmentId === x.appointmentId)
        if (!linkedAppointment) {
          linkAppointments.push(x);
        }
        else {
          this.utils.showAlert(this.captions.linkedAppointmentAlreadyAdded, GlobalConst.AlertType.Warning);
          return;
        }
      });

      const unique = [...new Set(linkAppointments.map(item => item.linkedAppointmentID))];

      if (unique.filter(x => x && x != '').length > 1) {
        this.utils.showAlert(this.captions.linkedAppointmentNotALlowed, GlobalConst.AlertType.Warning);
      }
      else {
        this.unLinkedAppointments = [...unlinkedAppointments];
        this.linkedAppointments = [...linkAppointments];
        this.RefreshGrid();
        this.appointmentPopupService.linkedAppointmentUpdated = [...this.linkedAppointments];
        this.linkedAppointmentsData.emit(this.appointmentPopupService.linkedAppointmentUpdated);
        this.appointmentPopupService.isLinkedAppointments = true;
      }
    }
  }
  getUnique(array: any[]): any {
    const response = [];
    array.forEach(x => {
      if (!response.find(y => y.appointmentId === x.appointmentId)) {
        response.push(x);
      }
    })
    return response;
  }
  unLinkAppointments() {
    if (this.selectedLinkedAppointments && this.selectedLinkedAppointments.length > 0) {
      this.selectedLinkedAppointments.map(x => {
        this.linkedAppointments = this.linkedAppointments.filter(c => c.appointmentId != x.appointmentId);
      });
      this.RefreshGrid();
      this.appointmentPopupService.linkedAppointmentUpdated = [...this.linkedAppointments];
      this.linkedAppointmentsData.emit(this.appointmentPopupService.linkedAppointmentUpdated);
      this.appointmentPopupService.isLinkedAppointments = true;
      this.LoadSearchResults();
    }
  }

  checkboxClick(evt, obj) {
    if (evt === 'All') {
      this.checkAllLinkedAppointments = obj.checked;
    }

    else {
      this.checkSameClientLinkedAppointments = obj.checked;
    }

    this.tableoptionsLinked = this.BindGridDataLinked();
  }

  getToolTip(option){
    const name = option.value;
    if(option.type =="ApptId")
    {
      return `${name}`
    }else{
      const email = option.email[0]?.emailId ? `| ${option.email[0]?.emailId}` : '';
      const phoneNumber = option.phoneNumber[0]?.number ? `| ${option.phoneNumber[0]?.number}` : '';
      return `${name} ${email} ${phoneNumber}`
    }   
  }
}
