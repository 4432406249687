import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormType, RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { CreateTransactionItemlist } from '../create-transcation/item-transaction';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { SystemPreferenceInterface } from 'src/app/eatecui/source/shared/models/system-preference';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import moment from 'moment';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';

export const AutoCompleteSearch: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: TransactionConfiguration,
    sysytemPreferenceService?: SysytemPreferenceService, createTransactionService?: CreateTransactionService
): any => {
    try {
        const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
        if (
            FieldGrouping.CustomFormAction.EnableCustomMethod &&
            FieldGrouping.hasOwnProperty('autoCompleteHeader') &&
            FieldGrouping.autoCompleteHeader.length > 0 && typeof CustomData === 'string') {
            const POId = parseInt(FormGroupData.value['SupplierId'], 10);
            const LocIds = JSON.parse(sessionStorage.getItem('FromLocId'));
            const Filter = POId ? 'SupplierId eq ' + POId + ` and POHeadLocationId/Id in (${LocIds.join()}) and TransactionStatusId eq 1 and ApprovalStatusId eq 4` :
                `POHeadLocationId/Id in (${LocIds.join()}) and TransactionStatusId eq 1 and ApprovalStatusId eq 4`;
            let GetDataFilter = encodeURIComponent(`${Filter}`).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
            GetDataFilter = '(startswith(cast(Id, \'Edm.String\'),\'' + CustomData + '\')) and ' + GetDataFilter;
            const filterString = '?$filter=' + GetDataFilter + '&$expand=POHeadSupplierId($select=id,name),PurchaseOrderDetails($expand=PODetailIngredientId)';
            const EndPointUrl = `/inventory/api/${FieldGrouping.CustomFormAction.Url}` + `${filterString}`;
            // if (CustomData !== '') {
            httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                const responseData = res['value'];
                const autoCompleteData = FormField.filter((x: FormType) => x.type === 'autocomplete');
                if (autoCompleteData && autoCompleteData.length > 0) {
                    const autoFormFields = autoCompleteData[0];
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [];
                    responseData.map((x: any) => {
                        if (x.Id.toString().split(',').length === 1) {
                            x.Id = `${x.Id},` + `${x.VersionNo}`;
                        }
                        x.SupplierName = `${x.POHeadSupplierId.Name}`;
                        const DateInvoice = x.PostDate;
                        // moment.utc(DateInvoice).local().format(DateTimeFormat);
                        x['PostDate'] = Formaters.globalDateFormaters(DateInvoice, true);
                    });
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [...responseData];
                }
            });
            // } else {
            //     const autoCompleteData = FormField.filter((x: FormType) => x.type === 'autocomplete');
            //     const autoFormFields = autoCompleteData[0];
            //     autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [...[]];
            // }
        }
        if (
            FieldGrouping.CustomFormAction.EnableCustomMethod &&
            FieldGrouping.hasOwnProperty('autoCompleteHeader') &&
            FieldGrouping.autoCompleteHeader.length > 0 && typeof CustomData !== 'string') {
            FormGroupData.controls[FieldGrouping.OdataKey].setValue(CustomData['Id']);
            const POId = parseInt(FormGroupData.value['PONumber'].split(',')[0], 10);
            const EndPointUrl = `/inventory/api/${FieldGrouping.CustomFormAction.Url}/${POId}?transtype=3`;
            httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                const ResultJson = JSON.parse(res['value']);
                console.log('PO Response', ResultJson);
                createTransactionService.previousSelectedLocation = ResultJson.LocationId;
                FormGroupData.controls['LocationId'].setValue(ResultJson.LocationId);
                FormGroupData.controls['DepartmentId'].setValue(ResultJson.DepartmentId);
                FormGroupData.controls['PostDate'].setValue(ResultJson.PostDate);
                FormGroupData.controls['SupplierId'].setValue(ResultJson.SupplierId);
                FormGroupData.controls['TransactionCurrencyId'].setValue(ResultJson.TransactionCurrencyId);
                FormGroupData.controls['ExchangeRate'].setValue(ResultJson.ExchangeRate);
                FormGroupData.controls['BaseCurrencyId'].setValue(ResultJson.BaseCurrencyId);
                FormGroupData.controls['DebitAccId'].setValue(ResultJson.DebitAccId);
                FormGroupData.controls['InvoiceNumber'].setValue('');
                FormGroupData.controls['POHeadId'].setValue(ResultJson.Id);
                if (MasterDataCollection.ViewTranscationConfig.GridConfiguration.GridName === 'All Invoices') {
                    getSupplierOptions(httpService, MasterDataCollection, FieldGrouping, FormGroupData, FormField);
                }
                const PODetails = _.cloneDeep(ResultJson.PurchaseOrderDetails);
                if (ResultJson.POHeadSupplierId) {
                    switch (ResultJson.POHeadSupplierId.RNIOptionId) {
                        case 1:
                            FormGroupData.controls['IsInvoiced'].setValue(true);
                            break;
                        case 2:
                            FormGroupData.controls['IsInvoiced'].setValue(false);
                            break;
                        case 3:
                            FormField[12].fieldType.SingleData.disbaledProperty = false;
                            break;
                        case null:
                            FormGroupData.controls['IsInvoiced'].setValue(true);
                            break;
                    }
                }
                const EndPointUrl = `/inventory/api/GetExchangeRate/${ResultJson.TransactionCurrencyId}/${ResultJson.BaseCurrencyId}`;
                httpService.GethttpMethod(EndPointUrl).subscribe(async res => {
                    let ExchangeRate = 1;
                    if (res) {
                        ExchangeRate = res['ExchangeRate'] ? res['ExchangeRate'] : 1;
                    }
                    FormGroupData.controls['ExchangeRate'].setValue(ExchangeRate);
                    const returnData = AssignPODetails(PODetails, MasterDataCollection, ExchangeRate);
                    await AssignMiscCharges(ResultJson, MasterDataCollection, returnData, httpService, ExchangeRate,
                        createTransactionService, sysytemPreferenceService).then(res => {
                            FormGroupData.controls['ReceivingHeadOtherTotals'].setValue(res);
                        });
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded(PODetails, true);
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody
                        = CreateTransactionItemlist.itemListTableCard
                            (ViewModelEntity, PostModelEntity, MasterDataCollection,
                                httpService, PostModelEntity, ViewModelEntity,
                                null, null, returnData, FormGroupData);
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard = {
                        ...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard
                    };
                });
                if (MasterDataCollection.OdataKey === 'ReceivingHead') {
                    FormField[2].fieldType.SingleData.disbaledProperty = true;
                    FormField[0].fieldType.SingleData.disbaledProperty = true;
                    FormField[1].fieldType.SingleData.disbaledProperty = false;
                } else {
                    FormField[0].fieldType.SingleData.disbaledProperty = true;
                    FormField[1].fieldType.SingleData.disbaledProperty = true;
                    FormField[2].fieldType.SingleData.disbaledProperty = false;
                }
                FormField[3].fieldType.SingleData.disbaledProperty = false;
                FormField[4].fieldType.SingleData.disbaledProperty = false;
                FormField[5].fieldType.SingleData.disbaledProperty = false;
                FormField[7].fieldType.SingleData.disbaledProperty = false;
                FormField[8].fieldType.SingleData.disbaledProperty = false;
                FormField[9].fieldType.SingleData.disbaledProperty = false;
                FormField[11].fieldType.SingleData.disbaledProperty = false;
                FormField[23].fieldType.SingleData.disbaledProperty = true;
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemListExistingData = _.cloneDeep(ResultJson);

                const prepostAssignementSystemPreference = MasterDataCollection.SystemPreference.filter(x => x.Action === 'prepost');
                if (prepostAssignementSystemPreference && prepostAssignementSystemPreference.length > 0) {
                    SystemPreferenceInterface.PreferenceTypeConfig(prepostAssignementSystemPreference, null, null, httpService,
                        sysytemPreferenceService, null, null, null, MasterDataCollection, null).then(function (response) {
                            console.log(response);
                        });
                }
            });
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

function AssignPODetails(PODetails, MasterDataCollection: TransactionConfiguration, ExchangeRate: number) {
    try {
        PODetails.forEach(element => {
            element.PODetailId = element.Id;
            element.IsActive = element.PODetailIngredientId.IsActive;
            element.Id = 0;
            element.Name = element.PODetailIngredientId.Name;
            element.Quantity = element.Qty;
            element.Units = element.PODetailIngredientId.Units;
            element.IngBidNumber = element.BidNumber;
            element.Charges = element.AllocatedCharges ? element.AllocatedCharges : 0;
            element.ChargesInFC = element.ChargesInForeignCurrency ? element.ChargesInForeignCurrency : 0;
            element.OnRecipt = element?.PODetailIngredientId?.OnRecipt;
            element.ReceivingItemsOtherTotals = [
                {
                    'Id': 0,
                    'CategoryId': 3,
                    'CategoryDetailId': 2,
                    'Amount': element.Charges,
                    'AmountInFC': element.ChargesInFC
                }
            ];
            const TransactionTax = element.TransactionTaxAmount !== undefined && element.TransactionTaxAmount !== null ? element.TransactionTaxAmount :
                element.transactiontaxamount !== undefined && element.transactiontaxamount !== null ? element.transactiontaxamount : [];
            if (TransactionTax.length > 0) {
                element['TaxName1'] = TransactionTax[0].TaxName ? TransactionTax[0].TaxName : '-';
                element['TaxAmount1'] = TransactionTax[0].TaxAmount && element.OnRecipt ? TransactionTax[0].TaxAmount : 0;
                element['TaxAmountInFC1'] = TransactionTax[0].TaxAmount  && element.OnRecipt ? TransactionTax[0].TaxAmountInFC : 0;
                element['TaxRate1'] = TransactionTax[0].TaxRate ? TransactionTax[0].TaxRate : 0;
                element['Tax1'] = TransactionTax[0].TaxId ? TransactionTax[0].TaxId : 0;
                if (TransactionTax[1] !== undefined && TransactionTax[1] !== null) {
                    element['TaxName2'] = TransactionTax[1].TaxName ? TransactionTax[1].TaxName : '-';
                    element['TaxAmount2'] = TransactionTax[1].TaxAmount  && element.OnRecipt ? TransactionTax[1].TaxAmount : 0;
                    element['TaxRate2'] = TransactionTax[1].TaxRate ? TransactionTax[1].TaxRate : 0;
                    element['Tax2'] = TransactionTax[1].TaxId ? TransactionTax[1].TaxId : 0;
                    element['TaxAmountInFC2'] = TransactionTax[1].TaxAmount && element.OnRecipt ? TransactionTax[1].TaxAmountInFC : 0;
                } else {
                    element['TaxName2'] = '-';
                    element['TaxAmount2'] = 0;
                    element['TaxRate2'] = 0;
                    element['Tax2'] = 0;
                    element['TaxAmountInFC2'] = 0;
                }
            }
            const transactionUnitsDetails = element['Units'].filter((unitDetails: any) =>
                unitDetails['UnitTypeId'] === element['UnitTypeId'])[0];
            if (transactionUnitsDetails) {
                element['UnitCode'] = transactionUnitsDetails.UnitCode;
                element['UnitCost'] = element.Valued / element.Qty;
            }
            element.ExchangeRate = ExchangeRate.toFixed(4);
            element.UnitCostInFC = (element.UnitCost * element.ExchangeRate).toFixed(4);
            element.AmountInFC = (element.Valued * element.ExchangeRate).toFixed(4);

        });
        return PODetails;
    } catch (error) {
        console.error('Error occurred in AssignPODetails', error);
    }
}

function AssignMiscCharges(PODetails, MasterDataCollection, returnData: Array<any>, httpService: HttpService,
    ExchangeRate, createTransactionService: CreateTransactionService, sysytemPreferenceService: SysytemPreferenceService) {
    try {
        return new Promise(async (resolve) => {
            const TaxAmountWithMisc: Array<MiscWithTaxAmount> = [];
            let TaxesWhenReceiving = sysytemPreferenceService.globalSystemPreference.filter(x => x.LookupName === 'TaxeswhenReceiving')[0].LookupValue;
            let miscChargeTaxList: Array<any> = createTransactionService.MiscChargesList;
            if (TaxesWhenReceiving === 2) {
                miscChargeTaxList.forEach(Miscelement => {
                    let MiscTaxListCollection: Array<any> = []
                    let TotalTaxAmount: number = 0;
                    MiscTaxListCollection = returnData.filter(x => x.TaxName1?.toLowerCase() === Miscelement?.Name.toLowerCase() || x.TaxName2?.toLowerCase() === Miscelement?.Name.toLowerCase());
                    TotalTaxAmount = MiscTaxListCollection.reduce(function (prev, element) {
                        let MiscTaxValue = 0
                        if (Miscelement.Name.toLowerCase() === element.TaxName1.toLowerCase()) {
                            MiscTaxValue = (element.TaxAmount1) ? parseFloat(element.TaxAmount1) : 0;
                        } else if (Miscelement.Name.toLowerCase() === element.TaxName2.toLowerCase()) {
                            MiscTaxValue = (element.TaxAmount2) ? parseFloat(element.TaxAmount2) : 0
                        }
                        return prev + MiscTaxValue;
                    }, 0);
                    if (TotalTaxAmount) {
                        TaxAmountWithMisc.push({
                            MiscId: Miscelement.Id,
                            Amount: parseFloat(TotalTaxAmount.toFixed(4)),
                            AmountInFC: parseFloat((ExchangeRate * TotalTaxAmount).toFixed(4))
                        });
                    }
                });
            }
            const miscObjectArray = [];
            // if (PODetails.TransactionMiscCharges && PODetails.TransactionMiscCharges.length > 0) {
            //     PODetails.TransactionMiscCharges.forEach(element => {
            //         const TaxWithMisc: MiscWithTaxAmount = TaxAmountWithMisc.filter(x => x.MiscId === element.MiscChargeId)[0];
            //         const miscObject = {};
            //         miscObject['Id'] = 0;
            //         miscObject['CategoryId'] = 2;
            //         miscObject['CategoryDetailId'] = element.MiscChargeId;
            //         miscObject['Amount'] = TaxWithMisc ? TaxWithMisc.Amount : element.Amount;
            //         miscObject['AmountInFC'] = TaxWithMisc ? TaxWithMisc.AmountInFC : element.AmountInFC;
            //         miscObjectArray.push(miscObject);
            //     });
            // } else {
            //     TaxAmountWithMisc.forEach(newmisc => {
            //         const miscObject = {};
            //         miscObject['Id'] = 0;
            //         miscObject['CategoryId'] = 2;
            //         miscObject['CategoryDetailId'] = newmisc.MiscId;
            //         miscObject['Amount'] = newmisc.Amount;
            //         miscObject['AmountInFC'] = newmisc.AmountInFC;
            //         miscObjectArray.push(miscObject);
            //     });
            // }

            if (miscChargeTaxList?.length > 0) {
                miscChargeTaxList.forEach((TaxMisc: any) => {
                    let PoMisc = null;
                    let TaxMiscValue = null;
                    if (TaxAmountWithMisc?.length > 0) {
                        TaxMiscValue = TaxAmountWithMisc.find((element: any) => element.MiscId === TaxMisc.Id);
                    }
                    if ( !TaxMiscValue && PODetails?.TransactionMiscCharges?.length > 0) {
                        PoMisc = PODetails?.TransactionMiscCharges.find((element: any) => element.MiscChargeId === TaxMisc.Id);
                    }
                    if ( PoMisc || TaxMiscValue) {
                        const miscModel = {} as MiscModel;
                        miscModel.Id = 0;
                        miscModel.CategoryId = 2;
                        miscModel.CategoryDetailId = ( TaxMiscValue ) ? TaxMiscValue.MiscId : PoMisc.MiscChargeId;
                        miscModel.Amount = ( TaxMiscValue ) ? TaxMiscValue.Amount : PoMisc.Amount
                        miscModel.AmountInFC = ( TaxMiscValue ) ? TaxMiscValue.AmountInFC : PoMisc.AmountInFC;
                        miscObjectArray.push(miscModel)
                    }
                })

            }
            MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData = miscObjectArray;
            resolve(miscObjectArray);
        });
    } catch (error) {
        console.error('Error occurred in AssignPODetails', error);
    }
}

async function getSupplierOptions(httpService: HttpService, TransactionCollection: TransactionConfiguration,
    FieldGrouping: FormBuilderGroupKey, FormGroupData: FormGroup, FormField: FormType[]) {
    try {
        const getCurrentLocationId = (FormGroupData?.controls && FormGroupData.controls[FieldGrouping.OdataKey]) ?
            FormGroupData.controls[FieldGrouping.OdataKey].value : null;
        const supplierDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'SupplierId');
        if (getCurrentLocationId) {
            let authPayload = {} as any;
            authPayload.ClientUrl = '/inventory/api/GetSuppliers';
            const supplierPostPayload = {} as any;
            supplierPostPayload.skip = 0;
            supplierPostPayload.take = 100;
            supplierPostPayload.fetchAll = true;
            supplierPostPayload.getTotalRecCount = true;
            supplierPostPayload.totalCount = 0;
            supplierPostPayload.searchText = '';
            supplierPostPayload.lookupField = '';
            supplierPostPayload.orderByColumn = 'Name';
            supplierPostPayload.orderByDirection = 1;
            supplierPostPayload.transTypeId = TransactionCollection.TransactionType;
            supplierPostPayload.locationId = FormGroupData?.value?.LocationId;
            supplierPostPayload.IsActive = true;
            authPayload.Payload = supplierPostPayload;
            const resultData = await httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).toPromise();
            // FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
            let mapDataDropDownArray: RadioOptions[] = []
            resultData.ResultData?.map(e => {
                const radioOptions = {} as RadioOptions;
                radioOptions.key = e['Id'];
                radioOptions.label = e['Name'];
                mapDataDropDownArray.push(radioOptions);
            });
            FormField[supplierDropdownIndex].fieldType.SingleData.options = mapDataDropDownArray;
        }
    } catch (error) {
        console.log(error);
    }
}


export interface MiscWithTaxAmount {
    MiscId: number;
    Amount: number;
    AmountInFC: number;
}

export interface MiscModel {
    Id: number;
    CategoryId: number;
    CategoryDetailId: number;
    Amount: number;
    AmountInFC: number
}