import { EventEmitter, Injectable } from '@angular/core';
import { RetailLocalization } from '../common/localization/retail-localization';
import { MatDialog } from '@angular/material/dialog';
import { ShopDialogPopUp } from './shop-dialog-popup/shop-dialog-popup.component';
import { ReplaySubject, Subject } from 'rxjs';
import { AlertMessagePopupComponent } from '../shared/alert-message-popup/alert-message-popup.component';
import { Router } from '@angular/router';
import {
  PayRequest, SaleRequest, PaymentMethod,
  HttpResponseStatus, SaleResponse, TryPayRequest, TryPayResponse, PaymentHistory, PaymentMethodIconConst, GuestStayDetail, SaleByTokenResponse, ValidatePayRequest, ValidatePayResponse, SaleByTokenRequest, TransactionPayment, TransactionPaymentAggregate, ArAccount, ARPostPaymentResponse, GuestRoomSearchResult, PaymentDetails, CardInputMethod, CardEntryModeDialogAction, CardEntryModeDialogResult, PaymentHistoryDetails, ARSenderCode, POSFinancialBin, MemberPaymentHistory, PMSIntegrationHostId, PMSXMLDBInterface, PMSHTTPInterface, POSPostingRecord, PostingRecordsAndCheckZoomDetails, ValidateCreditLimit,
  EnableZeroPosting,SourceTypeConstant,  
  TipConfigAPI,
  TipConfig,
  TipPresetValueType
} from "../shared/service/payment/payment-business.model";
import * as GlobalConst from '../shared/globalsContant';
import { HttpServiceCall, HttpMethod, ServiceParamsAsync } from "../shared/service/http-call.service";
import { BaseResponse, HandleRequest, HandleResponse, PaymentMethods, ClientCreditCardInfo, CardInfo, IDTechHandle, IDTech, PayeeInfo, Transaction, GolfPoints, PaymentErrorCodes, ScreenConstants } from '../shared/business/shared.modals';
import { RetailUtilities, RedirectToModules } from '../shared/utilities/retail-utilities';
import { CommonVariablesService, Gratuities, LineItem, RentalInfoWithAvailability, ServiceCharges, ShopRentalInfoRequest, TaxDetails } from "../shared/service/common-variables.service";
import { Validators, UntypedFormGroup } from '@angular/forms';
import { PayAgentService } from '../shared/service/payagent.service';
import * as _ from 'lodash';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { NegativeValueValidator } from '../shared/Validators/NegativeValueValidator';
import { UserMachineConfigurationService } from '../common/services/user-machine-configuration.service';
import { takeUntil } from 'rxjs/operators';
import { RetailService } from '../retail.service';
import { RetailItemType, AlertType, ClientInfo, ServiceCharge } from '../shared/shared.modal';
import { RetailSharedVariableService } from '../shared/retail.shared.variable.service';
import { RetailDataAwaiters } from '../shared/events/awaiters/retail.data.awaiters';
import { ProductPaymentMethods } from '../shared/constants/payment.type.constant';
import { CMSBusinessService } from '../shared/business/CMS-business.service';
import { CMSValidator } from '../shared/Validators/CMSValidator';
import { Voucher, CMSPlayerInfo, CMSAccountInfo, SMReturnCode } from 'src/app/common/Models/common.models';
import { GatewayConfiguration, GiftCardBalance, GiftcardSearchResult, PayHandle, PMSPostRequest, ReversalRequestParams, ServiceChargeGratuityUpdate, TaxDetailsUpdate, TransactionDetailUpdate, TransactionUpdate } from '../shared/service/payment/payment-model';
import { GiftCardBusiness } from '../sytem-config/gift-cards/gift-cards.business';
import { RetailFeatureFlagInformationService, SearchTypes } from '../shared/service/retail.feature.flag.information.service';
import { ARPostingBusinessService, TransactionInput } from '../shared/business/AR-Posting.business.service';
import { TeetimePaymentBusinessService } from '../shared/business/Teetime-Payment.business.service';
import { ReturnSettleValidator } from '../shared/Validators/ReturnSettleValidator';
import { PropertySettingDataService } from '../sytem-config/property-setting.data.service';
import { ClientMultipack, FinancialBinLevel, MultiPackReturn, ReportAPIOptions, SystemConfiguration, UserSessionConfiguration } from '../retail.modals';
import { MemberBusinessService, MemberPayeeInfo } from '../shared/business/Member-business.service';
import { CardEntryModeComponent } from '../shared/card-entry-mode/card-entry-mode.component';
import { CardPaymentMethods, ExternalGCMethods, GiftCardMethods, MemberShipMethods, PaymentAPIFlow, PaymentOptionType, RetailScreenType, RoomGrpChargeMethod, SurchargePaymentMethods, V1GiftCardMethods } from '../payment/PaymentFlowHelpers';
import { FinancialBinHelper } from '../shared/business/FinancialBin-business';
import { ClientType, CreditCardIssuerConfig, GatewayConfigurationType, Host, MiscellaneousSwitch, ReceiptFormat, ReceiptType } from '../shared/globalsContant';
import * as myGlobals from '../shared/globalsContant';
import { ReceiptBusinessService } from './receipt/business/receipt-business.service';
import { AllReports, ReceiptInputs } from './shop.modals';
import { FastReportBusinessService } from '../retail-reports/fast-report/fastreport.business.service';
import { SPAConfig } from '../common/config/SPA-config';
import { PaymentMethodBusiness } from '../retail-payment-method/payment-methods.business';
import { VoidReasonComponent } from './view-categories/void-reason/void-reason.component';
import { PlayerCategory, TransactionType } from 'src/app/common/enums/shared-enums';
import { ComponentAccessTypeLinkConfigProvider } from 'src/app/common/services/component-access-type-linking-config-provider.service';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { SurchargeConfigurationService } from '../sytem-config/data-service/surcharge-configuration.data.service';
import { RetailRoutes } from '../retail-route';
import { RetailCommunication } from '../retailcommunication.service';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { CMSRedemptionType } from 'src/app/common/Models/payment.model';
import { CreditCardConfigurationService } from '../sytem-config/credit-cards/credit-card.service';

import { WalletBusinessService } from '../shared/business/Wallet-business.service';
import { NonPMAgentRequestHandler } from '../payment/NonPMAgentRequestHandler';
import { PaymentCommunication } from '../payment-communication.service';
import { RetailValidationService } from '../shared/retail.validation.service';
import { REQUEST_TIMEOUT, RequestTimedOutException } from '../shared/business/Giftcard/rGuestPay-base.service';
import { CarddetailsPopupComponent } from '../shared/carddetails-popup/carddetails-popup.component';
import { TipConfigurationService } from '../sytem-config/data-service/tip-configuration.data.service';
@Injectable()
export class ShopBussinessService {
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  disabledRadiobool: boolean = false;
  paymentType: any;
  selectedpayment: PaymentMethod;
  paymentText: string;
  ifPaymentChoose: boolean = false;
  ifCreditCardBool: boolean = false;
  retainPaymentText: string;
  retainselectedpayment: PaymentMethod;
  selectPaymentOption: boolean = false;
  defaultSelectchecked: 0;
  selectedDeviceHandle: any = '';
  selectedCMSType: any = null;
  deviceId: number = 0;
  cardDetailsBool: any = false;
  paymentMethodSource: any = [];
  assignClientToRoom: boolean = true;
  PaymentBeforeClinet: boolean = true;
  deviceType: Array<any> = [];
  oCaptions: any = this.localization.captions;
  ShopCaptions: any = this.localization.captions.shop;
  CurrentTryPayResponse: TryPayResponse;
  SettlementHistory: Array<PaymentHistory> = [];
  tempSettlementHistory: Array<PaymentHistory> = [];
  PrevSettlementHistory: Array<PaymentHistory> = [];
  paymentMethods: Array<PaymentMethod> = [];
  paymentMethodsClone: Array<PaymentMethod> = [];
  ShowTenderField: boolean = false;
  ShowPaymentMethods: boolean = true;
  EnableCloseTranBtn: boolean = false;
  InitiateRetailTranRequest: boolean = false;
  SettledAmt: number;
  returnedTax: number
  public partialPayment: boolean = false;
  public FullPayment: boolean = true;
  remainingAmount: number;
  ShowSettlemtHist: boolean = false;
  ContinuePayment: boolean = false;
  ReturnWithTicketResponse: any;
  AllowCancel: boolean = true;
  paymentProcessing: boolean = false;
  CardOnFileforSelectedGuest: ClientCreditCardInfo;
  ConfirmationPopupShown: boolean = false;
  deviceName: string;
  GatewayConfiguration: GatewayConfiguration;
  zipcodeFormat = GlobalConst.zipcodeformat;
  CurrentActiveCard: CardInfo;
  roomDetailsArray: Array<GuestStayDetail>;
  RoomChargeTenderID: string = "3";
  disableMemberPayMethod: boolean = false;
  disableCreditBookMethod: boolean = false;
  GridItems: any = [];
  requireSignatureCapture: boolean = false;  
  get IsRefundFlow() {
    return (this._ss.finalAmount < 0);
  }
  isDefaultRoomLookUpByName: boolean = false;
  ReturnTransitionFormControlObj: UntypedFormGroup;
  GuestAccounts: any = [];
  TempRoomNo: string = '';
  FinancialBinsForRoomCharge: any;
  profitCenter = '';
  EncryptedCardData: string = "";
  ManualClosePerformed: boolean = false;
  public isCardTransactionFromRtrnTkt: boolean = false;
  IDTechCardSwipeTimeOut: any;
  cmsPlayerHandles: any[] = [];
  TransactionPayments: TransactionPayment[] = [];
  TransactionPaymentsAggregate: TransactionPaymentAggregate[] = [];
  isCMSReturnFlow: boolean = false;
  paymentHistoryDetailsForTransaction: PaymentHistory[] = [];
  selectedCMSPlayer = {
    PlayerName: "",
    PatronId: ""
  }
  selectedVoucher: Voucher;
  cmsPlayerInfo: CMSPlayerInfo;
  Vouchers: Voucher[] = [];
  giftcardBalance: Array<GiftCardBalance> = [];
  NonPayAgentMethods: PaymentMethods[] = [
    PaymentMethods.Cash,
    PaymentMethods.RainCheck,
    PaymentMethods.ARPost,
    PaymentMethods.RedeemPoint,
    PaymentMethods.CreditBook,
    PaymentMethods.OfferRedemption,
    PaymentMethods.ARAcctPost,
    PaymentMethods.CompSlipRedemption
  ];
  GiftCardMethods: PaymentMethods[] = [
    PaymentMethods.V1GiftCard,
    PaymentMethods.V1GiftCardIdTech,
    PaymentMethods.ExternalGiftCard,
    PaymentMethods.ExternalGiftCardIdTech,
    PaymentMethods.AgilysysGiftCard,
    PaymentMethods.AgilysysGiftCardIdTech
  ];
  V1GiftCardMethods = [
    PaymentMethods.V1GiftCard,
    PaymentMethods.V1GiftCardIdTech
  ]
  selectedGiftCard: GiftcardSearchResult;
  giftcardCaptions: any = this.localization.captions.shop.GiftCard;
  cardPaymentMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH];
  showAllPaymentMethods = false;
  IsRevenueItemIncluded: boolean;
  selectedGuestRoom: GuestRoomSearchResult = null;
  returnOriginalPaymentOnly: boolean = false;
  isIGRetail: boolean = false;
  defaultCardInputMethod: CardInputMethod;
  preferredInputSelectedBanner: string = "";
  userSessionConfiguration: UserSessionConfiguration = null;
  roomChargePostingPayMethodId: number = 0;
  quickIdUser: string = '';
  SelectedRoomNo: string = "";
  isCheckZoomEnabled: boolean = false;
  patchTotalAmountInCashPopup: boolean = true;
  selectRoomFromResult = new EventEmitter<boolean>();
  scheduledTeeTimeIds: any[] = [];
  isMultipleMemberTransaction = false;
  currentAmountTendered = 0;
  get IsShift4Giftcard(): boolean { return this.giftcardBusiness.IsShift4Giftcard; }
  get showPreferredCardEntryBanner() {
    return (this.GatewayConfiguration
      && this.GatewayConfiguration.cardInputMethod == CardInputMethod.All
      && this.GatewayConfiguration.preferredCardEntryMode > 0
      && this.selectedDeviceHandle)
  }
  LinkedTransaction: Transaction = null;
  functionalities: { [key: string]: boolean } = {};
  terminalList: any;
  CEDS_ByTerminal = "";
  canShowWalletInput: boolean = true;
  GatewayConfig = {
    isPartialPayAllowed: false,
    isAVSActive: false
  }
	taxExemptCalcTriggered: boolean = false;
  nonIntegratedPaytransId: number = 0;
  isNonIntegratedCreditCard:boolean = false;

  get IsFolioPaymentMethod() { return this.functionalities.IsFolioPaymentMethod; }

  get isGiftCardSelected() {
    return this.GiftCardMethods.includes(this.selectedPaymentMethod);
  }

  get isWalletSelected() {
    return this.selectedPaymentMethod == PaymentMethods.Wallet;
  }

  get isThirdPartyGiftcardSelected() {
    return this.selectedpayment && ExternalGCMethods.includes(this.selectedPaymentMethod);
  }

  get IsARPostorCreditBook() {
    return (this.SelectedPaymentMethodEquals(PaymentMethods.ARPost)
      || this.SelectedPaymentMethodEquals(PaymentMethods.CreditBook))
  }

  public get IsCMSPayment() {
    return (this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption)
      || this.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption)
      || this.SelectedPaymentMethodEquals(PaymentMethods.CompSlipRedemption));
  }

  public get IsCMSRefund() {
    return (this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) && this._ss.finalAmount < 0);
  }

  public get IslinkedtransactionVoidedOrCorrected() {
    if (this._ss.selectedTransaction && this._ss.selectedTransaction.transactionLinkId == 0) {
      return true;
    }
    else if (this._ss.selectedTransaction && this._ss.selectedTransaction.transactionLinkId != 0) {
      if (this.LinkedTransaction != null && this.LinkedTransaction.id == this._ss.selectedTransaction.transactionLinkId) {
        return this.LinkedTransaction.transactionData.isVoided
      }
    }
    return false
  }
  public get IsCompleteReturn() {
    return ((this.SettlementHistory && this.SettlementHistory.length == 0)
      && (this._ss.selectedTransaction && this._ss.selectedTransaction.transactionLinkId == 0 || this.IslinkedtransactionVoidedOrCorrected)
      && (this._ss.selectedReturnedRetailProducts.length == this._ss.returnWithticketItems.length)
      && (this._ss.selectedExchangeRetailProducts.length == 0 && this._ss.selectedRetainedRetailProducts.length == 0)
     ) // All the items were returned => COMPLETE REFUND
  }
  get IsFullPaymentWithRoomCharge() {
    return (this.SelectedPaymentMethodEquals(PaymentMethods.RoomCharge) && !this.partialPayment);
  }

  get IsRefundToCOF() { return (this.IsRefundFlow && this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)) }

  selectedARAccount: ArAccount;
  currentARPostResponse: ARPostPaymentResponse;
  MethodsWhichHasPaymentConfirmationPopup = [
    PaymentMethods.Cash,
    PaymentMethods.CardOnFile,
    PaymentMethods.V1GiftCard,
    PaymentMethods.V1GiftCardIdTech,
    PaymentMethods.ExternalGiftCard,
    PaymentMethods.ExternalGiftCardIdTech,
    PaymentMethods.AgilysysGiftCard,
    PaymentMethods.AgilysysGiftCardIdTech
  ];
  _isAPICallInProgress = false;
  get isCustomPaymentMethod() {
    return this.selectedpayment && this.selectedpayment.paymentTypeId > 0 && this.selectedpayment.parentTypeId == 0 && !this.selectedpayment.isCloned && !(this.selectedpayment.paymentTypeId in PaymentMethods);
  }
  AgilysysGCMethods = [PaymentMethods.AgilysysGiftCard, PaymentMethods.AgilysysGiftCardIdTech];
  private get SkipRoomChargePosting() {

    const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));

    return (this.selectedpayment && this.IsRoomOrGroupCharge
      && (this._featureFlagInfo?.PMSSystem?.toLowerCase().trim() == GlobalConst.VISUALONE
        || this._featureFlagInfo?.PMSSystem?.toLowerCase().trim() == GlobalConst.VISUAL_ONE
        || propConfig?.PMSSystem?.toLocaleLowerCase().trim() == GlobalConst.V1.toLocaleLowerCase().trim()
        || propConfig?.PMSSystem?.toLocaleLowerCase().trim() == GlobalConst.VERSA.toLocaleLowerCase().trim()));
  }
  get isVisualOneRevenuePosting() {
    const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    return (this._featureFlagInfo?.PMSSystem?.toLowerCase().trim() == GlobalConst.VISUALONE
      || this._featureFlagInfo?.PMSSystem?.toLowerCase().trim() == GlobalConst.VISUAL_ONE)
      || propConfig?.PMSSystem?.toLowerCase().trim() == GlobalConst.V1.toLowerCase()
  }

  get IsOfferOrCompSlipPaymentMethod() {
    return (this.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption) || this.SelectedPaymentMethodEquals(PaymentMethods.CompSlipRedemption));
  }

  get selectedPaymentMethod(): PaymentMethods { return this.selectedpayment?.isCloned ? this.selectedpayment?.parentTypeId : this.selectedpayment?.paymentTypeId }

  get IsRoomOrGroupCharge() { return this.selectedPaymentMethod && RoomGrpChargeMethod.includes(this.selectedPaymentMethod) }
  get IsHotelCompPayment() { return this.selectedPaymentMethod == PaymentMethods.HotelComp}


  get BlockPartialRefund() {
    return (!this.IsCompleteReturn && this.paymentHistoryDetailsForTransaction?.filter(f => f.amount >= 0).length > 1)
  }

  get validateCreditLimitForRoomGroupCharge(){
		const additionalConfig =  this.selectedpayment.additionalConfigurations ? JSON.parse(this.selectedpayment.additionalConfigurations) : null;
    const config = additionalConfig?.find(x => x.Key.toUpperCase().trim()  === ValidateCreditLimit) ;
		const validateCreditLimit = config?.Value ?? '';
		return this.selectedpayment && this.IsRoomOrGroupCharge && additionalConfig ?  validateCreditLimit.trim().toLowerCase() == 'true': false;
	}
  
  hostName: string = '';
  ipAddress: string = '';
  agentVersion: string = '';
  noOfReceipts: number = this._paymentMethodBusiness.defaultNoOfReceipt;
  requireReceipts: boolean = false;
  patchRemainingAmt: Subject<number> = new Subject<number>();
  isComponentAccessLinkingFilterApplied = false;
  paymentMethodsBeforeCompAccessValidation: PaymentMethod[] = [];
  playerDetails: PayeeInfo[] = [];
  isMultipleLinkedItem: boolean = false;
  isDefaultRefundEnabled: boolean = false;
  returnTransitionDetails: UntypedFormGroup;
  private _originalTotalAmount = 0;
  miscSetting: Promise<SystemConfiguration[]>;
  ScreenName: RetailScreenType = RetailScreenType.Shop;
  get OriginalTotalAmount() { return this._originalTotalAmount; }
  setOriginalTotalAmount(val) {
    this._originalTotalAmount = val;
  }
  refreshTotal = new EventEmitter<number>();
  isRefreshShopServiceItems = new EventEmitter<boolean>();
  isTaxExempted = new EventEmitter<boolean>();
  returnItemsListModified = new EventEmitter<boolean>();
  manualEmailId: any[] = [];
  amountBeforeRecalcDiscTax = 0;
  tipConfig: TipConfig = null;
  isTipApplicable = false;
  get SkipPMAgent() {
    return this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment);
  }
  get PaymentProcessor() {
    return this.SkipPMAgent ? this._nonPMAgentHandler : this.payAgentService;
  }
	get IsShopScreen() { return (this.ScreenName == RetailScreenType.Shop); }

  constructor(public localization: RetailLocalization,
    public dialog: MatDialog,
    public router: Router,
    private http: HttpServiceCall,
    public utils: RetailUtilities,
    public _ams: RetailService,
    public _ss: CommonVariablesService,
    private payAgentService: PayAgentService,
    public PropertyInfo: RetailPropertyInformation,
    private userMachineConfigurationService: UserMachineConfigurationService,
    public retailService: RetailSharedVariableService,
    private cmsBusinessService: CMSBusinessService,
    public giftcardBusiness: GiftCardBusiness,
    private _featureFlagInfo: RetailFeatureFlagInformationService,
    private _arPostingBusiness: ARPostingBusinessService,
    private _teetimePaymentBusiness: TeetimePaymentBusinessService,
    private _propertySettingDataService: PropertySettingDataService,
    private _memberService: MemberBusinessService,
    private _financialBinHelper: FinancialBinHelper,
    private _receiptService: ReceiptBusinessService,
    private _reportService: FastReportBusinessService,
    private spaConfig: SPAConfig,
    private _paymentMethodBusiness: PaymentMethodBusiness
    , private _componentAccessConfigService: ComponentAccessTypeLinkConfigProvider
    , private func: RetailFunctionalityBusiness
    , private _surchargeConfigService: SurchargeConfigurationService
    , private _retailCommunication: RetailCommunication
    , public _utils: RetailUtilities
    , private _propertyInfo: CommonPropertyInformation
    , private creditCardConfigurationService: CreditCardConfigurationService
    , private _walletBusiness: WalletBusinessService
    , private _nonPMAgentHandler: NonPMAgentRequestHandler
    , private _paymentCommunication: PaymentCommunication
    , private retailValidationService: RetailValidationService
    , private _tipConfigurationService: TipConfigurationService
  ) {
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      if (this.functionalities.ShowMultipleLinkedRetailItemForMultipack) {
        this.isMultipleLinkedItem = true;
      }
    });
    this.getMiscConfig();

  }
  SelectedPaymentMethodEquals(method: PaymentMethods) {
    return (this.selectedpayment.paymentTypeId === method || this.selectedpayment.parentTypeId === method)
  }
  async getMiscConfig() {
    if (this._ss.defaultRefundToOriginal && this._ss.defaultRefundToOriginal === true) {
      this.isDefaultRefundEnabled = true;
      this.returnTransitionDetails.controls.refundToOriginal.setValue('true');
    }
  }

	setTenderedAmount(val)
	{
		this.returnTransitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(val));
		this.returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
		this.currentAmountTendered = val;
    this.amountBeforeRecalcDiscTax = val;
	}

  async recalculateTicketBasedOnTender(amount, compScope: any, isPaymentModeChanged: boolean = false) {
    if (!this._ss.OriginalTicket || this.paymentText == this.oCaptions.shop.CloseTransaction) {
      return;
    }
    if(!this.IsRefundFlow && this.selectedpayment && this.selectedpayment.id > 0 && this.IsShopScreen){
      const autoRemovetax = (!this._ss.isTaxExempted && this.selectedpayment.isAutoRemoveTax);
      let amountTendered = this.localization.currencyToSQLFormat(amount);
      if ((amountTendered > 0) && (this.selectedpayment.tenderReducesDiscount || autoRemovetax)) {
        const calcBasedOnOriginalTicket = isPaymentModeChanged || !this._ss.isamountRecalcForDiscTax || this.taxExemptCalcTriggered;
        if (calcBasedOnOriginalTicket && this.SettledAmt != 0) {
          let OriginalAmount  = this._ss.isTaxExempted ?   this._ss.Ticket.checkData.totalAmountWithOutTax - this._ss.returnItemTotal   :  this._ss.Ticket.checkData.totalAmount - this._ss.returnItemTotal;
          amountTendered = OriginalAmount - this.SettledAmt;
        } else if (calcBasedOnOriginalTicket) {
          amountTendered =  this._ss.isTaxExempted ? this._ss.OriginalTicket.checkData.totalAmountWithOutTax - this._ss.returnItemTotal : this._ss.OriginalTicket.checkData.totalAmount - this._ss.returnItemTotal;
        }
        if ((amountTendered > 0)  && (this.amountBeforeRecalcDiscTax != amountTendered || calcBasedOnOriginalTicket)) {
          this._ams.loaderEnable.next(this.ShopCaptions.TransactionInprogressMsg);
          this.amountBeforeRecalcDiscTax = Number(amountTendered.customToFixed());
          try {
            const [netTenderReducesDiscountSettledAmount, netTenderReducesTaxSettledAmount] = this._ss.getNetTenderReducesSettledAmount(this.SettlementHistory);
            const [settledDiscountAmount, settledTaxAmount] = this.getReturnedNetTenderReducesSettledAmount(this.TransactionPaymentsAggregate.map(x => x.transactionPayment));
            await this._ss.ReCalculateTicketBasedOnTender(this.selectedpayment, amountTendered, netTenderReducesDiscountSettledAmount, netTenderReducesTaxSettledAmount, true, settledDiscountAmount ,settledTaxAmount );
            if (calcBasedOnOriginalTicket) {
              const taxamount = this._ss.isTaxExempted ?  this._ss.Ticket.checkData.totalAmountWithOutTax  : this._ss.Ticket.checkData.totalAmount;
              const result = taxamount - this.SettledAmt - this._ss.returnItemTotal;
              this.amountBeforeRecalcDiscTax = Number(result.customToFixed());
            }
            this.taxExemptCalcTriggered = false;
            this.refreshShopServiceItems(compScope, this.amountBeforeRecalcDiscTax);
          }
          catch (error) {
            this.disableLoader();
          }
          finally {
            this.disableLoader();
          }
        }
      } else if (isPaymentModeChanged) {
        this.resetTenderBasedCheckCalc(compScope);
      }
    }
  }

  getReturnedNetTenderReducesSettledAmount(SettlementHistory: Array<TransactionPayment> = []): [number , number] {
    const tenderReducesDiscountSettledPayments = SettlementHistory.filter(x => x.discountExemptRatio != 0 && !x.doNotDisplay);
    let netTenderReducesDiscountSettledAmount = 0;
    if (tenderReducesDiscountSettledPayments?.length > 0) {
      netTenderReducesDiscountSettledAmount = _.sumBy(tenderReducesDiscountSettledPayments, x => { return x.paymentAmount });
    }

    const tenderReducestaxSettledPayments = SettlementHistory.filter(x => x.taxExemptRatio != 0 && !x.doNotDisplay);
    let netTenderReducesTaxSettledAmount = 0;
    if (tenderReducestaxSettledPayments?.length > 0) {
      netTenderReducesTaxSettledAmount = _.sumBy(tenderReducestaxSettledPayments, x => { return x.paymentAmount });
    }
    return [netTenderReducesDiscountSettledAmount, netTenderReducesTaxSettledAmount];
  } 


  resetTenderBasedCheckCalc(compScope) {
      if (!this.selectedpayment.tenderReducesDiscount && !this.selectedpayment.isAutoRemoveTax && this.SettledAmt == 0) {
        this._ss.Ticket = _.cloneDeep(this._ss.OriginalTicket);
        this.isRefreshShopServiceItems.emit(true);
      } else if (!this.selectedpayment.tenderReducesDiscount && !this.selectedpayment.isAutoRemoveTax && this.SettledAmt != 0) {
        if (this._ss.TempTicket != null) {
          this._ss.Ticket = _.cloneDeep(this._ss.TempTicket);
          this.refreshShopServiceItems(compScope,this.remainingAmount);
        }
      }
  }

  refreshShopServiceItems(compScope, amount :number){
    this.isRefreshShopServiceItems.emit(true);
    this.returnTransitionDetails.controls.amounttendered.setValidators([
      Validators.max(amount),
      Validators.required
    ]);
    compScope.PatchRemainingAmountAndHighlight(amount, true);
    setTimeout(() => {
      this._ams.loaderEnable.next('');
    }, 1000);
  }

  disableLoader(){
    setTimeout(() => {
      this._ams.loaderEnable.next('');
    }, 1000);
  }

  async selectpaymentMethod(e: PaymentMethod, compScope: any, isOnLoad = false) {
    if (this._ss.finalAmount == 0 && this._ss.memberCardNumber == "0") {
      const cashMethod = this.paymentMethods.find(x => x.paymentTypeId == PaymentMethods.Cash && x.isActive);
      if (this._ss.hasAccessSettleCash && cashMethod) {
        e = {
          id: cashMethod.id,
          paymentTypeId: cashMethod.paymentTypeId,
          parentTypeId: cashMethod.parentTypeId,
          paymentMethod: cashMethod.paymentMethod,
          allowEarn: cashMethod.allowEarn,
          noOfReceipts: cashMethod.noOfReceipts,
          requireReceipts: cashMethod.requireReceipts,
          isCloned: cashMethod.isCloned,
          isAutoRemoveTax: cashMethod.isAutoRemoveTax,
          tenderReducesDiscount: cashMethod.tenderReducesDiscount
        };
      }
    }
    this.isCardTransactionFromRtrnTkt = false;
    const paymentTypeId = !e.isCloned ? e.paymentTypeId : e.parentTypeId;
    switch (paymentTypeId) {
      case PaymentMethods.CreditCard:
        if (this.IsRefundFlow && this.CheckIfCreditToGuestIsAllowed(this.GatewayConfiguration)) return;
        this.isCardTransactionFromRtrnTkt = true;
        if (!this._ss.hasAccessSettleCC) {
          this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToCreditCard)
          return
        }
        break;
      case PaymentMethods.RoomCharge:
        if (!this._ss.hasAccessSettleRC) {
          this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToRoomCharge)
          return
        }
        break;
      case PaymentMethods.GroupCharge:
        if (!this._ss.hasAccessSettleGC) {
          this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SETTLETOGROUPCHARGE)
          return
        }
        break;
      case PaymentMethods.HotelComp:
        if (!this._ss.hasAccessSettleHC) {
           this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SETTLETOGHOTELCOMP)
           return
        }
          break;
      case PaymentMethods.Cash:
        if (!this._ss.hasAccessSettleCash) {
          this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToCash)
          return
        }
        break;
      case PaymentMethods.NonIntegratedCreditCard:
        compScope.isEnablePaymentComment = true;
        this.isNonIntegratedCreditCard = true;
        if (!this._ss.hasAccessSettleNonIntegratedPaymentMethod) {
          this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToNonIntegratedCreditCards);
          return;
        }
        break;
      case PaymentMethods.PostToFolio:
        if (!this._ss.hasAccessSettlePostToFolio) {
          this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToPostToFolio)
          return
        }
        break;
      case PaymentMethods.CardOnFile:
        if (this.IsRefundFlow && this.CheckIfCreditToGuestIsAllowed(this.GatewayConfiguration)) return;
        this.isCardTransactionFromRtrnTkt = true;
        if (!this._ss.hasAccessSettleCC) {
          this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToCreditCard)
          return
        }
        break;
      case PaymentMethods.CompRedemption: //TO-DO
        //Break point code goes here
        break;
      case PaymentMethods.OfferRedemption: //TO-DO
        //Break point code goes here
        break;

      case PaymentMethods.ARAcctPost:
        if (!this._ss.hasAccessSettleAR) {
          this._ss.showBPMessage(GlobalConst.RetailBreakPoint.ALLOWARPAYMENTS)
          return;
        }

        if (!this.profitCenter) {
					await this._ss.SetAllOutletsData();
					this.profitCenter = await this._ss.GetCurrentProfitCenter(Number(this._ss.SelectedOutletId));
				}
        break;

    }

    if (e.paymentTypeId > 0 && !(e.paymentTypeId in PaymentMethods) && !this._ss.hasAccessSettleOthers) {
      this._ss.showBPMessage(GlobalConst.RetailBreakPoint.SettleToOthers);
      return;
    }

    //this.AutoScrollToBottom();
    
    if (compScope.isSurchargeApplied) {
      compScope.ResetSurchargeFlags();
    }
    this.paymentType = e;
    this.selectedpayment = _.cloneDeep(e);
    this.setTenderedAmount(this._ss.remainingAmountAfterPayment);
    this._ss.isamountRecalcForDiscTax = false;
    this.recalculateTicketBasedOnTender(compScope.returnTransitionDetails.controls.amounttendered.value,compScope, true);
    this.ifPaymentChoose = true;
    compScope.returnTransitionDetails.controls.amounttendered.patchValue('');
    compScope.returnTransitionDetails.controls.amounttendered.enable();
    compScope.returnTransitionDetails.controls.selectedDevice.patchValue('');
    compScope.returnTransitionDetails.controls.zipcode.patchValue('');
    compScope.returnTransitionDetails.controls.roomnumber.patchValue('');
    compScope.CMSForm?.controls.cmsPin.setValue('');

    this.EnableCloseTranBtn = false;
    this.cardDetailsBool = false;
    this.CurrentTryPayResponse = null; // Reset try pay response
    this.retainPaymentText = this.paymentText;
    this.retainselectedpayment = this.selectedpayment;
    this.selectedDeviceHandle = '';
    this.selectedCMSType = null;
    this.EncryptedCardData = '';
    this.cmsPlayerHandles = [];
    this.isCMSReturnFlow = false;
    this.Vouchers = [];
    this.selectedCMSPlayer = { PlayerName: '', PatronId: '' };
    this.selectedVoucher = null;
    this.cmsPlayerInfo = null;
    this.selectedGiftCard = null;
    this.currentARPostResponse = null;
    this.selectedARAccount = null;
    this.CalculateRemainingAmount(compScope.returnTransitionDetails);
    this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
    this.selectedGuestRoom = null;
    this.SelectedRoomNo = this.roomDetailsArray && this.roomDetailsArray?.length == 1 ? this.roomDetailsArray[0].roomNo : "";
    this.canShowWalletInput = true;
    compScope.PatchRemainingAmountAndHighlight(this.remainingAmount, true);
    this.currentAmountTendered = this.remainingAmount;
    compScope.ResetSurchargeFlags();
    this.CurrentActiveCard = null;

    switch (paymentTypeId) {
      case PaymentMethods.Cash:
        this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
        this.EnableCloseTranBtn = true;
        break;
      case PaymentMethods.PendingSettlement:
        this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
        compScope.returnTransitionDetails.controls.amounttendered.disable();
        this.EnableCloseTranBtn = true;
        break;
      case PaymentMethods.CreditCard:
        this.ifCreditCardBool = true;
        this.isNonIntegratedCreditCard = false;
        this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.ProceedRefund : this.oCaptions.shop.Proceed;
        compScope.returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
        compScope.returnTransitionDetails.controls.selectedDevice.updateValueAndValidity();
        if (!this.IsFolioPaymentMethod)
          this.GetHandles(compScope.returnTransitionDetails, compScope, null, isOnLoad);
        compScope.setTerminalDefault();
        compScope.IsSurchargeApplicableMethod ? compScope.cardIssuerTypes = compScope.allCardIssuerTypes.filter(x=> x.paymentMethodId === PaymentMethods.CreditCard ):[]
        break;
      case PaymentMethods.IDTECH:
        compScope.IsSurchargeApplicableMethod ? compScope.cardIssuerTypes = compScope.allCardIssuerTypes.filter(x=> x.paymentMethodId === PaymentMethods.CreditCard ):[]
        break;
      case PaymentMethods.RoomCharge:
      case PaymentMethods.GroupCharge:
      case PaymentMethods.HotelComp:
        if (!this.profitCenter) {          
          await this._ss.SetAllOutletsData();
          let config = await this._ss.GetRoomChargeConfigByOutlet(Number(this._ss.SelectedOutletId));
          this.profitCenter = config.profitCenter;
          this.roomChargePostingPayMethodId = config.paymentMethodId;
        }
        this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
        this.PrefillValuesBasedOnResortFinanceInput(compScope);
        this.selectRoomFromResult.emit(true);
        break;
      case PaymentMethods.CardOnFile:
        this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
        this.isNonIntegratedCreditCard = false;
        this.CurrentActiveCard = await this.payAgentService.GetCardInfo(this.CardOnFileforSelectedGuest.tokenTransId);
        this.CurrentActiveCard.cardNumber = this.payAgentService.MaskCreditCardNumber(this.CurrentActiveCard.cardNumber);
        //Auto Select card type config based on issuerType
        compScope.selectedIssuerType = compScope.allCardIssuerTypes.find(c => c.type == this.CurrentActiveCard?.issuerType && c.paymentMethodId === PaymentMethods.CreditCard);
        if (this.CurrentActiveCard.accessUrl) {
          compScope.isEnablePaymentComment = true;
          compScope.selectedIssuerType = compScope.allCardIssuerTypes.find(c => c.type == this.CurrentActiveCard?.issuerType && c.paymentMethodId === PaymentMethods.NonIntegratedCreditCard);
        }
        compScope.ApplySurcharge(compScope.AmtForSurchargeCalc);
        break;
      case PaymentMethods.CompRedemption:
      case PaymentMethods.OfferRedemption:
      case PaymentMethods.CompSlipRedemption:
        if (this.IsOfferOrCompSlipPaymentMethod) {
          compScope.returnTransitionDetails.controls.amounttendered.disable();
        }
        if (!this.IsRefundFlow) {
          compScope.CMSForm.patchValue({
            cmsSearch: this._ss.selectedclient && this._ss.selectedclient.patronId ? this._ss.selectedclient.patronId : '',
            cmsPaymentSelection: '',
            voucherSelection: '',
            cmsPin: ''
          });
          this.searchCMSPlayer(compScope);
        } else {
          let resultData = this.cmsBusinessService.CheckIfOfferOrCompSlipCanBeAppliedAndPrefill(this);
          if (!resultData[0]) {
            this.ResetSelectedPaymentMethod();
            return;
          }
          compScope.returnTransitionDetails.controls.amounttendered.patchValue(resultData[1] ? -resultData[1] : '');
          compScope.returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
          this.EnableCloseTranBtn = true;
        }
        break;
      case PaymentMethods.ARPost:
      case PaymentMethods.CreditBook:
      case PaymentMethods.RedeemPoint:
        
        if (!this.profitCenter) {          
          await this._ss.SetAllOutletsData();
          this.profitCenter = await this._ss.GetCurrentProfitCenter(Number(this._ss.SelectedOutletId));
        }

        let res = this._memberService.CheckIfMemberPaymentsCanBeRefunded(this);
        if (!res[0]) {
          this.ResetSelectedPaymentMethod();
          return;
        }
        compScope.returnTransitionDetails.controls.amounttendered.patchValue(res[1] ? -res[1] : '');
        compScope.returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
        this.EnableCloseTranBtn = true;
        break;
      case PaymentMethods.PostToFolio:
        this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
        compScope._folioBusiness.openFolioDialog(null, compScope, ScreenConstants.RETURN);
        break;
      case PaymentMethods.Wallet:
        if (this.IsRefundFlow) {
          let result = this._walletBusiness.CheckIfWalletCanBeAppliedAndPrefill(this);
          if (!result[0]) {
            this.ResetSelectedPaymentMethod();
            return;
          }
          compScope.returnTransitionDetails.controls.amounttendered.disable();
          this.canShowWalletInput = false;
          compScope.returnTransitionDetails.controls.amounttendered.patchValue(result[1] ? -result[1] : '');
          compScope.returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
          this.EnableCloseTranBtn = true;
        }
        else {
          this.canShowWalletInput = true;
        }
        break;
      default:
        this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.Refund : this.oCaptions.shop.Pay;
        break;
    }
  }

  async searchCMSPlayer(compScope, isOnLoad = false) {
    if (compScope.CMSForm.controls['cmsSearch'] && compScope.CMSForm.controls['cmsSearch'].value) {
      let patronId = compScope.CMSForm.controls['cmsSearch'].value;
      this.selectedDeviceHandle = '';
      this.selectedCMSType = null;
      this.cmsPlayerHandles = [];
      this.selectedCMSPlayer = { PlayerName: '', PatronId: '' };
      compScope.CMSForm.patchValue({ cmsPaymentSelection: '', voucherSelection: '' });
      if (this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption)) {
        if (this._featureFlagInfo.SkipPMAgentCMS) {
          let loaderMsg = {
            [PaymentMethods.CompRedemption]: this.ShopCaptions.RetrievePatronDetails
          };
          this._ams.loaderEnable.next(loaderMsg[this.selectedPaymentMethod]);
          try {
            let accountInfo: CMSAccountInfo = await this.cmsBusinessService.GetAccountInfo(patronId, compScope.CMSForm.controls['cmsPin'].value, this.selectedpayment?.paymentTypeId, true);
            if (accountInfo?.returnCode == SMReturnCode.Success) {
              this.cmsBusinessService.CreatePatronGuest(patronId);
              let pointsDetails: PayHandle = {
                playerId: patronId,
                playerPin: compScope.CMSForm.controls['cmsPin'].value,
                name: accountInfo.accountName,
                type: CMSRedemptionType[CMSRedemptionType.Points],
                balance: accountInfo.cmsBalanceDetails.compRedemtion.points.availablePointsInDollars,
                handle: "",
                inquiryInfo: null,
                isBalanceVisible: false,
                isPartialTenderAllowed: false,
                isRefundable: false,
                additionalAttributes: null,
                allowedAPIs: [],
                referenceNumber1: "",
                referenceNumber2: "",
              }
              let compsDetails: PayHandle = {
                playerId: patronId,
                playerPin: compScope.CMSForm.controls['cmsPin'].value,
                name: accountInfo.accountName,
                type: CMSRedemptionType[CMSRedemptionType.Comps],
                balance: accountInfo.cmsBalanceDetails.compRedemtion.comps.availableCompInDollars,
                handle: "",
                inquiryInfo: null,
                isBalanceVisible: false,
                isPartialTenderAllowed: false,
                isRefundable: false,
                additionalAttributes: null,
                allowedAPIs: [],
                referenceNumber1: "",
                referenceNumber2: "",
              }
              this.cmsPlayerHandles.push(pointsDetails, compsDetails);
              this.cmsPlayerHandles = this.FilterCMSApplicableBuckets(this.cmsPlayerHandles);
              this.selectedCMSPlayer = {
                PlayerName: accountInfo.accountName,
                PatronId: patronId
              }
            }
            else {
              let notFoundMsg = {
                [PaymentMethods.CompRedemption]: this.localization.captions.shop.NoPlayersFound
              };
              if (!isOnLoad) {
                this.utils.ShowErrorMessage(
                  this.localization.captions.common.Error,
                  accountInfo.message ? accountInfo.message
                    : notFoundMsg[PaymentMethods.CompRedemption]

                );
              }
              this.EnableCloseTranBtn = false;
            }
            setTimeout(() => {
              this._ams.loaderEnable.next('');
            }, 1000);
            //this._pblh.GetHandles(isOnLoad);
          }
          catch (error) {
            console.error("exception occurred while fetching player details:" + error?.message);
            this.utils.ShowErrorMessage(
              this.localization.captions.common.Error,
              this.localization.captions.shop.NoPlayersFound
            );
            this._ams.loaderEnable.next('');
          }
        }
        else {
          this.GetHandles(compScope.returnTransitionDetails, compScope, null, isOnLoad);

        }
      } else {
        this._ams.loaderEnable.next(this.ShopCaptions.RetrievePatronDetails);
        this.cmsPlayerInfo = null;
        try {
          let playerInfo: CMSPlayerInfo = await this.cmsBusinessService.GetCMSPlayerInfo(
            compScope.CMSForm.controls['cmsSearch'].value
            , this.selectedpayment?.paymentTypeId
            , compScope.CMSForm.controls['cmsPin'].value
            , true
          );
          if (playerInfo) {
            console.log(playerInfo);
            let patronId = compScope.CMSForm.controls['cmsSearch'].value;
            let vouchers = playerInfo.cmsBalanceDetails.offerRedemption.vouchers;
            if (vouchers && vouchers.length && vouchers.find(x => x.vendorType.toUpperCase() == this._featureFlagInfo.GetCMSSiteId.toUpperCase())) {
              this.cmsBusinessService.CreatePatronGuest(patronId);
            }
            this.cmsPlayerInfo = playerInfo;
            this.selectedCMSPlayer = {
              PlayerName: playerInfo.personalDetails ? `${playerInfo.personalDetails.firstName} ${playerInfo.personalDetails.lastName}` : "",
              PatronId: playerInfo.playerID
            };
            this.Vouchers = playerInfo.cmsBalanceDetails.offerRedemption.vouchers;
          } else {
            if (!isOnLoad) {
              this.utils.ShowErrorMessage(
                this.localization.captions.common.Error,
                this.localization.captions.shop.NoPlayersFound
              );
            }
          }
          setTimeout(() => {
            this._ams.loaderEnable.next('');
          }, 1000);
        } catch (error) {
          console.error("exception occurred while fetching player details:" + error?.message);
          this.utils.ShowErrorMessage(
            this.localization.captions.common.Error,
            this.localization.captions.shop.NoPlayersFound
          );
          this._ams.loaderEnable.next('');
        }
      }
    }
  }

  async ProceedCardOnFile(result: string, compScope) {
    if (result.toLowerCase() == GlobalConst.ButtonOptions.Yes.toLowerCase()) {
      if (compScope != '' && this.remainingAmount == this.getSaleAmt() && !this.ReturnWithTicketResponse) {
        this.ConfirmationPopupShown = true;
        compScope.ReturnwithTicket();
        return;
      }
      this.ConfirmationPopupShown = true;
      this.InitiateSaleReqBasedOnPaymentMethod(compScope.returnTransitionDetails, this.getSaleAmt(), compScope);
    } else {
      this.EnableCloseTranBtn = false;
      this.ResetSelectedPaymentMethod();
    }
  }

  async proceedForAdyenGatewayPayment(compScope, promptForPreferenceOverride = false) {
    if (this.GatewayConfiguration.cardInputMethod === CardInputMethod.All && this.GatewayConfiguration.preferredCardEntryMode == 0 || promptForPreferenceOverride) {
      const dialogRef = this.openCardEntryModeSelectionPopup();
      dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((result: CardEntryModeDialogResult) => {
        if (result.action === CardEntryModeDialogAction.Proceed) {
          this.defaultCardInputMethod = result.cardInputMethod;
          this.ConfirmationPopupShown = true;
          if (compScope != '' && !this.ReturnWithTicketResponse) {
            compScope.ReturnwithTicket();
            return;
          }
          this.MakeSale(compScope, compScope.returnTransitionDetails);
        } else {
          // Card Entry Mode dialog action cancelled
          this.ConfirmationPopupShown = false;
        }
      });
    } else {
      this.defaultCardInputMethod = this.GatewayConfiguration.preferredCardEntryMode;
      this.ConfirmationPopupShown = true;
      if (compScope != '' && !this.ReturnWithTicketResponse) {
        compScope.ReturnwithTicket();
        return;
      }
      this.MakeSale(compScope, compScope.returnTransitionDetails);
    }
  }

  openCardEntryModeSelectionPopup() {
    return this.dialog.open(CardEntryModeComponent, {
      width: '550px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.oCaptions.common.Warning,
        headerIcon: 'icon-warning-icon',
        headerMessage: this.oCaptions.shop.CorrectionTransactionMsg,
        buttonName: this.oCaptions.common.Yes,
        noButton: true,
        noButtonName: this.oCaptions.common.No,
        type: 'message'
      },
      disableClose: true
    });
  }

  isManualKeyInCardEntryMode(): boolean {
    let isManualEntry = false;
    if (this.GatewayConfiguration &&
      this.GatewayConfiguration.payGatewayID &&
      this.GatewayConfiguration.payGatewayID.trim().toLowerCase() === 'adyen') {
      if (this.GatewayConfiguration.cardInputMethod !== CardInputMethod.All) {
        this.defaultCardInputMethod = this.GatewayConfiguration.cardInputMethod;
      }
      isManualEntry = this.defaultCardInputMethod === CardInputMethod.KeyIn;
    }
    return isManualEntry;
  }

  getZipCodeValue(returnTransitionDetails) {
    let ZipcodeValue = '';
    if (returnTransitionDetails.controls.zipcode.value) {
      ZipcodeValue = returnTransitionDetails.controls.zipcode.value;
    }
    return ZipcodeValue;
  }

  FormSaleByTokenRequestBody(returnTransitionDetails, CompScope): SaleByTokenRequest {
    // let checkData = JSON.parse(this.ReturnWithTicketResponse.transactionData.checkData);
    let SaleAmount: number = 0;
    if (this.partialPayment) {
      let AmountTendered = this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value);
      SaleAmount = AmountTendered < 0 ? AmountTendered * -1 : AmountTendered;
    } else {
      SaleAmount = this.getSaleAmt(true);
    }

    return {
      inquirerInfo: {
        terminalId: "1",
        orderNumber: this.ReturnWithTicketResponse?.transactionData?.retailTicketNumber,
        profitCenter: "0",
        zipcode: this.GatewayConfiguration && this.GatewayConfiguration.isAVSActive ? this.getZipCodeValue(returnTransitionDetails) : "",
        isPartialTenderAllowed: this.GatewayConfiguration && this.GatewayConfiguration.isPartialPayAllowed,
        tenderId: this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? PaymentMethods.CreditCard.toString() : this.selectedpayment.paymentTypeId.toString(), // == PaymentMethods.CreditCard ? "1":"23" //this.selectedpayment,
        clientId: "",
        postingId: " - #" + this.ReturnWithTicketResponse?.transactionData?.retailTicketNumber
      },
      transactionId: this.CardOnFileforSelectedGuest.tokenTransId,
      amount: SaleAmount,
      surcharge: CompScope.surchargeAmount,
      hostName: this.hostName,
      ipAddress: this.ipAddress,
      agentVersion: this.agentVersion,
      guestGuid: CompScope?.selectedClient?.guestProfileId,
      sourceType:SourceTypeConstant.RetailTransaction.toString(),
			sourceTypeId: this.ReturnWithTicketResponse?.transactionData?.retailTicketNumber
    };
  }

  SaleByToken(compScope) {

    if (this.CurrentActiveCard.accessUrl) {
			let nonIntegratedPaymentMethod = this.paymentMethods.filter(x => x.paymentTypeId === PaymentMethods.NonIntegratedCreditCard);
			this.selectedpayment = {
				id: nonIntegratedPaymentMethod[0].id,
				paymentTypeId: nonIntegratedPaymentMethod[0].paymentTypeId,
				parentMethodId: nonIntegratedPaymentMethod[0].parentMethodId,
				isActive: nonIntegratedPaymentMethod[0].isActive,
				isDefault: nonIntegratedPaymentMethod[0].isDefault,
				noOfReceipts: nonIntegratedPaymentMethod[0].noOfReceipts,
				paymentMethod: nonIntegratedPaymentMethod[0].paymentMethod,
				type: nonIntegratedPaymentMethod[0].type,
			}
			return
		}
    const returnTransitionDetails = compScope.returnTransitionDetails;
    const RequestBody = this.FormSaleByTokenRequestBody(returnTransitionDetails, compScope);

    this.ShowTransactionInprogressDialog();

    let saleResponseObj: any = this.http.CallApiAsync<any>({
      host: GlobalConst.Host.payment,
      callDesc: "SaleByToken",
      method: HttpMethod.Post,
      body: RequestBody,
      uriParams: { outletId: this._ss.SelectedOutletId },
      showError: true
    });

    saleResponseObj.then(async (reponse) => {
      if (reponse && reponse.result.status) {
        let saleByTokenResponse: SaleByTokenResponse = reponse.result;
        saleByTokenResponse = this.ConstructPayRequest(saleByTokenResponse, returnTransitionDetails, compScope);
        //Check for partial tender flow for New Try Pay
        if (saleByTokenResponse.paymentManagerResponse.amount.authorisedAmount != saleByTokenResponse.paymentManagerResponse.amount.requestAmount && this.GatewayConfiguration && this.GatewayConfiguration.isPartialPayAllowed) {
          this.partialPayment = true;
          returnTransitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(saleByTokenResponse.paymentManagerResponse.amount.authorisedAmount), { emitEvent: false });
          await this.SendTryPayRequest(this.ReturnWithTicketResponse, this.selectedpayment.paymentTypeId, returnTransitionDetails, true, saleResponseObj);
        }
        const payRequestResult = await this.PayRequest(saleByTokenResponse);
        if (!payRequestResult) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(10724));
          return;
        } else {
          let SettleMentObject: PaymentHistory = {
            paymentReferenceId: saleByTokenResponse.transactionId,
            paymentMethodId: this.selectedpayment.paymentTypeId,
            parentTenderId: this.selectedpayment?.parentTypeId,
            paymentMethod: this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? this.oCaptions.shop.Card : "",
            paymentReferenceComment: compScope.paymentComments,
            amount: saleByTokenResponse.paymentManagerResponse.amount.authorisedAmount,
            issuerType: this.CurrentActiveCard == null ? "" : this.CurrentActiveCard.issuerType.toUpperCase(),
            entryMode: this.CurrentActiveCard == null ? "" : this.CurrentActiveCard.entryMode,
            cardNumber: this.CurrentActiveCard == null ? "" : this.CurrentActiveCard.cardNumber,
            paymentMethodInfo: this.selectedpayment,
            tenderReducesDiscount: this.selectedpayment.tenderReducesDiscount,
            tenderReducesTax:  this.selectedpayment.isAutoRemoveTax,
          }
          if(this._ss.Ticket != null){
            SettleMentObject.discountExemptRatio = this._ss.Ticket.tenderReducesDiscountRatio;
            SettleMentObject.taxExemptRatio = this._ss.Ticket.compTaxExemptRatio;
          }
          this.ConfirmationPopupShown = false;
          this.SettlementHistory.unshift(SettleMentObject);
          this.AlterSettlementHistory();

          //Check for partial tender flow
          if (saleByTokenResponse.paymentManagerResponse.amount.authorisedAmount != saleByTokenResponse.paymentManagerResponse.amount.requestAmount && this.GatewayConfiguration && this.GatewayConfiguration.isPartialPayAllowed) {
            this.SwitchToMultiPayment(saleByTokenResponse.paymentManagerResponse, returnTransitionDetails, true);
          }
        }

        this.dialog.closeAll();

        if (this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)) {
          const Popupmessage = this.localization.captions.shop.TrnsactionSuccessMsg;
          const dataObj = { 'text': Popupmessage, 'buttonname': this.localization.captions.common.okay, 'headertext': '', 'icon': 'icon-success-icon' }
          this.roomOpenDialog(dataObj);
        }
        this.CheckSettlementStatusAndUpdateButtons(compScope);
      }
    }).catch((errorResponse) => {
      this.HandleSaleFailure(errorResponse);
    });
  }

  CreditByToken(returnTransitionDetails, CompScope) {

    if (this.CurrentActiveCard.accessUrl) {
			let nonIntegratedPaymentMethod = this.paymentMethods.filter(x => x.paymentTypeId === PaymentMethods.NonIntegratedCreditCard);
			this.selectedpayment = {
				id: nonIntegratedPaymentMethod[0].id,
				paymentTypeId: nonIntegratedPaymentMethod[0].paymentTypeId,
				parentMethodId: nonIntegratedPaymentMethod[0].parentMethodId,
				isActive: nonIntegratedPaymentMethod[0].isActive,
				isDefault: nonIntegratedPaymentMethod[0].isDefault,
				noOfReceipts: nonIntegratedPaymentMethod[0].noOfReceipts,
				paymentMethod: nonIntegratedPaymentMethod[0].paymentMethod,
				type: nonIntegratedPaymentMethod[0].type,
			}
			return
		}
    const RequestBody = this.FormSaleByTokenRequestBody(returnTransitionDetails, CompScope);

    this.ShowTransactionInprogressDialog();

    let CreditByTokenResponse: any = this.http.CallApiAsync<any>({
      host: GlobalConst.Host.payment,
      callDesc: "CreditByToken",
      method: HttpMethod.Post,
      body: RequestBody,
      uriParams: { outletId: this._ss.SelectedOutletId }
    });
    console.log(CreditByTokenResponse);
    this.ConfirmationPopupShown = true;
    CreditByTokenResponse.then((response) => {
      if (response && response.result.status) {
        let creditByTokenResult: ValidatePayResponse = response.result;
        this.AddSettlemtAndShowDialog(creditByTokenResult.paymentManagerResponse, CompScope, creditByTokenResult);
        CompScope.RefundSale(creditByTokenResult.transactionId);
      }
    }).catch((errorResponse) => {
      this.HandleSaleFailure(errorResponse);
    });
  }
	mapTransactionDetail(lineItems: LineItem[]) {
		let details: TransactionDetailUpdate[] = [];
		lineItems.forEach(item => {
			details.push({
				baseTax: item.baseTax,
				tax: item.tax,
				itemId: item.itemId,
				linkedTax: item.linkedTax,
				transactionDetailId: this.ReturnWithTicketResponse.transactionDetails.find(x => x.lineNumber == item.index).id,
				serviceChargeGratuity: (item.gratuities && item.gratuities.length > 0) || (item.serviceCharges && item.serviceCharges.length > 0) ? this.mapServiceChargeGratuity(item, item.serviceChargeTax, item.gratuityTax) : null
			})
		})
		return details;
	}

	mapServiceChargeGratuity(lineItem: LineItem, serviceChargeTax, gratTax) {
		let res: ServiceChargeGratuityUpdate[] = [];
		lineItem.gratuities?.forEach(elem=>{
			res.push({
				gratuityTax: gratTax,
				isServiceCharge: false,
				serviceChargeTax: 0,
				staffId: elem.therapistId,
				staffType: elem.staffType,
        gratuity: elem.gratuityValue,
				additionalGratuity: elem.additionalGratuityValue,
				taxDetails: elem.taxDetails != null ? this.mapTaxDetailsforGratuity(elem.taxDetails, lineItem.index, elem) : null
			})
		});
		lineItem.serviceCharges?.forEach(elem=>{
			res.push({
				gratuityTax: 0,
				isServiceCharge: true,
				serviceChargeTax: serviceChargeTax,
				staffId: elem.therapistId,
				staffType: elem.staffType,
				taxDetails: elem.taxDetails != null ? this.mapTaxDetailsforSC(elem.taxDetails, lineItem.index, elem) : null
			})
		})
		return res;
	}

  mapTaxDetailsforSC(tax: TaxDetails[], lineNumber, serviceCharge: ServiceCharges): TaxDetailsUpdate[] {
    let res: TaxDetailsUpdate[] = [];
    tax?.forEach(x => {
      res.push({
        taxAmount: x.value,
        taxId: x.id,
        originalTaxAmount: this._ss.OriginalTicket.lineItems.find(y => y.index == lineNumber).serviceCharges?.find(y => y.staffType == serviceCharge.staffType && y.therapistId == serviceCharge.therapistId).taxDetails?.find(y => y.id == x.id).baseTaxValue,
      })
    })
    return res;
  }

  mapTaxDetailsforGratuity(tax: TaxDetails[], lineNumber, gratuity: Gratuities): TaxDetailsUpdate[] {
    let res: TaxDetailsUpdate[] = [];
    tax?.forEach(x => {
      res.push({
        taxAmount: x.value,
        taxId: x.id,
        originalTaxAmount: this._ss.OriginalTicket.lineItems.find(y => y.index == lineNumber).gratuities?.find(y => y.staffType == gratuity.staffType && y.therapistId == gratuity.therapistId).taxDetails?.find(y => y.id == x.id).baseTaxValue,
      })
    })
    return res;
  }

  mapLinkedTaxDetails(tax: TaxDetails[], baseTax: TaxDetails): TaxDetailsUpdate[] {
		let res: TaxDetailsUpdate[] = [];
		tax?.forEach(x => {
			res.push({
				taxAmount: x.value,
				taxId: x.id,
				baseTaxAmount: x.baseTaxValue,
				linkedTaxAmount: x.linkedTaxValue,
        originalTaxAmount: baseTax.linkedTaxDetails.find(tax=>tax.id == x.id).baseTaxValue,
				transactionLinkedTaxDetailUpdate: x.linkedTaxDetails ? this.mapLinkedTaxDetails(x.linkedTaxDetails, baseTax.linkedTaxDetails.find(tax=>tax.id == x.id)) : null
			})
		})
		return res;
	}

  mapLineItemTaxDetails(lineItems: LineItem[], retainedItemLength: number = 0) {
		let res: TaxDetailsUpdate[] = [];
		lineItems.forEach(item => {
      let prevlineNumForSameItem = 0;
      item.taxDetails.forEach(x => {
        const itemWiseTaxDetails = this.ReturnWithTicketResponse.transactionTaxDetails.filter(t => t.itemId == item.itemId);
        let currTransTaxDetail = this.ReturnWithTicketResponse.transactionTaxDetails.find(y => y.itemId == item.itemId && y.taxId == x.id);        
        let transDetailId = currTransTaxDetail.transactionDetailId;
        if (itemWiseTaxDetails.length > 1) {
          if (prevlineNumForSameItem == 0) {
            prevlineNumForSameItem = currTransTaxDetail.lineNumber;
          } else {
            const nextTransTaxDetail = itemWiseTaxDetails[itemWiseTaxDetails.indexOf(itemWiseTaxDetails.find(iw => iw.lineNumber == prevlineNumForSameItem)) + 1];
            prevlineNumForSameItem = nextTransTaxDetail.lineNumber;
            transDetailId = nextTransTaxDetail.transactionDetailId;
          }
        }
        
        res.push({
          taxAmount: x.value,
          transactionDetailId: transDetailId,
          taxId: x.id,
          baseTaxAmount: x.baseTaxValue,
          linkedTaxAmount: x.linkedTaxValue,
          originalTaxAmount: this._ss.OriginalTicket.lineItems.find(y => y.index == item.index).taxDetails.find(y => y.id == x.id).baseTaxValue,
          transactionLinkedTaxDetailUpdate: x.linkedTaxDetails ? this.mapLinkedTaxDetails(x.linkedTaxDetails, this._ss.OriginalTicket.lineItems.find(y => y.index == item.index).taxDetails.find(y => y.id == x.id)) : null
        })
      })
		})
		return res;
	}

  async PayRequest(pMPayResponse: ValidatePayResponse): Promise<boolean> {
    let PayRequestObj: PayRequest = {
      tryPayResponse: this.CurrentTryPayResponse,
      payResponse: pMPayResponse.paymentManagerResponse,
    }
    PayRequestObj.tryPayResponse.paymentReferenceId = pMPayResponse.transactionId;
		if (this.IsShopScreen && this.selectedpayment.isAutoRemoveTax && !this._ss.isTaxExempted) {
			let checkData = this._ss.Ticket.checkData;
			let transaction: TransactionUpdate = {
				totalAmount: checkData.totalAmount,
				totalTax: checkData.totalTax,
        totalGratuity: checkData.gratuity,
				transactionId: this.ReturnWithTicketResponse.id,
				transactionDetailUpdate: this.mapTransactionDetail(this._ss.Ticket.lineItems),
				transactionTaxDetailUpdate: this.mapLineItemTaxDetails(this._ss.Ticket.lineItems,this._ss.selectedRetainedRetailProducts.length)
			}
			PayRequestObj.transactionUpdate = transaction;
		}
    let Response: any = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: "Pay",
      method: HttpMethod.Post,
      body: PayRequestObj,
      uriParams: { outletId: this._ss.SelectedOutletId, terminalId: this._ss.SelectedTerminalId }
    });
    if (Response && Response.successStatus) {
      return true;
    } else {
      return false;
    }
  }

  async AlterSettlementHistory() {
    this.AutoScrollToBottom();
    this.cmsBusinessService.DisableOfferOrCompSlipIfVoucherRedeemed(this);
    this.UpdateCardPaymentOrder();
    this.AdjustPaymentMethods();
  }

  UpdateCardPaymentOrder() {
    let settledInCard = this.SettlementHistory.filter(r => (r.paymentMethodId == PaymentMethods.CreditCard || r.paymentMethodId == PaymentMethods.CardOnFile));
    settledInCard.forEach(s => s.paymentMethod = this.ShopCaptions.Card);
    settledInCard = settledInCard.filter(x => !x.isReversed);
    if (settledInCard && settledInCard.length > 1) {
      for (let i = settledInCard.length; i > 0; i--) {
        settledInCard[settledInCard.length - i].paymentMethod = `${this.ShopCaptions.Card} ${i}`;
      }
    }
  }

  ProceedTransaction(compScope) {
    this.selectPaymentOption = true;
    const returnTransitionDetails = compScope.returnTransitionDetails;
    this.CalculateSettledAmount(returnTransitionDetails);
    // Check if settled amount is equal to the Amount to be paid if yes show the transaction successful modal
    if (this.SettledAmt == this.getSaleAmt()) {
      if (this.selectedPaymentMethod === PaymentMethods.RoomCharge) {
        //// Not Use.
      } else {
        const paymentCloseText = this.localization.replacePlaceholders(this.localization.captions.shop.TicketCreationSuccess, ["checkNumber"], [this.ReturnWithTicketResponse.transactionData.ticketNumber]);
        const dataObj = { 'text': paymentCloseText, 'buttonname': this.localization.captions.common.Close, 'headertext': this.localization.captions.shop.PaymentComplete, 'icon': 'icon-success-icon' }
        this.roomOpenDialog(dataObj);
      }
    } else {

      // Settlement is pending continue with the payment flow
      if (this.selectedPaymentMethod === PaymentMethods.Cash) {
        if (this.partialPayment && returnTransitionDetails.controls.amounttendered.value == '') {
          returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
          return;
        }
        this.openCashPaymentPopup(returnTransitionDetails, compScope);
      } else if (this.selectedPaymentMethod === PaymentMethods.CreditCard || this.selectedPaymentMethod === PaymentMethods.IDTECH) { //Once Remove bind the Payment Api Retuen Response
        if (this.partialPayment && this.ContinuePayment && this.cardDetailsBool) {
          this.ContinuePayment = false;
          this.cardDetailsBool = false;
          this.ShowSettlemtHist = true;
          this.paymentText = this.oCaptions.shop.ContinuePayment;
          return;
        }

      } else if (this.IsRoomOrGroupCharge) {
        if (returnTransitionDetails.controls.roomnumber.value == '') { //Check for room number
          returnTransitionDetails.controls.roomnumber.updateValueAndValidity();
          return;
        }
      }
      if (!this.InitiateRetailTranRequest && !this.ReturnWithTicketResponse) {
        this.InitiateRetailTranRequest = true;
        compScope.ReturnwithTicket();
      }

    }
  }

  MakeSale(compScope, returnTransitionDetails) {
    if (this.ReturnWithTicketResponse) {
      this._ams.paymentProcessing = true;
      if (this.selectedPaymentMethod == PaymentMethods.PendingSettlement && returnTransitionDetails && this.remainingAmount == this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value)) {
        compScope.GotoRetailTransaction(true);
        return;
      }
      else if (this.IsRefundFlow) {
        this.InitiateSaleReqBasedOnPaymentMethod(returnTransitionDetails, this.getSaleAmt(), compScope);
      } else {
        this.SendTryPayRequest(this.ReturnWithTicketResponse, this.selectedpayment.paymentTypeId, returnTransitionDetails, false, '', compScope);
      }
    } else {
      compScope.ReturnwithTicket();
    }
  }

  /**
* @method Function
* @function openPaymentPopup _rformdeatils.value.amountpaid
* @param e <obj>
* @type any
* @description open popup choose the popup method or cash type based
* @return none
* @change active the select class compare to id
*
*/
  openCashPaymentPopup(returnTransitionDetails: any, compScope: any = '') {

    const fullpaymentAmount = this.localization.localizeCurrency(Number(this._ss.finalAmount).toString());
    const partialPaymentAmount = this.localization.localizeCurrency((this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value)).toString());
    const totalAmountInt = Number(this._ss.finalAmount);
    const tenderAmt = this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value);

    const dialogRef = this.dialog.open(ShopDialogPopUp, {
      width: '500px',
      height: 'auto',
      maxHeight: '700px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        isEdit: false,
        headername: 'Cash payment',
        closebool: true,
        templatename: 'shopCashPayment',
        data: {
          'totalamount': this.partialPayment ? partialPaymentAmount : fullpaymentAmount,
          'totalAmountInt': this.partialPayment ? tenderAmt : totalAmountInt,
          'paymenttype': (this.FullPayment) ? 'fullpayment' : 'partialpayment',
          patchTotalAmountInCashPopup: this.patchTotalAmountInCashPopup
        }
      },
      panelClass: 'shop-payment'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(cashPaymenHandle => {
      compScope.cashPaymenHandleData = cashPaymenHandle;
      if (cashPaymenHandle.paymentStaus === 'cashpaid') {
        if (compScope != '' && this.remainingAmount == this._ss.finalAmount && !this.ReturnWithTicketResponse) {
          this.ConfirmationPopupShown = true;
          compScope.ReturnwithTicket();
          return;
        }
        this.ValidatePayRequest(compScope, returnTransitionDetails, '');
      }
    });
  }

  /**
   *@method pop-up
   *@function roomOpenDialog()
   *@param input <obj>
   *@description In the popup open the roompayment method
   *@param output <null>
   */

  roomOpenDialog(e: any, formDetails?: any, compScope = '') {
    const data = {
      headername: e.headertext,
      headerIcon: e.icon,
      headerMessage: e.text,
      buttonName: e.buttonname,
      type: 'message',
      isloaderenable: e.isloaderenable,
      isHiddenFieldRequired: e.isHiddenFieldRequired,
      cardpayment: e.cardpayment,
      isManualEntry: e.isManualEntry
    }
    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '350px',
      height: 'auto',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: data,
      disableClose: true
    });
    const subscription = dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        if (this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH) && this.ValidateIDTechCardSwipeData(x)) {
          this.EncryptedCardData = x;
          this.GetHandles(formDetails, compScope);
          this.ShowTransactionInprogressDialog();
          this.ManualClosePerformed = true;          
        } else if (this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH) && !this.EncryptedCardData && x == this.localization.captions.common.Close) {
          this.ManualClosePerformed = true;
          if(this.ManualClosePerformed && (this.SettlementHistory.length == 0 || this.SettlementHistory.every(x => x.isReversed))){
            this._ams.paymentProcessing = false;
          }
          return;
        } else if (x != this.oCaptions.common.Close && this.selectedpayment.paymentTypeId > 0) {
          let AmountTendered = Number(this.localization.currencyToSQLFormat(formDetails.controls.amounttendered.value));
          if (AmountTendered === Number(formDetails.controls.amountpaid.value)) {
            this.paymentText = this.oCaptions.shop.CloseTransaction;
            this.PerformCloseTransactionUpdate(compScope);
            this.AllowCancel = false;
            this.ResetSelectedPaymentMethod();
            this.EnableCloseTranBtn = true;
          }
        }
      }
    });
  }

  /**
   * @method function
   * @function tenderedAmount();
   * @input params <null>
   * @output <obj>
   * @description tendered
   */

  tenderedAmount(compScope) {
    let AmountTendered = this.localization.currencyToSQLFormat(compScope.returnTransitionDetails.controls.amounttendered.value);
    this.CalculateSettledAmount(compScope.returnTransitionDetails);
    this.ReturnTransitionFormControlObj = compScope.returnTransitionDetails;
    const paymentBool = Number(compScope.returnTransitionDetails.controls.amountpaid.value) === AmountTendered;
    if (paymentBool && this.selectedPaymentMethod === PaymentMethods.Cash) {
      this.ifPaymentChoose = true;
      this.defaultSelectchecked = 0;
    } else {
      if (compScope.returnTransitionDetails.controls.amounttendered.valid && AmountTendered && this.selectedpayment.paymentTypeId > 0) {
        this.EnableCloseTranBtn = true;
      } else {
        this.EnableCloseTranBtn = false;
      }
    }
  }

  /**
*
* @param itemDetails;
* @function selectDevice
* @param <obj>
* @description Select the device type Device Details
*/
  RetrnPaymentSelectDevice(e: any, returnTransitionDetails) {
    this.selectedDeviceHandle = e.handle;
    this.deviceName = e.name;
    this.ifPaymentChoose = true;
    this.deviceId = e.id;
    if (e.name == IDTechHandle.name) {
      const clonedIdTech = this.paymentMethods.find(p => p.parentMethodId == this.selectedpayment.id && p.parentTypeId == PaymentMethods.IDTECH);
      this.selectedpayment.paymentTypeId = this.selectedpayment.isCloned && clonedIdTech ? clonedIdTech.paymentTypeId : PaymentMethods.IDTECH;
      this.selectedpayment.paymentTypeEqualant = PaymentMethods.CreditCard;
      this.selectedpayment.parentTypeId = PaymentMethods.IDTECH;
      const amtTenderField = returnTransitionDetails.controls.amounttendered;
      this.EnableCloseTranBtn = (amtTenderField.valid && amtTenderField.value);
    } else {
      this.selectedpayment.paymentTypeId = this.paymentMethods?.find(p => p.id == this.selectedpayment.id)?.paymentTypeId;
      this.selectedpayment.parentTypeId = this.paymentMethods?.find(p => p.id == this.selectedpayment.id)?.parentTypeId;
    }
  }
  async getReturnOriginalPaymentConfigurationSetting() {
    try {
      return await this._propertySettingDataService.GetReturnOriginalPaymentConfigurationSetting();
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  async isRetailInterface() {
    const propertySetting = await this._propertySettingDataService.getAllPropertySetting();
    return propertySetting && propertySetting.activateRetailInterface;
  }

  async GetCCTerminal() {
    const ccTerminalRes = this.http.CallApiAsync<any>({
      callDesc: "GetAllCreditCardTerminal",
      host: GlobalConst.Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { includeInactive: true }
    });
    ccTerminalRes.then((res) => {
      this.terminalList = res.result;
    }).catch((err) => { console.log(err); });
  }

  async GetAllPaymentMethods(compScope) {
    const returnTransitionDetails = compScope.returnTransitionDetails;
    this.ReturnTransitionFormControlObj = compScope.returnTransitionDetails;
    let serviceParams: ServiceParamsAsync;
    if (!this.IsFolioPaymentMethod) {
      serviceParams = {
        callDesc: "GetPaymentConfiguration",
        host: GlobalConst.Host.payment,
        method: HttpMethod.Get
      }
    } else {
      serviceParams = {
        callDesc: "GetPaymentMethodWithReferences",
        host: GlobalConst.Host.folio,
        method: HttpMethod.Get,
        uriParams: { includeInactive: true }
      }
    }
    let response: any = await this.http.CallApiAsync<any>(serviceParams);
    this.selectedpayment = {
      id: 0,
      paymentTypeId: 0,
      parentTypeId: 0,
      paymentMethodId: 0,
      allowEarn: false
    };
    if (response.successStatus) {
      let Paymethodresponse: any = response.result;
      const MethodsToBeHiddednInRefund = [
        PaymentMethods.IDTECH,
        PaymentMethods.RainCheck,
        PaymentMethods.V1GiftCardIdTech,
        PaymentMethods.ExternalGiftCardIdTech,
        PaymentMethods.AgilysysGiftCardIdTech,
        PaymentMethods.RedeemPoint,
        PaymentMethods.AuthorizePayment
      ];
      this.paymentMethodSource = Paymethodresponse.paymentMethods;
      let paymentMethodList = this.MapPaymentMethods(Paymethodresponse);

      //Allow Returns to Original Settlement Method Only
      const configValue: SystemConfiguration = await this._propertySettingDataService.GetReturnOriginalPaymentConfigurationSetting();
      if (configValue && configValue.value === 'true') {
        let trans = await this.GetPaymentTransaction(this._ss.transactionId);
        trans = trans.filter(t => t.paymentStatus == 1 || t.paymentStatus == 3);
        if (this.IsRefundFlow && trans && trans.length > 0) {          
          let activepaymethodIds = Array.from(new Set(trans.map(x => this.utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId))));

          //If original ticket has IDTech payment, then we need to show Card. Otherwise User cannot proceed with card.
          let IDTechMethodsMap = {
            [PaymentMethods.ExternalGiftCardIdTech]: PaymentMethods.ExternalGiftCard,
            [PaymentMethods.AgilysysGiftCardIdTech]: PaymentMethods.AgilysysGiftCard,
            [PaymentMethods.IDTECH]: PaymentMethods.CreditCard,
            [PaymentMethods.V1GiftCardIdTech]: PaymentMethods.V1GiftCard
          };

          activepaymethodIds = activepaymethodIds.map(x => {
            if (IDTechMethodsMap[x]) {
              x = IDTechMethodsMap[x]
            }
            return x;
          })

          this.paymentMethods = paymentMethodList.filter(pay => pay.isActive && activepaymethodIds.includes(this.utils.GetOriginalTenderId(pay.paymentTypeId, pay.parentTypeId)));
          // this.paymentMethods = paymentMethodList.filter(pay => pay.isActive && activepaymethodIds.has(pay.paymentTypeId) && !MethodsToBeHiddednInRefund.includes(pay.paymentTypeId));
          this.returnOriginalPaymentOnly = configValue.value === 'true';
        } else {
          this.paymentMethods = paymentMethodList.filter(pay => pay.isActive && !MethodsToBeHiddednInRefund.includes(this.utils.GetOriginalTenderId(pay.paymentTypeId, pay.parentTypeId)));
        }
      } else {
        this.paymentMethods = paymentMethodList.filter(pay => pay.isActive && !MethodsToBeHiddednInRefund.includes(this.utils.GetOriginalTenderId(pay.paymentTypeId, pay.parentTypeId)));
      }
      this.UpdateGridStateRequired();
      if (!this._ss.enableResortFinance || (this._ss.enableResortFinance && (this._ss.memberCardNumber && this._ss.memberCardNumber != '0'))) {
        this.paymentMethods = this.paymentMethods.filter(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) != PaymentMethods.PostToFolio);
      }
      this.paymentMethods.forEach(method => {
        if (PaymentMethodIconConst[method.paymentTypeId]) {
          method.type = PaymentMethodIconConst[method.paymentTypeId];
        } else if (PaymentMethodIconConst[method.parentTypeId]) {
          method.type = PaymentMethodIconConst[method.parentTypeId];
        } else {
          const otherPaymentMethod = 44; //WE DON'T HAVE ANY PAYMENT METHOD CALLED 'OTHER' SO JUST ADDING A const AS 44
          method.type = PaymentMethodIconConst[otherPaymentMethod];
        }
        let paymentMethodName = myGlobals.PaymentMethodValue[method.paymentTypeId];
        if (!this.IsFolioPaymentMethod && method.paymentMethod == paymentMethodName) {
          method.paymentMethod = this.ShopCaptions.paymentMethods[method.paymentTypeId];
        } else {
          method.paymentMethod = method.paymentMethod;
        }       
        const additionalConfig = method.additionalConfigurations ? JSON.parse(method.additionalConfigurations) : null;
        const EnableZeroPostingConfigValues = additionalConfig?.find(x => x.Key.trim().toUpperCase() === EnableZeroPosting);
        method.allowZeroPosting = additionalConfig ? EnableZeroPostingConfigValues?.Value.trim().toLowerCase() == 'true' || method.paymentTypeId == PaymentMethods.Cash : false;
      });
      this.CheckForCardOnFile(this._ss.selectedclient);
      this.paymentMethodsBeforeCompAccessValidation = _.cloneDeep(this.paymentMethods);
      await this.ValidatePaymentMethodActivationBasedOnComponentAccessLink(this._ss.selectedclient, true);
      //Bug 20734 - Default payment method should be cash

      const cashMethodAvailable = this.paymentMethods.some(x => x.paymentTypeId == PaymentMethods.Cash && x.isActive);
      const cashPayment = cashMethodAvailable ? this.paymentMethods.find(x => x.paymentTypeId == PaymentMethods.Cash && x.isActive) :
        this.paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.Cash && x.isActive);
      //Set default payment method
      if (this._ss.hasAccessSettleCash && cashPayment) {
        this.selectedpayment = {
          id: cashPayment?.id,
          paymentTypeId: cashPayment?.paymentTypeId,
          parentTypeId: cashPayment?.parentTypeId,
          allowEarn: cashPayment?.allowEarn,
          paymentMethod: cashPayment?.paymentMethod,
          requireReceipts: cashPayment?.requireReceipts,
          isCloned: cashPayment?.isCloned,
          isAutoRemoveTax: cashPayment?.isAutoRemoveTax,
          tenderReducesDiscount: cashPayment?.tenderReducesDiscount
        };
        this.ifPaymentChoose = this.EnableCloseTranBtn = true;
      }
      this.GatewayConfiguration = Paymethodresponse.gatewayConfiguration && Paymethodresponse.gatewayConfiguration.find(t => t.type == GlobalConst.GatewayConfigurationType.CreditCard);
      if (this.GatewayConfiguration && this.GatewayConfiguration.isAVSActive) {
        returnTransitionDetails.controls.zipcode.setValidators(Validators.required);
      }
      const preferredInputMap = {
        [CardInputMethod.KeyIn]: this.localization.captions.setting.keyIn,
        [CardInputMethod.SwipeInsertTap]: this.localization.captions.setting.swipeInsertTap
      }
      if (this.GatewayConfiguration && preferredInputMap[this.GatewayConfiguration.preferredCardEntryMode]) {
        this.preferredInputSelectedBanner = this.localization.replacePlaceholders(
          this.localization?.captions?.shop?.preferredInputModeSelectedBanner ?? '', ['preferredMethod'],
          [preferredInputMap[this.GatewayConfiguration.preferredCardEntryMode]]
        );
      }
      this.paymentMethods = this.FilterPaymentBasedOnProduct(this.paymentMethods);
      this.paymentMethodsClone = _.cloneDeep(this.paymentMethods);
      this.AdjustPaymentMethods(true);
      await this.cmsBusinessService.HandleCMSPaymentForReturnFlow(this, compScope);
      this._memberService.ValidateMembershipPaymentMethodActivation(this);
      this._walletBusiness.ValidateWalletMethodsActivation(this);
      this.SelectDefaultPaymentMethod(this, this._ss.selectedclient, compScope);
      if (this._ss.settleRWTTransaction && this.SettlementHistory.length > 0) {
        returnTransitionDetails.controls.paymentType.patchValue(PaymentOptionType.MultiPayment);
        returnTransitionDetails.controls.paymentType.updateValueAndValidity();
      }
      this.CalculateRemainingAmount(returnTransitionDetails);
    }
  }

  MapPaymentMethods(paymentMethodList) {
    let paymentMethods: any[] = [];
    if (this.IsFolioPaymentMethod) {
      paymentMethodList.map(x => {
        const paymentServiceObj = x.paymentMethodServiceReferences.find(o => o.paymentMethodId == x.paymentMethod.id);
        const payMethod: PaymentMethod = {
          paymentMethod: x.paymentMethod.name,
          paymentTypeId: paymentServiceObj?.paymentTypeId,
          isDisabled: !x.paymentMethod.isActive,
          isActive: x.paymentMethod.isActive,
          id: x.paymentMethod.id,
          requireComments: x.paymentMethod.requireComments
        };
        paymentMethods.push(payMethod);
      });
    } else {
      paymentMethods = paymentMethodList.paymentMethods;
    }
    return paymentMethods;
  }

  async GetCCConfiguration(returnTransitionDetails) {
    if (this.IsFolioPaymentMethod) {
      const result = await this.creditCardConfigurationService.getCreditCardConfiguration({ type: GatewayConfigurationType.CreditCard });
      if (result) {
        this.GatewayConfiguration = result.find(t => t.type == GatewayConfigurationType.CreditCard);
        if (this.GatewayConfiguration.isAVSActive) {
          returnTransitionDetails.controls.zipcode.setValidators(Validators.required);
        }
      }
    }
  }

  UpdateGridStateRequired() {
    var params: ServiceParamsAsync = { callDesc: "GetMiscConfiguration", method: HttpMethod.Get, host: GlobalConst.Host.retailManagement };
    this.http.CallApiAsync<any>(params).then(
      res => {
        const miscValue = res.result.find(x => x.switch == MiscellaneousSwitch.EXPAND_PAYMENT_METHODS).value;
        if (miscValue && miscValue.toLowerCase() === 'true') {
          this.showAllPaymentMethods = true;
        }
        this.SetCashPopupPrefillFlag(_.cloneDeep(res?.result));
      });
    if (this.selectedpayment.paymentTypeId > 0 && this.paymentMethods.length > 0 && !this.showAllPaymentMethods) {
      this.showAllPaymentMethods = (this.paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == this.utils.GetOriginalTenderId(this.selectedpayment.paymentTypeId, this.selectedpayment.parentTypeId)).listOrder > 4);
    }
  }

  GetActiveTransactionPayments(transactionPayments: TransactionPayment[]) {
    let activeRecords: TransactionPayment[] = [];
    if (transactionPayments && transactionPayments.length > 0) {
      const refundedRecords = transactionPayments.filter(x => x.linkedPaymentReferenceId > 0);
      if (refundedRecords.length > 0) {
        refundedRecords.forEach(r => {
          r.isRefunded = true;
          let originalSettledPayment = transactionPayments.find(x => x.paymentReferenceId == r.linkedPaymentReferenceId);
          if (originalSettledPayment) { originalSettledPayment.isRefunded = true; }
        });
      }
      activeRecords = transactionPayments.filter(x => !x.isRefunded);
    }
    return activeRecords;
  }

  FormGetHandleRequest(compScope) {
    let RequestBody: HandleRequest;
    switch (this.selectedPaymentMethod) {
      case PaymentMethods.CreditCard:
        RequestBody = {
          tenderId: this.selectedpayment.paymentTypeId.toString()
        }
        break;
      case PaymentMethods.IDTECH:
        RequestBody = {
          tenderId: this.selectedpayment.paymentTypeId.toString(),
          inquiryInfo: {
            cardData: {
              encryptedData: JSON.stringify({
                deviceType: 'idtechkb',
                encryptedData: this.EncryptedCardData
              })
            }
          }
        }
        break;
      case PaymentMethods.CompRedemption:
        const userId = this.localization.GetPropertyInfo('UserId');
        RequestBody = {
          tenderId: this.selectedpayment.paymentTypeId.toString(),
          inquiryInfo: {
            id: compScope.CMSForm.controls['cmsSearch'].value,
            type: this._featureFlagInfo.CMSSearchType,
            cardData: {
              pin: compScope.CMSPin,
              encryptedData: ""
            }
          },
          inquirerInformation: {
            profitCenterName: this._featureFlagInfo.CMSProfitCenterName,
            profitCenter: this._featureFlagInfo.CMSProfitCenterId,
            employeeId: userId ? userId : ""
          },
          profitCenterId: this._featureFlagInfo.CMSProfitCenterId
        }
        break;
      default:
        break;
    }
    return RequestBody;
  }

  async GetUserSessionConfiguration() {
    this.userSessionConfiguration = await this.userMachineConfigurationService.getUserSessionConfiguration(Number(this.localization.GetPropertyInfo('UserId')));
  }

  async GetHandles(returnTransitionDetails, compScope, settlementWithCMSSale?, isOnLoad = false) {
    const isIDTechEnabled = (this.userSessionConfiguration?.isIdtechSred || this.userSessionConfiguration?.defaultPaymentDevice == IDTech.id);
    if (this.cardPaymentMethods.includes(this.selectedPaymentMethod) && isIDTechEnabled) {
      if (!this.deviceType.includes(IDTechHandle))
        this.deviceType.push(IDTechHandle);
      if (this.userSessionConfiguration?.defaultPaymentDevice == IDTech.id) {
        returnTransitionDetails.controls.selectedDevice.patchValue(IDTechHandle.name);
        this.RetrnPaymentSelectDevice(IDTechHandle, returnTransitionDetails);
        //If Default device is IDTech, then no need fetch device list
        if (!this.EncryptedCardData)
          return;
      }
    }

    if (this.selectedPaymentMethod != PaymentMethods.IDTECH && !this.IsCMSRefund) {
      let loaderMsg = {
        [PaymentMethods.CreditCard]: this.ShopCaptions.RetrieveDeviceList,
        [PaymentMethods.CompRedemption]: this.ShopCaptions.RetrievePatronDetails
      }
      this._ams.loaderEnable.next(loaderMsg[this.selectedPaymentMethod]);
    }

    let RequestBody: HandleRequest = this.FormGetHandleRequest(compScope);

    const handleResult: Promise<HandleResponse> = this.PaymentProcessor.GetHandles(RequestBody, Number(this._ss.SelectedOutletId), this.CEDS_ByTerminal);

    handleResult.then(response => {
      if (response.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
        this.hostName = response?.hostName;
        this.ipAddress = response?.ipAddress;
        this.agentVersion = response?.agentVersion;
        if (this.cardPaymentMethods.includes(this.selectedPaymentMethod)) {
          let availableIngenicoDevices = response.paymentHandle;
          availableIngenicoDevices.forEach(device => {
            if (device.name && !this.deviceType.some(x => x.name == device.name)) {
              this.deviceType.push(device);
            }
          });
        }
        if (this.selectedPaymentMethod === PaymentMethods.IDTECH && this.EncryptedCardData) {
          this.selectedDeviceHandle = response.paymentHandle[0].handle;
          if (this.IsRefundFlow) {
            this.RequestForCredit(this.ReturnWithTicketResponse, returnTransitionDetails, compScope);
          } else {
            this.RequestForSale(this.ReturnWithTicketResponse, returnTransitionDetails, compScope);
          }

        } else if (this.selectedPaymentMethod === PaymentMethods.CompRedemption) {
          let patronId = compScope.CMSForm.controls['cmsSearch'].value;
          this.cmsBusinessService.CreatePatronGuest(patronId);
          this.cmsPlayerHandles = response.paymentHandle;
          this.cmsPlayerHandles = this.FilterCMSApplicableBuckets(this.cmsPlayerHandles);
          this.cmsPlayerHandles = this.cmsPlayerHandles.filter(x => this.ShopCaptions.CMS.handleType[x.type]);
          this.selectedCMSPlayer = {
            PlayerName: this.cmsPlayerHandles[0] && this.cmsPlayerHandles[0].name,
            PatronId: this.cmsPlayerHandles[0] && this.cmsPlayerHandles[0].inquiryInfo && this.cmsPlayerHandles[0].inquiryInfo.id
          }
          this.cmsBusinessService.PerformCMSAutoSaleIfRequired(this, compScope, settlementWithCMSSale);
        }
      } else {
        let notFoundMsg = {
          [PaymentMethods.CreditCard]: this.localization.captions.shop.NoDevicesFound,
          [PaymentMethods.CompRedemption]: this.localization.captions.shop.NoPlayersFound
        };
        this.EnableCloseTranBtn = false;
        this.ifPaymentChoose = false;
        if (!isOnLoad) {
          if (this.cardPaymentMethods.includes(this.selectedPaymentMethod)) {
            if (!isIDTechEnabled)
              this.utils.ShowErrorMessage(
                this.localization.captions.common.Error,
                this.localization.captions.shop.NoDevicesFound
              );
          } else {
            this.cmsPlayerHandles = [];
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, notFoundMsg[this.selectedPaymentMethod]);
          }
        }
      }
      this.SelectDefaultDevice(this.userSessionConfiguration, returnTransitionDetails);
      setTimeout(() => { this._ams.loaderEnable.next("") }, 1000);
    }).catch(err => {
      setTimeout(() => { this._ams.loaderEnable.next("") }, 1000);
      this.deviceType = [];
      this.ifPaymentChoose = false;
      console.log('Error during GetHandles:' + err.error);
      if (isOnLoad) { return }
      this.ResetSelectedPaymentMethod();
      //Show Payment Error Prompt
      if (err.error[0] != null && err.error[0].Code != null && err.error[0].Code != "")
        this.payAgentService.PaymentErrorPrompt(err.error[0].Code);
      else if (err.error[0] != null && err.error[0].Message == this.payAgentService.INVALID_TENDER_MSG) {
        return this.utils.ShowErrorMessage(
          this.localization.captions.common.Error,
          this.localization.captions.shop.PayAgentConfigError
        );
      }
      else
        return this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PayAgentUnavailable);
    });
  }

  SelectDefaultDevice(userSessionConfiguration, returnTransitionDetails) {
    if (userSessionConfiguration && userSessionConfiguration?.defaultPaymentDevice && this.cardPaymentMethods.includes(this.selectedPaymentMethod)) {
      if (userSessionConfiguration?.defaultPaymentDevice.toLowerCase() == IDTech.id) {
        returnTransitionDetails.controls.selectedDevice.patchValue(IDTechHandle.name);
        this.RetrnPaymentSelectDevice(IDTechHandle, returnTransitionDetails);
      } else {
        returnTransitionDetails.controls.selectedDevice.patchValue(userSessionConfiguration?.defaultDeviceName);
        let defaultDevice = this.deviceType.filter(x => { return x.name == userSessionConfiguration?.defaultDeviceName })[0];
        if (defaultDevice) {
          this.RetrnPaymentSelectDevice(defaultDevice, returnTransitionDetails);
        }
      }
    }
  }

  SelectDefaultPaymentMethod(compScope, payeeDetail: PayeeInfo, Scope = null) {
    try {
      const isUserDefaultPaymentMethodAvailable = (compScope.userSessionConfiguration?.defaultPaymentId
        && compScope.paymentMethods.some(x => x.id == compScope.userSessionConfiguration?.defaultPaymentId));
      let defaultMethod: PaymentMethod;
      //Override DefaultPayment if configured and skipping if cash selected
      if (isUserDefaultPaymentMethodAvailable) {
        defaultMethod = compScope.paymentMethods.find(x => x.id == compScope.userSessionConfiguration?.defaultPaymentId);
      }      

      //Override Payment method selection if refund is for Member
      if (payeeDetail?.playerCategoryId == PlayerCategory.Member || payeeDetail?.guesttype == ClientType.Member) {
        if (compScope.paymentHistoryDetailsForTransaction?.some(x => x.paymentMethodId == PaymentMethods.CreditBook)
          && !compScope.paymentHistoryDetailsForTransaction?.some(x => x.paymentMethodId == PaymentMethods.ARPost)) {
          defaultMethod = compScope.paymentMethods?.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.CreditBook);
        }
        else {
          defaultMethod = compScope.paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.ARPost);
        }
      }

      //Override default payment selection if guaranteedMethod is available, even for members
      const isGuaranteedMethodAvailable = compScope.paymentMethods.find(x => x.id == PaymentMethods.GuaranteeMethod);
      if (isGuaranteedMethodAvailable) { defaultMethod = isGuaranteedMethodAvailable }

      if (!defaultMethod) {
        //Get firstListOrder from available payment methods
        const firstListOrder = Math.min(...compScope.paymentMethods.map(x => x.listOrder));
        defaultMethod = compScope.paymentMethods.find(x => x.listOrder == firstListOrder);
      }

      if (defaultMethod && !defaultMethod?.isDisabled) {
        if (!compScope.showAllPaymentMethods) {
          compScope.showAllPaymentMethods = (compScope.paymentMethods.indexOf(defaultMethod) > 3);
        }
        this.AutoScrollToBottom();
        Scope != null ? compScope.selectpaymentMethod(defaultMethod, Scope, true) :
          compScope.selectpaymentMethod(defaultMethod, true);
      }
      if ((this._ss.defaultRefundToOriginal && this._ss.defaultRefundToOriginal === true && Scope?.IsEnableRefundToOriginalSettlement) || compScope.isMultipleMemberTransaction) {
        setTimeout(() => {
          this.returnOriginalPaymentOnly = true;
          Scope?.returnTransitionDetails.controls.refundToOriginal.setValue(true);
          Scope?.OnRefundToOriginalToggle(true);
        }, 100);
      }
    } catch (error) {
      console.log("Error occurred in SelectDefaultPaymentMethod:", error);
    }
  }

  ValidateOfferAndCompSettlementForReversal() {
    const transPayments = this.TransactionPaymentsAggregate;
    let allowedToProceed = true;
    if (transPayments && transPayments.length > 0) {
      const compExistsAndNotSupported = (transPayments.some(t => this.utils.GetOriginalTenderId(Number(t.transactionPayment.paymentMethod), t.transactionPayment.parentTenderId) == PaymentMethods.CompRedemption) && !this._featureFlagInfo.AllowCompRedemptionVoid);
      const offerExistsAndNotSupported = (transPayments.some(t => this.utils.GetOriginalTenderId(Number(t.transactionPayment.paymentMethod), t.transactionPayment.parentTenderId) == PaymentMethods.OfferRedemption && !this._featureFlagInfo.ValidateOfferRedemptionVoid(t.additionalInformation.VendorType)));
      if (offerExistsAndNotSupported || compExistsAndNotSupported) {
        allowedToProceed = false;
        this.returnTransitionDetails.controls.refundToOriginal.setValue(false);
        this.returnTransitionDetails.controls.refundToOriginal.markAsTouched();
        const errorMsg = offerExistsAndNotSupported ? this.localization.captions.shop.CMS.OfferRefundNotAllowed :
          this.localization.captions.shop.CMS.CompRefundNotAllowed
        this.utils.ShowErrorMessage(this.localization.captions.common.Warning, errorMsg, myGlobals.ButtonType.Ok)
      }
    }
    return allowedToProceed;
  }

  CheckIfGuestIsSelected(returnTransitionDetails): boolean {
    if (this.SelectedPaymentMethodEquals(PaymentMethods.RoomCharge) && this.GuestAccounts.length > 1 && returnTransitionDetails.controls.selectedGuest.value == '') {
      returnTransitionDetails.controls.selectedGuest.updateValueAndValidity();
      returnTransitionDetails.controls.selectedGuest.markAsTouched();
      return false;
    }
    return true;
  }

  CheckRoomNumber(returnTransitionDetails) {
    if (this.TempRoomNo != '' && this.TempRoomNo != returnTransitionDetails.controls.roomnumber.value) {
      this.selectedDeviceHandle = '';
      this.GuestAccounts = [];
    }
  }

  CheckFinancialBins(RequestBody: SaleRequest) {
    let finBinExist = false
    if (this.IsRefundFlow && this.SelectedPaymentMethodEquals(PaymentMethods.RoomCharge) && RequestBody.inquirerInfo != null
      && RequestBody.inquirerInfo.financialBins != null && RequestBody.inquirerInfo.financialBins.length == 0) {
      finBinExist = true;
    }
    return finBinExist;
  }

  async RequestForSale(ReturnWithTicketResponse, formGroupObj, compScope, saleAmount: number = 0, playerPin = "") {
    const isViaPMSCommunicationReceiver = this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment);
    let receiptText = "";
    if (this.isCheckZoomEnabled && this.IsRoomOrGroupCharge) {

      if (!isViaPMSCommunicationReceiver) {
        receiptText = await this.GetReceiptTextImage(
          this.ReturnWithTicketResponse.transactionData.retailTicketNumber,          
          this.ReturnWithTicketResponse.transactionData.id,          
        );
        const END_OF_LINE_SEQ = "\f"
        receiptText = receiptText.replace(END_OF_LINE_SEQ, '');
      }
    }

    let RequestBody: SaleRequest = await this.FormRequestBody(ReturnWithTicketResponse
      , formGroupObj, compScope, saleAmount, receiptText, playerPin);

    if (!isViaPMSCommunicationReceiver && this.CheckFinancialBins(RequestBody)) {
      return;
    }

    if (this.selectedPaymentMethod != PaymentMethods.IDTECH) {
      this.ShowCardSwipeDialog(formGroupObj, '');
    }

    if (this.isGiftCardSelected && this.IsShift4Giftcard && this.selectedGiftCard) {
      this.selectedGiftCard.zipcode = this.PropertyInfo.PropertyZipCode;
    }    
    
    //Skip PMAgent Logic
    if (this.SkipPMAgent || this.IsHotelCompPayment) {
      if (this.IsRoomOrGroupCharge || this.IsHotelCompPayment) {
        const pmsComunicationReceiverResponse = await this.PostChargesToPMSViaPMSCommunicationSender(RequestBody, Number(this._ss.SelectedOutletId), this.CEDS_ByTerminal);
        this.InvokePayOnPMSPostingViaPMSCommunicationSender(this.ReturnWithTicketResponse?.transactionData?.retailTransactionType, pmsComunicationReceiverResponse.paymentBaseResponse, compScope, compScope.selectedpayment, pmsComunicationReceiverResponse.errorMessage)
        return;
      } else if (this.isGiftCardSelected) {
        this.GiftcardPayment(RetailRoutes.RedeemGiftcard, RequestBody, saleAmount, compScope);
        return;
      }
    }
    
    let saleResponse: Promise<any> = this.PaymentProcessor.RequestSale(RequestBody, Number(this._ss.SelectedOutletId), this.CEDS_ByTerminal);
    saleResponse?.then(response => {
      console.log(response);
      let SaleResult: SaleResponse = response;
      if (SaleResult.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
        if (this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) || this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH)) {
          this.cardDetailsBool = true;
          formGroupObj.patchValue({
            cardtype: SaleResult.cardInfo.issuer.toUpperCase(),
            cardnumber: this.payAgentService.MaskCreditCardNumber(SaleResult.account.id),
            transactionnumber: this.localization.localizeCurrency(SaleResult.amount.requestAmount)
          });
        }

        //Check for partial tender flow
        if (SaleResult.amount && SaleResult.amount.authorisedAmount != RequestBody.amount.requestAmount && this.GatewayConfiguration && this.GatewayConfiguration.isPartialPayAllowed) {
          this.SwitchToMultiPayment(SaleResult, formGroupObj);
          return;
        }

        this.ValidatePayRequest(compScope, formGroupObj, SaleResult, saleAmount);
      } else {
        this.HandleSaleFailure(response);
      }
    });
    saleResponse?.catch(errorResponse => {
      this.HandleSaleFailure(errorResponse);
    });
  }

  async GiftcardPayment(route: RetailRoutes, RequestBody, saleAmount, compScope) {
    try {
      const redeemResponse: ValidatePayResponse = await this._paymentCommunication.postPromise<ValidatePayResponse>({
        route: route,
        body: RequestBody
      });
      const maskCardNumber = (cardNumber: string): string => { return cardNumber?.replace(/.(?=.{4})/g, '*') }
      if (redeemResponse.status) {
        const maskedCardNumber = maskCardNumber(redeemResponse?.paymentManagerResponse?.clientInfo?.accountNumber);
        if (this.isGiftCardSelected && this?.selectedGiftCard?.cardType == GlobalConst.GiftCardType.ExternalGiftCard && this.IsShift4Giftcard) {
          this.selectedGiftCard.maskedCardNumber = maskedCardNumber;
        }
        const SaleResult = {
          cardInfo: {
            maskedCardNumber: maskedCardNumber,
            amount: redeemResponse.paymentManagerResponse.amount,
            account: {
              id: redeemResponse.paymentManagerResponse.clientInfo.accountNumber
            }
          }
        }
        if (redeemResponse?.status && redeemResponse?.transactionId > 0) {
          if (this.IsRefundFlow) {
            await compScope.RefundSale(redeemResponse.transactionId);
          }
          await this.HandlePayRequest(redeemResponse, SaleResult, compScope);
        }
        else {
          this.utils.ToggleLoader(false);
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, redeemResponse.errorMessage);
        }
      }
    }
    catch (error) {

      console.log('Validate Pay Response:' + error.error);
      this.HandlePostSaleFailure(error);
    }
  }

  PerformCloseTransactionUpdate(compScope) {
    const body = {
      transactionId: this.ReturnWithTicketResponse.transactionData.id,
      SettlementHistory: JSON.stringify(this.tempSettlementHistory)
    };
    compScope.InvokeServiceCall("CreateSettlementHistory", HttpMethod.Post, '', body);
    if (!this.PropertyInfo.UseRetailInterface) {
      const uriParams = {
        transactionId: this.ReturnWithTicketResponse.transactionData.id,
        transactionDate: this.ReturnWithTicketResponse.transactionData.transactionDate,
        outletId: this._ss.SelectedOutletId,
        productId: this._ss.ProductId
      };
      compScope.InvokeServiceCall("CloseTransaction", HttpMethod.Put, uriParams);
    }
    this._ss.isfromReturnPage = false;
  }

  /**
   * Helper method which will get triggered either from the moment settlement is completed or Issuance is completed
   * @param transactionId
   * @param retailTicketNumber
   * @returns
   */
  TriggerGiftcardTopup(transactionId, retailTicketNumber, compScope) {
    if (!transactionId || !retailTicketNumber) return
    if (this._ss.selectedProducts.some(x => x.isGiftCardItem && ((x.GiftCardTransactionItem.transactionType == GlobalConst.GiftCardTransactionType.Load) || !x.GiftCardTransactionItem.isIssue))) {
      this.giftcardBusiness.PerformGiftCardLoad(transactionId, retailTicketNumber, this.ReturnWithTicketResponse);
    } else {
      this.PrintReceipts(compScope, this.ReturnWithTicketResponse);
      //Based on switch redirecting to res module after refund          
      if (this._ss.allowAutoCloseTransaction) {
        this.PerformCloseTransaction();
      }
    }
  }

  CheckSettlementStatusAndUpdateButtons(compScope) {
    const formGroupObj = compScope.returnTransitionDetails;
    if (!compScope.IsRefundToOriginalSettlement) {
      this.CalculateSettledAmount(formGroupObj);
    }
    let canPerformAutoRedirect: boolean = false;
    if (this.SettledAmt == this.getSaleAmt()) {
      this.ShowTenderField = false;

      const isThereAnyIssuanceNeedToBeProcessed = (this._ss.selectedProducts.some(x => x.isGiftCardItem && ((x.GiftCardTransactionItem.transactionType == GlobalConst.GiftCardTransactionType.Issue) || x.GiftCardTransactionItem.isIssue)));
      //Processing Issuance First
      if (isThereAnyIssuanceNeedToBeProcessed) {
        this.giftcardBusiness.PerformGiftCardIssue(
          this.ReturnWithTicketResponse.transactionData.id
          , this.ReturnWithTicketResponse.transactionData.retailTicketNumber
        )
      }

      //Skipping Top-up, If any Issuance is processing, this method will be triggered once Issuance is completed.      
      const isThereAnyTopUpNeedToBeProcessed = (this._ss.selectedProducts.some(x => x.isGiftCardItem && ((x.GiftCardTransactionItem.transactionType == GlobalConst.GiftCardTransactionType.Load) || !x.GiftCardTransactionItem.isIssue)));
      if (!isThereAnyIssuanceNeedToBeProcessed && isThereAnyTopUpNeedToBeProcessed) {
        this.TriggerGiftcardTopup(
          this.ReturnWithTicketResponse.transactionData.id
          , this.ReturnWithTicketResponse.transactionData.retailTicketNumber
          , compScope
        );
      }
      this.partialPayment = this.showAllPaymentMethods = this.ShowTenderField = false;
      this.paymentText = this.oCaptions.shop.CloseTransaction;
      this.remainingAmount = 0;
      this.PerformCloseTransactionUpdate(compScope);
      if (this.ReturnWithTicketResponse) {
        this.PerformPostReturnOperation(this._ss.selectedReturnedRetailProducts, this._ss.selectedRetainedRetailProducts);
        if (!isThereAnyIssuanceNeedToBeProcessed && !isThereAnyTopUpNeedToBeProcessed) {
          this.PrintReceipts(compScope, this.ReturnWithTicketResponse);
          // Based on switch redirecting to res module after refund
          if (this._ss.allowAutoCloseTransaction) {
            this.PerformCloseTransaction();
          };
        }
      }
      this.AllowCancel = false;
      this.ShowPaymentMethods = false;
      this.EnableCloseTranBtn = true;
      this.ResetSelectedPaymentMethod();
      const isTransactionHasOnlyLivePayments = this.SettlementHistory.every(f => this.utils.GetOriginalTenderId(f.paymentMethodInfo.paymentMethodId, f.paymentMethodInfo.parentTypeId) == PaymentMethods.RoomCharge || 
                                                                                 this.utils.GetOriginalTenderId(f.paymentMethodInfo.paymentMethodId, f.paymentMethodInfo.parentTypeId) == PaymentMethods.GroupCharge|| 
                                                                                 this.utils.GetOriginalTenderId(f.paymentMethodInfo.paymentMethodId, f.paymentMethodInfo.parentTypeId) == PaymentMethods.HotelComp);
      const isSkipPMAgent = this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment);
      this._ss.GetWebProxyHealthCheckStatusForPMSPostings(isTransactionHasOnlyLivePayments, false, (isTransactionHasOnlyLivePayments && isSkipPMAgent));
      //Retaining SelectedProducts if there is both Issuance AND TopUp involved
      this._ss.selectedProducts = isThereAnyIssuanceNeedToBeProcessed && isThereAnyTopUpNeedToBeProcessed ? this._ss.selectedProducts
        : []; // Resetting, otherwise these items may get displayed in the Shop Screen Cart, if user directly navigates without pressing close button
      if (this.retailValidationService.transactionLockId > 0) {
        this.retailValidationService.RemoveTransactionLock(this.retailValidationService.transactionLockId);
      }          
    } else {
      if (this.partialPayment) {
        formGroupObj.controls.amounttendered.patchValue('');
        this.CalculateRemainingAmount(formGroupObj);
        this._ss.TempTicket = this._ss.Ticket;
        if(this.selectedpayment.tenderReducesDiscount || this.selectedpayment.isAutoRemoveTax){
          this._ss.totalTenderReducesDiscountRatio += this._ss.Ticket.tenderReducesDiscountRatio;
          this._ss.totalTenderReducesTaxRatio +=  this._ss.Ticket.compTaxExemptRatio;
        }
        if (this.selectedpayment.isAutoRemoveTax) {
          this._ss.totalTaxExemptRatio += this._ss.taxExemptRatio;
          this._ss.isTaxRecalculated = true;
          this._ss.selectedTaxExemptPayment = {
            id: 0,
            paymentTypeId: 0,
            paymentMethodId: 0,
            postTypeId: 0,
          };
        }
        this._ss.Ticket.compTaxExemptRatio =0;
			  this._ss.Ticket.tenderReducesDiscountRatio = 0;
        this._ss.remainingAmountAfterPayment = this.remainingAmount;
        compScope.PatchRemainingAmountAndHighlight(this.remainingAmount, true);
        this.currentAmountTendered = this.remainingAmount;
        this.ResetSelectedPaymentMethod();
        this.AllowCancel = !this.IsRefundFlow;
        this.disabledRadiobool = true;
      }
      this.EnableCloseTranBtn = true;
      this.paymentText = this.IsRefundFlow ? this.oCaptions.shop.ContinueRefund : this.oCaptions.shop.ContinuePayment;
    }
    this.cmsPlayerHandles = [];
    this.ShowSettlemtHist = true;
    if (this.giftcardBusiness.CashBackCallRequired) {
      this.giftcardBusiness.PerformGiftCardCashBack(this.selectedGiftCard, this.ReturnWithTicketResponse.transactionData.retailTicketNumber);
    }
  }

  CalculateSettledAmount(returnTransitionDetails) {
    if (returnTransitionDetails && this.IsCompleteReturn && returnTransitionDetails.controls.refundToOriginal.value) { return }
    this.SettledAmt = 0;
    //check if previous settlements are there?
    if (this.SettlementHistory.length > 0) {
      //Get the settled amounts from all settlements
      this.SettlementHistory.forEach((settlemt) => {
        if (!settlemt.isReversed) {
          this.SettledAmt += this.RoundOffTwo(settlemt.amount)
        }
      });
    }
    this.CalculateRemainingAmount(returnTransitionDetails);
    this.SettledAmt = this.RoundOffTwo(this.SettledAmt);
  }

  CalculateRemainingAmount(returnTransitionDetails) {
    if (this.IsRefundFlow) {
      this.remainingAmount = -this.RoundOffTwo((this.getSaleAmt(true) - (isNaN(this.SettledAmt) ? 0 : this.SettledAmt) * -1));
      if (this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption)) {
        returnTransitionDetails.controls.amounttendered.setValidators([Validators.min(this.remainingAmount), Validators.max(0), Validators.required, NegativeValueValidator(this), CMSValidator(this)]);
      }
      else if (this.returnOriginalPaymentOnly || this.IsARPostorCreditBook) {
        returnTransitionDetails.controls.amounttendered.setValidators([Validators.min(this.remainingAmount), Validators.max(0), Validators.required, NegativeValueValidator(this), ReturnSettleValidator(this)]);
      }
      else {
        returnTransitionDetails.controls.amounttendered.setValidators([Validators.min(this.remainingAmount), Validators.max(0), Validators.required, NegativeValueValidator(this)]);
      }
      returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
    } else {
      this.remainingAmount = this.RoundOffTwo(this.getSaleAmt(true) - (isNaN(this.SettledAmt) ? 0 : this.SettledAmt));
      returnTransitionDetails.controls.amounttendered.setValidators([Validators.max(this.remainingAmount), Validators.required]);
      returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
    }
  }

  getSaleAmt(nonNegative: boolean = false) {
    let Amount = this.RoundOffTwo(this._ss.finalAmount);
    if (nonNegative) {
      return Amount < 0 ? Amount * -1 : Amount;
    } else {
      return Amount;
    }
  }

  RoundOffTwo(Amount): number {
    return Number((Amount).customToFixed());
  }

  SwitchToMultiPayment(saleResponse: any, returnTransitionDetails, isPartialPaymentCardOnFile: boolean = false) {
    this.PaymentOption({ id: PaymentOptionType.MultiPayment }, returnTransitionDetails); //This will change the payment mode to Partial Pay.
    this.disabledRadiobool = true;
    returnTransitionDetails.controls.paymentType.setValue(PaymentOptionType.MultiPayment);
    if (saleResponse && saleResponse.amount) {
      returnTransitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(saleResponse.amount.authorisedAmount), { emitEvent: false });
    }
    if (!isPartialPaymentCardOnFile) {
      this.SendTryPayRequest(this.ReturnWithTicketResponse, this.selectedpayment.paymentTypeId, returnTransitionDetails, true, saleResponse);
    }
  }

  AdjustPaymentMethods(skipCmsValidation: boolean = false) {
    let payMethodsToRemove: PaymentMethods[] = [];
    if (this.paymentMethods.length > 0) {
      this.paymentMethods = _.cloneDeep(this.paymentMethodsClone);
      if (!skipCmsValidation) {
        this.cmsBusinessService.ValidateCMSMethodsActivation(this);
        this._memberService.ValidateMembershipPaymentMethodActivation(this);
        this._walletBusiness.ValidateWalletMethodsActivation(this);
      }
    }
    if (this.IsRefundFlow) {
      payMethodsToRemove = [PaymentMethods.PendingSettlement];
      this.paymentMethods = this.paymentMethods.filter((r) => !payMethodsToRemove.includes(this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId)));
      if (payMethodsToRemove.some(x => x == this.selectedPaymentMethod)) {
        this.ResetSelectedPaymentMethod();
      }
    }
  }


  PaymentOption(e: any, returnTransitionDetails) {
    //this.AutoScrollToBottom();
    this.paymentMethods = _.cloneDeep(this.paymentMethods);
    if (e.id === 2) {
      this.partialPayment = true;
      this.FullPayment = false;
      this.ShowTenderField = true;
      returnTransitionDetails.controls.selectedDevice.patchValue('');
      returnTransitionDetails.controls.amounttendered.setValue('', { emitEvent: false });
      returnTransitionDetails.controls.amounttendered.enable();
      returnTransitionDetails.controls.paymentType.patchValue(PaymentOptionType.MultiPayment);
      returnTransitionDetails.updateValueAndValidity();
      //this.paymentMethods = this.paymentMethods.filter(r => r.paymentTypeId != PaymentMethods.PendingSettlement);
      if (this.IsOfferOrCompSlipPaymentMethod) {
        returnTransitionDetails.controls.amounttendered.disable();
      }
      this.CalculateRemainingAmount(returnTransitionDetails);
    } else {
      this.partialPayment = false;
      this.FullPayment = true;
    }
    this.AdjustPaymentMethods();
  }

  async SendTryPayRequest(ReturnWithTicketResponse: any
    , TenderId: number
    , returnTransitionDetails: any
    , skipSale: boolean = false
    , saleResponse = null
    , compScope: any = ''
  ) {

    let SaleAmount: number = 0, playerPin = "";
    if (this.partialPayment) {
      SaleAmount = this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value);
      this.ShowSettlemtHist = true;
    } else {
      SaleAmount = Number(this._ss.finalAmount.customToFixed());
    }

    SaleAmount = this.ValidateSaleAmount(SaleAmount, this);

    if (this.IsCMSRefund && this.isCMSReturnFlow) { //Overriding sale amount for CMS Sale while refund
      SaleAmount = saleResponse.Amount;
      playerPin = saleResponse?.PlayerPin;
    }

    if ((this.IsRefundFlow && this.IsRoomOrGroupCharge) || (this.CurrentTryPayResponse && this.isGiftCardSelected)) {
      this.InitiateSaleReqBasedOnPaymentMethod(returnTransitionDetails, SaleAmount, compScope);
      return;
    }

    const finBinSplitUp = this.paymentMethods.find(x => x.paymentTypeId === TenderId);
    const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    const payMethodAdditionalConfig = this.selectedpayment?.additionalConfigurations ? JSON.parse(this.selectedpayment.additionalConfigurations) : null;
    const multiPropertyPMSIntegrationHostId = this.selectedGuestRoom?.propertyInfo?.pMSIntegrationHostId;
    const PayMethodPMSIntegrationHostId = payMethodAdditionalConfig?.find(x => x.Key === PMSIntegrationHostId);
		let pMsIntegrationHostId = multiPropertyPMSIntegrationHostId? multiPropertyPMSIntegrationHostId : PayMethodPMSIntegrationHostId? PayMethodPMSIntegrationHostId?.Value : propConfig?.PMSIntegrationHostId??'1'
             
    const RequestObj: TryPayRequest = {
      transactionId: ReturnWithTicketResponse.transactionData.id,
      paymentMethodId: this.selectedpayment.id,
      tenderId: this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? this.CurrentActiveCard.accessUrl? PaymentMethods.NonIntegratedCreditCard :PaymentMethods.CreditCard : TenderId,
      parentTenderId: this.selectedpayment?.parentTypeId ?? 0,
      amount: SaleAmount,
      allowEarn: this.selectedpayment.allowEarn,
      surcharge: compScope.surchargeAmount,
      financialBinLevel: finBinSplitUp && finBinSplitUp.financialBinLevel || FinancialBinLevel.Category,
      multiPMSPropCode: this.selectedGuestRoom?.propertyInfo?.PropCode,
      pMSIntegrationHostId: pMsIntegrationHostId,
      isAutoRemoveTax: this.selectedpayment.isAutoRemoveTax,
      isAllowZeroPosting: this.selectedpayment?.allowZeroPosting,
      taxExemptRatio: this._ss.Ticket.compTaxExemptRatio,
      discountExemptRatio: this._ss.Ticket.tenderReducesDiscountRatio,
    }

    const TryPayRoute: string = "TryPay";
    var uriParams = {
      ticketNumber: ReturnWithTicketResponse.transactionData.retailTicketNumber,
      outletId: this._ss.SelectedOutletId,
      checkHandleGuid: ReturnWithTicketResponse.transactionData.checkHandleGuid,
      terminalId: ReturnWithTicketResponse.transactionData.terminalId
    };
    this._isAPICallInProgress = true;
    let Response: any = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: TryPayRoute,
      method: HttpMethod.Post,
      body: RequestObj,
      uriParams: uriParams
    });
    console.log(Response);
    this._isAPICallInProgress = false;
    if (Response && Response.successStatus) {
      this.CurrentTryPayResponse = Response.result;
      this.EnableCloseTranBtn = true;    
      if (!skipSale) {
        this.InitiateSaleReqBasedOnPaymentMethod(returnTransitionDetails, SaleAmount, compScope, playerPin);
      } else {
        if (this.selectedPaymentMethod === PaymentMethods.CreditCard || this.selectedPaymentMethod === PaymentMethods.IDTECH) {
          this.ValidatePayRequest(compScope, returnTransitionDetails, saleResponse);
        }
      }
    }
  }

  GetDetailedFinancialBin() {
    let exchangedItem = [];
    let returned = _.cloneDeep(this._ss.selectedReturnedRetailProducts);
    returned?.map(x => x.isReturn = true);

    exchangedItem = _.cloneDeep(this._ss.selectedExchangeRetailProducts);
    exchangedItem.forEach(x => {
      if (this._ss.Ticket?.lineItems) {
        const item = this._ss.Ticket.lineItems?.find(i => i.externalPOSItemId == x.ExternalPOSItemId);
        x.netPrice = item?.netPrice;
        x.netUnitPrice = item?.netUnitPrice;
        x.totalAmount = item?.amount;
      }
    });
    let paymentAmount = this.getRoomChargeOrGroupChargeTenderedValue(this.ReturnTransitionFormControlObj);
    this.FinancialBinsForRoomCharge = this._financialBinHelper.BuildDetailedFinancialBin(exchangedItem.concat(returned), paymentAmount);
  }

  RefreshFinancialBinWithLatestPaymentAmount() {
    if (this.FinancialBinsForRoomCharge.some(s => s.id)) {
      let paymentAmount = this.getRoomChargeOrGroupChargeTenderedValue(this.ReturnTransitionFormControlObj);
      this.FinancialBinsForRoomCharge = this._financialBinHelper.calculateWeightageAndTallyFinancialBins(this.FinancialBinsForRoomCharge, paymentAmount);
    }
  }


  async getFinancialBin(request: TransactionInput): Promise<POSFinancialBin[]> {
    const reponse = await this.http.CallApiAsync({
      callDesc: RetailRoutes.GetFinancialBins,
      method: HttpMethod.Put,
      host: GlobalConst.Host.retailPOS,
      body: request
    });
    const result = reponse ? reponse.result as any[] : [];
    const financialBins: POSFinancialBin[] = [];
    result.forEach(x => {
      const bin: POSFinancialBin = {
        id: x.id,
        amount: x.amount,
        type: x.type,
        quantity: x.quantity
      };
      financialBins.push(bin);
    });
    this.FinancialBinsForRoomCharge = result;
    return financialBins;
  }

  async getPostingRecords(request): Promise<PostingRecordsAndCheckZoomDetails> {
    const reponse = await this.http.CallApiAsync<PostingRecordsAndCheckZoomDetails>({
      callDesc: RetailRoutes.GetPostingRecords,
      method: HttpMethod.Put,
      host: GlobalConst.Host.retailPOS,
      body: request
    });
    return reponse.result;
  }

  async GetFinancialBins() {
    var uriParams = { parentTransactionId: this._ss.transactionId };

    let Response: any = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: "GetFinancialBins",
      method: HttpMethod.Get,
      uriParams: uriParams
    });
    console.log(Response);
    if (Response && Response.successStatus && Response.result) {
      let CheckData = JSON.parse(Response.result);
      this.FinancialBinsForRoomCharge = CheckData;
    }
  }

  ShowTransactionInprogressDialog(customTransactionMsg = "", apiFlow?: PaymentAPIFlow) {
    this.dialog.closeAll();
    let Popupmessage = customTransactionMsg ? customTransactionMsg : this.localization.captions.shop.TransactionInprogressMsg;
    if (apiFlow && !customTransactionMsg) {
      Popupmessage = this.GetTransactionInProgressMsg(apiFlow, this.selectedPaymentMethod);
    }
    const dataObj = { 'text': Popupmessage, 'buttonname': '', 'headertext': '', 'isloaderenable': true }
    this.roomOpenDialog(dataObj);
  }

  GetTransactionInProgressMsg(apiFlow: PaymentAPIFlow, selectedPaymentType: PaymentMethods) {
    let transMsg = this.localization.captions.shop.TransactionInprogressMsg;
    if (GiftCardMethods.includes(selectedPaymentType)) {
      const apiFlowMsgMapper = {
        [PaymentAPIFlow.Sale]: this.localization.captions.shop.SearchLoaderMsgGiftCardSale,
        [PaymentAPIFlow.Credit]: this.localization.captions.shop.GiftCard.TopUpInprogressMsg,
        [PaymentAPIFlow.ValidatePay]: this.localization.captions.shop.SearchLoaderMsgGiftCardValidateSale
      }
      transMsg = apiFlowMsgMapper[apiFlow] ? apiFlowMsgMapper[apiFlow] : transMsg
    }
    return transMsg;
  }


  /**
   * @description Wrapper method to pass shopbusiness service scope to CMSBusiness
   * @param compScope
   * @param formGroupObj
   */
  PerformCMSRefundFlow(compScope, formGroupObj) {
    this.cmsBusinessService.RefundExistingCMSPayments(this, compScope, formGroupObj);
  }

  /**
  * @description Wrapper method to pass shopbusiness service scope to WalletBusiness
  * @param compScope
  * @param formGroupObj
  */
  PerformWalletRefundFlow(compScope, formGroupObj) {
    this._walletBusiness.RefundExistingWalletPayments(this, compScope, formGroupObj);
  }

  async InitiateSaleReqBasedOnPaymentMethod(returnTransitionDetails, SaleAmount, compScope, playerPin = "") {
    if (!this.ConfirmationPopupShown) {      
      if (this.partialPayment && returnTransitionDetails.controls.amounttendered.value == '') {
        returnTransitionDetails.controls.amounttendered.updateValueAndValidity();
        return;
      }

      if (SaleAmount < 0) {
        if (this.isAdyenWithAllEntryMode() || this.IsRefundToCOF) {
          this.OpenPaymentDialog(compScope, returnTransitionDetails);
          return;
        }
        this.ConfirmationPopupShown = true;
      } else {
        // *** OPEN CONFIRMATION DIALOG BASED ON SELECTED PAYMENT
        if (this.MethodsWhichHasPaymentConfirmationPopup.includes(this.selectedPaymentMethod)
          || this.isAdyenWithAllEntryMode()) {
          this.OpenPaymentDialog(compScope, returnTransitionDetails);
          return;
        }
      }
    }

    if (SaleAmount >= 0) { //Sale
      this.PerformSale(compScope, returnTransitionDetails, SaleAmount, playerPin);
    } else if (SaleAmount < 0) { //Refund      
      this.PerformRefund(compScope, returnTransitionDetails, SaleAmount);
    }

    // Started with Cash / Normal Cash flow
    if (((this.selectedPaymentMethod === PaymentMethods.Cash) && this.ConfirmationPopupShown) || this.isCustomPaymentMethod || this.selectedPaymentMethod === PaymentMethods.NonIntegratedCreditCard) {
      this.ValidatePayRequest(compScope, returnTransitionDetails, '');
    }
  }

  CheckReturnReason(compScope) {
    if (this.IsRefundFlow && compScope.returnReasonElement?.nativeElement && !compScope.returnReasonElement.nativeElement?.value) {
      compScope.returnReasonField.nativeElement.focus();
      this.EnableCloseTranBtn = false;
      return false;
    }
    return true;
  }

  OpenPaymentDialog(compScope, returnTransitionDetails) {
    switch (this.selectedPaymentMethod) {
      case PaymentMethods.Cash:
        this.openCashPaymentPopup(returnTransitionDetails, compScope);
        break;
      case PaymentMethods.CardOnFile:
        this.ShowCardOnFileConfirmationPopup(compScope);
        break;
      case PaymentMethods.V1GiftCard:
      case PaymentMethods.V1GiftCardIdTech:
      case PaymentMethods.ExternalGiftCard:
      case PaymentMethods.ExternalGiftCardIdTech:
      case PaymentMethods.AgilysysGiftCard:
      case PaymentMethods.AgilysysGiftCardIdTech:
        const saleAmount = this.getAmountPaid(returnTransitionDetails);
        this.giftcardBusiness.PerformGiftCardRedemption(compScope, saleAmount, true, returnTransitionDetails);
        break;
      case PaymentMethods.CreditCard:
        if (this.isAdyenWithAllEntryMode()) {
          this.proceedForAdyenGatewayPayment(compScope);
        }
        break;
      case PaymentMethods.ARPost:
      case PaymentMethods.CreditBook:
        if (!this.CheckReturnReason(compScope)) break;
        //Validating If creditbook balance before creating the transaction
        if (this.SelectedPaymentMethodEquals(PaymentMethods.CreditBook)
          && !this._memberService.ValidateCreditBook(
            Math.abs(this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value))
            , this._ss.selectedMemberInfo?.balanceList
            , this.selectedpayment
          )) {
          return;
        }
        compScope.Memberpin = '';
        if (this._ss.selectedProducts && this._ss.memberCardNumber != "0") {
          const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
          if (this._memberService.ValidateInactiveMember(this._ss.memberCardNumber, this._ss.SelectedPlayers, false, corpId)) {
            this._memberService.openmembershippopup(compScope, this._memberService.SetMemberPinValue.bind(compScope));
          }
        }
        break;
      default:
        break;
    }
  }

  isAdyenWithAllEntryMode() {
    return this.GatewayConfiguration &&
      this.GatewayConfiguration.payGatewayID &&
      this.GatewayConfiguration.payGatewayID.trim().toLowerCase() === 'adyen' &&
      this.GatewayConfiguration.cardInputMethod === CardInputMethod.All &&
      this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard);
  }

  PerformSale(compScope, returnTransitionDetails, SaleAmount: number, playerPin = "") {
    switch (this.selectedPaymentMethod) {
      case PaymentMethods.CreditCard:
      case PaymentMethods.V1GiftCard:
      case PaymentMethods.V1GiftCardIdTech:
      case PaymentMethods.ExternalGiftCard:
      case PaymentMethods.ExternalGiftCardIdTech:
      case PaymentMethods.AgilysysGiftCard:
      case PaymentMethods.AgilysysGiftCardIdTech:
        if (this.ReturnWithTicketResponse && this.selectedDeviceHandle) {
          this.RequestForSale(this.ReturnWithTicketResponse, returnTransitionDetails, compScope, SaleAmount);
        }
        break;
      case PaymentMethods.RoomCharge:
      case PaymentMethods.GroupCharge:
      case PaymentMethods.HotelComp:
        if (this.selectedDeviceHandle && this.selectedGuestRoom) {
          if (this.SkipRoomChargePosting) {
            this.GetSelectedGuestHandleAndProceed(compScope, returnTransitionDetails, SaleAmount, this.selectedpayment, this.CurrentTryPayResponse);
          } else {
            this.RequestForSale(this.ReturnWithTicketResponse, returnTransitionDetails, compScope, SaleAmount);
          }
        }
        break;
      case PaymentMethods.IDTECH:
        if (!this.EncryptedCardData) {
          this.CaptureCardWithIDTechDevice(returnTransitionDetails, compScope);
        }
        break;
      case PaymentMethods.CardOnFile:
        this.SaleByToken(compScope);
        break;
      case PaymentMethods.CompRedemption:
        if (this._featureFlagInfo.SkipPMAgentCMS) {
          this.ShowTransactionInprogressDialog();
          this.cmsBusinessService.PerformPointRedemption(this, this.ReturnWithTicketResponse, SaleAmount, true, compScope);
        }
        else {
          this.RequestForSale(this.ReturnWithTicketResponse, returnTransitionDetails, compScope, SaleAmount);
        }
        break;
      case PaymentMethods.OfferRedemption:
      case PaymentMethods.CompSlipRedemption:
        this.ShowTransactionInprogressDialog();
        this.selectedVoucher.playerPin = compScope.CMSPin;
        this.cmsBusinessService.PerformVoucherRedemption(this, this.ReturnWithTicketResponse, true, compScope);
        break;
      case PaymentMethods.ARAcctPost:
        this.PerformARPosting(compScope, returnTransitionDetails);
        break;
      case PaymentMethods.Wallet:
        this.RedeemWallet(compScope, SaleAmount);
        break;
      default:
        break;
    }
  }

  PerformRefund(compScope, returnTransitionDetails, refundAmount: number) {
    switch (this.selectedPaymentMethod) {
      case PaymentMethods.CreditCard:
      case PaymentMethods.ExternalGiftCard:
      case PaymentMethods.ExternalGiftCardIdTech:
        if (this.selectedGiftCard && !this.selectedGiftCard?.isCardActive) {
          this._isAPICallInProgress = true;
          this.giftcardBusiness.PerformGiftCardCredit(this, compScope, this.getAmountPaid(returnTransitionDetails), this.selectedGiftCard, true);
        } else
          this.RequestForCredit(this.ReturnWithTicketResponse, returnTransitionDetails, compScope, refundAmount); //PMAgent Request
        break;
      case PaymentMethods.V1GiftCard:
      case PaymentMethods.V1GiftCardIdTech:
      case PaymentMethods.AgilysysGiftCard:
      case PaymentMethods.AgilysysGiftCardIdTech:
        this._isAPICallInProgress = true;
        this.giftcardBusiness.PerformGiftCardCredit(this, compScope, this.getAmountPaid(returnTransitionDetails), this.selectedGiftCard, true);
        break;
      case PaymentMethods.RoomCharge:
      case PaymentMethods.GroupCharge:
      case PaymentMethods.HotelComp:
        if (this.selectedDeviceHandle != '' && this.selectedGuestRoom) {
          if (this.SkipRoomChargePosting) {
            this.GetSelectedGuestHandleAndProceed(compScope, returnTransitionDetails, this.getAmountPaid(returnTransitionDetails), this.selectedpayment, this.CurrentTryPayResponse);
          } else {
            this.RequestForSale(this.ReturnWithTicketResponse, returnTransitionDetails, compScope);
          }
        }
        break;
      case PaymentMethods.CardOnFile:
        this.CreditByToken(returnTransitionDetails, compScope);
        break;
      case PaymentMethods.IDTECH:
        if (!this.EncryptedCardData) {
          this.CaptureCardWithIDTechDevice(returnTransitionDetails, compScope);
        }
        break;
      case PaymentMethods.CompRedemption:
      case PaymentMethods.OfferRedemption:
      case PaymentMethods.CompSlipRedemption:
        this.PerformCMSRefundFlow(compScope, returnTransitionDetails);
        break;
      case PaymentMethods.ARAcctPost:
        this.PerformARPosting(compScope, returnTransitionDetails);
        break;
      case PaymentMethods.ARPost:
      case PaymentMethods.CreditBook:
      case PaymentMethods.RedeemPoint:
        let originallyPaidAmt = 0, remainingMembersettlement = 0, settledMemberPayment = 0;
        const tenderedValue = Math.abs(this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value));
        let originalTransactionHist = this.TransactionPayments.filter(x => Number(x.paymentMethod) == this.selectedpayment.paymentTypeId);
        originalTransactionHist.forEach(x => originallyPaidAmt = this.RoundOffTwo(originallyPaidAmt + x.paymentAmount));
        remainingMembersettlement = originallyPaidAmt;
        if (this.SettlementHistory.some(x => x.paymentMethodId == this.selectedpayment.paymentTypeId)) {
          let settledMemberHist = this.SettlementHistory.filter(x => x.paymentMethodId == this.selectedpayment.paymentTypeId);
          settledMemberHist.forEach(x => settledMemberPayment = this.RoundOffTwo(settledMemberPayment + x.amount));
          remainingMembersettlement = this.RoundOffTwo(originallyPaidAmt + settledMemberPayment);
          if ((tenderedValue + Math.abs(settledMemberPayment)) == originallyPaidAmt && tenderedValue != originallyPaidAmt && this.SelectedPaymentMethodEquals(PaymentMethods.ARPost)) {
            this.disableMemberPayMethod = true;
          }
          if ((tenderedValue + Math.abs(settledMemberPayment)) == originallyPaidAmt && tenderedValue != originallyPaidAmt && this.SelectedPaymentMethodEquals(PaymentMethods.CreditBook)) {
            this.disableCreditBookMethod = true;
          }
        }
        if (tenderedValue <= remainingMembersettlement && tenderedValue != originallyPaidAmt) {
          const finBinSplitUp = this.paymentMethods.find(x => x.paymentTypeId === this.selectedpayment.paymentTypeId).financialBinLevel;               

          const transactionInput: TransactionInput = {
            id: this.ReturnWithTicketResponse && this.ReturnWithTicketResponse?.id ? this.ReturnWithTicketResponse?.id : this._ss.selectedTransaction.id,
            tenderId: this.selectedpayment.paymentTypeId,
            parentTenderId: this.selectedpayment?.parentTypeId ?? 0,
            amount: this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value),
            binLevelSplitUp: finBinSplitUp || FinancialBinLevel.Category,
            isAllowZeroPosting: this.selectedpayment?.allowZeroPosting
          };
          this.GetGridItems();
          if (this.SelectedPaymentMethodEquals(PaymentMethods.ARPost)) {
            this._memberService.ACESMemberPayment(compScope.Memberpin,
              this._ss.selectedclient,
              this.GridItems,
              this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value),
              this._ss.selectedTransaction.ticketNumber,
              null,
              [],
              true,
              this.SettlementHistory,
              this.selectedpayment,
              this.ValidatePayRequest.bind(this, compScope, returnTransitionDetails),
              true,
              transactionInput
            );
          }
          else if (this.SelectedPaymentMethodEquals(PaymentMethods.CreditBook)) {
            this._memberService.MembershipACESCreditBook(compScope.Memberpin,
              this._ss.selectedclient,
              this.GridItems,
              null,
              this._ss.selectedTransaction.ticketNumber,
              this.selectedpayment,
              this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value),
              this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value),
              this.ValidatePayRequest.bind(this, compScope, returnTransitionDetails)
            );
          }
        }
        else if (tenderedValue == originallyPaidAmt)
          this._memberService.RefundMembershipPayment(compScope, returnTransitionDetails, this);
        break;
      case PaymentMethods.PostToFolio:
        this.ShowTransactionInprogressDialog();
        compScope._folioBusiness.PostFolio(
          compScope, refundAmount, compScope.CreateRetailItemResponse?.transactionData?.id, this, this.ValidatePayRequest.bind(this));
        compScope.ConfirmationPopupShown = false;
        break;
      case PaymentMethods.Wallet:
        this.PerformWalletRefundFlow(compScope, returnTransitionDetails);
        break;
      default:
        break;
    }
  }

  GetGridItems() {
    this.GridItems = [];
    this.GridItems = this._ss.selectedReturnedRetailProducts.map(function (elem, index) {
      return {
        element: elem,
        id: index,
        itemId: elem.ItemId,
        product_id: index,
        itemName: elem.ProductName,
        unitprice: elem.ProductPrice,
        quantity: elem.Noofitems,
        isModificationRestricted: elem.isModificationRestricted,
        isPackagedItem: elem.isPackagedItem,
        packageItemId: elem.PackageItemId,
        isGroupingKey: elem.isGroupingKey,
        ischecked: false,
        PackageGroupId: elem.PackageGroupId,
        Gratuity: elem.Gratuity,
        ServiceCharge: elem.ServiceCharge,
        isOpenPricedItem: elem.isOpenPricedItem,
        retailItemType: elem.retailItemType,
        playerName: elem.playerName,
        isGiftCardItem: elem.isGiftCardItem,
        lineNumber: elem.LineNumber,
        costPrice: elem.costPrice,
        marginPercentage: elem.marginPercentage,
        clientName: elem.clientName
      };
    });
  }

  CheckIfFullMemberRefund(returnTransitionDetails) {
    let originallyPaidAmt = 0, remainingMembersettlement = 0, settledMemberPayment = 0;
    const tenderedValue = Math.abs(this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value));
    let originalTransactionHist = this.TransactionPayments.filter(x => Number(x.paymentMethod) == this.selectedpayment.paymentTypeId);
    originalTransactionHist.forEach(x => originallyPaidAmt = this.RoundOffTwo(originallyPaidAmt + x.paymentAmount));
    return (tenderedValue == originallyPaidAmt) ? true : false;
  }

  async GetSelectedGuestHandleAndProceed(compScope, returnTransitionDetails, postingAmount: number, selectedPayment, currentTryPayResponse) {
    const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    let posConstructedResponse: PostingRecordsAndCheckZoomDetails;
    let amountPaid = 0;
    if (this.selectedDeviceHandle && this.selectedGuestRoom) {
      this._isAPICallInProgress = true;
      this.ShowTransactionInprogressDialog();
      console.log("Before GetWebProxyHealthCheckStatus");
      let isWebProxyup = false;
      const isSkipPMAgent = this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment);

      if (isSkipPMAgent) {
        if (this.partialPayment) {
          let AmountTendered = this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value);
          amountPaid = AmountTendered < 0 ? AmountTendered * -1 : AmountTendered;
        } else {
          amountPaid = this.getSaleAmt(true);
        }
        if (this.IsRoomOrGroupCharge) {
          const finBinSplitUp = this.paymentMethods.find(x => x.paymentTypeId === this.selectedpayment.paymentTypeId).financialBinLevel;
          const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
          const payMethodAdditionalConfig = this.selectedpayment?.additionalConfigurations ? JSON.parse(this.selectedpayment.additionalConfigurations) : null;
          const multiPropertyPMSIntegrationHostId = this.selectedGuestRoom?.propertyInfo?.pMSIntegrationHostId;
          const PayMethodPMSIntegrationHostId = payMethodAdditionalConfig?.find(x => x.Key === PMSIntegrationHostId);
		      let pMsIntegrationHostId = multiPropertyPMSIntegrationHostId? multiPropertyPMSIntegrationHostId : PayMethodPMSIntegrationHostId? PayMethodPMSIntegrationHostId?.Value : propConfig?.PMSIntegrationHostId??'1'
    
          const transactionInput = {
            transactionId: this.ReturnWithTicketResponse.id,
            paymentTypeId: this.selectedpayment.paymentTypeId,
            parentTypeId: this.utils.GetOriginalTenderId(this.selectedpayment.paymentTypeId, this.selectedpayment.parentTypeId),
            paymentAmount: this.IsRefundFlow && (this.IsRoomOrGroupCharge || this.IsHotelCompPayment) ? -amountPaid : amountPaid,
            binLevelSplitUp: finBinSplitUp,
            multiPMSPropCode: this.selectedGuestRoom?.propertyInfo?.PropCode,
            pMSIntegrationHostId: pMsIntegrationHostId
          };
          posConstructedResponse = await this.getPostingRecords(transactionInput);
        }
      }

      isWebProxyup = isSkipPMAgent ? true : await this._ss.GetWebProxyHealthCheckStatusForPMSPostings(true, false, isSkipPMAgent);
      if (isWebProxyup) {
        console.log("WebProxy HealthCheck success!, firing PostChargesToPMS");
        let isPostingSuccess = await this.PostChargesToPMS(
          this.ReturnWithTicketResponse.transactionData.id
          , this.selectedPaymentMethod
          , postingAmount
          , this.selectedGuestRoom
          , this.CurrentTryPayResponse ? this.CurrentTryPayResponse?.transactionPaymentId : 0
          , this.selectedpayment.paymentTypeId
          , this.ReturnWithTicketResponse?.transactionData?.retailTicketNumber
          , this.ReturnWithTicketResponse?.transactionData?.retailTransactionType
          , compScope
          , selectedPayment
          , posConstructedResponse
        );
        this._isAPICallInProgress = false;
        console.log("PostChargesToPMS response:" + isPostingSuccess);
        if (isPostingSuccess) {
          if (!isSkipPMAgent) {
            this.ValidatePayRequest(compScope, returnTransitionDetails, '', 0, '');
          }
        } else {
          this.HandlePostSaleFailure("");
        }
      }
      else {
        console.log("WebProxy HealthCheck failed, hence PostChargesToPMS not fired!");
        this.dialog.closeAll();
        this._isAPICallInProgress = false;
      }
    }
  }

  async PerformARPosting(compScope, returnTransitionDetails) {
    this.ShowTransactionInprogressDialog();
    const finBinSplitUp = this.paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) === PaymentMethods.ARAcctPost);
    const postResponse = await this._arPostingBusiness.PerformARPosting(this, this.getAmountPaid(returnTransitionDetails),
      finBinSplitUp && finBinSplitUp.financialBinLevel || FinancialBinLevel.Category, true);
    if (postResponse && postResponse.postingStatus && (postResponse.postingStatus.toLowerCase() == HttpResponseStatus.Success || postResponse.postingStatus.toLowerCase() === 'true')) {
      this.currentARPostResponse = postResponse;
      this.ValidatePayRequest(compScope, returnTransitionDetails, '');
    } else {
      this.HandlePostSaleFailure("");
    }
  }

  async RedeemWallet(compScope: any, saleAmount: number) {
    this.ShowTransactionInprogressDialog();
    let cardNumber = this.returnTransitionDetails.controls.walletInput.value;
    let retailTicketNumber = this.ReturnWithTicketResponse.transactionData.retailTicketNumber;
    let tenderId = this.selectedpayment.paymentTypeId;
    let paymentMethodId = this.selectedpayment.id;

    const postResponse = await this._walletBusiness.RedeemWallet(cardNumber, saleAmount, retailTicketNumber, tenderId, paymentMethodId);
    if (postResponse && postResponse.status) {
      const validatePayResponse: ValidatePayResponse = {
        paymentManagerResponse: null,
        status: postResponse.status,
        transactionId: postResponse.transactionId
      };

      this.returnTransitionDetails.controls.walletInput.patchValue('');
      this.HandlePayRequest(validatePayResponse, postResponse, compScope);
    } else {
      if (postResponse && postResponse.debugMessage) {
        this.dialog.closeAll();
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.replacePlaceholders(postResponse.debugMessage, ["paymentmethod"], [this.localization.captions.shop.paymentMethods[this.selectedPaymentMethod]]));
      } else {
        this.HandlePostSaleFailure("");
      }
    }
  }

  ShowCardOnFileConfirmationPopup(compScope) {
    const dialogRef = this.dialog.open(CarddetailsPopupComponent, {
      height: 'auto',
      width: '40%',
      data: { CurrentActiveCard: this.CurrentActiveCard, IsExpireDateVisible: false },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(resultData => {
      this.ProceedCardOnFile(resultData.action, compScope);
    });
  }

  CheckForCardOnFile(payeeDetail: PayeeInfo) {
    // Check if CardOnFile included in payment method, if yes remove it
    const RemoveCOF = (collection) => {
      let filter = collection?.filter((method) => this.utils.GetOriginalTenderId(method?.paymentTypeId, method?.parentTypeId) == PaymentMethods.CardOnFile);
      if (filter?.length > 0) {
        console.log("Removing COF method, since payee don't have COF");
        collection.splice(collection.indexOf(filter[0]), 1);
      }
    }
    if (payeeDetail) {
      this.CardOnFileforSelectedGuest = payeeDetail.cardInfo.find(x => x.isActive);
      let CardOnFile: PaymentMethod = {
        id: PaymentMethods.CardOnFile,
        paymentTypeId: PaymentMethods.CardOnFile,
        paymentMethod: this.localization.captions.shop.paymentMethods[PaymentMethods.CardOnFile],
        type: 'Card',
        isActive: true,
        allowEarn: false
      };

      if (this.CardOnFileforSelectedGuest) { // Card on file exists then Add payment method
        const AddCOF = (collection: PaymentMethod[]) => {
          const creditCard = collection.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.CreditCard);
          const isCardPaymentAllowed = creditCard && creditCard.isActive;
          let nonIntegratedCreditCard = this.paymentMethodSource.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.NonIntegratedCreditCard);
          const isNonIntegratedCard = nonIntegratedCreditCard && nonIntegratedCreditCard.isActive
          CardOnFile.requireReceipts = creditCard?.requireReceipts;
          if (!collection.some((r) => this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId) == PaymentMethods.CardOnFile) && (isCardPaymentAllowed || isNonIntegratedCard)) {
            collection.push(CardOnFile);
          }
          if (!collection.some((r) => this.utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId) == PaymentMethods.NonIntegratedCreditCard) && isNonIntegratedCard) {
            nonIntegratedCreditCard = this.AssignAdditionalInfoToPaymentMethod(nonIntegratedCreditCard);
            collection.push(nonIntegratedCreditCard);
          }
        }
        AddCOF(this.paymentMethodsClone);
        AddCOF(this.paymentMethods);
      } else { // User may have selected a guest without having card on file
        RemoveCOF(this.paymentMethodsClone);
        RemoveCOF(this.paymentMethods);
      }
    } else { // User may have removed the selected a guest
      RemoveCOF(this.paymentMethodsClone);
      RemoveCOF(this.paymentMethods);
    }
  }

  AssignAdditionalInfoToPaymentMethod(nonIntegratedCreditCard:any){
    if (PaymentMethodIconConst[nonIntegratedCreditCard.paymentTypeId]) {
      nonIntegratedCreditCard.type = PaymentMethodIconConst[nonIntegratedCreditCard.paymentTypeId];
    } else if (PaymentMethodIconConst[nonIntegratedCreditCard.parentTypeId]) {
      nonIntegratedCreditCard.type = PaymentMethodIconConst[nonIntegratedCreditCard.parentTypeId];
    } else {
      const otherPaymentMethod = 44; //WE DON'T HAVE ANY PAYMENT METHOD CALLED 'OTHER' SO JUST ADDING A const AS 44
      nonIntegratedCreditCard.type = PaymentMethodIconConst[otherPaymentMethod];
    }
    let paymentMethodName = myGlobals.PaymentMethodValue[nonIntegratedCreditCard.paymentTypeId];
    if (!this.IsFolioPaymentMethod && nonIntegratedCreditCard.paymentMethod == paymentMethodName) {
      nonIntegratedCreditCard.paymentMethod = this.ShopCaptions.paymentMethods[nonIntegratedCreditCard.paymentTypeId];
    } else {
      nonIntegratedCreditCard.paymentMethod = nonIntegratedCreditCard.paymentMethod;
    }       
    const additionalConfig = nonIntegratedCreditCard.additionalConfigurations ? JSON.parse(nonIntegratedCreditCard.additionalConfigurations) : null;
    const EnableZeroPostingConfigValues = additionalConfig?.find(x => x.Key.trim().toUpperCase() === EnableZeroPosting);
    nonIntegratedCreditCard.allowZeroPosting = additionalConfig ? EnableZeroPostingConfigValues?.Value.trim().toLowerCase() == 'true' || nonIntegratedCreditCard.paymentTypeId == PaymentMethods.Cash : false;
    return nonIntegratedCreditCard;
  }
  ShowCardSwipeDialog(returnTransitionDetails, compScope) {
    if (this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) || this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH)) {
      const Popupmessage = this.isManualKeyInCardEntryMode() ?
        this.localization.captions.shop.KeyInCardMessage : this.localization.captions.shop.SwipeCardMessage;
      const isIDTech: boolean = (this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH));
      const dataObj = {
        'text': Popupmessage,
        'buttonname': isIDTech ? this.localization.captions.common.Close : '',
        'headertext': '',
        'isloaderenable': true,
        'isHiddenFieldRequired': isIDTech,
        cardpayment: true,
        isManualEntry: this.isManualKeyInCardEntryMode()
      };
      this.roomOpenDialog(dataObj, returnTransitionDetails, compScope);
    } else {
      this.ShowTransactionInprogressDialog("", this.IsRefundFlow ? PaymentAPIFlow.Credit : PaymentAPIFlow.Sale);
    }
  }

  CaptureCardWithIDTechDevice(returnTransitionDetails, compScope) {
    this.ShowCardSwipeDialog(returnTransitionDetails, compScope);
    setTimeout(() => {
      if (this.selectedPaymentMethod === PaymentMethods.IDTECH && !this.EncryptedCardData && !this.ManualClosePerformed) {
        this.dialog.closeAll();
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(10725));
      }
    }, GlobalConst.IDTechCardSwipeTimeOut * 1000);
  }

  async FormRequestBody(ReturnWithTicketResponse
    , returnTransitionDetails
    , compScope
    , saleAmount = 0
    , receiptText = ""
    , playerPin = ""
  ): Promise<SaleRequest> {
    // let checkData = JSON.parse(ReturnWithTicketResponse.transactionData.checkData);
    let posConstructedResponse: any;
    let AmountPaid = 0;
    if (this.partialPayment) {
      let AmountTendered = this.localization.currencyToSQLFormat(returnTransitionDetails.controls.amounttendered.value);
      AmountPaid = AmountTendered < 0 ? AmountTendered * -1 : AmountTendered;
    } else {
      AmountPaid = this.getSaleAmt(true);
    }
    if (this.IsCMSRefund && saleAmount != 0) {
      AmountPaid = saleAmount;
    }


    let FinancialBins = [];
    let pmsIntegrationHostId = '1';
    const originalTenderId = this.utils.GetOriginalTenderId(this.selectedpayment.paymentTypeId, this.selectedpayment.parentTypeId);
    const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    const payMethodAdditionalConfig = this.selectedpayment?.additionalConfigurations ? JSON.parse(this.selectedpayment.additionalConfigurations) : null;    
    const isPMSCommunicationViaReceiver = this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment);

    if((this.IsRoomOrGroupCharge && isPMSCommunicationViaReceiver) || this.IsHotelCompPayment){
      const finBinSplitUp = this.paymentMethods.find(x => x.paymentTypeId === this.selectedpayment.paymentTypeId).financialBinLevel;
      if(this.IsRefundFlow){          
          const transactionInput = {
            transactionId: this.ReturnWithTicketResponse.id,
            paymentTypeId: this.selectedpayment.paymentTypeId,
            parentTypeId: this.utils.GetOriginalTenderId(this.selectedpayment.paymentTypeId, this.selectedpayment.parentTypeId),
            paymentAmount: this.IsRefundFlow && (this.IsRoomOrGroupCharge || this.IsHotelCompPayment) ? -AmountPaid : AmountPaid,
            binLevelSplitUp: finBinSplitUp,
            multiPMSPropCode: this.selectedGuestRoom?.propertyInfo?.PropCode,
            pMSIntegrationHostId: pmsIntegrationHostId
          };
          posConstructedResponse = await this.getPostingRecords(transactionInput); 
          FinancialBins = posConstructedResponse?.financialBins;
          receiptText = posConstructedResponse?.checkZoomString??'';
      }
      else {
        FinancialBins = this.CurrentTryPayResponse?.financialBins;
        receiptText = this.CurrentTryPayResponse?.checkZoomString ?? '';
      }
      const END_OF_LINE_SEQ = "\f"
      receiptText = receiptText.replace(END_OF_LINE_SEQ, '');
    }

    AmountPaid = this.isGiftCardSelected && saleAmount ? Number(saleAmount.customToFixed()) : Number(AmountPaid.customToFixed());
    if (this.IsRoomOrGroupCharge && !isPMSCommunicationViaReceiver) {
      const finBinSplitUp = this.paymentMethods.find(x => x.paymentTypeId === this.selectedpayment.paymentTypeId).financialBinLevel;     

      const transactionInput: TransactionInput = {
        id: this.ReturnWithTicketResponse.id,
        tenderId: PaymentMethods.ARAcctPost,
        parentTenderId: this.selectedpayment?.parentTypeId ?? 0,
        amount: this.IsRefundFlow && this.IsRoomOrGroupCharge ? -AmountPaid : AmountPaid,
        binLevelSplitUp: finBinSplitUp,
        isAllowZeroPosting: this.selectedpayment?.allowZeroPosting
      };
      FinancialBins = this.FinancialBinsForRoomCharge = await this.getFinancialBin(transactionInput);
    }

    // Taking the Financial Bin Id to be Absolute Value
    // as for Gratuity and Service Charge they will be aving Id as a negative value.
    // Hence, they will not be posted successfully using the IG-V1 Room Posting XML
    FinancialBins.forEach(element => {
      if (element.Id < 0) {
        element.Id = Math.abs(element.Id)
      }
    });

    let ZipcodeValue = this.IsShift4Giftcard ? this.PropertyInfo.PropertyZipCode : "";
    if (returnTransitionDetails.controls.zipcode.value) {
      ZipcodeValue = returnTransitionDetails.controls.zipcode.value.replace(/[^\d]/g, '')
    }
    let outletDetail = this._ss.GetSelectedOutletDetail();
    let outletProfitCenter = (outletDetail && outletDetail?.profitCenter) ? outletDetail.profitCenter : "0";
    const temppCenter = this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) && this._featureFlagInfo.CMSProfitCenterId
      ? this._featureFlagInfo.CMSProfitCenterId : outletProfitCenter;
    this.profitCenter = this.profitCenter != "" ? this.profitCenter : outletProfitCenter;
    let profitCenter = this.IsRoomOrGroupCharge || this.IsHotelCompPayment? this.profitCenter
      : temppCenter;
    const tempCenterName = this._ss.AllOutlets && this._ss.AllOutlets.some(o => o.subPropertyID == this._ss.SelectedOutletId)
      ? this._ss.AllOutlets.find(o => o.subPropertyID == this._ss.SelectedOutletId).subPropertyName : "";
    let profitCenterName = this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) ? this._featureFlagInfo.CMSProfitCenterName
      : tempCenterName;
    const retailTicketNumber = this.ReturnWithTicketResponse?.transactionData?.retailTicketNumber;
    const updatedOrderNum = `${retailTicketNumber}${this.utils.GetRandomNumber(6)}`?.slice(0, 9);


		if(originalTenderId == PaymentMethods.RoomCharge || originalTenderId == PaymentMethods.GroupCharge || originalTenderId == PaymentMethods.HotelComp){
      const multiPropertyPMSIntegrationHostId = this.selectedGuestRoom?.propertyInfo?.pMSIntegrationHostId;
      const PayMethodPMSIntegrationHostId = payMethodAdditionalConfig?.find(x => x.Key === PMSIntegrationHostId);
      pmsIntegrationHostId = multiPropertyPMSIntegrationHostId? multiPropertyPMSIntegrationHostId : PayMethodPMSIntegrationHostId? PayMethodPMSIntegrationHostId?.Value : propConfig?.PMSIntegrationHostId??'1'
    }

    let searchType = this.selectedGuestRoom?.SearchType;

    return {
      handle: this.selectedDeviceHandle,
      originalTenderId: this.selectedPaymentMethod,
      inquirerInfo: {
        terminalId: "1", //By Default terminal Id is 1
        store: String(this._ss.SelectedOutletId),
        ticketNumber: retailTicketNumber ? retailTicketNumber : "",
        stayId: "",
        orderNumber: ExternalGCMethods.includes(this.selectedPaymentMethod) ||
          V1GiftCardMethods.includes(this.selectedPaymentMethod) || this.IsRoomOrGroupCharge || this.IsHotelCompPayment
          ? updatedOrderNum : retailTicketNumber,
        profitCenter: String(profitCenter ?? 0),
        profitCenterName: profitCenterName,
        financialBins: FinancialBins,
        zipcode: this.GatewayConfiguration && this.GatewayConfiguration.isAVSActive || this.IsShift4Giftcard ? ZipcodeValue : "",
        isPartialTenderAllowed: this.GatewayConfiguration && this.GatewayConfiguration.isPartialPayAllowed,
        tenderId: String(this.selectedpayment.paymentTypeId),
        id: this.isGiftCardSelected && this.selectedGiftCard ? this.selectedGiftCard.cardNumber : "",
        zoneOffset: this.isGiftCardSelected && this._featureFlagInfo.ZoneOffset ? this._featureFlagInfo.ZoneOffset : "",
        employeeId: "0",
        customer: "0",
        manualCardEntry: this.isManualKeyInCardEntryMode(),
        ReceiptTextImage: receiptText,
        pin: this.isCMSReturnFlow && this._featureFlagInfo.CMSRequirePin ? playerPin : compScope.CMSPin,
        clientId: profitCenterName,
        postingId: " - #" + retailTicketNumber,
        posTransactionId: this.ReturnWithTicketResponse.id,
        propertyInfo: this.selectedGuestRoom?.propertyInfo,

        //Tip Properties
				tipBasisAmount : AmountPaid,
				tipPresetValueType : this.tipConfig?.isPercentage ? TipPresetValueType.Percent : TipPresetValueType.Amount,
				tipPreset1: this.tipConfig?.presetValue[0] ?? 0,
				tipPreset2: this.tipConfig?.presetValue[1] ?? 0,
				tipPreset3: this.tipConfig?.presetValue[2] ?? 0,
				tipPreset4: this.tipConfig?.presetValue[3] ?? 0
      },
      paymentDetails: {
        firstName: this.isGiftCardSelected ? this.selectedGiftCard.firstName : "",
        lastName: this.isGiftCardSelected ? this.selectedGiftCard.lastName : "",
        email: this.isGiftCardSelected ? this.selectedGiftCard.email : "",
        phoneNumber: this.isGiftCardSelected ? this.selectedGiftCard.phoneNumber : "",
        parentTenderId: this.selectedpayment?.parentTypeId ?? 0,
        paymentMethodId: this.selectedPaymentMethod,
				paymentMethod: String(this.selectedpayment?.id),
        screenRoute: this.router.url,
        amount: AmountPaid
      },
      amount: {
        requestAmount: this.IsRefundFlow && (this.IsRoomOrGroupCharge || this.IsHotelCompPayment) ? -AmountPaid : AmountPaid,
      },
      transactionAmount: this.IsRefundFlow && (this.IsRoomOrGroupCharge || this.IsHotelCompPayment) ? -AmountPaid : AmountPaid,
      transactionId: this.ReturnWithTicketResponse?.id,
      ticketNumber: Number(this.ReturnWithTicketResponse?.transactionData?.retailTicketNumber ?? "0"),
      paymentMethodId: this.selectedpayment.paymentTypeId,
      transactionPaymentId: this.IsRefundFlow ? posConstructedResponse?.transactionPaymentId ?? 0 : this.CurrentTryPayResponse?.transactionPaymentId ?? 0,
      paymentMethodTableId: this.selectedpayment?.id ?? 0,
      paymentTypeId: this.selectedpayment?.paymentTypeId ?? 0,
      parentTypeId: this.selectedpayment?.parentTypeId ?? 0,
      roomNumber: this.selectedGuestRoom?.RoomNumber ?? '',
      roomReference: this.selectedGuestRoom?.RoomReference ?? '',
      guestName: this.selectedGuestRoom?.GuestName ?? '',
      paymentReferenceComments: compScope?.paymentComments ?? '',
      agentVersion: this.agentVersion,
      hostName: this.hostName,
      ipAddress: this.ipAddress,
      postingRecords: this.IsRefundFlow ? posConstructedResponse?.postingRecords : this.CurrentTryPayResponse?.postingRecords,
      pMSIntegrationHostId: pmsIntegrationHostId,
      paymentMethodAdditionalConfigurations: payMethodAdditionalConfig,
      searchValue: this.selectedGuestRoom?.SearchValue,
      searchType: searchType != null ? searchType : SearchTypes.None,
      bookingId: this.selectedGuestRoom?.HotelId ?? ''
    };
  }

  async RequestForCredit(ReturnWithTicketResponse, returnTransitionDetails, CompScope, refundAmount = 0) {
    let RequestBody: SaleRequest = await this.FormRequestBody(ReturnWithTicketResponse, returnTransitionDetails, CompScope);

    if (this.selectedPaymentMethod != PaymentMethods.IDTECH) {
      this.ShowCardSwipeDialog(returnTransitionDetails, '');
    }
    //SkipPMAgent flow for Giftcard
			if (this.SkipPMAgent && this.isGiftCardSelected) {
        this.GiftcardPayment(RetailRoutes.GiftcardCredit, RequestBody, refundAmount, CompScope);
				return;
			}
    let CreditResponse: Promise<any> = this.PaymentProcessor.RequestCredit(RequestBody, Number(this._ss.SelectedOutletId), this.CEDS_ByTerminal);

    CreditResponse.then(response => {
      console.log(response);
      let CreditResult: SaleResponse = response;
      if (CreditResult.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
        if (this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) || this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH)) {
          this.cardDetailsBool = true;
          returnTransitionDetails.patchValue({
            cardtype: CreditResult.cardInfo.issuer.toUpperCase(),
            cardnumber: this.payAgentService.MaskCreditCardNumber(CreditResult.account.id),
            transactionnumber: this.localization.localizeCurrency(CreditResult.amount.requestAmount)
          });
        }

        this.ValidatePayRequest(CompScope, returnTransitionDetails, CreditResult);
      }
    });

    CreditResponse.catch(errorResponse => {
      this.HandleSaleFailure(errorResponse);
    });
  }

  async ValidatePayRequest(
    CompScope
    , formGroupObj
    , SaleResult?
    , saleAmount = 0
    , voucherRedeemTransactionId: string = ""
    , compRedeemTransactionId: string = ""
  ) {
    if (!(this.IsRoomOrGroupCharge && this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment))) {
      if (Array.isArray(SaleResult) && SaleResult.length) SaleResult = SaleResult[0];
      this.disabledRadiobool = true; // disabling full/multiple option
      const currentSaleAmt = this.getAmountPaid(formGroupObj, SaleResult ? SaleResult.amount : '', 'authorisedAmount', saleAmount);
      let roomNo = null, guestName = null, roomReference = null, searchValue = null, searchType = null;
      if (this.IsRoomOrGroupCharge && this.selectedGuestRoom) {
        roomNo = this.selectedGuestRoom.RoomNumber;
        guestName = this.selectedGuestRoom.GuestName;
        roomReference = this.selectedGuestRoom.RoomReference;
        searchValue = this.selectedGuestRoom?.SearchValue;
				searchType = this.selectedGuestRoom?.SearchType;
      }
      if (this.ReturnWithTicketResponse != null) {
        let validatePayReq: ValidatePayRequest = {
          paymentMethodId: this.selectedpayment.id,
          tenderId: this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? PaymentMethods.CreditCard : this.selectedpayment.paymentTypeId,
          parentTenderId: this.selectedpayment.parentTypeId,
          payAgentResponse: this.NonPayAgentMethods.includes(this.selectedPaymentMethod)
            || this.isCustomPaymentMethod || !SaleResult || this.SkipRoomChargePosting ||
            (this.selectedPaymentMethod == PaymentMethods.CompRedemption && this._featureFlagInfo.SkipPMAgentCMS) 
            || this.SkipPMAgent  ? null : {
            payAgentID: SaleResult.payAgentId,
            status: SaleResult.status,
            transactionDetails: SaleResult.transactionDetails,
            transactionKey: SaleResult.transactionKey,
            errorMessage: SaleResult.errorMessage
          },
          gatewayResponse: this.SkipPMAgent && SaleResult ? SaleResult.transactionDetails : "",
          cardInfo: this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) || this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH) ? {
            cardNumber: SaleResult.account.id,
            cardHolderName: SaleResult.account.name,
            entryMode: SaleResult.cardInfo.entryMode,
            issuerType: SaleResult.cardInfo.issuer,
            cardExpiration: SaleResult.cardInfo.cardExpiration,
            cardType: SaleResult.cardInfo.cardType
          } : null,
          amount: currentSaleAmt,
          surcharge: CompScope.surchargeAmount,
          aCESPaymentRecord: this.SelectedPaymentMethodEquals(PaymentMethods.ARPost) && SaleResult ? [SaleResult] : null,
          cMSPaymentRecord: this.cmsBusinessService.ConstructCMSPaymentRecord(this, CompScope, SaleResult, voucherRedeemTransactionId, compRedeemTransactionId, formGroupObj, saleAmount),
          giftcardPaymentRecord: this.giftcardBusiness.ConstructGiftcardPaymentRecord(this.selectedGiftCard, currentSaleAmt),
          aRPostingPaymentRecord: this.currentARPostResponse,
          roomNumber: roomNo,
          reservationName: guestName,
          roomReference: roomReference,
          referenceComment: this.IsRefundFlow && this.selectedpayment.paymentTypeId == PaymentMethods.NonIntegratedCreditCard ? `${this.localization.captions.shop.nonIntegratedPaymentScreen.returnNoteMessage} | ${CompScope?.paymentComments}` : CompScope?.paymentComments ? CompScope.paymentComments : "",
          hostName: SaleResult?.hostName,
          agentVersion: SaleResult?.agentVersion,
          cashPaymentDetails: !this.IsRefundFlow && this.selectedpayment.paymentTypeId == PaymentMethods.Cash || this.selectedpayment.parentTypeId == PaymentMethods.Cash
            && CompScope.cashPaymenHandleData?.paymentStaus == 'cashpaid' ? {
            amountDue: CompScope.cashPaymenHandleData?.amtRequested,
            cashReceived: CompScope.cashPaymenHandleData?.amtReceived,
            changeDue: CompScope.cashPaymenHandleData?.amtBalanceDue,
            isReturn: false
          } : null,
          ipAddress: SaleResult?.ipAddress,
          requestId: SaleResult?.requestId,
          guestGuid: CompScope?.selectedClient?.guestProfileId,
          transactionState: SaleResult?.transactionState,
          searchValue: searchValue,
          searchType: searchType != null ? searchType : SearchTypes.None,
          tokenRefId: this.nonIntegratedPaytransId > 0 && this.isNonIntegratedCreditCard ? this.nonIntegratedPaytransId : this.CardOnFileforSelectedGuest?.tokenTransId,
          sourceTypeId:this.ReturnWithTicketResponse?.transactionData?.retailTicketNumber,
          sourceType: SourceTypeConstant.RetailTransaction.toString()
        }
        this.ShowTransactionInprogressDialog("", PaymentAPIFlow.ValidatePay);
        const validatePayRoute = this.SkipPMAgent && CardPaymentMethods.includes(this.selectedPaymentMethod) ? RetailRoutes.ValidatePayV2 : RetailRoutes.RetailValidatePay;
        try {
          const validatePayResponse: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: validatePayRoute,
            method: HttpMethod.Post,
            body: validatePayReq,
            uriParams: { outletId: this._ss.SelectedOutletId },
            showError: true
          });
          console.log(validatePayResponse.result);
          const validatePayResult: ValidatePayResponse = validatePayResponse.result;
          this._ss.paymentTransacId = validatePayResult.transactionId;
          if (validatePayResult.status) {
            if (this.isTipApplicable) {
              this._ss.AutoApplyTipAsGratuity.emit({
                paymentTransactionId: validatePayResult.transactionId,
                quickUserId: Number(this.quickIdUser),
                tipAmount: SaleResult.amount?.tipAmount
              });
            }            
            if (this.isGiftCardSelected && this.selectedGiftCard?.cardType == GlobalConst.GiftCardType.ExternalGiftCard && this.IsShift4Giftcard) {
              const maskCardNumber = (cardNumber: string): string => { return cardNumber?.replace(/.(?=.{4})/g, '*') }
              this.selectedGiftCard.maskedCardNumber = maskCardNumber(validatePayResult?.paymentManagerResponse?.clientInfo?.accountNumber);
            }
            //New flow for CMS Return handling
            if (this.IsCMSRefund) {
              this.isCMSReturnFlow = false;
              this.cmsBusinessService.UpdateSuccessfulCMSPayment(this, CompScope, validatePayResult);
              return;
            }
            this.ConstructSettlementHistory(CompScope, validatePayResult, SaleResult);
            if (this.IsRefundFlow) {
              this.AddSettlemtAndShowDialog(SaleResult, CompScope, validatePayResult);
              await CompScope.RefundSale(validatePayResult.transactionId);
              if (this.disableMemberPayMethod) {
                this._memberService.DisableMemberPaymentsIfSettled(this);
                this.disableMemberPayMethod = false;
              }
              if (this.disableCreditBookMethod) {
                this._memberService.DisableCreditBookIfSettled(this);
                this.disableCreditBookMethod = false;
              }
            } else {
              if (!CompScope) {
                CompScope = { returnTransitionDetails: formGroupObj };
              }
              this.HandlePayRequest(validatePayResult, SaleResult, CompScope);
            }
          }
        } catch (error) {
          console.log('Validate Pay Response:' + error.error);
          this.HandlePostSaleFailure(error);
        }
      }
    }
  }

  ConstructSettlementHistory(CompScope, validatePayResult: ValidatePayResponse, SaleResult) {
    const CardMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH]
    const IsCardType: boolean = (CardMethods.includes(this.selectedPaymentMethod));
    const cmsRedemption = CompScope.CMSForm && CompScope.CMSForm.controls["cmsPaymentSelection"].value;
    const patronIdVal: string = this.selectedCMSPlayer && this.selectedCMSPlayer.PatronId;
    const voucherName: string = this.selectedVoucher && this.selectedVoucher.name;
    const tempIssueType = this.utils.GetOriginalTenderId(this.selectedpayment.paymentTypeId, this.selectedpayment.parentTypeId) === PaymentMethods.CardOnFile ? this.CurrentActiveCard.issuerType : "";
    const tempEntryMode = this.utils.GetOriginalTenderId(this.selectedpayment.paymentTypeId, this.selectedpayment.parentTypeId) === PaymentMethods.CardOnFile ? this.CurrentActiveCard.entryMode : "";
    const tempCardNumber = this.utils.GetOriginalTenderId(this.selectedpayment.paymentTypeId, this.selectedpayment.parentTypeId) === PaymentMethods.CardOnFile ? this.payAgentService.MaskCreditCardNumber(this.CurrentActiveCard.cardNumber) : "";

    this.UpdateGiftcardBalance(CompScope, SaleResult);
    if (this.playerDetails && this.playerDetails.length == 0) {
      this.playerDetails = CompScope._shopservice.selectedclient;
    }
    const SettleMentObject: PaymentHistory = {
      paymentReferenceId: validatePayResult && validatePayResult.transactionId,
      paymentMethodId: this.selectedpayment.paymentTypeId,
      parentTenderId: this.selectedpayment?.parentTypeId,
      paymentReferenceComment: CompScope.paymentComments,
      paymentMethod: this.IsFolioPaymentMethod ? this.selectedpayment.paymentMethod :
        this.FormatPaymentMethodLabelForSettlementHistory(
          this.selectedpayment,
          this.paymentMethods,
          patronIdVal, cmsRedemption,
          voucherName,
          this.selectedGiftCard,
          this.selectedARAccount,
          this.selectedGuestRoom,
          this.playerDetails
        ),
      amount: this.getAmountPaid(CompScope.returnTransitionDetails, SaleResult ? SaleResult.amount : '', 'authorisedAmount'),
      issuerType: IsCardType ? SaleResult.cardInfo.issuer.toUpperCase() : tempIssueType,
      entryMode: IsCardType ? SaleResult.cardInfo.entryMode : tempEntryMode,
      cardNumber: IsCardType ? this.payAgentService.MaskCreditCardNumber(SaleResult.account.id) : tempCardNumber,
      additionalDetails: this.GetAdditionalPaymentDetails(this),
      cashPaymenHandle: (this.SelectedPaymentMethodEquals(PaymentMethods.Cash)) ? CompScope.cashPaymenHandleData : null,
      paymentMethodInfo: this.selectedpayment,
      tenderReducesDiscount: this.selectedpayment.tenderReducesDiscount,
      tenderReducesTax:  this.selectedpayment.isAutoRemoveTax,
    }
    if(this._ss.Ticket != null){
      SettleMentObject.discountExemptRatio = this._ss.Ticket.tenderReducesDiscountRatio;
      SettleMentObject.taxExemptRatio = this._ss.Ticket.compTaxExemptRatio;
    }
    this.tempSettlementHistory.unshift(SettleMentObject);
  }

  getAmountPaid(formGroupObj, SaleAmountResponse?, PropertyName?: string, saleAmount = 0) {
    let AmountPaid = 0;
    const MethodWhichDoesNotHavePartialPaymentFlow = [
      PaymentMethods.Cash,
      PaymentMethods.ARAcctPost,
      PaymentMethods.CompRedemption,
      PaymentMethods.OfferRedemption,
      PaymentMethods.CompSlipRedemption,
      PaymentMethods.ARPost,
      PaymentMethods.RedeemPoint,
      PaymentMethods.CreditBook,
      PaymentMethods.NonIntegratedCreditCard
    ]
    const cardMethods = [
      PaymentMethods.CreditCard,
      PaymentMethods.IDTECH,
      PaymentMethods.CardOnFile
    ]
    if (MethodWhichDoesNotHavePartialPaymentFlow.includes(this.selectedPaymentMethod) ||
      (cardMethods.includes(this.selectedPaymentMethod) && !SaleAmountResponse) || // If PaymentMgr Call failed then response will be null so sending value from UI
      this.isGiftCardSelected || this.isCustomPaymentMethod || this.SkipRoomChargePosting || this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment)
      || this.isWalletSelected) {
      if (this.partialPayment) {
        AmountPaid = this.ValidateSaleAmount(this.localization.currencyToSQLFormat(formGroupObj.controls.amounttendered.value), this);
      } else {
        AmountPaid = this.getSaleAmt();
      }
      if ((this.IsCMSRefund || this.IsOfferOrCompSlipPaymentMethod) && saleAmount != 0) {
        AmountPaid = saleAmount;
      }
    } else if (SaleAmountResponse) {
      AmountPaid = this.IsRefundFlow && SaleAmountResponse[PropertyName] > 0 ? SaleAmountResponse[PropertyName] * -1 : SaleAmountResponse[PropertyName];
    }
    return this.RoundOffTwo(AmountPaid);
  }

  getRoomChargeOrGroupChargeTenderedValue(formGroupObj) {
    let AmountPaid = 0;
    if (formGroupObj.controls.amounttendered.value) {
      AmountPaid = this.ValidateSaleAmount(this.localization.currencyToSQLFormat(formGroupObj.controls.amounttendered.value), this);
    }
    return this.RoundOffTwo(AmountPaid);
  }

  ConstructPayRequest(Response, formGroupObj, compScope, isFromValidatePay: boolean = false) {
    if (this.ReturnWithTicketResponse) {
      return {
        paymentManagerResponse: {
          amount: {
            requestAmount: this.getAmountPaid(formGroupObj, Response.paymentManagerResponse ? Response.paymentManagerResponse.amount : '', 'requestAmount'),
            authorisedAmount: this.getAmountPaid(formGroupObj, Response.paymentManagerResponse ? Response.paymentManagerResponse.amount : '', 'authorisedAmount'),
            tipAmount: this.getAmountPaid(formGroupObj, Response.paymentManagerResponse ? Response.paymentManagerResponse.amount : '', 'tipAmount')
          },
          inquirerInfo: this.NonPayAgentMethods.includes(this.selectedPaymentMethod) || this.isCustomPaymentMethod
            || this.V1GiftCardMethods.includes(this.selectedPaymentMethod) || this.SkipRoomChargePosting || (this.selectedPaymentMethod == PaymentMethods.CompRedemption && this._featureFlagInfo.SkipPMAgentCMS) || this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment) ? {
            terminalId: "1",
            orderNumber: compScope?.CurrentTryPayResponse?.ticketNumber,
            profitCenter: "",
            isPartialTenderAllowed: this.GatewayConfiguration && this.GatewayConfiguration.isPartialPayAllowed,
            tenderId: this.selectedpayment.paymentTypeId.toString()
          } : Response.paymentManagerResponse?.inquirerInfo,
          transactionTime: this.NonPayAgentMethods.includes(this.selectedPaymentMethod)
            || this.isCustomPaymentMethod || this.SkipRoomChargePosting || (this.selectedPaymentMethod == PaymentMethods.CompRedemption && this._featureFlagInfo.SkipPMAgentCMS) || this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment) ? this.PropertyInfo.CurrentDTTM : Response.transactionTime
        },
        transactionId: Response.transactionId
      };
    }
  }

  HandlePayRequest(validatePayResult, SaleResult, CompScope) {
    validatePayResult = this.ConstructPayRequest(validatePayResult, CompScope.returnTransitionDetails, CompScope, true);
    if (!this.PayRequest(validatePayResult)) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(10724));
      return;
    } else {
      this.AddSettlemtAndShowDialog(SaleResult, CompScope, validatePayResult);
      this.CheckSettlementStatusAndUpdateButtons(CompScope);
    }
  }

  UpdateGiftcardBalance(CompScope, SaleResult) {
    if (this.isGiftCardSelected && this.selectedGiftCard.isCardActive) {
      let saleAmount = this.getAmountPaid(CompScope.returnTransitionDetails, SaleResult ? SaleResult.amount : '', 'authorisedAmount');
      this.selectedGiftCard.UpdatedBalance = this.giftcardBusiness.CashBackCallRequired == true ? 0 : Math.abs(this.selectedGiftCard.amount - saleAmount);
    }
  }

  AddSettlemtAndShowDialog(SaleResult, CompScope, validatePayResult?: ValidatePayResponse, refundedAsGiftcard: boolean = false) {
    const CardMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH]
    const IsCardType: boolean = (CardMethods.includes(this.selectedPaymentMethod));
    let cmsRedemption = CompScope.CMSForm && CompScope.CMSForm.controls["cmsPaymentSelection"].value;
    let patronIdVal: string = this.selectedCMSPlayer && this.selectedCMSPlayer.PatronId;
    let voucherName: string = this.selectedVoucher && this.selectedVoucher.name;
    const tempIssueType = this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? this.CurrentActiveCard.issuerType : "";
    const tempEntryMode = this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? this.CurrentActiveCard.entryMode : "";
    const tempCardNumber = this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? this.payAgentService.MaskCreditCardNumber(this.CurrentActiveCard.cardNumber) : "";

    this.UpdateGiftcardBalance(CompScope, SaleResult);

    let SettleMentObject: PaymentHistory = {
      paymentReferenceId: validatePayResult && validatePayResult.transactionId,
      paymentMethodId: this.selectedpayment.paymentTypeId,
      parentTenderId: this.selectedpayment?.parentTypeId,
      paymentReferenceComment: CompScope.paymentComments,
      paymentMethod: this.IsFolioPaymentMethod ? this.selectedpayment.paymentMethod :
        this.FormatPaymentMethodLabelForSettlementHistory(
          this.selectedpayment,
          this.paymentMethods,
          patronIdVal, cmsRedemption,
          voucherName,
          this.selectedGiftCard,
          this.selectedARAccount,
          this.selectedGuestRoom,
          this.playerDetails
        ),
      amount: this.getAmountPaid(CompScope.returnTransitionDetails, SaleResult ? SaleResult.amount : '', 'authorisedAmount'),
      issuerType: IsCardType ? SaleResult.cardInfo.issuer.toUpperCase() : tempIssueType,
      entryMode: IsCardType ? SaleResult.cardInfo.entryMode : tempEntryMode,
      cardNumber: IsCardType ? this.payAgentService.MaskCreditCardNumber(SaleResult.account.id) : tempCardNumber,
      additionalDetails: this.GetAdditionalPaymentDetails(this),
      cashPaymenHandle: (this.SelectedPaymentMethodEquals(PaymentMethods.Cash)) ? CompScope.cashPaymenHandleData : null,
      disableDelete: refundedAsGiftcard,
      paymentMethodInfo: this.selectedpayment,
      tenderReducesDiscount: this.selectedpayment.tenderReducesDiscount,
      tenderReducesTax: this.selectedpayment.isAutoRemoveTax,
    }
    if(this._ss.Ticket != null){
      SettleMentObject.discountExemptRatio = this._ss.Ticket.tenderReducesDiscountRatio;
      SettleMentObject.taxExemptRatio = this._ss.Ticket.compTaxExemptRatio;
    }
    this.SettlementHistory.unshift(SettleMentObject);
    this.AlterSettlementHistory();
    this.ConfirmationPopupShown = false;

    this.dialog.closeAll();

    if (this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard)
      || this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH)
      || this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)
      || (this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) && !this.IsRefundFlow)) {
      const Popupmessage = this.localization.captions.shop.TrnsactionSuccessMsg;
      const dataObj = { 'text': Popupmessage, 'buttonname': this.localization.captions.common.okay, 'headertext': this.localization.captions.shop.Sale, 'icon': 'icon-success-icon' }
      this.roomOpenDialog(dataObj);
    } else if (this.IsRoomOrGroupCharge) {
      const fullPayment = this.localization.localizeCurrency(this.getSaleAmt().toString());
      let partialAmtWithoutLocalization = Number(CompScope.returnTransitionDetails.controls.amounttendered.value);

      partialAmtWithoutLocalization = isNaN(partialAmtWithoutLocalization) ? this.localization.currencyToSQLFormat(CompScope.returnTransitionDetails.controls.amounttendered.value.toString()) : partialAmtWithoutLocalization;
      const partialPaymentAmount = this.localization.localizeCurrency((partialAmtWithoutLocalization + 0).toString());
      const paymentBalanceText = this.localization.replacePlaceholders(
        this.selectedPaymentMethod == PaymentMethods.RoomCharge ? this.localization.captions.shop.AddedToRoomChrgSuccessMsg
          : PaymentMethods.GroupCharge? this.localization.captions.shop.AddedToGroupChrgSuccessMsg : this.localization.captions.shop.AddedToHotelCompSuccessMsg
        , ["amount", "roomNumber"]
        , [
          (this.partialPayment) ? partialPaymentAmount : fullPayment,
          this.selectedGuestRoom.RoomNumber
        ])
      const dataObj = {
        'text': paymentBalanceText,
        'buttonname': this.localization.captions.common.okay,
        'headertext': this.localization.captions.userConfig.wellDone,
        'icon': 'icon-success-icon'
      };
      this.roomOpenDialog(dataObj);
    }
  }

  FormatPaymentMethodLabelForSettlementHistory(
    paymentMethod: PaymentMethod
    , paymentMethodList: PaymentMethod[]
    , patronId: string = ""
    , redemptionType: string = ""
    , voucherName: string = ""
    , giftcardDetails?: GiftcardSearchResult
    , acctdetails?: ArAccount
    , guestRoomdetails?: GuestRoomSearchResult
    , playerDetails?: any
    , folioInvoiceNumber: string = ""
    , walletNumber: string = ""
  ) {
    const paymentTypeId = paymentMethod.isCloned ? paymentMethod.parentTypeId : paymentMethod.paymentTypeId;
    let paymentConstName = GlobalConst.PaymentMethodValue[paymentTypeId];
    let paymentApiName = paymentMethodList?.find(x => x.paymentTypeId == paymentTypeId)?.paymentMethod;
    let paymentMethodName = paymentApiName == paymentConstName ? this.oCaptions.shop.paymentMethods[paymentTypeId] : paymentApiName;
    paymentMethodName = paymentMethod.isCloned ? paymentMethod.paymentMethod : paymentMethodName;
    if (paymentTypeId == PaymentMethods.CompRedemption && patronId != '') {
      return `${paymentMethodName} | ${patronId} | ${this.GetCMSHandleName(redemptionType)}`;
    } else if (paymentTypeId == PaymentMethods.OfferRedemption || paymentTypeId == PaymentMethods.CompSlipRedemption && patronId != '') {
      return `${paymentMethodName} | ${patronId} | ${voucherName}`;
    } else if (this.V1GiftCardMethods.includes(paymentTypeId) && giftcardDetails) {
      return `${paymentMethodName} | ${giftcardDetails.maskedCardNumber}`;
    } else if (this.GiftCardMethods.includes(paymentTypeId) && giftcardDetails) {
      const ExternalGiftCardIDTechMethods: PaymentMethods[] = [
        PaymentMethods.ExternalGiftCardIdTech,
        PaymentMethods.AgilysysGiftCardIdTech
      ]
      const IDTechPaymentMethodNameMapper = {
        [PaymentMethods.ExternalGiftCardIdTech]: this.oCaptions.shop.paymentMethods[PaymentMethods.ExternalGiftCard],
        [PaymentMethods.AgilysysGiftCardIdTech]: this.oCaptions.shop.paymentMethods[PaymentMethods.AgilysysGiftCard]
      }
      paymentMethodName = ExternalGiftCardIDTechMethods.includes(paymentTypeId) ? IDTechPaymentMethodNameMapper[paymentTypeId] : paymentMethodName;
      return `${paymentMethodName} | ${giftcardDetails.maskedCardNumber}`;
    } else if (paymentTypeId == PaymentMethods.ARAcctPost && acctdetails) {
      if (this._featureFlagInfo.SenderCode.toUpperCase().trim() === ARSenderCode.STAY) {
        return `${paymentMethodName} | ${acctdetails.accountName}`;
      } else {
        return `${paymentMethodName} | ${acctdetails.accountName} | ${acctdetails.accountNumber}`;
      }
    } else if (!(paymentTypeId in PaymentMethods)) {
      return paymentMethodList.find(x => x.paymentTypeId == paymentTypeId).paymentMethod;
    } else if ((paymentTypeId == PaymentMethods.RoomCharge || paymentTypeId == PaymentMethods.GroupCharge) && guestRoomdetails) {
      return `${paymentMethodName} | ${guestRoomdetails.GuestName} | ${guestRoomdetails.RoomNumber}`
    }
    else if (paymentTypeId == PaymentMethods.HotelComp && guestRoomdetails) {
      return `${paymentMethodName} | ${this.oCaptions.shop.HotelCompNumber} : ${guestRoomdetails.RoomNumber}`
    }
    else if (paymentTypeId == PaymentMethods.ARPost || paymentTypeId == PaymentMethods.CreditBook) {
      return `${paymentMethodName} | ${playerDetails?.name} | AR Number: ${playerDetails?.arAccountNumber}`;
    }
    else if (paymentTypeId == PaymentMethods.PostToFolio) {
      return `${paymentMethodName} | ${folioInvoiceNumber}`;
    }
    else if (paymentTypeId == PaymentMethods.Wallet) {
      return `${paymentMethodName} | ${walletNumber}`;
    }
    else {
      return paymentMethodName;
    }
  }

  MapPaymentForMember(playerDetails: any, paymentReferenceId: number, memberInfo: MemberPayeeInfo[]): MemberPaymentHistory {
    const member = memberInfo.find(x => x.memberCardNumber == playerDetails['guestProfileId']);
    return {
      memberName: member ? member.memberName : playerDetails?.name,
      arAccountNumber: playerDetails?.arAccountNumber,
      golfPoints: member ? member.currentAvlRounds.toString() : "0",
      memberCardNumber: playerDetails['guestProfileId'],
      paymentReferenceId: paymentReferenceId
    }
  }

  cmsPaymentSelectType(saleAmt, playerInfo, event, CMSForm) {
    if (Math.abs(saleAmt) > Math.abs(playerInfo.balance)) {
      let errorMsg = this.localization.getError(100024);
      errorMsg = this.localization.replacePlaceholders(errorMsg, ["paymentType"], [this.GetCMSHandleName(playerInfo.type).toLowerCase()])
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
      CMSForm.patchValue({ cmsPaymentSelection: '' });
      event.preventDefault();
    } else {
      this.ifPaymentChoose = true;
      this.AutoScrollToBottom();
    }
    return playerInfo;
  }

  selectCMSPaymentType(saleAmt, playerInfo, event, CMSForm) {
    let handle = "";
    if (Math.abs(saleAmt) > Math.abs(playerInfo.balance)) {
      let errorMsg = this.localization.getError(100024);
      errorMsg = this.localization.replacePlaceholders(errorMsg, ["paymentType"], [this.GetCMSHandleName(playerInfo.type).toLowerCase()])
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
      CMSForm.patchValue({ cmsPaymentSelection: '' });
      event.preventDefault();
      handle = "";
    } else {
      handle = playerInfo.handle;
      this.ifPaymentChoose = true;
      this.AutoScrollToBottom();
    }
    return handle;
  }

  ResetSelectedPaymentMethod() {
    if (this.selectedpayment) {
      this.selectedpayment.id = this.selectedpayment.paymentTypeId = this.selectedpayment.parentTypeId = 0;
      this.ifPaymentChoose = false;
    }
  }

  FormatPrice(price: number) {
    if (price < 0) {
      return `(${this.localization.localizeCurrency(price * -1, true)})`;
    }
    else {
      return this.localization.localizeCurrency(price, true);
    }
  }

  HandleSaleFailure(errorResponse) {
    if (errorResponse?.error && errorResponse?.error[0]?.Code == PaymentErrorCodes.DeviceUserPressedCancel
      || errorResponse?.error?.code == PaymentErrorCodes.DeviceUserPressedCancel) {
      if (this.SettlementHistory.length == 0 || this.SettlementHistory.every(x => x.isReversed)) {
        this._ams.paymentProcessing = false;
      }
    }
    this.dialog.closeAll();
    this.ResetSelectedPaymentMethod();
    this.EnableCloseTranBtn = false;
    this.ifPaymentChoose = false;
    console.log('Error during RequestSale ' + errorResponse?.error);
    //Show Payment Error Prompt
    if (errorResponse?.error[0] != null && errorResponse?.error[0]?.Code != null && errorResponse?.error[0]?.Code != "") {
      this.EnableCloseTranBtn = (errorResponse.error?.[0]?.Code == PaymentErrorCodes.DeviceUserPressedCancel);
      this.payAgentService.PaymentErrorPrompt(errorResponse.error[0].Code, errorResponse.error[0].Message);
    }
    else {
      this.ExtractAndDisplayPaymentMgrError(errorResponse);
    }
  }

  HandlePostSaleFailure(errorResponse) {
    this.dialog.closeAll();
    this.ifPaymentChoose = false;
    //Payment Manager is not reachable.
    if (errorResponse && errorResponse.error && errorResponse.error.result && errorResponse.error.result.errorCode) {
      return this.payAgentService.PaymentErrorPrompt(errorResponse.error.result.errorCode);
    }
    else {
      if (errorResponse?.error?.result?.errorMessage) {
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorResponse?.error?.result?.errorMessage);
      } else {
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.replacePlaceholders(this.localization.captions.shop.PaymentTransactionFailureMsg, ["paymentmethod"], [this.localization.captions.shop.paymentMethods[this.selectedPaymentMethod]]));
      }      
    }
    this.ResetSelectedPaymentMethod(); //Reset Payment method selection
  }

  async GetPaymentHistory(TransactionId: number, includeAll = false) {
    let paymentHistory: Array<PaymentDetails> = [];
    let response: any = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: "PaymentHistory",
      method: HttpMethod.Get,
      uriParams: { transactionId: TransactionId, getAll: includeAll }
    });
    if (response && response.successStatus) {
      paymentHistory = response.result;
    }
    return paymentHistory;
  }

  async GetPaymentHistoryDetails(TransactionId: number) {
    let paymentHistoryDetails: PaymentHistoryDetails;
    let response: any = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: "PaymentHistoryDetails",
      method: HttpMethod.Get,
      uriParams: { transactionId: TransactionId }
    });
    if (response && response.successStatus) {
      paymentHistoryDetails = response.result;
    }
    return paymentHistoryDetails;
  }

  TriggerRemainingAmtCalc() {
    if (this.ReturnTransitionFormControlObj) {
      this._originalTotalAmount = this._ss.finalAmount;
      const tempPaymentText = this.IsRefundFlow ? this.localization.captions.shop.ReturnPayment : this.localization.captions.shop.Pay;
      this.paymentText = (this.PropertyInfo.UseRetailInterface) ? this.localization.captions.shop.SENDFORPAYMENT : tempPaymentText;
      this.AllowCancel = (this.IsRefundFlow && this._ss.settleRWTTransaction || !this.IsRefundFlow || !this.ReturnWithTicketResponse);
      this.CalculateRemainingAmount(this.ReturnTransitionFormControlObj);
      this.patchRemainingAmt.next(this.remainingAmount);
      this.ValidatePaymentMethodActivationBasedOnComponentAccessLink(this._ss.selectedclient, true);
      this.CheckForCardOnFile(this._ss.selectedclient);
    }
  }

  ValidateIDTechCardSwipeData(swipedData: string): boolean {
    let ValidCardData: boolean = false;
    if (swipedData) {
      const IDTechCardSwipeStartSeq = "02";
      const IDTechCardSwipeEndSeq = "03";
      if (swipedData.startsWith(IDTechCardSwipeStartSeq) && swipedData.endsWith(IDTechCardSwipeEndSeq)) { // Check swiped data has proper starting and ending seq
        const SwipedCardDatalengthHexVal = swipedData.substring(2, 4); // Extract Hex value of Carddetail length from swipedData
        let SwipedDataLength = parseInt(SwipedCardDatalengthHexVal, 16); // Converting Hex to integer
        if (SwipedDataLength > 100) {
          ValidCardData = true;
        }
      }
    }
    return ValidCardData;
  }

  async PerformPostReturnOperation(selectedReturnedRetailProducts, selectedRetainedRetailProducts) {
    if (this._ss.ProductId == GlobalConst.Product.SNC && this._ss.transactionId) {
      if (this.IsRevenueItemIncluded) {
        if (this._ss.IsbeoItemReturned) {
          RetailDataAwaiters.UpdateBeoCheckStatus(this._ss.transactionId);
        }
        else {
          RetailDataAwaiters.UpdateBeoCheckTransactionId(this._ss.transactionId, this.ReturnWithTicketResponse.id);
        }
        this._ss.IsbeoItemReturned = false;
      } else if (selectedReturnedRetailProducts.every(item => item.ItemType == RetailItemType.Deposit)) {
        RetailDataAwaiters.UpdateBeoCheckStatus(this._ss.transactionId);
      }
    }
    if (this._ss.ProductId == GlobalConst.Product.GOLF && this._ss.transactionId) {
      this._teetimePaymentBusiness.UpdateRefundedPlayerStatus(selectedReturnedRetailProducts, selectedRetainedRetailProducts, _.cloneDeep(this));
    }
    const isDepositTransaction = selectedReturnedRetailProducts.every(r => r.ItemType == RetailItemType.Deposit);
    if (this._ss.ProductId == GlobalConst.Product.SPA && this._ss.transactionId && isDepositTransaction) {
      let sourceType : Set<myGlobals.CustomFeeSourceType> = new Set<myGlobals.CustomFeeSourceType>();        
      if(selectedReturnedRetailProducts){
        sourceType = new Set(selectedReturnedRetailProducts?.filter(d => d.sourceType).map(data=>data.sourceType));
      } 
      const transactionSourceType = sourceType.size > 0 ? sourceType?.values()?.next()?.value : null
      RetailDataAwaiters.RefundAppointmentDeposit(this._ss.transactionId, this.ReturnWithTicketResponse.id, selectedReturnedRetailProducts,transactionSourceType);
    }
    let applicableProducts: number[] = [GlobalConst.Product.SPA, GlobalConst.Product.GOLF, GlobalConst.Product.RETAIL];
    if (applicableProducts.includes(this._ss.ProductId) && this._ss.ClientMultiPacks && this._ss.ClientMultiPacks.length > 0) {
      let multipacks: ClientMultipack[] = _.cloneDeep(this._ss.ClientMultiPacks);
      let UpdateClientMultipack: ClientMultipack[] = [];
      let AddClientMultipack: ClientMultipack[] = [];
      if (this.ReturnWithTicketResponse && this.ReturnWithTicketResponse.transactionDetails && this.ReturnWithTicketResponse.transactionDetails.length > 0) {
        this.ReturnWithTicketResponse.transactionDetails.forEach(detail => {
          if (detail.transactionDetailLinkId > 0) {
            let multipack = multipacks.find(x => x.transactionDetailId == detail.transactionDetailLinkId);
            if (multipack) {
              multipack.transactionId = detail.transactionId;
              multipack.transactionDetailId = detail.id;
              UpdateClientMultipack.push(multipack);
            }
          }
        });

        if (this._ss.MultiPackProducts.length > 0 && this._ss.selectedMultiPackExchangedProducts.length > 0) {
          if (this.isMultipleLinkedItem) {
            this._ss.selectedMultiPackExchangedProducts.forEach(x => {
              let multipackList = multipacks.filter(m => m.transactionDetailId == x.id);
              let detail = this.ReturnWithTicketResponse.transactionDetails.find(t => t.externalPOSId == x.ExternalPOSItemId && t.lineNumber == x.LineNumber);
              if (multipackList.length > 0 && detail) {
                multipackList.forEach(x => {
                  x.multiPackLinkId = x.id;
                  x.quanitity = x.quanitity - x.remaining;
                  x.transactionId = detail.transactionId;
                  x.transactionDetailId = detail.id;
                  x.remaining = 0;
                  x.id = 0;
                  AddClientMultipack.push(x);
                });

              }
            });

          } else {
            this._ss.selectedMultiPackExchangedProducts.forEach(x => {
              let multipack = multipacks.find(m => m.transactionDetailId == x.id);
              let detail = this.ReturnWithTicketResponse.transactionDetails.find(t => t.externalPOSId == x.ExternalPOSItemId && t.lineNumber == x.LineNumber);
              if (multipack && detail) {
                multipack.multiPackLinkId = multipack.id;
                multipack.quanitity = multipack.quanitity - multipack.remaining;
                multipack.transactionId = detail.transactionId;
                multipack.transactionDetailId = detail.id;
                multipack.remaining = 0;
                multipack.id = 0;
                AddClientMultipack.push(multipack);
              }
            })
          }

        }
        let obj: MultiPackReturn = {
          updateMultiPacks: UpdateClientMultipack,
          addMultiPacks: AddClientMultipack
        }
        let updatedmultipackreturn = await RetailDataAwaiters.UpdateMultiPack(obj);

        if (updatedmultipackreturn && updatedmultipackreturn.addMultiPacks.length > 0) {
          RetailDataAwaiters.UpdateAppointmentMultiPackId(updatedmultipackreturn.addMultiPacks);
        }
      }
    }
  }

  async PrintReceipts(compScope, returnWithTicketResponse) {
    const memberNumber = (compScope?.selectedClient?.playerCategoryId === 3 || compScope?.selectedClient?.guesttype === 2) ? compScope?.selectedClient?.guestProfileId : '';
    RetailDataAwaiters.PrintAgreement(returnWithTicketResponse.transactionData.id);
    const receiptComment = compScope.returnTransitionDetails.controls.receiptComment.value;
    const saleDate = this.utils.getDate(returnWithTicketResponse.transactionData.transactionDate);
    let availableRounds = 0;

    let paymentInfo = this.SettlementHistory.filter(x => GiftCardMethods.includes(x.paymentMethodId));
    let updatedGiftCardBalance: string;

    if (paymentInfo) {
      //let	giftCards = paymentInfo.map(r => r.additionalDetails as GiftcardSearchResult);
      paymentInfo.forEach(element => {
        const additionalDetails = element.additionalDetails as GiftcardSearchResult;
        if (additionalDetails) {
          let data: GiftCardBalance = {
            cardType: additionalDetails.cardType,
            UpdatedBalance: additionalDetails.UpdatedBalance,
            paymentReferenceId: element.paymentReferenceId
          }
          this.giftcardBalance.push(data);
        }

      });
      updatedGiftCardBalance = JSON.stringify(this.giftcardBalance);
    }
    // Receipt Flow
    const transactionDate = (this._ss.selectedTransaction != null && this._ss.selectedTransaction['transactionDateTime'])
      ? this._ss.selectedTransaction['transactionDateTime'] : this._ss.SelectedDate;
    if (memberNumber && memberNumber != '' && transactionDate) {
      const memberPayment: MemberPaymentHistory[] = [];
      let paymentReferenceIds = this.TransactionPayments.map(x => x.paymentReferenceId);
      let acesPaymentRecords: any[] = await this._memberService.GetACESPaymentRecordsByPaymentTransactionId(paymentReferenceIds);
      for (let x of acesPaymentRecords) {
        let memInfo = await RetailDataAwaiters.getMemberInfo(x.memberId, transactionDate, x.sourceCorpId)
        if (memInfo && memInfo.golfPoints) {
          const uniqueBucketCode: GolfPoints[] = [];
          memInfo.golfPoints.forEach((value, key) => {
            if (uniqueBucketCode.find(x => x.bucketCode == value.bucketCode) == undefined) {
              uniqueBucketCode.push(value);
              availableRounds += value.eligibleRounds;
            }
          });
        }
        memberPayment.push({
          memberName: memInfo ? memInfo.firstName + " " + memInfo.lastName : "",
          golfPoints: availableRounds.toString(),
          arAccountNumber: x.arAccountNumber,
          memberCardNumber: x.memberId,
          paymentReferenceId: x.paymentTransactionId
        });
        availableRounds = 0;
      }
      this.SendNotification(this._ss.ticketNumber, this._ss.SelectedOutletId, GlobalConst.ReceiptType.returnExchange, returnWithTicketResponse.transactionData.id,
        compScope.ClientName, compScope.returnTransitionDetails.value.returnReason, undefined, true, this._ss.transactionId, null, false, false, availableRounds, receiptComment, saleDate, memberPayment);

      compScope.receiptService.GenerateReceipt(this._ss.ticketNumber, this._ss.SelectedOutletId, GlobalConst.ReceiptType.returnExchange,
        returnWithTicketResponse.transactionData.id, memberNumber, compScope.ClientName,
        compScope.returnTransitionDetails.value.returnReason, undefined, true, this._ss.transactionId, null,
        false, false, availableRounds, receiptComment, saleDate, this.quickIdUser, updatedGiftCardBalance, undefined, false, memberPayment);

    }
    else {
      let isHotelGuest = (compScope?.selectedClient?.playerCategoryId == PlayerCategory.HotelReservation);
      let isHotelSpaGuest = (compScope?.selectedClient?.guesttype == ClientType.HotelReservation);
      let paymentDetails = this.paymentMethods;
      let selectedPaymentId = this.selectedpayment.paymentTypeId;
      if (this.returnOriginalPaymentOnly && compScope.prevSettlementInfo?.length > 0) {
        let selectedPaymentRTOS = compScope.prevSettlementInfo.map(x => x.paymentMethodMasterId);
        paymentDetails = paymentDetails.filter(x => selectedPaymentRTOS.includes(x.id));
      }
      else {
        let paymentmethodIds = this.SettlementHistory.map(x => x.paymentMethodId);
        paymentDetails = paymentDetails.filter(x => paymentmethodIds.includes(x.paymentTypeId) || x.paymentTypeId == selectedPaymentId);
      }
      this.requireSignatureCapture = paymentDetails.some(x => x.requireSignatureCapture);

      this.SendNotification(this._ss.ticketNumber, this._ss.SelectedOutletId, GlobalConst.ReceiptType.returnExchange, returnWithTicketResponse.transactionData.id,
        compScope.ClientName, compScope.returnTransitionDetails.value.returnReason, undefined, true, this._ss.transactionId, null, false, false, availableRounds, receiptComment, saleDate);

      compScope.receiptService.GenerateReceipt(this._ss.ticketNumber, this._ss.SelectedOutletId, GlobalConst.ReceiptType.returnExchange,
        returnWithTicketResponse.transactionData.id, memberNumber, compScope.ClientName,
        compScope.returnTransitionDetails.value.returnReason, undefined, true, this._ss.transactionId, null,
        false, false, availableRounds, receiptComment, saleDate, this.quickIdUser, updatedGiftCardBalance, undefined,
        undefined, undefined, undefined, this.requireSignatureCapture, undefined, isHotelGuest, isHotelSpaGuest);
    }
  }

  async SendNotification(ticketNumber: string, selectedOutletId: number, receiptType: GlobalConst.ReceiptType, transactionId: number, clientName?: string,
    voidReason?: string, isPendingSettlement?: boolean, isReturn?: boolean, returnTransactionId?: number, callBack?: any,
    isTaxExempt?: boolean, isMemberTransaction?: boolean, availableRounds?: number, receiptComment?: string, saleDate: Date = this.PropertyInfo.CurrentDTTM, memberPaymentHistory: MemberPaymentHistory[] = []) {
    const clientInfo = {} as ClientInfo;
    const clerkName = this.quickIdUser ? this.quickIdUser : this.utils.GetPropertyInfo("userName");
    const isEnableMachineTransaction = sessionStorage.getItem('enableMachineTransaction') == "true" ? true : false;


    const memberPayment = memberPaymentHistory;
    const options: ReportAPIOptions = await this._receiptService.formReportAPIOptions(ticketNumber, selectedOutletId, receiptType, transactionId, clientName, voidReason,
      isPendingSettlement, isReturn, returnTransactionId, isTaxExempt, isMemberTransaction, availableRounds, receiptComment, clerkName,
      isEnableMachineTransaction, this._ss.memberCardNumber.toString(), '', '', false, '', memberPayment);
    clientInfo.transactionId = transactionId;
    clientInfo.reportQuery = this._reportService.createAPIOptions(options);

    if (this._ss.ProductId == GlobalConst.Product.GOLF) {
      const playerIds: number[] = this._ss.SelectedPlayers.map(x => x.playerId);
      clientInfo.playerId = playerIds;
    }

    else if (this._ss.ProductId == GlobalConst.Product.SPA) {
      const appointmentId: number[] = this._ss.AppoinmentIdForCheckOut;
      clientInfo.appointmentId = appointmentId;
    }
    if (this._ss.ProductId == GlobalConst.Product.SPA || this._ss.ProductId == GlobalConst.Product.GOLF || this._ss.ProductId == GlobalConst.Product.RETAIL)
      RetailDataAwaiters.SendNotification(clientInfo, this.retailService.manualEmailId, true);
  }

  async TeeTimeRedirection(isCancelPressEvent = false) {
    const result = await RetailDataAwaiters.getTeeTimeSetting();
    if (this.retailService.isFromTeetimeSearch) {
      this.utils.RedirectTo(RedirectToModules.TeeTimeSearch);
    } else if (this.retailService.isFromTeeTimeGraphicalView) {
      this.utils.RedirectTo(RedirectToModules.TeeTimeGraphicalView);
    } else if (this.retailService.isFromRefund && isCancelPressEvent == false) {
      const redirectTo = result && result.defaultToGraphicalTeeSheet ? RedirectToModules.TeeTimeGraphicalView : RedirectToModules.TeeTime;
      this.utils.RedirectTo(redirectTo);
    } else {
      this.utils.RedirectTo(RedirectToModules.TeeTime);
    }
  }

  async HandlePostReturnRedirection() {
    if (this._ss.ProductId == GlobalConst.Product.GOLF && this._ss.transactionId && this._ss.isFromRefund) {
      await this.TeeTimeRedirection();
      this.retailService.isFromRefund = false;
      this.retailService.isFromTeetimeSearch = false;
      this._ss.SelectedPlayers = [];
      this.retailService.SelectedPlayers = [];
    } else if (!this.PropertyInfo.UseRetailInterface) {
      this.utils.RedirectTo(RedirectToModules.RetailTransactions);
    } else {
      this.utils.RedirectTo(RedirectToModules.ReturnWithTicket);
    }
    this._ss.selectedPayeeId = 0;
    this._ss.memberCardNumber = "0";
    this._ams.labelRecords = [];
    this._ams.clientsearchArray = [];
  }

  async PerformCloseTransaction() {
    // Refund already happened ..redirecting to modules
    let dataObj = {};
    if (this.IsRefundFlow) {
      const paymentCloseText = this.localization.captions.shop.RefundSuccessMsg;
      dataObj = {
        'text': paymentCloseText, 'buttonname': this.localization.captions.common.Close,
        'headertext': this.localization.captions.shop.Refund, 'icon': 'icon-success-icon'
      }
    } else {
      const paymentCloseText = this.localization.replacePlaceholders(this.localization.captions.shop.TicketCreationSuccess,
        ["checkNumber"], [this.ReturnWithTicketResponse.transactionData.retailTicketNumber]);
      dataObj = {
        'text': paymentCloseText, 'buttonname': this.localization.captions.common.Close,
        'headertext': this.localization.captions.shop.PaymentComplete, 'icon': 'icon-success-icon'
      }
    }
    if (this.retailValidationService.transactionLockId > 0) {
      this.retailValidationService.RemoveTransactionLock(this.retailValidationService.transactionLockId);
    }
    this.roomOpenDialog(dataObj);
    this.HandlePostReturnRedirection();
    this.ResetShopObject();
    this._ams.paymentProcessing = false;
    this._ss.GoToRetailTransaction = true;
    this._ss.CalculateReturnWithTicketCommission(this.ReturnWithTicketResponse.transactionData.id,
      this.utils.convertDateFormat(this.PropertyInfo.CurrentDate));
  }

  ResetShopObject() {
    this._ss.selectedExchangeRetailProducts = [];
    this._ss.selectedTransactionRetailProducts = [];
    this._ss.selectedReturnedRetailProducts = [];
    this._ss.selectedExchangeRetailProducts = [];
    this._ss.selectedRetainedRetailProducts = [];
    this._ss.returnWithticketItems = [];
    this._ss.retainedGiftCards = [];
    this._ss.selectedPayeeId = 0;
    this._ss.selectedclient = null;
    this._ss.memberCardNumber = "0";
    this._ams.labelRecords = [];
    this._ams.clientsearchArray = [];
    this._ss.isReturnWithoutTicket = false;
    this._ss.returnItemTotal = 0;
    this._ss.returnedItemTax = 0;
    this._ss.retainedItemTotal = 0;
    this._ss.exchangedItemTotal = 0;
    this._ss.finalAmount = 0;
    this._ss.selectedProducts = [];
    this._ss.ticketDiscount = null;
    this._ss.transactionTicketDiscount = [];
    this._ss.selectedRetailItem = null;
    this._ss.isFromRefund = false;
    this._ss.destroy();
    this.isCardTransactionFromRtrnTkt = false;
    this._ss.selectedResvProducts = [];
    this.GridItems = [];
    this.manualEmailId = [];
    this.canShowWalletInput = true;
  }

  FilterPaymentBasedOnProduct(paymentMethods: PaymentMethod[]) {
    return paymentMethods.filter(x => (ProductPaymentMethods[this._ss.ProductId].includes(this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId)) || !(this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) in PaymentMethods)) && x.isActive);
  }

  ReplaceGiftcardVendorName(paymentMethods: PaymentMethod[]) {
    const vendorName = this.giftcardBusiness.GiftcardVendorName;
    let giftCardMethod = paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.ExternalGiftCard);
    if (vendorName && giftCardMethod) {
      giftCardMethod.paymentMethod = this.localization.replacePlaceholders(this.localization.captions.shop.paymentMethods[giftCardMethod.paymentTypeId], ["Third Party"], [vendorName]);
    }
  }

  FilterIdTechAndInactiveMethods(paymentMethods: PaymentMethod[]) {
    const HiddenPaymentMethods = [PaymentMethods.IDTECH, PaymentMethods.V1GiftCardIdTech, PaymentMethods.ExternalGiftCardIdTech
      , PaymentMethods.AgilysysGiftCardIdTech, PaymentMethods.RedeemPoint];
    return paymentMethods?.filter(x => !HiddenPaymentMethods.includes(this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId)))?.filter(x => x.isActive);
  }

  AutoScrollToBottom() {
    if (document.getElementById("payComment")) {
      document.getElementById("payComment").focus();
    }
    else if (document.getElementById("proceedBtn")) {
      document.getElementById("proceedBtn").focus();
    }
  }

  async GetPaymentTransaction(transactionId: number): Promise<TransactionPayment[]> {
    try {
      return await this.cmsBusinessService.GetPaymentTransaction(transactionId);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  ValidateSaleAmount(SaleAmount: number, $scope) {
    //For Offer Redemption, Giftcard and RainCheck we know the balance
    //Correcting the Sale amount before TryPay will ensure no wrong entries in DB as well.

    if ($scope.IsOfferOrCompSlipPaymentMethod && $scope.selectedVoucher != null) {
      SaleAmount = SaleAmount < $scope.selectedVoucher.voucherAmount ? SaleAmount : $scope.selectedVoucher.voucherAmount;
    } else if ($scope.isGiftCardSelected && $scope.selectedGiftCard != null) {
      SaleAmount = SaleAmount < $scope.selectedGiftCard.amount ? SaleAmount : $scope.selectedGiftCard.amount;
    } else if ($scope.SelectedPaymentMethodEquals(PaymentMethods.RainCheck) && $scope.SelectedRainCheck != null) {
      SaleAmount = SaleAmount < $scope.SelectedRainCheck.rainCheckValue ? SaleAmount : $scope.SelectedRainCheck.rainCheckValue;
    }

    return SaleAmount;
  }

  ValidateTransactionCancellation(
    settlementHistory: PaymentHistory[]
    , reversalReqParam: ReversalRequestParams
    , callBack: Function
    , isReturnScreen: boolean = false
  ) {
    let allowTransactionCancel = true;
    if (this.CheckIfActivePaymentTransactionExists(settlementHistory)) {
      allowTransactionCancel = false;
      this.OpenCancelAllPaymentConfirmationDialog(settlementHistory, reversalReqParam, callBack, isReturnScreen);
    }
    return allowTransactionCancel;
  }

  private OpenCancelAllPaymentConfirmationDialog(settlementHistory: PaymentHistory[], reversalReqParam: ReversalRequestParams, callBackFn: Function, isReturnScreen: boolean) {
    const getVendorType = (voucherDetails: any): string => { return voucherDetails && voucherDetails.vendorType };
    if (settlementHistory.some(settlement => settlement.paymentMethodId == PaymentMethods.OfferRedemption && !this._featureFlagInfo.ValidateOfferRedemptionVoid(getVendorType(settlement.additionalDetails)))) {
      this.utils.showAlert(this.ShopCaptions.CMS.OfferRefundNotSupported, AlertType.Info);
      return;
    }
    this.utils.ShowErrorMessage(
      this.localization.captions.common.Warning,
      this.ShopCaptions.ConfirmCancelMsg,
      GlobalConst.ButtonType.YesNo,
      this.ReverseAllPayments.bind(this),
      [settlementHistory, reversalReqParam, callBackFn, isReturnScreen]
    );
  }

  async ReverseAllPayments(result, extraParam: any[]) {
    if (result.toLowerCase() == GlobalConst.ButtonOptions.Yes.toLowerCase()) {
      let settlementHistory: PaymentHistory[] = extraParam[0];
      const reversalReqParam: ReversalRequestParams = extraParam[1];
      const isReturnScreen: boolean = extraParam[3];
      if (!settlementHistory || !reversalReqParam) { return; }
      settlementHistory = settlementHistory.filter(s => !s.isReversed);
      const paymentReferenceIds = !isReturnScreen ? null : settlementHistory.map(x => x.paymentReferenceId);
      if (this.CheckIfActivePaymentTransactionExists(settlementHistory)) {
        this._ams.loaderEnable.next(this.ShopCaptions.CancelAllPaymentInprogressMsg);
        const uriParams = {
          transactionId: reversalReqParam.transactionId,
          ticketNumber: reversalReqParam.ticketNumber,
          outletId: this._ss.SelectedOutletId,
          checkHandleGuid: reversalReqParam.checkHandleGuid,
          terminalId: reversalReqParam.terminalId
        };
        let RefundResponse = this.http.CallApiAsync<any>({
          host: GlobalConst.Host.retailPOS,
          callDesc: "CancelAllPayments",
          method: HttpMethod.Post,
          uriParams: uriParams,
          body: paymentReferenceIds,
          showError: true
        });

        RefundResponse.then(response => {
          setTimeout(() => {
            this._ams.loaderEnable.next('');
          }, 1000);
          if (response && response.successStatus && response.result) {
            let cancelCallBackfn = extraParam[2];
            cancelCallBackfn(response.result);
          }
        }).catch((err) => {
          this.HandleReversalFailure(err);
        });
      }
    }
  }

  private CheckIfActivePaymentTransactionExists(settlementHistory: PaymentHistory[]): boolean {
    return (settlementHistory && settlementHistory.length > 0 && settlementHistory.some(x => !x.isReversed))
  }

  OpenPaymentReverseConfirmationDialog(settlement: PaymentHistory, params: ReversalRequestParams, callBackFn: Function) {
    if (settlement && !settlement.isReversed) {
      const voucherDetails: Voucher = <Voucher>settlement.additionalDetails;
      if (settlement.paymentMethodId == PaymentMethods.OfferRedemption && voucherDetails && !this._featureFlagInfo.ValidateOfferRedemptionVoid(voucherDetails.vendorType)) {
        this.utils.showAlert(this.ShopCaptions.CMS.OfferRefundNotSupported, AlertType.Info);
        return;
      }

      this.utils.ShowErrorMessage(
        this.localization.captions.common.Warning,
        this.ShopCaptions.ConfirmPaymentReversal,
        GlobalConst.ButtonType.YesNo,
        this.ProceedPaymentReversal.bind(this),
        [settlement, params, callBackFn]
      );
    }
  }

  private ProceedPaymentReversal(result, extraParam: any[]) {
    if (result.toLowerCase() == GlobalConst.ButtonOptions.Yes.toLowerCase()) {      
      const isSkipPMAgent = this._featureFlagInfo.IsSkipPMAgent(this.selectedpayment);
      if (RoomGrpChargeMethod.includes(extraParam[0].paymentMethodId) && this.isVisualOneRevenuePosting && !isSkipPMAgent) {
        console.log("Before GetWebProxyHealthCheckStatus");
        this._ss.GetWebProxyHealthCheckStatusForPMSPostings(true, true, isSkipPMAgent).then(res => {
          if (res) {
            console.log("WebProxy HealthCheck success");
            this.ReversePayment(extraParam);
          } else {
            console.log("WebProxy HealthCheck failed");
          }
        });
      } else {
        this.ReversePayment(extraParam);
      }

    }
  }

  private async ReversePayment(extraParams: any[]) {
    let settlement: PaymentHistory = extraParams[0]
      , reversalReqParam: ReversalRequestParams = extraParams[1]
      , callBackFn = extraParams[2];
    if (!settlement || !settlement.paymentReferenceId || !reversalReqParam) { return; }
    this._ams.loaderEnable.next(this.ShopCaptions.ReversalInprogressMsg);
    const uriParams = {
      transactionId: reversalReqParam.transactionId,
      ticketNumber: reversalReqParam.ticketNumber,
      outletId: this._ss.SelectedOutletId,
      checkHandleGuid: reversalReqParam.checkHandleGuid,
      tenderId: settlement.paymentMethodId,
      paymentReferenceId: settlement.paymentReferenceId,
      terminalId: reversalReqParam.terminalId
    };
    let RefundResponse: any = this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: "ReversePayment",
      method: HttpMethod.Post,
      body: settlement.amount,
      uriParams: uriParams,
      showError: true
    });
    RefundResponse.then(response => {
      setTimeout(() => {
        this._ams.loaderEnable.next('');
      }, 1000);
      if (response && response.successStatus && response.result) {
        settlement.isReversed = true;
        callBackFn(settlement);
      }
      if(this.SettlementHistory.every(x => x.isReversed)){
        this._ams.paymentProcessing = false;
      }
    }).catch((err) => {
      this.HandleReversalFailure(err);
    });
  }

  HandleReversalFailure(err) {
    console.log(err);
    setTimeout(() => {
      this._ams.loaderEnable.next('');
    }, 1000);
    this.utils.showError(`${this.ShopCaptions.UnableToProcessReversalMsg} \n ${err?.error?.errorDescription}`);
  }

  GetAdditionalPaymentDetails(compScope: any) {
    const PaymentMethodAdditionDetaiMap = {
      [PaymentMethods.ARAcctPost]: "selectedARAccount",
      [PaymentMethods.AgilysysGiftCard]: "selectedGiftCard",
      [PaymentMethods.AgilysysGiftCardIdTech]: "selectedGiftCard",
      [PaymentMethods.ExternalGiftCard]: "selectedGiftCard",
      [PaymentMethods.ExternalGiftCardIdTech]: "selectedGiftCard",
      [PaymentMethods.V1GiftCard]: "selectedGiftCard",
      [PaymentMethods.V1GiftCardIdTech]: "selectedGiftCard",
      [PaymentMethods.RoomCharge]: "selectedGuestRoom",
      [PaymentMethods.GroupCharge]: "selectedGuestRoom",
      [PaymentMethods.HotelComp]: "selectedGuestRoom",
      [PaymentMethods.RainCheck]: "SelectedRainCheck",
      [PaymentMethods.CreditCard]: "selectedDeviceHandle",
      [PaymentMethods.CardOnFile]: "selectedDeviceHandle",
      [PaymentMethods.IDTECH]: "selectedDeviceHandle",
      [PaymentMethods.CompRedemption]: "selectedDeviceHandle",
      [PaymentMethods.OfferRedemption]: "selectedVoucher",
      [PaymentMethods.CompSlipRedemption]: "selectedVoucher",
      [PaymentMethods.ARPost]: "selectedMember",
      [PaymentMethods.PostToFolio]: "postfolioData"
    }
    try {
      if (compScope.selectedpayment && compScope.selectedPaymentMethod &&
        compScope[PaymentMethodAdditionDetaiMap[compScope.selectedPaymentMethod]]) {
        return compScope[PaymentMethodAdditionDetaiMap[compScope.selectedPaymentMethod]]
      } else {
        return null;
      }
    } catch (error) {
      console.log("error while mapping AdditionalDetails to payment, error:" + error);
    }
  }

  CheckAndReversePayment(settlement: PaymentHistory, compScope) {
    if (RoomGrpChargeMethod.includes(settlement.paymentMethodId)) {
      let rcPayment: PaymentMethod[] = compScope.paymentMethodsClone.filter((r) => RoomGrpChargeMethod.includes(r.paymentTypeId));
      if (rcPayment) {
        rcPayment.map(p => {
          if (!compScope.paymentMethods.some(x => this.utils.GetOriginalTenderId(p.paymentTypeId, p.parentTypeId) == this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId)))
            compScope.paymentMethods.push(p);
        });
        compScope.paymentMethods.sort((a, b) => a.listOrder - b.listOrder);
      }
    }
    if (settlement.paymentMethodId == PaymentMethods.OfferRedemption || settlement.paymentMethodId == PaymentMethods.CompSlipRedemption) {
      compScope.paymentMethods.find(x => x.paymentTypeId == settlement.paymentMethodId).isDisabled = false;
    }
    if (SurchargePaymentMethods.includes(settlement.paymentMethodId) && settlement.surcharge > 0) {
      compScope.RemoveSurcharge(settlement.surcharge, true);
    }
  }

  async GetPaymentTransactionDetails(paymentReferenceIds: number[]): Promise<PaymentDetails[]> {
    if (!(paymentReferenceIds.length > 0 && paymentReferenceIds.every(x => x > 0))) { return }
    let response: BaseResponse<PaymentDetails[]> = await this.http.CallApiAsync<PaymentDetails[]>({
      host: GlobalConst.Host.payment,
      callDesc: "GetPaymentTransaction",
      method: HttpMethod.Put,
      body: paymentReferenceIds
    });
    return response.result;
  }

  public async getTransactionDetail(transactionId: number): Promise<Transaction> {
    let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
      callDesc: "GetTransactionInfoById",
      host: GlobalConst.Host.retailPOS,
      method: HttpMethod.Get,
      uriParams: { transactionId: transactionId }
    });
    return response.result;
  }

  FormSettlementHistoryLabel(paymentTransDetail: PaymentDetails, paymentMethods: PaymentMethod[]) {
    const V1GiftCardMethods = [
      PaymentMethods.V1GiftCard,
      PaymentMethods.V1GiftCardIdTech
    ]
    const GiftCardMethods: PaymentMethods[] = [
      PaymentMethods.V1GiftCard,
      PaymentMethods.V1GiftCardIdTech,
      PaymentMethods.ExternalGiftCard,
      PaymentMethods.ExternalGiftCardIdTech,
      PaymentMethods.AgilysysGiftCard,
      PaymentMethods.AgilysysGiftCardIdTech
    ];
    const paymentTypeId = paymentTransDetail.paymentMethodId == PaymentMethods.IDTECH ? PaymentMethods.CreditCard : paymentTransDetail.paymentMethodId;
    const patronId = paymentTransDetail.patronId;
    const acctdetails: ArAccount = {
      accountName: paymentTransDetail["aRAccountName"] || paymentTransDetail["arAccountName"],
      accountNumber: paymentTransDetail["aRAccountNumber"] || paymentTransDetail["arAccountNumber"]
    }
    const maskCardNumber = (cardNumber: string): string => { return cardNumber.replace(/.(?=.{4})/g, '*') }
    const paymentMethodCaptions = this.localization.captions.shop.paymentMethods;
    let paymentName = GlobalConst.PaymentMethodValue[paymentTypeId];
    let paymentApiName = paymentTransDetail.paymentMethod;
    let paymentLabel = (paymentApiName == paymentName) ? paymentMethodCaptions[paymentTypeId] : paymentApiName;
    if (paymentTypeId == PaymentMethods.CompRedemption && paymentTransDetail.patronId != '') {
      return `${paymentLabel} | ${patronId} | ${this.localization.captions.shop.CMS.handleType[paymentTransDetail.redemptionType == 1 ? "Points" : "Comps"]}`;
    } else if (paymentTypeId == PaymentMethods.OfferRedemption && patronId != '') {
      return `${paymentLabel} | ${patronId} | ${paymentTransDetail.offerName}`;
    } else if (V1GiftCardMethods.includes(paymentTypeId) && paymentTransDetail.cardNumber) {
      return `${paymentLabel} | ${maskCardNumber(paymentTransDetail.cardNumber)}`;
    } else if (this.GiftCardMethods.includes(paymentTypeId) && paymentTransDetail.cardNumber) {
      const ExternalGiftCardIDTechMethods: PaymentMethods[] = [
        PaymentMethods.ExternalGiftCardIdTech,
        PaymentMethods.AgilysysGiftCardIdTech
      ]
      const IDTechPaymentMethodNameMapper = {
        [PaymentMethods.ExternalGiftCardIdTech]: this.oCaptions.shop.paymentMethods[PaymentMethods.ExternalGiftCard],
        [PaymentMethods.AgilysysGiftCardIdTech]: this.oCaptions.shop.paymentMethods[PaymentMethods.AgilysysGiftCard]
      }
      paymentLabel = ExternalGiftCardIDTechMethods.includes(paymentTypeId) ? IDTechPaymentMethodNameMapper[paymentTypeId] : paymentLabel;
      const paymentMethod = this.localization.replacePlaceholders(paymentLabel, ["Third Party"], [this.giftcardBusiness.GiftcardVendorName])
      return `${paymentMethod} | ${maskCardNumber(paymentTransDetail.cardNumber)}`;
    }
    else if (paymentTypeId == PaymentMethods.ARAcctPost && acctdetails) {
      if (this._featureFlagInfo.SenderCode.toUpperCase().trim() === ARSenderCode.STAY) {
        return `${paymentLabel} | ${acctdetails.accountName}`;
      }
      else {
        return `${paymentLabel} | ${acctdetails.accountName} | ${acctdetails.accountNumber}`;
      }
    } else if ((paymentTypeId == PaymentMethods.RoomCharge || paymentTypeId == PaymentMethods.GroupCharge) && paymentTransDetail.roomNumber) {
      return `${paymentLabel} | ${paymentTransDetail.reservationName} | ${paymentTransDetail.roomNumber}`
    }
    else if (paymentTypeId == PaymentMethods.HotelComp && paymentTransDetail.roomNumber){
      return `${paymentLabel} | ${this.oCaptions.shop.HotelCompNumber} : ${paymentTransDetail.roomNumber}`;
    } 
    else if (paymentTypeId == PaymentMethods.CreditCard || paymentTypeId == PaymentMethods.IDTECH && paymentTransDetail.cardNumber) {
      return `${paymentLabel} | ${paymentTransDetail.cardNumber} | ${paymentTransDetail.issuerType}`;
    }
    else if ((paymentTypeId == PaymentMethods.ARPost || paymentTypeId == PaymentMethods.CreditBook) && acctdetails && acctdetails.accountNumber) {
      return `${paymentLabel} | AR Number: ${acctdetails?.accountNumber || ''}`;
    } else if ((paymentTypeId == PaymentMethods.PostToFolio && paymentTransDetail && paymentTransDetail.folioInvoiceNumber)) {
      return `${paymentLabel} | ${paymentTransDetail.folioInvoiceNumber}`;
    } else if (paymentTypeId == PaymentMethods.Wallet && paymentTransDetail && paymentTransDetail.walletNumber) {
      let maskedWalletNumber = this._walletBusiness.MaskWalletNumber(paymentTransDetail.walletNumber)
      return `${paymentLabel} | ${maskedWalletNumber}`;
    } else if (paymentMethodCaptions[paymentTypeId]) {
      return paymentLabel;
    }
    else {
      return paymentMethods?.find(x => x.paymentTypeId == paymentTypeId)?.paymentMethod;
    }

  }

  async ResetReturnObjects() {
    this.ifPaymentChoose = false;
    this.remainingAmount = 0;
    this.SettledAmt = 0;
    this.returnedTax = 0;
    this.ShowTenderField = false;
    this.partialPayment = false;
    this.PrevSettlementHistory = [];
    this.SettlementHistory = [];
    this.ShowPaymentMethods = true;
    this.disabledRadiobool = false;
    this.ifPaymentChoose = false;
    this.ifCreditCardBool = false;
    this.selectPaymentOption = false;
    this.deviceId = 0;
    this.cardDetailsBool = false;
    this.assignClientToRoom = true;
    this.PaymentBeforeClinet = true;
    this.oCaptions = this.localization.captions;
    this.SettlementHistory = [];
    this.PrevSettlementHistory = [];
    this.paymentMethods = [];
    this.paymentMethodsClone = [];
    this.ShowPaymentMethods = true;
    this.EnableCloseTranBtn = false;
    this.InitiateRetailTranRequest = false;
    this.FullPayment = true;
    this.ShowSettlemtHist = false;
    this.ContinuePayment = false;
    this.AllowCancel = true;
    this.paymentProcessing = false;
    this.ConfirmationPopupShown = false;
    this.RoomChargeTenderID = "3";
    this.defaultSelectchecked = 0;
    this.selectedDeviceHandle = '';
    this.ReturnWithTicketResponse = null;
    this.CurrentTryPayResponse = null;
    this.GatewayConfiguration = null;
    this.SettledAmt = 0;
    this.remainingAmount = 0;
    this.paymentText = '';
    this.deviceType = [];
    this.GuestAccounts = [];
    this.EncryptedCardData = '';
    clearTimeout(this.IDTechCardSwipeTimeOut);
    this.cmsPlayerHandles = [];
    this.TransactionPayments = [];
    this.TransactionPaymentsAggregate = [];
    this.isCMSReturnFlow = false;
    this.selectedVoucher = null;
    this.cmsPlayerInfo = null;
    this.selectedGiftCard = null;
    this.selectedARAccount = null;
    this.currentARPostResponse = null;
    this.Vouchers = [];
    this.showAllPaymentMethods = false;
    this._isAPICallInProgress = false;
    this.selectedGuestRoom = null;
    this.roomDetailsArray = [];
    this.returnOriginalPaymentOnly = false;
    this.isIGRetail = false;
    this.preferredInputSelectedBanner = "";
    this.tempSettlementHistory = [];
    this.quickIdUser = '';
    this.paymentHistoryDetailsForTransaction = [];
    this.playerDetails = [];
    this.scheduledTeeTimeIds = [];
  }





  async GetReceiptTextImage(ticketNumber: string, transactionId: number) {
    let options : ReceiptInputs = {
      transactionId: transactionId.toString(),
      receiptFormat: ReceiptFormat.text,
      returnTypeAsString: true
    };
    var result: any = await this._retailCommunication.putPromise<string>({
      body: options,
      route: RetailRoutes.GenerateReceipt
  });
    return result;
  }

  SetCheckZoomFlag(flag: boolean) { this.isCheckZoomEnabled = flag; }

  SetPaymentSectionScrollHeight() {
    const topHeaderAndMenuSectionHeight = 134;
    const scrollSectionId = "payment-scrollable-section";
    let topFixedSectionHeight = document.getElementById("payment-top-noscroll-section")?.offsetHeight;
    let scrollablePaymentSection = document.getElementById(scrollSectionId);
    if (scrollablePaymentSection) {
      scrollablePaymentSection.style.height = (window.innerHeight - (topFixedSectionHeight + topHeaderAndMenuSectionHeight)) + 'px';
      document.addEventListener("mousemove", function (e) {
        let distance = scrollablePaymentSection.offsetLeft + scrollablePaymentSection.offsetWidth - e.pageX;
        distance < 15 && distance > -15 ? scrollablePaymentSection.classList.add('more-width') : scrollablePaymentSection.classList.remove('more-width');
      });
    }
  }

  async CheckIfcloseTransactionllowed(transactionid: number, ticketnumber: string) {
    const response = await this.http.CallApiAsync({
      callDesc: 'ValidateCloseTransaction',
      method: HttpMethod.Put,
      uriParams: { transactionId: transactionid },
      showError: true,
      host: Host.retailPOS,
    }).catch(err => {
      this.CloseTransactionErrorHandler(err, ticketnumber);
      throw err;
    });
    return response;
  }

  CloseTransactionErrorHandler(err, ticketnumber: string) {
    if (this.http.ApiCallErrorHandler(err)) {
      let _errCode: number = (err.error != null && err.error.errorCode && !err.error.successStatus) ? err.error.errorCode : -2;
      let erroMsg = "";
      if (_errCode == -1) {
        const confirmationMsgForReopen = this.localization.replacePlaceholders(
          this.ShopCaptions.UnableToCloseTransaction,
          ['TicketNumber'],
          [ticketnumber]
        );
        erroMsg = confirmationMsgForReopen;
        this.http.ShowError(erroMsg, this.localization.captions.common.Error);
      }
      else {
        erroMsg = this.localization.getError(_errCode);
        const confirmationMsgForClose = this.localization.replacePlaceholders(
          erroMsg,
          ['TicketNumber'],
          [ticketnumber]
        );
        this.http.ShowError(confirmationMsgForClose, this.localization.captions.common.Error);
      }
    }
    console.log(err);
  }

  async closeTransaction(comments: string, transactionId: number, ticketnumber: string) {
    const result = await this.http.CallApiAsync({
      callDesc: 'TryCloseTransaction',
      method: HttpMethod.Put,
      host: Host.retailPOS,
      body: comments,
      uriParams: { transactionId: transactionId },
      showError: true
    }).catch(err => {
      this.CloseTransactionErrorHandler(err, ticketnumber);
      throw err;
    });
  }

  ExtractAndDisplayPaymentMgrError(errorResponse) {
    try {
      let errorfromRes: string = errorResponse?.error?.result?.errorMessage || errorResponse?.error?.message || errorResponse?.errorMessage
        || errorResponse?.error?.ErrorMessage || errorResponse?.message;
      if (errorfromRes && errorfromRes.includes("[")) {
        //Payment Mgr error will contain the rGuestPay error code in between, so extracting only the code and Msg
        let errorDetails = JSON.parse(errorfromRes?.split("[")[1]?.split("]")?.[0]);
        let errorCode = errorDetails?.Code ? errorDetails?.Code :
          //rGuestPay sends the error details differently for Credit token, so extracting once again from ErrorMessage
          errorDetails?.Message?.split(":")[1]?.split("|")?.[0]?.trim();
        this.payAgentService.PaymentErrorPrompt(errorCode, errorDetails?.Message);
      } else if (errorfromRes) {
        const errMsg = RequestTimedOutException == errorResponse ? REQUEST_TIMEOUT : errorfromRes;
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
      } else {
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMUnexpectedError);
      }
    } catch (e) {
      console.error("Error while extracting PaymentMgr Error:", e);
    }
  }

  async ValidatePaymentMethodActivationBasedOnComponentAccessLink(payeeInfo: PayeeInfo, isRWT = false) {

    const ResetSelectedPaymentAndDisableBtn = () => {
      if (this.selectedpayment?.paymentTypeId) {
        this.selectedpayment.paymentTypeId = 0;
        this.selectedpayment.parentTypeId = 0;
        this.EnableCloseTranBtn = false;
      }
    }
    const isTeetimeRefund = (this._ss.returnWithticketItems?.some(s => s.playerName && s.payeeId));
    //Skip Component Access Link validation if its not configured or override permission given or Retail Functionality not Applicable
    if (this._ss.hasOverrideAccessForComponentLinking
      || !this._componentAccessConfigService?.ComponentAccessTypeConfig
      || !this.functionalities?.ShowPaymentMethodBasedOnComponentAccessLink
      || this.IsFolioPaymentMethod
      || this.returnOriginalPaymentOnly
      || (!payeeInfo && ((!isRWT && !this._ss.SelectedPlayers?.length) || (isRWT && !isTeetimeRefund)))
    ) {
      if (this.isComponentAccessLinkingFilterApplied) {
        ResetSelectedPaymentAndDisableBtn();
      }
      this.isComponentAccessLinkingFilterApplied = false;
      this.paymentMethods = this.paymentMethodsClone = _.cloneDeep(this.paymentMethodsBeforeCompAccessValidation);
      return;
    }
    let playerType = (this._ss.memberCardNumber != "0" && payeeInfo?.playerCategoryId == PlayerCategory.Member) ? [PlayerCategory.Member]
      : payeeInfo != null && payeeInfo?.playerCategoryId == PlayerCategory.Guest ? [PlayerCategory.ExistingPlayer]
        : payeeInfo != null && payeeInfo?.playerCategoryId ? [payeeInfo?.playerCategoryId] : [];

    const transactionType = this.IsRefundFlow ? TransactionType.Refund : TransactionType.Sale;
    let allowedPMMethods: number[] = [];

    const GetUniqueIds = (collection, propertyToCheck): number[] => Array.from(new Set(...[collection.map(x => x[propertyToCheck])]));

    //Combining the playerCategory of all the players in Tee-time
    if (this._ss.SelectedPlayers?.length > 0) {
      playerType = playerType.concat(GetUniqueIds(this._ss.SelectedPlayers, "playerCategoryId"));
    }

    //Fethcing PlayerTypes for Teetime Refund directly from Retail Transaction
    if (isRWT && isTeetimeRefund) {
      const teetimeItems = this._ss.returnWithticketItems?.filter(s => s.playerName && s.payeeId);
      const playerIds = GetUniqueIds(teetimeItems, "payeeId");
      if (this.playerDetails.length == 0)
        this.playerDetails = await RetailDataAwaiters.getPayeeDetails(playerIds);
      playerType = playerType.concat(GetUniqueIds(this.playerDetails, "playerCategoryId"));
    }

    playerType.map(p => {
      //combining playerType wise paymentmethod and combining them for UNION results
      allowedPMMethods = allowedPMMethods.concat(this._componentAccessConfigService.GetAllAllowedPaymentMethodIds(p, transactionType))
    });

    if (allowedPMMethods?.length && this.paymentMethodsBeforeCompAccessValidation?.length) { //if there is nothing allowed, show all the method as earlier

      //Pending Settlement should always be shown for Sale
      const pendingSettlementPayment = this.paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.PendingSettlement);
      allowedPMMethods = !this.IsRefundFlow && pendingSettlementPayment ? allowedPMMethods.concat([pendingSettlementPayment?.id]) : allowedPMMethods;

      //Add Membership methods if member is attached, so that it will be available for Member business validation
      const memberMethodIds = this.paymentMethodsBeforeCompAccessValidation?.filter(x => MemberShipMethods.includes(x.paymentTypeId))?.map(x => x.id) || [];
      allowedPMMethods = playerType.some(p => p == PlayerCategory.Member) ? allowedPMMethods.concat(memberMethodIds) : allowedPMMethods;

      //Removing Duplicates
      allowedPMMethods = Array.from(new Set([...allowedPMMethods]));

      this.isComponentAccessLinkingFilterApplied = (allowedPMMethods.length > 0);

      //Filtering out only the allowed methods
      console.log("All Methods", this.paymentMethodsBeforeCompAccessValidation);
      console.log({ allowedPMMethods });
      this.paymentMethods = this.paymentMethodsClone = _.cloneDeep(this.paymentMethodsBeforeCompAccessValidation).filter(x => allowedPMMethods.includes(x.id));
      console.log("After Component linking:", this.paymentMethods);
    }
  }

  SetCashPopupPrefillFlag(MiscConfig) {
    const cashPopupZeroPatchConfig = MiscConfig?.find(x => x.switch == MiscellaneousSwitch.CASH_POPUP_ZERO_DOLLAR_POPULATE);
    this.patchTotalAmountInCashPopup = !(cashPopupZeroPatchConfig && cashPopupZeroPatchConfig?.value?.toLowerCase() === 'true');
  }

  async PostChargesToPMS(transactionId: number, selectedPaymentMethod: PaymentMethods
    , postingAmount: number, selectedGuestRoom: GuestRoomSearchResult
    , transactionPaymentId: number
    , paymentTypeId: number
    , retailTicketNumber: string
    , retailTransactionType: string
    , compScope
    , selectedPayment
    , posConstructedResponse) {
    try {
      let pmsRes: any;
      const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
      const payMethodAdditionalConfig = selectedPayment?.additionalConfigurations ? JSON.parse(selectedPayment.additionalConfigurations) : null;

      //PMS Integration Host ID Selection Logic
      const PayMethodPMSIntegrationHostId = payMethodAdditionalConfig?.find(x => x.Key === PMSIntegrationHostId)
      const multiPropertyPMSIntegrationHostId = selectedGuestRoom?.propertyInfo?.pMSIntegrationHostId;
      const productConfigPMSIntegrationHostId = propConfig?.PMSIntegrationHostId;
      const pmsIntegrationHostId = multiPropertyPMSIntegrationHostId? multiPropertyPMSIntegrationHostId : PayMethodPMSIntegrationHostId? PayMethodPMSIntegrationHostId?.Value : productConfigPMSIntegrationHostId??'1'
      
      let postChargeToPMSRoute;
      const isSkipPMAgent = this._featureFlagInfo.IsSkipPMAgent(selectedPayment);

      //Construct Route
      if (isSkipPMAgent) {
        postChargeToPMSRoute = (this.utils.GetOriginalTenderId(selectedPayment.paymentTypeId, selectedPayment.parentTypeId) == PaymentMethods.GroupCharge) ?
          RetailRoutes.PMSCommunicationSenderGroupPost : RetailRoutes.PMSCommunicationSenderRoomPost;
      }
      else {
        postChargeToPMSRoute = RetailRoutes.PostChargeToPMS
      }

      //Construct Request Body
      const ReqBody: PMSPostRequest = {
        guestName: selectedGuestRoom.GuestName,
        roomNumber: selectedGuestRoom.RoomNumber,
        roomReference: selectedGuestRoom.RoomReference,
        bookingId: selectedGuestRoom.groupBookingId,
        amount: postingAmount,
        tenderId: paymentTypeId,
        paymentMethodId: selectedPaymentMethod,
        paymentTypeId: selectedPayment?.paymentTypeId ?? 0,
        parentTypeId: selectedPayment?.parentTypeId ?? 0,
        paymentMethodTableId: selectedPayment?.id ?? 0,
        transactionId: transactionId,
        transactionPaymentId: transactionPaymentId,
        multiPMSPropertyIndex: selectedGuestRoom?.propertyInfo != null ? selectedGuestRoom?.propertyInfo?.multiPMSPropertyIndex : "",
        folioInvoiceNumber: '',
        roomChargePostTypeNumber: '',
        multiPropertyPropCode: selectedGuestRoom?.propertyInfo != null ? selectedGuestRoom?.propertyInfo?.PropCode : "",
        pMSIntegrationHostId: pmsIntegrationHostId,
        clerkId: Number(this.localization.GetsessionStorageValue('userSessionConfigInfo', 'UserId')),
        clerkUserName: this.localization.GetUserInfo('userName'),
        outletId: this._ss.SelectedOutletId,
        outletName: posConstructedResponse?.outletName ?? this._ss.outlets.find(x => x.subPropertyID == this._ss.SelectedOutletId)?.subPropertyName,
        retailTicketNumber: retailTicketNumber,
        retailTransactionType: retailTransactionType,
        clientId: posConstructedResponse?.clientId,
        postingId: posConstructedResponse?.postingId,
        postingCheckNumber: posConstructedResponse?.postingCheckNumber ?? retailTicketNumber,
        paymentReferenceComments: compScope.paymentComments,
        transactionAdditionalReference: posConstructedResponse?.transactionAdditionalReference,
        transactionAdditionalComment: posConstructedResponse?.transactionAdditionalReference,
        financialBins: posConstructedResponse?.financialBins,
        postingRecords: posConstructedResponse?.postingRecords,
        checkZoomString: posConstructedResponse?.checkZoomString,
        paymentMethodAdditionalConfigurations: payMethodAdditionalConfig
      }
      pmsRes = await this._retailCommunication.postPromise({
        route: postChargeToPMSRoute,
        body: ReqBody
      }, false)

      if (isSkipPMAgent) {
        await this.InvokePayOnPMSPostingViaPMSCommunicationSender(retailTransactionType, pmsRes, compScope, selectedPayment, pmsRes?.errorMessage);
      }

      return pmsRes;
    }
    catch (error) {
      this.utils.ToggleLoader(false);
      console.error("Error occurred while posting charges to PMS" + error);
    }
  }

  async InvokePayOnPMSPostingViaPMSCommunicationSender(retailTransactionType, response, compScope, selectedPayment, errorMessage) {
    if (response?.status && response?.transactionId > 0) {
      if (retailTransactionType.trim().toUpperCase() == 'SALE' || retailTransactionType.trim().toUpperCase() == 'SETTLE' ||retailTransactionType.trim().toUpperCase() == 'RETURN WITHOUT TICKET') {
        await compScope._pblh.HandlePayRequest(response, response?.paymentManagerResponse);
      }
      else {
        if (this.IsRefundFlow) {
          this.AddSettlemtAndShowDialog(response.paymentManagerResponse, compScope, response);
          await compScope.RefundSale(response.transactionId);
        }
        else{
          await this.HandlePayRequest(response, response?.paymentManagerResponse, compScope);
        }
      }
    }
    else {
      this.utils.ToggleLoader(false);
      this.dialog.closeAll();
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMessage);
    }
  }

  async PostChargesToPMSForResortFinance(selectedPaymentMethod: PaymentMethods
    , postingAmount: number, selectedGuestRoom: GuestRoomSearchResult
    , paymentTypeId: number, folioNumber: string, postTypeNum: string
    , sourceSystem: string, resortFinanceCheckZoom: string) {
    try {
      const ReqBody: PMSPostRequest = {
        guestName: selectedGuestRoom.GuestName,
        roomNumber: selectedGuestRoom.RoomNumber,
        roomReference: selectedGuestRoom.RoomReference,
        bookingId: selectedGuestRoom.groupBookingId,
        amount: postingAmount,
        paymentMethodId: selectedPaymentMethod,
        transactionId: 0,
        transactionPaymentId: 0,
        tenderId: paymentTypeId,
        multiPMSPropertyIndex: selectedGuestRoom?.propertyInfo != null ? selectedGuestRoom?.propertyInfo?.multiPMSPropertyIndex : "",
        folioInvoiceNumber: folioNumber,
        roomChargePostTypeNumber: postTypeNum,
        sourceSystem: sourceSystem,
        checkZoom: resortFinanceCheckZoom
      }
      let pmsRes: boolean = await this._retailCommunication.postPromise({
        route: RetailRoutes.PostChargeToPMSForResortFinance,
        body: ReqBody
      }, false);
      return pmsRes;
    } catch (error) {
      console.error("Error occurred while posting charges to PMS" + error);
    }
  }


  async PostChargesToPMSViaPMSCommunicationSender(payRequest: SaleRequest, outletId = 0, ceds: string = "") {
    const pmsCommuicationSenderRoute = this.GetPMSCommunicationSenderPostingRoute(payRequest?.originalTenderId);
    payRequest.inquirerInfo.tenderId = await this.payAgentService.FormTenderId(payRequest.inquirerInfo.tenderId, outletId, ceds);
    return this._retailCommunication.postPromiseDirect<SaleResponse>({
      route: pmsCommuicationSenderRoute,
      body: payRequest
    });
  }

  GetPMSCommunicationSenderPostingRoute(originalTenderId: number) : string{
    let pmsCommunicationSenderRoute = '';
    switch(originalTenderId){
      case PaymentMethods.RoomCharge:
        {
          pmsCommunicationSenderRoute = RetailRoutes.PMSCommunicationSenderRoomSale;
          break;
        }
      case PaymentMethods.GroupCharge:
        {
          pmsCommunicationSenderRoute = RetailRoutes.PMSCommunicationSenderGroupSale; 
          break;         
        }
      case PaymentMethods.HotelComp:
        {
          pmsCommunicationSenderRoute = RetailRoutes.PMSCommunicationSenderHotelCompPost; 
          break;          
        }
    }
    return pmsCommunicationSenderRoute; 
  }

  CheckPMSCommunication(originalTenderId: number, isRoomOrGroupCharge: boolean = false): Boolean {
    let isPMSCommunicationEnabled_Session = sessionStorage.getItem('IsPMSPostingViaCommunicator');
    let isPMSCommunicationEnabled: Boolean = false;
    if (isPMSCommunicationEnabled_Session && (isPMSCommunicationEnabled_Session.toLowerCase() == 'true')) {
      isPMSCommunicationEnabled = true;
    }
    return isPMSCommunicationEnabled && (isRoomOrGroupCharge || originalTenderId == PaymentMethods.RoomCharge || originalTenderId == PaymentMethods.GroupCharge)
  }

  FilterCMSApplicableBuckets(cmsPlayerHandles: any[]) {
    let applicableBuckets = cmsPlayerHandles;
    try {
      if (this._featureFlagInfo.CMSApplicableBuckets) {
        console.log("CMS Available buckets" + this._featureFlagInfo.CMSApplicableBuckets);
        const configuredBuckets = this._featureFlagInfo.CMSApplicableBuckets?.split(",");
        applicableBuckets = applicableBuckets.filter(x => configuredBuckets.some(c => c?.trim()?.toLowerCase() == x.type?.trim()?.toLowerCase()));
      }
    }
    catch (err) {
      console.log("Error while filter Applicable buckets: " + err)
    }
    return applicableBuckets;
  }

  CheckIfCreditToGuestIsAllowed(gatewayConfiguration: GatewayConfiguration): boolean {
    let restrictCreditToGuest = false;
    if (gatewayConfiguration != null) {
      restrictCreditToGuest = gatewayConfiguration?.restrictCreditToGuest;
      if (restrictCreditToGuest) this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.ShopCaptions.CreditToGuestNotAllowed);
    }
    return restrictCreditToGuest;
  }

  PrefillValuesBasedOnResortFinanceInput(scope) {
    if (scope.resortfolioInput?.guestLastName && scope.IsResortFinanceMakePaymentFlow) {
      this.SelectedRoomNo = scope.resortfolioInput?.guestLastName;
      this.isDefaultRoomLookUpByName = true;
    }
    else {
      this.isDefaultRoomLookUpByName = false;
    }
  }

  GetCMSHandleName(handleType) {
    if (this._featureFlagInfo.CMSCurrencyName && this.ShopCaptions.CMS.handleType[handleType]) {
      const captionSplit = this.ShopCaptions.CMS.handleType[handleType].split(" ");
      captionSplit[captionSplit.length - 1] = this._featureFlagInfo.CMSCurrencyName;
      return captionSplit.join(" ")
    } else {
      return this.ShopCaptions.CMS.handleType[handleType];
    }
  } 
  
  async getRentalInformation(request: ShopRentalInfoRequest){
    const response = await this.http.CallApiAsync<RentalInfoWithAvailability[]>({
      callDesc: RetailRoutes.GetShopRentalInfoAvailabiliybyItemIds,
      method: HttpMethod.Post,  
      body: request,    
      showError: true,
      host: Host.retailManagement,
    }).catch(err => {
      throw err;
    });
    return response.result;
  }

  async GetTipConfig() {
    try {
      this.utils.ToggleLoader(true);
      const response = await this._tipConfigurationService.GetTipConfiguration();
      this.utils.ToggleLoader(false);
      if (response) {
        return {
          allowTipConfig: response.enableTip,
          id: response.id,
          isPercentage: response.tipInPercentage,
          presetValue: JSON.parse(response.presetValues)
        } as TipConfig
      }
    }
    catch (error) {
      this.utils.ToggleLoader(false);
      console.error(error);
    }
  }
}
