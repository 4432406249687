import { AdddepositComponent } from './appointment-actions/add-deposit/add-deposit.component';
import { AdddespositsearchComponent } from './add-depositsearch/add-depositsearch.component';
import { AdditionalInformationComponent } from './appointment-popup/create-client/additional-information/additional-information.component';
import { AddwaitlistClientTherapistMappingComponent } from './addwaitlist-client-therapist-mapping/addwaitlist-client-therapist-mapping.component';
import { AddwaitlistComponent } from './addwaitlist/add-waitlist.component';
import { AddwaitlistPopupComponent } from './addwaitlist-popup/addwaitlist-popup.component';
import { AddwaitlistValidationPipe } from './addwaitlist-popup/addwaitlist-validation.pipe';
import { AgPhoneNumberComponent } from './ag-phone-number/ag-phone-number.component';
import { AgsysCalendarGridComponent } from './calendar-grid/calendar-grid.component';
import { AgysMailIdComponent } from './agys-mail-id/agys-mail-id.component';
import { AlertMessagePopupComponent } from './alert-message-popup/alert-message-popup.component';
import { AppointmentActionsCommonDialogComponent } from './common-functionalities/appointment-actions-dialog/appointment-actions-common-dialog.component';
import { AppointmentBusiness } from './business/appointment-break-business';
import { AppointmentdetailsComponent } from './appointment-popup/create-appointment/appointmentdetails/appointmentdetails.component';
import { appointmentEditService } from './service/edit-appointment.service';
import { AppointmentPopupComponent } from './appointment-popup/appointment-popup.component';
import { AppointmentpopupService } from './service/appointmentpopup.service';
import { AppointmentRecapComponent } from './common-functionalities/appointment-actions/appointment-recap/appointment-recap.component';
import { AppointmentRecapInfoComponent } from './common-functionalities/appointment-actions/appointment-recap-info/appointment-recap-info.component';
import { appointmentService } from './service/appointment.service';
import { AppointmentServiceInformationService } from './service/appointmentserviceinformation.service';
import { AppointmentTempHoldBusiness } from '../appointment/business/appointment-temphold.business';
import { AppointmentUtilities } from './utilities/appointment-utilities';
import { AppselectButtonDirective } from './directives/select-button.directives';
import { AsideFilterComponent, checkIndexPipe } from './aside-filter/aside-filter.component';
import { BaseResponse } from './business/shared.modals';
import { BindItineraryPipe, IteneraryComponent } from './appointment-popup/create-client/Itinerary/Itinerary.component';
import { BreakPointAccess } from './service/breakpoint.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CardTypeComponent } from './card-type/card-type.component';
import { CascadingDropdownComponent } from './cascading-dropdown/cascading-dropdown.component';
import { CategoryPipe } from './pipes/category-linking-pipe.pipe';
import { CdkTableModule } from '@angular/cdk/table';
import { CheckinnerboolPipe } from './pipes/checkinnerbool.pipe';
import { CheckInputValidate } from './directives/input-validate.directive';
import { checkorderExistPipe } from './tablevirtualscroll/pipes/checkobjexist.pipe';
import { ChipsAutocompleteExample } from './multiplesearch/multiplesearch.component';
import { clickOutsideDirective } from './directives/outside-click.directive';
import { ClientInfoComponent } from './addwaitlist/client-info/client-info.component';
import { ClientInformationComponent } from './appointment-popup/create-appointment/client-information/client-information.component';
import { ClientPreferencesComponent } from './appointment-popup/create-client/client-preferences/client-preferences.component';
import { ClientsearchComponent } from './clientsearch/client-search.component';
import { ClientService } from './service/client-service.service';
import { ColorSketchModule } from 'ngx-color/sketch';
import { CommissionValuesComponent } from './commission-values/commission-values.component';
import { CommonFunctionalities } from './common-functionalities/common.service';
import { CommonModule } from '@angular/common';
import { CommonService } from './service/common-service.service';
import { ConvertObjPipe } from './searchbox/conver-object.pipe';


import {
  ConvertTime,
  CustomColours,
  FilterisExist,
  IsbuttonValid,
  IsExist
} from './pipes/custom-filter-pipe.pipe';
import { CreateAppointmentComponent } from './appointment-popup/create-appointment/create-appointment.component';
import { CreateClientComponent } from './appointment-popup/create-client/create-client.component';
import { SpaCurrencyFormatterDirective } from '../core/localization/currency.directive';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CustomcolorDirectives } from './directives/custom-colour.directives';
import { CustomCurrencyPipe } from '../core/localization/currency.pipe';
import { CustomDateAdapter, MY_DATE_FORMATS } from '../core/localization/custom.dateAdapter';
import { CustomDropdownPipePipe } from './pipes/custom-dropdown-pipe.pipe';
import { CustomWidthCalculateDirective } from './directives/width-calculate.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DecimalRestrictionPipePipe } from './pipes/decimal-restriction-pipe.pipe';
import { DialogOverviewExampleDialog } from './dialog-popup/dialogPopup-componenet';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DuplicateCheckDirective } from './ag-phone-number/ag-duplicate-contact-directive';
import { EnablerowPipe } from './pipes/enablerow.pipe';
import { FilterPipe } from './pipes/filter-pipe.pipe';
import { FirstCharUpperPipePipe } from './pipes/first-char-upper-pipe.pipe';
import { FormatText } from './pipes/formatText-pipe.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GetLegendBorderWithBgColorPipe } from './pipes/get-legend-border-with-bg-color.pipe';
import { GetLegendColorPipesPipe } from './pipes/get-legend-color-pipes.pipe';
import { GetPrimary } from './pipes/getPrimary-pipe.pipe';
import { GetServiceNamePipesPipe } from './pipes/get-service-name-pipes.pipe';
import { GetServiceStatusCodePipesPipe } from './pipes/get-service-status-code-pipes.pipe';
import { GetTextColorPipesPipe } from './pipes/get-text-color-pipes.pipe';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { Host } from './globalsContant';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpMethod, HttpServiceCall } from './service/http-call.service';
import { HttpRequestService } from './service/http-request.service';
import { IfbooleanPipe } from './pipes/ifboolean.pipe';
import { ImageProcessorService } from './service/image-processor-service';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ImageValiation } from './searchbox/image-validation.pipe';
import { ImgThumbnailComponent } from './img-thumbnail/img-thumbnail.component';
import { inputtypeDirective } from './utilities/inputtype.directive';
import { IsSpaEllipsisDirective } from './utilities/isellipsis.directive';
import { IsExistPipe } from './pipes/isexist-pipe.pipe';
import { LimitExceedDirective } from './directives/limit-exceed.directive';
import { LoadDecimalValuePipe } from './pipes/load-decimal-value.pipe';
import { loadingInterceptor } from './service/loadingintersepctor.service';
import { LocalizeDatePipe } from '../core/localization/localize-date.pipe';
import { LocalizeDateTimePipe } from '../core/localization/localize-dateTime.pipe';
import { LocalizeTimePipesPipe } from './pipes/localize-time-pipes.pipe';
import { MedicalHistoryComponent } from './appointment-popup/create-client/medical-history/medical-history.component';
import { MedicalWarningDialogComponent } from './appointment-popup/create-appointment/medical-warning-dialog/medical-warning-dialog.component';
import { MenuComponent } from './menu/menu.component';
import { MessageService } from './service/messageservice.service';
import { MinuteToHourMinute } from './appointment-popup/create-appointment/appointmentdetails/minuteToHourMinute-Pipe.Pipe';
import { MoreSectionServiceService } from './more-section/more-section-service.service';
import { MultiLineEllipses } from './pipes/ellipses-pipe.pipe';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgDragDropModule } from 'ng-drag-drop';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { numFormat } from './utilities/num-formatter.directive';
import { NummaxLength } from './utilities/num-maxlength.directive';
import { OrderSummaryPipe } from './pipes/order-summary.pipe';
import { OtherdetailsComponent } from './appointment-popup/create-appointment/otherdetails/otherdetails.component';
import { PackageEntitlementsComponent } from './appointment-popup/create-appointment/package-entitlements/package-entitlements.component';
import { PackageSummaryDatePipe } from '../core/localization/package-summary-date.pipe';
import { PaymentButtonPipe } from './pipes/payment-validate-pipe';
import { PersonalInformationComponent } from './appointment-popup/create-client/personal-information/personal-information.component';
import { PopoverModule } from 'ngx-smart-popover';
import { RedenderingOptionComponent } from './searchbox/rendering-options/rendering.options.component';
import { RequiredLabelDirective } from './directives/required-label.directive';
import { RestrictTextPipe } from './pipes/restrict-text.pipe';
import { RouterModule } from '@angular/router';
import { ScrollbarModule } from 'ngx-scrollbar';
import { SearchboxComponent } from './searchbox/searchbox.component';
import { SearchboxService } from './searchbox/searchbox.service';
import { ServiceClientDetailsComponent } from './common-functionalities/appointment-actions/service-and-client-details/service-and-client-details.component';
import { ServiceRegistry } from './service/base.service';
import { SetcolumnvaluePipe } from './pipes/setcolumnvalue.pipe';
import { SettingDialogPopupCommonComponent } from './common-functionalities/setting-dialog-common-popup/setting-dialog-common-popup.component';
import { SlideInformationService } from './slide-information/slide-information.service';
import { SoapnotesComponent } from './appointment-popup/create-client/soapnotes/soapnotes.component';
import { SortOrderPipe } from './pipes/sort-order.pipe';
import { sortPipe } from './pipes/sort-pipe.pipe';
import { SPAConfig } from '../core/config/SPA-config';
import { SpaLocalization } from '../core/localization/spa-localization';
import { SpaUtilities } from './utilities/spa-utilities';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { TableComponent } from './table/table.component';
import { TablevirtualscrollComponent } from './tablevirtualscroll/tablevirtualscroll.component';
import { TemplatesModule } from '../common/templates/templates.module';
import { TextMaskDirective } from './utilities/mask.directive';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { SpaToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TransactionHistoryComponent } from './appointment-popup/create-client/transaction-history/transaction-history.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { UserAlerts } from '../core/config/alerts-config';
import { ViewMoreComponent } from './view-more/view-more.component';
import { ViewmoreDirective } from './directives/view-more.directive';
import { ViewMoreServiceService } from './view-more/view-more-service.service';
import { ViewSettingClientBusiness } from './common-functionalities/business/view-settings.business';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { WaitListService } from './addwaitlist-popup/waitlist-popup.service';
import { SplitButtonComponent } from './split-button/split-button.component';
import { FormFieldLabelValueComponent } from './form-field-label-value/form-field-label-value.component';
import { GetClientNamePipe } from './pipes/get-client-name.pipe';
import { HttpCancelService } from './service/httpcancel.service';
import { ManageHttpInterceptor } from './service/managehttp.interceptor';
import { MultiSelectButtonComponent } from './multi-select-button copy/multi-select-button.component';
import { ButtonViewmoreDirective } from './directives/button-viewmore.directive';
import { ObjectindexofPipe } from './multi-select-button copy/objectindexof.pipe';
import { AllowedSpecialCharacterDirective } from './directives/allowedSpecialCharacter.directive';
import { PreSettlementReceiptService } from './service/pre-settlement-receipt/pre-settlement-receipt.service'
import { PreSettlementReceiptBusinessService } from './service/pre-settlement-receipt/pre-settlement-receipt.buisness.service'
import { FastReportBusinessService } from '../report/fast-report/fastreport.business.service';
import { AgCommonModule } from '../ag-common/ag-common.module';
import { CommonSharedModule } from '../common/shared/shared/shared.module';
import { AuthenticationCommunication } from './communication/services/authentication.service';
import { LocalServerCommunication } from './communication/services/local-server.service';
import { SPACommunication } from './communication/services/sales-catering.service';
import { MsGraphApiCommunication } from './communication/services/ms-graph.service';
import { RetailmanagementCommunication } from './communication/services/retailmanagement.service';
import { MoreSectionComponent } from '../common/shared/shared/more-section/more-section.component';
import { ServiceListComponent } from './service-list/service-list.component';
import { LinkAppointmentsComponent } from './appointment-popup/create-appointment/link-appointments/link-appointments.component';
import { GuaranteeMethodComponent } from './appointment-popup/create-appointment/guarantee-method/guarantee-method.component';
import { RetailSharedModule } from '../retail/shared/retail-shared.module';
import { ClientBlockedInformationComponent } from './appointment-popup/create-client/client-blocked-information/client-blocked-information.component';
import { SpaMoreSectionComponent } from './more-section/more-section.component';
import { SpaWizardService } from '../appointment/spa-wizard/spa-wizard.service';
import { RetailIntegrationLogService } from '../retail/shared/service/retail-integrationLog.service';
import { ServiceGroupCascadingComponent } from './service-group-cascading/service-group-cascading.component';
import { CascadingCustomDropdownComponent } from './cascading-custom-dropdown/cascading-custom-dropdown.component';
import { SchedulingAssistantComponent } from './scheduling-assistant/scheduling-assistant.component';
import { ScheduleAsstTableComponent } from './scheduling-assistant/schedule-asst-table/schedule-asst-table.component';
import { SchedulingAssistantBusiness } from '../appointment/business/scheduling-assistant.business';
import { AppointmentServiceInformationBusiness } from '../appointment/spa-wizard/appointment-service-information/appointment-service-information.business';
import { SpaWizardBusiness } from '../appointment/spa-wizard/spa-wizard.business';
import { WizardSlotBusiness } from '../appointment/spa-wizard/spa-wizard.slot.business';
import { WizardClientBusiness } from '../appointment/spa-wizard/wizard-client-appointments/wizard-client-appointments.business';
import { SchedulingAssistantService } from './scheduling-assistant/scheduling-assistant.service';
import { SelectOutletModalComponent } from './select-outlet-modal/select-outlet-modal.component';
import { SelectOutletModalBusiness } from './select-outlet-modal/select-outlet-modal.business';
import{LostDeniedBusinessPopupService} from './appointment-actions/add-lost-denied-business-popup/lost-denied-business-popup.service';
import { ContactLogComponent } from 'src/app/common/contact-log/contact-log.component';
import { CreateContactLogComponent } from 'src/app/common/contact-log/create-contact-log/create-contact-log.component';
import { ClientRelationshipComponent } from './appointment-popup/create-client/client-relationship/client-relationship.component';
import { FindRelationshipComponent } from './find-relationship/find-relationship.component'
import {ContactLogService} from 'src/app/common/contact-log/contact-log.service';
import { GuestPolicyWrapperComponent } from './appointment-popup/create-client/additional-information/guest-policy-wrapper/guest-policy-wrapper.component';
import { AppointmentAddOnsComponent } from './appointment-popup/create-appointment/appointment-add-ons/appointment-add-ons.component'
import { SpaContactLogWrapperComponent } from './appointment-popup/create-client/spa-contact-log-wrapper/spa-contact-log-wrapper.component'
import { LocalizeDateMonthPipes } from './pipes/Localize-Date-MonthPipes';
import { ConfirmClientsPopupComponent } from './appointment-popup/create-client/confirm-clients-popup/confirm-clients-popup.component';
import { DepositInfoDialogComponent } from './appointment-popup/create-appointment/deposit-info-dialog/deposit-info-dialog.component';
import { AddLockersComponent } from './add-lockers/add-lockers.component';
import { AddCommentsComponent } from './add-comments/add-comments.component';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  keyboard: {
    enabled: true,
  },
  mousewheel: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slideToClickedSlide: true,
  loop: false,
  observer: true
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        CdkTableModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        NgDragDropModule.forRoot(),
        ScrollbarModule,
        NgxPaginationModule,
        NgbModule,
        FormsModule,
        //HttpClientModule,
        NgxMaterialTimepickerModule,
        GooglePlaceModule,
        PopoverModule,
        ColorSketchModule,
        BsDropdownModule.forRoot(),
        SwiperModule,
        UiSwitchModule.forRoot({}),
        NgxBarcodeModule,
        TemplatesModule,
        VirtualScrollerModule,
        DragDropModule,
        AgCommonModule,
        CommonSharedModule,
        RetailSharedModule
    ],
    declarations: [
        AppointmentRecapComponent,
        ServiceClientDetailsComponent,
        SettingDialogPopupCommonComponent,
        AppointmentActionsCommonDialogComponent,
        AppointmentRecapInfoComponent,
        IsExist,
        FilterisExist,
        IsbuttonValid,
        clickOutsideDirective,
        CustomColours,
        CustomcolorDirectives,
        ConvertTime,
        AsideFilterComponent,
        AppselectButtonDirective,
        AddwaitlistComponent,
        ClientsearchComponent,
        IteneraryComponent,
        AdddespositsearchComponent,
        AdddepositComponent,
        OtherdetailsComponent,
        ChipsAutocompleteExample,
        ClientPreferencesComponent,
        ClientBlockedInformationComponent,
        RequiredLabelDirective,
        DialogOverviewExampleDialog,
        TextMaskDirective,
        SpaCurrencyFormatterDirective,
        CustomCurrencyPipe,
        PackageSummaryDatePipe,
        AppointmentdetailsComponent,
        ClientInformationComponent,
        ClientInfoComponent,
        PersonalInformationComponent,
        AdditionalInformationComponent,
        MedicalHistoryComponent,
        SearchboxComponent,
        inputtypeDirective,
        FilterPipe,
        sortPipe,
        FormatText,
        MultiLineEllipses,
        TableComponent,
        LoadDecimalValuePipe,
        MultipleSelectComponent,
        MinuteToHourMinute,
        TransactionHistoryComponent,
        SoapnotesComponent,
        PackageEntitlementsComponent,
        ImgThumbnailComponent,
        NummaxLength,
        numFormat,
        AlertMessagePopupComponent,
        GetPrimary,
        DecimalRestrictionPipePipe,
        CreateClientComponent,
        CreateAppointmentComponent,
        SpaToggleSwitchComponent,
        LimitExceedDirective,
        OrderSummaryPipe,
        CategoryPipe,
        SpaMoreSectionComponent,
        ViewmoreDirective,
        CustomDropdownPipePipe,
        LocalizeDatePipe,
        LocalizeDateTimePipe,
        LoadDecimalValuePipe,
        RestrictTextPipe,
        IsExistPipe,
        checkIndexPipe,
        LocalizeDatePipe,
        LocalizeDateMonthPipes,
        AgsysCalendarGridComponent,
        LocalizeTimePipesPipe,
        GetServiceNamePipesPipe,
        GetLegendColorPipesPipe,
        GetTextColorPipesPipe,
        GetServiceStatusCodePipesPipe,
        AppointmentPopupComponent,
        EnablerowPipe,
        CheckinnerboolPipe,
        SetcolumnvaluePipe,
        IfbooleanPipe,
        CheckInputValidate,
        RedenderingOptionComponent,
        ConvertObjPipe,
        IsSpaEllipsisDirective,
        BindItineraryPipe,
        ImageValiation,
        AddwaitlistPopupComponent,
        ImageUploaderComponent,
        AddwaitlistValidationPipe,
        ViewMoreComponent,
        MedicalWarningDialogComponent,
        AddwaitlistClientTherapistMappingComponent,
        CustomWidthCalculateDirective,
        CascadingDropdownComponent,
        ServiceGroupCascadingComponent,
        FirstCharUpperPipePipe,
        NoDataFoundComponent,
        PaymentButtonPipe,
        CardTypeComponent,
        TimepickerComponent,
        CommissionValuesComponent,
        GetLegendBorderWithBgColorPipe,
        SortOrderPipe,
        MenuComponent,
        AgPhoneNumberComponent,
        DuplicateCheckDirective,
        AgysMailIdComponent,
        TablevirtualscrollComponent,
        checkorderExistPipe,
        SplitButtonComponent,
        FormFieldLabelValueComponent,
        GetClientNamePipe,
        MultiSelectButtonComponent,
        ButtonViewmoreDirective,
        ObjectindexofPipe,
        AllowedSpecialCharacterDirective,
        ServiceListComponent,
        LinkAppointmentsComponent,
        GuaranteeMethodComponent,
        CascadingCustomDropdownComponent,
        SchedulingAssistantComponent,
        ScheduleAsstTableComponent,
        SelectOutletModalComponent,
        ContactLogComponent,
        CreateContactLogComponent,
        ClientRelationshipComponent,
        FindRelationshipComponent,
        GuestPolicyWrapperComponent,
        SpaContactLogWrapperComponent,
        AppointmentAddOnsComponent,
        ConfirmClientsPopupComponent,
        DepositInfoDialogComponent,
        AddLockersComponent,
        AddCommentsComponent
    ],
    exports: [
        PaymentButtonPipe,
        AppointmentRecapComponent,
        ServiceClientDetailsComponent,
        CascadingDropdownComponent,
        ServiceGroupCascadingComponent,
        AsideFilterComponent,
        SwiperModule, ClientsearchComponent, IteneraryComponent, AdddespositsearchComponent,
        AdddepositComponent, OtherdetailsComponent, ChipsAutocompleteExample, RequiredLabelDirective,
        inputtypeDirective, TextMaskDirective, SpaCurrencyFormatterDirective, CustomCurrencyPipe,
        PackageSummaryDatePipe, NgDragDropModule, DialogOverviewExampleDialog, ScrollbarModule,
        AppointmentPopupComponent, AddwaitlistPopupComponent, SearchboxComponent, FilterPipe,
        sortPipe, FormatText, MultiLineEllipses, NgxPaginationModule, TableComponent,
        LoadDecimalValuePipe, MultipleSelectComponent, ClientInformationComponent, ClientInfoComponent,
        ColorSketchModule, TransactionHistoryComponent, PopoverModule, BsDropdownModule, ReactiveFormsModule,
        NgxMaterialTimepickerModule, ImgThumbnailComponent, NummaxLength, numFormat,
        ImageUploaderComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        CdkTableModule,
        MatFormFieldModule,
        AddwaitlistComponent,
        inputtypeDirective,
        GetPrimary,
        DecimalRestrictionPipePipe,
        CreateClientComponent,
        CreateAppointmentComponent,
        UiSwitchModule,
        SpaToggleSwitchComponent,
        LimitExceedDirective,
        OrderSummaryPipe,
        CategoryPipe,
        BindItineraryPipe,
        MoreSectionComponent,
        SpaMoreSectionComponent,
        ViewMoreComponent,
        ViewmoreDirective,
        clickOutsideDirective,
        CheckInputValidate,
        IsExistPipe, EnablerowPipe,
        CheckinnerboolPipe,
        SetcolumnvaluePipe,
        IfbooleanPipe,
        IsExist, IsbuttonValid, CustomColours, ConvertTime, FilterisExist, ImageValiation,
        AgsysCalendarGridComponent, CustomDropdownPipePipe,
        NoDataFoundComponent,
        RestrictTextPipe, LocalizeDatePipe, LocalizeDateTimePipe, LoadDecimalValuePipe, AppselectButtonDirective, RedenderingOptionComponent,
        ConvertObjPipe, AddwaitlistClientTherapistMappingComponent, CustomWidthCalculateDirective, IsSpaEllipsisDirective, CommissionValuesComponent,
        GetLegendColorPipesPipe, GetLegendBorderWithBgColorPipe, SortOrderPipe, MenuComponent, AgPhoneNumberComponent, DuplicateCheckDirective,
        AgysMailIdComponent,
        TablevirtualscrollComponent,
        checkorderExistPipe,
        SplitButtonComponent,
        FormFieldLabelValueComponent,
        GetClientNamePipe,
        MultiSelectButtonComponent,
        ServiceListComponent,
        ButtonViewmoreDirective,
        ObjectindexofPipe,
        AllowedSpecialCharacterDirective,
        AgCommonModule,
        CommonSharedModule,
        LinkAppointmentsComponent,
        GuaranteeMethodComponent,
        CascadingCustomDropdownComponent,
        AddLockersComponent
    ],
    providers: [
        ServiceRegistry,
        SettingDialogPopupCommonComponent,
        CommonService,
        SpaWizardBusiness,
        SchedulingAssistantBusiness,
        SchedulingAssistantService,
        WizardSlotBusiness,
        AppointmentServiceInformationBusiness,
        WizardClientBusiness,
        appointmentEditService,
        AppointmentpopupService,
        SpaWizardService,
        SPAConfig,
        HttpServiceCall,
        SpaUtilities,
        SearchboxService,
        ClientService,
        sortPipe,
        AppointmentServiceInformationService,
        NgxImageCompressService,
        //HttpClientModule,
        HttpRequestService,
        MessageService,
        BreakPointAccess,
        MoreSectionServiceService,
        ViewMoreServiceService,
        ImageProcessorService,
        AppointmentTempHoldBusiness,
        PreSettlementReceiptService,
        PreSettlementReceiptBusinessService,
        FastReportBusinessService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: loadingInterceptor,
            multi: true,
        },
        AppointmentUtilities,
        {
            provide: DateAdapter, useClass: CustomDateAdapter, deps: [SpaLocalization]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG,
        },
        AppointmentBusiness,
        SlideInformationService,
        ViewSettingClientBusiness,
        CommonFunctionalities,
        FormatText,
        WaitListService,
        LostDeniedBusinessPopupService,
        ContactLogService,
        NgxBarcodeModule,
        CustomCurrencyPipe,
        LoadDecimalValuePipe,
        LocalizeDatePipe,
        HttpCancelService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ManageHttpInterceptor,
            multi: true
        },
        RetailIntegrationLogService,
        AuthenticationCommunication,
        LocalServerCommunication,
        SPACommunication,
        MsGraphApiCommunication,
        RetailmanagementCommunication,
        ServiceListComponent,
        SelectOutletModalBusiness
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {

  constructor(
    private adapter: DateAdapter<any>,
    public localization: SpaLocalization,
    private userAlerts: UserAlerts,
    private http: HttpServiceCall,
    public utils: SpaUtilities) {
    this.adapter.setLocale(localization.localeCode);
    if (!this.userAlerts.IsUserPromptConfigsLoaded) {
      if (this.utils.GetPropertyInfo('PropertyId')) {
        this.GetUserPromptConfigs();
      }
    }
  }
  GetUserPromptConfigs() {
    this.http.CallApiWithCallback<any[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetUserConfiguration',
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string): void {
    if (callDesc === 'GetUserConfiguration') {
      const _result: any = result.result;
      this.userAlerts.IsUserPromptConfigsLoaded = true;
      this.userAlerts.userPromptConfigs = _result;
    }
  }

  errorCallback(callDesc: string): void {
    if (callDesc === 'GetUserConfiguration') {
      this.userAlerts.userPromptConfigs = [{
        SYSTEM_MESSAGES_PRIORITY1: false,
        SYSTEM_MESSAGES_PRIORITY2: false,
        SYSTEM_MESSAGES_PRIORITY3: false
      }];
    }
  }

}
