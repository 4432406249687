import {
  ActionType,
  AppointmentFrom,
  AppointmentStatus,
  ButtonType,
  Host
} from 'src/app/shared/globalsContant';
import {
  appointment,
  appointmentDetail,
  BaseResponse,
  ClientInfo,
  GenderOverrideMessage,
  GenderOverrideTransLog,
  ManagementData,
  ServiceDetail,
  ServiceViewModel
} from 'src/app/shared/business/shared.modals';
import { AppointmentActionsDialogComponent } from 'src/app/shared/appointment-actions/appointment-actions-dialog/appointment-actions-dialog.component';
import { appointmentService } from 'src/app/shared/service/appointment.service';
import * as GlobalConst from '../../shared/globalsContant';
import {
  BookingDetails,
  ClientForm,
  FilterOptions,
  GroupActivity,
  GroupAppointment,
  GroupDetail,
  LocationAPI,
  LocationUI,
  ServiceGroupAPI,
  SpaClient,
  SpaWizardClient,
  spaWizardSaveMenu,
  TempAppointmentInfo,
  TherapistDetailAPI,
  TherapistUI,
  WizardAppointmentType,
  WizardAutoBookTempAppointmentRequest,
  WizardGroupAppointmentRequest,
  WizardTempAppointmentRequest,
  WizardTempAppointmentResponse,
  WizardTimeSlots
} from './spa-wizard.modal';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SlideInformationService } from 'src/app/shared/slide-information/slide-information.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { SpaWizardService } from './spa-wizard.service';
import { AppointmentActionFilter } from 'src/app/shared/shared.modal';
import { AppointmentUtilities } from 'src/app/shared/utilities/appointment-utilities';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { AppointmentpopupService } from 'src/app/shared/service/appointmentpopup.service';

@Injectable()
export class SpaWizardBusiness {
  actionTypes = ActionType;
  captions: any;
  spaWizardCaptions: any;
  alertCaptions: any;
  moreOptions:any=[];
  disableMoreOptions:boolean=false;
  disableMoreOptionsForAppointment:boolean=false;
  constructor(
    private utilities: SpaUtilities
    , public http: HttpServiceCall
    , private _appService: appointmentService
    , private apptPopService: AppointmentpopupService
    , public SlideService: SlideInformationService
    , private dialog: MatDialog
    , private PropertyInfo: SpaPropertyInformation
    , private localization: SpaLocalization
    , private wizardService: SpaWizardService
    , private ApptUtilities: AppointmentUtilities
  ) {
    this.captions = this.localization.captions.bookAppointment;
    this.spaWizardCaptions = this.localization.captions.bookAppointment.spawizard;
    this.alertCaptions = this.localization.captions.alertPopup;
  }

  async GetRecentAppointmentData(startDate: any,endDate:any, searchString: string,selectedSearchType:any) {
    this.utilities.ToggleLoader(true);
    const response = await this.wizardService.GetRecentAppointmentData(searchString, startDate, endDate,selectedSearchType);
    response.forEach(x => {
      if (x.startTime) {
        x.appointmentDate = x.startTime;
        x.appointmentDate=x.endTime;
        x.startTime = this.localization.localizeDisplayDate(x.startTime);
        x.endTime=this.localization.localizeDisplayDate(x.endTime);
        x.status = this.formStatus(x);
      }
    });

    if (response.length === 0) {
      return [];
    }
    this.utilities.ToggleLoader(false);
    return response;
  }

  formStatus(element) {
    switch (element.status) {
      case 'CKIN':
        element.statusCode = GlobalConst.AppointmentStatus.CheckIn;
        element.status = this.captions.CheckedIn;
        break;
      case 'CKOUT':
        element.statusCode = GlobalConst.AppointmentStatus.CheckOut;
        element.status = this.captions.CheckedOut;
        break;
      case 'RESV':
        element.statusCode = GlobalConst.AppointmentStatus.Scheduled;
        element.status = this.captions.Scheduled;
        break;
      case 'CANC':
        element.statusCode = GlobalConst.AppointmentStatus.Canceled;
        element.status = this.captions.Cancelled;
        break;
      case 'NOSHOW':
        element.statusCode = GlobalConst.AppointmentStatus.NoShow;
        element.status = this.captions.NoShow;
        break;

      default:
        break;
    }
    return element.status;
  }

  GetRecentAppointmentHeader() {
    return [{ 'description': 'Name', 'key': 'clientName', 'alignment': 'textLeft', sorting: false, searchable: true, additionalClass: 'icon-Member_new', additionalIconField: 'memberGuest' },
    { 'description': 'Date', 'key': 'startTime', 'alignment': 'textLeft', sorting: false, searchable: false },
    { 'description': 'Booking ID', 'key': 'bookingId', 'alignment': 'textLeft', sorting: false, searchable: true },
    { 'description': 'Booking Name', 'key': 'bookingName', 'alignment': 'textLeft', sorting: false, searchable: true },
    { 'description': 'Hotel Booking Id', 'key': 'hotelBookingId', 'alignment': 'textLeft', sorting: false, searchable: true },
    { 'description': 'Status', 'key': 'status', 'alignment': 'textLeft', sorting: false, searchable: true },];
  }

  GetAppointmentsMedicalConditionHeader() {
    return [
      { 'description': 'Name', 'key': 'clientName', 'alignment': 'textLeft', sorting: false, searchable: true },
      { 'description': 'Medical Condtion', 'key': 'medicalCondition', 'alignment': 'textLeft', 'customCss': 'medical-desc', sorting: false, searchable: false }
    ];
  }

  GetMoreOptionsData(settings:any) {
    let spaWizardOptions=settings.APPOINMENT_SPAWIZARDOPTIONS;
    let bookLunch = false;
    let deposit = false;
    let cancel = false;
    let checkIn =false;
    let undoCheckIn = false;
    let checkOut =false;
    let print =false;
    if(settings?.APPOINMENT_SPAWIZARDOPTIONS)
    {
      let appointmentspaWizardOptions = Object.entries(JSON.parse(settings.APPOINMENT_SPAWIZARDOPTIONS));
      bookLunch=(appointmentspaWizardOptions.filter(x=>x[0]=="APPOINMENT_SPAWIZARDBOOKLUNCH").map(y=>y[1])[0]=='true'?true:false);
      deposit=(appointmentspaWizardOptions.filter(x=>x[0]=="APPOINMENT_SPAWIZARDDEPOSIT").map(y=>y[1])[0]=='true'?true:false);
      cancel=(appointmentspaWizardOptions.filter(x=>x[0]=="APPOINMENT_SPAWIZARDCANCEL").map(y=>y[1])[0]=='true'?true:false);
      checkIn=(appointmentspaWizardOptions.filter(x=>x[0]=="APPOINMENT_SPAWIZARDCHECKIN").map(y=>y[1])[0]=='true'?true:false);
      undoCheckIn=(appointmentspaWizardOptions.filter(x=>x[0]=="APPOINMENT_SPAWIZARDUNDOCHECKIN").map(y=>y[1])[0]=='true'?true:false);
      checkOut=(appointmentspaWizardOptions.filter(x=>x[0]=="APPOINMENT_SPAWIZARDCHECKOUT").map(y=>y[1])[0]=='true'?true:false);
      print=(appointmentspaWizardOptions.filter(x=>x[0]=="APPOINMENT_SPAWIZARDPRINT").map(y=>y[1])[0]=='true'?true:false);
    }
    if(bookLunch && deposit && cancel && checkIn && undoCheckIn && checkOut && print)
    {
        this.disableMoreOptions=true;
    }
    else
    {
      this.disableMoreOptions=false;
    }
    if(bookLunch || deposit || cancel || checkIn || undoCheckIn || checkOut || print)
    {
        this.disableMoreOptionsForAppointment=false;
    }
    else
    {
      this.disableMoreOptionsForAppointment=true;
    }
    this.moreOptions= [
      { id: 1, description: this.captions.BookLunch, type: this.actionTypes.booklunch, icon: 'icon-lunch' ,show:bookLunch },
      { id: 2, description: this.captions.Deposit, type: this.actionTypes.adddeposit, icon: 'icon-adddeposit' ,show:deposit},
      { id: 3, description: this.captions.Cancel, type: this.actionTypes.cancel, icon: 'icon-cancel' ,show:cancel},
      { id: 4, description: this.captions.Check_In, type: this.actionTypes.checkin, icon: 'icon-checkin',show:checkIn },
      { id: 5, description: this.captions.UndoCheckIn, type: this.actionTypes.undocheckin, icon: 'icon-undo',show:undoCheckIn },
      { id: 6, description: this.captions.CheckOut, type: this.actionTypes.checkout, icon: 'icon-checkout',show:checkOut },
      { id: 7, description: this.captions.Print, type: this.actionTypes.print, icon: 'icon-print',show:print }
    ];
    return this.moreOptions;
  }

  openDialog(actionId, popupheader) {

    let width = '90%';
    let height = 'auto';
    if (actionId == ActionType.booklunch) {
      height = '80%';
    }
    if (actionId == ActionType.cancel ||
      actionId == ActionType.checkin ||
      actionId == ActionType.checkout ||
      actionId == ActionType.adddeposit) {
      height = '90%';
    }
    if (actionId == ActionType.staffChange || actionId == ActionType.changeSetup) {
      width = '60%';
      height = '60%';
    }
    this.sliderInfo({}, true, actionId);
    if (this.SlideService.fieldData.appointmentFrom == AppointmentFrom.SpaWizard) {
      if(actionId == ActionType.cancel && this.checkIfCancellationNoShowFeeEnabled()){
        this.utilities.showAlert(this.alertCaptions.CancellationRestricted, GlobalConst.AlertType.Info, ButtonType.Ok);
        return;
      }
      let filterRequest:AppointmentActionFilter
      if(actionId == ActionType.booklunch)
      {
      filterRequest = this.getAppointmentFilterData(actionId);
      }
      else
      {
      filterRequest = this.getAppointmentFilterDataCheckInCheckOutValidated(actionId);
      }
      return this.dialog.open(AppointmentActionsDialogComponent, {
        width: width,
        height: height,
        hasBackdrop: true,
        data: {
          id: actionId, headername: popupheader, closebool: true, appointmentInfomation: this.SlideService.fieldData,
          filterInput: filterRequest
        },
        panelClass: 'action-dialog-overlay'
      });
    }
    else {
      return this.dialog.open(AppointmentActionsDialogComponent, {
        width: width,
        maxWidth: '1000px;',
        height: height,
        hasBackdrop: true,
        data: {
          id: actionId,
          headername: popupheader,
          closebool: true,
          appointmentInfomation: this.SlideService.fieldData
        },
        panelClass: 'action-dialog-overlay'
      });
    }
  }

  checkIfCancellationNoShowFeeEnabled(){
    const isCancellationNoShowFeeEnabled = JSON.parse(sessionStorage.getItem('defaultSettings'))?.find(x=>x.switch == GlobalConst.CancellationNoShowFeeSwitch).value == 'true';
    return isCancellationNoShowFeeEnabled;
  }
  async undoCheckin(appointments: GroupAppointment[]) {
    const appointmentId = appointments.map(r => r.appointment.appointmentDetail.id);
    this.utilities.ToggleLoaderWithMessage(true, this.spaWizardCaptions.UpdatingAppointment);
    const result = await this.wizardService.UndoCheckIn(appointmentId);
    if (!result) {
      this.wizardService.groupAppointments.forEach(app => {
        if (appointmentId.some(r => app.appointment.appointmentDetail.id)) {
          app.appointment.appointmentDetail.status = AppointmentStatus.Scheduled;
        }
      });
      this.wizardService.appointmentUpdateNotifier$.next('Undo checkIn');
    }
    this.utilities.ToggleLoaderWithMessage(false);

  }

  public async getActivitiesByBookData(bookData: string): Promise<BookingDetails[]> {
    const response = await this.wizardService.getActivitiesByBookData(bookData);
    return response.map(x => this.BookingDetailsUIMapping(x));
  }
  private BookingDetailsUIMapping(input: GroupActivity): BookingDetails {
    return {
      bookingId: input.groupBookingId,
      bookingName: input.groupBookingName,
      NoOfPlayers: input.noOfPlayers,
      start: input.start,
      end: input.end,
      clients: input.guests
    };

  }

  public async LoadAppointmentConfig(): Promise<any> {
    return await this.http.CallApiAsync({
      host: Host.spaManagement,
      callDesc: "GetAppointmentConfiguration",
      method: HttpMethod.Get,
      showError: false
    });
  }

  public async getSpaClientsByGuids(guids: string[]): Promise<ClientInfo[]> {
    try {
      return await this.wizardService.getSpaClientByGuids(guids);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  /**
 * @function receivedfilterArray
 * @params Service option Id : any
 * @description Option from the serviceoptionComponent choose the particular service or package value pass to the parent component
 * @output Wizzard
 */
  receivedfilterArray = (event, cloneWizardarray) => {
    const result = [];
    event.selectedServices.forEach(service => {
      cloneWizardarray.forEach(x => {
        if (service.serviceName == 'Service Group') {
          if (x.serviceid == service.id) {
            result.push(x);
          }
        } else {
          if (x.packageid == service.id) {
            result.push(x);
          }
        }
      });
    });
    return result && result.length ? result : cloneWizardarray;
  }
  getAppointmentFilterDataCheckInCheckOutValidated(action: ActionType): AppointmentActionFilter {
    let appointmentFilter: AppointmentActionFilter = null;
    if (!this.wizardService.groupAppointments || this.wizardService.groupAppointments.length == 0) {
      return appointmentFilter;
    }
    let status = [];
    if (action == ActionType.checkin) {
      status = [AppointmentStatus.Scheduled];
    }
    else if (action == ActionType.checkout) {
      status = [AppointmentStatus.CheckIn];
    }
    else if (action == ActionType.booklunch) {
      status = [AppointmentStatus.Scheduled, AppointmentStatus.CheckIn];
    }
    else {
      return appointmentFilter;
    }
    let appointmentIds = this.wizardService.groupAppointments.filter(r=> this.utilities.ValidateDatesAreEqual(this.utilities.GetDateWithoutTime(r.appointment.appointmentDetail.startTime),(this.utilities.GetDateWithoutTime(this.PropertyInfo.CurrentDate)))).map(r => r.appointment.appointmentDetail.id);
    let groupid = (this.wizardService.groupDetail && this.wizardService.groupDetail.id) ? this.wizardService.groupDetail.id : 0;
    let sorttedDate: Date[] = this.wizardService.groupAppointments.filter(r=> this.utilities.ValidateDatesAreEqual(this.utilities.GetDateWithoutTime(r.appointment.appointmentDetail.startTime),(this.utilities.GetDateWithoutTime(this.PropertyInfo.CurrentDate)))).map(r => r.appointment.appointmentDetail.startTime).sort(function (a, b) {
      return Number(new Date(a)) - Number(new Date(b));
    });
    appointmentFilter = {
      appointmentId: appointmentIds,
      groupId: groupid,
      appointmentStatus: status,
      fromDate: this.localization.convertDateObjToAPIdate(sorttedDate[0]),
      toDate: this.localization.convertDateObjToAPIdate(sorttedDate[sorttedDate.length - 1])
    };
    return appointmentFilter;
  }


  getAppointmentFilterData(action: ActionType): AppointmentActionFilter {
    let appointmentFilter: AppointmentActionFilter = null;
    if (!this.wizardService.groupAppointments || this.wizardService.groupAppointments.length == 0) {
      return appointmentFilter;
    }
    let status = [];
    if (action == ActionType.checkin) {
      status = [AppointmentStatus.Scheduled];
    }
    else if (action == ActionType.checkout) {
      status = [AppointmentStatus.CheckIn];
    }
    else if (action == ActionType.booklunch) {
      status = [AppointmentStatus.Scheduled, AppointmentStatus.CheckIn];
    }
    else {
      return appointmentFilter;
    }
    let appointmentIds = this.wizardService.groupAppointments.map(r => r.appointment.appointmentDetail.id);
    let groupid = (this.wizardService.groupDetail && this.wizardService.groupDetail.id) ? this.wizardService.groupDetail.id : 0;
    let sorttedDate: Date[] = this.wizardService.groupAppointments.map(r => r.appointment.appointmentDetail.startTime).sort(function (a, b) {
      return Number(new Date(a)) - Number(new Date(b));
    });
    appointmentFilter = {
      appointmentId: appointmentIds,
      groupId: groupid,
      appointmentStatus: status,
      fromDate: this.localization.convertDateObjToAPIdate(sorttedDate[0]),
      toDate: this.localization.convertDateObjToAPIdate(sorttedDate[sorttedDate.length - 1])
    };
    return appointmentFilter;
  }

  async sliderInfo(input: any, updateMgmtData: boolean = true, actionId: any = '') {
    let startDate: Date, endDate: Date;
    if (this.wizardService.groupAppointments && this.wizardService.groupAppointments.length > 0) {
      let sorttedDate: Date[] = this.wizardService.groupAppointments.map(r => r.appointment.appointmentDetail.startTime).sort(function (a, b) {
        return Number(new Date(a)) - Number(new Date(b));
      });
      startDate = sorttedDate[0];
      endDate = sorttedDate[sorttedDate.length - 1];
    }
    this.SlideService.fieldData = {
      appointmentFrom: AppointmentFrom.SpaWizard,
      appointmentId: this.wizardService.groupAppointments.length > 0 ?
        this.wizardService.groupAppointments[0].appointment.appointmentDetail.id : 0,
      appointmentIds: this.wizardService.groupAppointments.map(x => x.appointment.appointmentDetail.id),
      EndDTTM: this.wizardService.groupAppointments.length > 0 ?
        this.wizardService.groupAppointments[0].appointment.appointmentDetail.endTime : '',
      ServiceGroupIds: this.wizardService.selectedClients.filter(x => x.id !== 0).map(x => x.id),
      appointmentDate: this.wizardService.groupAppointments.length > 0 ?
        this.wizardService.groupAppointments[0].appointment.appointmentDetail.startTime : '',
      startDTTM: this.wizardService.groupAppointments.length > 0 ?
        this.wizardService.groupAppointments[0].appointment.appointmentDetail.startTime : '',
      clientMultiPackId: 0,
      clientName: 'Multiple',
      clientIds: this.wizardService.selectedClients.filter(x => x.id !== 0).map(x => x.id),
      groupAppointmentId: this.wizardService.groupDetail ? this.wizardService.groupDetail.id : 0,
      groupStartDate: startDate,
      groupEndDate: endDate,
      clientId: 0,
      serviceName: '',
      therapistName: '',
      LocationName: '',
      status: '',
      managementData: this.SlideService.fieldData.managementData,
      isSpaWizardCheckout: actionId === ActionType.checkout,
      isFromSpaWizard: true,
      linkedAppointmentInfos: this.wizardService.groupAppointments.map(x => { return { 'appointmentId': x.appointment.appointmentDetail.id, 'linkedAppointmentId': x.appointment.appointmentDetail.linkedAppointmentID }; })
    };


    if (this.isGroupHasSingleClient()) {
      this.SlideService.fieldData.clientId = this.wizardService.groupAppointments[0].appointment.appointmentDetail.clientId;
      const serviceData: ServiceDetail = this.wizardService.serviceArray.find(res => res.id === this.wizardService.groupAppointments[0].appointment.appointmentDetail.serviceId);
      const clientData: ClientInfo = this.wizardService.selectedClients.find(res => res.clientDetail.id === this.SlideService.fieldData.clientId);
      const therap: TherapistDetailAPI = this.wizardService.therapistArray.find(r => r.id == this.wizardService.groupAppointments[0].appointment.appointmentTherapists[0].therapistId);
      const location: LocationAPI = this.wizardService.locationArray.find(res => res.id === this.wizardService.groupAppointments[0].appointment.appointmentDetail.locationId);
      let serviceName = serviceData ? serviceData.description : '';
      this.SlideService.fieldData.serviceName = serviceName;
      let therapistName = therap ? `${therap.firstName} ${therap.lastName}` : '';
      this.SlideService.fieldData.therapistName = therapistName;
      let locationName = location ? location.description : '';
      this.SlideService.fieldData.LocationName = locationName;

      let groupAppointmentDetails = this.wizardService.groupAppointments.map(x => {
        const serviceDataInfo: ServiceDetail = this.wizardService.serviceArray.find(res => res.id === x.appointment.appointmentDetail.serviceId);
        const therapInfo: TherapistDetailAPI = this.wizardService.therapistArray.find(r => r.id == x.appointment.appointmentTherapists[0].therapistId);
        const locationInfo: LocationAPI = this.wizardService.locationArray.find(res => res.id === x.appointment.appointmentDetail.locationId);

        return {
          'serviceName': serviceDataInfo ? serviceDataInfo.description : '',
          'therapistName': therapInfo ? `${therapInfo.firstName} ${therapInfo.lastName}` : '',
          'locatioName': locationInfo ? locationInfo.description : '',
          'appointmentId': x.appointment.appointmentDetail.id,
          'appTime': this.utilities.FormatAppointmentDateTime(x.appointment.appointmentDetail.startTime, x.appointment.appointmentDetail.endTime),
          'price': this.localization.localizeCurrency(x.appointment.appointmentDetail.price),
          'status': x.appointment.appointmentDetail.status,
          'linkedAppointmentId': x.appointment.appointmentDetail.linkedAppointmentID
        }

      });

      this.SlideService.fieldData.groupAppointmentDetails = groupAppointmentDetails;
      this.SlideService.fieldData.status = this.wizardService.groupAppointments[0].appointment.appointmentDetail.status;
      this.SlideService.fieldData.price = this.wizardService.groupAppointments[0].appointment.appointmentDetail.price;
      this.SlideService.fieldData.dateField = this.utilities.getDate(this.wizardService.groupAppointments[0].appointment.appointmentDetail.startTime);
      this.SlideService.fieldData.clientName = clientData ? `${clientData.clientDetail.firstName} ${clientData.clientDetail.lastName}` : '';
      this._appService.otherinfo = `${this.captions.by} ${therapistName} ${this.captions.at} ${locationName}`;
      this._appService.serviceInfo = {
        name: serviceName,
        date: this.utilities.FormatAppointmentDateTime(this.SlideService.fieldData.startDTTM, this.SlideService.fieldData.EndDTTM),
        cost: `${this.localization.localizeCurrency(this.SlideService.fieldData.price)}`
      };

      // resetting this to open single appointment action popups
      this.SlideService.fieldData.appointmentFrom = '';
    }

    if (!this.SlideService.fieldData.managementData && updateMgmtData) {
      await this.GetManagementData();
    }
  }


  isGroupHasSingleClient(): boolean {
    let isSingleClient = false;
    if (this.wizardService.groupAppointments && this.wizardService.groupAppointments.length > 0) {
      let distinctClients = Array.from(new Set(this.wizardService.groupAppointments.filter(r => r.appointment.appointmentDetail.clientId > 0)
        .map(r => r.appointment.appointmentDetail.clientId)));
      if (distinctClients.length == 1) {
        isSingleClient = true;
      }
    }
    return isSingleClient;
  }
  resetSliderInfo() {
    this.SlideService.fieldData.appointmentFrom = '';
    this.SlideService.fieldData.isFromSpaWizard = false;
  }

  async GetManagementData(): Promise<any> {
    const apiResponse = await this.InvokeServiceCallAsync('GetManagementData', Host.spaManagement, HttpMethod.Get);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      const response: ManagementData = apiResponse.result;
      //let managementData: any[] = [];
      this._appService.managementData['Service'] = response.service;
      this._appService.managementData['Therapist'] = response.therapist;
      this._appService.managementData['Location'] = response.location;
      this._appService.managementData['AddOn'] = response.addOn;
      this._appService.managementData['AppointmentConfigurations'] = response.appointmentConfigurations[0];
      this._appService.managementData['LinkCode'] = response.linkCode;
      this._appService.managementData['BreakType'] = response.breakType;

      //this.SlideService.fieldData.managementData = managementData;
      this.SlideService.fieldData.managementData = this._appService.managementData; 
      this._appService.appointmentConfiguration = response.appointmentConfigurations;
    }
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async getAllServiceGroups(): Promise<FilterOptions[]> {
    let serviceGroupData = [];
    serviceGroupData = await this.wizardService.getAllServiceGroups();
    return serviceGroupData.map(x => this.ServiceGroupUIMapping(x));
  }

  private ServiceGroupUIMapping(input: ServiceGroupAPI): FilterOptions {
    return {
      id: input.id,
      isSelect: true,
      code: input.code,
      serviceName: 'Service Group'
    };
  }

  async getActiveTherapist(): Promise<TherapistUI[]> {
    const APIModel: TherapistDetailAPI[] = await this.wizardService.getAllTherapists();
    return APIModel.map(x => this.therapistUIMapping(x));
  }
  private therapistUIMapping(input: TherapistDetailAPI): TherapistUI {
    return {
      id: input.id,
      firstName: input.firstName,
      lastName: input.lastName
    };
  }
  async getAllLocations(): Promise<LocationUI[]> {
    const APIModel: LocationAPI[] = await this.wizardService.getAllLocations();
    return APIModel.map(x => this.LocationUIMapping(x));
  }
  private LocationUIMapping(input: LocationAPI): LocationUI {
    return {
      id: input.id,
      locationname: input.description,
      code: input.description
    };
  }

  public async GetAllAppointmentFilters(selectedDate) {
    try {
      return await this.wizardService.GetAllAppointmentFilters(selectedDate);
    } catch (e) {
      this.http.exceptionHandle(e);
    }

  }



  /**
   * Create Temp Client For Spa Wizard
   * @param name Client Name
   */
  CreateTempClient(firstName: string, lastName: number): ClientInfo {
    return {
      id: 0,
      clientDetail: {
        firstName: firstName,
        lastName: lastName.toString(),
        tempId: this.utilities.generateGUID(),
        bypassClientScheduling: false,
        status: undefined
      }
    } as ClientInfo;
  }

  public async createBulkClient(mappedVirtualClients): Promise<ClientInfo[]> {
    try {
      let clientInfoArray: Promise<ClientInfo[]>[] = [];
      let i = 0;
      while (i * this.wizardService.BatchScheduleTempAppointments < mappedVirtualClients.length) {
        let range = i * this.wizardService.BatchScheduleTempAppointments;
        const cliList = [];
        for (let j = range; j < range + this.wizardService.BatchScheduleTempAppointments && j < mappedVirtualClients.length; j++) {
          cliList.push(mappedVirtualClients[j]);
        }
        clientInfoArray.push(this.wizardService.createBulkClient(cliList));
        i++;
      }
      let response = await Promise.all(clientInfoArray);

      let result = [];
      response.forEach(element => {
        if (element) {
          element.forEach(client => {
            if (!result.find(x => x.id == client.id)) {
              result.push(client);
            }
          });
        }
      });
      return result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }

  }

  /**
   * Get Virtually Mapped Client TempHold Appointment
   */
  getTempHoldVirtualClient(): GroupAppointment[] {
    let tempClientAppointments = [];
    if (this.wizardService.groupAppointments && this.wizardService.groupAppointments.length > 0) {
      tempClientAppointments = this.wizardService.groupAppointments.filter(r => r.appointment.appointmentDetail.clientId == 0 && r.tempClientId && r.tempClientId.length > 0);
    }
    return tempClientAppointments;
  }

  /**
   * Get Virtual Client By Temp Client Id
   */
  getVirtualClientByTempClientId(tempClientIds: string[]): ClientInfo[] {
    let tempClientInfo = [];
    if (this.wizardService.selectedClients && this.wizardService.selectedClients.length > 0) {
      tempClientInfo = this.wizardService.selectedClients
        .filter(x => tempClientIds.includes(x.clientDetail.tempId))
        // make sure contact details added
        .map(x => {
          return {
            ...x,
            phoneNumbers: x.phoneNumbers || [],
            emails: x.emails || [],
          };
        });
    }
    return tempClientInfo;
  }

  TemporaryAppointments(): GroupAppointment[] {
    return [
      {
        appointment: {
          appointmentDetail: {
            id: 1,
            startTime: '2018-06-27T09:00',
            endTime: '2018-06-27T10:00',
            clientId: 7,
            serviceGroupId: 1,
            serviceId: 1,
            locationId: 1,
            comments: '',
            status: 'TEMP',
            cancelId: '',
            duration: '60 min',
            setUpTime: 0,
            breakDownTime: 0,
            price: 125,
            doNotMove: false,
            requestStaff: false,
            isBookingLocked: false,
            isVip: false,
            genderPreference: '',
            checkOutComments: '',
            noShowComments:'',
            noShow: false,
            packageId: 1,
            appointmentType: '',
            cancelReason: 0,
            cancelComments: '',
            cancelFee: '',
            customField1: 0,
            customField2: 0,
            customField3: 0,
            customField4: '',
            customField5: '',
            tempHoldId: '',
            tempHoldLinkId: 0,
            isTherapistOverbook: false,
            isLocationOverbook: false,
            isClientOverbook: false,
            linkCodeId: 0,
            guestId: '',
            clientComments: '',
            activityId: '',
            clientMultiPackId: '',
            transactionId: 0,
            transactionDetailId: 0,
            ServiceCharge: 0,
            Gratuity: 0,
            Tax: 0,
            TotalAmount: 0,
            multiGroupId: '',
            userSessionId: 0,
            packageGroupId: '',
            priceTypeId: 0,
            serviceChargePercent: 0,
            gratuityPercent: 0,
            yieldPrice: 0,
            intakeForm: false,
            originalStartTime: '',
            originalEndTime: '',
            discountType: ''
          },
          appointmentTherapists: [
            {
              therapistId: 1,
              appointmentId: 1
            }],
            pMSActivityId:'',
          appointmentAddOn: []
        },
        tempClientId: ''
      },
      {
        appointment: {
          appointmentDetail: {
            id: 1,
            startTime: '2018-06-27T11:00',
            endTime: '2018-06-27T12:00',
            clientId: 8,
            serviceGroupId: 1,
            serviceId: 1,
            locationId: 1,
            comments: '',
            status: 'RESV',
            cancelId: '',
            duration: '60 min',
            setUpTime: 0,
            breakDownTime: 0,
            price: 125,
            doNotMove: false,
            requestStaff: false,
            isBookingLocked: false,
            isVip: false,
            genderPreference: '',
            checkOutComments: '',
            noShowComments:'',
            noShow: false,
            packageId: 1,
            appointmentType: '',
            cancelReason: 0,
            cancelComments: '',
            cancelFee: '',
            customField1: 0,
            customField2: 0,
            customField3: 0,
            customField4: '',
            customField5: '',
            tempHoldId: '',
            tempHoldLinkId: 0,
            isTherapistOverbook: false,
            isLocationOverbook: false,
            isClientOverbook: false,
            linkCodeId: 0,
            guestId: '',
            clientComments: '',
            activityId: '',
            clientMultiPackId: '',
            transactionId: 0,
            transactionDetailId: 0,
            ServiceCharge: 0,
            Gratuity: 0,
            Tax: 0,
            TotalAmount: 0,
            multiGroupId: '',
            userSessionId: 0,
            packageGroupId: '',
            priceTypeId: 0,
            serviceChargePercent: 0,
            gratuityPercent: 0,
            yieldPrice: 0,
            intakeForm: false,
            originalStartTime: '',
            originalEndTime: '',
            discountType: ''
          },
          appointmentTherapists: [
            {
              therapistId: 1,
              appointmentId: 1
            }],
            pMSActivityId:'',
          appointmentAddOn: [],
        },
        tempClientId: ''
      }
    ];
  }

  getFailedClientNameArray(failedApp: WizardTempAppointmentResponse[], clients): string[] {
    const failedClientNames = [];
    for (const failure of failedApp) {
      if (failure.appointment.appointmentDetail.clientId) {
        const client = clients.find(r => r.clientDetail.id == failure.appointment.appointmentDetail.clientId);
        if (client) {
          failedClientNames.push(`${client.clientDetail.firstName} ${client.clientDetail.lastName}`);
        }
      } else if (failure.tempClientId && failure.tempClientId.length > 0) {
        const tempClient = clients.find(r => r.clientDetail.tempId == failure.tempClientId);
        if (tempClient) {
          failedClientNames.push(`${tempClient.clientDetail.firstName} ${tempClient.clientDetail.lastName}`);
        }
      }
    }
    return failedClientNames;
  }

  formAppointmentObject(clients: ClientInfo[], service: ServiceViewModel, selectedSlot: WizardTimeSlots,
    isMultiGroupApp: boolean, packageId: number = 0): WizardTempAppointmentRequest {
    const serviceData = this.wizardService.GetAppointmentFilterData();
    const guid = isMultiGroupApp && clients.length > 1 ? this.utilities.generateGUID() : null;
    const requestModel: WizardTempAppointmentRequest = {
      isOffSiteLocation: serviceData.serviceOptionsForm.locationId == 0 || false,
      isQuickRoomForTwo: serviceData.newApptForm.quickRoom,
      appointments: [],
      isPackageBooking: false,
      packageId: 0,
      selectedLocationId: serviceData.serviceOptionsForm.locationId || 0,
      selectedTherapistId: serviceData.serviceOptionsForm.therapistId || 0
    };

    let appointmentBookingDetail: appointmentDetail;
    for (const client of clients) {
      let appPrice = service.price;
      let price = 0, priceTypeId = 0;
      if (!packageId || packageId == 0) {
        if (client.clientDetail.priceTypeId > 0 && service.servicePriceType) {
          const priceType = service.servicePriceType.find(c => c.priceTypeId === client.clientDetail.priceTypeId);
          if (priceType) {
            price = priceType.price;
            priceTypeId = priceType.priceTypeId;
          }
        }
        let serviceBasePrice = service.price;
        let serviceAggregate = this.wizardService.serviceDetailArray.find(r => r.serviceDetail.id == service.id);
        if (serviceAggregate) {
          serviceBasePrice = this.utilities.GetServiceBasePrice(service, serviceAggregate.serviceSeasonalDates, selectedSlot.dateTime);
        }
        appPrice = priceTypeId != 0 ? price : serviceBasePrice;
      }
      appointmentBookingDetail = {
        id: 0,
        startTime: this.localization.convertDateTimeToAPIDateTime(selectedSlot.dateTime),
        endTime: this.localization.convertDateTimeToAPIDateTime(this.utilities.AddMinutes(selectedSlot.dateTime, service.duration)),
        clientId: client.clientDetail.id,
        serviceGroupId: service.serviceGroupId,
        serviceId: service.id,
        locationId: 0,
        comments: serviceData.otherDetails.AppointmentComments,
        status: 'TEMP',
        cancelId: 0,
        duration: service.duration,
        setUpTime: serviceData.newApptForm.ignoreSetupBreakdownTime ? 0 : service.setupTime,
        breakDownTime: serviceData.newApptForm.ignoreSetupBreakdownTime ? 0 : service.breakDownTime,
        price: appPrice,
        doNotMove: serviceData.otherDetails.DoNotMove || false,
        requestStaff: serviceData.otherDetails.ReqStaff || false,
        isBookingLocked: serviceData.otherDetails.IsBookingLocked || false,
        isVip: serviceData.otherDetails.vip || false,
        genderPreference: serviceData.otherDetails.genderPreference || 0,
        checkOutComments: serviceData.otherDetails.CheckOutComments,
        noShowComments:serviceData.otherDetails.noShowComments,
        noShow: false,
        packageId: packageId,
        appointmentType: '',
        cancelReason: 0,
        cancelComments: '',
        cancelFee: 0,
        customField1: serviceData.otherDetails.customField1 || 0,
        customField2: serviceData.otherDetails.customField2 || 0,
        customField3: serviceData.otherDetails.customField3 || 0,
        customField4: serviceData.otherDetails.customField4,
        customField5: serviceData.otherDetails.customField5,
        tempHoldId: '',
        tempHoldLinkId: 0,
        isTherapistOverbook: false,
        isLocationOverbook: false,
        isClientOverbook: false,
        linkCodeId: serviceData.otherDetails.LinkCode || 0,
        guestId: client.clientDetail.GuestId || client.clientDetail.guestId,
        clientComments: '',
        activityId: '',
        clientMultiPackId: 0,
        transactionId: 0,
        transactionDetailId: 0,
        ServiceCharge: 0,
        Gratuity: 0,
        Tax: 0,
        TotalAmount: appPrice,
        multiGroupId: guid,
        userSessionId: 0,
        priceTypeId: priceTypeId,
        serviceChargePercent: service.serviceChargePercent,
        gratuityPercent: service.gratuityAmount,
        yieldPrice: 0,
        intakeForm: false,
        discountType:this.apptPopService.discountType?this.apptPopService.discountType:''
      };
      const appointmentObj: appointment = {
        appointmentDetail: appointmentBookingDetail,
        appointmentAddOn: [],
        appointmentTherapists: [],
        pMSActivityId:''
      };
      const tempInfo: TempAppointmentInfo = {
        tempClientId: client.clientDetail.tempId || '',
        appointment: appointmentObj
      };
      requestModel.isPackageBooking = packageId != 0;
      requestModel.packageId = packageId;
      requestModel.appointments.push(tempInfo);

    }
    return requestModel;
  }

  formAutoBookAppointmentObject(clients: ClientInfo[], service: ServiceViewModel): WizardAutoBookTempAppointmentRequest {
    const serviceData = this.wizardService.GetAppointmentFilterData();
    return {
      setGuid: serviceData.clientForm.addSet.guid,
      setName: serviceData.clientForm.addSet.name,
      startDate: this.localization.convertDateTimeToAPIDateTime(this.utilities.getDate(serviceData.newApptForm.startDate)),
      endDate: this.localization.convertDateTimeToAPIDateTime(this.utilities.getDate(serviceData.newApptForm.endDate)),
      startTimeInMinutes: this.convertHoursToMinutes(serviceData.newApptForm.startTime),
      endTimeInMinutes: this.convertHoursToMinutes(serviceData.newApptForm.endTime),
      useSpaHours: serviceData.newApptForm.spaHours,
      ignoreSetupBreakDown: serviceData.newApptForm.ignoreSetupBreakdownTime,
      maxPerDay: serviceData.autoForm.maxPerDay,
      maxPerTimeSlot: serviceData.autoForm.maxPerSlot,
      remaining: serviceData.newApptForm.remainingAppointmentsOn ? this.localization.convertDateTimeToAPIDateTime(this.utilities.getDate(serviceData.newApptForm.remainingAppointmentsOn)) : null,
      overrideOverlap: serviceData.autoForm.overLap,
      overrideMedicalCondition: serviceData.autoForm.medicalCondition,
      negotiatedPrice: serviceData.autoForm.negotiatedPrice,
      negotiatedPriceValue: serviceData.autoForm.negotiatedValue != '' ? this.localization.currencyToSQLFormat(serviceData.autoForm.negotiatedValue) : null,
      serviceSetup: serviceData.autoForm.distributionSetupForm.service,
      staffSetup: serviceData.autoForm.distributionSetupForm.staff,
      locationSetup: serviceData.autoForm.distributionSetupForm.location,
      serviceId: service.id,
      locations: serviceData.serviceOptionsForm.locationIds.filter(x => x != -1),
      therapists: serviceData.serviceOptionsForm.therapistIds.filter(x => x != -1),
      clients: clients.map(r => <SpaClient>{ id: r.clientDetail.id, guestId: r.clientDetail.guestId, tempClientId: r.clientDetail.tempId }),
      comments: serviceData.otherDetails.AppointmentComments,
      doNotMove: serviceData.otherDetails.DoNotMove,
      requestStaff: serviceData.otherDetails.ReqStaff,
      isVip: serviceData.otherDetails.vip,
      genderPreference: serviceData.otherDetails.genderPreference,
      checkOutComments: serviceData.otherDetails.CheckOutComments,
      customField1: serviceData.otherDetails.customField1 || 0,
      customField2: serviceData.otherDetails.customField2 || 0,
      customField3: serviceData.otherDetails.customField3 || 0,
      customField4: serviceData.otherDetails.customField4,
      customField5: serviceData.otherDetails.customField5
    };
  }

  getActionList() {
    return {
      buttonLabel: this.spaWizardCaptions.SAVE,
      actions: [{
        value: spaWizardSaveMenu.Save,
        label: this.spaWizardCaptions.SAVE
      },
      {
        value: spaWizardSaveMenu.SaveNew,
        label: this.spaWizardCaptions.SAVENEW
      },
      {
        value: spaWizardSaveMenu.SaveExit,
        label: this.spaWizardCaptions.SAVEEXIT
      }
      ]
    };
  }

  GetClientName(client) {
    if (client.clientDetail) {
      return (client.clientDetail.firstName || client.clientDetail.FirstName) + ' ' + (client.clientDetail.lastName || client.clientDetail.LastName || '');
    } else {
      return '';
    }
  }

  /**
   * Substitute Create Client Into TempHold Appointment And Selected Clients
   * @param originalClients Create Client Array
   */
  SubstituteOriginalClient(originalClients: ClientInfo[]): void {
    if (!this.wizardService.selectedClients || this.wizardService.selectedClients.length == 0 || !this.wizardService.selectedClients.some(r => r.clientDetail.tempId && r.clientDetail.tempId.length > 0)) {
      return;
    }
    originalClients.forEach(ele => {
      // Find temp id from selected client using firstname and lastname
      const mappedClientIdx = this.wizardService.selectedClients.findIndex(x => x.clientDetail.tempId == ele.clientDetail.tempId);
      if (mappedClientIdx != -1) {
        const foundClient = this.wizardService.selectedClients[mappedClientIdx];
        // Update selected client
        foundClient.id = ele.id;
        foundClient.clientDetail.id = ele.clientDetail.id;
        foundClient.clientDetail.guestId = ele.clientDetail.guestId;
        foundClient.clientDetail.GuestId = ele.clientDetail.GuestId;
        if (this.wizardService.groupAppointments && this.wizardService.groupAppointments.length) {
          // Update Temp Hold Appointment with created original client instead of temp client
          this.wizardService.groupAppointments.forEach(x => {
            if (x.tempClientId == foundClient.clientDetail.tempId) {
              x.appointment.appointmentDetail.clientId = ele.clientDetail.id;
              x.appointment.appointmentDetail.guestId = ele.clientDetail.guestId || ele.clientDetail.GuestId;
              x.tempClientId = '';
            }
          });
        }
        foundClient.clientDetail.tempId = '';
      }
    });
    this.wizardService.clientUpdateNotifier$.next('GuestId Update');
  }

  getBookingType(clientForm: ClientForm): WizardAppointmentType {
    let bookingType: WizardAppointmentType = WizardAppointmentType.None;
    if (clientForm.multipleBooking) {
      bookingType = WizardAppointmentType.MutipleBooking;
    }
    else if (clientForm.hotelBookingList) {
      bookingType = WizardAppointmentType.PMSGroupBooking;
    }
    return bookingType;
  }

  async createBulkAppointment(action: string): Promise<boolean> {
    let isSuccess = false;
    let groupInfo: GroupDetail = this.wizardService.groupDetail;
    const groupContact = this.wizardService.selectedClients.find(x => x.isBookingContact);
    if (!groupInfo) {
      const formData = this.wizardService.GetAppointmentFilterData();
      groupInfo = {
        id: 0,
        groupId: '',
        groupName: formData.clientForm.bookingName,
        groupType: this.getBookingType(formData.clientForm),
        isAutobook: formData.clientForm.autoBook,
        startTime: formData.newApptForm.startTime,
        endTime: formData.newApptForm.endTime,
        hotelBookingId: formData.clientForm.hotelBookingList ? formData.clientForm.bookingId.bookingId : null,
        linkAppointments: formData.clientForm.linkAppointments,

      };
      this.wizardService.groupDetail = groupInfo;
    }
    else {
      groupInfo.linkAppointments = this.wizardService.spaWizardForm.controls.clientForm.value.linkAppointments;
    }

    // Set group contact id
    this.wizardService.groupDetail.clientId = groupContact ? groupContact.id : 0

    // Set group contact id
    this.wizardService.groupDetail.clientId = groupContact ? groupContact.id : 0

    const appointmentsToConfirm = this.wizardService.groupAppointments || [];
    const request: WizardGroupAppointmentRequest = {
      groupDetail: groupInfo,
      isPackageAppointment: this.wizardService.spaWizardForm.controls.serviceOptionsForm.value.serviceType != 'services',
      appointments: appointmentsToConfirm.map(r => r.appointment),
      SendEmailNotification:this.wizardService.sendAutomaticNotification
    };
    request.appointments.forEach(a=>{
      this.wizardService.clientsArr.forEach(c=>c.forEach(d=>{
        if(a.appointmentDetail.clientId==d.id)
        {
          a.pMSActivityId=d.clientDetail.pmsActivityId;
        }
      })
      );
    });   
   
    let appointmentClientDetail = [];
    let genderOverrideClientDetails: GenderOverrideMessage[] = [];
    
    request.appointments?.forEach((a) => {
      let therapistDetail = [];
      let clientObject = null;
      this.wizardService.clientsArr.forEach(
        (ca) => {
          let client = ca.find(c => c.id === a.appointmentDetail.clientId) 
          if(client){
            clientObject = client?.clientDetail;
          }
        }
      );
      a.appointmentTherapists.forEach((t) => {
        let therapistObject = this.wizardService.therapistArray.find(
          (ta) => ta.id === t.therapistId
        );
        therapistDetail.push({
          therapistId: t.therapistId,
          gender: therapistObject ? therapistObject.gender : "",
        });
      });
    
      appointmentClientDetail.push({
        serviceId: a.appointmentDetail.serviceId,
        clientId: a.appointmentDetail.clientId,
        clientName: clientObject
          ? clientObject.firstName?.concat(" ", clientObject.lastName)
          : "",
        genderPreference: clientObject
          ? clientObject?.genderPreference
          : "",
        therapistDetails: therapistDetail,
      });
    });
    
    appointmentClientDetail.forEach((data) => {
      if (
        data.genderPreference !== "" &&
        data.genderPreference != GlobalConst.Gender.NoPreference &&
        data.genderPreference
      ) {
        data.therapistDetails.forEach((th) => {
          if (th.gender !== data.genderPreference) {
            genderOverrideClientDetails.push({
              clientName: data.clientName,
              clientPreferredGender: data.genderPreference,
              therapistGender: th.gender,
              isOverriden: false,
              appointmentId: 0,
              serviceId: data.serviceId,
              therapistId: th.therapistId,
              clientId: data.clientId,
            });
          }
        });
      }
    });

    if (genderOverrideClientDetails.length > 0) {
      let customMessageTemplate: string =
        this.localization.captions.bookAppointment.GenderOverrideMessagecustom +
        "<br>";
      let customMessage: string = "";
      genderOverrideClientDetails.forEach((x) => {
        let clientName: string = x.clientName;
        let clientPreferredGender: string = x.clientPreferredGender;
        let therapistGender: string = x.therapistGender;
        customMessage += customMessageTemplate.interpolate({
          clientName,
          clientPreferredGender,
          therapistGender,
        });
      });
    
      let tempGenderOverrideMessage: string =
        customMessage +
        this.localization.captions.bookAppointment.GenderOverrideMessagestatic;
    
      this.utilities.ShowErrorMessage(
        this.localization.captions.alertPopup.warning,
        tempGenderOverrideMessage,
        ButtonType.YesNo,
        async (result) => {
          if (result.toLowerCase() === GlobalConst.ButtonOptions.Yes) {
            this.wizardService.genderOverrideMessage = genderOverrideClientDetails;
            return this.proceedScheduleGroupAppointment(request, action);
          } else {
            this.wizardService.genderOverrideMessage = [];
            return false;
          }
        }
      );
    } else {
      return this.proceedScheduleGroupAppointment(request, action);
    }
    
  }

  async proceedScheduleGroupAppointment(request,action){
    let isSuccess = false;
    let response = await this.wizardService.SheduleGroupAppointment(request);

    if (response) {

      if (request.appointments.length > response.appointments.length) {
        this.wizardService.FailedAppointments = request.appointments.filter(x => !response.appointments.find(y => y.appointmentDetail.id == x.appointmentDetail.id));
      }

      const groupId = response.groupDetail.id;
      // Update GroupId For Normal Appointments
      let appointmentId: number[] = response.appointments.map(t => t.appointmentDetail.id);
      let normalAppointmentIds: number[] = this.wizardService.groupAppointments.filter(r => !appointmentId.includes(r.appointment.appointmentDetail.id))
        .map(app => app.appointment.appointmentDetail.id);


      // Update Temp Appointment with scheduled ones
      if (response.appointments && response.appointments.length > 0) {
        for (const temp of this.wizardService.groupAppointments) {
          const scheduledApp = response.appointments.find(r => r.appointmentDetail.id == temp.appointment.appointmentDetail.id);
          if (scheduledApp) {
            temp.appointment = scheduledApp;
          }
        }
        if (this.wizardService.genderOverrideMessage.length > 0) {
          let genderOverriddenAppointmentIds: number[] = [];
          const genderOverrides = this.wizardService.genderOverrideMessage;
          genderOverrides.forEach(x => {
            const app = response.appointments.filter(y => y.appointmentDetail.serviceId === x.serviceId && y.appointmentDetail.clientId === x.clientId);
            const appTherapists = app[0].appointmentTherapists.map(g => g.therapistId);
            if (appTherapists.includes(x.therapistId)) {
              genderOverriddenAppointmentIds.push(app[0].appointmentDetail.id);
            }
          });
          if (genderOverriddenAppointmentIds.length > 0) {
            let genderOverrideTransLog: GenderOverrideTransLog = { appointmentIds: genderOverriddenAppointmentIds };
            this.ApptUtilities.insertTransLogforGenderPreferenceOverride(genderOverrideTransLog);
            this.wizardService.genderOverrideMessage = []
          }
        }
        isSuccess = true;
      }
      // Update create groupid
      if (response.groupDetail) {
        this.wizardService.groupDetail = response.groupDetail;
      }
      this.wizardService.appointmentUpdateNotifier$.next(action);
    }
    return isSuccess;
  }

  async AssignOtherDetailsToAppointment() {
    const serviceData = this.wizardService.GetAppointmentFilterData();
    const customFieldValues: any[] = await this.wizardService.GetCustomFields();
    const appointmentsToConfirm = this.wizardService.groupAppointments;

    if (this.wizardService.isOtherDetailsUpdated) {
      this.wizardService.isOtherDetailsUpdated = false;
      appointmentsToConfirm.forEach(x => {
        x.appointment.appointmentDetail.customField1 = serviceData.otherDetails.customField1;
        x.appointment.appointmentDetail.customField2 = serviceData.otherDetails.customField2;
        x.appointment.appointmentDetail.customField3 = serviceData.otherDetails.customField3;
        x.appointment.appointmentDetail.customField4 = serviceData.otherDetails.customField4;
        x.appointment.appointmentDetail.customField5 = serviceData.otherDetails.customField5;
        x.appointment.appointmentDetail.doNotMove = serviceData.otherDetails.DoNotMove;
        x.appointment.appointmentDetail.requestStaff = serviceData.otherDetails.ReqStaff;
        x.appointment.appointmentDetail.isVip = serviceData.otherDetails.vip;
        x.appointment.appointmentDetail.genderPreference = serviceData.otherDetails.genderPreference;
        x.appointment.appointmentDetail.checkOutComments = serviceData.otherDetails.CheckOutComments;
        x.appointment.appointmentDetail.linkCodeId = serviceData.otherDetails.LinkCode;
        x.appointment.appointmentDetail.comments = serviceData.otherDetails.AppointmentComments;
      });
    }

    let copyCustomField = { customField1: 0, customField2: 0, customField3: 0, customField4: "", customField5: "" };
    if (customFieldValues && customFieldValues.length > 0) {
      let isCustomFieldsAvailable = false;

      appointmentsToConfirm.forEach(x => {
        let appointmentCustomFields = true;
        customFieldValues.forEach(y => {
          const customId = Number.parseInt(y.columnName.charAt(y.columnName.length - 1));
          if (y.requiredOnAppointment && ((customId == 1 && !(x.appointment.appointmentDetail.customField1 || serviceData.otherDetails.customField1))
            || (customId == 2 && !(x.appointment.appointmentDetail.customField2 || serviceData.otherDetails.customField2))
            || (customId == 3 && !(x.appointment.appointmentDetail.customField3 || serviceData.otherDetails.customField3))
            || (customId == 4 && !(x.appointment.appointmentDetail.customField4 || serviceData.otherDetails.customField4))
            || (customId == 5 && !(x.appointment.appointmentDetail.customField5 || serviceData.otherDetails.customField5)))) {
            appointmentCustomFields = false;
          }
        });

        if (!appointmentCustomFields && this.wizardService.groupAppointments && this.wizardService.groupAppointments.length > 0) {
          const existingAppointment = this.wizardService.groupAppointments.find(c => c.appointment.appointmentDetail.customField1 && c.appointment.appointmentDetail.customField2 && c.appointment.appointmentDetail.customField3 && c.appointment.appointmentDetail.customField4 && c.appointment.appointmentDetail.customField5);
          if (existingAppointment) {
            copyCustomField = {
              customField1: existingAppointment.appointment.appointmentDetail.customField1,
              customField2: existingAppointment.appointment.appointmentDetail.customField2,
              customField3: existingAppointment.appointment.appointmentDetail.customField3,
              customField4: existingAppointment.appointment.appointmentDetail.customField4,
              customField5: existingAppointment.appointment.appointmentDetail.customField5
            };
            isCustomFieldsAvailable = true;
          }
        }

        else if (appointmentCustomFields) {
          copyCustomField = {
            customField1: x.appointment.appointmentDetail.customField1 || serviceData.otherDetails.customField1,
            customField2: x.appointment.appointmentDetail.customField2 || serviceData.otherDetails.customField2,
            customField3: x.appointment.appointmentDetail.customField3 || serviceData.otherDetails.customField3,
            customField4: x.appointment.appointmentDetail.customField4 || serviceData.otherDetails.customField4,
            customField5: x.appointment.appointmentDetail.customField5 || serviceData.otherDetails.customField5
          };
          isCustomFieldsAvailable = true;
        }
      });

      if (appointmentsToConfirm && appointmentsToConfirm.length === 0) {
        isCustomFieldsAvailable = true;
      }


      if (!isCustomFieldsAvailable) {
        this.utilities.ShowErrorMessage(this.alertCaptions.error, `${this.spaWizardCaptions.MissingCustomFields}`, ButtonType.Ok);
        return false;
      }
    }

    appointmentsToConfirm.forEach(x => {
      x.appointment.appointmentDetail.customField1 = x.appointment.appointmentDetail.customField1 || copyCustomField.customField1 || 0;
      x.appointment.appointmentDetail.customField2 = x.appointment.appointmentDetail.customField2 || copyCustomField.customField2 || 0;
      x.appointment.appointmentDetail.customField3 = x.appointment.appointmentDetail.customField3 || copyCustomField.customField3 || 0;
      x.appointment.appointmentDetail.customField4 = x.appointment.appointmentDetail.customField4 || copyCustomField.customField4;
      x.appointment.appointmentDetail.customField5 = x.appointment.appointmentDetail.customField5 || copyCustomField.customField5;
      x.appointment.appointmentDetail.doNotMove = x.appointment.appointmentDetail.doNotMove == null ? serviceData.otherDetails.DoNotMove : x.appointment.appointmentDetail.doNotMove;
      x.appointment.appointmentDetail.requestStaff = x.appointment.appointmentDetail.requestStaff == null ? serviceData.otherDetails.ReqStaff : x.appointment.appointmentDetail.requestStaff;
      x.appointment.appointmentDetail.isVip = x.appointment.appointmentDetail.isVip == null ? serviceData.otherDetails.vip : x.appointment.appointmentDetail.isVip;
      x.appointment.appointmentDetail.genderPreference = (x.appointment.appointmentDetail.genderPreference == null ? serviceData.otherDetails.genderPreference : x.appointment.appointmentDetail.genderPreference) || 0;

      x.appointment.appointmentDetail.doNotMove = x.appointment.appointmentDetail.doNotMove || false;
      x.appointment.appointmentDetail.requestStaff = x.appointment.appointmentDetail.requestStaff || false;
      x.appointment.appointmentDetail.isVip = x.appointment.appointmentDetail.isVip || false;

      x.appointment.appointmentDetail.checkOutComments = x.appointment.appointmentDetail.checkOutComments || serviceData.otherDetails.CheckOutComments;
      x.appointment.appointmentDetail.linkCodeId = x.appointment.appointmentDetail.linkCodeId || serviceData.otherDetails.LinkCode || 0;
      x.appointment.appointmentDetail.comments = x.appointment.appointmentDetail.comments || serviceData.otherDetails.AppointmentComments;
    });
    return true;
  }

  // validation

  public validateMaximumTherapist(maximumTherapist: number, clientTherapist: SpaWizardClient[], showError: boolean = false): boolean {
    let isValid = false;
    const therapistArray: number[][] = clientTherapist.map(t => t.therapists);
    let totalSelectedTherapist: number[] = [].concat.apply([], therapistArray);
    // total count of selected therapist for service
    totalSelectedTherapist = Array.from(new Set(totalSelectedTherapist)); // remove duplicate therapists
    isValid = totalSelectedTherapist.length <= maximumTherapist;
    if (!isValid && showError) {
      this.utilities.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(100010), ButtonType.Ok);
    }
    return isValid;
  }

  // validation package
  public validatePackage(clients: SpaWizardClient[]): boolean {
    let isValid = true;
    let errormessages = [];

    if (this.utilities.GetDateWithoutTime(this.wizardService.spaWizardForm.value.newApptForm.appointmentDate) <
      this.utilities.GetDateWithoutTime(this.wizardService.selectedPackageGroupList.packageDetail.startDate)
      || this.utilities.GetDateWithoutTime(this.wizardService.spaWizardForm.value.newApptForm.appointmentDate) >
      this.utilities.GetDateWithoutTime(this.wizardService.selectedPackageGroupList.packageDetail.endDate)) {
      errormessages.push(this.localization.getError(100303));
    }
    else {
      const dayIndex = this.wizardService.spaWizardForm.value.newApptForm.appointmentDate.getDay();
      const availableDays = this.wizardService.selectedPackageGroupList.availableDays;
      const availabledaysArray = [availableDays.isAvailableOnSunday, availableDays.isAvailableOnMonday, availableDays.isAvailableOnTuesday, availableDays.isAvailableOnWednesday,
      availableDays.isAvailableOnThursday, availableDays.isAvailableOnFriday, availableDays.isAvailableOnSaturday];
      if (!availabledaysArray[dayIndex]) {
        errormessages.push(this.localization.getError(100304));
      }
    }

    if (errormessages && errormessages.length) {
      isValid = false;
      errormessages.forEach(x => {
        this.utilities.ShowErrorMessage(this.localization.captions.common.Error, x, ButtonType.Ok);
      });
    }
    return isValid;
  }

  public validateMinimumTherapist(minimumTherapist: number, clientTherapist: SpaWizardClient[], showError: boolean = false): boolean {
    let isValid = false;
    const therapistArray: number[][] = clientTherapist.map(t => t.therapists);
    let totalSelectedTherapist: number[] = [].concat.apply([], therapistArray);
    // total count of selected therapist for service
    totalSelectedTherapist = Array.from(new Set(totalSelectedTherapist)); // remove duplicate therapists
    isValid = totalSelectedTherapist.length >= minimumTherapist;
    if (!isValid && showError) {
      this.utilities.ShowErrorMessage(this.localization.captions.common.Error, `${this.localization.getError(100011)} ${minimumTherapist}`, ButtonType.Ok);
    }
    return isValid;
  }

  public validateAllTherapistAssigned(clientTherapist: SpaWizardClient[], showError: boolean = false): boolean {
    const isValid = !clientTherapist.some(r => r.therapists.length == 0);
    if (!isValid && showError) {
      this.utilities.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(100014), ButtonType.Ok);
    }
    return isValid;
  }

  public validateMultiClientRule(selectedTherapist: number[]): boolean {
    let isValid = true;
    if (selectedTherapist.length > 1) {// Multi client can be assigned with maximum of 1 therapist
      isValid = false;
    }
    return isValid;
  }

  public validateSingleClientRule(): boolean {
    return true;
  }

  async DeleteTempHoldAppointments(groupAppointments: GroupAppointment[]) {
    try {
      const tempIds = [];
      groupAppointments.filter(res => res.appointment.appointmentDetail.status === AppointmentStatus.Temp).forEach(res => tempIds.push({ appointmentId: res.appointment.appointmentDetail.id }));
      return await this.deleteTempHold(tempIds);
    }
    catch (e) {
      this.http.exceptionHandle(e);
    }

  }

  async deleteTempHold(tempHoldId: any) {
    if (tempHoldId.length > 0) {
      const tempHoldIds: number[] = tempHoldId.map(x => x.appointmentId);
      const response = await this.http.CallApiAsync<any>(
        {
          callDesc: 'DeleteTempHold',
          method: HttpMethod.Delete,
          host: Host.schedule,
          body: tempHoldIds
        }
      );
      return response.result;
    }
  }

  async updateTempLife(appointmentIds: any) {
    if (appointmentIds.length > 0) {
      const response = await this.http.CallApiAsync<any>(
        {
          callDesc: 'TempLife',
          method: HttpMethod.Put,
          host: Host.schedule,
          body: appointmentIds
        }
      );
      return response.result;
    }
  }

  convertHoursToMinutes(hours: string): number {
    const meridians = ["am", "AM", "pm", "PM"];
    if (meridians.filter(x => hours.includes(x)).length > 0) {
      let index = -1;
      let i = 0;
      meridians.forEach(c => {
        if (hours.indexOf(c) != -1) {
          index = i;
        }
        i++;
      });

      let hourSplit = hours.substring(0, hours.indexOf(" ")).split(':');
      let hour = Number(hourSplit[0]);
      let minute = Number(hourSplit[1]);
      if (index >= 2) {
        hour = hour + 12;
      }
      return hour * 60 + minute;
    } else {
      let hourSplit = hours.split(':');
      return Number(hourSplit[0]) * 60 + Number(hourSplit[1]);
    }
  }

  async IsGuaranteePaymentRequired(): Promise<boolean> {
    return this._appService.IsGuaranteePaymentRequired();
  }


  validateLocationMaxGuests(service: ServiceViewModel, clients: ClientInfo[]): any {

    const filterData = this.wizardService.GetAppointmentFilterData();
    if (!this.wizardService.autoBook && filterData.serviceOptionsForm.serviceType == "services" && service.maximumGuest > 1) {
      const location = filterData.serviceOptionsForm.locationId;
      if (location > 0) {
        const clientCount = clients.length;
        let locationInfo = this.wizardService.locationArray.find(x => location === x.id);
        if (locationInfo.maxGuests > 0 && locationInfo.maxGuests < clientCount) {
          return { result: false, maxguest: locationInfo.maxGuests };
        }
      }
    }
    return { result: true };
  }
}
