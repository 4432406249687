import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  ViewChild, 
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,  UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { PopoverContentComponent } from 'ngx-smart-popover';
import { SpaLocalization } from 'src/app/core/localization/spa-localization'; 
import { ButtonData, single, multiple } from './multi-select-button.modal';

@Component({
  selector: 'app-multi-select-spa-button',
  templateUrl: './multi-select-button.component.html',
  styleUrls: ['./multi-select-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class MultiSelectButtonComponent implements OnInit, OnChanges {

  more:string;
  all:string;
  
  buttonData: ButtonData[];
  changedData: boolean;
  multiSelectFrmGrp: UntypedFormGroup;
  noOfButtons = 100;
  morepopover = true;
 
  @Input() data: ButtonData[];
  @Input() defaultSelected: ButtonData[] | [] | null;
  @Input() isEnableAll: boolean;
  @Input() applyCancel: boolean;
  @Input() title: string;
  @Input() keyName = 'name';
  @Input() allButtonPlaceholder;
  @Input() type: string = multiple;
  @Input() automationId:string ='';
  @Output() buttonSelected: EventEmitter<any> = new EventEmitter();
  @Input() shouldNotSlice:boolean;
  @ViewChild('myPopover') popoverRef: PopoverContentComponent;
  isAllDisabled: boolean;
  actionBtn: ButtonData[];
  allData: { [x: string]: any; id: number; description: string; };
  commonCaptions: any;
  isPopOverOpen = false;
  floatLabel:string;
  constructor(private formBuilder: UntypedFormBuilder,private _localization: SpaLocalization) {
    this.floatLabel = this._localization.setFloatLabel;
  }
  captions: any;
  
  ngOnInit() 
  {
    this.captions = this._localization.captions.reports;
    this.commonCaptions = this._localization.captions.common;
    this.allData = { id: 0, [this.keyName]: this.allButtonPlaceholder ? this.allButtonPlaceholder : this.captions.all, description: 'All' };
    // For first time emit after the component creation.
    this.buttonSelected.emit( (
      this.multiSelectFrmGrp.controls.controlname
    ) as UntypedFormControl);
  }

  ngOnChanges() {
    this.multiSelectFrmGrp = this.formBuilder.group({
      controlname: []
    });
    if (!this.data) {
      return;
    }
    this.buttonData = this.data;
    this.actionBtn = this.buttonData.filter(x => !x.disabled);
    this.multiSelectFrmGrp = this.formBuilder.group({
      controlname: _.cloneDeep(this.defaultSelected) ? [_.cloneDeep(this.defaultSelected)] : []
    });
    this.isAllDisabled = this.buttonData.every(x => x.disabled);
    if (!this.applyCancel) {
    this.buttonSelected.emit( (
      this.multiSelectFrmGrp.controls.controlname
    ) as UntypedFormControl);
    }
  }  

  buttonSelectionChange(buttonData: ButtonData) {
    this.buttonSelectionAction(buttonData);
    this.buttonSelected.emit(this.multiSelectFrmGrp.controls
      .controlname as UntypedFormControl);
  }

  buttonSelectionChangeFromPopover(buttonData: ButtonData) {
    this.buttonSelectionAction(buttonData);
    if (!this.applyCancel) {
    this.buttonSelected.emit(this.multiSelectFrmGrp.controls
      .controlname as UntypedFormControl);
    }
  }

  buttonSelectionAction(buttonData: ButtonData) {    
    if (this.type === single) {
      this.multiSelectFrmGrp.controls.controlname.setValue(buttonData);
    } else if (this.type === multiple) {
      const data = this.multiSelectFrmGrp.controls.controlname.value
        ? this.multiSelectFrmGrp.controls.controlname.value
        : [];
      const index = data ? data.findIndex(res => res.id === buttonData.id) : -1;
      if (index !== -1) {
        const currentSelectedData = this.multiSelectFrmGrp.controls.controlname.value.filter(
          (res: ButtonData) => res.id !== buttonData.id
        );
        this.multiSelectFrmGrp.controls.controlname.setValue(
          currentSelectedData
        );
      } else if (index === -1) {
        data.push(buttonData);
        this.multiSelectFrmGrp.controls.controlname.setValue(data);
      }
      this.multiSelectFrmGrp.controls.controlname.markAsDirty();
      this.changedData = !this.changedData;
    }
  }

  allButtonSelectionChange(event) {
    if (event.id === 0) {
      if (
        (this.multiSelectFrmGrp.controls.controlname.value &&
          this.multiSelectFrmGrp.controls.controlname.value.length ===
            this.buttonData.length) ||
        (this.multiSelectFrmGrp.controls.controlname.value &&
          this.multiSelectFrmGrp.controls.controlname.value.length ===
            this.actionBtn.length)
      ) {
        this.multiSelectFrmGrp.controls.controlname.setValue([]);
      } else if (
        !this.multiSelectFrmGrp.controls.controlname.value ||
        this.multiSelectFrmGrp.controls.controlname.value.length !==
          this.buttonData.length
      ) {
        this.multiSelectFrmGrp.controls.controlname.setValue(this.buttonData);
        this.changedData = !this.changedData;
      }
    }
    let formControl = this.multiSelectFrmGrp.controls.controlname as UntypedFormControl;
    formControl.markAsDirty();
    this.buttonSelected.emit(formControl);
  }

  noOfChipsEmit(noOfButtons) {
    this.noOfButtons = noOfButtons;
    this.morepopover = false;
  }

  applyData() {
    this.buttonSelected.emit(this.multiSelectFrmGrp.controls
      .controlname as UntypedFormControl);
    this.popoverRef.hide();
  }

  closePopOver() {
    this.multiSelectFrmGrp.controls.controlname.setValue(_.cloneDeep(this.defaultSelected));
    this.popoverRef.hide();
  }
}
