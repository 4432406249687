import { ActionType } from 'src/app/shared/globalsContant';
import { AppointmentStatus } from 'src/app/retail/shared/globalsContant';
import { appointment, appointmentTherapist, Location, ServiceViewModel, Therapist } from 'src/app/shared/business/shared.modals';

export interface Appointments {
  id: number; name: string; date: string; bookingID: string; bookingName: string;
}

export interface AppointmentsHeader {
  description: string; key: string; alignment: string; sorting: boolean; searchable: boolean;
}

export interface OptionsData {
  id: number; description: any; type: ActionType; icon: string,show:boolean;
}

export interface TableOptionInteface {
  'selectType'?: SelectTypeEnum;
  'options'?: OptionsTypesEnum[];
}

export enum OptionsTypesEnum {
  edit = 'edit',
  delete = 'delete',
  dragdrop = 'dragdrop',
  claim = 'claim',
  moreoption = 'moreoption'
}

export enum SelectTypeEnum {
  checkbox = 'checkbox',
  radiobutton = 'radiobutton'
}

export interface GroupList {
  id: number; value: string; isSelect: boolean;
}

export interface AppointmentAvailable {
  id: number;
  overlap: string;
  ignorebreakdown: string;
  medictioncondtion: string;
  serviceDetails: ServiceViewModel;
  packageid: number;
  description: string;
  locationId: number[];
  therapistId: number[];
  slots?: WizardTimeSlots[];
  open?: boolean;
}

export interface Slots {
  id: number;
  time: string;
  slots: number;
  male: number;
  female: number;
  selected: boolean;
}

export interface TimeIntervals {
  localizedTime: string;
  dateTime: Date;
}

export interface WizardTimeSlots {
  id: number;
  localizedTime: string;
  dateTime: Date;
  availableLocations: Location[];
  availableStaff: Therapist[];
  availableSlots: number;
  maleStaffs: number;
  femaleStaffs: number;
  isEquipmentAvailable: boolean;
}
export interface FilterOptions {
  id: number;
  code: string;
  isSelect: boolean;
  serviceName: string;
}

export interface ServiceGroupAPI {
  id: number;
  code: string;
  description: string;
  listOrder: number;
}

export interface TherapistDetailAPI {
  id: number;
  code: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfHire: string;
  seniorityLevel: string;
  alsoKnownAs: string;
  allowGratuity: boolean;
  allowServiceCharge: boolean;
  allowCommission: boolean;
  isActive: boolean;
  listOrder: number;
  commissionClassId: number;
}

export interface TherapistUI {
  id: number;
  firstName: string;
  lastName: string;
}

export interface LocationUI {
  id: number;
  code: string;
  locationname: string;
}

export interface LocationAPI {
  id: number;
  code: string;
  description: string;
  listOrder: number;
}

export interface GroupActivity {
  id: string;
  start: string;
  end: string;
  guests: string[];
  tenantId: string;
  propertyId: string;
  groupBookingId: string;
  groupBookingName: string;
  noOfPlayers: string;
}
export interface FilterData {
  clientForm: ClientForm;
  newApptForm: NewApptForm;
  autoForm: AutoForm;
  otherDetails: OtherDetails;
  serviceOptionsForm: ServiceOptionsForm;
}
export interface ClientForm {
  multipleBooking: boolean;
  autoBook: boolean;
  hotelBookingList: boolean;
  member: boolean;
  linkAppointments: boolean;
  addSet: {
    id: number,
    name: string,
    guid: string
  };
  searchText: string;
  noOfClients: string;
  bookingId: any;
  bookingName: string;
  bookingClients: string;
  multiBookingOption: string;
  hotelReservationOption: string;
  ExistingClientOption:string;
}
export interface NewApptForm {
  appointmentDate: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  remainingAppointmentsOn: string;
  spaHours: boolean;
  ignoreSetupBreakdownTime: boolean;
  quickRoom: boolean;
}
export interface AutoForm {
  overLap: boolean;
  medicalCondition: boolean;
  maxPerDay: number | string;
  maxPerSlot: string;
  negotiatedPrice: NegotiatedPrice;
  negotiatedValue: string;
  distributionSetupForm: {
    service: number,
    staff: number,
    location: number
  }
}
export interface OtherDetails {
  LinkCode: number;
  vip: boolean;
  DoNotMove: boolean;
  ReqStaff: boolean;
  IsBookingLocked: boolean;
  genderPreference: number;
  AppointmentComments: string;
  CheckOutComments: string;
  noShowComments:string;
  customField1: number;
  customField2: number;
  customField3: number;
  customField4: string;
  customField5: string;
}
export interface ServiceOptionsForm {
  serviceType: string;
  locationId: number;
  therapistId: number;
  locationIds: number[];
  therapistIds: number[];
}
export interface BookingDetails {
  bookingId: string;
  bookingName: string;
  NoOfPlayers: string;
  start: Date | string;
  end: Date | string;
  clients: string[];
}


export enum spaWizardSaveMenu {
  Save = 1,
  SaveNew = 2,
  SaveExit = 3
}

export interface AppointmentServiceInformation {
  appointmentInformation: AppointmentInformation;
  customFieldData: CustomFieldData;
  medicalWarning: MedicalWarning;
  multiServiceInformation: MultiServiceInformation;
  serviceInformation: ServiceInformation;
  customFields: CustomField[];
}

export interface AppointmentInformation {
  appointmentDetails: AppointmentDetails;
  costDetails: CostDetails;
  otherDetails: OtherDetailsModel;
}

export interface AppointmentDetails {
  appointmentDateTime: string;
  appointmentId: number;
  bookingName: string;
  clientId: number;
  clientName: string;
  clientGender: string;
  duration: string;
  location: string;
  offsiteLocation: string;
  service: string;
  staff: string;
  status: AppointmentStatus;
}
export interface CostDetails {
  gratuity: number;
  package: string;
  price: number;
  serviceCharge: number;
  tax: number;
}

export interface OtherDetailsModel {
  appointmentComments: string;
  checkoutComments: string;
  doNotMoveAppointment: boolean;
  isVip: boolean;
  isStaffMemberRequested: boolean;
  genderPreference: GenderPreference;
}

export interface CustomFieldData {
  CustomField1: number | string;
  CustomField2: number | string;
  CustomField3: number | string;
  CustomField4: string;
  CustomField5: string;
}


export interface CustomField {
  fieldKey?: string;
  fieldName?: string;
  fieldType?: CustomFieldType;
  fieldValues?: KeyValuePair[];
  displayOn?: DisplayCustomField;
  hide?: boolean;
  isRequired?: boolean;
}

export interface ServiceInformation {
  serviceId?: number;
  serviceCancelPolicy: string;
  serviceComments: string;
  servicePolicy: string;
}

export interface MultiServiceInformation {
  clientList: string[];
  maxNumberOfClients: number;
  maxNumberOfStaff: number;
  minNumberOfClients: number;
  minNumberOfStaff: number;
  therapistList: string[];
}

export interface MedicalWarning {
  warnings: Warning[];
}

export interface Warning {
  warningMessage: string;
  warningTitle: string;
}

export interface KeyValuePair {
  isSelected?: boolean;
  id: number;
  key?: string;
  value?: string;
}

export enum GenderPreference {
  enforceGender = 1,
  male,
  female,
  noStaffGenderPreference
}

export enum CustomFieldType {
  Dropdown,
  TextField
}

export enum DisplayCustomField {
  None,
  ClientOnly,
  AppointmentOnly,
  BothClientAndAppointment
}
export interface NameList {
  tableHeader: string;
  tableValues: string[];
  tableMessage: string;
}

export interface AppointmentAggregateResponse {
  requestedAppointmentId: number;
  requestedAppointment: appointment;
  appointments: appointment[];
  customFields: any[];
  locations: any[];
  services: any[];
  therapists: any[];
  medicalWarning: any[];
}
export interface SpaWizardAppointmentRequest {
  id: number;
  spaWizardActionRequestType: SpaWizardActionRequestType;
  doNotMove?: boolean;
  isVip?: boolean;
  requestStaff?: boolean;
  genderPreference?: number;
  comments?: string;
  checkOutComments?: string;
  customField1?: number | null;
  customField2?: number | null;
  customField3?: number | null;
  customField4?: string;
  customField5?: string;
  locationid?: number;
  AppointmentTherapists?: appointmentTherapist[];
}

export enum SpaWizardActionRequestType {
  UpdateAppointmentServiceInformation,
  AppointmentStaffChange
}
export interface MultiServiceStaffSelection {
  maxNumberOfClients: number;
  maxNumberOfStaff: number;
  minNumberOfClients: number;
  minNumberOfStaff: number;
  service: string;
  appointmentDateTime: Date;
  clientsTherapists: SpaWizardClient[];
  therapists: SpaWizardTherapist[];
  serviceId: number;
}



export enum WizardAppointmentType {
  None = 0,
  MutipleBooking = 1,
  PMSGroupBooking = 2,
  AutoBook = 3,
}

export interface SlotSelectionValidationResponse {
  isValidSelectionForBooking: boolean;
  validationMessage: string;
  isMultiGroupAppointment: boolean;
}

export interface WizardTempAppointmentRequest {
  isQuickRoomForTwo: boolean;
  isOffSiteLocation: boolean;
  selectedTherapistId: number;
  selectedLocationId: number;
  isPackageBooking: boolean;
  packageId: number;
  appointments: TempAppointmentInfo[];
}

export interface WizardAutoBookTempAppointmentRequest {
  setGuid: string;
  setName: string;
  startDate: Date | string;
  endDate: Date | string;
  startTimeInMinutes: number;
  endTimeInMinutes: number;
  useSpaHours: boolean;
  ignoreSetupBreakDown: boolean;
  maxPerDay: number | string;
  maxPerTimeSlot: number | string;
  remaining: Date | string;
  overrideOverlap: boolean;
  overrideMedicalCondition: boolean;
  negotiatedPrice: NegotiatedPrice;
  negotiatedPriceValue?: number;
  serviceSetup: DistributionSetup;
  staffSetup: DistributionSetup;
  locationSetup: DistributionSetup;
  serviceId: number;
  locations: number[];
  therapists: number[];
  clients: SpaClient[];
  comments: string;
  doNotMove: boolean;
  requestStaff: boolean;
  isVip: boolean;
  customField1?: number;
  customField2?: number;
  customField3?: number;
  customField4: string;
  customField5: string;
  genderPreference: any;
  checkOutComments: string;
}

export interface SpaClient {
  id: number;
  guestId: string;
  tempClientId: string;
}

export interface WizardTempAppointmentResponse {
  isSuccess: boolean;
  errorList: number[];
  appointment: appointment;
  tempClientId: string;
  clientActivities?: any[];
}

export interface WizardAutoBookTempAppointmentResponse {
  tempAppointments: WizardTempAppointmentResponse[];
  clientResult: AutoBookClientResult[];
}

export interface AutoBookClientResult {
  clientId: number;
  tempClientId: string;
  tempAppointmentCreated: boolean
  errorList: number[];
}

export interface GroupDetail {
  id: number;
  groupName: string;
  groupId: string;
  groupType: WizardAppointmentType;
  startTime?: string;
  endTime?: string;
  useSpaHours?: boolean;
  createdTime?: string;
  createdBy?: number;
  modifiedTime?: string;
  modifiedBy?: number;
  clientId?: number;
  isAutobook?: boolean;
  hotelBookingId?: string;
  linkAppointments?: boolean;
}

export interface WizardGroupAppointmentRequest {
  groupDetail: GroupDetail;
  appointments: appointment[];
  isPackageAppointment: boolean;
  SendEmailNotification:boolean;
}

export interface WizardGroupAppointmentResponse {
  groupDetail: GroupDetail;
  appointments: appointment[];
}

export interface TempAppointmentInfo {
  tempClientId: string;
  appointment: appointment;
}

export interface GroupAppointment {
  appointment: appointment;
  tempClientId?: string;
}

export interface SetDetails {
  setName: string;
  setGuid: string;
  appointmentInfo:  GroupAppointment[]
}

export interface SpaWizardClient {
  id: number;
  spaWizardTempGuid: string;
  isTempClient?: boolean;
  name: string;
  therapists: number[];
  genderPreference: string;
}
export interface SpaWizardTherapist {
  id: number;
  name: string;
}

export interface GroupAppointmentsRequestModel {
  groupAppointmentId: number;
  appointmentId: number;
}

export interface AppointmentServiceInformationModal {
  requestedAppointmentId: number;
  requestedAppointment: appointment;
  requestedAppointmentClientInfo: AppointmentServiceClientData;
  requestedAppointmentGroupInfo: any;
  appointments: appointment[];
  customFields: any[];
  locations: any[];
  services: any[];
  therapists: any[];
  medicalWarning: any[];
  serviceMedicalWarnings: any[];
}

export interface AppointmentServiceClientData {
  requestedClientName: string;
  requestedClientGender: string;
  appointmentClientList: string[];
  appointmentTherapistList?: string[];
}

export interface AutoBookSet {
  id: number;
  name: string;
  guid: string;
}

export const AppointmentAction = {
  AppointmentStaffChangeSuccess: 'AppointmentStaffChangeSuccess',
  AppointmentStaffChangeFailure: 'AppointmentStaffChangeFailure',
  AppointmentServiceInformationUpdateSuccess: 'AppointmentStaffChangeUpdateSuccess',
  AppointmentServiceInformationUpdateFailure: 'AppointmentStaffChangeUpdateFailure',
  DeleteTempHoldAppointmentSuccess: 'DeleteTempHoldAppointmentSuccess',
  DeleteTempHoldAppointmentFailure: 'DeleteTempHoldAppointmentFailure'
};

export enum DistributionSetup {
  None,
  Range,
  Day
}

export enum NegotiatedPrice {
  Flat,
  Percent
}

export interface Options {
  id: number,
  value: number;
  viewValue: string;
}

export enum BookingStatus {
  None = 0,
  Booked = 1,
  NotAvailable = 2
}

export interface AutoBookResponse {
  tempAppointments: WizardTempAppointmentResponse[];
  clientResult: AutoBookClientResult[];
}
export interface AutoBookClientResult {
  clientId: number;
  tempAppointmentCreated: boolean;
  errorList: number[];
}

export enum Days {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export interface SetClientDetail {
  setName: string;
  setGuid: string;
  clientAppointmentDetail: ClientAppointmentDetail[];
}

export interface ClientAppointmentDetail {
  clientId: number;
  clientTempId: string;
  serviceId: number;
}

export enum AppointmentActions {
  TempAppointmentCreated = 'TEMP Created'
}