import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { EmailTemplatesService } from './email-templates.service';
import { NotificationActions, Type, Keywords, TemplateData, TemplateForm, TemplateDetailUI, TemplateAsset } from './email-templates.model';
import { EmailTemplatesBusiness } from './email-templates.business';
import * as DecoupledEditor from '../../ckeditor5-build-decoupled-document-master/build/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { TemplateServiceCommunication } from '../templates.service';
import { ButtonType } from '../../shared/shared/globalsContant';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CkeditorPopupComponent } from '../../shared/shared/ckeditor-popup/ckeditor-popup.component';
import { MatDialog } from '@angular/material/dialog';
import juice from 'juice';
@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
  providers: [EmailTemplatesBusiness, EmailTemplatesService, TemplateServiceCommunication],
  encapsulation: ViewEncapsulation.None
})
export class EmailTemplatesComponent implements OnInit, OnDestroy {
  emailTemplatesFormGroup: any;
  captions: any;
  actions: NotificationActions[];
  keywords: Keywords[];
  emailTypes: { id: number; name: string; }[];
  productId: string;
  Editor = DecoupledEditor;
  Config = {
    toolbar: [
      'bold', 'italic', 'underline', 'strikethrough', 'fontfamily', 'fontsize',
      'fontcolor', 'fontbackgroundcolor', 'heading', 'alignment', 'numberedList',
      'bulletedList', 'outdent', 'indent', 'link', 'imageUpload', 'undo', 'redo','htmlEmbed'
    ]
  };
  @ViewChild('editor1') editor1: CKEditorComponent;
  @ViewChild('editor2') editor2: CKEditorComponent;
  @ViewChild('editor3') editor3: CKEditorComponent;
  @ViewChild('editor4') editor4: CKEditorComponent;
  @ViewChild('editor5') editor5: CKEditorComponent;
  currentEditor: any;
  templateForm: TemplateForm;
  templateData: TemplateData[] = [];
  currentTopic: number = 0;
  defaultTemplateIdSelectedValue: number = 0;
  defaultTemplateIdValue: string = '';
  isdefaultUpdateRequired: boolean = false;
  templateCaptions: any;
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  floatLabel: string;
  constructor(private fb: UntypedFormBuilder, private localization: Localization, private emailTemplatesBusiness: EmailTemplatesBusiness, private utils: CommonUtilities, private templateServiceCommunication: TemplateServiceCommunication,public dialog: MatDialog) {
    this.captions = this.localization.captions.settings.utilities;
    this.templateCaptions = this.localization.captions.settings.utilities.template;
    this.floatLabel = this.localization.setFloatLabel;
  }

  async ngOnInit() {
    this.templateServiceCommunication.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';

        }
        else {
          loadingContainer.style.display = 'none';

        }
      }
    });

    this.templateServiceCommunication.loaderEnable.next(true);
    this.DefaultFormGenerator();
    this.productId = this.localization.GetPropertyInfo('ProductId');
    this.GetMasterData();
  }

  DefaultFormGenerator() {
    this.emailTemplatesFormGroup = this.fb.group({
      action: 0,
      emailTypes: 1,
      emailType1: '',
      emailType2: '',
      emailType3: '',
      emailType4: '',
      emailType5: '',
      templateId1: '',
      templateId2: '',
      templateId3: '',
      templateId4: '',
      templateId5: '',
      templateName1: '',
      templateName2: '',
      templateName3: '',
      templateName4: '',
      templateName5: '',
      name: ''
    });
  }

  async GetMasterData() {
    this.emailTypes = this.emailTemplatesBusiness.getEmailTypes();
    this.actions = await this.emailTemplatesBusiness.getActions(Type.Email);

    if (this.actions && this.actions.length > 0) {
      this.emailTemplatesFormGroup.controls.action.setValue(this.actions[0].id);
      await this.GetKeywordsAndTemplateData(this.actions[0].id);
    }

    this.templateServiceCommunication.loaderEnable.next(false);
  }

  async onSave($event) {
    if (!this.ValidateDefaultTemplate(this.emailTemplatesFormGroup.value, 'emailTypes')) {
      return;
    }

    this.templateServiceCommunication.loaderEnable.next(true);
    this.UpdateTemplateDataObject(this.emailTemplatesFormGroup.value);

    this.emailTemplatesFormGroup.markAsPristine();

    if (this.templateData && this.templateData.length > 0) {
      await this.emailTemplatesBusiness.CreateorUpdateTemplate(this.currentTopic, this.templateData);
    }

    if (this.isdefaultUpdateRequired) {
      if (!this.defaultTemplateIdValue) {
        await this.GetKeywordsAndTemplateData(this.emailTemplatesFormGroup.value.action); //To get the default template id from api
        this.getDefaultTemplateIdValue(this.templateForm);
      }
      await this.emailTemplatesBusiness.UpdateDefaultTemplate(this.currentTopic, this.defaultTemplateIdValue, Type.Email);
    }

    await this.GetKeywordsAndTemplateData(this.emailTemplatesFormGroup.value.action);

    this.clearValues();

    this.templateServiceCommunication.loaderEnable.next(false);
  }

  clearValues() {
    this.currentTopic = 0;
    this.templateData = [];
    this.defaultTemplateIdSelectedValue = 0;
    this.isdefaultUpdateRequired = false;
    this.defaultTemplateIdValue = '';
  }

  onCancel($event) {
    this.PatchValue();
    this.emailTemplatesFormGroup.markAsPristine();
  }

  async GetKeywordsAndTemplateData(actionId: number) {
    const templateDetailUI: TemplateDetailUI = await this.emailTemplatesBusiness.getTemplateDetails(actionId);
    this.keywords = templateDetailUI.keywords;
    this.templateForm = templateDetailUI.templateForm;
    this.PatchValue();
  }

  async actionChanged(event) {
    this.templateServiceCommunication.loaderEnable.next(true);
    await this.GetKeywordsAndTemplateData(event.value);
    this.templateServiceCommunication.loaderEnable.next(false);
  }

  PatchValue() {
    if (this.templateForm) {
      this.emailTemplatesFormGroup.patchValue(this.templateForm);
    }
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  elementClick(arg) {
    const appendData = "{{" + arg + "}}";
    const selection = this.currentEditor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    this.currentEditor.editorInstance.model.change(writer => {
      writer.insert(appendData, range.start);
    });
  }

  onChange(currentEditorInstance) {
    this.currentEditor = currentEditorInstance;
  }

  UpdateTemplateDataObject(formData: any) {
    this.currentTopic = formData.action;
    this.AddTemplateDataIfDirty(formData, 'emailType1', 'templateId1', 'templateName1', '_Email1', 1);
    this.AddTemplateDataIfDirty(formData, 'emailType2', 'templateId2', 'templateName2', '_Email2', 2);
    this.AddTemplateDataIfDirty(formData, 'emailType3', 'templateId3', 'templateName3', '_Email3', 3);
    this.AddTemplateDataIfDirty(formData, 'emailType4', 'templateId4', 'templateName4', '_Email4', 4);
    this.AddTemplateDataIfDirty(formData, 'emailType5', 'templateId5', 'templateName5', '_Email5', 5);
  }

  AddTemplateDataIfDirty(formData: any, htmlContentcontrol: string, templateIdControl: string, templateNameControl: string,
    suffixTemplateName: string, templateNumber: number) {
    this.templateData.forEach((r, i) => {
      if (r.templateName === formData[templateNameControl]) {
        this.templateData.splice(i, 1);
      }
    });

    if (this.emailTemplatesFormGroup.controls[htmlContentcontrol].dirty) {
      const actionName = formData.name;
      const templateNameValue = formData[templateNameControl];
      const templateContentValue = formData[htmlContentcontrol];
      const templateIdValue = formData[templateIdControl];
      const ImageData = this.getImageUrlFromHtml(templateContentValue, actionName + '_');
      let modifiedHTMLData = juice.inlineContent(this.utils.getCkeditorImgWidth(ImageData.replacedImageSrcURL),this.utils.getCssStringForCkEditor());
      const templateData: TemplateData = {
        templateName: templateNameValue ? templateNameValue : actionName + suffixTemplateName,
        topicName: actionName,
        templateType: Type.Email,
        isDefault: formData['emailTypes'] == templateNumber ? true : false,
        templateAsset: ImageData.templateAsset,
        htmlContent: modifiedHTMLData,
        templateId: templateIdValue
      };

      this.templateData.push(templateData);
    }

  }

  getImageUrlFromHtml(htmlData, imageNamePrefix) {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlData, 'text/html');
    const imageData = document.getElementsByTagName('img');
    const templateAsset: TemplateAsset[] = [];
    for (let i = 0; i < imageData.length; i++) {
      const imageBase64Data = this.getBase64Data(imageData[i].src);

      if (imageBase64Data) {
        const imageUniqueName = imageNamePrefix + this.utils.generateGUID();
        templateAsset.push(
          {
            contentType: this.getImageType(imageData[i].src),
            fileName: imageUniqueName,
            keyName: imageUniqueName,
            imageData: imageBase64Data
          });
        imageData[i].src = imageUniqueName;
      }
    }
    const replacedImageSrcURL = document.getElementsByTagName('body')[0].innerHTML;
    return { templateAsset, replacedImageSrcURL };
  }

  getBase64Data(url: string) {
    return url.split(',')[1];
  }

  getImageType(url: string) {
    return url.substring(
      url.lastIndexOf(':') + 1,
      url.lastIndexOf(';')
    );
  }

  ValidateDefaultTemplate(formData: any, defaultDropdownControl: string): boolean {
    let isValid: boolean = false;
    this.defaultTemplateIdSelectedValue = formData[defaultDropdownControl];
    const templateHtmlContentControl = 'emailType' + this.defaultTemplateIdSelectedValue;
    const defaultTemplateHtmlContentValue = formData[templateHtmlContentControl];

    if (this.defaultTemplateIdSelectedValue && defaultTemplateHtmlContentValue) {
      this.getDefaultTemplateIdValue(formData);
      isValid = true;
      const tempDefault = this.emailTemplatesFormGroup.controls[defaultDropdownControl].dirty ? true : false;
      this.isdefaultUpdateRequired = !this.defaultTemplateIdValue ? true : tempDefault;
    }

    if (!isValid) {
      this.utils.ShowError(this.captions.DefaultTemplateErrorTitle, this.captions.DefaultTemplateErrorMessage, ButtonType.Ok, null, null, false);
    }

    return isValid;
  }

  getDefaultTemplateIdValue(formData: any) {
    const defaultTemplateIdControl = 'templateId' + this.defaultTemplateIdSelectedValue;
    this.defaultTemplateIdValue = formData[defaultTemplateIdControl];
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
  showHtmlContent(getControlName){
    let modifyHTML = juice.inlineContent(this.utils.getCkeditorImgWidth(this.emailTemplatesFormGroup.get(getControlName)?.value),this.utils.getCssStringForCkEditor())
    const dialogRef = this.dialog.open(CkeditorPopupComponent, {
      height: '90%',
      width: '90%',
      data: { headername: this.captions.composed,datarecord: modifyHTML, Type:"HTML" },
      panelClass: 'ag_dialog--lg',
      disableClose: true,
      hasBackdrop: true
  });
  }
}
