import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from '../../../settings.service';
import { SpaUtilities, RecurringType } from '../../../../shared/utilities/spa-utilities';
import * as moment from 'moment';
import { BaseResponse } from '../../../../shared/business/shared.modals';
import { HttpServiceCall, HttpMethod } from '../../../../shared/service/http-call.service';
import { Host, SPAScheduleBreakPoint } from '../../../../shared/globalsContant';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { AgTimeConfig } from 'src/app/common/Models/ag-models';
import { ButtonValue } from 'src/app/ag-common/models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
 
@Component({
  selector: 'app-lunch-setup-edit',
  templateUrl: './lunch-setup-edit.component.html',
  styleUrls: ['./lunch-setup-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LunchSetupEditComponent implements OnInit {
  lunchsetupform: UntypedFormGroup;
  DaysArr: any = [];
  captions: any = this.localization.captions.setting;
  common: any = this.localization.captions.utilities;
  LunchArr: any = [{ "id": 0, "name": "15 " + this.localization.captions.common.Minutes, "minutes": 15 },
  { "id": 1, "name": "30 " + this.localization.captions.common.Minutes, "minutes": 30 },
  { "id": 2, "name": "45 " + this.localization.captions.common.Minutes, "minutes": 45 },
  { "id": 3, "name": "60 " + this.localization.captions.common.Minutes, "minutes": 60 },];
  selectedLunchArr: any = [];
  selectedDaysArr: any = [];
  selectedDaysArrNumber: any[];
  availableDaysArr: any = [];
  Editdata: any;
  EditCurData: any;
  clickbutton: any;
  index: any;
  isEditable = false;
  weekstart: moment.Moment;
  weekend: moment.Moment;
  Data: any;
  id: any;
  minDate: Date = this.PropertyInfo.CurrentDate;
  startDate: any;
  appointmentConfiguration: any;
  endDate: any;
  isUserAuthorized = true;
  isViewOnly = true;
  operation: string;
  isReadOnly: any;
  currentTime: any;
  today = new Date;
  placeholderFormat: string;
  commonCaptions: any;
  floatLabel:string;
  startTimeInputs: AgTimeConfig;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(private vsBusiness: ViewSettingClientBusiness,
    private BP: BreakPointAccess,
    private Form: UntypedFormBuilder, public dialogRef: MatDialogRef<LunchSetupEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public ss: SettingsService,
    public utils: SpaUtilities, public http: HttpServiceCall,
    public localization: SpaLocalization, private PropertyInfo: SpaPropertyInformation) {
      this.placeholderFormat = this.localization.inputDateFormat;
      this.commonCaptions = this.localization.captions.common;
      this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.Editdata = this.data.datarecord ? this.data.datarecord.lunchdata : '';
    this.EditCurData = this.data.datarecord ? this.data.datarecord.curdata : '';
    this.index = this.data.datarecord ? this.data.datarecord.index : '';
    this.weekstart = moment(this.PropertyInfo.CurrentDate).startOf("isoWeek");
    this.weekend = moment(this.PropertyInfo.CurrentDate).endOf("isoWeek");
    this.lunchsetupform = this.Form.group({
      startdate: [this.ss.startDate, Validators.required],
      enddate: [this.ss.endDate, Validators.required],
      starttime: ['', Validators.required],
      slots: ['', Validators.required],
      duration: ['', Validators.required]
    });
    this.saveButton = {
      type: 'primary',
      label: typeof this.data.datarecord === 'undefined' ? this.common.save : this.common.update,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.common.cancel,
    }
    this.startTimeInputs = {
      className: '',
      form: this.lunchsetupform,
      formControlName: 'starttime',
      placeHolderId: 'lbl_startTime',
      placeHolder: this.common.StartTime,
      isTimeRequired: true,
      automationId: "'Txt_LunchSetupEdit_starttime'",
      errorMessage: this.captions.missingStartTime
    };
    this.CheckAvailableDays('');
    this.vsBusiness.activeFormGroup = this.lunchsetupform;
    this.operation = this.data.popupConfig.operation;
    this.ValidateBreakPoint();
    this.DaysArr = this.utils.getShortWeekArrayLocaleSorted();
    this.utils.getConfiguration(this.successCallback.bind(this), this.utils.errorCallback.bind(this), "Appointment", "APPOINMENT_DEFAULTLUNCHDURATION");
    this.clickbutton = typeof this.data.datarecord === 'undefined' ? this.common.save : this.common.update;
    if (this.Editdata) {
      this.isEditable = true;
      this.id = this.EditCurData.id;
      this.GetLunchData(this.id);
    } else {
      this.isEditable = false;

    }
    this.currentTime = this.localization.LocalizeTime(new Date());
    console.log("current time is ", this.currentTime);
    this.lunchsetupform.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.enableSave();
    });
  }
  enableSave(){
    this.saveButton.disabledproperty = !(this.lunchsetupform.valid && this.lunchsetupform.dirty && this.selectedDaysArr.length > 0);
    this.saveButton = { ...this.saveButton };
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingLunchSetup]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingLunchSetup);
    if (this.isViewOnly) {
      this.utils.disableControls(this.vsBusiness.activeFormGroup);
    }
  }
  CheckAvailableDays(e) {
    let strt = moment(this.localization.ConvertDateToISODate(this.utils.getDate(this.lunchsetupform.get('startdate').value)));//.format('DD-MM-YYYY');
    let end = moment(this.localization.ConvertDateToISODate(this.utils.getDate(this.lunchsetupform.get('enddate').value)));//.format('DD-MM-YYYY');
    this.availableDaysArr = this.WeekArray(strt, end);
    this.RemoveUnavailabileDays();
    this.toggleAllFilter(false);
  }
  // Generate Week Array starts
  WeekArray(strt: any, end: any) {
    const aweek = [];
    let row2: any, nxtday: any;
    const noOfDayDisp = end.diff(strt, "days");
    aweek.push(this.captions.AllDays);
    for (let i = 0; i <= noOfDayDisp; i++) {
      if (i == 0) {
        row2 = strt.format("ddd");
        aweek.push(row2);
      } else {
        nxtday = strt.add(1, "day");
        row2 = nxtday.format("ddd");
        aweek.push(row2);
      }
    }
    return aweek;
  }

  RemoveUnavailabileDays() {
    for (let i = 0; i < this.selectedDaysArr.length; i++) {
      if (!this.availableDaysArr.includes(this.selectedDaysArr[i].name)) {
        this.selectedDaysArr.splice(i, 1);
      }
    }
  }

  toggleButtonClick = ($event: any, frm: any, Driven: any) => {
    if (Driven.id == 7) {
      this.toggleAllFilter();
      return;
    }
      this.RemoveUnavailabileDays();
      this.PP(this.selectedDaysArr, Driven);
      this.enableSave();
  }

  toggleAllFilter(isDisable: boolean = true) {
    if (this.selectedDaysArr.length != this.availableDaysArr.length) {
      this.selectedDaysArr = []
      for (let i = 0; i < this.DaysArr.length; i++) {
        for (let j = 0; j < this.availableDaysArr.length; j++) {
          if (this.DaysArr[i].name == this.availableDaysArr[j]) {
            this.selectedDaysArr.push(this.DaysArr[i])
          }
        }
      }
    } else if (isDisable) {
      this.selectedDaysArr = [];
    }
  }
  ButtonSelect(Arr: any, value: any, setFlag: boolean = true) {
    Arr.splice(0, 1);
    Arr.push(value);
    if (setFlag) {
      this.lunchsetupform.markAsDirty();
    }
  }

  isExist(coll: any, obj: any) {
    let index = -1;
    if (coll) {
      index = coll.findIndex(x => x.id == obj.id);
    }
    return index;
  }
  PP(ga: any, gv: any) {
    if (this.isExist(ga, gv) == -1) {
      ga.push(gv);
      if (ga.length == this.availableDaysArr.length - 1) {
        this.selectedDaysArr = [];
        for (let i = 0; i < this.DaysArr.length; i++) {
          for (let j = 0; j < this.availableDaysArr.length; j++) {
            if (this.DaysArr[i].name == this.availableDaysArr[j]) {
              this.selectedDaysArr.push(this.DaysArr[i])
            }
          }
        }
      }
    } else {
      ga.splice(this.isExist(ga, gv), 1);
      if (ga.length == this.availableDaysArr.length - 1) {
        const obj = ga.find(x => x.id == 7);
        if (obj) {
          ga.splice(obj, 1);
        }
      }
    }
  }

  GetLunchData(id: any) {
    this.http.CallApiWithCallback<number>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetLunchData",
      method: HttpMethod.Get,
      uriParams: { id: id },
      showError: true,
      extraParams: []
    });

  }
  validateSave() {
    return this.lunchsetupform.valid && this.lunchsetupform.dirty && this.selectedDaysArr.length > 0;
  }

  save() {
    this.saveButton.disabledproperty = true;
    if (this.clickbutton == this.common.save) {
      this.Data = this.utils.getRecurringDates(RecurringType.Weekly, this.utils.getDate(this.lunchsetupform.value.startdate), this.utils.getDate(this.lunchsetupform.value.enddate), 1
        , this.selectedDaysArr.map(x => x.id));
      const startEndDate: any = [];
      const startTime = this.lunchsetupform.value.starttime;
      const duration = this.lunchsetupform.value.duration;
      for (let i = 0; i < this.Data.length; i++) {
        const actualDate = this.Data[i];
        const lunchTime = this.utils.getAPIDateTimeFromDateAndTimeControls(this.Data[i], startTime);
        startEndDate.push({
          Id: 0,
          StartDate: this.utils.formatDate(actualDate),
          EndDate: this.utils.formatDate(actualDate),
          LunchTime: lunchTime,
          Available: this.lunchsetupform.value.slots,
          Duration: duration
        });
      }
      try {
        this.http.CallApiWithCallback({
          host: Host.spaManagement,
          success: this.successCallback.bind(this),
          error: this.utils.errorCallback.bind(this),
          callDesc: "CreateLunchSetup",
          method: HttpMethod.Post,
          body: startEndDate,
          showError: true,
          extraParams: []
        });
      }
      finally{
        this.saveButton.disabledproperty = false;
      }
    } else {
      let currentObj: any;
      this.localization.getDate(this.lunchsetupform.controls.startdate.value);
      const startTime = this.lunchsetupform.value.starttime;
      const duration = this.lunchsetupform.value.duration;
      const lunchTime = this.utils.getAPIDateTimeFromDateAndTimeControls(this.lunchsetupform.controls.startdate.value , startTime);
      currentObj = {
        Id: this.id,
        StartDate: this.lunchsetupform.controls.startdate.value,
        EndDate: this.lunchsetupform.controls.startdate.value,
        LunchTime: lunchTime,
        Available: this.lunchsetupform.value.slots,
        Duration: duration
      }
      try {
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "UpdateLunchSetup",
        uriParams: { id: this.id },
        method: HttpMethod.Put,
        body: currentObj,
        showError: true,
        extraParams: []
      });
     } 
     finally{
      this.saveButton.disabledproperty = false;
     }
    }
  }
  cancel() {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateLunchSetup" || callDesc == "UpdateLunchSetup") {
      this.dialogRef.close();
    }
      if (callDesc == "GetLunchData") {
      const resultData = <any>result.result;
      this.lunchsetupform.controls.startdate.setValue(resultData.lunchTime);
      this.lunchsetupform.controls.enddate.setValue(resultData.lunchTime);
      this.lunchsetupform.controls.slots.setValue(resultData.available);
      this.lunchsetupform.get('starttime').setValue(this.localization.getTime(resultData.lunchTime,12));
      this.ss.type2Array = this.Editdata.days;
      this.lunchsetupform.controls.duration.setValue(resultData.duration);
      const dayName = this.localization.getDayForDate(this.utils.getDate(this.lunchsetupform.controls.startdate.value));
      const daySelected: any[] = [];
      this.DaysArr.forEach(element => {
        if (element.name == dayName) {
          daySelected.push(element);
        }
      });
      const day = daySelected[0];
      this.PP(this.selectedDaysArr, day);
      this.lunchsetupform.controls['startdate'].disable();
      this.lunchsetupform.controls['enddate'].disable();
    }
  }

}
