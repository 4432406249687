import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StaffScheduleService } from '../staff-schedule.service';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { SPAManagementBreakPoint } from 'src/app/shared/globalsContant';

@Component({
  selector: "app-staff-weekly-data-table",
  templateUrl: "./staff-weekly-data-table.component.html",
  styleUrls: ["./staff-weekly-data-table.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class StaffWeeklyDataTableComponent implements OnInit {
  captions: any;
  ScheduleData: any[];
  scheduleWeeklyForm: UntypedFormGroup;
  BookAptCaptions: any;
  therapistLocationArr = [];
  therapistBreakTypeArr = [];
  timeFormat: number;
  blankDateTime: any = "HH:mm";
  TherapistLocation: UntypedFormArray;
  WorkBreakType: UntypedFormArray;
  WorkStartTime: UntypedFormArray;
  WorkEndTime: UntypedFormArray;
  viewCheckedFlag: any;
  WorkBreakTime: UntypedFormArray;
  WorkBreakEndTime: UntypedFormArray;
  WorkBreakTimeDuration: UntypedFormArray;
  paid: UntypedFormArray;
  Unpaid: UntypedFormArray;
  floatLabel: string;
  OCIcon = false;
  OffIcon = false;
  PaidIcon = false;
  UnPaidIcon = false;
  startTimeTempHolder: any;
  endTimeTempHolder: any;
  breakTimeTempHolder: any;
  breakEndTimeTempHolder: any;
  addBreak: boolean = false;
  IsRecurringOn: boolean = false;
  IsshowCopySectionOn: boolean = false;
  breakPointNumber: any;
  paidUnpaidToggle: Boolean;
  disableAllCheckBox: Boolean = false;
  fromDate: any;
  toDate: any;
  recurringDays;
  scheduleData;
  breakCaptionName: string;
  breakPointAccessCheck: boolean = true;
  @Input()
  set ApplyToRecurringDays(data) {
    if (data) {
      this.recurringDays = data;
      //this.breakCaptionName = !this.recurringDays?.isEdit ? this.captions.AddBreak : this.recurringDays.isEdit && this.recurringDays.breakTime.length > 0 && this.recurringDays.breakEndTime.length > 0 ? this.captions.EditBreak : this.captions.AddBreak;
      this.ApplyAllRecurringDays(
        this.recurringDays.applyAllTime,
        this.recurringDays.startTime,
        this.recurringDays.endTime,
        this.recurringDays.location,
        this.recurringDays.breakType,
        this.recurringDays.breakTime,
        this.recurringDays.breakEndTime,
        this.recurringDays.onCall,
        this.recurringDays.offDuty,
        this.recurringDays.paid,
        this.recurringDays.unPaid
      );
    }
  }
  @Input()
  set ApplyToRemoveBreakTime(data) {
      this.addBreak = data;
      this.ApplyToAllRemoveBreakTime(data);
      this.checkBreakTimeAvailable();
  }
  @Input()
  set locationArr(data) {
    this.therapistLocationArr = data;
  }
  @Input()
  set BreakTypeArr(data) {
    this.therapistBreakTypeArr = data;
  }
  @Input()
  set IsshowCopySection(data) {
    this.IsshowCopySectionOn = data;
  }

  @Input()
  set scheduleArray(data) {
    if (data) {
      this.scheduleWeeklyForm = data;
      this.checkBreakTimeAvailable();
    }
  }

  @Input()
  set IsRecurring(data) {
    this.IsRecurringOn = data;
  }
  @Input()
  set FromDate(data) {
    this.fromDate = data;
  }
  @Input()
  set ToDate(data) {
    this.toDate = data;
  }
  @Input()
  set formedScheduleData(data) {
    this.scheduleData = data;
  }
  @Output() FormEmit = new EventEmitter();
  @Output() SelectedDayFormEmit = new EventEmitter();
  @Output() isFormValid = new EventEmitter();
  dayMaxTime = "11:59 pm";
  constructor(
    private localization: SpaLocalization,
    private fb: UntypedFormBuilder,
    private BP: BreakPointAccess,
    public _su: StaffScheduleService
  ) {
    this.timeFormat = this.localization.getTimeFormat();
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.breakPointNumber = this.BP.GetBreakPoint([SPAManagementBreakPoint.paidUnpaidProfile]);
    this.paidUnpaidToggle = this.breakPointNumber.result[0].allow;
    this.captions = this.localization.captions.staffSchedule;
    this.BookAptCaptions = this.localization.captions.bookAppointment;
    this.ScheduleData = this.scheduleData;//this._su.formScheduleData();
    this._su.ScheduleData = this.ScheduleData;
    if (this.fromDate && this.toDate) {

      const fromDay = new Date(this.fromDate).getDay() + 1;

      const endDay = new Date(this.toDate).getDay() + 1;

      const diffTime = this.localization.getDateDifference(this.fromDate, this.toDate);

      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays >= 6) {

        this._su.ScheduleData.forEach((x) => x.disabled = false);

      } else {

        if (fromDay === endDay) {

          this._su.ScheduleData.forEach(x => {

            x.disabled = (x.id !== fromDay);

          });

        } else if (fromDay < endDay) {

          this._su.ScheduleData.forEach((x) => {

            x.disabled = !(x.id >= fromDay && x.id <= endDay);

          });

        } else {

          this._su.ScheduleData.forEach((x) => {

            x.disabled = !(x.id >= fromDay || x.id <= endDay);

          });

        }
      }
    }

    this.scheduleWeeklyForm = this.fb.group({
      TherapistLocation: this.fb.array([
        this.addTherapistLocation(0, this.ScheduleData[0].locationId)
      ]),
      WorkBreakType: this.fb.array([
        this.addWorkBreakType(0, this.ScheduleData[0].breakTypeId)
      ]),
      WorkStartTime: this.fb.array([
        this.addWorkStartTime(0, this.ScheduleData[0].startTime)
      ]),
      WorkEndTime: this.fb.array([
        this.addWorkEndTime(0, this.ScheduleData[0].endTime)
      ]),
      WorkBreakTime: this.fb.array([
        this.addWorkBreakTime(0, this.ScheduleData[0].breakTime)
      ]),
      WorkBreakEndTime: this.fb.array([
        this.addWorkEndBreakTime(0, this.ScheduleData[0].breakTime)
      ]),
      WorkBreakTimeDuration: this.fb.array([
        this.addWorkBreakTimeDuration(0, this.ScheduleData[0].breakTimeDuration)
      ])
    });

    for (let i = 1; i < this.ScheduleData.length; i++) {
      this.TherapistLocation = this.scheduleWeeklyForm.get("TherapistLocation") as UntypedFormArray;
      this.TherapistLocation.push(this.addTherapistLocation(i, this.ScheduleData[i].locationId));

      this.WorkBreakType = this.scheduleWeeklyForm.get("WorkBreakType") as UntypedFormArray;
      this.WorkBreakType.push(this.addWorkBreakType(i, this.ScheduleData[i].breakTypeId));

      this.WorkStartTime = this.scheduleWeeklyForm.get("WorkStartTime") as UntypedFormArray;
      this.WorkStartTime.push(this.addWorkStartTime(i, this.ScheduleData[i].startTime));

      this.WorkEndTime = this.scheduleWeeklyForm.get("WorkEndTime") as UntypedFormArray;
      this.WorkEndTime.push(this.addWorkEndTime(i, this.ScheduleData[i].endTime));

      this.WorkBreakTime = this.scheduleWeeklyForm.get("WorkBreakTime") as UntypedFormArray;
      this.WorkBreakTime.push(this.addWorkBreakTime(i, this.ScheduleData[i].breakTime));

      this.WorkBreakEndTime = this.scheduleWeeklyForm.get("WorkBreakEndTime") as UntypedFormArray;
      this.WorkBreakEndTime.push(this.addWorkEndBreakTime(i, this.ScheduleData[i].breakTime));

      this.WorkBreakTimeDuration = this.scheduleWeeklyForm.get("WorkBreakTimeDuration") as UntypedFormArray;
      this.WorkBreakTimeDuration.push(this.addWorkBreakTimeDuration(i, this.ScheduleData[i].breakTime));

    }
    this.viewCheckedFlag = false;
    this.breakCaptionName = !this.recurringDays?.isEdit ? this.captions.AddBreak : this.recurringDays.isEdit && this.recurringDays.breakTime.length > 0 && this.recurringDays.breakEndTime.length > 0 ? this.captions.EditBreak : this.captions.AddBreak;
    if (this.recurringDays) {
      this.breakPointAccessCheck = this.breakCaptionName == this.captions.AddBreak ? this.BP.CheckForAtleastOneAccess([SPAManagementBreakPoint.AddTherapistBreak]).hasAccess : this.BP.CheckForAtleastOneAccess([SPAManagementBreakPoint.EditTherapistBreak]).hasAccess;
      this.ApplyAllRecurringDays(
        this.recurringDays.applyAllTime,
        this.recurringDays.startTime,
        this.recurringDays.endTime,
        this.recurringDays.location,
        this.recurringDays.breakType,
        this.recurringDays.breakTime,
        this.recurringDays.breakEndTime,
        this.recurringDays.onCall,
        this.recurringDays.offDuty,
        this.recurringDays.paid,
        this.recurringDays.unPaid
      );
    }
    this.FormEmit.emit(this.scheduleWeeklyForm);
    this.isFormValid.emit(false);
    this.scheduleWeeklyForm.valueChanges.subscribe(res => this.FormEmit.emit(this.scheduleWeeklyForm));

  }
  ApplyToAllRemoveBreakTime(bool) {
    if (bool) {

      for (let i = 0; i < this.ScheduleData.length; i++) {
        this.scheduleWeeklyForm.controls["WorkBreakTime"].reset();
        this.scheduleWeeklyForm.controls["WorkBreakEndTime"].reset();
        this.scheduleWeeklyForm.controls["WorkBreakType"].reset();
        this.PaidIcon = false;
        this.UnPaidIcon = false;
        this.ScheduleData[i].unPaid = false;
        this.ScheduleData[i].paid = false;
      }
    }

  }
  ApplyAllRecurringDays(applyAllTime, startTime, endTime, location, breakType, breakTime, breakEndTime, onCall = false, offDuty = false, paid = false, unpaid = false) {
    if (this.ScheduleData) {
      for (let i = 0; i < this.ScheduleData.length; i++) {
        this.PaidIcon = false;
        this.UnPaidIcon = false;
        this.ScheduleData[i].unPaid = unpaid;
        this.ScheduleData[i].paid = paid;
        this.OCIcon = false;
        this.OffIcon = false;
        this.ScheduleData[i].onCall = onCall;
        this.ScheduleData[i].Off = offDuty;
      }
      if (applyAllTime) {
        this.disableAllCheckBox = true;
        const start: any = this.scheduleWeeklyForm.controls["WorkStartTime"];
        const end: any = this.scheduleWeeklyForm.controls["WorkEndTime"];
        const therapistLocation: any = this.scheduleWeeklyForm.controls["TherapistLocation"];
        const BreakType: any = this.scheduleWeeklyForm.controls["WorkBreakType"];
        const breakT: any = this.scheduleWeeklyForm.controls["WorkBreakTime"];
        const breakET: any = this.scheduleWeeklyForm.controls["WorkBreakEndTime"];
        for (let i = 0; i < this.ScheduleData.length; i++) {
          if (!this.ScheduleData[i].Off) {
            start.controls[i].controls["StartTime"].patchValue(startTime);
            end.controls[i].controls["EndTime"].patchValue(endTime);
            therapistLocation.controls[i].controls["location"].patchValue(location);
            BreakType.controls[i].controls["BreakType"].patchValue(breakType);
            //breakT.controls[i].controls[0].controls["BreakStartTime"].patchValue(breakTime);
            //breakET.controls[i].controls[0].controls["BreakEndTime"].patchValue(breakEndTime);
            this.ScheduleData[i].breakTypeId = breakType;

            for (let j = 0; j < breakTime.length; j++) {
              if (j >= 1) {
                breakT.controls[i].push(this.addWorkBreakTimeGroup(j, breakTime[j].breakStartTime, breakTime[j].id));
                breakET.controls[i].push(this.addWorkEndBreakTimeGroup(j, breakEndTime[j].breakEndTime, breakTime[j].id));
              } else {
                breakT.controls[i].controls[j].controls['Id'].patchValue(breakTime[j].id);
                breakT.controls[i].controls[j].controls['BreakStartTime'].patchValue(breakTime[j].breakStartTime);

                breakET.controls[i].controls[j].controls['BreakEndTime'].patchValue(breakEndTime[j].breakEndTime);
                breakET.controls[i].controls[j].controls['Id'].patchValue(breakEndTime[j].id);
              }
            }
          }
        }
        for (let i = 0; i < this.ScheduleData.length; i++) {
          if (!this._su.IsValidTime(this.scheduleWeeklyForm.controls.WorkStartTime['controls'][i].value.StartTime, this.scheduleWeeklyForm.controls.WorkEndTime['controls'][i].value.EndTime)) {
            end['controls'][i].controls.EndTime.setErrors({ incorrect: true });
            end['controls'][i].controls.EndTime.markAsDirty();
            end['controls'][i].controls.EndTime.markAsTouched();
          }
          if (this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][i].value.BreakTime &&
            (!this._su.IsValidTime(this.scheduleWeeklyForm.controls.WorkStartTime['controls'][i].value.StartTime, this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][i].controls[0].value.BreakStartTime) ||
              !this._su.IsValidTime(this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][i].controls[0].value.BreakStartTime, this.scheduleWeeklyForm.controls.WorkEndTime['controls'][i].value.EndTime))) {
            breakT['controls'][i].controls[0].controls.BreakStartTime.setErrors({ incorrect: true });
            breakT['controls'][i].controls[0].controls.BreakStartTime.markAsDirty();
            breakT['controls'][i].controls[0].controls.BreakStartTime.markAsTouched();
          }
        }
      } else {
        if (this.scheduleWeeklyForm) {
          this.disableAllCheckBox = false;
          this.scheduleWeeklyForm.controls["WorkStartTime"].reset();
          this.scheduleWeeklyForm.controls["WorkEndTime"].reset();
          this.scheduleWeeklyForm.controls["TherapistLocation"].reset();
          this.scheduleWeeklyForm.controls["WorkBreakType"].reset();
          this.scheduleWeeklyForm.controls["WorkBreakTime"].reset();
          this.scheduleWeeklyForm.controls["WorkBreakEndTime"].reset();
        }
      }

      this._su.ScheduleData = this.ScheduleData;
    }
  }
  checkBreakTimeAvailable() {
    const breakT: any = this.scheduleWeeklyForm.controls["WorkBreakTime"];
    for (let i = 0; i < this.ScheduleData.length; i++) {
      if ((breakT.controls[i].controls[0].controls["BreakStartTime"].value != null) && (breakT.controls[i].controls[0].controls["BreakStartTime"].value != '')) {
        this.disableAllCheckBox = true;
        return;
      }
    }
    this.disableAllCheckBox = false;

  }

  SetLocation(k) {
    this.ScheduleData[k].locationId = this.scheduleWeeklyForm.controls.TherapistLocation['controls'][k]["controls"]["location"].value;
    if (!this.scheduleWeeklyForm.controls.WorkStartTime['controls'][k].controls.StartTime.value) {
      this.scheduleWeeklyForm.controls.WorkStartTime['controls'][k].controls.StartTime.setErrors({ incorrect: true });
      this.scheduleWeeklyForm.controls.WorkStartTime['controls'][k].controls.StartTime.markAsDirty();
      this.scheduleWeeklyForm.controls.WorkStartTime['controls'][k].controls.StartTime.markAsTouched();
    }
    if (!this.scheduleWeeklyForm.controls.WorkEndTime['controls'][k].controls.EndTime.value) {
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][k].controls.EndTime.setErrors({ incorrect: true });
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][k].controls.EndTime.markAsDirty();
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][k].controls.EndTime.markAsTouched();
    }

  }
  SetBreakType(k) {
    this.ScheduleData[k].breakTypeId = this.scheduleWeeklyForm.controls.WorkBreakType['controls'][k]["controls"]["BreakType"].value;
    if (!this.scheduleWeeklyForm.controls.WorkStartTime['controls'][k].controls.StartTime.value) {
      this.scheduleWeeklyForm.controls.WorkStartTime['controls'][k].controls.StartTime.setErrors({ incorrect: true });
      this.scheduleWeeklyForm.controls.WorkStartTime['controls'][k].controls.StartTime.markAsDirty();
      this.scheduleWeeklyForm.controls.WorkStartTime['controls'][k].controls.StartTime.markAsTouched();
    }
    if (!this.scheduleWeeklyForm.controls.WorkEndTime['controls'][k].controls.EndTime.value) {
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][k].controls.EndTime.setErrors({ incorrect: true });
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][k].controls.EndTime.markAsDirty();
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][k].controls.EndTime.markAsTouched();
    }
    this._su.ScheduleData = this.ScheduleData;
  }

  toggleonCallAll(res) {
    this.OCIcon = res;
    const breakT: any = this.scheduleWeeklyForm.controls["WorkBreakTime"];
    const breakET: any = this.scheduleWeeklyForm.controls["WorkBreakEndTime"];
    if (res) {
      this.breakTimeTempHolder = breakT.value;
      this.breakEndTimeTempHolder = breakET.value;
    }
    for (let i = 0; i < this.ScheduleData.length; i++) {
      this.ScheduleData[i].onCall = res;

      if (this.ScheduleData[i].onCall) {
        this.OffIcon = false;
        this.ScheduleData[i].Off = this.OffIcon;
        this.ScheduleData[i].paid = this.OffIcon;
        this.ScheduleData[i].unPaid = this.OffIcon;
        for (let k = 0; k < breakT.controls[i].controls.length; k++) {
          breakT.controls[i].controls[k].controls["BreakStartTime"].setValue("");
          breakET.controls[i].controls[k].controls["BreakEndTime"].setValue("");
        }
      }
      else {
        for (let k = 0; k < this.breakTimeTempHolder[i].length; k++) {
          breakT.controls[i].controls[k].controls["BreakStartTime"].setValue(this.breakTimeTempHolder[i][k].BreakStartTime);
          breakET.controls[i].controls[k].controls["BreakEndTime"].setValue(this.breakEndTimeTempHolder[i][k].BreakEndTime);
        }
      }
    }
    this.checkBreakTimeAvailable();
    this._su.ScheduleData = this.ScheduleData;

  }
  toggleoffAll(res) {
    this.OffIcon = res;
    const start: any = this.scheduleWeeklyForm.controls["WorkStartTime"];
    const end: any = this.scheduleWeeklyForm.controls["WorkEndTime"];
    const breakT: any = this.scheduleWeeklyForm.controls["WorkBreakTime"];
    const breakET: any = this.scheduleWeeklyForm.controls["WorkBreakEndTime"];

    if (res) {
      this.startTimeTempHolder = start.value;
      this.endTimeTempHolder = end.value;
      this.breakTimeTempHolder = breakT.value;
      this.breakEndTimeTempHolder = breakET.value;
    }
    for (let i = 0; i < this.ScheduleData.length; i++) {
      this.ScheduleData[i].Off = res;

      if (this.ScheduleData[i].Off) {
        this.OCIcon = false; // Set other toggle as False by default
        this.ScheduleData[i].onCall = this.OCIcon;
        this.ScheduleData[i].paid = this.OCIcon;
        this.ScheduleData[i].unPaid = this.OCIcon;
        start.controls[i].controls["StartTime"].setValue("");
        end.controls[i].controls["EndTime"].setValue("");
        for (let k = 0; k < breakT.controls[i].controls.length; k++) {
          breakT.controls[i].controls[k].controls["BreakStartTime"].setValue("");
          breakET.controls[i].controls[k].controls["BreakEndTime"].setValue("");
        }
      }
      else {
        start.controls[i].controls["StartTime"].setValue(this.startTimeTempHolder[i].StartTime);
        end.controls[i].controls["EndTime"].setValue(this.endTimeTempHolder[i].EndTime);
        for (let k = 0; k < this.breakTimeTempHolder[i].length; k++) {
          breakT.controls[i].controls[k].controls["BreakStartTime"].setValue(this.breakTimeTempHolder[i][k].BreakStartTime);
          breakET.controls[i].controls[k].controls["BreakEndTime"].setValue(this.breakEndTimeTempHolder[i][k].BreakEndTime);
        }
      }
    }
    this.checkBreakTimeAvailable();
    this._su.ScheduleData = this.ScheduleData;


  }

  toggleschedule(k, frm) {



    const toggleName: string = frm == "onCall" ? "Off" : "onCall"; // Used for automatic deselect of other toggle.
    if (this.ScheduleData[k][frm]) {
      this.ScheduleData[k][toggleName] = false;
      if (toggleName == "onCall") {
        this.OCIcon = false;
      } else {
        this.OffIcon = false;
      }
    } else {
      if (frm == "onCall") {
        this.OCIcon = false;
      } else {
        this.OffIcon = false;
      }
    }

    this.OCIcon = this.ScheduleData.every((x) => x.onCall);
    this.OffIcon = this.ScheduleData.every((x) => x.Off);

    const isValid = this.ScheduleData.filter(s => {
      return s.Off;
    })[0];


    const start: any = this.scheduleWeeklyForm.controls["WorkStartTime"];
    const end: any = this.scheduleWeeklyForm.controls["WorkEndTime"];
    const breakT: any = this.scheduleWeeklyForm.controls["WorkBreakTime"];
    const breakET: any = this.scheduleWeeklyForm.controls["WorkBreakEndTime"];


    // Clear start /End Time when Off toggle is selected
    if (frm == "Off") {

      if (this.ScheduleData[k][frm]) {
        this.startTimeTempHolder = start.value;
        this.endTimeTempHolder = end.value;
        this.breakTimeTempHolder = breakT.value;
        this.breakEndTimeTempHolder = breakET.value;
        this.ScheduleData[k].paid = false;
        this.ScheduleData[k].unPaid = false;
        start.controls[k].controls["StartTime"].setValue("");
        end.controls[k].controls["EndTime"].setValue("");
        for (let i = 0; i < breakT.controls[k].controls.length; i++) {
          breakT.controls[k].controls[i].controls["BreakStartTime"].setValue("");
          breakET.controls[k].controls[i].controls["BreakEndTime"].setValue("");
        }
      }
      else {
        start.controls[k].controls["StartTime"].setValue(this.startTimeTempHolder[k].StartTime);
        end.controls[k].controls["EndTime"].setValue(this.endTimeTempHolder[k].EndTime);
        for (let i = 0; i < this.breakTimeTempHolder[k].length; i++) {
          breakT.controls[k].controls[i].controls["BreakStartTime"].setValue(this.breakTimeTempHolder[k][i].BreakStartTime);
          breakET.controls[k].controls[i].controls["BreakEndTime"].setValue(this.breakEndTimeTempHolder[k][i].BreakEndTime);
        }
      }
    }
    if (frm == "onCall") {

      if (this.ScheduleData[k][frm]) {
        this.breakTimeTempHolder = breakT.value;
        this.breakEndTimeTempHolder = breakET.value;
        this.ScheduleData[k].paid = false;
        this.ScheduleData[k].unPaid = false;
        for (let i = 0; i < breakT.controls[k].controls.length; i++) {
          breakT.controls[k].controls[i].controls["BreakStartTime"].setValue("");
          breakET.controls[k].controls[i].controls["BreakEndTime"].setValue("");
        }
      }
      else {
        for (let i = 0; i < this.breakTimeTempHolder[k].length; i++) {
          breakT.controls[k].controls[i].controls["BreakStartTime"].setValue(this.breakTimeTempHolder[k][i].BreakStartTime);
          breakET.controls[k].controls[i].controls["BreakEndTime"].setValue(this.breakEndTimeTempHolder[k][i].BreakEndTime);
        }
      }
    }
    this.checkBreakTimeAvailable();
    this._su.ScheduleData = this.ScheduleData;

  }
  toggleschedulePaidUnpaid(k, frm) {



    const toggleName: string = frm == "paid" ? "unPaid" : "paid"; // Used for automatic deselect of other toggle.
    if (this.ScheduleData[k][frm]) {
      this.ScheduleData[k][toggleName] = false;
      if (toggleName == "paid") {
        this.PaidIcon = false;
      } else {
        this.UnPaidIcon = false;
      }
    } else {
      if (frm == "paid") {
        this.PaidIcon = false;
      } else {
        this.UnPaidIcon = false;
      }
    }

    this.PaidIcon = this.ScheduleData.every((x) => x.paid);
    this.UnPaidIcon = this.ScheduleData.every((x) => x.unPaid);

    const isValid = this.ScheduleData.filter(s => {
      return s.unPaid;
    })[0];
    this._su.ScheduleData = this.ScheduleData;
  }
  togglePaidAll(res) {
    this.PaidIcon = res;
    const breakT: any = this.scheduleWeeklyForm.controls["WorkBreakTime"];
    const breakET: any = this.scheduleWeeklyForm.controls["WorkBreakEndTime"];
    for (let i = 0; i < this.ScheduleData.length; i++) {

      if (breakT.controls[i].controls[0].controls["BreakStartTime"].value && breakET.controls[i].controls[0].controls["BreakEndTime"].value) {
        this.ScheduleData[i].paid = res;
        if (this.ScheduleData[i].paid) {
          this.UnPaidIcon = false;
          this.ScheduleData[i].unPaid = this.UnPaidIcon;
        }
      }
    }
    this._su.ScheduleData = this.ScheduleData;

  }
  toggleUnPaidAll(res) {
    this.UnPaidIcon = res;
    const breakT: any = this.scheduleWeeklyForm.controls["WorkBreakTime"];
    const breakET: any = this.scheduleWeeklyForm.controls["WorkBreakEndTime"];
    for (let i = 0; i < this.ScheduleData.length; i++) {
      if (breakT.controls[i].controls[0].controls["BreakStartTime"].value && breakET.controls[i].controls[0].controls["BreakEndTime"].value) {
        this.ScheduleData[i].unPaid = res;
        if (this.ScheduleData[i].unPaid) {
          this.PaidIcon = false; // Set other toggle as False by default
          this.ScheduleData[i].paid = this.PaidIcon;
        }
      }
    }
    this._su.ScheduleData = this.ScheduleData;
  }


  addTherapistLocation(arr: number, _location?: any): UntypedFormGroup {
    return this.fb.group({
      location: _location
    });
  }
  addWorkBreakType(arr: number, _BreakType?: any): UntypedFormGroup {
    return this.fb.group({
      BreakType: _BreakType
    });
  }

  addWorkStartTime(arr: number, _StartTime?: any): UntypedFormGroup {
    return this.fb.group({
      StartTime: _StartTime
    });
  }
  addWorkEndTime(arr: number, _EndTime?: any): UntypedFormGroup {
    return this.fb.group({
      EndTime: _EndTime
    });
  }
  addWorkBreakTime(arr: number, _BreakTime?: any): UntypedFormArray {
    return this.fb.array([
      this.fb.group({
        BreakStartTime: _BreakTime,
        Id: 0
      })
    ]);
  }

  addWorkEndBreakTime(arr: number, _BreakTime?: any): UntypedFormArray {
    return this.fb.array([
      this.fb.group({
        BreakEndTime: _BreakTime,
        Id: 0
      })
    ]);
  }

  addWorkBreakTimeDuration(arr: number, _BreakTimeDuration?: any): UntypedFormGroup {
    return this.fb.group({
      BreakTimeDuration: _BreakTimeDuration
    });
  }

  addWorkBreakTimeGroup(arr: number, _BreakTime?: any, breakTimeId?: number): UntypedFormGroup {
    return this.fb.group({
      BreakStartTime: _BreakTime,
      Id: breakTimeId
    })
  }

  addWorkEndBreakTimeGroup(arr: number, _BreakTime?: any, breakTimeId?:number): UntypedFormGroup {
    return this.fb.group({
      BreakEndTime: _BreakTime,
      Id: breakTimeId
    })
  }


  isClosed(event, index) {
    document.getElementById("staff-creation-section").classList.remove("transform3dnone");
    if (!this._su.IsValidTime(this.scheduleWeeklyForm.controls.WorkStartTime['controls'][index].value.StartTime, this.scheduleWeeklyForm.controls.WorkEndTime['controls'][index].value.EndTime)) {
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][index].controls.EndTime.setErrors({ incorrect: true });
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][index].controls.EndTime.markAsDirty();
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][index].controls.EndTime.markAsTouched();
      this.isFormValid.emit(false);
    } else {
      this.scheduleWeeklyForm.controls.WorkEndTime['controls'][index].controls.EndTime.setErrors(null);
    }

    if (this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][index].value.BreakTime && !this._su.IsValidTime(this.scheduleWeeklyForm.controls.WorkStartTime['controls'][index].value.StartTime, this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][index].value.BreakTime)) {
      this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][index].controls.BreakTime.setErrors({ incorrect: true });
      this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][index].controls.BreakTime.markAsDirty();
      this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][index].controls.BreakTime.markAsTouched();
      this.isFormValid.emit(false);
    } else {
      this.scheduleWeeklyForm.controls.WorkBreakTime['controls'][index].controls.BreakTime.setErrors(null);
    }
  }

  openPicker(event) {
    document.getElementById("staff-creation-section").classList.add("transform3dnone");
  }

  calculateWidth() {
    Array.from(document.querySelectorAll('#recurringTableWrapper>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')).forEach((table, index) => {
      if (table) {
        let tableHeight = table['offsetHeight'];
        let parentHeight = table.closest("#time-overlay")['offsetHeight'];
        if (parentHeight > tableHeight)
          table.closest("#recurringTableWrapper")['style']['height'] = tableHeight + 2 + 'px';
        else if (parentHeight < tableHeight)
          table.closest("#recurringTableWrapper")['style']['height'] = parentHeight + 'px';
      }
    });
  }

  ngAfterViewChecked() {
    if (!this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
      setTimeout(() => {
        this.calculateWidth();
      }, 1);

    }
  }

  addBreakForSelectedDay(index) {
    if (!this.breakPointAccessCheck) {
      let breakPointCaptionName = this.breakCaptionName == this.captions.AddBreak ? this.localization.captions.breakpoint[SPAManagementBreakPoint.AddTherapistBreak] : this.localization.captions.breakpoint[SPAManagementBreakPoint.EditTherapistBreak] ;
      this.BP.showBreakPointPopup(breakPointCaptionName);
      return;
    }
    this.SelectedDayFormEmit.emit([this.scheduleWeeklyForm, this.ScheduleData, index]);
  }
}
