import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, ReplaySubject } from 'rxjs';
import { AgDropdownConfig, AgInputFieldConfig, AgToggleConfig, DropdownOptions, ButtonValue } from 'src/app/common/Models/ag-models';
import { EventDetailsBusiness } from './event-details.business';
import { Distribution, DistributionType } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { ReportControl, ReportSelector_ } from 'src/app/reports/report.model';
import { ReportSelectorBuilder } from 'src/app/reports/common/report.selector';
import { DatePipe } from '@angular/common';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from '../../utilities/common-utilities';
import { ExecuteQuery, ReportAPIOptions , ScheduleJobConfig, CustomQueryUI, ExtractList, UI,ApiConfig} from '../event-scheduler.model';
import { ActionMode, Product } from 'src/app/common/enums/shared-enums';
import { AUTOINVOICE,AutoARInvoice, API } from 'src/app/common/constants';


@Component({
  selector: 'app-shared-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  providers: [EventDetailsBusiness, DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  captions;
  eventDetailForm: UntypedFormGroup;
  eventSource: Promise<DropdownOptions[]>;
  eventSourceConfig: AgDropdownConfig;
  guestEmailToggleInput: AgToggleConfig;
  sftpToggleInput: AgToggleConfig;
  ftpToggleInput: AgToggleConfig;
  reportTypeConfig: AgDropdownConfig;
  genericTypeConfig: AgDropdownConfig;
  customQueryInput: AgDropdownConfig;
  apiScheduleInput: AgDropdownConfig;
  adhocReportInput: AgDropdownConfig;
  guestSmsToggleInput: AgToggleConfig;
  userEmailToggleInput: AgToggleConfig;
  guestToggleInput: AgToggleConfig;
  userSmsToggleInput: AgToggleConfig;
  guestEmailDLSource: Observable<DropdownOptions[]>;
  guestEmailDLSourceConfig: AgDropdownConfig;
  guestSMSDLSource: Observable<DropdownOptions[]>;
  guestSmsDLSourceConfig: AgDropdownConfig;
  guestEmailTemplateSource: Promise<DropdownOptions[]>;
  guestEmailTemplateSourceConfig: AgDropdownConfig;
  guestSMSTemplateSource: Promise<DropdownOptions[]>;
  guestSMSTemplateSourceConfig: AgDropdownConfig;
  userEmailDLSource: Observable<DropdownOptions[]>;
  userEmailDLSourceConfig: AgDropdownConfig;
  sftpSetupSource: Promise<DropdownOptions[]>;
  SFTPSetupSourceConfig: AgDropdownConfig;
  userSMSDLSource: Observable<DropdownOptions[]>;
  userSMSDLSourceConfig: AgDropdownConfig;
  userEmailTemplateSource: Promise<DropdownOptions[]>;
  userEmailTemplateSourceConfig: AgDropdownConfig;
  userSMSTemplateSource: Promise<DropdownOptions[]>;
  userSMSTemplateSourceConfig: AgDropdownConfig;
  isReportSelected = true;
  isExecuteQuerySelected : boolean = false;
  isAPISelected:boolean = false;
  isGenericExtractSelected:boolean = false;
  reportSource: Promise<DropdownOptions[]>;
  executeQueryConfig : ExecuteQuery
  reportSourceConfig: AgDropdownConfig;
  displayGuestNotifyConfig = false;
  reportSelector: any[] = [];
  customQuerySource = [];
  hideReportConfigSaveBtn = false;
  showSlide = false;
  reportName: AgInputFieldConfig;
  isReportConfigured = false;
  header: string;
  isUserEmail = false;
  isGuestEmail = false;
  isSFTP = false;
  isFTP = false;
  sftpOption : any;
  guestEmailSubjConfig: AgInputFieldConfig;
  sftpRemoteDirectory: AgInputFieldConfig;
  ftpRemoteDirectory: AgInputFieldConfig;
  userEmailSubjConfig: AgInputFieldConfig;
  displayuserSMS: boolean = false;
  scheduleJob: any[];
  customQueryGroups: CustomQueryUI.CustomQueryGroup[];
  selectedSchedule: number;
  displayGuestConfig: boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() inputData: any;
  @Output() formReady: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() editform: EventEmitter<UntypedFormGroup> = new EventEmitter();
  isAdhocReportSelected: boolean = false;
  adhocReportModelConfig: UI.ScheduleAdhocReport;
  SFTPSetupInput: AgDropdownConfig;
  @Output() notifyReportAPIOptions: EventEmitter<ReportAPIOptions> = new EventEmitter();
  @Output() reportOptionChange: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() editSlider = new EventEmitter();
  @Output() emitReportSelector = new EventEmitter();
  @Output() retailReportEmitter = new EventEmitter();
  @Output() slideEmit = new EventEmitter();
  report : any;
  productId: number;
  mode: number;
  ScheduleJobConfig = ScheduleJobConfig;
  ApiConfig = ApiConfig;
  isAutoARInvoiceEnabled : boolean = false;
  accountingReport: boolean =false;
  golfReport: boolean = false;
  @Input('report')
  set setInputs(value){
    this.report = value;
  }
  @Input('dropDownEmit')
  set setdropdownConfig(value) {
    if(value){
      this.displayReportConfiguration(value);
    }
  }
  @Input('saveReportConfig')
  set setsaveReportConfig(value){
    if (value)
    this.saveReportConfig(value);
  }
  @Input('crudInput')
  set setcrudInput(value){
    if(value){
      this.mode = value.mode;
    }
  }


  addButton: ButtonValue;
  cancelButton: ButtonValue;
  showCards : boolean = false;
  allDatas : any[] =[];
  sliceValue : number;
  reportTypeSource : any;
  genericTypeSource: any;
  adhocReportSource: Promise<DropdownOptions[]>;
  index : number;
  fromEdit : boolean = false;
  editdynamicForm: any[]=[];
  generateId =0;
  reportId ;
  patchReportValues: any = {
  reportCode:'',
  reportName:'',
  filters : ''
};

  isRetailReport: boolean = false;
  isSpaReport: boolean = false;
  reportGroupName: string;
    constructor(private localization: Localization, private fb: UntypedFormBuilder,
    private business: EventDetailsBusiness, private utilities: CommonUtilities,
    public datepipe: DatePipe,
    private retailLocalization: Localization) {
    this.captions = this.localization.captions.ReportSchedular;
    this.productId =  parseInt(this.localization.GetPropertyInfo('ProductId'));
  }

  async ngOnInit() {
    this.buildForm();
    this.initializeInputs();
    this.localization.isFromJobScheduler = true;
    this.retailLocalization.isFromJobScheduler = true;
    this.utilities.isFromJobScheduler = true;
    let scheduleJobWildCards = await this.business.getAllScheduleJobWildCard();
    this.localization.scheduleJobWildCards = scheduleJobWildCards;
    this.retailLocalization.scheduleJobWildCards = scheduleJobWildCards;
    this.patchFormValue();
    this.loadRequiredFields();

  }

  buildForm() {
    this.eventDetailForm = this.fb.group({
      id: 0,
      event: '',
      report: '',
      type : '',
      customQuery : '',
      reportName: '',
      reportLabel: '',
      active: false,
      sftp:'',
      ftp:'',
      sftpRemoteDirectory: '',
      SFTPSetup: [''],
      ftpRemoteDirectory:'',
      guestEmail: '',
      guestEmailSubj: '',
      guestSms: '',
      userEmail: '',
      userEmailSubj: '',
      userSMS: '',
      guestEmailDL: '',
      guestSMSDL: '',
      guestEmailTemplate: '',
      guestSMSTemplate: '',
      userEmailDL: [''],
      userSMSDL: '',
      userEmailTemplate: [''],
      userSMSTemplate: '',
      operationSchedule:'',
      schedulerCode:'',
      schedulerName: '',
      adhocReportScheduler: ''
    });

    this.formReady.emit(this.eventDetailForm);
  }

  initializeInputs() {
    this.addButton = {
      type: 'primary',
      label: this.captions.btn_createSegment1,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.lbl_resetFilter,
      disabledproperty: false
    };
    this.eventSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'event',
      selectOptions: this.eventSource,
      placeHolderId: 'lbl_events',
      placeHolder: this.captions.lbl_events,
      className: 'ag_form-control--1',
      isMultiSelect: false,
      showRequired: true,
      defaultSelectededOptionValue: { id: 2, value: 2, viewValue: "Reports" }
    };
    this.reportTypeConfig = {
      form: this.eventDetailForm,
      formControlName: 'type',
      selectOptions: this.reportTypeSource,
      placeHolderId: 'lbl_type',
      placeHolder: "Report type",
      className: 'ag_form-control--1',
      isMultiSelect: false,
      showRequired: true,
      defaultSelectededOptionValue: { id: 0, value: 0, viewValue: "PDF" }
    }
    this.genericTypeConfig = {
      form: this.eventDetailForm,
      formControlName: 'type',
      selectOptions: this.genericTypeSource,
      placeHolderId: 'lbl_type',
      placeHolder: "Generic type",
      className: 'ag_form-control--1',
      isMultiSelect: false,
      showRequired: true,
      defaultSelectededOptionValue: { id: 0, value: 0, viewValue: "PDF" }
    }
    this.customQueryInput = {
      form: this.eventDetailForm,
      formControlName: 'customQuery',
      selectOptions: this.reportTypeSource,
      placeHolderId: 'lbl_customQuery',
      placeHolder: "Saved Queries",
      className: 'ag_form-control--1',
      isMultiSelect: false,
      showRequired: true,
      defaultSelectededOptionValue: null
    };
    this.apiScheduleInput = {
      form: this.eventDetailForm,
      formControlName: 'operationSchedule',
      selectOptions: this.reportTypeSource,
      placeHolderId: 'lbl_OperationSchedule',
      placeHolder: this.captions.lbl_OperationSchedule,
      className: 'ag_form-control--1',
      isMultiSelect: false,
      showRequired: true,
      defaultSelectededOptionValue: null
    };
    this.adhocReportInput = {
      form: this.eventDetailForm,
      formControlName: 'adhocReportScheduler',
      selectOptions: this.adhocReportSource,
      placeHolderId: '',
      placeHolder: "Adhoc Report",
      className: 'ag_form-control--1',
      isMultiSelect: false,
      showRequired: true,
      defaultSelectededOptionValue: null
    }
    this.guestEmailToggleInput = {
      group: this.eventDetailForm,
      formControlName: 'guestEmail',
      // label: this.captions.lbl_email,
      labelID: 'guestEmail',
      // horizontal: true,
      disabled : true
    };
    this.sftpToggleInput = {
      group: this.eventDetailForm,
      formControlName: 'sftp',
      // label: this.captions.lbl_sftp,
      labelID: 'lbl_sftp',
      disabled : false
    };
    this.ftpToggleInput = {
      group: this.eventDetailForm,
      formControlName: 'ftp',
      // label: this.captions.lbl_sftp,
      labelID: 'lbl_ftp',
      disabled : false
    };
    this.guestSmsToggleInput = {
      group: this.eventDetailForm,
      formControlName: 'guestSms',
      // label: this.captions.lbl_sms,
      labelID: 'guestSms',
      horizontal: true,
      disabled : true
    };
    this.userEmailToggleInput = {
      group: this.eventDetailForm,
      formControlName: 'userEmail',
      // label: this.captions.lbl_email,
      labelID: 'userEmail',
      // horizontal: true,
    };
    this.userSmsToggleInput = {
      group: this.eventDetailForm,
      formControlName: 'userSMS',
      // label: this.captions.lbl_sms,
      labelID: 'userSMS',
      horizontal: true
    };
    this.guestToggleInput = {
      group: this.eventDetailForm,
      formControlName: 'active',
      // label: this.captions.lbl_chkGuest,
      labelID: 'guest',
      horizontal: true
    }
    this.guestEmailDLSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'guestEmailDL',
      selectOptions: this.guestEmailDLSource,
      placeHolderId: 'lbl_guestEmailDistributionList',
      placeHolder: this.captions.lbl_guestEmailDistributionList,
      className: 'ag_w--100 ',
      isMultiSelect: false,
      showRequired: false
    };
    this.guestSmsDLSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'guestSMSDL',
      selectOptions: this.guestSMSDLSource,
      placeHolderId: 'lbl_guestSmsDistributionList',
      placeHolder: this.captions.lbl_guestSmsDistributionList,
      className: 'ag_w--100 ',
      isMultiSelect: false,
      showRequired: false
    };
    this.guestEmailTemplateSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'guestEmailTemplate',
      selectOptions: this.guestEmailTemplateSource,
      placeHolderId: 'lbl_guestEmailTemplate',
      placeHolder: this.captions.lbl_guestEmailTemplate,
      className: 'ag_w--100 ',
      isMultiSelect: false,
      showRequired: false
    };
    this.guestSMSTemplateSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'guestSMSTemplate',
      selectOptions: this.guestSMSTemplateSource,
      placeHolderId: 'lbl_guestSmsTemplate',
      placeHolder: this.captions.lbl_guestSmsTemplate,
      className: 'ag_w--100 ',
      isMultiSelect: false,
      showRequired: false
    };
    this.userEmailDLSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'userEmailDL',
      selectOptions: this.userEmailDLSource,
      placeHolderId: 'lbl_userEmailDistributionList',
      placeHolder: this.captions.lbl_userEmailDistributionList,
      className: 'ag_w--100 ',
      isMultiSelect: false,
      showRequired: true
    };
    this.SFTPSetupSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'SFTPSetup',
      selectOptions: this.sftpSetupSource,
      placeHolderId: '',
      placeHolder: "SFTP Connection",
      className: 'ag_form-control--1',
      isMultiSelect: false,
      showRequired: false,
      isFirstEmpty: false
    };
    this.userSMSDLSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'userSMSDL',
      selectOptions: this.userSMSDLSource,
      placeHolderId: 'lbl_userSmsDistributionList',
      placeHolder: this.captions.lbl_userSmsDistributionList,
      className: 'ag_w--100 ',
      isMultiSelect: false,
      showRequired: true
    };
    this.userEmailTemplateSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'userEmailTemplate',
      selectOptions: this.userEmailTemplateSource,
      placeHolderId: 'lbl_userEmailTemplate',
      placeHolder: this.captions.lbl_userEmailTemplate,
      className: 'ag_w--100 ',
      isMultiSelect: false,
      showRequired: true
    };
    this.userSMSTemplateSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'userSMSTemplate',
      selectOptions: this.userSMSTemplateSource,
      placeHolderId: 'lbl_userSmsTemplate',
      placeHolder: this.captions.lbl_userSmsTemplate,
      className: 'ag_w--100 ',
      isMultiSelect: false,
      showRequired: true
    };
    this.reportSourceConfig = {
      form: this.eventDetailForm,
      formControlName: 'report',
      selectOptions: this.reportSource,
      placeHolderId: 'lbl_jobScheduler_Report',
      placeHolder: this.captions.lbl_jobScheduler_Report,
      className: 'ag_w--100',
      isMultiSelect: false,
      showRequired: false
    };
    this.reportName = {
      className: '',
      form: this.eventDetailForm,
      formControlName: 'reportName',
      placeHolderId: 'lbl_jobScheduler_ReportName',
      placeHolder: this.captions.lbl_jobScheduler_ReportName,
      showRequired: false,
      inputType: '',
      minlength: 1,
      maxlength: 250,
      readonly: true
    };
    this.userEmailSubjConfig = {
      className: '',
      form: this.eventDetailForm,
      formControlName: 'userEmailSubj',
      placeHolderId: 'lbl_jobScheduler_Subject',
      placeHolder: this.captions.lbl_jobScheduler_Subject,
      showRequired: false,
      inputType: '',
      minlength: 1,
      maxlength: 250
    };
    this.guestEmailSubjConfig = {
      className: '',
      form: this.eventDetailForm,
      formControlName: 'guestEmailSubj',
      placeHolderId: 'lbl_jobScheduler_Subject',
      placeHolder: this.captions.lbl_jobScheduler_Subject,
      showRequired: false,
      inputType: '',
      minlength: 1,
      maxlength: 250
    };
    this.sftpRemoteDirectory = {
      className: '',
      form: this.eventDetailForm,
      formControlName: 'sftpRemoteDirectory',
      placeHolderId: 'lbl_RemoteDirectory',
      placeHolder: this.captions.lbl_RemoteDirectory,
      showRequired: false,
      inputType: '',
      minlength: 1,
      maxlength: 250
    };
    this.ftpRemoteDirectory = {
      className: '',
      form: this.eventDetailForm,
      formControlName: 'ftpRemoteDirectory',
      placeHolderId: 'lbl_RemoteDirectory',
      placeHolder: this.captions.lbl_RemoteDirectory,
      showRequired: false,
      inputType: '',
      minlength: 1,
      maxlength: 250
    };
    this.initializeSelectOption();
  }

  async initializeSelectOption() {
    this.utilities.ToggleLoader(true);
    this.sftpSetupSource = this.business.getAllSFTPConnectionSetup();
    this.sftpSetupSource.then(res => {
      this.sftpOption = res;
    })
    this.reportSource = this.business.getReportList(this.report);
    this.reportSourceConfig.selectOptions = this.reportSource;
    this.reportSourceConfig = { ...this.reportSourceConfig };
    this.eventSource = this.business.getAllScheduleJob();    
    this.SFTPSetupSourceConfig.selectOptions = this.sftpSetupSource;
    this.SFTPSetupSourceConfig = { ...this.SFTPSetupSourceConfig };
    let eventSourceOptions = [];
    this.eventSource.then(y => {
      if (this.productId === 1) {
        eventSourceOptions = y.filter(x => x.id === 2);
        this.eventSourceConfig.selectOptions = of(eventSourceOptions);
        this.eventSourceConfig = { ...this.eventSourceConfig };
      } else {
        if (this.inputData?.id > 0) {
          eventSourceOptions = y.filter(x => x.id === this.inputData?.event?.id);
          this.eventSourceConfig.defaultSelectededOptionValue = eventSourceOptions[0];
          this.eventSourceConfig.selectOptions = of(eventSourceOptions);
        }
        else
          this.eventSourceConfig.selectOptions = this.eventSource; this.eventSourceConfig = { ...this.eventSourceConfig };
      }
    })
    let reportTypeSourceOptions = [];
    let genericTypeSourceOptions = [];
    let adhocReportTypeSourceOptions = [];
    this.reportTypeSource = this.business.getAllReportType();
    this.genericTypeSource = this.business.getGenericExtractDownloadType();
    this.adhocReportSource = this.business.getadhocReportType();
   if (this.inputData?.id > 0)
    {
      this.reportTypeSource = this.business.getAllReportType();
      this.reportTypeSource.then(y =>
      {
        reportTypeSourceOptions = y.filter(x => x.id === this.inputData?.type?.id);
        this.reportTypeConfig.defaultSelectededOptionValue = reportTypeSourceOptions[0];
        this.reportTypeConfig.selectOptions = this.reportTypeSource;
        this.reportTypeConfig = { ...this.reportTypeConfig };
      });
      this.genericTypeSource = this.business.getGenericExtractDownloadType();
      this.genericTypeSource.then(y =>
        {
          genericTypeSourceOptions = y.filter(x => x.id === this.inputData?.type?.id);
          this.genericTypeConfig.defaultSelectededOptionValue = genericTypeSourceOptions[0];
          this.genericTypeConfig.selectOptions = this.genericTypeSource;
          this.genericTypeConfig = { ...this.genericTypeConfig };
      });

       this.adhocReportSource.then(y => {
         adhocReportTypeSourceOptions = y.filter(x => x.id === this.inputData?.type?.id);
         this.adhocReportInput.defaultSelectededOptionValue = adhocReportTypeSourceOptions[0];
         this.adhocReportInput.selectOptions = this.adhocReportSource;
         this.adhocReportInput = { ...this.adhocReportInput };
       });

    }
    else
    {
      this.reportTypeConfig.selectOptions = this.reportTypeSource;
      this.reportTypeConfig = { ...this.reportTypeConfig };

      this.genericTypeConfig.selectOptions = this.genericTypeSource;
      this.genericTypeConfig = { ...this.genericTypeConfig };

     this.adhocReportInput.selectOptions = this.adhocReportSource;
     this.adhocReportInput = { ...this.adhocReportInput };
    }
    const distributionList: Distribution[] = await this.business.getDistributionList();
    this.guestEmailDLSource = this.userEmailDLSource = of(this.business.getDistributionListByType(distributionList, DistributionType.EMAIL));
    this.guestEmailDLSourceConfig.selectOptions = this.guestEmailDLSource;
    this.guestEmailDLSourceConfig = { ...this.guestEmailDLSourceConfig };
    this.userEmailDLSourceConfig.selectOptions = this.userEmailDLSource;
    this.userEmailDLSourceConfig = { ...this.userEmailDLSourceConfig };
    this.guestSMSDLSource = this.userSMSDLSource = of(this.business.getDistributionListByType(distributionList, DistributionType.SMS));
    this.guestSmsDLSourceConfig.selectOptions = this.guestSMSDLSource;
    this.guestSmsDLSourceConfig = { ...this.guestSmsDLSourceConfig };
    this.userSMSDLSourceConfig.selectOptions = this.userSMSDLSource;
    this.userSMSDLSourceConfig = { ...this.userSMSDLSourceConfig };
    this.guestEmailTemplateSource = this.userEmailTemplateSource = this.business.getGuestEmailTemplateSourceList();
    this.guestEmailTemplateSourceConfig.selectOptions = this.guestEmailTemplateSource;
    this.guestEmailTemplateSourceConfig = { ...this.guestEmailTemplateSourceConfig };
    this.userEmailTemplateSourceConfig.selectOptions = this.userEmailTemplateSource;
    this.userEmailTemplateSourceConfig = { ...this.userEmailTemplateSourceConfig };
    this.guestSMSTemplateSource = this.userSMSTemplateSource = this.business.getGuestSMSTemplateSourceList();
    this.guestSMSTemplateSourceConfig.selectOptions = this.guestSMSTemplateSource;
    this.guestSMSTemplateSourceConfig = { ...this.guestSMSTemplateSourceConfig };
    this.userSMSTemplateSourceConfig.selectOptions = this.userSMSTemplateSource;
    this.userSMSTemplateSourceConfig = { ...this.userSMSTemplateSourceConfig };
    this.utilities.ToggleLoader(false);
  }

  patchFormValue() {
    if (this.inputData) {
      if(this.localization.isFromJobScheduler){
        if(!this.inputData.reportName)
        {
          const selector = new ReportSelectorBuilder(this.localization);
          if (this.inputData.patchReportConfiguration?.reportGroup)
          {
            this.reportSelector = selector.getReportSelections(this.inputData.patchReportConfiguration?.reportGroup);
            let reportSelectorCodevalue = this.inputData.patchReportConfiguration.reportAPIOptions.filterBody?.reportSelectorCode;
            if (reportSelectorCodevalue) {
              this.inputData.reportName = this.reportSelector.find(o => o.code === reportSelectorCodevalue)?.value;
              this.inputData.reportCard.reportName = this.reportSelector.find(o => o.code === reportSelectorCodevalue)?.value;
            }
          }
        }
      }
      this.eventDetailForm.patchValue(this.inputData);
      if(this.inputData.scheduleOperationConfiguration != null){
        if(this.inputData.scheduleOperationConfiguration.methodName == AutoARInvoice){
          this.isAutoARInvoiceEnabled = true;
          this.eventDetailForm.controls.schedulerCode.setValue(this.inputData.scheduleOperationConfiguration.code);
          this.eventDetailForm.controls.schedulerName.setValue(this.inputData.scheduleOperationConfiguration.description);
        }       
      }
      
      this.orchestrateGuestNotifyConfigOptions(this.inputData.active, true);
      this.toggleReportOption(this.inputData.event);
      this.isReportSelected ? this.isReportConfigured = true : this.isReportConfigured = false;
      this.isSFTP = this.inputData.isSFTP;      
    if(this.inputData.isSFTP){
      this.eventDetailForm.controls.sftp.setValue(true);
      this.sftpPatch(this.inputData.sftpSetupId);
    }
      if(this.inputData.event.id == Number(ScheduleJobConfig.ExecuteQuery) || this.inputData.event.id == Number(ScheduleJobConfig.API))
      {
        this.loadData(this.inputData);
      }
      else
      {
      const filterBodyValues = this.inputData.patchReportConfiguration.reportAPIOptions.parameters.PatchEditParam ?
      this.inputData.patchReportConfiguration.reportAPIOptions.parameters
      : (this.inputData.patchReportConfiguration?.reportAPIOptions?.filterBody && typeof this.inputData.patchReportConfiguration?.reportAPIOptions?.filterBody === 'string'
      ? JSON.parse(this.inputData.patchReportConfiguration?.reportAPIOptions?.filterBody)
      : JSON.parse(JSON.stringify(this.inputData.patchReportConfiguration?.reportAPIOptions?.filterBody ? this.inputData.patchReportConfiguration?.reportAPIOptions?.filterBody: '')));

      let FromWildCardName;
      let ToWildCardName;
      if(filterBodyValues.fromWildCard)
        FromWildCardName = this.getWildCardName(filterBodyValues.fromWildCard);
      else
        FromWildCardName = "";

      if(filterBodyValues.toWildCard)
        ToWildCardName = this.getWildCardName(filterBodyValues.toWildCard);
      else
        ToWildCardName = "";

      if(this.isReportSelected){
        this.showCards = true;
        this.editdynamicForm.push({
          id : this.inputData.reportCard.id,
          value : this.inputData.reportCard.value,
          reportId : this.inputData.reportCard.reportModule,
          reportName : this.inputData.patchReportConfiguration.reportAPIOptions.code,
          reportLabel: this.inputData.patchReportConfiguration.reportAPIOptions?.name ?? this.inputData.patchReportConfiguration.reportAPIOptions.code,
          report : this.inputData.reportCard.reportModuleName,
          fromWildCard : FromWildCardName,
          fromWildCardCount : filterBodyValues.fromIncrement ? filterBodyValues.fromIncrement : 0,
          toWildCard : ToWildCardName,
          toWildCardCount : filterBodyValues.toIncrement ? filterBodyValues.toIncrement : 0,
          jobSchedulerId: this.inputData.jobSchedulerId
        });
      }
      this.loadData(this.inputData);
      if(this.inputData.patchReportConfiguration){
        this.editdynamicForm[0].value = this.inputData.patchReportConfiguration.reportAPIOptions;
        this.notifyReportAPIOptions.emit(this.inputData.patchReportConfiguration.reportAPIOptions);
      } else {
        this.notifyReportAPIOptions.emit(null);
      }
    }
      this.eventDetailForm.markAsPristine();
    }
     else {
      this.notifyReportAPIOptions.emit(null);
    }
  }

 async sftpPatch(id)
 {
    this.sftpOption = await this.business.getAllSFTPConnectionSetup();
    if(id)
    {
      let sftpData = this.sftpOption.find(x => x.id == id);
        this.eventDetailForm.controls.SFTPSetup.setValue(sftpData);
    }
 }

  loadData(e){
    this.isUserEmail=e.userEmail;
    this.displayuserSMS=e.userSMS;
    this.isGuestEmail=e.guestEmail;
    this.displayGuestConfig=e.guestSms;    
  }
  async loadRequiredFields() {
    this.scheduleJob = await this.business.getAllScheduleJobs()
    this.selectedSchedule = Number(this.eventSourceConfig.defaultSelectededOptionValue.id);
    this.setRequiredFields(Number(this.eventSourceConfig.defaultSelectededOptionValue.id));
  }

 async  toggleReportOption(e) {
  if(e.value == API) {
    if(e.value ==API && this.eventDetailForm.value.operationSchedule.value == AutoARInvoice)    
    {
      this.isAutoARInvoiceEnabled = true;
      this.eventDetailForm.controls.schedulerCode.setValidators([Validators.required]);
      this.eventDetailForm.controls.schedulerCode.updateValueAndValidity();
      this.eventDetailForm.controls.schedulerName.setValidators([Validators.required]);
      this.eventDetailForm.controls.schedulerName.updateValueAndValidity();
    }
  }
  else {
    this.isAutoARInvoiceEnabled = false;
    this.eventDetailForm.controls.schedulerCode.setValidators(null);
    this.eventDetailForm.controls.schedulerCode.updateValueAndValidity();
    this.eventDetailForm.controls.schedulerName.setValidators(null);
    this.eventDetailForm.controls.schedulerName.updateValueAndValidity();
    this.eventDetailForm.controls.userEmailDL.setValidators([Validators.required]);
    this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
    this.eventDetailForm.controls.userEmailTemplate.setValidators([Validators.required]);
    this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
  }
    this.isReportSelected = e.id && e.id === Number(ScheduleJobConfig.Reports) ? true : false;
    this.isExecuteQuerySelected = e.id && e.id === Number(ScheduleJobConfig.ExecuteQuery) ? true : false;
    this.isAPISelected = e.id && e.id === Number(ScheduleJobConfig.API);
   this.isGenericExtractSelected = e.id && e.id === Number(ScheduleJobConfig.GenericExtract);
   this.isAdhocReportSelected = e.id && e.id === Number(ScheduleJobConfig.AdhocReport);
    this.selectedSchedule = e.id;
    if(e.id && e.id === Number(ScheduleJobConfig.GenericExtract))
   {
    this.genericTypeSource = this.business.getGenericExtractDownloadType();
    this.notifyReportAPIOptions.emit(e);
    this.genericTypeConfig.selectOptions = this.genericTypeSource;
    this.genericTypeConfig = { ...this.genericTypeConfig };
   }
    this.setRequiredFields(e.id);
   if (this.isReportSelected) {
     var formats = await this.business.getAllReportType();
     this.loadFormats(formats);
   }
    if(e.id && e.id === Number(ScheduleJobConfig.ExecuteQuery))
    {
      await this.getAllCustomQueryGroups();
      this.notifyReportAPIOptions.emit(e);
      if(this.inputData?.id > 0)
      {
        let queryId = this.inputData.patchExecuteQueryConfiguration?.queryId;
        let customQueryOptions = this.customQuerySource.filter(x => x.value === queryId);
        this.customQueryInput.defaultSelectededOptionValue = customQueryOptions[0];
        this.customQueryInput.selectOptions = of(this.customQuerySource);
        this.customQueryInput = { ... this.customQueryInput };
      }
      else
      {
        this.customQueryInput.selectOptions = of(this.customQuerySource);
        this.customQueryInput = { ... this.customQueryInput };
      }
    }
   if (this.isAPISelected && e.id === Number(ScheduleJobConfig.API)) {
     var formats = await this.business.getAllReportType();
     var excludeFormats = [8];
     this.loadFormats(formats.filter(f => !excludeFormats.includes(Number(f.id))));
     this.eventSource = this.business.getAllScheduleOperations();
     this.notifyReportAPIOptions.emit(e);
     if (this.inputData?.id) {
       const methodName = this.inputData?.scheduleOperationConfiguration.methodName;
       const scheduleOperationsDropDownData = await this.eventSource;
       const selectedScheduleOperation = scheduleOperationsDropDownData.filter(op => op.value == methodName);
       this.apiScheduleInput.defaultSelectededOptionValue = selectedScheduleOperation[0];
       this.apiScheduleInput.selectOptions = this.eventSource;
       this.apiScheduleInput = { ...this.apiScheduleInput };
     }
     else {
       this.apiScheduleInput.selectOptions = this.eventSource;
       this.apiScheduleInput = { ...this.apiScheduleInput };
     }
     if(this.apiScheduleInput.defaultSelectededOptionValue.id == Number(ApiConfig.AutoARInvoice) &&
     +this.localization.GetPropertyInfo('ProductId') !== Number(Product.PMS)) {
      this.isAutoARInvoiceEnabled = true
      this.reportTypeConfig.disabled = true;
      this.reportTypeConfig = {...this.reportTypeConfig}
      this.userEmailToggleInput.disabled = true
      this.userEmailToggleInput = {...this.userEmailToggleInput}
      this.sftpToggleInput.disabled = true;
      this.sftpToggleInput = {...this.sftpToggleInput}
      this.ftpToggleInput.disabled = true;
      this.ftpToggleInput = {...this.ftpToggleInput}
      this.eventDetailForm.controls.userEmailDL.clearValidators();
      this.eventDetailForm.controls.userEmailTemplate.clearValidators();
      this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
      this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
      this.eventDetailForm.controls.SFTPSetup.clearValidators();
      this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();
      this.isUserEmail = false;
      this.isSFTP = false;
      this.isFTP = false;
  }
   }
   if (this.isAdhocReportSelected && e.id === Number(ScheduleJobConfig.AdhocReport)) {
     this.notifyReportAPIOptions.emit(e);
     if (this.inputData?.id) {
       const lableName = this.inputData?.scheduleadhocReportConfiguration.labelName;
       const scheduladhocReportDropDownData = await this.business.getadhocReportType();
       const selectedScheduleOperation = scheduladhocReportDropDownData.filter(op => op.viewValue == lableName);
       this.adhocReportInput.defaultSelectededOptionValue = selectedScheduleOperation[0];
       this.adhocReportInput.selectOptions = this.adhocReportSource;
       this.adhocReportInput = { ...this.adhocReportInput };
     }
     else {
       this.adhocReportInput.selectOptions = this.adhocReportSource;
       this.adhocReportInput = { ...this.adhocReportInput };
     }
   }
  }

  async getAllCustomQueryGroups() {

    await this.business.getAllCustomQueryGroups().then(result => {
      this.customQueryGroups = result;

      let k = 0;
      for (let i = 0; i < result.length; ++i) {
        if(result[i] && result[i].groupName != "GenericExtract"){
          for (let j = 0; j < result[i].queryListItem.length; ++j) {
            k++;
            this.customQuerySource.push({
              id: k,
              value: result[i].queryListItem[j].id,
              viewValue: result[i].queryListItem[j].name,
              otherData: result[i].groupName,
              disabled: false
            });
          }
        }
      }
    });
  }

  customQueryChange(event)
  {
    if(event)
    {
    this.executeQueryConfig = {
      queryId : event.value,
      queryName : event.viewValue,
      moduleName : event.otherData
    }
    this.notifyReportAPIOptions.emit(event);
    }
  }

  apiScheduleChange(event) {
    this.notifyReportAPIOptions.emit(event);
    if(event.value == AutoARInvoice){//ApiConfig.AutoARInvoice) {
      this.isAutoARInvoiceEnabled = true;
      this.eventDetailForm.controls.schedulerCode.setValidators([Validators.required]);
      this.eventDetailForm.controls.schedulerCode.updateValueAndValidity();
      this.eventDetailForm.controls.schedulerName.setValidators([Validators.required]);
      this.eventDetailForm.controls.schedulerName.updateValueAndValidity();
      

      this.eventDetailForm.controls.userEmail.setValue(false)
      this.eventDetailForm.controls.userEmailDL.setValue('');
      this.eventDetailForm.controls.userEmailTemplate.setValue('');
      this.eventDetailForm.controls.userEmailSubj.setValue('');

      this.eventDetailForm.controls.sftp.setValue(false)
      this.eventDetailForm.controls.sftpRemoteDirectory.setValue('');
      this.eventDetailForm.controls.SFTPSetup.setValue('');
      this.eventDetailForm.controls.ftp.setValue(false)
      this.eventDetailForm.controls.ftpRemoteDirectory.setValue('');
      this.reportTypeConfig.disabled = true;
      this.reportTypeConfig = {...this.reportTypeConfig}
      this.userEmailToggleInput.disabled = true
      this.userEmailToggleInput = {...this.userEmailToggleInput}
      this.sftpToggleInput.disabled = true;
      this.sftpToggleInput = {...this.sftpToggleInput}
      this.ftpToggleInput.disabled = true;
      this.ftpToggleInput = {...this.ftpToggleInput}
      this.eventDetailForm.controls.userEmailDL.clearValidators();
      // this.eventDetailForm.controls.userEmailTemplate.disable();
      this.eventDetailForm.controls.userEmailTemplate.clearValidators();
      this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
      this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
      this.eventDetailForm.controls.SFTPSetup.clearValidators();
      this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();

      this.isUserEmail = false;
      this.isSFTP = false;
      this.isFTP = false;
    }
    else {
      this.isAutoARInvoiceEnabled = false;
      this.eventDetailForm.controls.schedulerCode.setValue('')
      this.eventDetailForm.controls.schedulerName.setValue('')
      this.eventDetailForm.controls.schedulerCode.setValidators(null);
      this.eventDetailForm.controls.schedulerCode.updateValueAndValidity();
      this.eventDetailForm.controls.schedulerName.setValidators(null);
      this.eventDetailForm.controls.schedulerName.updateValueAndValidity();

      this.reportTypeConfig.disabled = false;
      this.reportTypeConfig = {...this.reportTypeConfig}
      this.userEmailToggleInput.disabled = false
      this.userEmailToggleInput = {...this.userEmailToggleInput}
      this.sftpToggleInput.disabled = false;
      this.sftpToggleInput = {...this.sftpToggleInput}
      this.ftpToggleInput.disabled = false;
      this.ftpToggleInput = {...this.ftpToggleInput}
      this.eventDetailForm.controls.userEmailDL.clearValidators();      
      this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
      this.eventDetailForm.controls.userEmailTemplate.clearValidators();
      this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
      // this.eventDetailForm.controls.SFTPSetup.setValidators([Validators.required]);
      // this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();
    }
  }

  adhocReportChange(event) {
    if (event) {
      this.adhocReportModelConfig = {
        labelName: event.value,
        adhocReportURI: event.viewValue
      }
      this.notifyReportAPIOptions.emit(event);
    }
  }

  loadFormats(formats: DropdownOptions[]){
    this.reportTypeSource =  new Promise((resolve) => { resolve(formats); });
    this.reportTypeConfig.selectOptions = this.reportTypeSource;
    this.reportTypeConfig = { ...this.reportTypeConfig };
  }

  async setRequiredFields(id:number){

      const value=this.scheduleJob?.find(y=>y.id==id);
      if(this.inputData){
        this.ToggleEmail(this.inputData.userEmail);
      }else{
        this.ToggleEmail(false);
      }
      if (value) {
        this.ToggleSMS(value.isSMS);
        if (!this.guestSmsToggleInput.disabled) {
          this.ToggleGuestEmail(value.isEmail);
          this.ToggleGuestSMS(value.isSMS);
        }
      }
      this.formReady.emit(this.eventDetailForm);
  }

  ToggleEmail(enable:boolean){
    if(enable){
      // this.eventDetailForm.controls.userEmailDL.enable();
      // this.eventDetailForm.controls.userEmailTemplate.enable();
      this.userEmailTemplateSourceConfig.showRequired=enable;
      this.userEmailDLSourceConfig.showRequired=enable;
      this.isUserEmail = true;
      this.eventDetailForm.controls.userEmail.setValue(true);
    }
    else{
      // this.eventDetailForm.controls.userEmailDL.disable();
      // this.eventDetailForm.controls.userEmailTemplate.disable();
      this.userEmailTemplateSourceConfig.showRequired=enable;
      this.userEmailDLSourceConfig.showRequired=enable;
      this.eventDetailForm.controls.userEmail.setValue(false);
      this.isUserEmail = false;
    }
  }
  ToggleGuestEmail(enable:boolean){
    if(enable){
      this.guestEmailTemplateSourceConfig.showRequired=enable;
      this.guestEmailDLSourceConfig.showRequired=enable;
      this.eventDetailForm.controls.guestEmailTemplate.enable();
      this.eventDetailForm.controls.guestEmailDL.enable();
      this.eventDetailForm.controls.guest
      this.isGuestEmail = true;
      this.eventDetailForm.controls.guestEmail.setValue(true);
    }
    else{
      this.guestEmailTemplateSourceConfig.showRequired=enable;
      this.guestEmailDLSourceConfig.showRequired=enable;
      this.eventDetailForm.controls.guestEmailTemplate.disable();
      this.eventDetailForm.controls.guestEmailDL.disable();
      this.isGuestEmail = false;
      this.eventDetailForm.controls.guestEmail.setValue(false);


    }
  }
  ToggleGuestSMS(enable:boolean){
    if(enable){
      this.guestSMSTemplateSourceConfig.showRequired=enable;
      this.guestSmsDLSourceConfig.showRequired=enable;
      this.eventDetailForm.controls.guestSMSDL.enable();
      this.eventDetailForm.controls.guestSMSTemplate.enable();
      this.displayGuestConfig = true;
      this.eventDetailForm.controls.guestSms.setValue(true);
    }
    else{
      this.guestSMSTemplateSourceConfig.showRequired=enable;
      this.guestSmsDLSourceConfig.showRequired=enable;
      this.eventDetailForm.controls.guestSMSDL.disable();
      this.eventDetailForm.controls.guestSMSTemplate.disable();
      this.displayGuestConfig = false;
      this.eventDetailForm.controls.guestSms.setValue(false);
    }

  }

  ToggleSMS(enable:boolean){
    if(enable){
        this.displayuserSMS = true;
        this.eventDetailForm.controls.userSMSDL.enable();
        this.eventDetailForm.controls.userSMSTemplate.enable();
        this.userSMSTemplateSourceConfig.showRequired=enable;
        this.userSMSDLSourceConfig.showRequired=enable;
        this.eventDetailForm.controls.userSMS.setValue(true);
      }
      else {
        this.displayuserSMS = false;
        this.eventDetailForm.controls.userSMSDL.disable();
        this.eventDetailForm.controls.userSMSTemplate.disable();
        this.userSMSTemplateSourceConfig.showRequired=enable;
        this.userSMSDLSourceConfig.showRequired=enable;
        this.eventDetailForm.controls.userSMS.setValue(false);

      }
  }

  displayReportConfiguration(e) {
    this.header = this.captions.pg_title_reports;
    this.isRetailReport = false; this.isSpaReport = false;
    this.accountingReport=false; this.golfReport = false;
    this.reportOptionChange.emit(this.eventDetailForm);
    if(e.value.toLowerCase() == 'appointment' || e.value.toLowerCase() == 'client'){
      this.isSpaReport = true;
    }
    if(e.value.toLowerCase()== 'teetime' || e.value.toLowerCase()== 'tournament'){
        this.golfReport =true;
    }
    if(e.value.toLowerCase() == 'retail' || e.value.toLowerCase() == 'giftcards')
    {
      this.isRetailReport = true;
       this.reportGroupName = e.value.toLowerCase();
    }
    if(e.value.toLowerCase()== 'condo')
    {
      this.accountingReport =true;
    }
    if(e.value.toLowerCase() == 'commission' || e.value.toLowerCase() == 'commissiongratuity' || e.value.toLowerCase() == 'commissiongratuityservicecharge')
    {
      this.isRetailReport = true;
      this.reportGroupName = 'commission';
      if(e.value.toLowerCase() == 'commissiongratuityservicecharge'){
        e.value = 'commission';
        e.viewValue = 'Commission/Gratuity/ServiceCharge'
      }     
    }
    // this.showSlide = true;
    if(!this.isRetailReport && !this.isSpaReport)
    {
      const selector = new ReportSelectorBuilder(this.localization);
      this.reportSelector = selector.getReportSelections(e.id);
      this.reportId = {id: e.id, value: e.value, viewValue: e.viewValue};
      this.emitReportSelector.emit(this.reportSelector);
    }
    else{
      this.reportId = { id: e.id, value: e.value, viewValue: e.viewValue };
      this.emitReportSelector.emit(this.reportId);
    }
    this.retailReportEmitter.emit(this.isRetailReport);
  }
  openSlide(){
      //  this.reportOptionChange.emit(this.eventDetailForm);

      this.eventDetailForm.controls.report.setValue("");
      const selector = new ReportSelectorBuilder(this.localization);
      this.reportSelector =[];
      this.fromEdit = false;
      this.hideReportConfigSaveBtn = false;
      this.sliceValue = 1;
       this.showSlide = true;
       this.slideEmit.emit(this.showSlide);
       this.emitReportSelector.emit(this.reportSelector);
       this.editSlider.emit({
        patchReportValues : this.patchReportValues,
        allDatas : this.allDatas,
        fromEdit : this.fromEdit,
        hideReportConfigSaveBtn: this.hideReportConfigSaveBtn,
        reportGroupName : this.reportGroupName,
        reportSourceConfig : this.reportSourceConfig,
        isRetailReport : this.isRetailReport,
        header: this.header
      });
      //  this.eventDetailForm.controls.report.setValue("");
      //  this.eventDetailForm.controls.reportName.setValue("");
      //  const selector = new ReportSelectorBuilder(this.localization);
      //  this.reportSelector = selector.getReportSelections(e.id);
  }
  closeSlide() {
    this.showSlide = false;
    this.slideEmit.emit(this.showSlide);
  }
  viewAll(){
    this.sliceValue = this.editdynamicForm.length;
  }

  getReportCode(reportApiOptionscode){
    switch(reportApiOptionscode){
      case 'TeeSheet_Portrait' :
        return this.reportSelector.find(o => o.code === 'TeeSheet')?.code;
      case 'CartCards_Landscape':
        return this.reportSelector.find(o => o.code === 'CartCards')?.code;
      case 'CartCardsTeeTime_Landscape':
        return  this.reportSelector.find(o => o.code === 'CartCardsForTeeTime')?.code;
      default :
        return this.reportSelector.find(o => o.code === reportApiOptionscode)?.code;
    }
  }
  saveReportConfig(e) {
    let reportApiOptions: any = this.isRetailReport || this.isSpaReport ? e.retailReportAPIOptions : e;
    this.notifyReportAPIOptions.emit(reportApiOptions);
    this.showSlide = false;
    let reportApiOptionscode = reportApiOptions.code;
    this.slideEmit.emit(this.showSlide);
    if(this.localization.isFromJobScheduler){
      if (reportApiOptions.filters) {
        let reportSelectorCodevalue = (typeof reportApiOptions.filters === 'string') ? JSON.parse(reportApiOptions.filters)?.reportSelectorCode : reportApiOptions.filters?.reportSelectorCode;
        if (reportSelectorCodevalue) {
          reportApiOptionscode = reportSelectorCodevalue;
        }
      }
    }
    let reportName;
    if(!this.accountingReport)
    {
      if(this.golfReport){
       reportName = this.getReportCode(reportApiOptionscode);
      }
      else{
        reportName = this.isRetailReport || this.isSpaReport ? e.retailReportSelector.find(o => o.code === reportApiOptionscode)?.code :(reportApiOptionscode !== 'SummaryArrivals'
        ? this.reportSelector.find(o => o.code === reportApiOptionscode)?.code
        : this.reportSelector.find(o => o.code === 'DetailArrivals')?.code);
      }
    }
    else
    {
       reportName = this.getReportName(reportApiOptionscode);
    }
  
    this.eventDetailForm.controls.reportName.setValue(reportName);
    this.eventDetailForm.controls.reportLabel.setValue(reportApiOptions?.name);
    this.reportName = { ...this.reportName };
    this.isReportConfigured = true;
    this.sliceValue = 1;
    this.showCards = true;
    this.editreportConfig(reportApiOptions);
  }
  getReportName(reportApiOptionscode)
  {
    let CondoOwnerRevenueExpense : string[]=[
  'CondoOwnerExpRevDetail',
  'DetailExpRevGrouping',
  'SummaryExpRevGrouping',
  'CondoOwnerExpRevSummary'
]
    let reportName = this.reportSelector.find(o => o.code === reportApiOptionscode)?.code;
    if(reportName)
      return reportName;
    else if(CondoOwnerRevenueExpense.includes(reportApiOptionscode))
    {
      return  this.reportSelector.find(o => o.code === 'CondoOwnerRevenueExpense')?.code;
    }      
  }
  removeItem(i) {
    this.editdynamicForm.splice(i, 1);
    this.editdynamicForm = [];
    this.eventDetailForm.controls.report.setValue("");
    this.patchReportValues = [];
      const selector = new ReportSelectorBuilder(this.localization);
      this.reportSelector =[];
      this.fromEdit = false;
      this.sliceValue = 1;
      this.isRetailReport = false;
      this.reportSourceConfig.defaultSelectededOptionValue =  null;
      this.reportSourceConfig = {...this.reportSourceConfig};
      this.emitReportSelector.emit(this.reportSelector);
       this.editSlider.emit({
        patchReportValues : this.patchReportValues,
        allDatas : this.allDatas,
        fromEdit : this.fromEdit,
        hideReportConfigSaveBtn: this.hideReportConfigSaveBtn,
        reportGroupName : this.reportGroupName,
        reportSourceConfig : this.reportSourceConfig,
        isRetailReport : this.isRetailReport,
        header: this.header
      });
      this.notifyReportAPIOptions.emit(null);
  }
  disableEmailControls(e) {
    if (!e.checked) {
      this.isGuestEmail = false;
    }
    else {
      this.isGuestEmail = true;
    }
  }

  disableSMSControls(e) {
    if (!e.checked) {
      this.eventDetailForm.controls.guestSMSDL.disable();
      this.eventDetailForm.controls.guestSMSDL.clearValidators();
      this.eventDetailForm.controls.guestSMSTemplate.disable();
      this.eventDetailForm.controls.guestSMSTemplate.clearValidators();
      this.displayGuestConfig = false;
    }
    else {
      this.eventDetailForm.controls.guestSMSDL.enable();
      this.eventDetailForm.controls.guestSMSDL.setValidators([Validators.required]);
      this.eventDetailForm.controls.guestSMSTemplate.enable();
      this.eventDetailForm.controls.guestSMSTemplate.setValidators([Validators.required]);
      this.displayGuestConfig = true;
    }
  }

  onEmailToggleChange(e) {
    if (!e.checked) {
      this.eventDetailForm.controls.userEmailDL.setValue('');
      this.eventDetailForm.controls.userEmailTemplate.setValue('');
      this.eventDetailForm.controls.userEmailSubj.setValue('');
      // this.eventDetailForm.controls.userEmailDL.disable();
      // this.eventDetailForm.controls.userEmailTemplate.disable();
      this.eventDetailForm.controls.userEmailDL.clearValidators();
      this.eventDetailForm.controls.userEmailTemplate.clearValidators();
      this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
      this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
      this.eventDetailForm.controls.SFTPSetup.setValidators([Validators.required]);
      this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();
      this.isUserEmail = false;
    }
    else {
      // this.eventDetailForm.controls.userEmailDL.enable();
      this.eventDetailForm.controls.userEmailDL.setValidators([Validators.required]);
      this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
      // this.eventDetailForm.controls.userEmailTemplate.enable();
      this.eventDetailForm.controls.userEmailTemplate.setValidators([Validators.required]);
      this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
      this.eventDetailForm.controls.SFTPSetup.clearValidators();
      this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();
      this.isUserEmail = true;
    }
    this.isEmailSftpChecked();
  }

  onSFTPToggleChange(e){
    this.isSFTP = e.checked;
    if(!e.checked){
      this.eventDetailForm.controls.sftpRemoteDirectory.setValue('');
      //this.eventDetailForm.controls.ftpRemoteDirectory.setValue('');
      this.eventDetailForm.controls.SFTPSetup.setValue('');
      this.eventDetailForm.controls.SFTPSetup.clearValidators();
      this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();

    } else {
      this.eventDetailForm.controls.SFTPSetup.setValidators([Validators.required]);   
      this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();
    }
    this.isEmailSftpChecked();
  }

  onFTPToggleChange(e){
    this.isFTP = e.checked;
    if(!e.checked){
      this.eventDetailForm.controls.ftpRemoteDirectory.setValue('');      
      this.eventDetailForm.controls.userEmailDL.setValidators([Validators.required]);
      this.eventDetailForm.controls.userEmailTemplate.setValidators([Validators.required]);
      this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
      this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
      this.eventDetailForm.controls.SFTPSetup.setValidators([Validators.required]);
      this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();
    } else {
      this.eventDetailForm.controls.userEmailDL.clearValidators();
      this.eventDetailForm.controls.userEmailTemplate.clearValidators();
      this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
      this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
      this.eventDetailForm.controls.SFTPSetup.clearValidators();
      this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();
    }
    this.isEmailSftpChecked();
  }

  isEmailSftpChecked() {
    if (this.isSFTP && this.isUserEmail) {
      this.eventDetailForm.controls.userEmailDL.setValidators([Validators.required]);
      this.eventDetailForm.controls.userEmailTemplate.setValidators([Validators.required]);
      this.eventDetailForm.controls.SFTPSetup.setValidators([Validators.required]);   
    }
    this.eventDetailForm.controls.userEmailDL.updateValueAndValidity();
    this.eventDetailForm.controls.userEmailTemplate.updateValueAndValidity();
    this.eventDetailForm.controls.SFTPSetup.updateValueAndValidity();
  }

  disableUsrSMSControls(e) {
    if (!e.checked) {
      this.displayuserSMS = false;
      this.eventDetailForm.controls.userSMSDL.disable();
      this.eventDetailForm.controls.userSMSDL.clearValidators();
      this.eventDetailForm.controls.userSMSTemplate.disable();
      this.eventDetailForm.controls.userSMSTemplate.clearValidators();
    }
    else {
      this.displayuserSMS = true;
      this.eventDetailForm.controls.userSMSDL.enable();
      this.eventDetailForm.controls.userSMSDL.setValidators([Validators.required]);
      this.eventDetailForm.controls.userSMSTemplate.enable();
      this.eventDetailForm.controls.userSMSTemplate.setValidators([Validators.required]);
    }
  }
  editItem(data,i){
    this.index = i;
    this.fromEdit = true;
    this.hideReportConfigSaveBtn = true;
    this.allDatas = data;
    const selector = new ReportSelectorBuilder(this.localization);
    var selectedReportName = data.reportName;
    if(data.value && data.value.filterBody && data.value.filterBody.reportSelectorCode){
      selectedReportName = data.value.filterBody.reportSelectorCode;
    }
    this.reportSelector = selector.getReportSelections(selectedReportName);
    // this.eventDetailForm.controls['event'].setValue(data.reportId, {onlySelf: true});
    // this.eventDetailForm.get('event').setValue(data.reportId);
    this.eventDetailForm.controls['report'].setValue(data.reportId);
    // this.patchReportValues['jobSchedulerId'] = this.ScheduleJobConfigurationId;
    this.patchReportValues['reportName'] = selectedReportName;
    this.patchReportValues['reportCode'] = data.value.code;

    if(data.reportId?.value.toLowerCase() == 'retail' || data.reportId?.value.toLowerCase() == 'giftcards' || data.reportId?.value.toLowerCase() == 'commissiongratuity')
    {
         this.isRetailReport = true;
    }
    if(data.value && data.value.filters){
      this.patchReportValues['filters'] =  data.value.filters && typeof data.value.filters === 'string'
      ? JSON.parse(data.value.filters)
      : JSON.parse(JSON.stringify(data.value.filters));
    }
    if(data.value && data.value.params){
      this.patchReportValues['params'] =  data.value.params && typeof data.value.params === 'string'
      ? JSON.parse(data.value.params)
      : JSON.parse(JSON.stringify(data.value.params));
    }
    if(data.value && data.value.filterBody){
      this.patchReportValues['filters'] =  data.value.filterBody && typeof data.value.filterBody === 'string'
      ? JSON.parse(data.value.filterBody)
      : JSON.parse(JSON.stringify(data.value.filterBody));
    }
    if(data.value && data.value.parameters){
      this.patchReportValues['params'] =  data.value.parameters && typeof data.value.parameters === 'string'
      ? JSON.parse(data.value.parameters)
      : JSON.parse(JSON.stringify(data.value.parameters));
    }
    if (data?.jobSchedulerId >0 ) {
      this.patchReportValues['jobSchedulerId'] = data.jobSchedulerId;
    }
  else if (this.inputData?.id > 0) {
      this.patchReportValues['jobSchedulerId'] = this.inputData.id;
    }
    //this.patchReportName(data.reportId? data.reportId: '');
    if(this.isRetailReport )
     {
      this.displayReportConfiguration(data.reportId? data.reportId: '');
     }
    this.showSlide = true;
    this.slideEmit.emit(this.showSlide);
    if(this.fromEdit && !this.isRetailReport)
    {
      this.emitReportSelector.emit(this.reportSelector);
    }
    this.editSlider.emit({
      patchReportValues : this.patchReportValues,
      allDatas : this.allDatas,
      fromEdit : this.fromEdit,
      viewOnly : this.mode == ActionMode.update ? true : false,
      hideReportConfigSaveBtn: this.hideReportConfigSaveBtn,
      reportGroupName : this.reportGroupName,
      reportSourceConfig : this.reportSourceConfig,
      isRetailReport : this.isRetailReport,
      header: this.header
    });
  }

  patchReportName(obj){
    this.reportSourceConfig.selectOptions = Promise.resolve([obj]);
    this.reportSourceConfig = {...this.reportSourceConfig};
    this.reportSourceConfig.defaultSelectededOptionValue =  obj;
    this.reportSourceConfig = {...this.reportSourceConfig};
  }
  showGuestNotificationConfig(e) {
    // this.orchestrateGuestNotifyConfigOptions(e.checked);
    if (!e.checked) {
      this.guestSmsToggleInput.disabled = true;
      this.guestEmailToggleInput.disabled = true;
      this.isGuestEmail = false;
      this.displayGuestConfig = false;
      this.eventDetailForm.controls.guestEmail.setValue(false);
      this.eventDetailForm.controls.guestSms.setValue(false);
      this.ToggleGuestEmail(false);
      this.ToggleGuestSMS(false);
      // this.displayGuestNotifyConfig = false;
    } else {
      // this.displayGuestNotifyConfig = true;
      this.guestSmsToggleInput.disabled = false;
      this.guestEmailToggleInput.disabled = false;
      const value=this.scheduleJob.find(x=>x.id==this.selectedSchedule)
      this.ToggleGuestEmail(value.isEmail);
      this.ToggleGuestSMS(value.isSMS);

    }

  }

  orchestrateGuestNotifyConfigOptions(option: boolean, isFromUpdate: boolean = false) {
    this.displayGuestNotifyConfig = option;
    this.orchestrateGuestNotifyControls(option, isFromUpdate);
  }

  orchestrateGuestNotifySourceConfig(isMailEnable: boolean, isSmsEnable: boolean) {
    this.guestEmailDLSourceConfig.showRequired = isMailEnable;
    this.guestEmailDLSourceConfig = { ...this.guestEmailDLSourceConfig };
    this.guestEmailTemplateSourceConfig.showRequired = isMailEnable;
    this.guestEmailTemplateSourceConfig = { ...this.guestEmailTemplateSourceConfig };
    this.guestSmsDLSourceConfig.showRequired = isSmsEnable;
    this.guestSmsDLSourceConfig = { ...this.guestSmsDLSourceConfig };
    this.guestSMSTemplateSourceConfig.showRequired = isSmsEnable;
    this.guestSMSTemplateSourceConfig = { ...this.guestSMSTemplateSourceConfig };
  }

  orchestrateGuestNotifyControls(option: boolean, isFromUpdate: boolean = false) {
    if (!isFromUpdate) {
      this.eventDetailForm.controls.guestEmail.setValue(option);
      this.eventDetailForm.controls.guestSms.setValue(option);
    }
    const sendGuestMail = this.eventDetailForm.controls.guestEmail.value;
    const sendGuestSms = this.eventDetailForm.controls.guestSms.value;
    if (option) {
      if (sendGuestMail) {
        this.eventDetailForm.controls.guestEmailTemplate.enable();
        this.eventDetailForm.controls.guestEmailDL.enable();
      }
      if (sendGuestSms) {
        this.eventDetailForm.controls.guestSMSDL.enable();
        this.eventDetailForm.controls.guestSMSTemplate.enable();
      }
      this.orchestrateGuestNotifySourceConfig(sendGuestMail, sendGuestSms);
    }
    else {
      this.eventDetailForm.controls.guestEmailTemplate.disable();
      this.eventDetailForm.controls.guestEmailTemplate.setValue({} as DropdownOptions);
      this.eventDetailForm.controls.guestEmailDL.disable();
      this.eventDetailForm.controls.guestEmailDL.setValue({} as DropdownOptions);
      this.eventDetailForm.controls.guestSMSDL.disable();
      this.eventDetailForm.controls.guestSMSDL.setValue({} as DropdownOptions);
      this.eventDetailForm.controls.guestSMSTemplate.disable();
      this.eventDetailForm.controls.guestSMSTemplate.setValue({} as DropdownOptions);
    }
    this.orchestrateGuestSubject(sendGuestMail);
  }

  orchestrateGuestSubject(isGuestMail: boolean) {
    if (isGuestMail) {
      this.isGuestEmail = true;
    }
    else {
      this.isGuestEmail = false;
      this.eventDetailForm.controls.guestEmailSubj.setValue('');
    }
  }

  ngOnDestroy() {
    this.localization.isFromJobScheduler = false;
    this.localization.scheduleJobWildCards = [];
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  editreportConfig(e){
    let filterObj;
    let startDate ; let endDate; let date;  let FromWildCardName;  let ToWildCardName;
    let FromWildCardCount; let ToWildCardCount;
    // let paramsObj = e.params.filter(x => x['PatchEditParam'] === true);
    // console.log(paramsObj);
    // if (paramsObj[0]['PatchEditParam']) {
    //   filterObj = e.params && typeof e.params === 'string' ? JSON.parse(e.params) : JSON.parse(JSON.stringify(e.params));
    //   if(e.params.filter(x => x['pStartDate'])){
    //     let startDateVal = e.params.filter(x => x['pStartDate'])[0]['pStartDate'];
    //     startDate =this.datepipe.transform(startDateVal, 'dd/MM/yyyy');
    //   } else {
    //     startDate ="";
    //   }
    //   if(e.params.filter(x => x['pEndDate'])) {
    //     let endDateVal = e.params.filter(x => x['pEndDate'])[0]['pEndDate'];
    //     endDate =this.datepipe.transform(endDateVal, 'dd/MM/yyyy');
    //   } else {
    //     endDate ="";
    //   }
    //   if(e.params.filter(x => x['pDate'])) {
    //     let dateVal = e.params.filter(x => x['pDate'])[0]['pDate'];
    //     date =this.datepipe.transform(dateVal, 'dd/MM/yyyy');
    //   } else {
    //     date ="";
    //   }

    //   if(e.params.filter(x => x['fromWildCard']))
    //     FromWildCardName = this.getWildCardName(e.params.filter(x => x['fromWildCard'])[0]['fromWildCard']);
    //   else
    //     FromWildCardName = "";

    //   if(e.params.filter(x => x['toWildCard']))
    //     ToWildCardName = this.getWildCardName(e.params.filter(x => x['toWildCard'])[0]['toWildCard']);
    //   else
    //     ToWildCardName = "";

    //     if(e.params.filter(x => x['fromIncrement']))
    //     FromWildCardCount = e.params.filter(x => x['fromIncrement'])[0]?e.params.filter(x => x['fromIncrement'])[0]['fromIncrement']:0;
    //   else
    //     FromWildCardCount = "";

    //   if(e.params.filter(x => x['toIncrement']))
    //     ToWildCardCount = e.params.filter(x => x['toIncrement'])[0]?e.params.filter(x => x['toIncrement'])[0]['toIncrement']:0;
    //   else
    //     ToWildCardCount = "";

    // } else {
      filterObj = e.filters && typeof e.filters === 'string' ? JSON.parse(e.filters) : JSON.parse(JSON.stringify(e.filters));
      if(filterObj.startDate){
        startDate =this.datepipe.transform(filterObj.startDate.value, 'dd/MM/yyyy');
      } else {
        startDate ="";
      }
      if(filterObj.endDate) {
        endDate =this.datepipe.transform(filterObj.endDate.value, 'dd/MM/yyyy');
      } else {
        endDate ="";
      }
      if(filterObj.date) {
        date =this.datepipe.transform(filterObj.date.value, 'dd/MM/yyyy');
      } else {
        date ="";
      }

      if(filterObj.fromWildCard)
        FromWildCardName = this.getWildCardName(filterObj.fromWildCard);
      else
        FromWildCardName = "";

      if(filterObj.toWildCard)
        ToWildCardName = this.getWildCardName(filterObj.toWildCard);
      else
        ToWildCardName = "";

        if(filterObj.fromIncrement)
        FromWildCardCount = filterObj.fromIncrement;
      else
        FromWildCardCount = "";

      if(filterObj.toIncrement)
        ToWildCardCount = filterObj.toIncrement;
      else
        ToWildCardCount = "";

    // }




    if(this.fromEdit)
     {
      // this.editdynamicForm[0].value = e;
      // this.editdynamicForm.slice(0)[this.index] = this.editdynamicForm;
      // let obj = {
      //   id : this.generateId,
      //   value : this.editdynamicForm.slice(0)[this.index].value,
      //   // startDate : startDate,
      //   // endDate : endDate,
      //   // date : date,
      //   reportId : this.editdynamicForm.slice(0)[this.index].reportId,
      //   reportName : this.eventDetailForm?.get('reportName').value,
      //   report : this.eventDetailForm?.get('report').value.value,
      //   fromWildCard : FromWildCardName,
      //   fromWildCardCount : filterObj.fromIncrement ? filterObj.fromIncrement : 0,
      //   toWildCard : ToWildCardName,
      //   toWildCardCount : filterObj.toIncrement ? filterObj.toIncrement : 0
      // };
      // let newArray = Object.assign([...this.editdynamicForm], {[this.index]: obj});
      // this.editdynamicForm = newArray;
      this.editdynamicForm = [];
      this.editdynamicForm.push({
        id : this.generateId+1,
        value : e,
        // startDate : startDate,
        // endDate : endDate,
        // date : date,
        reportId : this.reportId,
        reportName : this.eventDetailForm?.get('reportName').value,
        reportLabel: this.eventDetailForm?.get('reportLabel')?.value ?? this.eventDetailForm?.get('reportName').value,
        report : this.eventDetailForm?.get('report').value.value,
        fromWildCard : FromWildCardName,
        fromWildCardCount : FromWildCardCount ? FromWildCardCount : 0,
        toWildCard : ToWildCardName,
        toWildCardCount :ToWildCardCount ? ToWildCardCount : 0
      });
      }
      else
      {
        this.editdynamicForm = [];
        this.editdynamicForm.push({
          id : this.generateId+1,
          value : e,
          // startDate : startDate,
          // endDate : endDate,
          // date : date,
          reportId : this.reportId,
          reportName : this.eventDetailForm?.get('reportName').value,
          reportLabel: this.eventDetailForm?.get('reportLabel')?.value ?? this.eventDetailForm?.get('reportName').value,
          report : this.eventDetailForm?.get('report').value.value,
          fromWildCard : FromWildCardName,
          fromWildCardCount : FromWildCardCount ? FromWildCardCount : 0,
          toWildCard : ToWildCardName,
          toWildCardCount :ToWildCardCount ? ToWildCardCount : 0
        });
      }
    }

    getWildCardName(WildCardId){

      return this.localization.scheduleJobWildCards.find(x => x.id == WildCardId)?.viewValue;
    }

}

