import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { ApplyDiscountService } from './appply-discount.service';
import { DiscountReason, DiscountType, DiscountTypeDetails, ItemDiscount } from '../shop.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { ApplyDiscountInput } from '../../shared/service/common-variables.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { SystemConfiguration } from '../../retail.modals';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import * as GlobalConst from '../../shared/globalsContant';
import { DiscountLevel, Pricing } from '../../retail-code-setup/retail-discount-types/discount-types.model';
import { EngineDiscountModel } from '../../retail-transaction-engine/transaction-engine-model';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs/operators';
import { SearchDiscountType } from '../../shared/service/transaction-service/transction-model';

@Component({
  selector: 'app-apply-discount',
  templateUrl: './apply-discount.component.html',
  styleUrls: ['./apply-discount.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplyDiscountComponent implements OnInit {

  selectedType: number = 1;
  discountForm: UntypedFormGroup;
  // @Input() CategoryId: number;
  // @Input() SelectedDiscount: ItemDiscount;
  // @Input() miscSetting;


  // @Input() ApplyDiscountInput: ApplyDiscountInput;
  // @Output() discountEmitter: EventEmitter<ItemDiscount> = new EventEmitter();
  // @Output() closeEmitter: EventEmitter<any> = new EventEmitter();

  ApplyDiscountInput: ApplyDiscountInput;
  CategoryId: number;
  SelectedDiscount: ItemDiscount;
  public discountList: DiscountTypeDetails[] = [];
  public discountReason: Promise<DiscountReason[]>;
  public captions: any = this.localization.captions.shop;
  public retailcaptions: any = this.localization.captions.retailsetup;
  public discountReasonSetting: SystemConfiguration;
  public discountCommentSetting: SystemConfiguration;
  reasonRequired: boolean = false;
  commentRequired: boolean = false;
  isPrecentageDisabled: boolean = false;
  customDiscountsAccess: boolean = false;
  itemName: string;
  itemPrice: number;
  showItemDetails: boolean = false;
  floatLabel: string;
  IsTicketDiscount: boolean;
  ticketDiscount: EngineDiscountModel;
  barCodeSearchTextChanged = new Subject<string>();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedText: string = '';
  subTotal: number = 0;
  validateMinimumAmountInCart = false;
  errorMsg : string = '';
  memberDiscountTypeIds = [];
  constructor(private fb: UntypedFormBuilder, public utils: RetailUtilities, public localization: RetailLocalization, private applyDiscountService: ApplyDiscountService, private dialogRef: MatDialogRef<ApplyDiscountComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    , private breakPoint: BreakPointAccess) {
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.CategoryId = this.data?.CategoryId;
    this.IsTicketDiscount = this.data?.IsTicketDiscount;
    this.ticketDiscount = this.data?.ticketDiscount;
    this.SelectedDiscount = this.data?.discountInput?.selectedDiscount;
    this.showItemDetails = this.data?.discountInput?.showItemDetails;
    this.ApplyDiscountInput = this.data.discountInput
    this.itemName = this.data?.discountInput?.itemName;
    this.itemPrice = this.data?.discountInput?.itemPrice;
    this.subTotal = this.data?.subTotal || 0;
    this.validateMinimumAmountInCart = this.data?.validateMinimumAmountInCart;
    this.memberDiscountTypeIds = this.data?.memberDiscTypeIds;
    this.checkBreakpoints();
    this.SetDiscountPopUp();
    this.barCodeSearchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap((source) => this.searchDiscountBarCode(source)),
        takeUntil(this.destroyed$)
      ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async checkBreakpoints() {
    this.customDiscountsAccess = this.breakPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyCustomDiscount], false);
  }

  checkCustomDiscountControls(val) {
    if (!this.customDiscountsAccess) {
      this.breakPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyCustomDiscount], true);
    }
  }

  initializeForm(): void {
    this.discountForm = this.fb.group({
      type: 1,
      percentage: [{ value: '', disabled: !this.customDiscountsAccess }],
      discType: 0,
      flatdiscType: 0,
      amount: [0],
      comment: '',
      reason: 0
    });
  }

  SetDiscountPopUp() {
    this.initializeForm();
    this.selectedType = 1;
    this.loadDiscounts();
    this.DiscountReason();
    this.GetMiscSetting();
  }

  typeChange(arg) {
    this.selectedType = arg.value;
    this.discountForm.controls.amount.setValue("");
    this.discountForm.controls.percentage.setValue("");
    this.discountForm.controls.discType.setValue(0);
    this.discountForm.controls.flatdiscType.setValue(0);
    if(this.selectedText) {
      this.selectedText = '';
    }
    this.resetValidators();
    if (this.selectedType == 1) {
      if (this.discountForm.controls.percentage.disabled) {
        this.discountForm.controls.percentage.enable();
      }
    } else {
      if (this.discountForm.controls.amount.disabled) {
        this.discountForm.controls.amount.enable();
      }
    }
    this.DiscountList(true);
    this.SetRequiredFields();
  }

   filterDiscBasedOnMinAmountInCart(disc: DiscountType[] ): DiscountType[] {
    return this.validateMinimumAmountInCart ? disc.filter(x => this.subTotal >= x.minimumAmountInCart || x.minimumAmountInCart == 0) : disc;
  }

  async loadDiscounts() {
    if (this.IsTicketDiscount) {
      let discountTypes = await this.applyDiscountService.getActiveTicketDiscountTypes();
      if (this.memberDiscountTypeIds && this.memberDiscountTypeIds.length > 0) {
        discountTypes = discountTypes.filter(disc => this.memberDiscountTypeIds.includes(disc.id));
      }
        this.discountList = this.filterDiscBasedOnMinAmountInCart(discountTypes).map(x => {
          return {
            discountTypeId: x.id,
            discountTypeName: x.type,
            discountValue: 0,
            isPercentage: x.isPercentage,
            minimumAmountInCart: x.minimumAmountInCart,
            tenderReducesDiscount: x.tenderReducesDiscount,
            pricing: x.pricing,
            amount: x.amount
          }
        });
        this.patchCheckDiscount();
    }
    else {
      this.DiscountList();
    }
  }

  validateTicketDiscPercent(){
    return this.discountForm.controls.percentage.value > 0 &&  !(this.discountForm.controls.discType.value > 0);
  }

  validateTicketDiscAmount(){
    return this.discountForm.controls.amount.value > 0 && !(this.discountForm.controls.flatdiscType.value > 0);
  }

  patchCheckDiscount() {
    if(!this.ticketDiscount) return;
    this.selectedType = this.ticketDiscount.isPercentage ? 1 : 2;
    if (this.ticketDiscount.discountTypeId > 0) {
      if (this.ticketDiscount.isPercentage) {
        this.discountForm.controls.discType.setValue(this.ticketDiscount.discountTypeId);
        const appliedDiscount = this.discountList?.find(x => x.discountTypeId == this.ticketDiscount.discountTypeId);
        if(appliedDiscount&& appliedDiscount.pricing == Pricing.Open){
          this.discountForm.controls.percentage.setValue(this.ticketDiscount.value);
          this.discountForm.controls.percentage.enable();
        } else
        this.discountForm.controls.percentage.disable();
      } else {
        this.discountForm.controls.flatdiscType.setValue(this.ticketDiscount.discountTypeId);
        const appliedDiscount = this.discountList?.find(x => x.discountTypeId == this.ticketDiscount.discountTypeId);
        if(appliedDiscount&& appliedDiscount.pricing == Pricing.Open){
          this.discountForm.controls.amount.setValue(this.ticketDiscount.value);
          this.discountForm.controls.amount.enable();
        } else
        this.discountForm.controls.amount.disable();
      }
    }
    else if (this.ticketDiscount.value > 0) {
      if (this.ticketDiscount.isPercentage) {
        this.discountForm.controls.percentage.setValue(this.ticketDiscount.value);
      }
      else {
        this.discountForm.controls.amount.setValue(this.ticketDiscount.value);
      }
    }
    this.discountForm.controls.type.setValue(this.selectedType);
    this.discountForm.controls.reason.setValue(this.ticketDiscount.discountReason);
    this.discountForm.controls.comment.setValue(this.ticketDiscount.discountComments);
    this.SetRequiredFields();
  }

  DiscountList(isChange: boolean = false) {
    if(this.selectedText) {
      this.selectedText = '';
    }
    if (this.ApplyDiscountInput.CategoryId === -1) {
      let discountTypes = this.applyDiscountService.getActiveDiscountTypes();
      discountTypes.then(res => {
        if (this.memberDiscountTypeIds && this.memberDiscountTypeIds.length > 0) {
          res = res.filter(disc => this.memberDiscountTypeIds.includes(disc.id));
        }
        this.discountList = this.filterDiscBasedOnMinAmountInCart(res).map(x => {
          return {
            discountTypeId: x.id,
            discountTypeName: x.type,
            discountValue: 0,
            isPercentage: x.isPercentage,
            minimumAmountInCart: x.minimumAmountInCart,
            tenderReducesDiscount: x.tenderReducesDiscount,
            pricing: x.pricing,
            amount: x.amount
          }
        })
      });
        
    }
    else {
      if (this.ApplyDiscountInput.CategoryId == 0) {
        this.discountList = [];
      }
      else {
        this.applyDiscountService.getDiscountsForItem(this.ApplyDiscountInput.CategoryId, (this.ApplyDiscountInput.CategoryId == -1)).then(res => {
          if (this.memberDiscountTypeIds && this.memberDiscountTypeIds.length > 0) {
            res = res.filter(disc => this.memberDiscountTypeIds.includes(disc.discountTypeId));
          }
          this.discountList = this.validateMinimumAmountInCart ? res.filter(x => x.discountValue > 0 && (this.subTotal >= x.minimumAmountInCart || x.minimumAmountInCart == 0))
                             : res.filter(x => x.discountValue > 0);
        })
      }
    }

    if (this.ApplyDiscountInput.SelectedDiscount && !isChange) {
      if (this.ApplyDiscountInput.SelectedDiscount.discountId > 0) {
        this.selectedType = this.ApplyDiscountInput.SelectedDiscount.isPercentage ? 1 : 2;
        if (this.ApplyDiscountInput.SelectedDiscount.isPercentage) {
          this.discountForm.controls.discType.setValue(this.ApplyDiscountInput.SelectedDiscount.discountId);
          this.discountForm.controls.percentage.disable();
        } else {
          this.discountForm.controls.flatdiscType.setValue(this.ApplyDiscountInput.SelectedDiscount.discountId);
          this.discountForm.controls.amount.disable();
        }
      }
      else if (this.ApplyDiscountInput.SelectedDiscount.discountPercentage > 0) {
        this.selectedType = 1;
        this.discountForm.controls.percentage.setValue(this.ApplyDiscountInput.SelectedDiscount.discountPercentage);
      }
      else if (this.ApplyDiscountInput.SelectedDiscount.discountAmount > 0) {
        this.selectedType = 2;
        this.discountForm.controls.amount.setValue(this.ApplyDiscountInput.SelectedDiscount.discountAmount);
      }
      this.discountForm.controls.type.setValue(this.selectedType);
      this.discountForm.controls.reason.setValue(this.ApplyDiscountInput.SelectedDiscount.discountReason);
      this.discountForm.controls.comment.setValue(this.ApplyDiscountInput.SelectedDiscount.discountComment);
      this.SetRequiredFields();
    }
    else if (isChange) {
      this.discountForm.controls.amount.setValue("");
      this.discountForm.controls.percentage.setValue("");
      this.discountForm.controls.discType.setValue(0);
      this.discountForm.controls.flatdiscType.setValue(0);
    }
  }


  apply() {
    const discountId = this.discountForm.controls.type.value == 1 ? this.discountForm.controls.discType.value : this.discountForm.controls.flatdiscType.value;
    const itemDiscount: ItemDiscount = {
      itemId: 0,
      discountAmount: this.IsTicketDiscount ? this.getTicketDiscountAmount(discountId) : this.getItemDiscountAmount(discountId),
      discountId: discountId,
      discountPercentage: this.IsTicketDiscount ? this.getTicketDiscountPercentage(discountId) : this.getItemDiscountPercentage(discountId),
      discountComment: this.discountForm.controls.comment.value,
      discountReason: this.discountForm.controls.reason.value,
      isPercentage: this.discountForm.controls.type.value == 1,
      discountDetails: discountId > 0 ? this.discountList.find(x => x.discountTypeId == discountId) : null,
      minimumAmountInCart: discountId > 0 ? this.discountList.find(x => x.discountTypeId == discountId).minimumAmountInCart : 0
    };
    this.discountForm.reset({ type: 1 });

    this.dialogRef.close(itemDiscount)
  }

  getItemDiscountAmount(discountId): number {
    if (!discountId || discountId == 0) {
      return this.localization.currencyToSQLFormat(this.discountForm.controls.amount.value);
    }
    const selectedDiscount = this.discountList.find(x => x.discountTypeId == discountId);
    return selectedDiscount && this.discountForm.controls.type.value == 2 ? selectedDiscount.discountValue : 0;
  }

  getItemDiscountPercentage(discountId): number {
    if (!discountId || discountId == 0) {
      return this.localization.currencyToSQLFormat(this.discountForm.controls.percentage.value);
    }
    const selectedDiscount = this.discountList.find(x => x.discountTypeId == discountId);
    return selectedDiscount && this.discountForm.controls.type.value == 1 ? selectedDiscount.discountValue : 0;
  }

  getTicketDiscountAmount(discountId): number {
    if (!discountId || discountId == 0) {
      return this.localization.currencyToSQLFormat(this.discountForm.controls.amount.value);
    }
    const selectedDiscount = this.discountList.find(x => x.discountTypeId == discountId);
    if (selectedDiscount.pricing == Pricing.Fixed && this.discountForm.controls.type.value == 2) {
      return selectedDiscount.amount;
    }
    else {
      return this.localization.currencyToSQLFormat(this.discountForm.controls.amount.value);
    }
  }

  getTicketDiscountPercentage(discountId): number {
    if (!discountId || discountId == 0) {
      return this.localization.currencyToSQLFormat(this.discountForm.controls.percentage.value);
    }
    const selectedDiscount = this.discountList.find(x => x.discountTypeId == discountId);
    if (selectedDiscount.pricing == Pricing.Fixed && this.discountForm.controls.type.value == 1) {
      return selectedDiscount.amount;
    }
    else {
      return this.localization.currencyToSQLFormat(this.discountForm.controls.percentage.value)
    }
  }

  close() {
    // this.closeEmitter.emit();
    this.discountForm.reset({ type: 1 });

    this.dialogRef.close()
  }

  oneOfControlRequired(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const aControl of controls) {
        if (!Validators.required(aControl)) {
          console.log(null);
          return null;
        }
      }
      return { oneOfRequired: true };
    };
  }

  discountTypeSelected(e) {
    if(this.selectedText) {
      this.selectedText = '';
    }
    if (!this.IsTicketDiscount) {
      if (e.value > 0) {
        this.discountForm.controls.percentage.setValue('');
        this.discountForm.controls.percentage.disable();
      } else {
        if (this.customDiscountsAccess) {
          this.discountForm.controls.percentage.enable();
        }
      }
    }
    else {
      if (e.value > 0) {
        const selectedDiscount = this.discountList.find(x => x.discountTypeId == e.value);
        this.discountForm.controls.percentage.setValue('');
        this.resetValidators();
        if (selectedDiscount.pricing == Pricing.Fixed) { // TODO max validation has to be added  for open
          this.discountForm.controls.percentage.disable();
        } else {
          this.discountForm.controls.percentage.enable();
          this.discountForm.controls.percentage.setValidators([Validators.required,Validators.max(selectedDiscount.amount)]);
          this.discountForm.controls.percentage.updateValueAndValidity();
        }

      } else {
        if (this.customDiscountsAccess) {
          this.discountForm.controls.percentage.enable();
        }
      }
    }
  }

  flatdiscountTypeSelected(e) {
    if(this.selectedText) {
      this.selectedText = '';
    }
    if (!this.IsTicketDiscount) {
      if (e.value > 0) {
        this.discountForm.controls.amount.setValue('');
        this.discountForm.controls.amount.disable();
      } else {
        if (this.customDiscountsAccess) {
          this.discountForm.controls.amount.enable();
        }
      }
    }
    else {
      if (e.value > 0) {
        const selectedDiscount = this.discountList.find(x => x.discountTypeId == e.value);
        this.discountForm.controls.amount.setValue('');
        this.resetValidators();
        if (selectedDiscount.pricing == Pricing.Fixed) {// TODO max validation has to be added  
          this.discountForm.controls.amount.disable();
        }
        else {
          this.discountForm.controls.amount.enable();
          this.discountForm.controls.amount.setValidators([Validators.required,Validators.max(selectedDiscount.amount)]);
          this.discountForm.controls.amount.updateValueAndValidity();
        }
      } else {
        if (this.customDiscountsAccess) {
          this.discountForm.controls.amount.enable();
        }
      }

    }
  }

  resetValidators(){
    this.discountForm.controls.percentage.setValidators(null);
    this.discountForm.controls.percentage.updateValueAndValidity();
    this.discountForm.controls.amount.setValidators(null);
    this.discountForm.controls.amount.updateValueAndValidity();
  }

  onPercentageChange(e) {
    if(this.selectedText) {
      this.selectedText = '';
    }
    if (this.discountForm.controls.percentage.value > 0) {
      if (!this.IsTicketDiscount) {
        this.discountForm.controls.discType.setValue(0);
      }
      else if (this.IsTicketDiscount && this.discountForm.controls.discType.value > 0) {
        const selectedDiscount = this.discountList.find(x => x.discountTypeId == this.discountForm.controls.discType.value);
        if (selectedDiscount && selectedDiscount.pricing == Pricing.Fixed) {
          this.discountForm.controls.discType.setValue(0);
        } 
      }
    }
  }

  getErrorMessage(){
    const discountId = this.discountForm.controls.type.value == 1 ? this.discountForm.controls.discType.value : this.discountForm.controls.flatdiscType.value;
    const discount = this.discountList.find(x => x.discountTypeId == discountId);
    if(!discount) return '';
    const maxValue =  this.localization.currencyToSQLFormat(discount.amount.toString());
    const value = discount.isPercentage ? maxValue + '%' :  this.localization.currencySymbol + maxValue ;
    return  this.localization.replacePlaceholders(this.retailcaptions.maximumDiscountvalue, ["discountName","maxValue","maxValue"],[discount.discountTypeName,value,value]);
  }

  onFlatAmountChange(e) {
    if(this.selectedText) {
      this.selectedText = '';
    }
    if (this.discountForm.controls.amount.value > 0) {
      if (!this.IsTicketDiscount) {
        this.discountForm.controls.flatdiscType.setValue(0);
      }
      else if (this.IsTicketDiscount && this.discountForm.controls.flatdiscType.value > 0) {
        const selectedDiscount = this.discountList.find(x => x.discountTypeId == this.discountForm.controls.flatdiscType.value);
        if (selectedDiscount && selectedDiscount.pricing == Pricing.Fixed) {
          this.discountForm.controls.flatdiscType.setValue(0);
        }
      }
    }
  }

  isFormValid(): boolean {
    if (this.discountForm.controls.type.value == 1) {
      if (this.discountForm.controls.percentage.value != null) {
        return this.validatePercentDiscount() && this.validateRequiredReasonComments();
      }
      else { return false; }
    } else {
      if(this.discountForm.controls.type.value == 2){
        if (this.discountForm.controls.amount.value != null) {
          return this.validateFlatDiscount() && this.validateRequiredReasonComments();
        }
      }
      else { return false; }
    }
  }

  validateRequiredReasonComments() :boolean {
    return (!this.reasonRequired || (this.reasonRequired && this.discountForm.controls.reason.value > 0))
    && (!this.commentRequired || (this.commentRequired && this.discountForm.controls.comment.value?.toString().trim() != ''));
  }

  validateFlatDiscount() :boolean {
    const isFlatDiscValid = (this.discountForm.controls.flatdiscType.value > 0 || (this.discountForm.controls.amount.value.toString().trim() != '' 
      && this.discountForm.controls.amount.value.toString().trim() != '0'));
    if(this.discountForm.controls.flatdiscType.value > 0 && this.IsTicketDiscount){
      const selectedDiscount = this.discountList.find(x => x.discountTypeId == this.discountForm.controls.flatdiscType.value);
      if(!selectedDiscount) return;
      return selectedDiscount.pricing == Pricing.Open ?  isFlatDiscValid && this.discountForm.controls.amount.valid :  isFlatDiscValid ;
   }
    return isFlatDiscValid;
  }

  validatePercentDiscount() :boolean {
    const isPercentDiscValid = ((this.discountForm.controls.percentage.value.toString().trim() != '' 
    && this.discountForm.controls.percentage.value.toString().trim() != '0')  || this.discountForm.controls.discType.value > 0)
    if(this.discountForm.controls.discType.value > 0 && this.IsTicketDiscount){
       const selectedDiscount = this.discountList.find(x => x.discountTypeId == this.discountForm.controls.discType.value);
       if(!selectedDiscount) return;
       return selectedDiscount.pricing == Pricing.Open ? isPercentDiscValid &&  this.discountForm.controls.percentage.valid :  isPercentDiscValid;
    }
    return isPercentDiscValid;
  }

  DiscountReason() {
    this.discountReason = this.applyDiscountService.getActiveDiscountReasons().then(res => {
      return res && res.filter(x => x.isActive);
    })
  }

  GetMiscSetting() {
    const allSettings = this.applyDiscountService.getMiscSettings();
    allSettings.then(res => {
      if (res && res.length > 0) {
        this.discountReasonSetting = res.find(x => x.switch == GlobalConst.MiscellaneousSwitch.DISCOUNT_REASON_REQUIRED);
        this.discountCommentSetting = res.find(x => x.switch == GlobalConst.MiscellaneousSwitch.DISCOUNT_COMMENTS_REQUIRED);
      }
      this.SetRequiredFields();
    })
  }

  SetRequiredFields() {
    this.reasonRequired = false;
    if ((this.discountReasonSetting && this.discountReasonSetting.value == '1')
      || (this.discountReasonSetting && this.discountReasonSetting.value == '2' && this.discountForm.controls.type.value == 1)
      || (this.discountReasonSetting && this.discountReasonSetting.value == '3' && this.discountForm.controls.type.value == 2)
    ) {
      this.reasonRequired = true;
    }

    this.commentRequired = false;
    if ((this.discountCommentSetting && this.discountCommentSetting.value == '1')
      || (this.discountCommentSetting && this.discountCommentSetting.value == '2' && this.discountForm.controls.type.value == 1)
      || (this.discountCommentSetting && this.discountCommentSetting.value == '3' && this.discountForm.controls.type.value == 2)
    ) {
      this.commentRequired = true;
    }
  }
  searchDiscountByBarCode(input){
    this.barCodeSearchTextChanged.next(input);
  }

  async searchDiscountBarCode(input: string) {
    if (!input.trim()?.length) return;
    this.resetDiscountType();
    const request: SearchDiscountType = {
      searchText: input.trim(),
      discountLevel: this.IsTicketDiscount ? DiscountLevel.Ticket : DiscountLevel.Item
    }
    const discount = await this.applyDiscountService.searchDiscountByBarCode(request);
    const result = this.discountList.find(x => x.discountTypeId == discount.id && this.subTotal >=  x.minimumAmountInCart );
    if(result){
      this.patchDiscountType(result);
      this.SetRequiredFields();
    }
  }

  resetDiscountType(){
    this.discountForm.controls.amount.setValue("");
    this.discountForm.controls.percentage.setValue("");
    this.discountForm.controls.discType.setValue(0);
    this.discountForm.controls.flatdiscType.setValue(0);
    this.discountForm.controls.percentage.enable();
    this.discountForm.controls.amount.enable();
  }

  patchDiscountType(selectedDisc: DiscountTypeDetails) {
    this.selectedType = selectedDisc.isPercentage ? 1 : 2;
    this.discountForm.controls.type.setValue(this.selectedType);
    if (this.IsTicketDiscount && selectedDisc.pricing == Pricing.Open) {
      if (selectedDisc.isPercentage) {
        this.discountForm.controls.percentage.enable();
        this.discountForm.controls.percentage.setValidators([Validators.required, Validators.max(selectedDisc.amount)]);
        this.discountForm.controls.percentage.updateValueAndValidity();
      } else {
        this.discountForm.controls.amount.enable();
        this.discountForm.controls.amount.setValidators([Validators.required,Validators.max(selectedDisc.amount)]);
        this.discountForm.controls.amount.updateValueAndValidity();
      }
    } else {
      this.discountForm.controls.percentage.disable();
      this.discountForm.controls.amount.disable();
      this.resetValidators();
    }
    if (selectedDisc.isPercentage) {
      this.discountForm.controls.discType.setValue(selectedDisc.discountTypeId);
    } else {
      this.discountForm.controls.flatdiscType.setValue(selectedDisc.discountTypeId);
    }
  }


}
