import {
  BreakType,
  LinkCode,
  PackageAvailableDays,
  PackageDetail,
  PackageYield,
  ServiceAddOns,
  SpaServiceLocation,
  SpaServices,
  SpaServiceSesonalDate,
  SystemConfiguration,
  TherapistDetails
} from './view-settings.modals';
import { AppointmentStatus, GridOperationType, noRecordsType } from '../globalsContant';
import { BookingStatus } from 'src/app/appointment/spa-wizard/spa-wizard.modal';
import { GuaranteeMethodJSONModel } from '../appointment-popup/create-appointment/guarantee-method/guarantee-method.business';
import { ReportAPIModel } from 'src/app/retail/retail-reports/business/report.modals';
import { DepositTransactionStatus } from 'src/app/retail/shared/service/payment/payment-business.model';
import { CustomFeeSourceType } from 'src/app/retail/shared/globalsContant';



export interface MoveReponse {
  errorList: number[];
  unCertifiedAddOns: number[];
  autoPickValues: number[];
}

export interface BaseResponse<T> {
  result: T;
  errorCode: number;
  errorDescription: string;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}


export interface localizationJSON {
  common: any;
  login: any;
  calendar: Calendar;
  home: any;
  header: any;
  bookAppointment: any;
  shop: any;
  client: any;
  reports: any;
  receipt: any;
  appointmentSearch: any;
  appointmentConfirmation: any;
  setting: any;
  staffSchedule: any;
  utilities: any;
  retailsetup: any;
  userConfig: any;
  breakpoint: any;
  dayEnd: any;
  None?: any;
  globalSearch: any;
  inventory: any;
  emailConfigurationsetup: any;
  settings: any;
  alertPopup: any;
}
export interface googleApi {
  results: Result[];
  status: string;
}

export interface Result {
  address_components: Addresscomponent[];
  formatted_address: string;
  geometry: Geometry;
  place_id: string;
  types: string[];
}

export interface Geometry {
  bounds: Bounds;
  location: Northeast;
  location_type: string;
  viewport: Bounds;
}

export interface Bounds {
  northeast: Northeast;
  southwest: Northeast;
}

export interface Northeast {
  lat: number;
  lng: number;
}

export interface Addresscomponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface ClientScreenProperties {
  minGuest: number;
  maxGuest: number;
  helperText: string;
  selectedStaff?: any;
  maxGuestLocation?: number;
}

export interface multiClientInfo {
  id: number;
  comments: string;
  TherapistId: number;
  clientName?: string;
  priceTypeId: number;
  price: number;
  discountType?: string;
  depositAmount?:number
}

export interface Location {
  id?: number;
  code: string;
  description: string;
  comments: string;
  listOrder: number;
}

export interface appointment {
  appointmentDetail: appointmentDetail;
  appointmentTherapists: appointmentTherapist[];
  appointmentAddOn: appointmentAddOn[];
  deposit?: DepositAPIModel[];
  appointmentBillingDetail?: AppointmentBillingDetail;
  cancellationNoShowCharge?: CancellationNoShowCharge;
  isFromAssignTherapist?: boolean;
  SendEmailNotification?: boolean;
  packageName?: string;
  packagePrice?: string;
  pMSActivityId?: string;
  externalPMSConfNo?: string;
  pmsActivityStatus?: any;
}

export interface appointmentDetail {
  id?: any;
  startTime: any;
  endTime: any;
  startTimeObj?: Date;
  endTimeObj?: Date;
  clientId: number;
  serviceGroupId: number;
  serviceId: number;
  locationId: number;
  comments: string;
  status: string | AppointmentStatus;
  cancelId: any;
  duration: any;
  setUpTime: any;
  breakDownTime: any;
  price: any;
  doNotMove: boolean;
  requestStaff: boolean;
  isBookingLocked: boolean;
  isVip: boolean;
  genderPreference: any;
  checkOutComments: string;
  noShowComments: string;
  noShow: boolean;
  packageId: number;
  appointmentType: string;
  cancelReason: number;
  cancelComments: string;
  cancelFee: any;
  customField1?: number;
  customField2?: number;
  customField3?: number;
  customField4: string;
  customField5: string;
  tempHoldId: string;
  tempHoldLinkId: number;
  isTherapistOverbook: boolean;
  isLocationOverbook: boolean;
  isClientOverbook?: boolean;
  linkCodeId?: number;
  guestId?: string;
  clientComments: string;
  activityId?: any;
  clientMultiPackId?: any;
  transactionId?: number;
  transactionDetailId?: number;
  ServiceCharge?: number;
  Gratuity?: number;
  Tax?: number;
  TotalAmount?: number;
  multiGroupId?: string;
  userSessionId?: number;
  packageGroupId?: string;
  priceTypeId?: number;
  serviceChargePercent?: number;
  gratuityPercent?: number;
  yieldPrice?: number;
  intakeForm: boolean;
  originalStartTime?: any;
  originalEndTime?: any;
  groupAppointmentId?: number;
  setGuid?: string;
  setName?: string;
  totalAmount?: number;
  createdAt?: string;
  linkedAppointmentID?: string;
  isClientBlockOverrided?: boolean;
  serviceCharge?: number;
  gratuity?: number;
  guestTypeId?: number;
  bypassClientScheduling?: boolean;
  confirmationId?: string;
  discountType?: string;
  dayPassId?:number;
}
export interface appointmentTherapist {
  therapistId: number;
  appointmentId: number;
  id?: number;
  servicePoints?: number;
}

export interface appointmentAddOn {
  id?: number;
  appointmentId: number;
  addOnId: number;
  therapistId: number;
  price: number;
}

export interface AppointmentBillingDetail {
  id: number;
  appointmentId: number;
  billingDetail: string;
}

export interface CancellationNoShowCharge {
  activityId: number;
  policyId: number;
  policyName: string;
  policyDescription: string;
  includeCharge: number[];
  isFullPrice: boolean;
  isPercentage: boolean;
  charge: any;
  isCancellation: boolean;
}

export interface AppointmentCancel {
  id: number;
  cancelReason: number;
  cancelFee: any;
  cancelComments: string;
  depositRefundAmount: number;
  canPerformCancel?: boolean;
  outletId?: number;
}

export interface ClassClientCancel {
  classClientId: number;
  cancelReasonId: number;
  cancelComments: string;
  depositRefundAmount: number;
}

export interface AppointmentAction {
  id: number;
  clientId: number;
  serviceId: number;
  serviceGroupId: number;
  locationId: number;
  startTime: string;
  therapistId: any;
  servicePrice?: any;
  tempIds?: any;
  isTherapistOverbook?: boolean;
  isLocationOverbook?: boolean;
  isClientOverbook?: boolean;
  clientMultiPackId?: number;
  transactionid?: number;
  transactionDetailId?: number;
  ServiceCharge?: number;
  Gratuity?: number;
  Tax?: number;
  TotalAmount?: number;
  duration?: number;
  breakdownTime?: number;
  setupTime?: number;
  MoveTherapist?: MoveTherapist;
  AddOns?: appointmentAddOn[];
  packageGroupId?: string;
  multiGroupId?: string;
  priceTypeId?: number;
  serviceChargePercent: number;
  gratuityPercent: number;
  yieldPrice?: number;
  genderPreference?: number;
  isBookingLocked?: boolean;
  sendEmailNotification?: boolean;
  clientType?: number;
  activityId?: string;
  isFromPackageMove?: boolean;
  requestStaff?: boolean;
  applyAddOnRule?: boolean;
}

export interface MoveTherapist {
  ApplyAddOnRule: boolean;
  FromTherapistId: number;
  ToTherapistId: number;
}

export interface AppointmentCheckoutWithTransaction {
  appointmentId: number;
  transactionId: number;
  transactionDetailId: number;
  serviceCharge: number;
  gratuity: number;
  tax: number;
  addOnId?: number;
  retailTicketNumber: string;
  isAppointmentCheckedOut?: boolean;
  appointmentCustomFee?:AppointmentCustomFee[];
}

export interface AppointmentCustomFee{
  customFeeId : number;
  appointmentId : number;
  amount:number;
  taxAmount:number;
}

export interface AppointmentLineNumber {
  appointmentId: number;
  LineNumber: number;
  addOnId?: number;
}

export interface ClientInfo {
  id?: number;
  clientDetail: ClientDetail;
  phoneNumbers?: PhoneNumber[];
  addresses?: Address[];
  emails?: Email[];
  clientMedicalCondition?: MedicalHistory[];
  clientPreferences?: ClientPreference[];
  clientSOAPNotes?: ClientSOAPNote[];
  clientCreditCardInfo?: ClientCreditCardInfo[];
  isBookingContact?: boolean;
  clientContactLogs?: ContactLog[];
  guestContact?: GuestContact[];
  isContactRelationUpdateRequired?: boolean;
}

export interface Email {
  id: number;
  contactTypeId: number;
  clientId: number;
  emailId: string;
  isPrivate: boolean;
  isPrimary: boolean;
  propertyId: number;
  subPropertyId: number;
  platformContactUuid?: string;
}

export interface PhoneNumber {
  id: number;
  contactTypeId: number;
  clientId: number;
  countryCode: string | number;
  number: string;
  extension: string;
  isPrivate: boolean;
  isPrimary: boolean;
  propertyId: number;
  subPropertyId: number;
  platformContactUuid?: string;
}

export interface ClientDetail {
  id: number;
  guestId?: string;
  GuestId?: string;
  title: string;
  firstName: string;
  FirstName?: string;
  lastName?: string;
  LastName?: string;
  pronounce: string;
  gender: string;
  dateOfBirth: string;
  comments: string;
  bypassClientScheduling: boolean;
  propertyId: number;
  subPropertyId: number;
  customField1: any;
  customField2: any;
  customField3: any;
  customField4: any;
  customField5: any;
  genderPreference: string;
  lastChangeId?: string;
  interfaceGuestId: string;
  priceTypeId: number;
  loyaltyDetail: LoyaltyDetail[];
  tempId?: string;
  status?: string | BookingStatus;
  blockedClient: boolean;
  clientType: number;
  clientLinkId: string;
  arAccountNumber: string;
  confirmationNumber: string;
  roomNumber: string;
  linkCode: string;
  pmsActivityId?: string;
  consent?:Date;
  consentExpiryDate?:Date;
  consentPolicyId?:number;
  isPurged?:boolean;
  policyComments ?: string;
  emailId?: string;
  discountType: string;
  isExternalGuest: boolean;
  externalPMSActivityId?: string;
  platformBussinessCardRevUuid?: string;
  platformBussinessCardUuid?: string;
  platformGuestUuid?: string;
  platformRevUuid?: string;
  isExternalGuestLinked?: boolean;
  vip: string;
}

export interface LoyaltyDetail {
  patronId: string;
  rank: string;
}

export interface SubscribeProfile{
  guestId: string;
  interfaceGuestId: string;
  interfaceName: string;
  subscriptionType: SubscriptionType
}

export enum SubscriptionType{
  UnSubscribe = 0,
  Subscribe = 1,
}

export interface Address {
  clientId: number;
  contactTypeId: number;
  line1?: string;
  Line1?: string;
  line2: string;
  line3: string;
  state?: string;
  State?: string;
  city: string;
  country?: string;
  Country?: string;
  zip: string;
  isPrivate: boolean;
  id: number;
  platformAddressUuid?: string;
}

export interface MedicalHistory {
  clientId: number;
  medicalConditionId: number;
}

export interface BaseResp {
  result: any;
  errorCode: number;
  errorDescription?: any;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}
export interface TempHoldApp {
  startTime: any;
  endTime: any;
  serviceGroupId: number;
  clientId: any;
  service: number;
  location: number;
  comments: string;
  status: any;
  cancelId: string;
  duration: any;
  setUpTime: any;
  breakDownTime: any;
  price: any;
  doNotMove: any;
  requestStaff: boolean;
  isBookingLocked: boolean;
  isVip: boolean;
  genderPreference: number;
  checkOutComments: string;
  noShowComments: string;
  noShow: boolean;
  packageId: number;
  therapist: any[];
  appointmentId: number;
  appointmentDate: any;
  TempHoldId: string;
  TempHoldLinkId: number;
  originalClientId: number;
  multiGroupId: number;
  transactionid?: number;
  transactionDetailId?: number;
  ServiceCharge?: number;
  Gratuity?: number;
  Tax?: number;
  TotalAmount?: number;
  guestTypeId?: number;
  isFromAssignTherapist?: boolean;
  discountType?: string;
}

export interface AppointmentSearchData {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  clientName: string;
  clientId: number;
  serviceName: string;
  serviceId: number;
  packageName: string;
  packageId: number;
  therapistName: string;
  therapistId: number;
  locationName: string;
  locationId: number;
  status: string;
  appointmentId: number;
  cancelId: string;
  retailTransactionNo: string;
  confirmationNo: string;
  appointmentTime: string;
  ticketNumber: string;
}
export interface staffSchedule {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  therapistName: string;
  therapistId: number;
  breaktype: string;
  breakduration: string;
  therapist: string;
  comments: string;
}
export interface SearchByValues {
  filterValue: string;
  filterId: number;
  filterType: string;
}


export interface AppointmentUpdate {
  tempHoldId: any;
  appointments: appointment[];
}



export interface IdentityModel_Name {
  id: number;
  name: string;
}

export interface DaysLocaleSorted_app_multiple {
  id?: number;
  name: string;
  setSelected: boolean;
}


export interface Calendar {
  Sun: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  Jan: string;
  Feb: string;
  Mar: string;
  Apr: string;
  Jun: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
}

export interface DaysModel {
  id: number;
  short: string;
  long: string;
  code: string;
}

export interface ClientPreference {
  id: number;
  ClientId: number;
  TypeName: string;
  TypeId: number;
}

export interface ClientSOAPNote {
  id: number;
  clientId: number;
  userId: number;
  userName: string;
  note: string;
  loggedTime: string;
}

export interface LocalizedMonthsModel {
  id: number;
  short: string;
  long: string;
  code: string;
}

export interface Deposit {
  id: number;
  AppointmentId: number;
  Amount: any;
  Gratuity: number;
  ServiceCharge: number;
}

export interface DepositAPIModel {
  id: number;
  appointmentId: number;
  amount: number;
  gratuity: number;
  serviceCharge: number;
  refundAmount: number;
  depositTransactionId: number;
  depositTransactionDetailId: number;
  refundTransactionId: number;
  isVoided: boolean;
  status: DepositTransactionStatus;
}
export class DayPassModel {
  id?:number;
  packageName?:string;
  clientIds?:string;
  bookedDate?:string;
  packageDetailsJson?:string;
  packagePrice?:number;
  paidAmount?:number;
  transactionId?:number;
  transactionDetailIds?:any;
  isActive?:boolean;
  status?:number;
  createdAt?:string;
}

export interface ClassDepositAPIModel {
  id: number;
  classClientId: number;
  gratuity: number;
  serviceCharge: number; 
  transactionStatus: DepositTransactionStatus;
  amountPaid: number; 
  depositTransactionId: number;
  depositTransactionDetailId: number;
  isVoided: boolean;
  refundAmount: number; 
  refundTransactionId: number;
}


export interface ShopItemDetails {
  id: number;
  externalPOSId: number;
  propertyId: number;
  subPropertyId: number;
  itemNumber: number;
  itemName: string;
  itemDescription: string;
  salesPrice: number;
  ProductPrice: number;
  memberPrice: number;
  suggestedPrice: number;
  costPrice: number;
  marginPercentage: number;
  barcode: string;
  secondaryBarcode: string;
  category: number;
  isCommissionable: boolean;
  isCommissionRequired: boolean;
  isSalesTax: boolean;
  isBevTax: boolean;
  itemType: number;
  isGroupingKey: boolean;
  isScaledItem: boolean;
  uom: string;
  isTeeTimeTrigger: boolean;
  isCartTrigger: boolean;
  isActive: boolean;
  isRequestName: boolean;
  isPMSPackageItem: boolean;
  isRequireComments: boolean;
  isOpenItem: boolean;
  isMultiPack: boolean;
  isGratuityAllowed: boolean;
  gratuity: number;
  isServiceChargeAllowed: boolean;
  serviceCharge: number;
  isPrintCartAgreement: boolean;
  isPrintClubAgreement: boolean;
  isPrintShoeAgreement: boolean;
  isPrintToCaddyShack: boolean;
  isPrintOnlyOnePerTansaction: boolean;
  isHangingTicket: boolean;
  isSmallTicket: boolean;
  linkedItemId: number;
  multiStartDate: Date;
  multiEndDate: Date;
  multiExpDays: number;
  multiSalesPrice: number;
  multiMemberPrice: number;
  multiCostPrice: number;
  multiGratuity: number;
  multiServiceCharge: number;
  multiPerSale: number;

}

export interface ShopItem {
  id: number;
  retailItemDetail: ShopItemDetails;
  outletItem: any[];
  packagedItem: any[];
}

export interface UserAlertsModal {
  id: number;
  message: string;
  priority: number;
}

export interface lostDeniedReason {
  id: number;
  reasonCode: string;
  isActive: boolean;
}

export interface serviceGroup {
  id: number;
  code: string;
  description: string;
}

export interface Service {
  serviceDetail: ServiceDetail;
  serviceAddOns: ServiceAddOn[];
  serviceLocations: ServiceLocation[];
  serviceEquipments: ServiceEquipment[];
  serviceTherapists: ServiceTherapist[];
  serviceMedicalConditions: ServiceMedicalCondition[];
  servicePriceTypes: any[];
  serviceSeasonalDates: SpaServiceSesonalDate[];
  serviceAvailableDays: ServiceAvailableDays;
  id: number;
}

export interface ServiceAvailableDays {
  id: number;
  serviceId: number;
  typeName: string;
  isAvailableOnSunday: boolean;
  isAvailableOnMonday: boolean;
  isAvailableOnTuesday: boolean;
  isAvailableOnWednesday: boolean;
  isAvailableOnThursday: boolean;
  isAvailableOnFriday: boolean;
  isAvailableOnSaturday: boolean;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceMedicalCondition {
  id: number;
  serviceId: number;
  medicalConditionId: number;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceTherapist {
  serviceId: number;
  therapistId: number;
  seniorityLevel?: string;
  propertyId: number;
  subPropertyId: number;
  id: number;
}

export interface ServiceEquipment {
  id: number;
  serviceId: number;
  equipmentId: number;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceLocation {
  id: number;
  serviceId: number;
  locationId: number;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceAddOn {
  id: number;
  serviceId: number;
  addOnId: number;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceDetail {
  code: string;
  description: string;
  serviceGroupId: number;
  effectiveFromDate: string;
  effectiveToDate: string;
  price: number;
  time: number;
  setupTime: number;
  breakDownTime: number;
  order: number;
  minimumAge: number;
  minimumGuest: number;
  maximumGuest: number;
  minimumStaff: number;
  maximumStaff: number;
  isAutoGratuity: boolean;
  gratuityPercent: number;
  gratuityAmount: number;
  isAutoServiceCharge: boolean;
  serviceChargePercent: number;
  serviceChargeAmount: number;
  isCommissionable: boolean;
  comments: string;
  policy: string;
  cancellationPolicy: string;
  requireStaffAtCheckin: boolean;
  isOffsite: boolean;
  isAvailableOnWeb: boolean;
  isInActive: boolean;
  retailItemId: number;
  propertyId: number;
  subPropertyId: number;
  id: number;
  colorCode: string;
  servicePriceTypes: any[];
}

export interface Therapist {
  code: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfHire: string;
  seniorityLevel: string;
  alsoKnownAs: string;
  allowGratuity: boolean;
  allowServiceCharge: boolean;
  allowCommission: boolean;
  isActive: boolean;
  listOrder: number;
  commissionClassId: number;
  propertyId: number;
  subPropertyId: number;
  id: number;
}




export interface GuestRetailTransactionHistory {
  transaction: Transaction[];
  itemDescription: any;
}
export interface TransactionWithItemNumber {
  id: number;
  transactionData: TransactionData;
  transactionDetails: TransactionDetailWithItemNumber[];
  transactionPayments: any[];
}


export interface Transaction {
  id: number;
  transactionData: TransactionData;
  transactionDetails: TransactionDetail[];
  transactionPayments: any[];
}

export interface TransactionDetailWithItemNumber {
  id: number;
  transactionId: number;
  lineNumber: number;
  itemId: number;
  serviceId: number;
  staffId: number;
  staffType?: any;
  quantitySold: number;
  unitPrice: number;
  discount: number;
  commission?: any;
  serviceChargeGratuity?: any;
  tax: number;
  totalAmount: number;
  outletId: number;
  propertyId: number;
  subPropertyId: number;
  itemDescription:string;
  itemNumber?:string;
}

export interface TransactionDetail {
  id: number;
  transactionId: number;
  lineNumber: number;
  itemId: number;
  serviceId: number;
  staffId: number;
  staffType?: any;
  quantitySold: number;
  unitPrice: number;
  discount: number;
  commission?: any;
  serviceChargeGratuity?: any;
  tax: number;
  totalAmount: number;
  outletId: number;
  propertyId: number;
  subPropertyId: number;
  itemDescription: string;
  customFeeId?:number;
  sourceTypeId? : number;
  sourceType?: CustomFeeSourceType;
}

export interface TransactionData {
  id: number;
  ticketNumber: string;
  transactionType: string;
  status: string;
  transactionDate: string;
  clerkId: number;
  totalPrice: number;
  totalTax: number;
  totalAmount: number;
  guestId: number;
  memberId: number;
  comment: string;
  stayId: number;
  isTaxExempt: boolean;
  isVoided: boolean;
  outletId: number;
  gratuity: number;
  serviceCharge: number;
  discount: number;
  propertyId: number;
  subPropertyId: number;
  retailTicketNumber: string;
}

export interface SystemConfig {
  id: number;
  switch: string;
  value: string | boolean;
  moduleId: number;
}
export interface SeasonalConfig {
  id: number;
  startDate: object;
  endDate: object;
  isApplyForAllDays: object;
  openTime: string;
  closeTime: string;
  seasonaldays: SeasonalDaysConfig[];
}
export interface ColorCodeConfig {
  id: number;
  status: string;
  switchName: string;
  colorCode: string;
}
export interface SeasonalDaysConfig {
  id: number;
  seasonalHourId: number;
  day: string;
  openTime: string;
  closeTime: string;
}
export interface PropertyConfig {
  propertyId: number;
  propertyName: string;
  zip: string;
  state: string;
  city: string;
  country: string;
  languageCode: string;
  tenantId: number;
  propertyCode: number;
  address1: string;
  address2: string;
  address3: string;
  propertyContacts: PhNumber[];
  imageReferenceId?: string;
}
export interface PhNumber {
  id: number;
  number: number;
  propertyId: string;
  contactTypeId: string | number;
  clientId: number;
}

export interface UserBreakPoint {
  userRoleId: number;
  breakPointNumber: number;
  allow: boolean;
  view: boolean;
}
export interface UserName {
  id: number;
  firstName: string;
  lastName: string;
}

export interface ManagementData {
  location: SpaServiceLocation[];
  therapist: TherapistDetails[];
  service: SpaServices[];
  package: PackageDetail[];
  client: ClientDetail[];
  addOn: ServiceAddOns[];
  linkCode: LinkCode[];
  appointmentConfigurations: SystemConfiguration[];
  breakType: BreakType[];
  clients: ClientDetail[];
  lostDeniedReasons: lostDeniedReason[];
  serviceGroups: serviceGroup[];
}

export interface linkMultipackData {
  appointmentId: number;
  multipackId?: number;
  price: number;
  serviceCharge: number;
  gratuity: number;
  serviceChargePercentage: number;
  gratuityPercentage: number;
}


export interface StaffTransactionDetail {
  StaffId: number;
  StaffType: string;
}

export interface Role {
  id: number;
  roleName: string;
  active: boolean;
}

export interface RoleSetup {
  id?: number;
  code: string;
  description: string;
  active: boolean;
  TenantId?: number;
  propertyId?: number;
  subPropertyId?: number;
  productId: number[];
}

export interface CommissionTemplate {
  id: number;
  templateCode: string;
  templateName: string;
  commissionClassId: number;
  productId: number;
  splitDetail: CommissionTemplateSplit[];
}

export interface CommissionTemplateSplit {
  id: number;
  commissionTemplateId: number;
  levelDescription: string;
  percentage: any;
}



export interface popupConfig {
  operation: string;
  maxListOrder?: number;
  GridOperationType?: GridOperationType;
  fromScreen?: string;
  isClassView? : boolean;
}

export interface ImageData {
  id: number;
  referenceType: string;
  referenceId: number;
  sequenceNo: number;
  contentType: string;
  data: any[];
  thumbnailData: any[];
  imageReferenceId?: string;
}

export interface CalendarGrid {
  timing: string;
  data: CalendarGridData[];
}

export interface CalendarGridData {
  spaDayout: string;
  noschedule: string;
  hdr: CalendarHeader[];
  tempdata: any[];
  data: any[];
}

export interface MoreFilterOptions {
  ShowMoreFilters: boolean;
  moreData: any;
  selectedMoreData: any;
  top: number;
  left: number;
  belongto: string;
  fromPage: string;
}


export interface CalendarHeader {
  id: number;
  title: string;
  subtitle: string;
  img: image;
}

export interface image {
  contentType: string;
  data: string;
}

export interface KeyValuePair {
  key: any;
  value: any;
}

export interface OverBookOptions {
  canOverbook: boolean;
  isClientOverbook: boolean;
  isTherapistOverbook: boolean;
  isLocationOverbook: boolean;
}

export interface clientMultiPack {
  id: number;
  retailItemId: number;
  transactionId: number;
  transactionDetailId: number;
  clientId: number;
  quanitity: number;
  remaining: number;
  linkedRetailItemId: number;
  soldOn: Date;
  startDate: Date;
  expirationDate: Date;
  salePrice: number;
  gratuity: number;
  serviceCharge: number;
  tax: number;
  isUnlimited: boolean;
  isReturned: boolean;
  isExpired: boolean;
}

export interface AppointmentGlobalSearch {
  searchText: string;
  searchDate: boolean;
  searchDateValue: string;
}

export interface PackageMoveValidators {
  maxNoDays: number;
  minDate: Date;
  maxDate: Date;
  packageStartDate: Date;
  packageEndDate: Date;
  availableDays: PackageAvailableDays;
  yield?: PackageYield[];
  packageAppointmentDates: Date[];
}

export interface AppointmentFilterOutput {
  serviceViewModels: ServiceViewModel[];
  locationViewModels: LocationViewModel[];
  therapistViewModels: TherapistViewModel[];
  therapistLocationModels: TherapistLocationModel[];
}

export interface GroupAppointmentSearchModel {
  appointmentId?: number;
  clientId?: number;
  clientName?: string;
  appointmentDate?: Date | string;
  startTime?: Date | string;
  endTime?: Date | string;
  bookingId?: number;
  bookingName?: string;
  groupAppointmentId?: number | null;
  hotelBookingId?: string;
  memberGuest?: boolean;
  status?: string;
}

export interface ServiceViewModel {
  id: number;
  code: string;
  description: string;
  serviceGroupDescription: string;
  serviceGroupId: number;
  isActive: boolean;
  effectiveFromDate: Date | string;
  effectiveToDate: Date | string;
  cancellationPolicy: string;
  price: number;
  servicePriceTypes: any[]
  duration: number;
  setupTime: number;
  breakDownTime: number;
  serviceComment: string;
  servicePolicy: string;
  minimumGuest: number;
  maximumGuest: number;
  minimumStaff: number;
  maximumStaff: number;
  isAutoGratuity: boolean;
  gratuityPercent: number;
  gratuityAmount: number;
  isAutoServiceCharge: boolean;
  serviceChargePercent: number;
  serviceChargeAmount: number;
  retailItemId: number;
  colorCode: string;
  isOffsite: boolean;
  manualStaffSelectionEnabled?: boolean;
  servicePriceType?: any[];
}

export interface LocationViewModel {
  serviceId: number;
  locationId: number;
  locationCode: string;
  locationDescription: string;
}

export interface TherapistViewModel {
  serviceId: number;
  therapistId: number;
  therapistName: string;
}
export interface TherapistLocationModel {
  therapistId: number;
  locationId: number;
}

export interface AppointmentWaitListClient {
  id: number;
  appointmentWaitListId: number;
  clientId: number;
  therapistId: number;
  comments: string;
  firstName?: string;
  lastName?: string;
  guestId?: any;
}
export interface AppointmentLostDeniedBusinessClient {
  id: number;
  LostDeniedBusinessId: number;
  clientId: number;
  therapistId: number;
  comments: string;
  firstName?: string;
  lastName?: string;
  guestId?: any;
}

export interface AppointmentWaitListBillingDetail {
  id: number;
  appointmentWaitListId: number;
  billingDetail: string;
}

export interface AppointmentLostDeniedBusinessBillingDetail {
  id: number;
  appointmentLostDeniedBusinessId: number;
  billingDetail: string;
}

export interface AppointmentWaitList {
  id: number;
  startTime: Date;
  endTime: Date;
  serviceId: number;
  serviceGroupId: number;
  locationId: number;
  priority: number;
  comments: string;
  confirmed?: boolean;
  createdAt?: Date | string;
  createdBy?: number;
  modifiedAt?: Date | string;
  modifiedBy?: number;
  client: AppointmentWaitListClient[];
  billingDetail?: AppointmentWaitListBillingDetail;
}


export interface WaitListAppointmentInfo {
  id: number;
  startTime: Date | string;
  endTime: Date | string;
  startDate: Date | string;
  endDate: Date | string;
  serviceId: number;
  serviceGroupId: number;
  selectedServiceGroup: number[];
  locationId: number;
  priority: number;
  comments: string;
  therapistHelperText?: string;
  client: AppointmentWaitListClient[];
  guaranteePaymentMethod?: GuaranteeMethodJSONModel,
  billingDetail?: AppointmentWaitListBillingDetail;
}

export interface LostDeniedReasonBusiness {
  id: number;
  startTime: Date;
  endTime: Date;
  serviceId: number;
  serviceGroupId: number;
  lostDeniedReasonId: number;
  price: number;
  comments: string;
  therapistId: number;
  waitListId?: number;
  confirmed?: boolean;
  createdAt?: Date | string;
  createdBy?: number;
  modifiedAt?: Date | string;
  modifiedBy?: number;
  client: AppointmentLostDeniedBusinessClient[];
  //guaranteePaymentMethod?: GuaranteeMethodJSONModel,
  billingDetail?: AppointmentLostDeniedBusinessBillingDetail;
}

export interface LostDeniedReasonBusinessInfo {
  id: number;
  startTime: Date | string;
  endTime: Date | string;
  startDate: Date | string;
  endDate: Date | string;
  serviceId: number;
  serviceGroupId: number;
  lostDeniedReasonId: number;
  price: number;
  comments: string;
  therapistId: number;
  waitListId?: number;
  therapistHelperText?: string;
  client: AppointmentLostDeniedBusinessClient[];
  guaranteePaymentMethod?: GuaranteeMethodJSONModel,
  billingDetail?: AppointmentLostDeniedBusinessBillingDetail;
}

export enum WaitListPriority {
  High = 1,
  Medium = 2,
  Low = 3
}

export interface MedicalConditionWarning {
  MedicalConditionCode: string;
  MedicalDescription: string;
  MedicalConditionMatched: string;
  MedicalWarning: string;
}

export interface ServiceClientMedicalWarning {
  ServiceId: number;
  ClientId: number;
  MedicalConditionWarning: MedicalConditionWarning[];
}
export interface AppointmentPrice {
  serviceCharge: number;
  gratuity: number;
  totalAmount: number;
  price: number;
  serviceChargePercent: number;
  gratuityPercent: number;
  yieldPrice?: number;
}

export interface asideFilterModel {
  id: number;
  name: string;
  isAll: boolean;
}

export interface OutletSubProperty {
  subPropertyID: number;
  subPropertyCode: string;
  subPropertyName: string;
  propertyID: number;
  isActive: boolean;
  profitCenter?: string;
}



export interface NoDataModel {
  inputText: string;
  noDataId: noRecordsType;
  noDataFontSize?: number;
}

export interface Device {
  name: string;
  handle: string;
}


// Request Model for GetPaymentHandles:
export interface HandleRequest {
  tenderId: string; // (Required)
  inquiryInfo?: Inquiry; // (Required)
}

export interface Inquiry {
  id?: string;
  type?: string; // (Required)
  name?: string;
  cardData?: CardData;
  TenderId?: string;
}

export interface CardData {
  track1?: string;
  track2?: string;
  encryptedData: string;
}

// -----------------------------------------------------------------------------------------
// Response Model for GetPaymentHandles:

export interface HandleResponse {
  status: string;
  errorMessage: string;
  paymentHandle: PayHandle[];
}

export interface PayHandle {
  handle: string;
  inquiryInfo: Inquiry; // (as passed in request)
  name: string;
  type: string;
  balance: number;
  isBalanceVisible: boolean;
  isPartialTenderAllowed: boolean;
  isRefundable: boolean;
  additionalAttributes: object;
  allowedAPIs: string[];
}

export interface HandleData {
  configurationId: number;
  adapter: string;
  id: string;
  type: string;
  name: string;
}

export interface ClientCreditCardInfo {
  id: number;
  clientId: number;
  tokenTransId: number;
  isActive: boolean;
  createdTime: Date | string | null;
}
export interface CreateTokenRequest {
  handle: string;
  inquirerInfo: Inquiry;
}

export interface TokentransactionInfo {
  payAgentId: string;
  status: string;
  account: Account;
  cardInfo: PayAgentCardInfo;
  transactionDetails: string;
  transactionKey: string;
}

export interface PaymentTransactionInfo {
  payAgentId: string;
  status: string;
  account: Account;
  transactionDetails: string;
  transactionKey: string;
}

export interface StoreTokenRequest {
  payAgentResponse: PaymentTransactionInfo;
  cardInfo: CardInfo;
  tenderId: number;
}

export interface TokenRequest {
  PayAgentResponse: TokentransactionInfo;
}

export interface Account {
  id: string;
  name: string;
  type: string;
}

export interface PayAgentCardInfo {
  entryMode: string;
  issuer: string;
  cardExpiration: string;
  cardType: string;
  token: string;
}

export interface CardInfo {
  cardNumber: string;
  cardHolderName: string;
  entryMode: string;
  issuerType: string;
  cardExpiration: string;
  cardType: string;
}

export interface PaymentBaseResponse {
  transactionId: number;
  status: boolean;
  errorMessage: string;
}

export enum PaymentMethods {
  Cash = 1,
  CreditCard = 2,
  RoomCharge = 3,
  PendingSettlement = 4,
  IDTECH = 5,
  RainCheck = 6,
  CardOnFile = 22,
  ARPost = 7,
  RedeemPoint = 8,
  CreditBook = 9,
  CompRedemption = 10,
  OfferRedemption = 11,
  V1GiftCard = 12,
  ExternalGiftCard = 13,
  V1GiftCardIdTech = 14,
  ExternalGiftCardIdTech = 15,
  ARAcctPost = 16,
  AgilysysGiftCard = 17,
  AgilysysGiftCardIdTech = 18,
  CompSlipRedemption = 19,
  GuaranteeMethod = 99,
  Other = 44
}

export const IDTechHandle = {
  handle: 'IDTech SRED Key',
  name: 'IDTech SRED Key'
};

export const RGuestBookSwitch = {
  RBOOK_BOOKINGDAYSOUT: 'RBOOK_BOOKING_DAYS_OUT',
  RBOOK_BOOKINGDAYSIN: 'RBOOK_BOOKING_DAYS_IN',
  RBOOK_ALLOWAPPOINTMENTEDIT: 'RBOOK_ALLOW_APPOINTMENT_EDIT',
  RBOOK_ALLOWAPPOINTMENTCANCEL: 'RBOOK_ALLOW_APPOINTMENT_CANCEL',
  RBOOK_CANCELDAYSOUT: 'RBOOK_CANCEL_DAYS_OUT',
  RBOOK_CANCELPOLICY: 'RBOOK_CANCEL_POLICY',
  RBOOK_GRIDINTERVAL: 'RBOOK_GRIDINTERVAL',
  RBOOK_ENABLEMINIMUMINTERVAL: 'RBOOK_ENABLEMINIMUMINTERVAL',
  RBOOK_MININMUMINTERVAL: 'RBOOK_MININMUMINTERVAL',
  RBOOK_MINIMUM_GAP_ADDITIONAL_VALIDATAION : 'RBOOK_MINIMUM_GAP_ADDITIONAL_VALIDATAION',
  RBOOK_REQUIREAPPOINTMENTDEPOSIT: 'RBOOK_REQUIRE_APPOINTMENT_DEPOSIT',
  RBOOK_REQUIREAPPOINTMENTDEPOSITTYPE: 'RBOOK_REQUIRE_APPOINTMENT_DEPOSIT_TYPE',
  RBOOK_INCLUDEGRATUITY: 'RBOOK_INCLUDE_GRATUITY',
  RBOOK_INCLUDESERVICECHARGE: 'RBOOK_INCLUDE_SERVICE_CHARGE',
  RBOOK_THERAPISTDISPLAY: 'RBOOK_THERAPIST_DISPLAY',
  RBOOK_THERAPISTDISPLAYIMAGE: 'RBOOK_DISPLAY_THERAPIST_IMAGE',
  RBOOK_DEPOSITVALUE: 'RBOOK_DEPOSIT_VALUE',
  RBOOK_OUTLET: 'RBOOK_OUTLET',
  RBOOK_IS_DEPOSITCUSTOMPERCENTAGE: 'RBOOK_IS_DEPOSIT_CUSTOM_PERCENTAGE',
  RBOOK_TERMINAL: 'RBOOK_TERMINAL',
  RBOOK_LIMIT_ADD_ONS: 'RBOOK_LIMIT_ADD_ONS',
  RBOOK_MAXIMUM_ADD_ONS: 'RBOOK_MAXIMUM_ADD_ONS',
  RBOOK_AUTOBOOK_THERAPIST: 'RBOOK_AUTOBOOK_THERAPIST'
};

export const ACRSwitch = {
  bookingDaysOut: 'ACR_BOOKING_DAYS_OUT',
  bookingDaysIn: 'ACR_BOOKING_DAYS_IN',
  allowAppointmentEdit: 'ACR_ALLOW_APPOINTMENT_EDIT',
  gridInterval: 'ACR_GRIDINTERVAL',
  requireAppointmentDeposit: 'ACR_REQUIRE_APPOINTMENT_DEPOSIT',
  pricePercentage: 'ACR_REQUIRE_APPOINTMENT_DEPOSIT_TYPE',
  includeGratuity: 'ACR_INCLUDE_GRATUITY',
  includeServiceCharge: 'ACR_INCLUDE_SERVICE_CHARGE',
  depositValue: 'ACR_DEPOSIT_VALUE',
  outlet: 'ACR_OUTLET',
  isCustomPercentage: 'ACR_IS_DEPOSIT_CUSTOM_PERCENTAGE',
  terminal: 'ACR_TERMINAL',
  limitAddOns: 'ACR_LIMIT_ADD_ONS',
  maximumAddOns: 'ACR_MAXIMUM_ADD_ONS',
  autoBookTherapist: 'ACR_AUTOBOOK_THERAPIST'
};

export const NotificationConfigurationSwitch = {
  EMAILNOTIFICATION_EDITORMOVEAPPOINTMENT: 'AUTOMATIC_MAIL_ON_EDITORMOVE_APPOINTMENT',
  SMSNOTIFICATION_EDITORMOVEAPPOINTMENT: 'AUTOMATIC_SMS_ON_EDITORMOVE_APPOINTMENT',
  EMAILNOTIFICATION_BOOKAPPOINTMENT: 'AUTOMATIC_MAIL_ON_APPOINTMENT_BOOKING',
  SMSNOTIFICATION_BOOKAPPOINTMENT: 'AUTOMATIC_SMS_ON_APPOINTMENT_BOOKING'
};

export const DataTypeConstants = {
  Boolean: 'Boolean',
  Int32: 'Int32'
};

export const IDTech = {
  id: 'idtech'
};

export enum PaymentErrorCodes {
  ClientInvalidRequest = 9000,
  ClientInvalidConfiguration = 9001,
  ClientInvalidDeviceGuid = 9002,
  ClientInvalidTransactionId = 9003,
  ClientInvalidToken = 9004,
  ClientInvalidTransactionState = 9005,
  ClientInvalidTransactionFollowOnData = 9006,
  ClientAbortedRequest = 9099,
  CardError = 9500,
  CardErrorUnsupportedCardType = 9501,
  CardErrorInvalidAccountNumber = 9502,
  CardErrorInvalidExpirationDate = 9503,
  CardErrorUnsupportedCardIssuer = 9504,
  CardDeclined = 9600,
  CardDeclinedExpired = 9601,
  CardDeclinedLimit = 9602,
  CardDeclinedReferral = 9603,
  CardDeclinedCvv = 9604,
  CardDeclinedAvs = 9605,
  CardDeclinedChipDecline = 9606,
  DeviceError = 9200,
  DeviceInvalidConfiguration = 9201,
  DeviceNotAvailable = 9202,
  DeviceNotReady = 9203,
  DeviceOperationUnsupported = 9204,
  DeviceOperationAborted = 9205,
  DeviceReadWithoutPrompt = 9206,
  DeviceNoDataAvailable = 9207,
  DeviceInvalidData = 9208,
  DeviceUserPressedCancel = 9209,
  GatewayError = 9300,
  GatewayAuthenticationFailed = 9301,
  GatewayUnavailable = 9302,
  GatewayProcessorUnavailable = 9303,
  GatewayUnsupportedRequest = 9304,
  ServerError = 9100,
  ServerUnsupportedRequest = 9101,
  ServerInvalidConfiguration = 9102,
  TransactionError = 9400,
  TransactionNotFound = 9401,
  TransactionInvalidState = 9402,
  TransactionDuplicate = 9403,
  // Added for IG PMAgent Timeout
  TransactionTimeOut = 408,
  PaymentManagerTimedOut = 10060
}

export interface GlobalConfiguration {
  Switches: SwitchConfig;
}

export interface SwitchConfig {
  GuestProfileRequired: boolean;
}

export interface VATConfiguration {
  id: number;
  propertyId: number;
  vatNumber: string;
  serviceChargeOnGross: boolean;
  serviceChargeOnNet: boolean;
  vatEnbledForServiceCharge: boolean;
  serviceChargeVATId: number;
  gratuityOnGross: boolean;
  gratuityOnNet: boolean;
  itemCommissionOnGross: boolean;
  itemCommissionOnNet: boolean;
  revenueCommissionOnGross: boolean;
  revenueCommissionOnNet: boolean;
}

export enum GrossNetEnum {
  Gross = 1,
  Net = 2
}

export interface CheckData {
  gratuity: number;
  serviceCharge: number;
}

export interface TransactionAdapter {
  lineItems: LineItem[];
  checkData: CheckData;
  checkJSON: string;
}
export interface LineItem {
  externalPOSItemId: number;
  quantity: number;
  unitPrice: number;
}

export interface PriceModel {
  priceForGratuity: number;
  priceForServiceCharge: number;
}

export interface NotificationModel {
  appointmentId: number[];
  classClientId?: number[];
  status: string;
  emailId: string;
  phoneNumber: string;
  canSendemail: boolean;
  canSendSMS: boolean;
  canSendIntakeForm: boolean;
  canSendEForm: boolean;
  reportQuery?: ReportAPIModel;
  eformInfo: any;
  canSendAuthorize?: boolean;
  isGroupNotify?: boolean;
  isManualTrigger?: boolean;
}

export interface EformNotificationModel {
  id: number;
  appointmentId: number;
  eFormId: string;
  eFormName: string;
  notificationType: EformNotificationTypes;
  isActive: boolean;
  isReceived: boolean;
  createdDate: Date;
  createdBy: number;
  receivedDate: Date;
}
export enum EformNotificationTypes {
  Manual = 1,
  Automatic = 2,
  Generate = 3
}

export interface RetailReceiptNotificationModel {
  appointmentId: number[];
  action: string;
  reportQuery: ReportAPIModel;
  isRetailNotification: boolean;
  emailId: any[];
  transactionId: number;
  isDistributionListRequired: boolean
}

export interface DepositRefund {
  appointmentId: number;
  transactionId:number;
  transactionDetailId: number;
  refundTransactionId: number;
  depositRefundAmount: number
}

export interface TherapistAvailability {
  therapistDetails: StafftDetails[];
  locations: Location[];
}

export interface StafftDetails {
  therapist: Therapist;
  scheduleTime: ScheduleTime[];
  occupiedTime: ScheduleTime[];
}

export interface ScheduleTime {
  therapistId: number;
  startTime: Date;
  endTime: Date;
  availableOnCall: boolean;
  locationId: number;
  breakType: number;
  isBreakTypeUtilized: boolean;
}

export interface LocationAvailability {
  location: Location;
  occupiedTime: OccupiedTime[];
}

export interface OccupiedTime {
  startTime: Date;
  endTime: Date;
}

export interface TherapistMaxServiceDetail {
  serviceId: number;
  therapistId: number;
  maxNumberOfServices: number;
  bookedServices: number;
  remainingServices: number;
  therapistSchedule?: any[];
}

export interface ServiceEquipmentMaintenance {
  iD: number;
  equipmentID: number;
  quantity: number;
  startTime: Date;
  endTime: Date;
  comments: string;
}

export interface UserClient {
  id: number;
  name: string;
}

export interface GenderOverrideMessage {
  clientName: string;
  clientPreferredGender: string;
  therapistGender: string;
  isOverriden: boolean;
  appointmentId: number;
  serviceId: number;
  therapistId: number;
  clientId: number;
}

export interface GenderOverrideTransLog {
  appointmentIds: number[]
}

export interface LogEntry {
  oldValue: any;
  newValue: any;
  clientId: number;
  logType: string;
  description: string;
}
export enum DefaultGridType {
  Standard = 1,
  Minimal = 2,
  Compact = 3
}

export class AppointmnetsAddons {
  addOnId: number;
  addOnName: string;
  appointmentId: number;
  certifiedTherapist = new Array<number>();
  id: number;
  isCommissionable: boolean;
  price: number;
  selected: boolean;
  serviceId: number;
  therapistId: number;
}

export class checkoutAppoitnmentAddons {
  appointmentId: number;
  serviceId: number;
  addOnModels = new Array<AppointmnetsAddons>();
}

export class EquipmentQuantity {
  equipmentId: number;
  availableQuantity: number;
  usedQuantity: number;
  availableSlots: number;
}
export class ServiceEquipments {
  serviceId: number;
  equipmentId: number;
  quantity: number;
}
export class Equipment {
  id: number;
  code: string;
  description: string;
  quantity: number;
}
export class EquipmentQuantityDetails {
  serviceEquipments = new Array<ServiceEquipments>();
  equipments = new Array<Equipment>();
}

export interface SchedulingAssistant {
  id: number;
  displayName: string;
  comments?: string;
  listOrder: number;
  slots: SchedulingAssistantSlots[];
  innerData: SchedulingAssistantInnerData[];
}

export interface SchedulingAssistantInnerData {
  id: number;
  displayName: string;
  listOrder: number;
  slots: SchedulingAssistantSlots[];
}
export interface SchedulingAssistantSlots {
  localizedTime: string;
  dateTime: Date;
  isAvailable: boolean;
  isServiceAvailable: boolean;
}

export enum SchedulingAssistantView {
  Therapist = 1,
  Location
}

export interface SlotResourceFilter {
  therapistIds: number[];
  locationIds: number[];
  ignoreSetupBreakdownTime: boolean;
}

export interface Options {
  key: any;
  label: any;
  ischecked?: boolean;
  value?: any;
}

export interface ContactLog {
  id: number;
  productId?: number;
  logTypeId: number;
  logNotes: string;
  logNotes_short: string;
  logDate: Date | string;
  dueDate?: Date | string;
  expiryDate?: Date | string;
  isAlert: boolean;
  isPrivate: boolean;
  isGroup: boolean;
  createdAt?: Date;
  createdBy?: number;
  modifiedAt?: Date;
  modifiedBy?: number;
}

export interface GuestContact {
  id: number,
  primaryGuestId: string,
  contactGuestId: string,
  contactGuestFirstName: string,
  contactGuestLastName: string,
  contactRelationshipId: number,
  contactRelationshipName: string
}

export interface AppointmentOtherDetails {
  clientComments: string;
  appointmentComments: string;
  checkoutComments: string;
}

export const packageType = {
  service : 'service',
  retailItems : 'retailItems'
}

export interface GuestBasicInformation {
  id: number,
  guestId: string,
  firstName: string,
  lastName: string,
  imageContent: ImageData,
  emailAddress: string,
  phoneNumber: string
}

export interface ClientConfiguration {
  CLIENT_FIRST_NAME: boolean
  CLIENT_LAST_NAME: boolean
  CLIENT_TITLE: boolean
  CLIENT_ADDRESS_LINE_1: boolean
  CLIENT_POSTAL_CODE: boolean
  CLIENT_CITY: boolean
  CLIENT_STATE: boolean
  CLIENT_GENDER: boolean
  CLIENT_EMAIL: boolean
  CLIENT_COUNTRY: boolean
  CLIENT_PHONE: boolean
  CUSTOM_FIELD_1: boolean
  CUSTOM_FIELD_2: boolean
  CUSTOM_FIELD_3: boolean
  CUSTOM_FIELD_4: boolean
  CUSTOM_FIELD_5: boolean
  CLIENT_BIRTHDAY: boolean
  CLIENT_CREDIT_CARD: boolean
  CLIENT_SPA_GUEST_TYPE: boolean
}

export interface CustomFieldInfo {
  id: number
  columnName: string
  fieldName: string
  moveRecordFromClient: boolean
  requiredOnAppointment: boolean
  displayOn: number
  customFieldValues: any[]
}


