import { Injectable } from '@angular/core';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableHeaderOptions, TableOptions, DropdownOptions } from 'src/app/common/Models/ag-models';
import { uniqBy } from 'lodash';
import { Localization } from 'src/app/common/localization/localization';
import { JobSchedulerDataServices } from 'src/app/common/dataservices/notification/job-scheduler.data.service';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { API, JobStatus, UI } from 'src/app/common/shared/shared/event-scheduler-history/event-scheduler-history.model';
import { Distribution, DistributionType } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { HttpServiceCall } from "../../shared/service/http-call.service";
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { environment } from 'src/environments/environment';
import { CommonControllersRoutes } from 'src/app/common/communication/common-route';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EventSchedulerHistoryBusiness {
    captions: any;
    schedulerCaptions: any;
    usersDropDownOption: DropdownOptions[];
    usersList: { id: number; userName: string; }[];
    constructor(private localization: Localization
        , private jobSchedulerDataServices: JobSchedulerDataServices
        , private notificationConfigurationService: NotificationConfigurationService, private http: HttpServiceCall
        , private util: CommonUtilities) {
        this.captions = this.localization.captions;
        this.schedulerCaptions = this.localization.captions.ReportSchedular;
    }

    public getHeaderOptions(): TableHeaderOptions[] {
        return [
            {
                key: 'referenceId',
                displayNameId: 'tbl_hdr_Reference',
                displayName: this.captions.tbl_hdr_ReferenceID,
                searchable: true,
                sorting: true,
                sortingKey: 'referenceId'
            },
            {
                key: 'retryReference',
                displayNameId: 'tbl_hdr_Reference',
                displayName: this.schedulerCaptions.tbl_hdr_RetryReference,
                searchable: true,
                sorting: false,
                sortingKey: 'retryReference'
            },
            {
                key: 'jobType',
                displayNameId: 'tbl_hdr_JobType',
                displayName: this.captions.tbl_hdr_JobType,
                searchable: true,
                sorting: true,
                sortingKey: 'jobType'
            },
            {
                key: 'jobName',
                displayNameId: 'tbl_hdr_JobName',
                displayName: this.captions.tbl_hdr_JobName,
                searchable: true,
                sorting: true,
                sortingKey: 'jobName'
            },
            {
                key: 'remindAt',
                displayNameId: 'tbl_hdr_JobRanAt',
                displayName: this.captions.tbl_hdr_JobRanAt,
                searchable: false,
                sorting: true,
                sortingKey: 'remindAtSortKey'
            },
            {
                key: 'distributionList',
                displayNameId: 'tbl_hdr_EmailSendTo',
                displayName: this.captions.tbl_hdr_EmailSendTo,
                searchable: false,
                sorting: true,
                sortingKey: 'distributionList'
            },
            //{
            //   key: 'emailSendStatus',
            //   displayNameId: 'tbl_hdr_EmailSendStatus',
            //   displayName: this.captions.tbl_hdr_EmailSendStatus,
            //   searchable: false,
            //   sorting: true,
            //   sortingKey: 'emailSendStatus',
            //   isHidden: true
            // },
            // {
            //   key: 'emailSendAt',
            //   displayNameId: 'tbl_hdr_EmailSendAt',
            //   displayName: this.captions.tbl_hdr_EmailSendAt,
            //   searchable: false,
            //   sorting: true,
            //   sortingKey: 'emailSendAt',
            //   isHidden: true
            // },
            {
                key: 'sFTPFolderPath',
                displayNameId: 'tbl_hdr_SFTPFolderPath',
                displayName: this.captions.tbl_hdr_SFTPFolderPath,
                searchable: false,
                sorting: true,
                sortingKey: 'sFTPFolderPath',
            },
            {
                key: 'jobStatus',
                displayNameId: 'tbl_hdr_JobStatus',
                displayName: this.captions.tbl_hdr_JobStatus,
                searchable: true,
                sorting: true,
                sortingKey: 'jobStatus',
                templateName: ActionTypeEnum.custom
            },
            {
                key: 'errorMessage',
                displayNameId: 'tbl_hdr_jobErrorMessage',
                displayName: this.captions.tbl_hdr_jobErrorMessage,
                searchable: true,
                sorting: false,
                sortingKey: 'errorMessage'
            },
            {
                key: ActionTypeEnum.menu,
                displayName: this.captions.tbl_hdr_actions,
                displayNameId: 'tbl_hdr_actions',
                templateName: ActionTypeEnum.menu
            }
        ];
    }

    public getTableOptions(): TableOptions {
        return {
            defaultsortingColoumnKey: 'referenceId',
            defaultSortOrder: SorTypeEnum.desc,
            showTotalRecords:true,
            columnFreeze: {
                firstColumn: false,
                lastColumn: false
            },
            ignoreSort: true,
            enablePagination: true
        };
    }

    getJobTypeFilterOptions() {
        return [
            {
                id: 0,
                value: "All",
                viewValue: "All"
            },
            {
                id: 1,
                value: "Generic Extract",
                viewValue: "Generic Extract"
            },
            {
                id: 2,
                value: "Reports",
                viewValue: "Reports"
            },
            // {
            //     id: 3,
            //     value: "Saved Queries",
            //     viewValue: "Saved Queries"
            // },
            {
                id: 4,
                value: "API",
                viewValue: "API"
            },
            {
                id: 5,
                value: "Adhoc Report",
                viewValue: "Adhoc Report"
            }
        ]
    }

    getStatusFilterOptions() {
        return [
            {
                id: 0,
                value: "All",
                viewValue: "All"
            },
            {
                id: 1,
                value: "Initiated",
                viewValue: "Initiated"
            },
            {
                id: 2,
                value: "Running",
                viewValue: "Running"
            },
            {
                id: 3,
                value: "Completed",
                viewValue: "Completed"
            },
            {
                id: 4,
                value: "Failed",
                viewValue: "Failed"
            }
        ]
    }

    getTableMenus(status: JobStatus, isDowloadAllowed?: boolean): UI.ScheduleHistoryActions[] {
        let allowRetry: boolean = false;
        if (status === JobStatus.Failed) {
            allowRetry = true;
        }
        return [
            { id: UI.ScheduleHistoryActionOptions.download, menu: 'Download', isDisabled: !isDowloadAllowed },
            { id: UI.ScheduleHistoryActionOptions.retry, menu: 'Retry', isDisabled: !allowRetry },
            //{ id: UI.ScheduleHistoryActionOptions.emailSentStatus, menu: 'Email sent status', isDisabled: false }
        ]
    }

    public async getJobHistoryByFilter(filter: API.ScheduleJobHistoryFilter): Promise<UI.ScheduleJobHistory[]> {
        const jobHistories = await this.jobSchedulerDataServices.GetAllJobHistory(filter);
        let allDistList: any;
        await this.getDistributionList().then(res => {
            allDistList = res.filter(x => x.distributionType == DistributionType.EMAIL || x.distributionType == DistributionType.BOTH);
        });
        return jobHistories.map(o => this.APIMapper(o, allDistList));
    }

    public async retryJobManually(id: number): Promise<boolean> {
        const retried = await this.jobSchedulerDataServices.retryJobManually(id);
        return retried;
    }

    public async exportJobOutput(id: number, fileType: string, fileName: string): Promise<void> {
        const downloadUrl = environment["CommonGateway"] + '/' + CommonControllersRoutes.ExportJobOutput+ '/' + id;
        fileName = `${fileName}.${fileType}`
        this.downloadFile(downloadUrl, fileType).subscribe((blob: Blob) => {
            saveAs(blob, fileName);
          });
    }

    async getDistributionList(): Promise<Distribution[]> {
        return await this.notificationConfigurationService.getAllDistributionByProductId();
    }

    private APIMapper(jobHistory: API.ScheduleJobHistory, distList: Distribution[]): UI.ScheduleJobHistory {
        return {
            id : jobHistory.id,
            referenceId: jobHistory.referenceId,
            retryReference: jobHistory?.retryReference ?? '',
            jobType: jobHistory.jobType,
            jobName: jobHistory.jobName,
            remindAt: jobHistory.remindAt ? this.localization.LocalizeDateTimeFormatDDMMMMYYYY(jobHistory.remindAt) : '',
            remindAtSortKey: jobHistory.remindAt ? this.localization.getDate(jobHistory.remindAt).getTime() : '',
            distributionList: jobHistory.distributionListId > 0 ? distList.find(x => x.id == jobHistory.distributionListId)?.distributionName : '',
            emailSendStatus: jobHistory.emailSendStatus,
            emailSendAt: jobHistory.emailSendAt != '' ? this.localization.localizeUTCDate(jobHistory.emailSendAt, true) : '',
            sFTPFolderPath: jobHistory.sftpFolderPath,
            menuList: this.getTableMenus(jobHistory.jobStatus, jobHistory.isDownloadAllowed),
            jobStatus: jobHistory.jobStatus,
            downloadFormat: jobHistory.downloadFormat,
            errorMessage: jobHistory.errorMessage
        } as UI.ScheduleJobHistory;
    }

    downloadFile(url: string, fileType: string): Observable<Blob> {
        return this.http.getBlob(url).pipe(
          map((res: Blob) => {
            return new Blob([res], { type: fileType });
          })
        );
      }
}
