import { Localization } from 'src/app/common/localization/localization';
import { Host,ReportBreakPoint } from 'src/app/shared/globalsContant';
import { ReportSelector_, ReportControls } from '../report.model';
import {JasperReportUrlPath} from 'src/app/reports/common/report.constants';


export class ReportSelectorBuilder {
	constructor(private localize: Localization) {
    }
    private captions: any = this.localize.captions.reports;


    public reportSelector: ReportSelector_[] = [
        { id: 1, group: "APPOINTMENT", code: "AppointmentListing", value: this.captions.AppointmentListing, breakPointNumber: ReportBreakPoint.AppointmentListing, IsAuthorized: true, enableJasperReports : true,
        reportUrlpath : JasperReportUrlPath.AppointmentListingUrlPath },
        { id: 2, group: "APPOINTMENT", code: "AppointmentByLocation", value: this.captions.AppointmentListingbyLocation, breakPointNumber: ReportBreakPoint.AppointmentListingbyLocation, IsAuthorized: true },
        { id: 3, group: "APPOINTMENT", code: "AppointmentByStaffMember", value: this.captions.AppointmentListingbyStaffMember, breakPointNumber: ReportBreakPoint.AppointmentListingbyStaffMember, IsAuthorized: true },
        { id: 4, group: "APPOINTMENT", code: "AppointmentByLinkCode", value: this.captions.AppointmentListingbyColorCode, breakPointNumber: ReportBreakPoint.AppointmentListingbyColorCode, IsAuthorized: true },
        { id: 5, group: "CLIENT", code: "Itinerary", value: this.captions.ClientItinerary, breakPointNumber: ReportBreakPoint.ClientItinerary, IsAuthorized: true },
        { id: 6, group: "POS", code: "CommissionReport", value: this.captions.CommissionReport, breakPointNumber: ReportBreakPoint.CommissionReport, IsAuthorized: true },
        { id: 7, group: "POS", code: "Gratuity", value: this.captions.GratuityReport, breakPointNumber: ReportBreakPoint.GratuityReport, IsAuthorized: true },
        { id: 8, group: "APPOINTMENT", code: "CustomField1", value: "", breakPointNumber: ReportBreakPoint.AppointmentListingbyCustomFields, IsAuthorized: true },
        { id: 9, group: "APPOINTMENT", code: "CustomField2", value: "", breakPointNumber: ReportBreakPoint.AppointmentListingbyCustomFields, IsAuthorized: true },
        { id: 10, group: "APPOINTMENT", code: "CustomField3", value: "", breakPointNumber: ReportBreakPoint.AppointmentListingbyCustomFields, IsAuthorized: true },
        { id: 11, group: "APPOINTMENT", code: "CustomField4", value: "", breakPointNumber: ReportBreakPoint.AppointmentListingbyCustomFields, IsAuthorized: true,enableJasperReports : true,
        reportUrlpath : JasperReportUrlPath.CustomField4UrlPath },
        { id: 12, group: "APPOINTMENT", code: "CustomField5", value: "", breakPointNumber: ReportBreakPoint.AppointmentListingbyCustomFields, IsAuthorized: true,enableJasperReports : true,
        reportUrlpath : JasperReportUrlPath.CustomField5UrlPath },
        { id: 13, group: "APPOINTMENT", code: "Cancellation", value: this.captions.CancellationReport, breakPointNumber: ReportBreakPoint.CancellationReport, IsAuthorized: true },
        { id: 14, group: "APPOINTMENT", code: "NoShowAppointment", value: this.captions.NoShowReport, breakPointNumber: ReportBreakPoint.NoShowReport, IsAuthorized: true ,enableJasperReports : true,
        reportUrlpath : JasperReportUrlPath.NoShowUrlPath},
        { id: 15, group: "POS", code: "ServiceCharge", value: this.captions.ServiceChargeReport, breakPointNumber: ReportBreakPoint.ServiceChargeReport, IsAuthorized: true },
        { id: 16, group: "POS", code: "StaffMemberSummary", value: this.captions.StaffMemberSummaryReport, breakPointNumber: ReportBreakPoint.StaffMemberSummaryReport, IsAuthorized: true },
        { id: 17, group: "APPOINTMENT", code: "StaffSchedule", value: this.captions.StaffScheduleReport, breakPointNumber: ReportBreakPoint.StaffSchedule, IsAuthorized: true },
        { id: 18, group: 'APPOINTMENT', code: 'Utilization', value: this.captions.Utilization, breakPointNumber: ReportBreakPoint.Utilization, IsAuthorized: true },
        { id: 19, group: "APPOINTMENT", code: "ProjectedRevenue", value: this.captions.ProjectedRevenueSummary, breakPointNumber: ReportBreakPoint.ProjectedRevenueSummary, IsAuthorized: true },
        { id: 20, group: "CLIENT", code: "BatchClientItinerary", value: this.captions.BatchClientItinerary, breakPointNumber: ReportBreakPoint.BatchPrintClientItinerary, IsAuthorized: true },
        { id: 21, group: "CLIENT", code: "ClientListing", value: this.captions.ClientListing, breakPointNumber: ReportBreakPoint.ClientListing, IsAuthorized: true },
        { id: 22, group: "APPOINTMENT", code: "OnlineAppointment", value: this.captions.OnlineAppointment, breakPointNumber: ReportBreakPoint.OnlineAppointment, IsAuthorized: true,enableJasperReports : true,
        reportUrlpath : JasperReportUrlPath.OnlineAppointmentUrlPath },
        { id: 3, group: "APPOINTMENT", code: "AppointmentByUser", value: this.captions.AppointmentBookedByUser, breakPointNumber: ReportBreakPoint.AppointmentBookedByUser, IsAuthorized: true },
        { id: 1, group: 'RETAIL', code: 'MultiPackItems', value: this.captions.MultiPackItemsReport, breakPointNumber: ReportBreakPoint.MultiPackItems, IsAuthorized: true },
        { id: 2, group: 'RETAIL', code: 'ReturnedItems', value: this.captions.ReturnedItemsReport, breakPointNumber: ReportBreakPoint.ReturnedItems, IsAuthorized: true },
        { id: 3, group: 'RETAIL', code: 'InventorySummary', value: this.captions.InventorySummaryReport, breakPointNumber: ReportBreakPoint.InventorySummary, IsAuthorized: true },
        { id: 4, group: 'RETAIL', code: 'InventoryDetails', value: this.captions.InventoryDetailsReport, breakPointNumber: ReportBreakPoint.InventoryDetail, IsAuthorized: true },
        { id: 5, group: 'RETAIL', code: 'InventoryTransfer', value: this.captions.InventoryTransferReport, breakPointNumber: ReportBreakPoint.InventoryTransfer, IsAuthorized: true },
        { id: 6, group: 'RETAIL', code: 'InventoryWash', value: this.captions.InventoryWashReport, breakPointNumber: ReportBreakPoint.InventoryWash, IsAuthorized: true },
        { id: 7, group: 'RETAIL', code: 'InventoryAudit', value: this.captions.InventoryAuditReport, breakPointNumber: ReportBreakPoint.InventoryAudit, IsAuthorized: true },
        { id: 8, group: 'RETAIL', code: 'SalesByDiscountTypes', value: this.captions.SalesByDiscountTypeReport, breakPointNumber: ReportBreakPoint.SaleByDiscountType, IsAuthorized: true },
        { id: 9, group: 'RETAIL', code: 'SalesBySubCategory', value: this.captions.SalesBySubCategoryReport, breakPointNumber: ReportBreakPoint.SaleBySubCategory, IsAuthorized: true },
        { id: 10, group: 'RETAIL', code: 'SalesByCategory', value: this.captions.SalesByCategoryReport, breakPointNumber: ReportBreakPoint.SaleByCategory, IsAuthorized: true },
        { id: 11, group: 'RETAIL', code: 'SaleByItem', value: this.captions.SaleByItemReport, breakPointNumber: ReportBreakPoint.SaleByItem, IsAuthorized: true },
        { id: 12, group: 'RETAIL', code: 'CorrectVoid', value: this.captions.CorrectVoidReport, breakPointNumber: ReportBreakPoint.CorrectVoid, IsAuthorized: true },
        { id: 13, group: 'RETAIL', code: 'Shift', value: this.captions.ShiftReport, breakPointNumber: ReportBreakPoint.Shift, IsAuthorized: true },
        { id: 14, group: 'RETAIL', code: 'Transaction', value: this.captions.TransactionReport, breakPointNumber: ReportBreakPoint.Transaction, IsAuthorized: true },
        { id: 15, group: 'RETAIL', code: 'RevenueSummary', value: this.captions.RevenueSummaryReport, breakPointNumber: ReportBreakPoint.RevenueSummary, IsAuthorized: true },
        { id: 16, group: 'SECURITY', code: 'UserAccessReport', value: this.captions.UserAccessReport, breakPointNumber: ReportBreakPoint.UserAccessReport, IsAuthorized: true },
        { id: 17, group: 'MEMBER', code: 'MemberServiceAvailed', value: this.captions.MemberServiceAvailedReport, breakPointNumber: ReportBreakPoint.MemberServiceAvailed, IsAuthorized: true },
        { id: 23, group: "APPOINTMENT", code: "AppointmentDeposit", value: this.captions.Deposit, breakPointNumber: ReportBreakPoint.AppointmentDeposit, IsAuthorized: true },
        { id: 24, group: "APPOINTMENT", code: "LostDeniedBusiness", value: this.captions.LostDeniedBusinessReport, breakPointNumber: ReportBreakPoint.LostDeniedBusinessReport, IsAuthorized: true },
        { id: 25, group: "APPOINTMENT", code: "DayPassReport", value: this.captions.DayPassReport, breakPointNumber: ReportBreakPoint.DayPass, IsAuthorized: true },
        { id: 26, group: "APPOINTMENT", code: "ClassClientListing", value: this.captions.ClassClientListingReport, breakPointNumber: ReportBreakPoint.ClassClientListing, IsAuthorized: true },
        { id: 27, group: "APPOINTMENT", code: "ClassClientNoShow", value: this.captions.ClassClientNoShowReport, breakPointNumber: ReportBreakPoint.ClassClientNoShow, IsAuthorized: true },
        { id: 28, group: "APPOINTMENT", code: "ClassClientCancellation", value: this.captions.ClassClientCancellationReport, breakPointNumber: ReportBreakPoint.ClassClientCancellation, IsAuthorized: true },
        { id: 26, group: "APPOINTMENT", code: "LockerUtilization", value: this.captions.LockerUtilization, breakPointNumber: ReportBreakPoint.LockerUtilization, IsAuthorized: true }

    ]
    // private reportConfigs: ReportControls[] = [
    //     {
    //         code: "AppointmentListing",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: false,
    //             layout: "Portrait"
    //         }
    //     },
    //     {
    //         code: "AppointmentByLocation",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllLoc", name: this.captions.Locations, host: Host.spaManagement }],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "AppointmentByStaffMember",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllTherapist", name: this.captions.Therapists, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveTherapist,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     }, {
    //         code: "StaffSchedule",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllTherapist", name: this.captions.Therapists, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveTherapist,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "ProjectedRevenue",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllTherapist", name: this.captions.Therapists, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveTherapist,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     }, {
    //         code: "AppointmentByLinkCode",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllLinkCodes", name: this.captions.ColorLinkingCodes, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveColorLinkingCodes,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "AppointmentByDropdown",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllLinkCodes", name: this.captions.ColorLinkingCodes, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveColorLinkingCodes,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "AppointmentByTextBox",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllLinkCodes", name: this.captions.ColorLinkingCodes, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveColorLinkingCodes,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "Cancellation",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllCancelReasons", name: this.captions.CancelReasons, host: Host.spaManagement }],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: false,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "NoShowAppointment",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: false,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "OnlineAppointment",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: false,
    //             layout: "Landscape",
    //             allowFutureDate: false,
    //             startDateCaption: this.captions.BookedDateFrom,
    //             endDateCaption: this.captions.BookedDateTo
    //         }
    //     },
    //     {
    //         code: "StaffMemberSummary",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllTherapist", name: this.captions.Users, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveUsers,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "CustomField1",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "CustomField1", name: this.captions.Codes, host: Host.spaManagement }],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "CustomField2",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "CustomField2", name: this.captions.Codes, host: Host.spaManagement }],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "CustomField3",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "CustomField3", name: this.captions.Codes, host: Host.spaManagement }],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "CustomField4",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: false,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "CustomField5",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: false,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "CommissionReport",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllTherapist", name: this.captions.Users, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveUsers,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "Gratuity",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllTherapist", name: this.captions.Users, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveUsers,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "Itinerary",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: false,
    //             layout: "Portrait"
    //         }
    //     },
    //     {
    //         code: "ServiceCharge",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllTherapist", name: this.captions.Users, host: Host.spaManagement }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveUsers,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "ClientListing",
    //         reportConfig: {
    //             startDatePicker: false,
    //             endDatePicker: false,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             inActiveToggleName: "",
    //             pageBreakToggle: false,
    //             layout: "Portrait"
    //         }
    //     },
    //     {
    //         code: "AppointmentByUser",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [{ callDesc: "GetAllUsers", name: this.captions.Users, host: Host.authentication }],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.IncludeInactiveUsers,
    //             pageBreakToggle: true,
    //             layout: "Landscape"
    //         }
    //     },
    //     {
    //         code: "BatchClientItinerary",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: true,
    //             inActiveToggleName: this.captions.ExcludeAppointmentComments,
    //             pageBreakToggle: false,
    //             layout: "Portrait"
    //         }
    //     },
    //     {
    //         code: "MemberServiceAvailed",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             pageBreakToggle: true,
    //             layout: "Portrait"
    //         }
    //     },
    //     {
    //         code: "LostDeniedBusiness",
    //         reportConfig: {
    //             startDatePicker: true,
    //             endDatePicker: true,
    //             dropDownFilters: [],
    //             inActiveToggle: false,
    //             pageBreakToggle: true,
    //             layout: "Portrait"
    //         }
    //     }
    // ]

    public getReportSelections(code: string): ReportSelector_[] {
        if(this.localize.isFromJobScheduler) {
            return this.reportSelector.filter(x => x.id == Number(code));
        } else {
            return this.reportSelector.filter(x => x.code.toLowerCase() == code.toLowerCase());
        }  
    }
    public getAllReportSelections():ReportSelector_[]{
		return this.reportSelector;
	}
}
