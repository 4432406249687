import { Component, OnInit } from '@angular/core';
import { PrintLabelsData } from '../../retail/retail.modals';
import { BarcodePrintService } from '../retail-print/barcode-print.service';
import { RetailLocalization } from '../common/localization/retail-localization';

@Component({
  selector: 'app-retail-print-labels',
  templateUrl: './retail-print-labels.component.html',
  styleUrls: ['./retail-print-labels.component.scss']
})
export class RetailPrintLabelsComponent implements OnInit {
  printData: PrintLabelsData[] = [];
  LABELS_PER_SHEET: number = 30;
  COLUMNS_PER_ROW: number = 3;
  ROWS_PER_SHEET: number = 10;
  price: string = this.localization.replacePlaceholders(this.localization.captions.settings.prices, ["option"], [this.localization.currencySymbol]);

  job: any[];

  constructor(public BarcodePrint: BarcodePrintService, private localization: RetailLocalization) { }

  ngOnInit() {
    this.printData = this.BarcodePrint.PrintLabelsData;

    var data = [];

    // Expand print data
    for (let print of this.printData) {
      let p = print;
      for (let j = 0; j < p.printQuantity; j += 1) {
        data.push(p);
      }
    }

    var index: number = 0;
    console.log(data.length);
    this.job = [];
    for (var j = 0; j < data.length; j += this.LABELS_PER_SHEET) {
      var page = [];
      while (page.length < this.ROWS_PER_SHEET) {
        var row = [];
        while (row.length < this.COLUMNS_PER_ROW) {
          index = j + (page.length * this.COLUMNS_PER_ROW) + row.length;
          var label = data[index];
          if (!label) {
            break;
          }
          row.push(label);
        }
        page.push(row);
      }
      this.job.push(page);
    }
  }

}
