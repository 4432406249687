import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessage } from 'src/app/eatecui/source/shared/models/toast-message.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { ButtonKey, ModuleName, TransactionType } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ToastrService } from 'ngx-toastr';
import { AgilysysConfirmPostPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirm-post-popup/agilysys-confirm-post-popup.component';
import { AssignBidsPopupModel, ConfirmPopModel, ConfirmPostPopUpModel, 
    PostPOPopUp, PrintPopModel, PrintPopUpCallBack } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { CreateTransaction } from '../create-transcation';
import { PostPOSupplierConfig, TransactionConfiguration, TransactionTableData } from '../transaction.interface';
import { AgilysysPostPoComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-post-po/agilysys-post-po.component';
import { transactionButton } from '.';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { AgilysysAssignBidsPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-assign-bids-popup/agilysys-assign-bids-popup.component';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { AgilysysPrintPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-print-popup/agilysys-print-popup.component';
import { GenerateReportFilterModel, GenerateReportModel } from "src/app/eatecui/source/reports/interface/reports.interface";
import moment from 'moment';
import _ from 'lodash';
import { UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { AgilysysattachedfilesPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-attached-files-popup/agilysys-attached-files-popup.component';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { ConversionAlgorithm } from 'src/app/eatecui/source/shared/algorithms';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { DataConstruct } from 'src/app/eatecui/source/shared/models/construct-data';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { transactionButtonConfig } from './button-config.interface';
import * as constants from '@shared/constant/GlobalConstants';

export const transButtonCallBack: transactionButtonConfig = async (
    postModelHederEntity: any,
    postModelDetailEntity: any,
    buttonCallBackValue: CallBackData,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    selectedTransaction: any,
    selectedId: number,
    homeGroup: FormGroup,
    routerNavigateService: RouteNavigateService,
    router: ActivatedRoute,
    toastrService: ToastrService,
    GlobalOdataModel: any,
    transactionFrom: any,
    dialog: MatDialog,
    translateService?: TranslateService,
    SystemPreferenceService?: SysytemPreferenceService,
    selectedSeqNo?: number,
    analysisRedirect ?: any,
    configService?: ConfigService,
    integrationService?: IntegrationService,  
    manageSalesService ?: any,
    commonService?: CommonService, masterService?: MasterManagementService): Promise<void> => {
    try {
        if (buttonCallBackValue) {
          const PostDeactivateButtons = ['Post', 'save', 'Confirm', 'confirm', 'update'];
            const buttonKey = buttonCallBackValue.buttonValue.buttonKey;
            const Modulename = TransactionCollection.Name;
            PostDeactivateButtons.forEach((x: any) => {
              if (x === buttonCallBackValue.buttonValue.buttonActionType) {
                transactionService.PostDeactivate = true;
              } else { if (x === buttonKey) {
                transactionService.PostDeactivate = true;
              } }
            });
            switch (buttonKey) {
                case 'cancel':
                    if (Modulename === 'Packages') {
                        const from = { queryParams: { master: selectedTransaction } };
                        const routerUrl = `inventory-management/view-${selectedTransaction.toLowerCase()}`;
                        routerNavigateService.navigateRoute(routerUrl, from);
                    } else {
                        const from = { queryParams: { transaction: selectedTransaction } };
                        const routerUrl = `/settings/enhancedInventory/transaction/view-${selectedTransaction.toLowerCase()}`;
                        routerNavigateService.navigateRoute(routerUrl, from);
                    }
                    break;
                case 'null':
                    console.log('Null');
                    break;
                case 'attachedFiles':
                    const attchedFilesfrom = {
                        queryParams: {
                            transaction: selectedTransaction, rowId: selectedId,
                            transactionFrom: transactionFrom,
                            attachedFileConfig: integrationService.IntegrationConfig,
                            transactionJson: TransactionCollection
                        }
                    };
                    const dialogRefer = dialog.open(AgilysysattachedfilesPopupComponent, {
                        width: '87%',
                        height: '80vh',
                        data: attchedFilesfrom,
                        maxWidth: '100vw',
                        panelClass: IsRguestEnabled() ? 'rguest-attached-files-popup' : 'attached-files-popup',
                        hasBackdrop: true,
                        backdropClass: 'detail-expand-backdrop-class',
                        position: {
                            right: '0'
                        },
                        // disableClose: true
                    });
                    dialogRefer.afterClosed().subscribe(result => {
                        transactionService.PostDeactivate = true;
                        TransactionCollection.AttachedFilesConfig.AttachMentConfig.FileAttachedData = result.FileCollection;
                    });
                    break;      
                case 'miscellinouscharges':
                    CreateTransaction.POMiscCharges(TransactionCollection, httpService, transactionService,
                        null, postModelHederEntity, postModelDetailEntity, homeGroup, GlobalOdataModel, toastrService,
                        routerNavigateService, selectedTransaction, dialog, selectedId, null, null, commonService);
                    break;
                case 'receivemischarges':
                    CreateTransaction.ReceivingMiscCharges(TransactionCollection, httpService, transactionService,
                        null, postModelHederEntity, postModelDetailEntity, homeGroup, GlobalOdataModel, toastrService,
                        routerNavigateService, selectedTransaction, dialog, selectedId, null, null, commonService);
                    break;
                case 'allocatecharges':
                    CreateTransaction.AllocateCharges(TransactionCollection, httpService, transactionService,
                        null, postModelHederEntity, postModelDetailEntity, homeGroup, GlobalOdataModel, toastrService,
                        routerNavigateService, selectedTransaction, dialog, selectedId, null, null, commonService, router, SystemPreferenceService);
                    break;
                case 'processpo':
                    const processAttchedFilesfrom = {
                        queryParams: {
                            transaction: selectedTransaction,
                            transactionFrom: 'processpo', from: 'processpo',
                            analysisRedirect: ( analysisRedirect && analysisRedirect !== undefined ) ? analysisRedirect : null
                        }
                    };
                    const processAttachedRouterUrl = '/settings/enhancedInventory/transaction/process-purchaseorder';
                    await CreateTransaction.ProcessPo(TransactionCollection, httpService, transactionService, postModelHederEntity, 
                        postModelDetailEntity, homeGroup, GlobalOdataModel, dialog);
                    routerNavigateService.navigateRoute(processAttachedRouterUrl, processAttchedFilesfrom);
                    break;
                case 'postpoconfirm':
                    const ZeroCosting = [];
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList.forEach(supplier => {
                        const CurrentItemList = transactionService.poCloneGridData.
                            filter(x => x.SupplierId === supplier.SuplierId);
                        const Valued = CurrentItemList.reduce(function (prev, element) {
                            return prev + (element.Valued ? parseFloat(element.Valued) : 0);
                        }, 0);
                        if (Valued === 0) {
                            ZeroCosting.push({ Name: supplier.SuplierName, Valued: Valued });
                        }
                    });
                    if (ZeroCosting.length > 0) {
                        let ContentLabel = '';
                        if (ZeroCosting.length <= 2) {
                            ContentLabel = ZeroCosting.map(res => {
                                return res.Name;
                            }).join(', ');
                            ContentLabel = `in ${ContentLabel} Supplier(s)`;
                        } else {
                            ContentLabel = 'for two or more Suppliers';
                        }
                        const ConfirmPopUpDialog: ConfirmPopModel = {
                            PopupHeaderContent: 'Validations',
                            PopUpContent: `You are processing one or more PO(s) with '0' order value or missing order field values. Do you wish to proceed?`,
                        };
                        const dialogRefs = dialog.open(AgilysysConfirmationPopupComponent, {
                            width: '400px',
                            height: '222px',
                            data: ConfirmPopUpDialog,
                            disableClose: true
                        });
                        dialogRefs.afterClosed().subscribe(result => {
                            if (result === 'Confirm') {
                                PostPO(
                                    TransactionCollection,
                                    transactionService,
                                    postModelHederEntity,
                                    postModelDetailEntity,
                                    homeGroup,
                                    GlobalOdataModel,
                                    httpService,
                                    toastrService,
                                    routerNavigateService,
                                    selectedTransaction,
                                    dialog,
                                    analysisRedirect
                                    );
                            }
                        });
                    } else {
                        PostPO(
                            TransactionCollection,
                            transactionService,
                            postModelHederEntity,
                            postModelDetailEntity,
                            homeGroup,
                            GlobalOdataModel,
                            httpService,
                            toastrService,
                            routerNavigateService,
                            selectedTransaction,
                            dialog,
                            analysisRedirect
                            );
                    }
                    break;
                case 'save':
                    switch (Modulename) {
                        case ModuleName.physicalinventory:
                            homeGroup.controls['TransactionStatusId'].setValue(TransactionType.Save);
                            homeGroup.controls['IsPosted'].setValue(false);
                            const transactionGridData = constractPiPostEngine(TransactionCollection);
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData
                                = transactionGridData;
                            break;
                        case ModuleName.receiving:
                            homeGroup.controls['IsPosted'].setValue(false);
                            break;
                    }

                    if (ModuleName.receiving === Modulename) {
                        CheckForAlreadyExistsInvoice(TransactionCollection, selectedId, router, transactionService, postModelHederEntity,
                            postModelDetailEntity, homeGroup, GlobalOdataModel, httpService, toastrService,
                            routerNavigateService, selectedTransaction, true, dialog, configService, integrationService,
                            manageSalesService, buttonCallBackValue, commonService);
                    } else {
                        PostCall(TransactionCollection, selectedId, router, transactionService, postModelHederEntity,
                            postModelDetailEntity, homeGroup, GlobalOdataModel, httpService, toastrService,
                            routerNavigateService, selectedTransaction, selectedSeqNo, true, buttonCallBackValue, dialog, null,
                            null, translateService, configService, integrationService, manageSalesService, commonService);
                    }
                    break;
                case ButtonKey.PiConfirm:
                    switch (Modulename) {
                        case ModuleName.physicalinventory:
                            homeGroup.controls['TransactionStatusId'].setValue(TransactionType.Post);
                            homeGroup.controls['IsPosted'].setValue(true);
                            break;
                    }
                    const transactionGridata = constractPiPostEngine(TransactionCollection);
                    const ingredients = [];
                    transactionGridata.forEach(x => {
                        ingredients.push(x.IngredientId);
                    });
                    const ActivitySummaryObject = {
                        IngredientId: ingredients,
                        LocationId: homeGroup.value.LocationId,
                        PostDate: moment(homeGroup.value.PostDate).startOf('hour').format(constants.PostDateTimeFormat)
                    };
                    // const activitySummaryEndPoint = '/ReportData/api/IngredientActivitySummary'; For RT Bug Fix
                    const activitySummaryEndPoint = '/ReportData/api/PIActivitySummary';
                    httpService.PosthttpMethod(activitySummaryEndPoint, ActivitySummaryObject).subscribe(y => {
                        const postObject = populateInventoryResetHead(transactionGridata, y, homeGroup);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
                            postObject.PhysicalDetails;
                        prePostPopup(TransactionCollection, selectedId, router, transactionService, postModelHederEntity,
                            postModelDetailEntity, homeGroup, GlobalOdataModel, httpService, toastrService,
                            routerNavigateService, selectedTransaction, selectedSeqNo, dialog, true, buttonCallBackValue, analysisRedirect,
                            null, translateService, configService, integrationService, manageSalesService, commonService);
                    });
                    break;
                case 'piprint':
                    const piprint = {
                        queryParams: {
                            transaction: 'physicalinventory',
                        }
                    };
                    const pirprintUrl = '/settings/enhancedInventory/transaction/view-physicalinventory';
                    CreateTransaction.PrintPi(TransactionCollection, httpService, transactionService, homeGroup);
                    routerNavigateService.navigateRoute(pirprintUrl, piprint);
                    break;
                case 'editpo':
                    CreateTransaction.EditPO(TransactionCollection, httpService, transactionService,
                        null, postModelHederEntity, postModelDetailEntity, homeGroup, GlobalOdataModel, toastrService,
                        routerNavigateService, selectedTransaction, dialog, selectedId, selectedSeqNo);
                    break;
                case 'receivepost':
                    homeGroup.controls['IsPosted'].setValue(true);
                    CreateTransaction.ReceivePost(TransactionCollection, httpService, transactionService,
                        null, postModelHederEntity, postModelDetailEntity, homeGroup, GlobalOdataModel, toastrService,
                        routerNavigateService, selectedTransaction, dialog, selectedId).then(function (response) {
                            if (response) {
                                prePostPopup(
                                    TransactionCollection,
                                    selectedId,
                                    router,
                                    transactionService,
                                    postModelHederEntity,
                                    postModelDetailEntity,
                                    homeGroup,
                                    GlobalOdataModel,
                                    httpService,
                                    toastrService,
                                    routerNavigateService,
                                    selectedTransaction,
                                    selectedSeqNo,
                                    dialog,
                                    true,
                                    buttonCallBackValue,
                                    analysisRedirect,
                                    null,
                                    translateService,
                                    configService,
                                    integrationService,
                                    manageSalesService, commonService, SystemPreferenceService
                                    );
                            }
                        });
                    // if (TransactionCollection.AttachedFilesConfig) {
                    //     const fileAttachedData: Array<UploadMetaData> = 
                    //        TransactionCollection.AttachedFilesConfig.AttachMentConfig.FileAttachedData;
                    //     if (fileAttachedData && fileAttachedData.length > 0) {
                    //         if ( transactionService.FileAttachmentConfig.IsIntegration ) {
                    //             const FromGridData: Array<TransactionTableData> = 
                    //                TransactionCollection.CreateTranscationConfig.DetailsConfig
                    //             .DetailTableCard.DetailsTableCardBuilder.GridTableData;
                    //             transactionService.intergerationInstance.GetModuleIndexValue(TransactionCollection.Name, null);
                    //             transactionService.intergerationInstance.PostDatamagineFileData(fileAttachedData, FromGridData);
                    //         }                       
                    //     }
                    // }
                    break;
                case ButtonKey.Transfer:
                    CreateTransaction.TransferPost(TransactionCollection, httpService, transactionService, postModelHederEntity,
                        postModelDetailEntity, homeGroup, GlobalOdataModel, toastrService, routerNavigateService, selectedTransaction,
                        dialog, selectedId, SystemPreferenceService, router, configService, integrationService).then(function (response) {
                            console.log(response);
                        });
                    break;
                case ButtonKey.AssignBids:
                    const Assignbidsmodel: AssignBidsPopupModel = {
                        PopupHeaderContent: translateService.instant('Common.AssignBids'),
                        FormFields: TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormFields,
                        FormType: 'bids Form',
                        FormGroupData: TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData ?
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData : null
                    };
                    const dialogRef = dialog.open(AgilysysAssignBidsPopupComponent, {
                        width: '400px',
                        height: '400px',
                        data: Assignbidsmodel,
                        disableClose: true
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result[0] === 'Confirm') {
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData = result[1].value;
                            transactionService.transactionConfigurationData.CreateTranscationConfig
                                .ItemsConfig.ItemAssignBids.FormGroupData = result[1].value;
                            if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0) {
                                AssignBids(TransactionCollection, httpService, transactionService, homeGroup, 'fromAssignBids');
                            }
                        }
                    });
                    break;
                case ButtonKey.GlobalGridSupplier:
                    if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0) {
                        AssignBids(TransactionCollection, httpService, transactionService, null, 'fromGridSupplier');
                    }
                    break;
                case 'confirmPackages':
                    const confirmPopModel = {} as ConfirmPopModel;
                    const popUpHeader = selectedId ? translateService.instant('Packages.Save' + selectedTransaction) 
                    : translateService.instant('Packages.Create' + selectedTransaction);
                    confirmPopModel.PopupHeaderContent = popUpHeader;
                    confirmPopModel.PopUpContent = selectedTransaction === 'packagelimitation' ? 
                    translateService.instant('Packages.SeqConfirmationMsgForPL') : translateService.instant('Packages.SeqConfirmationMsg');
                    const dialogReferences = dialog.open(AgilysysConfirmationPopupComponent, {
                        width: '440px',
                        height: '220px',
                        data: confirmPopModel,
                        autoFocus: false,
                        disableClose: true
                    });
                    dialogReferences.afterClosed().subscribe(res => {
                        if (res === 'Confirm') {
                            PostCall(
                                TransactionCollection,
                                selectedId,
                                router,
                                transactionService,
                                postModelHederEntity,
                                postModelDetailEntity,
                                homeGroup,
                                GlobalOdataModel,
                                httpService,
                                toastrService,
                                routerNavigateService,
                                selectedTransaction,
                                selectedSeqNo,
                                false,
                                buttonCallBackValue,
                                dialog,
                                transactionFrom,
                                analysisRedirect,
                                translateService,
                                configService,
                                integrationService,
                                manageSalesService,
                                commonService
                            );
                        }
                    });
                break;
                default:
                    switch (Modulename) {
                        case ModuleName.physicalinventory:
                            homeGroup.controls['TransactionStatusId'].setValue(TransactionType.Post);
                            break;
                        case ModuleName.receiving:
                            homeGroup.controls['IsPosted'].setValue(true);
                            break;
                            
                    }
                    if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp &&
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.EnableConfirmationPopUp) {
                        const popupModel: ConfirmPostPopUpModel = {
                            PopupHeaderContent: 'Confirmation',
                            ModuleName: TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.Module,
                            PopUpContent: transactionButton.confirmPopUpInfo(TransactionCollection),
                            FormFields: transactionButton.ConfirmPopFormFields(TransactionCollection, commonService),
                            FormHeaderName: TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.FormHeaderName,
                            transactionConfiguration: TransactionCollection
                        };
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        const dialogRef = dialog.open(AgilysysConfirmPostPopupComponent, {
                            width: '50%',
                            height: 'auto',
                            data: popupModel,
                            disableClose: true
                        });
                        dialogRef.afterClosed().subscribe(res => {
                            if (res[0] === 'Confirm') {
                                console.log('Confirm', res);
                                PostCall(TransactionCollection, selectedId, router, transactionService, postModelHederEntity,
                                    postModelDetailEntity, homeGroup, GlobalOdataModel, httpService, toastrService,
                                    routerNavigateService, selectedTransaction, selectedSeqNo, null, null,
                                    null, null, null, translateService, configService, integrationService, manageSalesService, commonService);
                            }
                        });
                    } else {                        
                            PostCall(
                                TransactionCollection,
                                selectedId,
                                router,
                                transactionService,
                                postModelHederEntity,
                                postModelDetailEntity,
                                homeGroup,
                                GlobalOdataModel,
                                httpService,
                                toastrService,
                                routerNavigateService,
                                selectedTransaction,
                                selectedSeqNo,
                                false,
                                buttonCallBackValue,
                                dialog,
                                transactionFrom,
                                analysisRedirect,
                                translateService,
                                configService,
                                integrationService,
                                manageSalesService,
                                commonService,
                                masterService
                            );
                    }
            }
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }

};

function prePostPopup(
    TransactionCollection: TransactionConfiguration,
    selectedId: number,
    router: ActivatedRoute,
    transactionService: TransactionService,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    httpService: HttpService,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    selectedSeqNo: number,
    dialog: MatDialog,
    IsSave: boolean,
    buttonCallbackValue?: CallBackData,
    analysisRedirect = null,
    popupHeader = null,
    translateService?: TranslateService,
    configService?: ConfigService,
    integrationService?: IntegrationService,
    manageSalesService?: any,
    commonService?: CommonService,
    systemPreferenceService?: SysytemPreferenceService
    ): void {
    try {
        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp &&
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.EnableConfirmationPopUp) {
            const popupModel: ConfirmPostPopUpModel = {
                PopupHeaderContent: popupHeader === null ? 'Confirmation' : popupHeader.OuterHeader,
                ModuleName: TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.Module,
                PopUpContent: transactionButton.confirmPopUpInfo(TransactionCollection),
                FormFields: transactionButton.ConfirmPopFormFields(TransactionCollection, commonService, systemPreferenceService),
                FormHeaderName: TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.FormHeaderName,
                transactionConfiguration: TransactionCollection,
                ConfirmationText: popupHeader === null ? '' : popupHeader.InnerHeader
            };
            const dialogRef = dialog.open(AgilysysConfirmPostPopupComponent, {
                width: '50%',
                height: 'auto',
                data: popupModel,
                autoFocus: false,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe((res: any) => {
                if (res[0] === 'Confirm') {
                    console.log('Confirm', res);
                    const PostEntityName = TransactionCollection.PostEntityName;
                    switch (PostEntityName) {
                        case 'ReceivingHead':
                            if (homeGroup.controls['IsUICalculated']) {
                                homeGroup.controls['IsUICalculated'].setValue(res[1].value.IsUICalculated);
                            }
                            homeGroup.controls['SupplierTotal'].setValue(res[1].value.SupplierTotal);
                            homeGroup.controls['SupplierTotal'].clearValidators();
                            homeGroup.controls['SupplierTotal'].updateValueAndValidity();
                            if (homeGroup.controls['Balance']) {
                                homeGroup.controls['Balance'].setValue(res[1].value.Balance);
                            }
                            break;
                        case 'PhysicalHead':
                            TransactionCollection.CreateTranscationConfig.
                                ItemsConfig.ItemList.ItemGridConfig.GridData = [...TransactionCollection.CreateTranscationConfig.
                                    ItemsConfig.ItemList.ItemGridConfig.GridData.filter(x => x.Qty !== '')];
                            break;
                    }
                    PostCall(
                        TransactionCollection,
                        selectedId,
                        router,
                        transactionService,
                        postModelHederEntity,
                        postModelDetailEntity,
                        homeGroup,
                        GlobalOdataModel,
                        httpService,
                        toastrService,
                        routerNavigateService,
                        selectedTransaction,
                        selectedSeqNo,
                        false,
                        buttonCallbackValue,
                        dialog,
                        null,
                        analysisRedirect,
                        translateService,
                        configService,
                        integrationService,
                        manageSalesService,
                        commonService
                        );
                }
            });
        } else {
            PostCall(
                TransactionCollection,
                selectedId,
                router,
                transactionService,
                postModelHederEntity,
                postModelDetailEntity,
                homeGroup,
                GlobalOdataModel,
                httpService,
                toastrService,
                routerNavigateService,
                selectedTransaction,
                selectedSeqNo,
                null,
                buttonCallbackValue,
                dialog,
                null,
                analysisRedirect,
                translateService,
                configService,
                integrationService,
                manageSalesService,
                commonService
                );
        }
    } catch (error) {
        console.error(error);
    }
}

function constractPiPostEngine(TransactionCollection: TransactionConfiguration): Array<any> {
    try {
        const gridData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
        // const nonQuantityProducts = Object.values(NonQuantity);
        const piData = gridData.filter((x, i: number ) => {
           if ( x.hasOwnProperty('Qty') && x['Qty']?.toString()?.toLowerCase() !== 'n_c' && x['Qty']?.toString()?.toLowerCase() !== 'l_p' ) {
            return x;
           }
        });
        return piData;
    } catch (error) {
        console.error(error);
    }
}

function PostCall(
    TransactionCollection: TransactionConfiguration,
    selectedId: any,
    router: any,
    transactionService: TransactionService,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: any,
    GlobalOdataModel: any,
    httpService: HttpService,
    toastrService: any,
    routerNavigateService: any,
    selectedTransaction: any,
    selectedSeqNo = 0,
    IsSave = false,
    buttonCallBackValue?: CallBackData,
    dialog?: MatDialog,
    transactionFrom ?: string,
    analysisRedirect ?: any,
    translateService?: TranslateService,
    configService?: ConfigService,
    integrationService?: IntegrationService,
    manageSalesService?: any,
    commonService?: CommonService,
    masterService?: MasterManagementService
) {
    let MessageName = TransactionCollection.Name.toLowerCase();
    let PostModelEndPoint = TransactionCollection.PostEntityName;
    if (MessageName === 'butchery') {
        PostModelEndPoint = 'PostButchery';
    }
    let postObject = {
    };
    const requestMethod = CreateTransaction.HttpServiceMethod(null, JSON.parse(decodeURIComponent(router.snapshot.queryParams.data)));
    let postModelData;
    if (TransactionCollection.Name === 'PackageLimitation') {
        postModelData = DataConstruct.ConstructDataObject('Create-PackageLimitation',
            commonService, TransactionCollection, null, null, true, null, null, homeGroup, selectedId);
    } else {
      postModelData = transactionService.constractTransactionHeaderPostModel(
        postModelHederEntity, postModelDetailEntity, TransactionCollection, homeGroup, requestMethod, GlobalOdataModel);
    }
    if (TransactionCollection.CustomPostSchema) {
        PostModelEndPoint = TransactionCollection.CustomPostSchema.PostKey;
        postObject[TransactionCollection.CustomPostSchema.PostModel] = postModelData;
    } else {
        postObject = postModelData;
        if (MessageName === 'planhead' || MessageName === 'cycleplanning' || MessageName === 'productionplan') {
            const arrayFields = ['LocationIds', 'MealPeriodsIds', 'PackageIds', 'InventoryTypeIds', 'PlanIds', 'RequisitionIds'];
            arrayFields.forEach(ele => {
                postObject[ele] = postObject[ele] && Array.isArray(postObject[ele]) ? postObject[ele] : [];
            })
        }
        if (MessageName === 'butchery') {
            postObject['IsButchery'] = true;
        }
    }
    const data = JSON.parse(decodeURIComponent(router.snapshot.queryParams.data));
    // const seqNo = selectedTransactionSeq(selectedTransaction,data);
    let requetEntity = (requestMethod === 'PuthttpMethod') ?
        `${PostModelEndPoint}/` + `${selectedId}` : PostModelEndPoint === 'QuickOrderHead' ? 'PostQuickOrder' : PostModelEndPoint;
    requetEntity = selectedTransaction === 'packagelimitation' ? 'SavePackages' : requetEntity;
    const EndPointUrl = `/inventory/api/${requetEntity}`;
    httpService[requestMethod](EndPointUrl, postObject).subscribe(async (x: any) => {
        // if (x) {
        let serviceType = '';
        let responseId = '';
        if (selectedTransaction === 'receiving') {
            // responseId = (x && x.hasOwnProperty('Id')) ? ` #${x.Id}` : ` ${selectedId}`;
            if(x && x.hasOwnProperty('Id')){
                responseId = ` (${x.InvoiceNumber}/ #${x.Id}) `;
            } else if(x && x.hasOwnProperty('id')) {
                responseId = ` (${x.invoiceNumber}/ #${x.id}) `;
            } else {
                responseId = ` ${selectedId}`;
            }
        } else if (selectedTransaction === 'savedreceiving') { 
            responseId = (postObject && postObject.hasOwnProperty('InvoiceNumber')) ? ` (${postObject['InvoiceNumber']}/ #${selectedId})` 
            : ` ${selectedId}`;
        } else {
            if (requestMethod === 'PuthttpMethod') {
                responseId = (selectedSeqNo !== undefined && selectedSeqNo != null && selectedSeqNo > 0) ? ` ${selectedSeqNo}` : ` ${selectedId}`;
            } else {
                responseId = (x && x.hasOwnProperty('Id')) ? ` #${x.Id}` : ` #${selectedId}`;
            }
        }
        if (TransactionCollection.AttachedFilesConfig) {
            const fileAttachedData: Array<UploadMetaData> = TransactionCollection.AttachedFilesConfig.AttachMentConfig.FileAttachedData;
            if (fileAttachedData && fileAttachedData.length > 0) {
                fileAttachedData.forEach(req => {
                    req.IsTransaction = true;
                    if (requestMethod === 'PuthttpMethod') {
                        req.ItemId = selectedId;
                    } else {
                        req.ItemId = x.id;
                    }
                });
                FileData.FileDataPost(fileAttachedData, httpService, toastrService, masterService);
          }
          }
        if (selectedTransaction === 'receiving' && homeGroup.value.AllowOverrideSave === true) {
            serviceType = 'PuthttpMethod';
        } else {
            serviceType = requestMethod;
        }
        let toastText = (serviceType === 'PuthttpMethod') ?
        (selectedTransaction === 'receiving') ? 'Saved' : 'Updated' : (IsSave ? ' Saved' : ' Created') ;
        if (selectedTransaction === 'savedreceiving' && !IsSave) {
            toastText = ' Created';
        } else if (selectedTransaction === 'savedreceiving' && IsSave) {
            toastText = 'Saved';
        }
        if (buttonCallBackValue &&
            buttonCallBackValue.buttonValue.hasOwnProperty('EnablePrintPost') &&
            buttonCallBackValue.buttonValue.EnablePrintPost
        ) {
            // const Printenpoint = '/reportdata/api/ReportModule?$select=Id,Name&$expand=Reports($filter=IsActive eq true and IsPrint eq true;$select=Id,Name,FileName,Description)&$filter=IsActive eq true And Reports/any(c: c/IsActive eq true and c/IsPrint eq true)';
            // const getPrintList = await httpService.GethttpMethod(Printenpoint).toPromise();
            let selectedTransactionName = null;
            switch (selectedTransaction) {
                case 'pientercounts':
                case 'pientercyclecounts':
                    selectedTransactionName = 'Physical Inventory';
                    break;
                case 'savedreceiving':
                    selectedTransactionName = 'Receiving';
                    break;
                default:
                    selectedTransactionName = selectedTransaction;
                    break;
            }
            const PrintId = await commonService.getPrintId(selectedTransactionName);
            const getPrintList = await commonService.getPrintListById(PrintId);
            const EnablePopupResult: PrintPopUpCallBack = await OpenPrintList(dialog, getPrintList, selectedTransactionName, PrintId);
            if (EnablePopupResult && EnablePopupResult.ButtonKey === 'Confirm' && EnablePopupResult.SelectedId !== null) {
                const generateReportModel = {} as GenerateReportModel;
                generateReportModel.reportFormat = 0;
                generateReportModel.fileName = null;
                generateReportModel.inLine = false;
                generateReportModel.sortBy = 'string';
                generateReportModel.reportid = EnablePopupResult.SelectedId;
                generateReportModel.isAsc = true;
                generateReportModel.isRetail = true;
                const generateReportFilterModel = {} as GenerateReportFilterModel;
                generateReportFilterModel.property =  EnablePopupResult.SelectedId === 178 || EnablePopupResult.SelectedId === 179 ? 'ids' : 'id';
                generateReportFilterModel.value = (x && x.id) ? ` ${x.id}` :
                    (x && x.Id) ? ` ${x.Id}`:
                    (selectedTransaction === 'apvoucherrni' && responseId)  ? ` ${responseId}` : 
                    (selectedTransaction === 'butchery') ? x.Data?.ProductionHead?.Id?.toString() :
                    (selectedTransaction === 'quickorders') ? x.Data?.toString() :
                     ` ${selectedId}`;
                generateReportFilterModel.isExclude = false;
                generateReportModel.filters = [generateReportFilterModel];
                generateReportModel.IanaTimeZoneId = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
                generateReportModel.UserTimeZoneId = new Date()?.toString()?.split("(")[1]?.split(")")[0];
                const putUrl = `/report`;
                let object = {
                    code: '',
                    filterBody : generateReportModel,
                    format: "HTML",
                    downloadFileName: "Download",
                    parameters: '',
                    uRIParams: '',
                    dateFormat:  StorageService.GetSessionStorage('IniDateFormat')
                  }
                  switch(EnablePopupResult.SelectedId)
                  {
                    case(ReportId.PHYS09) : object['code'] = ReportCode.PHYS09;
                    break;
                    case(ReportId.INGFRM) : object['code'] = ReportCode.INGFRM;
                    break;
                    case(ReportId.PPOX90) : object['code'] = ReportCode.PPOX90;
                    break;
                    case(ReportId.PHYFRM) : object['code'] = ReportCode.PHYFRM;
                    break;
                    case(ReportId.MAFRM) : object['code'] = ReportCode.MAFRM;
                    break;
                    case(ReportId.MAFRMWITHOUTGL) : object['code'] = ReportCode.MAFRMWITHOUTGL;
                    break;
                    case(ReportId.PHYS09P) : object['code'] = ReportCode.PHYS09P;
                    break;
                    case(ReportId.PHYS04UNITP) : object['code'] = ReportCode.PHYS04UNITP;
                    break;
                    case(ReportId.PHYS09QOH) : object['code'] = ReportCode.PHYS09QOH;
                    break;
                    case(ReportId.PHYS10) : object['code'] = ReportCode.PHYS10;
                    break;
                    case(ReportId.RTLFRM) : object['code'] = ReportCode.RTLFRM;
                    break;
                    case(ReportId.PHYS09P_PRINT) : object['code'] = ReportCode.PHYS09P_PRINT;
                    break;
                    case(ReportId.PHYS04UNITP_PRINT) : object['code'] = ReportCode.PHYS04UNITP_PRINT;
                    break;
                  }
                const reportData = await httpService.PutthttpMethod(putUrl,
                    object, null, { observe: 'response', responseType: 'blob' }).toPromise();
                const file = new Blob([reportData.body], { type: reportData.body.type });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
        }
        let toastMessage = selectedTransaction === 'piadjusment' ? 'Adjustments Posted Successfully' :
            `${MessageName.charAt(0).toUpperCase() + MessageName.slice(1) + responseId} ${toastText} Successfully`;
        
        const message: ToastMessage = {
            Message: toastMessage,
            Show: true,
            AutoClose: true,
            TimerCount: 20000
        };
        if (selectedTransaction.toLowerCase() !== 'quickorders') {
            toastrService.success(message.Message, '', {
                timeOut: 3000,
                closeButton: true,
            });
        } else if (x?.Success) {
            toastrService.success(message.Message, '', {
                timeOut: 3000,
                closeButton: true,
            });
        } else {
            toastrService.error(message.Message, '', {
                timeOut: 3000,
                closeButton: true,
            });
        }
        const selectedTransactions = selectedTransaction === 'piadjusment' || selectedTransaction === 'pientercounts'
            || selectedTransaction === 'pientercyclecounts' || selectedTransaction === 'piprint' ? 'physicalinventory'
            : selectedTransaction;
        let transActionFrom: any;
        let transActionrouterUrl: any;
            transActionFrom = { queryParams: { transaction: selectedTransactions } };
            transActionrouterUrl = `/settings/enhancedInventory/transaction/view-${selectedTransactions.toLowerCase()}`;
        if (x && x.hasOwnProperty('DisplayPopup') && x.DisplayPopup) {
            const ConfirmPopUpDialog: ConfirmPopModel = {
                PopupHeaderContent: translateService.instant('Common.Warning'),
                PopUpContent: translateService.instant('Common.ApprovalLimitExceedMessage'),
                EnableSingleButton: true
            };
            const dialogRefs = dialog.open(AgilysysConfirmationPopupComponent, {
                width: '400px',
                height: '222px',
                data: ConfirmPopUpDialog
            });
            dialogRefs.afterClosed().subscribe(result => {
                if (result === 'Confirm') {
                    console.log('On Hold');
                }
            });
        }
        const enableIframe = StorageService.GetSessionStorage('enableIframe');
        if (enableIframe && enableIframe !== null && enableIframe !== 'null') {
            StorageService.SetSessionStorage('EnableIframeButton', JSON.stringify(this.homeGroup.value.Name));
        } else {
            if (buttonCallBackValue.buttonValue.buttonKey !== 'cyclepostspoilageloss' &&
                buttonCallBackValue.buttonValue.buttonKey !== 'cyclepostsale' &&
                buttonCallBackValue.buttonValue.buttonKey !== 'cyclepostproduction' &&
                buttonCallBackValue.buttonValue.buttonKey !== 'postproduction' ) {
                routerNavigateService.navigateRoute(transActionrouterUrl, transActionFrom);
            }
        }
    });
}

function selectedTransactionSeq(trans: string, data: any) {
    let selectedTrans = '';
    switch (trans) {
        case 'requisition':
        case 'spoilage':
        case 'opentransaction':
        case 'allpurchaserequest':
        case 'openpurchaserequest':
        case 'purchaseorder':
        case 'poopenrequisition':
        case 'poopenpr':
        case 'pientercounts':
        case 'pientercyclecounts':
        case 'piadjusment':
            selectedTrans = data && data.hasOwnProperty('TransSequenceNo') ? data.TransSequenceNo : '';
            break;
        case 'transfer':
            selectedTrans = data && data.hasOwnProperty('CustomerId') && data.CustomerId != null ?
                data.TransOutSequenceNo : data.TransSequenceNo;
            break;
        case 'apcredits':
            selectedTrans = data && data.hasOwnProperty('PayableTransSeqNo') ? data.PayableTransSeqNo : data.Id;
            break;
        // case 'receiving':
        // eslint-disable-next-line max-len
        //     this.selectedTrans = data.isinvoiced != null &&  data.isinvoiced == true ? data.transsequenceno : data.invoicetranssequenceno;
        //     break;
        // case 'savedreceiving':
        // eslint-disable-next-line max-len
        //     this.selectedTrans = data.isinvoiced != null &&  data.isinvoiced == true ? data.transsequenceno : data.invoicetranssequenceno;
        //     break;

    }
    return selectedTrans;
}

function CheckForAlreadyExistsInvoice(TransactionCollection: TransactionConfiguration, selectedId, router, transactionService,
    postModelHederEntity, postModelDetailEntity, homeGroup: FormGroup, GlobalOdataModel, httpService: HttpService, toastrService,
    routerNavigateService, selectedTransaction, IsSave, dialog, configService: ConfigService, integrationService: IntegrationService,
    manageSalesService: any, buttonCallBackValue: CallBackData, commonService: CommonService) {
    try {
        // eslint-disable-next-line max-len
        httpService.GethttpMethod(`/inventory/api/ReceivingHead?$filter=InvoiceNumber eq '${encodeURIComponent(homeGroup.value.InvoiceNumber)}' and SupplierId eq ${homeGroup.value.SupplierId} and Id ne ${parseInt(selectedId, 10)}`).subscribe(res => {
            // if (res['value'].length > 0) {
            //     if (!res['value'][0].IsPosted) {
                if (res.length > 0) {
                    if (!res[0].IsPosted) {
                    const ConfirmPopUpDialog: ConfirmPopModel = {
                        PopupHeaderContent: 'Save Invoice',
                        PopUpContent: 'Invoice Number already used. Performing save will overwrite the previously saved invoice. Do you wish to proceed ?',
                    };

                    const dialogRef = dialog.open(AgilysysConfirmationPopupComponent, {
                        width: '400px',
                        height: '222px',
                        data: ConfirmPopUpDialog,
                        disableClose: true
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result === 'Confirm') {
                            homeGroup.controls['AllowOverrideSave'].setValue(true);
                            PostCall(
                                TransactionCollection,
                                selectedId,
                                router,
                                transactionService,
                                postModelHederEntity,
                                postModelDetailEntity,
                                homeGroup,
                                GlobalOdataModel,
                                httpService,
                                toastrService,
                                routerNavigateService,
                                selectedTransaction,
                                null,
                                false,
                                buttonCallBackValue,
                                null,
                                null,
                                null,
                                null,
                                configService,
                                integrationService,
                                manageSalesService,
                                commonService
                                );
                        }
                    });
                } else {
                    const ConfirmPopUpDialog: ConfirmPopModel = {
                        PopupHeaderContent: 'Validation Failed',
                        PopUpContent: 'Cannot Save Duplicate Invoice Number.',
                    };

                    const dialogRef = dialog.open(AgilysysConfirmationPopupComponent, {
                        width: '400px',
                        height: '222px',
                        data: ConfirmPopUpDialog,
                        disableClose: true
                    });
                    dialogRef.afterClosed().subscribe(result => {
                    });
                }
            } else {
                PostCall(
                    TransactionCollection,
                    selectedId,
                    router,
                    transactionService,
                    postModelHederEntity,
                    postModelDetailEntity,
                    homeGroup,
                    GlobalOdataModel,
                    httpService,
                    toastrService,
                    routerNavigateService,
                    selectedTransaction,
                    null,
                    true,
                    buttonCallBackValue,
                    null,
                    null,
                    null,
                    null,
                    configService,
                    integrationService,
                    manageSalesService,
                    commonService
                    );
            }
        });
    } catch (error) {
        console.error('Error occurred in CheckForAlreadyExistsInvoice', error);
    }
}


function PostPO(TransactionCollection: TransactionConfiguration, transactionService,
    postModelHederEntity, postModelDetailEntity, homeGroup: FormGroup, GlobalOdataModel, httpService: HttpService, toastrService,
    routerNavigateService, selectedTransaction, dialog, analysisRedirect = null) {

    const CollectionJson: TransactionConfiguration
        = CreateTransaction.PostPOPopUpConfig(TransactionCollection, httpService, transactionService);
    const hasWrongDeliveryCutoff = CollectionJson.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList.filter(x => x['Warning'])[0];
    if (hasWrongDeliveryCutoff &&CollectionJson.CreateTranscationConfig.ItemsConfig.PostPoFormConfig.PoFormConfig.length > 0) {
        CollectionJson.CreateTranscationConfig.ItemsConfig.PostPoFormConfig.PoFormConfig[0].fieldType.SingleData.value = 'select';
    }
    const popupModels: PostPOPopUp = {
        PopupHeaderContent: 'Post PO',
        FormFields: CollectionJson.CreateTranscationConfig.ItemsConfig.PostPoFormConfig.PoFormConfig,
        PostPoFormConfig: CollectionJson.CreateTranscationConfig.ItemsConfig,
        TransactionType: CollectionJson.TransactionType
    };
    const dialogRef = dialog.open(AgilysysPostPoComponent, {
        width: '800px',
        height: '60%',
        data: popupModels,
        autoFocus: false,
        disableClose: true
    });
    dialogRef.afterClosed().subscribe(res => {
        if (res[0] === 'Confirm') {
            console.log('Confirm', res);
            const Result: PostPOSupplierConfig = res[1];
            TransactionCollection = CreateTransaction.PostPOSupplierConfig(
                CollectionJson,
                httpService,
                transactionService,
                Result,
                postModelHederEntity,
                postModelDetailEntity,
                homeGroup,
                GlobalOdataModel,
                toastrService,
                routerNavigateService,
                selectedTransaction,
                dialog,
                null,
                null,
                analysisRedirect
                );
            console.log('Transaction Collection after post', TransactionCollection);
        }
    });
}

function AssignBids(TransactionCollection: TransactionConfiguration, httpService: HttpService, transactionService: TransactionService, homeGroup?: any, from?: string) {
    try {
        // eslint-disable-next-line max-len
        let postIngredient: any;
        const listIngredient: any[] = [];
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(x => {
            postIngredient = {
                InventoryId: x.IngredientId,
                SupplierName: x.SupplierName,
                SupplierId: x.SupplierId ? x.SupplierId : 0,
                SupplierBidNumber: x.SupplierBidNumber,
                MinOrder: x.MinOrder !== null && x.MinOrder !== undefined ? x.MinOrder.toString() : x.MinOrder,
                Price: x.UnitCost !== null && x.MinOrder !== undefined ? x.UnitCost.toString() : x.UnitCost.toString(),
                Amount: x.Amount,
                Conversion: ((typeof x.Conversion) === 'string') ?  parseFloat(x.Conversion) : x.Conversion,
                UnitTypeId: x.UnitTypeId,
                UnitId : x.UnitId
            };
            listIngredient.push(postIngredient);
        });
        const AssignBidsObject = {
            IngredientId: listIngredient,
            LocationId: 0,
            Options: AssignBidsOptions(TransactionCollection, from),
            ItemDetailSupplierId : 
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.SelectedSupplier
        };
        httpService.PosthttpMethod(`/inventory/api/AssignBids`, AssignBidsObject).subscribe(res => {
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(x => {
                if (res.IngredientId) {
                    const object = res.IngredientId.filter(y => y.InventoryId === x.IngredientId)[0];
                    if (object) {
                        x.SupplierName = object.SupplierName;
                        x.SupplierId = object.SupplierId;
                        x.SupplierBidNumber = object.SupplierBidNumber;
                        x.BidNumber = object.SupplierBidNumber;
                        x.MinOrder = object.MinOrder;
                        x.UnitCost = Formater('UnitCost', object.Price, null, null);
                        x.Amount = object.Amount;
                        const Valued = x.Qty && parseFloat(x.Qty) !== 0 && x.UnitCost
                        && parseFloat(x.UnitCost) > 0 ? x.UnitCost * x.Qty : 0;
                        x.Valued = Formater('Valued', Valued, null, null);
                        // const packsize = x.IngredientsBids && x.IngredientsBids.length > 0
                        //     ? (x.IngredientsBids.filter(z => z.SupplierId === x.SupplierId)[0].Conversion ?
                        //         x.IngredientsBids.filter(z => z.SupplierId === x.SupplierId)[0].Conversion : 0) : x.Conversion;
                        x.Conversion = Formater('Conversion', object.Conversion, null, null);
                        x.UnitId = object.UnitId;
                        x.UnitTypeId = object.UnitTypeId;
                        x.UnitCode = transactionService.unitList.find(y => y.Id === object.UnitId).Code;
                        const index = x.Units.findIndex(z => z.UnitTypeId === object.UnitTypeId);
                        x.Units[index].UnitCode = x.UnitCode;
                        x.Units[index].UnitCost = x.UnitCost;
                        x.Units[index].Conversion = x.Conversion;
                    }
                }
            });
            TransactionCollection.CreateTranscationConfig.ItemsConfig
                .ItemList.ItemGridConfig.GridData = [...TransactionCollection.CreateTranscationConfig.ItemsConfig
                    .ItemList.ItemGridConfig.GridData];
            TransactionCollection.CreateTranscationConfig.ItemsConfig
                .ItemList.ItemGridConfig = {
                ...TransactionCollection.CreateTranscationConfig.ItemsConfig
                    .ItemList.ItemGridConfig
            };
            let TotalValue = transactionService.calculateValues(null,
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData);
            TotalValue = Formater('Currency', TotalValue, null, null);
            TransactionCollection.CreateTranscationConfig.DetailsConfig
                .DetailsFormModel.DetailFormHeader[1].Value = TotalValue;
            const ClearPrices = TransactionCollection.CreateTranscationConfig?.ItemsConfig?.ItemAssignBids?.FormGroupData && 
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData['ClearPrices'] ? '1' : '0';
            if (ClearPrices === '1' && homeGroup) {
              homeGroup.controls['TransactionMiscCharges'].reset();
              TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(x => {
                x.AllocatedCharges = '';
              });
            }
            if (TransactionCollection && TransactionCollection.Name === 'Purchase Order') {               
                addEstimationTotal(TransactionCollection);
            }
        });
    } catch (error) {
        console.error('Error occurred in CheckForAlreadyExistsInvoice', error);
    }
}
function AssignBidsOptions(transactionJson, from) {
    if (transactionJson.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData) {
        const ExpiredBids = transactionJson.CreateTranscationConfig.ItemsConfig
            .ItemAssignBids.FormGroupData['ExpiredBids'] ? '1' : '0';
        const Priority = transactionJson.CreateTranscationConfig.ItemsConfig
            .ItemAssignBids.FormGroupData['Priority'] ? '1' : '0';
        const SkipZero = transactionJson.CreateTranscationConfig.ItemsConfig
            .ItemAssignBids.FormGroupData['SkipZero'] ? '1' : '0';
        const ClearPrices = transactionJson.CreateTranscationConfig.ItemsConfig
            .ItemAssignBids.FormGroupData['ClearPrices'] ? '1' : '0';
        const ClearSupplier = transactionJson.CreateTranscationConfig.ItemsConfig
            .ItemAssignBids.FormGroupData['ClearSupplier'] ? '1' : '0';
        const BidRegion = transactionJson.CreateTranscationConfig.ItemsConfig
            .ItemAssignBids.FormGroupData['BidRegion'] ? '1' : '0';
        let options = ExpiredBids + Priority + SkipZero + ClearPrices + ClearSupplier + BidRegion;
        options = (from === 'fromAssignBids') ? options : '000000';
        return options;
    } else {
        return '011000';
    }
}

function OpenPrintList(dialog: MatDialog, ResultData: Array<any>, selectedtransaction: string, PrintId: number) {
    try {
        if (selectedtransaction === 'quickorder' && ResultData?.length) {
            ResultData[0].Reports = ResultData[0].Reports?.filter(report => report.Id ===178);
        }
        return new Promise<PrintPopUpCallBack>(resolve => {
            const printPopModel = {} as PrintPopModel;
            printPopModel.PopUpContent = `${selectedtransaction} Print`;
            printPopModel.PopupHeaderContent = `${selectedtransaction} Print`;
            printPopModel.PrintList = ResultData;
            printPopModel.PrintKey = selectedtransaction;
            printPopModel.EnableCount = false;
            printPopModel.PrintId = PrintId;
            const dialogRef = dialog.open(AgilysysPrintPopupComponent, {
                width: '40%',
                height: '30%',
                data: printPopModel,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe((result: PrintPopUpCallBack) => {
                resolve(result);
            });
        });
    } catch (error) {
        console.error(error);
    }
}

function populateInventoryResetHead(transactionGridata, ActivityResponse, homeGroup: FormGroup) {
    const itemListObject = [];
    transactionGridata.forEach(x => {
        let sumOfIngActSummaryQty = 0;
        let sumOfIngActSummaryValued = 0;
        if (ActivityResponse && ActivityResponse.length > 0) {
            const activityInventoryList = ActivityResponse.filter(y => y.IngredientId === x.IngredientId);
            if (activityInventoryList && activityInventoryList.length > 0) {
                activityInventoryList.forEach(element => {
                    if (element) {
                        sumOfIngActSummaryQty = sumOfIngActSummaryQty +
                            ((parseInt(element.PurchaseQty, 10) || 0) + (parseInt(element.ProductionQty, 10) || 0)
                                - (parseInt(element.TransferOutQty, 10) || 0)
                                + (parseInt(element.TransferInQty, 10) || 0) - (parseInt(element.TotalUsageQty, 10) || 0));

                        sumOfIngActSummaryValued = sumOfIngActSummaryValued +
                            ((parseInt(element.PurchaseValue, 10) || 0) + (parseInt(element.ProductionValue, 10) || 0)
                                - (parseInt(element.TransferOutValue, 10) || 0)
                                + (parseInt(element.TransferInValue, 10) || 0) - (parseInt(element.TotalUsageValue, 10) || 0));
                    }
                });
            }

        }
        const itemObject = {
            IngredientId: x.IngredientId,
            UnitTypeId: x.UnitTypeId,
            Qty: x.Qty === '' || x.Qty === null || x.Qty === undefined ? 0 : x.Qty,
            CurrentCost: x.UnitCost === '' || x.UnitCost === null || x.UnitCost === undefined ? 0 : x.UnitCost,
            Remark: x.Remark,
            LotQty: 0,
            UnitId: x.UnitId,
            SortOrder: x.SortOrder,
            Conversion: x.Conversion,
            SumOfIngActSummaryQty: sumOfIngActSummaryQty,
            SumOfIngActSummaryValued: sumOfIngActSummaryValued,
            Id: x.Id // Added for 150503
        };
        itemListObject.push(itemObject);
    });
    const date = new Date(homeGroup.value.PostDate);
    const newdate = new Date();
    let postDate: any;
    // eslint-disable-next-line max-len
    const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), newdate.getHours(), newdate.getMinutes(), newdate.getSeconds());
    if (isNaN(currentDate.getTime())) {
        postDate = null;
    } else {
        const now_utc = moment(currentDate).format(constants.PostDateTimeFormat);
        postDate = (date) ? now_utc : null;
    }
    const headObject = {
        LocationId: homeGroup.value.LocationId,
        PostDate: postDate,
        Remark: homeGroup.value.Remark,
        TransactionTypeId: homeGroup.value.TransactionTypeId,
        PhysicalDetails: itemListObject
    };
    return headObject;
}

function IsRguestEnabled(): boolean {    
    let rguestTenantId;
    const checkTenant = sessionStorage.getItem('RGuestTenantId');
    const isEatecUser = sessionStorage.getItem('isEatecUser');
    if (checkTenant && JSON.parse(checkTenant)) {
      rguestTenantId = true;
    }
    return (rguestTenantId || (isEatecUser && !JSON.parse(isEatecUser)));
}
function addEstimationTotal(TransactionCollection: any,): void {
    try {
      const AllocateCharge = (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges !== undefined &&
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges.PostData !== undefined) ?
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges.PostData : [];
      const MisCharge = (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges !== undefined &&
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData !== undefined) ?
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData : [];
      const estimationValue = ConversionAlgorithm.allocateTotal(null, TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData,
        AllocateCharge, '', MisCharge);
        TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailFormHeader.forEach(x => {
        if (x.Id === 2) {
          x.Value = Formater('Currency', estimationValue, null, null);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  export enum ReportCode
{
  PHYS09 = 'PHYS09',
  INGFRM = 'INGFRM',
  PPOX90 = 'PPOX90',
  PHYFRM = 'PHYFRM',
  MAFRM = 'MAFRM',
  MAFRMWITHOUTGL = 'MAFRMWITHOUTGL',
  PHYS09P = 'PHYS09P',
  PHYS04UNITP = 'PHYS04UNITP',
  PHYS09QOH = 'PHYS09QOH',
  PHYS10 = 'PHYS10',
  RTLFRM = 'RTLFRM',
  PHYS09P_PRINT = 'PHYS09P_PRINT',
  PHYS04UNITP_PRINT = 'PHYS04UNITP_PRINT'
}
export enum ReportId
{
  PHYS09 = 24,
  INGFRM = 44,
  PPOX90 = 71,
  PHYFRM = 77,
  MAFRM = 82,
  MAFRMWITHOUTGL = 115,
  PHYS09P = 147,
  PHYS04UNITP = 148,
  PHYS09QOH = 180,
  PHYS10 = 282,
  RTLFRM = 113,
  PHYS09P_PRINT = 161,
  PHYS04UNITP_PRINT = 162
}
