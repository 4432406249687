import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
  Validators,
  UntypedFormControl
} from "@angular/forms";
import { RetailLocalization } from "../../common/localization/retail-localization";
import * as myGlobals from '../../shared/globalsContant';
import { GiftCardsService } from "./gift-cards.data.service";
import { EmptyValueValidator } from '../../shared/Validators/EmptyValueValidator';
import { CreditCardConfigurationService } from '../credit-cards/credit-card.service';
import { GatewayConfiguration, GiftCardConfiguration, GatewayValues } from '../../shared/service/payment/payment-model';
import { GiftCardType } from '../../shared/globalsContant'
import { GiftCardBusiness } from './gift-cards.business';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { Subscription, merge } from 'rxjs';
import { RetailItemType } from '../../retail.modals';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailSetupService } from "../../retail-setup/retail-setup.service";
import { RetailFeatureFlagInformationService } from "../../shared/service/retail.feature.flag.information.service";

@Component({
  selector: "app-gift-cards",
  templateUrl: "./gift-cards.component.html",
  styleUrls: ["./gift-cards.component.scss"],
  providers: [GiftCardBusiness, GiftCardsService, CreditCardConfigurationService]
})
export class GiftCardsComponent implements OnInit, OnDestroy {
  giftcardsInfo: UntypedFormGroup;
  captions: any;
  giftCardType = [];
  viewOnly: boolean = false;
  gatewayPairs: any = [];
  defaultValues: any = [];
  config: GatewayConfiguration[];
  giftCardConfig: GiftCardConfiguration;
  enableSave: boolean;
  subscriber: Subscription[] = [];
  expiryValidate: boolean;
  retailItemType: RetailItemType.RetailItemRetailPOSOnly;
  isGatewayConfigUpdate: boolean = false;
  floatLabel: string;
  constructor(
    private localization: RetailLocalization
    , private fb: UntypedFormBuilder
    , private _rs: RetailSetupService
    , private creditCardConfigurationService: CreditCardConfigurationService
    , private giftCardsService: GiftCardsService
    , private propertyInfo: RetailPropertyInformation
    , private _utils: RetailUtilities
    , private _featureFlagService: RetailFeatureFlagInformationService
  ) {
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnDestroy(): void {
    this.subscriber && this.subscriber.length && this.subscriber.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  async ngOnInit() {
    this.captions = this.localization.captions.setting;
    this.expiryValidate = false;
    this.giftCardType = [
      { id: GiftCardType.V1GiftCard, name: this.captions.V1, description: this.captions.V1 },
      {
        id: GiftCardType.ExternalGiftCard,
        name: this.captions.ThirdParty,
        description: this.captions.ThirdParty
      },
      {
        //For Both, Don't remove , the logic has been resolved on validation
        id: (GiftCardType.V1GiftCard | GiftCardType.ExternalGiftCard),
        name: this.captions.BothV1and3rdParty,
        description: this.captions.BothV1and3rdParty
      }
    ];
    this.giftcardsInfo = this.fb.group({
      SET_ACTIVATE_GIFTCARD: false,
      SET_ALLOW_EXPIRATION: false,
      expirationDays: 365,
      giftCardType: GiftCardType.V1GiftCard,
      V1rGuestPayAgentURL: "",
      V1rGuestPayGatewayID: "",
      V1GatewayPairs: this.fb.array([this.createGatewayPairItem(0, "", "")]),
      ThirdpartyrGuestPayAgentURL: "",
      ThirdpartyrGuestPayGatewayID: "",
      ThirdpartyGatewayPairs: this.fb.array([
        this.createGatewayPairItem(0, "", "")
      ]),
      ALLOW_CASHBACK: false,
      linkedItem: "",
      selectedLinkedItem: ["", Validators.required],
      GiftCardCustomValuePairs: this.fb.array([this.createDefaultValues(0, 0, null)]),
      AllowSearchByGuestName: false
    });

    this.viewOnly = this._rs.retailSetupBreakPoints.find(bp => bp.breakPointNumber == myGlobals.RetailBreakPoint.GiftCards).view;
    this._utils.ToggleLoader(true, this.captions.lbl_processing);
    await this.getGiftCardConfiguration();
    await this.GetGatewayConfigurationAsync();
    this.setValidators();
    this.ChangeSubscriber();
    this.enableSave = false;
  }

  OnActivateChange() { this.giftcardsInfo.markAsTouched(); }

  OnAllowExpirationChange([val]) {
    let control = this.giftcardsInfo.get('expirationDays');
    if (val) {
      control.enable();
      control.setValidators([
        Validators.required,
        EmptyValueValidator,
      ]);
      this.giftcardsInfo.controls.expirationDays.markAsDirty()
    } else {
      control.disable();
      control.clearValidators();
    }
    setTimeout(x => {
      if (this.giftcardsInfo.controls['SET_ALLOW_EXPIRATION'].value && this.giftcardsInfo.controls['expirationDays'].value <= 0) {
        this.giftcardsInfo.controls['expirationDays'].setErrors({ incorrect: true });
      }
    }, 1);
    this.giftcardsInfo.controls['expirationDays'].markAsTouched();
  }

  async SaveGiftCardConfiguration() {
    this._utils.ToggleLoader(true, this.captions.lbl_processing);
    let _defaultValueArr = this.giftcardsInfo.get("GiftCardCustomValuePairs").value;
    try {
      let GiftCardConfig: GiftCardConfiguration = {
        id: this.giftCardConfig.id,
        activateGiftCardInterface: this.giftcardsInfo.get("SET_ACTIVATE_GIFTCARD").value,
        allowCashback: this.giftcardsInfo.get("ALLOW_CASHBACK").value,
        allowExpiration: this.giftcardsInfo.get("SET_ALLOW_EXPIRATION").value,
        expireDays: this.giftcardsInfo.get("expirationDays").value,
        giftCardDefaultValues: this.DelocalizeAmount(_defaultValueArr),
        giftCardType: this.giftcardsInfo.get("giftCardType").value,
        retailItemId: this.giftcardsInfo.get("linkedItem").value,
        allowSearchByGuestName: this.giftcardsInfo.get("AllowSearchByGuestName").value
      };
      await this.giftCardsService.createOrUpdateGiftCardConfiguration(GiftCardConfig).finally(() => {
        this._utils.ToggleLoader(false);
      });

      let GatewayConfig: GatewayConfiguration[] = this.GetGatewayConfig(GiftCardConfig.giftCardType, this.giftCardConfig);
      this.isGatewayConfigUpdate && GatewayConfig && GatewayConfig.length &&
        (this.config = GatewayConfig,
          await this.creditCardConfigurationService.createOrUpdateCreditCardConfiguration(GatewayConfig));
      this._utils.ToggleLoader(false);
      this._featureFlagService.SetGiftcardFlags(GatewayConfig);
      //GatewayConfiguration
      let updatedDetails = await this.giftCardsService.getGiftCardConfiguration().finally(() => {
        this._utils.ToggleLoader(false);
      });

      this.giftCardConfig = updatedDetails;
      this.propertyInfo.SetGiftCardConfiguration(updatedDetails);
      this.enableSave = false;
    }
    catch (ex) {
      this._utils.ToggleLoader(false);
      return console.error(ex);

    }
  }

  DelocalizeAmount(_defaultValueArr) {
    let customValues = [];
    if (_defaultValueArr) {
      customValues = Array.isArray(_defaultValueArr) ? _defaultValueArr.filter((val) => { return val.Amount; }) : [];
      customValues.forEach(x => x.Amount = this.localization.currencyToSQLFormat(x.Amount));
    }
    return customValues;
  }

  GetGatewayConfig(selectedType: GiftCardType, GiftCardResponse: GiftCardConfiguration): GatewayConfiguration[] {
    let _returnConfig: GatewayConfiguration[] = [],
      _localInstance: GatewayValues[] = [],
      _V1id: number = this.config && this.config.find(x => x.type == GiftCardType.V1GiftCard) && this.config.find(x => x.type == GiftCardType.V1GiftCard).id,
      _EXTid: number = this.config && this.config.find(x => x.type == GiftCardType.ExternalGiftCard) && this.config.find(x => x.type == GiftCardType.ExternalGiftCard).id;
    if ((selectedType & GiftCardType.V1GiftCard) == GiftCardType.V1GiftCard) {
      _returnConfig.push({
        id: 0,
        payAgentURL: this.giftcardsInfo.get("V1rGuestPayAgentURL").value.trim(),
        payGatewayID: this.giftcardsInfo.get("V1rGuestPayGatewayID").value.trim(),
        gatewayValues: (_localInstance = this.giftcardsInfo.get("V1GatewayPairs").value,
          this.UpdateConfigurationId(_localInstance)),
        type: GiftCardType.V1GiftCard,
      } as GatewayConfiguration);
    }

    if ((selectedType & GiftCardType.ExternalGiftCard) == GiftCardType.ExternalGiftCard) {
      _returnConfig.push({
        id: 0,
        payAgentURL: this.giftcardsInfo.get("ThirdpartyrGuestPayAgentURL").value.trim(),
        payGatewayID: this.giftcardsInfo.get("ThirdpartyrGuestPayGatewayID").value.trim(),
        gatewayValues: (_localInstance = this.giftcardsInfo.get("ThirdpartyGatewayPairs").value,
          this.UpdateConfigurationId(_localInstance)),
        type: GiftCardType.ExternalGiftCard
      } as GatewayConfiguration);
    }
    return _returnConfig;
  }

  UpdateConfigurationId(GateWayValues: GatewayValues[]) {
    GateWayValues.forEach(val => {
      val.configurationId = 0;
    });
    return GateWayValues;
  }

  async CancelGiftCardConfiguration() {
    await this.GetGatewayConfigurationAsync();
    await this.getGiftCardConfiguration();
    setTimeout(() => {
      this.disableSave();
    }, 1);
  }


  ChangeSubscriber() {
    this.subscriber.push(this.giftcardsInfo.valueChanges.subscribe((value) => {
      if (this.giftcardsInfo.valid && this.giftcardsInfo.dirty && this.giftcardsInfo.touched)
        this.enableSave = true;
      else
        this.disableSave();
    }));

    this.subscriber.push(this.giftcardsInfo.statusChanges.subscribe((value) => {
      if (this.giftcardsInfo.valid && this.giftcardsInfo.dirty && this.giftcardsInfo.touched)
        this.enableSave = true;
      else
        this.disableSave();
    }));
  }

  setValidators() {
    if (this.giftcardsInfo.get('SET_ALLOW_EXPIRATION').value) {
      this.giftcardsInfo.get('expirationDays').setValidators([
        Validators.required,
        EmptyValueValidator
      ]);
    }
    else {
      this.giftcardsInfo.get('expirationDays').clearValidators();
    }
    this.giftcardsInfo.get('expirationDays').updateValueAndValidity();
    this.subscriber.push(this.giftcardsInfo.get('expirationDays').valueChanges.subscribe(val => {
      if (this.giftcardsInfo.controls['SET_ALLOW_EXPIRATION'].value && this.giftcardsInfo.controls['expirationDays'].value <= 0) {
        this.giftcardsInfo.controls['expirationDays'].setErrors({ incorrect: true });
      }
    }));
    this.subscriber.push(this.giftcardsInfo.get("SET_ACTIVATE_GIFTCARD").valueChanges.subscribe(val => {

      if (val)
        this.GatewayTypeChangeValidators(this.giftcardsInfo.get("giftCardType").value);
    }));
    this.subscriber.push(this.giftcardsInfo.get("giftCardType").valueChanges.subscribe(val => {
      this.GatewayTypeChangeValidators(val);
    }));

    this.subscriber.push(merge(
      this.giftcardsInfo.get("V1rGuestPayAgentURL").valueChanges
      , this.giftcardsInfo.get("V1rGuestPayGatewayID").valueChanges
      , this.giftcardsInfo.get("V1GatewayPairs").valueChanges
      , this.giftcardsInfo.get("ThirdpartyrGuestPayAgentURL").valueChanges
      , this.giftcardsInfo.get("ThirdpartyrGuestPayGatewayID").valueChanges
      , this.giftcardsInfo.get("ThirdpartyGatewayPairs").valueChanges
    ).subscribe(val => {
      this.isGatewayConfigUpdate = true;
      this.giftcardsInfo.markAsTouched();
    }));

    this.setGatewayTypeChangeValidators(this.giftcardsInfo.get('giftCardType').value);
  }

  setGatewayTypeChangeValidators(val): void {
    let _thirdPartyGatewayControl = this.giftcardsInfo.get("ThirdpartyrGuestPayGatewayID"),
      _thirdPartyGatewayPairs = this.giftcardsInfo.get("ThirdpartyGatewayPairs"),
      _v1GatewayControl = this.giftcardsInfo.get("V1rGuestPayGatewayID"),
      _v1GatewayPairs = this.giftcardsInfo.get("V1GatewayPairs");

    if ((val & GiftCardType.ExternalGiftCard) === GiftCardType.ExternalGiftCard) {
      _thirdPartyGatewayControl.setValidators([Validators.required, EmptyValueValidator]);
    }
    if (!val) {
      this.clearValiators(_v1GatewayControl as UntypedFormControl);
      this.removeValidators(_v1GatewayPairs as UntypedFormArray);
      this.clearValiators(_thirdPartyGatewayControl as UntypedFormControl);
      this.removeValidators(_thirdPartyGatewayPairs as UntypedFormArray);
    }
  }

  GatewayTypeChangeValidators(val): void {
    let _thirdPartyGatewayControl = this.giftcardsInfo.get("ThirdpartyrGuestPayGatewayID"),
      _thirdPartyGatewayPairs = this.giftcardsInfo.get("ThirdpartyGatewayPairs"),
      _v1GatewayControl = this.giftcardsInfo.get("V1rGuestPayGatewayID"),
      _v1GatewayPairs = this.giftcardsInfo.get("V1GatewayPairs");
    //Check if v1 gateway selected or both selected    
    if ((val & GiftCardType.V1GiftCard) === GiftCardType.V1GiftCard) {

      this.enableKeyValuePairValidation('V1GatewayPairs', 'configKey', 'configValue');
      //if v1 alone selected remove validation for 3rd party
      if (val === GiftCardType.V1GiftCard) {
        this.clearValiators(_thirdPartyGatewayControl as UntypedFormControl);
        this.removeValidators(_thirdPartyGatewayPairs as UntypedFormArray);
      }
    }
    //Check if 3rd party gateway selected or both selected
    if ((val & GiftCardType.ExternalGiftCard) === GiftCardType.ExternalGiftCard) {
      _thirdPartyGatewayControl.setValidators([Validators.required, EmptyValueValidator]);

      _thirdPartyGatewayControl.updateValueAndValidity();
      this.enableKeyValuePairValidation('ThirdpartyGatewayPairs', 'configKey', 'configValue');
      //if 3rd party alone selected remove validation for v1
      if (val === GiftCardType.ExternalGiftCard) {
        this.clearValiators(_v1GatewayControl as UntypedFormControl);
        this.removeValidators(_v1GatewayPairs as UntypedFormArray);
      }
    }
    if (!val) {
      this.clearValiators(_v1GatewayControl as UntypedFormControl);
      this.removeValidators(_v1GatewayPairs as UntypedFormArray);
      this.clearValiators(_thirdPartyGatewayControl as UntypedFormControl);
      this.removeValidators(_thirdPartyGatewayPairs as UntypedFormArray);
    }
  }

  enableKeyValuePairValidation(type: string, key: string, value: string) {
    this.toggleKeyValueValidation(false, type, key, value);

    if (this.giftcardsInfo.get("SET_ACTIVATE_GIFTCARD").value) {
      this.toggleKeyValueValidation(true, type, key, value);
    }

    this.giftcardsInfo.get(type).markAsDirty();
    const gatewayValues: UntypedFormArray = this.giftcardsInfo.get(type) as UntypedFormArray;
    gatewayValues.markAsDirty();
  }

  toggleKeyValueValidation(status: boolean, type: string, key: string, value: string) {
    if (status) {
      this.giftcardsInfo
        .get(type)
      ["controls"].map((x: UntypedFormGroup, index: number) => {
        if (key) {
          x["controls"][key].setValidators([
            Validators.required,
            EmptyValueValidator
          ]);
          x["controls"][key].updateValueAndValidity();
        }
        if (value) {
          x["controls"][value].setValidators([
            Validators.required,
            EmptyValueValidator
          ]);
          x["controls"][value].updateValueAndValidity();
        }
      });
    } else {
      this.giftcardsInfo
        .get(type)
      ["controls"].map((x: UntypedFormGroup, index: number) => {
        this.clearValiators(x["controls"][key] as UntypedFormControl);
        this.clearValiators(x["controls"][value] as UntypedFormControl);
      });
    }
  }


  getErrorMessage(control: string, index: number) {
    switch (control) {
      case "ThirdpartyrGuestPayGatewayID":
      case "V1rGuestPayGatewayID":
        return this.giftcardsInfo.get("SET_ACTIVATE_GIFTCARD").value
          ? this.captions.MissingrGuestPayGateway
          : "";

      case "V1GatewayPairs":
        if (
          index == 0 &&
          this.giftcardsInfo.get("V1GatewayPairs")["controls"].length == 1
        ) {
          return this.giftcardsInfo.get("SET_ACTIVATE_GIFTCARD").value
            ? this.captions.MissingKeyPairValue
            : "";
        }
        break;

      case "ThirdpartyGatewayPairs":
        if (
          index == 0 &&
          this.giftcardsInfo.get("ThirdpartyGatewayPairs")["controls"].length ==
          1
        ) {
          return this.giftcardsInfo.get("SET_ACTIVATE_GIFTCARD").value
            ? this.captions.MissingKeyPairValue
            : "";
        }
        break;
      case "GiftCardCustomValuePairs":
        if (
          index == 0 &&
          this.giftcardsInfo.get("GiftCardCustomValuePairs")["controls"].length <
          1
        ) {
          return this.giftcardsInfo.get("SET_ACTIVATE_GIFTCARD").value
            ? this.captions.InvalidCustomValues
            : "";
        }
        break;
      case "expirationDays":
        if (
          this.giftcardsInfo.get("expirationDays")["controls"].length ==
          1
        ) {
          return this.giftcardsInfo.get("SET_ACTIVATE_GIFTCARD").value
            ? this.captions.InvalidExpiryDays
            : "";
        }
        break;

      default:
        break;
    }
  }

  keyPress(event: any) {
    this.giftcardsInfo.markAsTouched();
    if (event.target.value.length >= 200) {
      event.preventDefault();
    }
  }

  giftCardValueChange(event: any) {
    let val = this.giftcardsInfo.controls['V1GatewayPairs']['controls'][0].value;
    if (val) {
      this.giftcardsInfo.controls['V1GatewayPairs']['controls'][0].setValue(val);
    }
    this.giftcardsInfo.markAsTouched();
    this.giftcardsInfo.controls.V1GatewayPairs.markAsTouched();
    this.giftcardsInfo.controls.ThirdpartyGatewayPairs.markAsTouched();
  }

  externalGiftCardValueChange(event: any) {
    let val = this.giftcardsInfo.controls['ThirdpartyGatewayPairs']['controls'][0].value;
    if (val) {
      this.giftcardsInfo.controls['ThirdpartyGatewayPairs']['controls'][0].setValue(val);
    }
    this.giftcardsInfo.markAsTouched();
    this.giftcardsInfo.controls.V1GatewayPairs.markAsTouched();
    this.giftcardsInfo.controls.ThirdpartyGatewayPairs.markAsTouched();
  }

  clearValiators(control: UntypedFormControl) {
    try {
      control.setValidators(null);
      control.clearValidators();
      control.updateValueAndValidity();
    }
    catch {
      return null;
    }
  }

  removeValidators(form: UntypedFormArray) {
    for (const key in form.controls) {
      for (const control in form.get(key)['controls']) {
        form.get(key)['controls'][control].setValidators(null);
        form.get(key)['controls'][control].clearValidators();
        form.get(key)['controls'][control].updateValueAndValidity();
      }
    }
  }

  LinkedItemEmit(event) {
    this.giftcardsInfo.controls.linkedItem.setValue(event > 0 ? event : 0);
  }

  async getGiftCardConfiguration() {
    this.giftCardConfig = await this.giftCardsService.getGiftCardConfiguration().finally(() => {
      this._utils.ToggleLoader(false);
    });
    let _length = (this.giftcardsInfo.get('GiftCardCustomValuePairs') as UntypedFormArray).length;
    if (_length) this.removeAllGatewayPairs("GiftCardCustomValuePairs");
    this.giftCardConfig.giftCardDefaultValues.length ? this.giftCardConfig.giftCardDefaultValues.forEach((x, i) => {
      //Index 0 alone removed , because we added only one default value
      if (i == 0)
        (this.giftcardsInfo.get('GiftCardCustomValuePairs') as UntypedFormArray).removeAt(i);
      this.addDefaultValues(x.id, 'GiftCardCustomValuePairs', x.giftCardConfigurationId, x.amount);
    }) : this.addDefaultValues(0, 'GiftCardCustomValuePairs', 0, 0);

    this.giftcardsInfo.patchValue({
      SET_ACTIVATE_GIFTCARD: this.giftCardConfig.activateGiftCardInterface,
      SET_ALLOW_EXPIRATION: this.giftCardConfig.allowExpiration,
      expirationDays: this.giftCardConfig.expireDays,
      giftCardType: (this.giftCardConfig.giftCardType || GiftCardType.V1GiftCard),
      ALLOW_CASHBACK: this.giftCardConfig.allowCashback,
      linkedItem: this.giftCardConfig.retailItemId,
      GiftCardCustomValuePairs: this.giftCardConfig.giftCardDefaultValues,
      AllowSearchByGuestName: this.giftCardConfig.allowSearchByGuestName
    });
    this.GatewayTypeChangeValidators((this.giftCardConfig.giftCardType || GiftCardType.V1GiftCard));
  }

  async GetGatewayConfigurationAsync() {
    let result: GatewayConfiguration[] = await this.creditCardConfigurationService.getCreditCardConfiguration({ type: (this.giftCardConfig && this.giftCardConfig.giftCardType) }).finally(() => {
      this._utils.ToggleLoader(false);
    }),
      v1Available: boolean = false,
      trdPartyAvailable: boolean = false;
    if (result && result.length && (this.config = result)) {
      this.removeAllGatewayPairs('V1GatewayPairs');
      this.removeAllGatewayPairs('ThirdpartyGatewayPairs');
      this.config.forEach(element => {
        if (element.type == GiftCardType.V1GiftCard) {
          this.giftcardsInfo.patchValue({
            V1rGuestPayAgentURL: element.payAgentURL,
            V1rGuestPayGatewayID: element.payGatewayID
          }),
            v1Available = element.gatewayValues.length ? true : false,
            element.gatewayValues.forEach((x, i) => {
              if (x.configKey) {
                let _gatewayArr: UntypedFormArray;

                i == 0 ?
                  (_gatewayArr = (this.giftcardsInfo.get('V1GatewayPairs') as UntypedFormArray),
                    (_gatewayArr.length && _gatewayArr.removeAt(i))) : null;
              }
              this.addGatewayPairs(x.configurationId, 'V1GatewayPairs', x.configKey, x.configValue);
            });
        }
        if (element.type == GiftCardType.ExternalGiftCard) {
          this.giftcardsInfo.patchValue({
            ThirdpartyrGuestPayAgentURL: element.payAgentURL,
            ThirdpartyrGuestPayGatewayID: element.payGatewayID
          }),
            trdPartyAvailable = element.gatewayValues.length ? true : false,
            element.gatewayValues.forEach((x, i) => {
              if (x.configKey) {
                let _gatewayArr: UntypedFormArray;
                if (i == 0) {
                  _gatewayArr = (this.giftcardsInfo.get('ThirdpartyGatewayPairs') as UntypedFormArray), (_gatewayArr.length && _gatewayArr.removeAt(i));
                }
              }
              this.addGatewayPairs(x.configurationId, 'ThirdpartyGatewayPairs', x.configKey, x.configValue);
            });
        }
      });
      if (!v1Available)
        this.addGatewayPairs(0, 'V1GatewayPairs', '', '');
      if (!trdPartyAvailable)
        this.addGatewayPairs(0, 'ThirdpartyGatewayPairs', '', '');
    }
  }

  /**
   * Disable Save Button
   */
  disableSave() {
    if (this.enableSave) {
      this.enableSave = false;
    }
  }

  /**
   * Create Gateway Pair Item Model
   * @param _index Gateway Mapper Id For Default Values
   * @param _key Default Configuration Key
   * @param _value Default Configuration value
   */
  createGatewayPairItem(_index: number, _key: any, _value: any): UntypedFormGroup {
    return this.fb.group({
      configurationId: _index,
      configKey: _key,
      configValue: _value
    });
  }

  createDefaultValues(_index: number, _key: number, _value: number): UntypedFormGroup {
    return this.fb.group({
      id: (_key == 0 ? 0 : _index),
      GiftCardConfigurationId: (this.giftCardConfig && this.giftCardConfig.id) || _key,
      Amount: [_value, [EmptyValueValidator, Validators.min(1)]]
    });
  }

  addDefaultValues(_index: number, type: string, _key: number, _value: number) {
    let _ctrls: any;
    if (this.giftcardsInfo.controls[type].invalid ||
      (_ctrls = (this.giftcardsInfo.get(type) as UntypedFormArray).controls, _ctrls.length > _index && _ctrls[_index].value.Amount == "0.00")) {
      return;
    }

    this.defaultValues = this.giftcardsInfo.get(type) as UntypedFormArray;
    this.defaultValues.push(this.createDefaultValues(_index, _key, _value));

  }
  removeDefaultValues(_index: any, type: string) {
    this.giftcardsInfo.get(type).markAsTouched();
    this.defaultValues = (this.giftcardsInfo.get(type) as UntypedFormArray) || [];
    this.defaultValues.removeAt(_index);
  }

  addGatewayPairs(_index: number, type: string, _key: any, _value: any) {
    if (this.giftcardsInfo.controls[type].invalid)
      return;
    this.gatewayPairs = this.giftcardsInfo.get(type) as UntypedFormArray;
    this.gatewayPairs.push(this.createGatewayPairItem(_index, _key, _value));
    if ((this.giftcardsInfo.get("giftCardType").value == GiftCardType.V1GiftCard && type == "V1GatewayPairs") ||
      (this.giftcardsInfo.get("giftCardType").value == GiftCardType.ExternalGiftCard && type == "ThirdpartyGatewayPairs"))
      this.enableKeyValuePairValidation(type, 'configKey', 'configValue');
  }

  removeGatewayPairs(_index: any, type: string) {
    this.giftcardsInfo.get(type).markAsTouched();
    this.gatewayPairs = (this.giftcardsInfo.get(type) as UntypedFormArray) || [];
    this.gatewayPairs.removeAt(_index);
  }

  removeAllGatewayPairs(type: string) {
    this.gatewayPairs = (this.giftcardsInfo.get(type) as UntypedFormArray) || [];
    while (this.gatewayPairs.length !== 0) {
      this.gatewayPairs.removeAt(0);
    }
  }

  markTouched() {
    this.giftcardsInfo.get("expirationDays").markAsTouched();
  }
}
