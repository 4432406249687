export const routeJSON = {
  "host": {
    "image": "/VisualOne/spagateway/",
    "spaManagement": "/VisualOne/spagateway/",
    "schedule": "/VisualOne/spagateway/",
    "retailManagement": "/VisualOne/retailgateway/",
    "retailPOS": "/VisualOne/retailgateway/",
    "authentication": "/commongateway/",
    "report": "/VisualOne/spagateway/",
    "commission": "/VisualOne/retailgateway/",
    "payment": "/VisualOne/retailgateway/",
    "common": "/VisualOne/spagateway/Spa/Services/Notification/",
    "documentation": "/Help/",
    "commonGateway": "/commongateway/",
    "CommonGateway": "/commongateway/",
    "signalrService": "/commongateway/SignalRService"
  },
    "Login": "Property/GetLoginDetails",
    "LogOut": "User/LogOutByUserId/{Username}/{TenantId}/{PropertyId}",
    "QuickLoginByUserId": "User/Login",
    "QuickLoginByQuickId": "Login/LoginByQuickId",
    "GetTenantbyConfig": "Login/api/Configuration",
    "AuthorizeBySession": "User/AuthorizeBySession",
    "SavePassword": "User/SavePassword/{UserId}/{NewPassword}/{TenantId}/{PropertyId}",
    "CreateSession": "User/session",
    "UpdateSession": "User/session/sessionId/{sessionId}",
    "CheckPassword": "User/CheckPasswordExists/{UserId}/{NewPassword}/{TenantId}",
    "VerifyPassword": "User/VerifyPassword/{UserId}/{NewPassword}/{TenantId}",
    "PasswordSetting": "Property/GetPasswordSettings/{TenantId}",
    "GetUserInfoByPropertyId": "User/GetUserInfoByPropertyId/{propertyId}/{productId}",
    "EatecToken": "Login/Token/Eatec",
    "GetAllLoc": "locations",
    "GetAllLocByUserId": "locations/useraccess",
    "GetSpecLoc": "locations",
    "CreateLoc": "locations",
    "UpdateLoc": "locations",
    "deleteLoc": "locations",
    "GetLocation": "locations/locationDetail/{id}",
    "isServiceHasOneLocation": "locations/serviceMapping/service/{serviceId}/location/{locationId}",
    "GetAllEquip": "equipments",
    "CreateEquip": "equipments",
    "UpdateEquip": "equipments",
    "deleteEquip": "equipments",
    "GetEquipment": "equipments/id/{id}",
    "LocationDragDrop": "Locations/listorder",
    "GetAllServiceGrp": "serviceGroups",
    "GetAllServiceGrpByUserId": "serviceGroups/useraccess",
    "GetAllGroups":"Management/serviceGroups/all",
    "CreateServiceGrp": "serviceGroups",
    "UpdateServiceGrp": "serviceGroups",
    "DeleteServiceGrp": "serviceGroups",
    "ServiceGrpDragDrop": "serviceGroups/listorder",
    "GetAllGuestType": "guestType",
    "CreateGuestType": "guestType",
    "UpdateGuestType": "guestType/{id}",
    "DeleteGuestType": "guestType/{id}",
    "GuestTypeDragDrop": "guestType/listorder",
    "ServiceDragDrop": "services/listorder",
    "GetAllMC": "medicalConditions",
    "CreateMC": "medicalConditions",
    "UpdateMC": "medicalConditions",
    "deleteMC": "medicalConditions",
    "maxListOrderLocation": "locations/nextlistorder",
    "maxListOrderGroup": "serviceGroups/nextlistorder",
    "maxListOrderGuestType": "guestType/nextlistorder",
    "maxListOrderService": "services/nextlistorder",
    "maxListOrderRelationship": "relationship/nextlistorder",
    "GetAllAddOns": "addOns",
    "CreateAddOn": "addOns",
    "UpdateAddOn": "AddOnsAction/{id}",
    "DeleteAddOn": "AddOnsAction/{id}",
    "GetAllSpaService": "services",
    "GetAllSpaServiceByUserId": "services/useraccess",
    "GetServices": "services/list",
    "GetSpecificService": "services/{id}",
    "DeleteSpaService": "services/{id}",
    "CreateSpaService": "services",
    "updateSpaService": "services/{id}",
    "GetServiceTherapistsWithGroup": "services/servicetherapists",
    "GetServiceTherapists": "services/therapists",
    "getAllMedicalConditions": "medicalConditions",
    "CreateNewAppointment": "appointments",
    "DeleteAppointments": "appointments",
    "CreateTempHoldAppointment": "appointments/temphold/overbooktherapist/{istherapistoverbook}/overbooklocation/{islocationoverbook}",
    "UpdateAppointment": "appointments/{id}/create/{isCreateAppointment}",
    "GetAppointments": "appointments/from/{FromDate}/to/{ToDate}/{includeAllAppointments}/{includeOnlyScheduled}/{isLockersEnabled}",
    "GetAppointmentsWithTempCreated": "appointments/temp/{id}",
    "GetAppointmentById": "appointments/{id}",
    "GetAllTherapist": "therapists/detail",
    "GetTherapistDetailById": "therapists/detail/{id}",
    "AccesibleTherapist": "therapists/useraccess",
    "CreateTherapistDetails": "therapists",
    "getActiveTherapist": "therapists/active",
    "GetSpecificTherapist": "therapists",
    "createTherapistContact": "therapists/{id}/contacts",
    "getTherapistCertification": "therapists/{id}/services/{includeServiceDetail}",
    "getMaxListOrderTherapist": "therapists/nextlistorder",
    "dragAndDropActiveTherapist": "therapists/active/listorder/from/{fromorder}/to/{toorder}",
    "dragAndDropTherapist": "therapists/listorder/from/{fromorder}/to/{toorder}",
    "DeleteTherapist": "therapists",
    "getTherapistContact": "therapists/{id}/contacts",
    "getTherapistAddOn": "therapists/{id}/addOns",
    "saveImage": "images",
    "getImagesByReference": "images/type/{referencetype}/{referenceid}",
    "getAllImagesByReference": "images/type/{referencetype}/{referenceids}/thumbnailonly/{isthumbnailonly}",
    "deleteImagesByReference": "images/type/{referencetype}/{referenceid}",
    "updateImageById": "images/{id}",
    "GetAllImagesByIds": "images/type/{referencetype}/thumbnailonly/{isthumbnailonly}",
    "GetImagesByGuid": "v2/images?guid={imageReferenceId}&isThumbnailOnly={isThumbnailOnly}",
    "v2saveImage": "v2/Images",
    "v2updateImage": "v2/Images",
    "v2DeleteImageByReference": "v2/images/{guid}",
    "v2GetImagesByReferenceId": "v2/images?guid={imageReferenceId}&isThumbnailOnly={isThumbnailOnly}",
    "v2GetAllImagesByReferenceId": "v2/images/list?isThumbnailOnly={isThumbnailOnly}",
    "GetDashBoardData": "dashboard/date/{datetime}",
    "AppointmentStatusData": "dashboard/appointment/statistics/date/{datetime}",
    "AppointmentDataScheduled": "dashboard/nearing/schedule/time/{datetime}",
    "AppointmentDataCheckOut": "dashboard/nearing/checkout/time/{datetime}",
    "ClientData": "dashboard/client/statistics/date/{datetime}",
    "AvailableTherapistInt": "dashboard/therapist/count/date/{datetime}",
    "updateTherapistDetails": "therapists/{id}",
    "updateTherapistContacts": "therapists/{id}/contacts",
    "updateTherapistServiceDetails": "therapists/{id}/services",
    "LinkAppointmentTherapist": "appointments/{id}/therapist",
    "LinkAppointmentClient": "appointments/{id}/client",
    "ValidateLocation": "appointments/validate/location/{id}/date/{date}",
    "getClientRecentAppointmentInfo": "appointments/client/{clientID}",
    "GetCommissions": "CommissionSetup/class/{classId}/productId/{productId}/outlet/{outletId}",
    "UpdateCommission": "CommissionSetup/class/{classId}",
    "createNewClient": "clients",
    "CreateBulkClient": "clients/bulk",
    "GetContactTypes": "clients/contacttypes/{type}",
    "GetContactPhoneTypes": "clients/contacttypes/Phone",
    "GetClientConfiguration": "settings/module/Client",
    "clientSearch": "clients/search/{name}/{searchType}/{requestUid}",
    "extendedClientSearch": "clients/search/{key}/{searchType}/{requestUid}/{clientType}?isExternalGuestSearch={isExternalGuestSearch}&isPlatformGuestSearch={isPlatformGuestSearch}",
    "getClientInfo": "clients/{id}",
    "getCopyClientInfo":"clients/clientInfo/{id}",
    "getClientInfoByGuid": "clients/guid/{guid}?isCopyClient={isCopyClient}",
    "GetClientByPlatformGuestUuid": "Clients/platformGuid/{platformGuid}",
    "getClientMedicalConditions": "clients/medicalConditions/{id}",
    "getAppointmentFilters": "appointments/{id}/filter/overbooktherapist/{isTherapistOverbook}/overbooklocation/{isLocationOverbook}/timeavailable/{timeAvailable}/date/{date}/tempholdlist/{tempIds}",
    "GetAllTherapistSchedules": "therapistschedule/from/{fromDate}/to/{toDate}?therapistId={therapistId}&isInstructorSchedule={isInstructorSchedule}",
    "getTherapistSchedule": "therapistschedule/{therapistId}/from/{fromDate}/to/{toDate}",
    "getTherapistScheduleView":"therapistschedule/TherapistView/{therapistId}/from/{fromDate}/to/{toDate}",
    "createTherapistSchedule": "therapistschedule/overwrite/{isOverwiteExiting}",
    "EditTherapistSchedule": "TherapistSchedule/overwrite/{isOverwiteExisting}",
    "GetLinkCodes": "linkCodes/date/{propertyDate}/expired/{showInActive}",
    "GetLinkCodesRange": "linkCodes/date/{fromDate}/{toDate}",
    "GetGuestMemberId": "clients/GetGuestByMemberID/{memberId}",
    "GetAllPackagesForAppointment": "packages/available/date/{dateTime}/day/{day}/maxpeople/{maxPeople}",
    "GetPackageComponents": "packages/{id}/dateTime/{dateTime}/day/{day}/components",
    "GetTherapistPackageAppt": "appointmentpackage/filter/service/{serviceId}/date/{date}/client/{clientId}/excludeappointment/{excludeAppointmentId}",
    "MoveCopyFilter": "appointmentpackage/filter/service/{serviceId}/date/{date}",
    "GetPackageServiceLocation": "appointmentpackage/filterlocation/service/{serviceId}/date/{dateTime}/therapist/{therapistId}/excludeappointment/{excludeAppointmentId}",
    "CreateAppointmentPackage": "appointmentpackage/overbook/{overbook}",
    "GetAllSpaPackage": "packages/active",
    "GetAllSpaPackageDetails": "packages/activepackages",
    "GetSpecificPackageByDate": "packages/{id}/date/{date}/day/{day}",
    "AddPackage": "packages",
    "GetSpecificPackage": "packages/{id}",
    "DeletePackage": "packages/{id}",
    "GetPackageYield": "packages/yield/from/{fromDate}/to/{toDate}",
    "UpdatePackageYield": "packages/yield",
    "UpdatePackage": "packages/{id}",
    "GetSpaPackages": "packages",
    "CopyPackage": "packages/{id}/copy",
    "GetAllRecapAppointments": "appointments/{id}/recap/client/{clientId}/date/{date}/showall/{isShowAll}",
    "GetAllRecapAppointmentsforClient": "appointments/recap/client/{clientId}",
    "GetRecapAppointmentInfo": "appointments/{id}/recap",
    "PackageYieldCountUpdate": "packages/{id}/yield/{appointmentId}",
    "CreateTherapistBreak": "appointments/break/therapist/{therapistId}",
    "GetAppointmentsByStatus": "schedule/appointments/client/{clientId}/status/{status}/date/{date}/{outletId}",
    "CancelAppointment": "appointments/cancel",
    "AppointmentSearchClientFilter": "appointments/search/{searchValue}",
    "GetChangeServiceAppointments": "appointments/{id}/changeservice/filter/time/{requestDTTM}",
    "ChangeAppointmentService": "appointments/{id}/changeservice",
    "StandardPackAvailLocation": "appointmentpackage/availablelocations/service/{serviceId}/date/{dateTime}/therapist/{therapistId}",
    "UpdateTempHoldAppointment": "appointments/temphold",
    "SearchAppointments": "appointments/searchappointments",
    "LinkedAppointments": "appointments/LinkedAppointments/{isLinked}",
    "LinkAppointments": "appointments/LinkAppointments",
    "GetCustomFields": "customfields",
    "AddDefaultCustomFields": "customfields/default",
    "GetCustomSpecificField": "customfields/{id}",
    "getOccupiedLocation": "appointments/filter/date/{filterDate}",
    "GetCustomFieldsWithValues": "customfields/withvalues",
    "IsCustomFieldUsed": "customfields/validate/inuse/{id}",
    "DeleteTempHold": "appointments",
    "GetSpaPackageClass": "packageClass",
    "GetGuid": "appointmentpackage/guid",
    "GetPackageAppointmentDate": "packages/{id}/appointmentrange",
    "GetPackageAppointmentRetailItems": "appointments/retailItems/{packageGroupId}",
    "GetTherapistBreak": "appointments/therapist/{therapistId}/break/{breakTime}",
    "ModifyTherapistBreak": "appointments/{id}/therapist/{therapistId}/break",
    "DeleteTherapistBreak": "appointments/{id}/therapist/{therapistId}/break/{breakTime}",
    "GetBreakTypes": "breakTypes",
    "UpdateCustomeField": "customfields/{id}",
    "MoveAppointment": "appointments/move",
    "MoveAppointmentDragandDrop": "appointments/movedraganddrop/{isLocationView}",
    "CreateBreakType": "breakTypes",
    "GetAllBreakTypes": "breakTypes",
    "GetBreakType": "breakTypes/{id}",
    "DeleteBreakType": "breakTypes/{id}",
    "UpdateBreakType": "breakTypes/{id}",
    "GetValidServices": "services/from/{startDate}/to/{endDate}",
    "CreateRecurringAppointment": "appointments/{id}/recurring/pickanylocation/{anyLocation}/redeemmultipcak/{redeemMultipack}",
    "CopyAppointment": "appointments/{id}/copy",
    "GetAvailableLunchSlots": "lunch/slot/date/{date}",
    "CreateClientLunch": "appointments/lunch/client/{clientId}",
    "GetSwappableAppointments": "appointments/{id}/swap/filter",
    "SwapAppointment": "appointments/swap",
    "ReInstateAppointment": "appointments/reinstate",
    "GetAppointmentsForMultiMove": "appointments/scheduled/from/{fromDate}/to/{toDate}/therapist/{therapistId}/location/{locationId}",
    "GetAvailableTherapists": "appointments/availabletherapists/service/{serviceId}/from/{startTime}/to/{endTime}/{appointmentId}",
    "GetAvailableLocations": "appointments/availablelocations/service/{serviceId}/from/{startTime}/to/{endTime}/therapist/{therapistId}",
    "UndoCheckInAppointment": "appointments/{id}/checkin/undo",
    "ChangeAppointmentStatus": "schedule/appointments/{appointmentId}/changestatus/{toStatus}/{noShowComments}/{outletId}",
    "UndoCheckOutAppointment": "appointments/checkout/undo",
    "UndoCheckOutAppointmentByTransactionId": "appointments/checkout/undo/transaction/{transactionId}",
    "CheckinAppointment": "appointments/checkin/tracknoshow/{isNoShow}/isCreateFolio/{isCreateFolio}",
    "updateClient": "clients/{id}",
    "clientprofilelink": "clients/clientprofilelink",
    "createClientByGuestId": "clients/guid/{guid}",
    "GetItenerary": "itinerary/{clientId}",
    "GetIteneraryByDateRange": "itinerary/{clientId}/{fromDate}/{toDate}",
    "GetItineraryForAppointment": "itinerary/{clientId}/time/{datetime}",
    "GetConflictingActivities": "itinerary/{clientId}/conflict/schedule/from/{startTime}/to/{endTime}",
    "MoveMultipleAppointments": "appointments/movemultiple/therapist/{therapistId}/from/{fromDate}/to/{toDate}/deleteschedule/{isDeleteSchedule}",
    "GetAppointmentAddons": "appointments/{id}/addon/service/{serviceId}",
    "GetServiceTherapistAddons": "appointments/addon/service/{serviceId}/therapist/{therapistIds}",
    "AddOrUpdateAppointmentAddons": "appointments/{id}/addon",
    "CreatePriceType": "priceTypes",
    "UpdatePriceType": "priceTypes/{id}",
    "GetAllPriceTypes": "priceTypes",
    "DeletePriceType": "priceTypes/{id}",
    "CreateLinkCode": "linkCodes",
    "UpdateLinkCode": "linkCodes/{id}",
    "GetLinkCode": "linkCodes/{id}",
    "GetAllLinkCodes": "linkCodes",
    "DeleteLinkCode": "linkCodes/{id}",
    "CreateServiceYield": "serviceYield/{isOverwiteExisting}",
    "GetMonthlyYield": "serviceYield/service/{serviceId}/month/{month}/year/{year}",
    "GetDateYield": "serviceYield/service/{serviceId}/date/{date}",
    "GetAllLocationMaintenance": "locations/maintenance",
    "GetAllEquipmentMaintenance": "equipments/maintenance",
    "DeleteLocationMaintenance": "locations/maintenance",
    "DeleteEquipmentMaintenance": "equipments/maintenance",
    "GetClients": "clients/list",
    "GetClientsByGuestIds": "clients/guids",
    "GetAppointmentConfiguration": "settings/module/Appointment",
    "ConfirmAppointment": "appointments/confirm/{isConfirm}/{isNotesUpdated}",
    "GetAllCancelReasons": "cancelReasons",
    "CreateCancelReason": "cancelReasons",
    "UpdateCancelReason": "cancelReasons/{id}",
    "DeleteCancelReason": "cancelReasons/{id}",
    "GetActiveCancelReason": "cancelReasons/active",
    "GetAllPackageClasses": "packageClass",
    "CreatePackageClass": "packageClass",
    "UpdatePackageClass": "packageClass/{id}",
    "DeletePackageClass": "packageClass/{id}",
    "LinkCodeDragDown": "linkCodes/listorder/from/{fromorder}/to/{toorder}",
    "maxListOrderLinkCode": "linkCodes/nextlistorder",
    "getTherapistUnavailability": "therapistunavailability",
    "SaveTherapistUnavailability": "therapistunavailability/{byPassAppointments}",
    "DeleteTherapistUnavailability": "therapistunavailability",
    "UpdateTherapistUnavailability": "therapistunavailability/{id}/{byPassAppointments}",
    "CreateLunchSetup": "lunch",
    "GetAllLunch": "lunch/from/{fromdate}/to/{toDate}",
    "GetLocationConflictAppointments": "appointments/conflict/location/{locationId}",
    "GetEquipmentConflictAppointments": "appointments/conflict/equipment/{equipmentId}",
    "CreateLocationMaintenance": "locations/maintenance",
    "CreateEquipmentMaintenance": "equipments/maintenance",
    "CreateTherapistBreakList": "appointments/break/therapist/{therapistId}/overwrite/{isOverWrite}",
    "DeleteLunchSetup": "lunch/{id}/slot/{lunchTime}",
    "UpdateLunchSetup": "lunch/{id}",
    "GetLunchData": "lunch/{id}",
    "GetServicePriceBasedOnYield": "appointments/yieldprice/service/{serviceId}/date/{appointmentDate}/baseprice/{originalPrice}",
    "GetServicePriceBasedOnPriceTypeYield": "appointments/yieldprice/service/{serviceId}/date/{appointmentDate}",
    "CreatePackageYield": "packages/yield",
    "DeletePackageYield": "packages/{id}/yield",
    "GetAllAppointmentsByStatus": "appointments/date/{date}/status/{status}",
    "GetTherapistAggregate": "therapists/{id}",
    "ReinstateAllAppointment": "appointments/reinstate/all",
    "CancelAllAppointment": "appointments/cancel",
    "GetAllDepositAppointments": "appointments/{id}/deposits/client/{clientIds}/from/{fromDate}/to/{toDate}/linkcode/{linkId}/outlet/{outletId}",
    "GetAllClients": "clients/detail",
    "UpdateAppointmentDeposit": "appointments/deposit/transaction/{transactionId}",
    "GetUserConfiguration": "settings/module/OtherSettings",
    "CancelDepositAppointment": "schedule/appointments/cancel/deposit/transaction/{transactionId}/{canPerformCancel}",
    "CreateRetailTransaction": "transaction/create/{transactionType}",
    "UpdateRetailTransaction": "transaction/update/{id}",
    "SettleRetailTransaction": "transaction/settle/{transactionType}",
    "ReOpenRetailTransaction": "transaction/reopen/{transactionType}",
    "CorrectRetailTransaction": "transaction/correct/voidedTransactionId/{voidedTransactionId}",
    "UpdateRetailTransactionData": "transaction/updatetransaction/{id}",
    "Outlet": "outlet",
    "ActiveOutlet": "outlet/active",
    "ActiveOutlets": "outlets/active",
    "OutletWithId": "outlet/{id}",
    "GetDefaultOutlet": "Outlets/GetDefaultOutlet",
    "GetShopItems": "retailitems",
    "CreateRetailItem": "retailItems",
    "GetShopItem": "retailitemsAggregate/{itemId}",
    "UpdateRetailItem": "retailItems/{id}",
    "ImportRetailItem": "retailItems/ImportRetailItem",
    "SyncRetailItems": "retailitems/sync/outlets/{outletId}",
    "SyncInventory": "retailitems/syncinventory/outlets/{outletId}",
    "PosFullSync": "POSSync/SyncOutletItems/{outletId}",
    "GetOutletShopItems": "retailitems/outlet/{outletId}/{isInActive}",
    "GetRetailItemsByOutletId": "retailitems/outletId/{outletId}",
    "GetAllShopItems": "retailitems/detail",
    "GetActiveRetailItems": "retailitems/active",
    "GetRetailItemByItemId": "retailItems/{itemId}",
    "GetRetailItemByItemType": "retailitems/type/{type}",
    "GetCommissionItems": "retailItems/commissionable/outlet/{outletId}",
    "GenerateItemNumber": "retailItems/generateItemNumber",
    "GetShopItemByName": "retailitems/name/{itemName}",
    "GlobalSearchRetailItems": "retailitems/search/name",
    "GetItemByItemNumber": "RetailItems/itemNumber/{itemnumber}",
    "UpdateRetailItemStatus": "RetailItems/{id}/{status}",
    "QuickSaleCategoryAndItems": "quicksale",
    "QuickSaleCategory": "quicksale/category",
    "AllQuickSale": "quicksale",
    "AddQuickSaleItem": "quicksale/item/{outletId}",
    "QuickSaleActiveCategory": "quicksale/category/active",
    "QuickSaleCategoryWithId": "quicksale/category/{id}",
    "UpdateQuickSaleCategoryListOrder": "quicksale/category/listorder/from/{fromorder}/to/{toorder}",
    "CategoryItemAndListOrderChange": "QuickSale/items/Categoryandlistorder",
    "GetAllTransactions": "transaction/{status}/{outletId}/{transactionDate}",
    "getCommissionableTherapist": "GetCommissionableStaffs/item/{itemId}",
    "GetCommissionableStaffs": "GetCommissionableStaffs/item/{itemId}/productId/{productId}/outlet/{outletId}",
    "GetCommissionbyType": "CommissionSetup/type/{referenceType}/id/{referenceId}/productId/{productId}/outlet/{outletId}",
    "getTherapists": "therapists/list",
    "RemoveCommission": "CommissionSetup",
    "GetGuestSalesHistoryTransaction": "transaction/guest/{id}",
    "GetSalesHistoryTransactionByGuestIds": "transaction/GetSalesHistoryTransactionByGuestIds",
    "GetAllCommissionByItem": "CommissionSetup/items/{itemId}/productId/{productId}/outlet/{outletId}",
    "CommissionTemplate": "commissiontemplates",
    "CommissionTemplateById": "commissiontemplates/{id}",
    "GetCommissionTemplateById": "commissiontemplates/{id}/productId/{productId}",
    "GetCommissionTemplate": "commissiontemplates/productId/{productId}",
    "DeleteCommissionTemplateById": "commissiontemplates/{id}/productId/{productId}",
    "GetTransactionDetails": "transaction/GetTransactionDetails/{transactionId}/productId/{productId}",
    "GetTransactionsForDay": "transaction/outlet/{outletId}/status/{status}/date/{transactionDate}/IncludeVoid/{IncludeVoid}",
    "GetTransactionByRange": "transaction/outlet/{outletId}/status/{status}/range/{startDate}/{endDate}",
    "SearchTransaction": "transaction/search",
    "GetMultiAppointment": "appointments/{id}/multiclient",
    "GetMultiPacks": "multipack/getMultiPacks",
    "GetAllMultiPacks": "multipack/getAllMultiPacks",
    "GetAciveMultipackforClient": "multipack/query/active/list",
    "LinkMultiPack": "appointments/{id}/link/multipack/{multipackId}/price/{multipackPrice}",
    "LinkMultiPacks": "appointments/link/multipack/list",
    "UnlinkMultiPack": "appointments/unlink/multipack",
    "UnlinkMultiPacks": "appointments/unlink/multipack/list",
    "RedeemMultiPack": "multipack/redeemMultiPack",
    "ReinstateMultiPack": "multipack/reinstateMultiPackSession",
    "GetMultiPack": "multipack/getMultiPack/{id}",
    "ReturnWithoutTicket": "transaction/return/withoutticket/{transactionType}",
    "RetunWithticket": "transaction/return/withticket/{transactionId}/productId/{productId}",
    "VoidTransaction": "transaction/void",
    "GetConfiguration": "settings/module/{module}/switch/{Switch}",
    "GetSeasonalSetting": "settings/seasonalhour",
    "UpdateSeasonalSetting": "settings/seasonalhour",
    "GetAllSetting": "settings/AllSetting/All",
    "GetAllSettingByModule": "Settings/module/{module}",
    "GetSettingsByModule": "Settings/{module}",
    "GetSettingByModule": "settings/GetSettings/{module}",
    "GetAllBreakPoint": "user/GetAllUserClaimsAsync",
    "GetBreakPoint": "user/GetUserClaimsAsync/",
    "BlockUserProfile": "user/BlockUserProfile?userId={userId}&accountBlocked={accountBlocked}",
    "GetAllUserRole": "userRole/GetUserRoleByTenantId/{tenantId}",
    "GetActiveUserRole": "UserRole/GetActiveUserRoleByTenantId/{tenantId}/{includeInActive}",
    "GetActiveUserRolesByPropertyId": "UserRole/GetActiveUserRolesByPropertyId/{propertyId}/{includeInActive}",
    "GetUserRoleByPropertyId": "UserRole/GetUserRoleByPropertyId/{propertyId}",
    "UpdateUserRole": "UserRole/UpdateUserRole",
    "CreateUserRole": "UserRole/CreateUserRole",
    "DeleteUserRole": "userRole/DeleteUserRole",
    "CheckUserRoleExist": "userRole/CheckUserRoleExist/{tenantId}/{roleName}",
    "CheckDupicateUserRoleExist": "UserRole/CheckDupicateUserRoleExist/{propertyId}/{roleCode}/{roleName}",
    "GetUserCountsByRoleId": "userRole/GetUserCountsByRoleId/{RoleId} ",
    "GetUserRoleConfiguration": "userRole/GetUserClaimsByRoleId/{userRoleId}",
    "GetProducts": "Product/GetAllProducts",
    "GetStandAloneProducts": "Product/GetAllStandAloneProducts",
    "GetProductsByPropertyId": "Product/GetProductsByPropertyId/{propertyId}",
    "GetOutlets": "Outlets/property/{propertyId}",
    "GetAllUsers": "User/GetAllUserbyTenantId/{tenantId}",
    "GetAllUsersByPropertyId": "User/GetAllUserbyPropertyId/{propertyId}/{productId}",
    "GetAllUsersbyUserIds": "User/GetAllUserbyUserIds/{tenantId}",
    "GetPropLanguages": "Language/GetAllLanguageByPropertyId/{PropertyId}",
    "GetAllLanguages": "Language/GetAllLanguages",
    "GetUserRetailConfiguration": "UserRetailConfiguration/{id}",
    "GetAllUserRetailConfiguration": "UserRetailConfiguration/all",
    "GetUserSpaConfiguration": "spausers/{id}/servicegroup",
    "CreateUser": "user/CreateUser/{PropertyId}",
    "UpdateUser": "user/UpdateUser/{PropertyId}",
    "CreateUserRetailConfig": "UserRetailConfiguration/create",
    "CreateUserSpaConfig": "spausers/{id}/servicegroup",
    "UpdateUserRetailConfig": "UserRetailConfiguration/update",
    "UpdateUserSpaConfig": "spausers/{id}/servicegroup",
    "UpdateSetting": "settings",
    "IsLocationInUse": "appointments/validate/inuse/service/{serviceId}/location/{locationId}",
    "IsServiceInUse": "appointments/validate/inuse/Service/{serviceId}/from/{startDate}/to/{endDate}/dayofWeek/{dayOfWeek}",
    "CopyUserRoles": "user/CopyUserClaimsAsync/{from}/{to}",
    "UpdateUserRoles": "User/UpdateUserClaimsAsync",
    "GetPropertyInfoByPropertyId": "Property/GetPropertyInfoByPropertyId/{Id}",
    "UpdatePropertyInfoByPropertyId": "Property/UpdatePropertyInfo",
    "CheckTherapistAssociatedInAppointment": "appointments/Validate/resource/Therapist/{id}/action/Update",
    "GetTransactionStatus": "transaction/appointment/{id}",
    "GetAppointmentWithDeposit": "appointments/status/{status}/date/{appointmentDate}",
    "GetAppointmentWithoutTransaction": "appointments/notransaction/checkedout/date/{appointmentDate}",
    "PerformDayEnd": "dayend/process/date/{currentDate}/{isAllowFutureDate}",
    "GetManagementData": "Rules/ManagementData",
    "GetServiceEquipmentMaintenance": "Rules/EquipmentMaintenance/service/{serviceId}",
    "CheckOutAppointment": "appointments/checkout",
    "GetReport": "report",
    "clientListingData": "reports/clientListingData",
    "transactionExtract": "reports/TransactionExtract",
    "GetOutletsByProperty": "Outlets",
    "TransferInventoryItem": "Inventory/transfer",
    "AddOutletsToProperty": "Outlets",
    "UpdateOutletsOfProperty": "Outlets/{id}",
    "RemoveOutletsOfProperty": "Outlets/{id}",
    "GetPackageDetailbyId": "packages/{id}/detail",
    "LockAppointment": "appointments/{id}/lock/{sessionId}",
    "ReleaseAppointmentLock": "appointments/lock/{sessionId}/remove",
    "GetAppointmentLock": "appointments/{id}/enablelock/{lockAppointment}/{sessionId}",
    "LockAppointments": "appointments/lock/{userId}",
    "ReleaseAppointmentLocks": "appointments/releaseppointmentlock/{isFromScheduler}",
    "ValidateRule": "appointments/validate/rule/{isLocationView}",
    "GetAppointmentLocks": "appointments/getappointmentlocks",
    "GlobalSearchClients": "clients/searchByKey",
    "GlobalSearchTherapist": "therapists/search/name",
    "GlobalSearchAppointments": "services/search/appointments",
    "SearchClientInformation": "Clients/search/{isVip}/{clientGuid}/{searchType}/{requestUid}?IncludePurgedData={IncludePurgedData}&isExternalSearch={isExternalSearch}&isPlatformGuestSearch={isPlatformGuestSearch}",
    "RecentClientInformation": "Clients/recent/{propertyDate}/search/{searchType}/{requestUid}",
    "IsTherapistServiceInUse": "appointments/validate/inuse/therapist/{therapistId}/Service/{serviceId}",
    "IsTherapistAddonInUse": "appointments/validate/inuse/therapist/{therapistId}/addon/{addonId}",
    "PackageMoveValidations": "AppointmentPackage/packagemove/validation/{id}/packagegroupid/{guid}",
    "IsPackageYieldAvailable": "packages/{packageId}/yieldcheck/date/{date}",
    "UpdatePackageYieldOnMove": "packages/{packageId}/updateyield/appointment/{packageGroupId}/from/{fromDate}/to/{toDate}",
    "GlobalSearchAppointmentsIds": "services/search/appointmentId",
    "WaitList": "appointmentwaitlists",
    "WaitListFilter": "appointmentwaitlists/filter/date/{date}",
    "WaitListWithId": "appointmentwaitlists/{id}",
    "LostDeniedWithId": "lostDeniedBusiness/{id}",
    "GetAllWaitListByDate": "appointmentwaitlists/from/{fromdate}/to/{todate}",
    "GetServicePriceTypes": "Services/ServicePriceType/{serviceid}",
    "AppointmentTherapistServiceLimitation": "appointments/validatetherapistservicelimit/therapistid/{therapistid}/serviceid/{serviceid}/servicecount/{servicecount}",

    "GetMedicalCondition": "services/{id}/getmedicalcondition",
    "GetMedicalWarning": "services/{id}/getmedicalwarning",
    "GetLoctionByServiceId": "Services/locations/{id}",
    "ConfirmWaitList": "appointmentwaitlists/{id}/confirm",
    "GetClientByIds": "Clients/query/{includeRelatedData}",
    "CreateCategoryGroup": "CategoryGroups",
    "DeleteCategoryGroup": "CategoryGroups/{id}",
    "GetAllActiveCategoryGroups": "CategoryGroups/active",
    "GetAllCategoryGroups": "CategoryGroups",
    "UpdateCategoryGroup": "CategoryGroups/{id}",
    "CategoryGroupDragDrop": "CategoryGroups/listorder",
    "GetAllCategories": "Categories",
    "CreateCatSubCatAndLinkAndMeasure": "Categories/AddCatSubCatAndLinkAndMeasure",
    "GetActiveCategories": "Categories/active",
    "CreateCategory": "Categories",
    "UpdateCategory": "Categories/{id}",
    "DeleteCategory": "Categories/{id}",
    "CategoryDragDrop": "Categories/listorder",
    "GetAllSubCategories": "SubCategories",
    "CreateSubCategory": "SubCategories",
    "EditSubCategory": "SubCategories/{id}",
    "DeleteSubCategory": "SubCategories/{id}/{levelId}?forceDelete={forceDelete}",
    "SubCategoryDragDrop": "SubCategories/listorder",
    "LinkCategoryAndSubCategory": "SubCategories/{level}/{parentId}",
    "GetCategoryAndSubCategoryLink": "SubCategories/categorySubCategoryLink",
    "GetActiveUnitOfMeasures": "UnitsOfMeasure/active",
    "GetUnitOfMeasures": "UnitsOfMeasure",
    "CreateUnitOfMeasure": "UnitsOfMeasure",
    "UpdateUnitOfMeasure": "UnitsOfMeasure/{id}",
    "DeleteUnitOfMeasure": "UnitsOfMeasure/{id}",
    "UnitOfMeasureDragDrop": "UnitsOfMeasure/listorder/from/{fromOrder}/to/{toOrder}",
    "GetDiscountTypes": "DiscountTypes",
    "GetActiveDiscountTypes": "DiscountTypes/active",
    "CreateDiscountType": "DiscountTypes",
    "UpdateDiscountType": "DiscountTypes/{id}",
    "DeleteDiscountType": "DiscountTypes/{id}",
    "DiscountTypeDragDrop": "DiscountTypes/listorder/from/{fromOrder}/to/{toOrder}",
    "GetDiscountConfiguration": "DiscountConfigurations",
    "UpdateDiscountConfiguration": "DiscountConfigurations/{id}",
    "ResetDiscountConfiguration": "DiscountConfigurations/{id}",
    "ApplyAndUpdateDiscountConfiguration": "DiscountConfigurations",
    "GetPaymentConfiguration": "GetPaymentConfiguration",
    "PaymentMethod": "GetPaymentMethodsByProductId",
    "PaymentMethodWithId": "paymentmethods/{id}",
    "AddPaymentMethod": "paymentmethods",
    "UpdatePaymentMethodListOrder": "paymentmethods/listorder/from/{fromorder}/to/{toorder}",
    "getAllCCTerminals": "CreditCardTerminals",
    "createCCTerminal": "CreditCardTerminals",
    "updateCCTerminal": "CreditCardTerminals/{id}",
    "deleteCCTerminal": "CreditCardTerminals/{id}",
    "UpdateCCTerminalListOrder": "CreditCardTerminals/listorder/from/{fromorder}/to/{toorder}",
    "getAllOutletTerminals": "OutletTerminal",
    "createOutletTerminal": "OutletTerminal",
    "updateOutletTerminal": "OutletTerminal/{id}",
    "deleteOutletTerminal": "OutletTerminal/{id}",
    "GetOutletItemWithStatus": "Inventory/outlet/{outletId}/items",
    "StartNewInventoryCount": "Inventory/{outletId}/counts",
    "UpdateInventoryCountType": "Inventory/counts/{countId}/type",
    "UpdateInventoryCount": "Inventory/counts/{countId}",
    "UpdateInventoryStatus": "Inventory/{outletId}/status",
    "GetTaxes": "TaxConfiguration/grid",
    "CreateTax": "TaxConfiguration",
    "UpdateTax": "TaxConfiguration/{id}",
    "DeleteTax": "TaxConfiguration/{id}",
    "OutletGet": "GetOutlets/{propertyId}",
    "OutletCreate": "CreateOutlet",
    "OutletDelete": "DeleteOutlet/{propertyId}/{subPropertyId}/{outletTerminalLinkId}",
    "OutletUpdate": "UpdateOutlets/{outletTerminalLinkId}",
    "GetActiveTaxes": "TaxConfiguration/active",
    "CreateTicket": "transaction/create",
    "ModifyServiceCharge": "transaction/modifyServiceCharge/outletId/{outletId}/terminalId/{terminalId}",
    "ModifyGratuity": "transaction/modifyGratuity/outletId/{outletId}/terminalId/{terminalId}",
    "ModifyServiceChargeAndGratuity": "transaction/modifyServiceChargeAndGratuity/outletId/{outletId}/terminalId/{terminalId}",
    "ModifyItemQuantity": "transaction/modifyItemQuantity",
    "CancelTicket": "transaction/cancelTicket/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "RemoveItems": "transaction/removeItems/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "getUserOutlet": "outlet/user/{userid}",
    "TransactionDetailWithPostedCommission": "transaction/{transactionId}/commissions",
    "GetCCConfiguration": "GatewayConfiguration/{type}",
    "UpdateCCConfiguration": "GatewayConfiguration",
    "GetGCConfiguration": "GiftCardConfiguration",
    "UpdateGCConfiguration": "GiftCardConfiguration",
    "getAllPropertySettings": "Property/GetPropertySetting/{propertyId}",
    "updatePropertySetting": "Property/PatchUpdatePropertySetting/{PropertyId}",
    "getReceiptInfo": "ReceiptConfiguration",
    "createReceipt": "ReceiptConfiguration",
    "GetReceiptConfigurationByOutletId": "ReceiptConfiguration/{id}",
    "GetAllRetailItemAndOutletInfo": "retailItems/AllRetailItemInfo",
    "GetRetailItemDetails": "retailItems/query",
    "getCommissionForItem": "commissions/getCommissionForItem/item/{itemId}/staff/{staffId}/{staffType}/{commissionClass}/product/{productId}/outlet/{outletId}",
    "AddOrModifyPostedCommission": "commissions/modifypostedcommission/{isCreate}",
    "GetSubPropertyAccessByUser": "UserOutlets/user/{userId}",
    "GetOutletsAccessByPropertyId": "UserOutlets/property",
    "CreateUserOutletAccess": "UserOutlets",
    "UpdateUserOutletAccess": "UserOutlets",
    "LinkTransactionId": "transaction/link/transactionId/{transactionId}/transactionLinkId/{transactionLinkId}",
    "LinkOutletsWithRetailItem": "LinkOutlet",
    "getAllMultiPacks": "MultiPack/GetAllMultiPacks",
    "ApplyDiscount": "transaction/applyDiscount/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "UpdateCategoryLinking": "retailitems/UpdateCategoryLinking",
    "GetTicket": "transaction/getTicket/{ticketId}/outletId/{outletId}/terminalId/{terminalId}",
    "GetHandles": "GetHandles",
    "CreateToken": "CreateToken",
    "StoreToken": "Payment/StoreToken",
    "GetCardInfoByTransaction": "Payment/GetCardInfoByTransaction/{tokenTransId}",
    "Sale": "sale",
    "SaleByToken": "Payment/SaleByToken/outletId/{outletId}",
    "CreditByToken": "Payment/CreditByToken/outletId/{outletId}",
    "ValidateSale": "Payment/ValidateSale/outletId/{outletId}",
    "ValidatePay": "Payment/ValidatePay/outletId/{outletId}",
    "TryPay": "transaction/trypay/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "TryPayForRefund": "transaction/trypayForRefund/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "CloseTransaction": "transaction/close/{transactionId}/transactionDate/{transactionDate}/outletId/{outletId}/productId/{productId}",
    "DeleteTransaction": "transaction/delete/{transactionId}",
    "GetTransactionCount": "transaction/outlets/{outletId}/count",
    "UpdateInventoryAuditOnDayEnd": "Inv/property/{propertyId}/dayend/audit",
    "TenderRetainedData": "transaction/{transactionId}/tenderRetainedData/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "OldRefund": "transaction/{transactionId}/refund",
    "PaymentHistory": "transaction/{transactionId}/payment/history?getAll={getAll}",
    "PaymentHistoryDetails": "PointOfSaleService/GetPaymentHistoryDetails/{transactionId}/payment/history",
    "GetUserSessionConfiguration": "UserSessionConfiguration/user/{userId}",
    "CreateUserSessionConfiguration": "UserSessionConfiguration/create",
    "UpdateUserSessionConfiguration": "UserSessionConfiguration/update",
    "SaveTicket": "transaction/save/{ticketNumber}/checkHandle/{checkHandleGuid}/outletId/{outletId}/terminalId/{terminalId}",
    "GetAllRoomsWithGuestID": "Itinerary/clients/{clientId}/stay",
    "AddTicketTax": "transaction/AddTicketTax/ticket/{ticketNumber}/checkHandle/{checkHandleGuid}/outletId/{outletId}/terminalId/{terminalId}",
    "GetPaymentConfigurationByProperty": "Payment/GetPaymentConfigurationByProperty/{propertyId}",
    "GetSupportedPMAgentVersionByProperty": "Payment/GetPMAgentVersion/{propertyId}",
    "SyncItemAndTax": "Inventory/Syncup/outlets/{outletId}/type/{type}/operation/{operation}/id/{id}",
    "CloseReturnTransaction": "transaction/closeReturnTransaction/{transactionId}/transactionDate/{transactionDate}/productId/{productId}",
    "GetTransactionInfoById": "transaction/transactionInfoById/{transactionId}",
    "GetTransactionDetailsForReceipt": "transaction/GetTransactionDetailsForReceipt/{transactionId}",
    "Pay": "Transaction/pay/outletId/{outletId}/terminalId/{terminalId}",
    "Refund": "transaction/{transactionId}/refund/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/tenderId/{tenderId}/paymentReferenceId/{paymentReferenceId}/terminalId/{terminalId}",
    "GetLinkedTransactions": "transaction/{transactionId}/outlet/{outletId}/linkedTransactions",
    "CheckVoidForMultipack": "transaction/checkVoidForMultiPack/transactionId/{transactionId}",
    "IsTherapistServicesInUse": "appointments/validate/inuse/therapist/{therapistId}",
    "IsTherapistAddOnInUse": "schedule/appointments/validate/inuse/addons/therapist/{therapistId}",
    "GetFinancialBins": "transaction/getFinancialBins/parentTransactionId/{parentTransactionId}",
    "SyncUpTransactions": "transaction/SyncTransactions/outletId/{outletId}/productId/{productId}",
    "CancelTransaction": "transaction/cancel/{transactionId}",
    "CheckTicketStatus": "transaction/{transactionId}/CheckTicketStatus",
    "GetAppointmentByTransaction": "appointments/transaction/{transactionId}",
    "GetAppointmentDetailsByTransactionIds": "appointments/GetAppointmentDetailsbyTransactionIds",
    "UpdateAppointmentTransactionId": "schedule/appointments/update/transactionAndStatus",
    "RevertDeposit": "appointments/Revertdeposit/TransactionId/{transactionId}",
    "ReceiptDetailsByTransactionId": "appointments/Receipt/{transactionId}",
    "CreateTransactionLock": "transaction/lock/{transactionId}/overrideExistingLock/{overrideExisting}",
    "GetTransactionLock": "transaction/lock/{transactionId}",
    "RemoveTransactionLock": "transaction/lock/{lockId}",
    "OutletTransLog": "TransLog",
    "GetVatConfiguration": "Property/GetVatConfiguration/{propertyId}",
    "UpdateVatConfiguration": "Property/UpdateVatConfiguration",
    "GetRetailItemById": "RetailItems/{itemId}",
    "AddAndRemoveTicketItems": "Transaction/addAndRemoveItems/outletId/{outletId}/terminalId/{terminalId}",
    "GetTicketDetail": "transaction/getTicketDetail/outlet/{outletId}/terminalId/{terminalId}",
    "GetStoreTerminal": "Outlets/GetStoreTerminal/{outletId}",
    "SearchMembers": "Membership/SearchGuests/{pattern}/{searchType}?isSearchNameOnly={isSearchNameOnly}",
    "GetMemberInfoByCardNo": "Membership/GetMemberByCardNo/{cardNo}/{scheduleDateTime}?corpId={corpId}",
    "GetAllPaymentRecords": "Membership/GetAllPaymentRecords",
    "GetPaymentRecordsbyTransactionId": "Membership/GetPaymentRecord/{TransactionId}",
    "CreateACESPaymentRecord": "Membership/CreatePaymentRecord",
    "ARPost": "Membership/ARPost",
    "RedeemPoint": "Membership/RedeemPoint",
    "MemberSummary": "Membership/CreateACESPaymentSummary",
    "ACESPaymentRecords": "Membership/GetACESPaymentsRecord",
    "GetMember": "transaction/GetPaymentById/{transactionId}",
    "MemberPayment": "Membership/MemberPayment",
    "GetOutletsByPropertyAndProduct": "Outlets/GetOutlets/property/{propertyId}/product/{productId}",
    "GetTherapistAppointments": "appointments/therapist/{therapistId}/from/{FromDate}/to/{ToDate}",
    "GetAppointmentByTherapistId": "appointments/therapistAppointments/{therapistId}/from/{FromDate}/to/{ToDate}",
    "DeleteTherapistSchedule": "therapistschedule/therapist/{therapistId}",
    "GetPropertyFeatures": "PropertyFeature/GetPropertyFeatures/property/{propertyId}/product/{productId}",
    "UpdatePropertyFeature": "PropertyFeature/UpdatePropertyFeature/Id/{id}/updatedvalue/{value}",
    "GetPropertyFeatureConfiguration": "FeaturesConfiguration/GetFeaturesConfigurationsForDefaultValues/FeaturesConfigurationWithDefaultValues/{PropertyFeatureId}",
    "GetRetailPropertyFeatureConfiguration": "RetailFeatureConfiguration/GetFeaturesConfigurationsForDefaultValues/{PropertyFeatureId}",
    "SaveFeatureConfiguration": "FeaturesConfiguration/SaveFeaturesConfigurations",
    "SaveRetailFeatureConfiguration": "RetailFeatureConfiguration/SaveFeaturesConfigurations",
    "GetOutletTerminals": "Outlets/GetStoreTerminals",
    "CreateOutletTerminal": "Outlets/CreateStoreTerminal",
    "UpdateOutletTerminal": "Outlets/UpdateStoreTerminal",
    "DeleteOutletTerminal": "Outlets/DeleteStoreTerminal/{id}",
    "MoveTherapistBreak": "Appointments/{appointmentId}/move/therapistbreak/{therapistId}",
    "GetTransactionPayment": "Transaction/getTransactionPayment/{transactionId}",
    "GetTransactionPaymentWithAdditionalDetails": "Transaction/getTransactionPaymentWithAdditionalDetails/{transactionId}",
    "UpdateSuccessfulPayment": "Transaction/{transactionId}/UpdateSuccessfulPayment",
    "VoidCMSPayment": "Transaction/{transactionId}/VoidCMSPayments/patronId/{patronId}/redemptionType/{redemptionType}/paymentMethodType/{paymentMethodType}",
    "CheckIsTerminalInUse": "Transaction/IsOpenTransactionExistForTerminal/Outlet/{outletId}/Terminal/{terminalId}",
    "GetFeatureConfiguration": "GetFeatureConfiguration/propertyId/{propertyId}/productId/{productId}",
    "GetFeatureConfigurationById": "GetFeatureConfigurationById/propertyId/{propertyId}/productId/{productId}",
    "GetRetailFeatureConfiguration": "GetRetailFeatureConfiguration/propertyId/{propertyId}/productId/{productId}",
    "GetClientByPatronId": "Clients/getClientByPatronId/{patronId}",
    "GetPatronInformation": "Payment/GetPlayerInformation",
    "RedeemVoucher": "Payment/RedeemVoucher/{patronId}",
    "GetAccountInformation": "Payment/GetAccountInformation",
    "RedeemPoints": "Payment/RedeemPoints",
    "GetTopicsByType": "Notifications/GetTopicsByNotificationType/{NotificationTypeId}",
    "GetTemplateDetailsByTopicId": "Notifications/GetTemplateDetailsByTopicId/{TopicId}",
    "CreateorUpdateTemplate": "Notifications/CreateorUpdateTemplate",
    "UpdateDefaultTemplate": "Notifications/UpdateDefaultTemplate",
    "SendManualNotification": "emailNotification/SendManualNotification",
    "GetGiftcardItemsForLoad": "Transaction/getGiftCardTransactionItemsForLoad/{transactionId}",
    "GetGiftcardItemsForIssue": "Transaction/getGiftCardTransactionItemsForIssue/{transactionId}",
    "UpdateGiftCardLoadSuccess": "Transaction/updateGiftCardLoadSuccess/giftcardTransactionItemId/{giftcardTransactionItemId}/paymentReferenceId/{paymentReferenceId}",
    "ValidateGiftCard": "Payment/ValidateGiftCard/outletId/{outletId}",
    "GetTransactions": "Transaction/GetTransactionsByIds",
    "GetTransactionSummary": "PointOfSaleService/Transaction/GetTransactionSummaryByIds",
    "GetGiftCardPaymentRecords": "GiftCardPaymentRecord/cardnumber/{giftcardNumber}",
    "GetPaymentTicketDetail": "Transaction/getpaymentTicketdetail",
    "GetLatestPaymentRecordDetailByCardNumber": "GiftCardPaymentRecord/{cardNumber}/tenderId/{tenderId}",
    "GetGiftCardItemsForUnLoad": "Transaction/getGiftCardTransactionItemsForUnLoad/{transactionId}",
    "UpdateGiftCardUnLoadSuccess": "Transaction/updateGiftCardUnLoadSuccess/giftcardTransactionItemId/{giftcardTransactionItemId}/unloadPaymentReferenceId/{paymentReferenceId}",
    "UpdateGiftCardIssueSuccess": "Transaction/updateGiftCardIssueSuccess/giftcardTransactionItemId/{giftcardTransactionItemId}/paymentReferenceId/{paymentReferenceId}",
    "GetGiftcardItems": "Transaction/getGiftCardTransactionItems/{transactionId}",
    "ValidateGiftCardCashback": "Payment/ValidateGiftCardCashback/outletId/{outletId}",
    "CreateHelpSession": "Login/?product={product}",
    "RemoveHelpSession": "Logout/?product={product}",
    "GetClosedTransactionByDate": "Transaction/closeddate/{closedDate}",
    "GetARPostingConfiguration": "Payment/ARPostingConfiguration",
    "ARAccountLookup": "AccountsReceivable/Lookup/ByName/{byname}",
    "ARPaymentPost": "AccountsReceivable/PostPayment",
    "ARPaymentCredit": "AccountsReceivable/CreditPayment",
    "GetVendors": "Vendor",
    "GetVendorInfo": "RetailManagementService/Vendor/GetVendorDetails",
    "CreateVendor": "Vendor",
    "UpdateVendor": "Vendor",
    "DeleteVendor": "Vendor/{id}",
    "SearchVendors": "Vendor/search/{pattern}",
    "GetVendorCode": "Vendor/{id}",
    "GetVendorTypes": "VendorType",
    "CreateVendorType": "VendorType",
    "UpdateVendorType": "VendorType",
    "DeleteVendorType": "VendorType/{id}",
    "GetPropertyConfiguration": "PropertyConfiguration/configurationName/{configName}/propertyId/{propertyId}/productId/{productId}",
    "GetADB2CAuthConfig": "Adb2CAuthentication/GetADB2CAuthConfigByProductId/{tenantId}/{productId}",
    "ADB2CLogin": "Adb2CAuthentication/GetADB2CLoginDetails",
    "GetTenantConfiguration": "TenantConfiguration/configurationName/{configurationName}",
    "GetItemByPagination": "RetailItems/items?outletId={outletId}&includeInactive={includeInactive}&pageStart={pageStart}&pageLength={pageLength}&sortBy={sortBy}&isSortByAscending={isSortByAscending}&searchText={searchText}&category={category}&subcategory1={subcategory1}&subcategory2={subcategory2}&subcategory3={subcategory3}&subcategory4={subcategory4}&subcategory5={subcategory5}&vendorId={vendorId}",
    "GetShopItemByPagination": "RetailItems/shop/items?outletId={outletId}&includeInactive={includeInactive}&pageStart={pageStart}&pageLength={pageLength}&sortBy={sortBy}&isSortByAscending={isSortByAscending}&searchText={searchText}&searchByBarcode={searchByBarcode}&quickSaleCategory={quickSaleCategory}&itemId={itemId}",
    "RechargeV1Giftcard": "Payment/RechargeV1GiftCard",
    "ReverseRechargeV1GiftCard": "Payment/ReverseRechargeV1GiftCard/transactionId/{paymentTransactionId}",
    "IssueV1GiftCard": "Payment/IssueV1GiftCard",
    "ReturnV1GiftCard": "Payment/ReturnV1GiftCard",
    "RedeemV1GiftCard": "Payment/RedeemV1GiftCard",
    "GetCommissionSetupByClassId": "CommissionSetup/class/{classId}/productId/{productId}",
    "GetAllCommissionItemsByOutlet": "retailItems/commissionable",
    "GetCommissionByItem": "CommissionSetup/items/{itemId}/productId/{productId}",
    "SearchRetailItemsByType": "retailitems/search",
    "GetInventoryOutletStatus": "Inventory/outlet/{outletId}/status",
    "GetRetailInventoryItemByPagination": "RetailItems/retail-inventory/items?outletId={outletId}&includeInactive={includeInactive}&pageStart={pageStart}&pageLength={pageLength}&sortBy={sortBy}&isSortByAscending={isSortByAscending}&searchText={searchText}&category={category}&subcategory1={subcategory1}&subcategory2={subcategory2}&subcategory3={subcategory3}&subcategory4={subcategory4}&subcategory5={subcategory5}&useInventory=true",
    "GetCommissionSetup": "retailitems/getcommissionsetup",
    "UpdateCommissionSetupByFilter": "CommissionSetup/updatecommissionsetupbyfilter",
    "UpdateRetaCommissionSetupByFilter": "retailitems/updatecommissionsetupbyfilter",
    "IsBarcodeExists": "retailitems/validate/barcode/{barcode}/{itemId}",
    "GetRetailItemDetailedInfo": "retailitems/detailed-info?id={id}",
    "GetRetailItemDetailedInfoList": "retailitems/detailed-infolist",
    "GetRetailItemDetailsByIds": "RetailItems/query",
    "GetCEDSByOutletId": "Outlets/GetCEDSByOutletId/{outletId}",
    "GetActivity": "Itinerary/GetActivity/{activityId}",
    "GetPackageEntitlementData": "GetDataForPackageEntitlement/{activityId}",
    "GetTransactionCountByPaymentMethod": "Transaction/paymentmethod/{paymentmethodId}/count",
    "GetAllPostTypes": "RevenuePosting/GetPostTypes",
    "CreateRevenuePostMapping": "RevenuePosting/AddPostTypeMappingDetails",
    "GetRevenuePostings": "RevenuePosting/GetPostTypeMappingDetails",
    "GetMemberBucketMappingsDetails": "RetailManagementService/MemberBucket/GetMemberBucketMappingsDetails",
    "CreateMemberBucketMapping": "RetailManagementService/MemberBucket/AddMemberBucketMappingDetails",
    "DeleteMemberBucketMapping": "RetailManagementService/MemberBucket/RemoveMemberBucketMappingDetails",
    "DeleteRevenuePostMapping": "RevenuePosting/RemovePostTypeMapping",
    "SyncPosting": "RevenuePosting/Sync",
    "NotifyDayEnd": "RevenuePosting/NotifyDayEnd",
    "WebProxyHealthCheck": "Transaction/WebCommunicationProxy/HealthCheck",
    "GetLocationAvailability": "appointments/filter/service/{serviceId}/date/{bookingDate}",
    "GetTherapistAvailability": "appointments/therapistavailability/service/{serviceId}/date/{bookingDate}/packagebook/{isPackageBook}/appointmentid/{appointmentId}/tempholdlist/{tempIds}",
    "GetTherapistServiceCount": "appointments/therapistservicecount/service/{serviceId}/date/{bookingDate}",
    "GetActivityByBookData": "Itinerary/GetActivityByBookData",
    "GetSpaClientsByGuid": "Clients/getSpaClient",
    "GetServiceById": "services/detail/{id}",
    "GetServiceDetailsByIds": "services/serviceDetailList",
    "UpdateAppointmentAction": "spawizard/appointmentaction",
    "Changesetupbreakdown": "appointments/change/setupbreakdown",
    "searchGroupAppointment": "appointments/groupAppointment",
    "GetGroupAppointmentById": "spawizard/groupappointment/{id}",
    "CreateGroupTempAppointment": "spawizard/CreateGroupTempAppointment/{guid}",
    "CreateAutoBookGroupTempAppointment": "spawizard/autobook",
    "QuickBook": "appointments/quickBook",
    "SheduleGroupAppointment": "spawizard/SheduleGroupAppointment/{guid}",
    "SheduleGroupDetail": "spawizard/SheduleGroupDetail",
    "UpdateGroupId": "spawizard/update/groupid/{groupId}",
    "AppointmentbyClientsandStatus": "Appointments/clientIds/{clientIds}/status/{status}/date/{date}",
    "GetFullGroupAppointment": "spawizard/GetFullGroupAppointment",
    "TempLife": "spawizard/TempLife",
    "ReversePayment": "transaction/{transactionId}/reversepayment/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/tenderId/{tenderId}/paymentReferenceId/{paymentReferenceId}/terminalId/{terminalId}",
    "CancelAllPayments": "transaction/{transactionId}/cancelpayments/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "UndoCheckinMulti": "appointments/undocheckin",
    "GetAllCreditCardIssuerType": "Payment/GetAllCreditCardIssuerType",
    "CreateTempForCopyMove": "appointments/CreateTempForCopyMove/{isLocationView}",
    "GetExceptionByDate": "elasticsearch/getexceptionbydate/{fromdate}/{toDate}",
    "GetStackTraceForTraceId": "elasticsearch/getstacktracefortraceid/{traceId}",
    "GetUserAccessTherapistByAvailability": "Appointments/GetUserAccessTherapistByAvailability/{date}",
    "GetMiscConfiguration": "settings/AllSetting/Misc",
    "GetAggregateTransactionByIds": "Transaction/GetTransactionAggregateByIds",
    "GetPaymentTransaction": "Payment/GetPaymentTransaction",
    "GetAppointmentsByFilter": "appointments/appointmentsbyfilter",
    "RefundAllPayments": "Transaction/{transactionId}/returnpayments/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "RefundPartialPayments": "Transaction/{transactionId}/returnpartialpayments/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}/amountToRefund/{amountToRefund}",
    "GetTopExceptionsByUsers": "elasticsearch/GetTopExceptionsByUsers/{startDate}/{endDate}",
    "GetTopFrequentExceptions": "elasticsearch/GetTopFrequentExceptions/{startDate}/{endDate}",
    "GetTransactionsByDate": "elasticsearch/GetTransactionsByDate/{startDate}/{endDate}/{recordStart}",
    "GetTopTransactionsByUsers": "elasticsearch/GetTopTransactionsByUser/{startDate}/{endDate}",
    "GetTopFrequentTransactions": "elasticsearch/GetTopFrequentTransactions/{startDate}/{endDate}",
    "GetTransactionsByPerformance": "elasticsearch/GetTransactionsByPerformance/{startDate}/{endDate}/{seconds}",
    "GetAllTransactionsForUser": "elasticsearch/GetAllTransactionsForUser/{startDate}/{endDate}/{userName}",
    "RetailFunctionalitiesByProduct": "RetailManagementService/RetailFunctionalities/product/{productId}",
    "GetGuestItinerary": "Activity/GetActivitiesByGuestAndDate",
    "GenderOverrideTransLog": "TransLog/GenderPreference",
    "GetTaxesV2": "RetailManagementService/Tax",
    "GetPaymentSettingByModuleAndScreen": "Setting/{module}/{screen}",
    "PaymentSetting": "Setting",
    "GetDiscountReason": "RetailManagementService/DiscountReason",
    "CreateDiscountReason": "RetailManagementService/DiscountReason",
    "UpdateDiscountReason": "RetailManagementService/DiscountReason",
    "DeleteDiscountReason": "RetailManagementService/DiscountReason/{id}",
    "SearchInHouseGuests": "clients/SearchInHouseGuests",
    "UpdateCardDetailToClientProfile": "clients/updatecarddetail",
    "GetTransLogSearch": "TransLog/search",
    "GetAppointmentTranslog": "Translog/appointmentIds",
    "CategoryOutletListOrderChange": "QuickSale/category/listorder/outlet/{outletId}/from/{fromOrder}/to/{toOrder}",
    "ItemListOrderChange": "QuickSale/items/listorder/outlet/{outletId}/category/{categoryId}/from/{fromOrder}/to/{toOrder}",
    "GetClientBlockById": "clients/clientblock/{id}",
    "GetClientblockdetails": "clients/clientblockdetails",
    "GetGuestInformation": "CombineGuest/search",
    "GetDedupeGuestInformation":"CombineGuest/DuplicateGuestGroups",
    "CombineGuestInformation": "CombineGuest/MergeRecords/{primaryGuestId}",
    "UpdateGuestInformation": "CombineGuest/{id}",
    "GetGuestInfoByGuid": "CombineGuest/{id}",
    "GetMachineNamesByPropertyId": "MachineName/property/{propertyId}",
    "GetMiscConfigurationByPropertyId": "settings/AllSetting/{propertyId}/Misc",
    "GetMachineNamesConfigurationInfo": "MachineName/GetMachineNames/ConfigInfo/{productId}/{userId}",
    "getCCTerminalNextListOrder": "CreditCardTerminals/NextListOrder",
    "appointmentByIds": "appointments/appointmentIds",
    "GetMachineNames": "MachineName",
    "GetActiveMachineNames": "MachineName/active",
    "CreateMachineName": "MachineName",
    "UpdateMachineName": "MachineName/{id}",
    "DeleteMachineName": "MachineName/{id}",
    "MachineNameDragDrop": "MachineName/listorder/from/{fromOrder}/to/{toOrder}",
    "RoomLookupWithRoomKeyData": "Payment/GetRoomDetailsFromRoomkeyData",
    "GetAllTransactionsForScreen": "elasticsearch/GetAllTransactionsForScreen",
    "ImportItem": "RetailItems/ImportItems/{isAuthorised}",
    "ValidateImport": "RetailItems/ValidateImport",
    "ImportStatus": "RetailItems/ImportStatus",
    "EmptyFilterSearch": "CombineGuest/search/{filterName}",
    "SendRetailNotification": "emailNotification/RetailReceiptNotification",
    "GetClientMultiPacksByTransactionId": "MultiPack/GetMultiPacksByTransactionId/TransactionId/{transactionId}",
    "UpdateMultiPacks": "MultiPack/UpdateMultiPack",
    "SaveIntegrationsLog": "Payment/PostIntegrationsLog",
    "UpdateIntegrationsLog": "Payment/UpdateIntegrationsLog",
    "UpdateAppoinmentMultipackId": "Appointments/UpdateMultipackId",
    "appointmentAddons": "Appointments/addonByAppointmentIds",
    "CreateSettlementHistory": "Transaction/CreateSettlementHistory",
    "GetSettlementHistory": "Transaction/GetSettlementHistoryList/outlet/{outletId}/{transactionDate}/{status}",
    "TryCloseTransaction": "Checks/TryCloseTransaction/{transactionId}",
    "ValidateCloseTransaction": "Checks/ValidateCloseTransaction/{transactionId}",
    "CreateTransLog": "CreateTransLog/transaction/{transactionId}/logtype/{logType}",
    "EquipmentQtyDetails": "services/getServiceEquipmentQty",
    "GetAppointmentsBookedDetails": "Appointments/appointmentsBooked",
    "GetAppointmentsByClient":"schedule/Appointments/filter/client",
    "GetFailedTransactions": "PaymentTransaction/GetFailedTransactions",
    "BatchProcessFailedTransactions": "PaymentTransaction/BatchProcessFailedTransactions/{processAll}",
    "GetFailureDetails": "PaymentTransaction/GetFailureDetails",
    "GetActiveBatchStatus": "PaymentTransaction/GetActiveBatchProcess",
    "GetFailureRevenuePosting": "POS/RevenuePosting",
    "RevenuePost": "POS/RevenuePosting/Post",
    "GetRevenuePostingLogs": "POS/RevenuePosting/GetRevenuePostingLogs",
    "IsBatchProcessingPending": "POS/RevenuePosting/IsBatchProcessingPending",
    "GetSalesHistoryTransactionByGuestGuids": "transaction/GetSalesHistoryTransactionByGuestGuids",
    "MachineAssociatedWithTransaction": "Transaction/IsMachineNameAssociatedWithRetailTransaction/{machineNameId}",
    "GetTemplateById": "ReportTemplate/{templateId}",
    "ReportTemplate": "ReportTemplate",
    "GetMaxListOrder": "ReportTemplate/maxListOrder",
    "DeleteTemplate": "ReportTemplate/{id}",
    "UpdateTemplate": "ReportTemplate/{id}",
    "UpdateListOrder": "ReportTemplate/listorder/{fromOrder}/{toOrder}",
    "DefaultTemplateExist": "ReportTemplate/defaultTemplateExists/id/{id}",
    "GetAllEvents": "ReportTemplate/getEvents",
    "GetTemplateReport": "Report/templatereport",
    "GetDefaultTemplateInfo": "ReportTemplate/type/{type}",
    "GetTemplateKeyWords": "ReportTemplate/keyWords",
    "GetRoomRevenuePostingLogs": "POS/RevenuePosting/GetRoomRevenuePostingLogs",
    "GetMemberToken": "Membership/GetAcesToken",
    "AcesToken": "Login/Token/Aces",
    "LoginEncrypted": "Property/GetEncLoginDetails",
    "GetEncryptKey": "Login/encValue",
    "CheckPasswordPut": "User/CheckPasswordExists",
    "VerifyPasswordPut": "User/VerifyPassword",
    "GetMultiPackInfo": "POS/MultiPack/GetMultipleItemMultipacksByTransactionDetailIds",
    "SavePasswordPost": "User/SavePassword",
    "StayClientSearch": "clients/SearchHotelReservationGuests",
    "DepositClientSearch": "Appointments/depositClientSearch/from/{fromDate}/to/{toDate}/searchKey/{searchKey}/linkcode/{linkId}",
    "GetMultiPackRedeemHistoryDetails": "MultiPack/GetMultiPackRedeemHistoryDetails",
    "MembershipRefundPayment": "Transaction/{transactionId}/membershiprefund/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/tenderId/{tenderId}/terminalId/{terminalId}",
    "GetAllLostDeniedReasons": "LostDeniedReasons/active/{IncludeInactive}",
    "DeleteLostDeniedReasons": "LostDeniedReasons/{id}",
    "UpdateLostDeniedReasons": "LostDeniedReasons/{id}",
    "GetLostDeniedReasons": "LostDeniedReasons/{id}",
    "CreateLostDeniedReasons": "LostDeniedReasons",
    "GetLostDeniedReasonBusiness": "LostDeniedBusiness/from/{fromDate}/to/{toDate}",
    "LostDeniedBusiness": "LostDeniedBusiness",
    "LostDeniedBusinessUpdate": "LostDeniedBusiness/{id}",
    "GetDataForEformManualNotify": "GetDataForEForm/service/{serviceId}/{productId}",
    "GetADB2CEnableConfig": "Adb2CAuthentication/GetADB2CEnableConfig/{tenantId}",
    "GetAppointmentByGuestID": "appointments/{guestId}/{startDate}/{endDate}",
    "GetDataMagineConfiguration": "DataMagine/GetDmConfig",
    "CreateDataMagineConfiguration": "DataMagine/CreateDmConfig",
    "UpdateDataMagineConfiguration": "DataMagine/UpdateDmConfig",
    "copyTherapistSchedule": "TherapistSchedule/CopyTherapistSchedule",
    "InsertEFormNotification": "DataMagine/CreateEformNotification",
    "UpdateEformNotification": "DataMagine/UpdateEformNotification",
    "CreateLogType": "LogType",
    "UpdateLogType": "LogType/{id}",
    "GetAllLogType": "LogType/active/{isIncludeInactive}",
    "GetLogType": "LogType/{id}",
    "DeleteLogType": "LogType/{id}",
    "GetAllRelationships": "Relationship/active/{isIncludeInactive}",
    "GetRelationship": "Relationship/{id}",
    "CreateRelationship": "Relationship",
    "UpdateRelationship": "Relationship/{id}",
    "DeleteRelationship": "Relationship/{id}",
    "RelationshipDragDrop" : "Relationship/listorder",
    //VipTypeRoutes
    "GetAllVipType": "VipType/active/{isIncludeInactive}",
    "GetVipType": "VipType/{id}",
    "CreateVipType": "VipType",
    "UpdateVipType": "VipType/{id}",
    "DeleteVipType": "VipType/{id}",
    "VipTypeDrag" : "VipType/listorder/{fromOrder}/{toOrder}/{includeInactive}",
    "VipTypeNextListOrder" : "VipType/nextlistorder",
    "NightAudit": "NightAudit",
    "RemoveNightAudit": "NightAudit/DeleteNightAuditConfig/{configId}",
    "NightAuditSchedule": "NightAuditSchedule",
    "RemoveNightAuditSchedule": "NightAuditSchedule/DeleteNightAuditSchedule/{scheduleId}",
    "GetDataRetentionPolicyConfiguredFlag": "Policy/GetDataRetentionPolicyConfiguredFlag/{tenantId}",
    "GetDataGroupsUsingPolicyId": "Policy/GetDataGroupsUsingPolicyId/{tenantId}/{policyId}",
    "GetConsentPoliciesForProduct": "Policy/GetConsentPoliciesForProduct/{tenantId}/{productId}",
    "UpdateConsentPolicyDetailsForGuestId": "GuestPolicy/UpdateConsentPolicyDetailsForGuestId",
    "ApplyDataPolicyPurgeForGuestId": "GuestPolicy/ApplyDataPolicyPurgeForGuestId",
    "GetPolicyTypeUsingPolicyId": "Policy/GetPolicyTypeUsingPolicyId/{policyId}",
    "GetTherapistServicePointsByDate": "Appointments/GetTherapistServicePointsByDate/{currentDate}",
    "GetUnassignedTherapistAppointmentByDate": "Appointments/GetUnassignedTherapistAppointmentByDate/{currentDate}/{therapistId}",
    "ExtendTempHold": "appointments/extendTemphold?holdMinutes={holdMinutes}",
    "ReleaseTempHold": "appointments/releaseTempHold",
    "GetAllFailedGuestProfile": "Management/GuestPolicy/GetAllFailedGuestProfile",
    "ProfileSyncManaulTrigger": "Management/GuestPolicy/ProfileSyncManualTrigger",
  
    "GetAllPostType": "FolioService/PostTypes?includeInactive={includeInactive}",
    "GetAllPaymentMethod": "FolioService/PaymentMethods?includeInactive={includeInactive}",
    "GetPaymentMethodWithReferences": "FolioService/PaymentMethods/PaymentReferences?includeInactive={includeInactive}",
    "GetPaymentMethod": "FolioService/PaymentMethods/{id}",
    "DeletePaymentMethod": "FolioService/PaymentMethods/{id}",
    "CreatePaymentMethod": "FolioService/PaymentMethods",
    "UpdatePaymentMethod": "FolioService/PaymentMethods",
    "GetNextListOrder_PaymentMethod": "FolioService/PaymentMethods/NextListOrder",
    "GetPaymentWithRefernceById": "FolioService/PaymentMethods/GetPaymentWithRefernceById/{id}",
    "UpdatePaymentOnGridAction": "FolioService/PaymentMethods/UpdateOndDragnDrop",
    "GetWebCommunicationProxyVersion": "Payment/GetWebCommunicationProxyVersion",
    "GetAllCreditCardTerminal": "CreditCardTerminals/includeInactive/{includeInactive}",
    "CreateUserTherapist":"SpaUsers/CreateUserTherapist",
    "GetUserTherapist": "SpaUsers/usertherapist/{id}",
    "UpdateUserTherapist":"SpaUsers/UpdateUserTherapist",
    "GetTherapistEarnings":"Appointments/GetTherapistEarnings/{startDate}/{endDate}/{therapistId}",
    "GetAppointmentWithClientById":"Appointments/GetAppointmentWithClientById/{appointmentId}",
    "GetAllUserTherapist":"SpaUsers/GetAllUserTherapist",
    "DeleteUserTherapist":"SpaUsers/DeleteUserTherapist/{therapistId}",
    "GetARAccountByFilter": "AccountingService/ARCustomer/GetAccountsByFilter?searchText={searchText}&searchBy={searchBy}",
    "SendGuestExportNotification" : "Schedule/EmailNotification/SendGuestExportNotification",
    "CreateBookTempAppointment": "Appointments/{id}/createtemp/{isCreateAppointment}",
    "AppointmentCommentsUpdate": "Appointments/Otherdetails/{appointmentId}",
    "AddSoapNotes": "Clients/SoapNotes",
    "GetAppointmentsForPackageAppointmentMove": "AppointmentPackage/packgaeappointments/packagegroupid/{packageGroupId}",
    "CreateDiscountTypeConfiguration" :"DiscountTypeConfiguration",
    "UpdateDiscountTypeConfiguration" : "DiscountTypeConfiguration/update",
    "DeleteDiscountTypeConfiguration" : "DiscountTypeConfiguration/{discountId}",
    "GetDiscountTypeConfigurationById" : "DiscountTypeConfiguration/{DiscountTypeId}",
    "GetDiscountConfigurationsByCategoryId" : "DiscountConfigurations/{categoryId}",
    "DeleteDiscountTypeConfigurationByIds":"DiscountTypeConfiguration/DeleteDiscountTypeConfigurationByIds",
    "CreateUpdateDeleteAppointmentRetailItem":"Appointments/appointment-retailitems",
    "GetAppointmentRetailItemByAppointmentId":"Appointments/appointment-retailitems/{appointmentid}",
    "GetAppointmentRetailItemByAppointmentIds": "Appointments/appointment-retailitems/by-appointmentids",
    "RefundDepositWithAppointment" : "Appointments/refund/deposit/transactionId",
    "FolioLookupForNightAudit": "dayend/FolioLookupForNightAudit",
    "UpdateTherapistBreak":"TherapistSchedule/therapist-break/{therapistId}/overwrite/{overwrite}/{scheduleDate}",
    "GetBreakpointforRoleAsync":"user/GetUserClaimsForRoleAsync/",
    "GetClientRelationships" : "Clients/relationships/{id}",
    "GetAppointmentsByConfirmationNumber" : "Appointments/GetAppointmentsByConfirmationNumber/confirmationNumber/{confirmationNumber}",
    "GetDuplicateUserByName": "user/GetDuplicateUserByName/{userId}?tenantId={tenantId}&userName={userName}",
    "GetMatchingGuests": "Clients/GetMatchingGuests",
    "IsOutletInUse":"Appointments/isOutletInUse/{outletId}",
    "UpdateReturnTransactionCustomFee":"appointments/update/returnedcustomFee",
    "GetAllCancellationNoShowPolicies": "schedule/CancellationNoShow/GetAllCancellationNoShowPolicy/{includeInactive}",
    "GetCancellationNoShowPolicyById": "schedule/CancellationNoShow/GetCancellationNoShowPolicyById/{id}",
    "CreateOrUpdateCancellationNoShowPolicy": "schedule/CancellationNoShow/CreateCancellationNoShowPolicy/isUpdate/{isUpdate}",
    "DeleteCancellationNoShowPolicy": "schedule/CancellationNoShow/DeleteCancellationNoShowPolicy/{id}",
    "GetAllCancellationNoShowMappings": "schedule/CancellationNoShow/GetAllCancellationNoShowMapping/{includeInactive}",
    "GetCancellationNoShowMappingByGroupId": "schedule/CancellationNoShow/GetCancellationNoShowMappingByGroupId/{groupId}",
    "CreateOrUpdateCancellationNoShowMapping": "schedule/CancellationNoShow/CreateCancellationNoShowMapping/isUpdate/{isUpdate}",
    "DeleteCancellationNoShowMapping": "schedule/CancellationNoShow/DeleteCancellationNoShowMapping/{groupId}",
    "GetAllServicesGroupsWithServices":"management/Services/GetAllServicesGroupsWithServices",
    "SearchCancellationNoShowPolicy": "schedule/CancellationNoShow/search/{searchText}",
    "UpdatePolicyActive": "schedule/CancellationNoShow/UpdatePolicyActive/{policyId}",
    "GetCustomFeeConfiguration": "CustomFeeConfiguration",
    "UpdateAppointmentWithcharge": "schedule/Appointments/UpdateAppointmentWithcharge",
    "GetUnPaidCancellationNoShowAppointments": "schedule/Appointments/GetUnPaidCancellationNoShowAppointments",
    "WaiveOffCancellationOrNoShowFee": "schedule/Appointments/WaiveOffCancellationOrNoShowFee/{appointmentId}",
    
    "CreateDayPassDetails": "schedule/appointments/daypass",
    "UpdateTransactionForDayPass": "schedule/appointments/update/transactionDayPass",
    "GetDayPassDetailsByDateRange": "schedule/appointments/daypass/fromDate/{fromDate}/toDate/{toDate}",
    "GetDayPassDetailsById": "schedule/appointments/daypass/{id}",
    "UpdateIsActiveForDayPass": "schedule/appointments/update/IsActive/{id}",
    "UpdateAppointmentDetailsForDayPass": "schedule/appointments/update/appointmentDetail/{id}",
    "CancelAllAppointmentsWithCharge": "schedule/Appointments/CancelAllAppointmentsWithCharge",
    "GetCancellationNoShowCharge":"schedule/Appointments/GetCancellationNoShowCharge",
    
    "GetTransactionInfoByIds": "transaction/GetTransactionsByIds",
    "GetAppointmentsByPackageGroupId": "schedule/appointments/packageappointments/{packageGroupId}",
    "GetDayPassDetailsByIds": "schedule/appointments/daypass/id",
    "UpdateDayPassIdInAppointment": "schedule/appointments/cancelDayPass",
    "GetRetailItemsByDayPassForCancel": "schedule/appointments/getCancel/reatilItem/daypass/appointment",

    //classes
    "GetAllClassesByDateRange":"schedule/class/fromDate/{fromDate}/toDate/{toDate}",
    "CreateTempHoldClass":"schedule/class/temphold",
    "UpdateTempHoldClass": "schedule/class/temphold",
    "classAvailability":"schedule/class/availability",
    "deleteClassTempHold": "schedule/class/temphold/{id}",
    "deleteClassTempHolds": "schedule/class/temphold",
    "createUpdateClassAppointment": "schedule/class/schedule",
    "deleteClassAppointment": "schedule/class/{id}",
    "GetInstructorBreak": "schedule/class/instructor/{instructorId}/break/{breakTime}",
    "DeleteInstructorBreak": "schedule/class/{id}/instructor/{instructorId}/break/{breakTime}",
    "HaltContinueClassBooking": "schedule/class/{id}/halt/{isHalted}",
    "CreateInstructorBreak": "schedule/class/break/instructor/{instructorId}",
    "ModifyInstructorBreak": "schedule/class/{id}/instructor/{instructorId}/break",
    "GetLocationConflictClasses": "schedule/class/conflict/location/{locationId}",
    "GetEquipmentConflictClasses": "schedule/class/conflict/equipment/{equipmentId}",
    "CreateTherapistBreakListForClass": "schedule/class/break/instructor/{instructorId}/overwrite/{isOverWrite}",
    "IsLocationInUseForClass": "schedule/class/validate/inuse/class/{classId}/location/{locationId}",
    "IsClassInUse": "schedule/class/validate/inuse/Class/{classId}/from/{startDate}/to/{endDate}/dayofWeek/{dayOfWeek}",
    "IsInstructorClassInUse": "schedule/class/validate/inuse/instructorId/{instructorId}/class/{classId}",    
    "classInstructorclassLimitation": "schedule/class/validateInstructorClasslimit/instructorId/{instructorId}/classId/{classId}/classcount/{classcount}",
    "IsInstructorClassesInUse": "schedule/class/validate/inuse/instructor/{instructorId}",
    "GetInstructorClasses": "schedule/class/instructor/{instructorId}/from/{FromDate}/to/{ToDate}",
    "GetClassByInstructorId": "schedule/class/InstructorClasses/{instructorId}/from/{FromDate}/to/{ToDate}",
    "SearchClasses": "schedule/class/search",
    "AddClientsToClass": "schedule/class/{classAppointmentId}/client",
    "GetClientDetailsForClass":"schedule/class/{id}/client",
    "checkInClassClient":"schedule/class/{id}/client/checkIn",
    "undoCheckInClassClient":"schedule/class/{id}/client/undoCheckIn",
    "saveClientNotes":"schedule/class/{id}/client/{classClientId}/comment",
    "cancelClientRequest":"schedule/class/{id}/clients/cancel",
    "deleteClassClient":"schedule/class/{id}/clients/delete",
    "ClassSearchByConfirmationId": "schedule/class/search/{searchValue}",
    "CancelClass":"schedule/class/cancel",
    "getClassClientDeposit":"schedule/class/deposits/classAppointmentId/{id}/outlet/{outletId}",
    "UpdateClassClientDeposit": "schedule/class/clientdeposit/transaction/{transactionId}",  
    "RevertClassDeposit": "schedule/class/Revertdeposit/TransactionId/{transactionId}",
    "RefundDepositWithClasses" : "schedule/class/refund/deposit",  
    "CancelClientDeposit": "schedule/class/cancel/deposit/refundTransaction/{transactionId}",
    "UndoCheckOutClassClientByTransactionId":"schedule/class/client/checkout/undo/transaction/{transactionId}",
    "UndoCheckOutClassClient":"schedule/class/client/checkout/undo/classAppointmentId/{id}",
    "getClassClientDetails":"schedule/class/details",
    "checkOutClassClients":"schedule/class/client/checkout",
    "ClassDayEnd":"schedule/class/client/dayend/{propertyDate}",
    "CreateTempForCopyMoveClass":"schedule/class/copyMoveTemp/{isMove}",
    "SendClassManualNotification": "schedule/emailNotification/SendClassManualNotification",
    "CopyClassSchedule":"schedule/class/copy/schedule",
    "GetClassClientValidationInfo":"schedule/class/clientvalidationinfo/{classId}",

    // DynamicPricing
    "GetDynamicPricingByGroupId" : 'schedule/DynamicPricing/{groupId}/override/{overrideGroupId}',
    "GetDynamicPricingByServiceAndDate": 'schedule/DynamicPricing/groupId?startDate={startDate}&endDate={endDate}&groupId={groupId}',
    "GetDynamicPricingServices": "schedule/DynamicPricing/Date?startDate={startDate}&endDate={endDate}",
    "CreateDynamicPricing" : 'schedule/DynamicPricing',
    "UpdateDynamicPricing" : 'schedule/DynamicPricing',
    "DeleteDynamicPricingByGroupId" : "schedule/DynamicPricing/groupId/{groupId}",
    "DeleteDynamicPricingById" : "schedule/DynamicPricing/{id}?deleteUsedPricing={deleteUsedPricing}",
    "DeleteOverrideDynamicPricingByGroupId" : "schedule/DynamicPricing/Group/{overrideGroupId}/{dynamicPricingGroupId}?deleteUsedPricing={deleteUsedPricing}",
    "ViewPricing": "schedule/DynamicPricing/ViewPricing?groupId={groupId}",
    "PreviewPricing": "schedule/DynamicPricing/PreviewPricing/{date}",
    "GetDynamicPricingForListView":"schedule/DynamicPricing/listView?startDate={startDate}&endDate={endDate}",
    "GetOccupancy":"schedule/DynamicPricing/Occupancy?startDate={startDate}&endDate={endDate}",
    "SaveOccupancy":"schedule/DynamicPricing/SaveOccupancy",

    // day pass
    "CheckAppointmentStatusByDayPassId": "schedule/appointments/appointmentStatus/daypass/{id}",
    //Lockers
    "GetAppointmentInfoByAppointmentIds": "schedule/Appointments/AppointmentInfoByAppointmentIds",
    "CreateAppointmentTransLog": "schedule/TransLog/CreateTransLog/typeId/{typeId}/logtype/{logType}",

    "UpdateCancellationNoShowCharge": "schedule/Appointments/UpdateCancellationNoShowCharge"
  }
  