import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { NotificationActions, Type, Keywords, TemplateData, TemplateForm, TemplateDetailUI } from './sms-templates.model';
import * as DecoupledEditor from '../../ckeditor5-build-decoupled-document-master/build/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { TemplateServiceCommunication } from '../templates.service';
import { SmsTemplatesBusiness } from './sms-templates.business';
import { EmailTemplatesBusiness } from '../email-templates/email-templates.business';
import { EmailTemplatesService } from '../email-templates/email-templates.service';
import { ButtonType } from '../../shared/shared/globalsContant';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CkeditorPopupComponent } from '../../shared/shared/ckeditor-popup/ckeditor-popup.component';

@Component({
  selector: 'app-sms-templates',
  templateUrl: './sms-templates.component.html',
  styleUrls: ['./sms-templates.component.scss'],
  providers: [SmsTemplatesBusiness, EmailTemplatesBusiness, EmailTemplatesService, TemplateServiceCommunication],
  encapsulation: ViewEncapsulation.None
})
export class SmsTemplatesComponent implements OnInit, OnDestroy {
  smsTemplatesFormGroup: any;
  captions: any;
  actions: NotificationActions[];
  keywords: Keywords[];
  smsTypes: { id: number; name: string; }[];
  productId: string;
  Editor = DecoupledEditor;
  Config = { toolbar: [
    'bold', 'italic', 'underline', 'strikethrough', 'fontfamily', 'fontsize',
    'fontcolor', 'fontbackgroundcolor', 'heading', 'alignment', 'numberedList', 
    'bulletedList', 'outdent', 'indent', 'link', 'imageUpload', 'undo', 'redo','htmlEmbed'
  ],
  fontColor: {
    colors: this.utils.getColors()
  },
  fontBackgroundColor: {
    colors: this.utils.getColors()
  },
  fontSize: {
    options: this.utils.getFontSize(),
    supportAllValues: true
  },
  table: {
    tableProperties: {
      borderColors: this.utils.getColors(),
      backgroundColors: this.utils.getColors()
    },
    tableCellProperties: {
      borderColors:this.utils.getColors(),
      backgroundColors: this.utils.getColors()
    }
  }
 };
  @ViewChild('editor0') editor0: CKEditorComponent;
    currentEditor: any;
    templateForm: TemplateForm;
    templateData: TemplateData[] = [];
    currentTopic: number = 0;
    defaultTemplateIdSelectedValue: number = 0;
    defaultTemplateIdValue: string  = '';
    isdefaultUpdateRequired: boolean = false;
    templateCaptions: any;
    $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  floatLabel: string;
  constructor(private fb: UntypedFormBuilder, private localization: Localization, private smsTemplatesBusiness: SmsTemplatesBusiness, private utils: CommonUtilities, private templateServiceCommunication: TemplateServiceCommunication,public dialog: MatDialog) {
    this.captions = this.localization.captions.settings.utilities;
    this.templateCaptions = this.localization.captions.settings.utilities.template;
    this.floatLabel = this.localization.setFloatLabel;
  }

  async ngOnInit() { this.templateServiceCommunication.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
    const loadingContainer = document.getElementById('custom-cover-spin');
    const loadingContainerMessage = document.getElementById('custom-cover-message');
    if (loadingContainer && loadingContainerMessage) {
      if (loader) {
        loadingContainer.style.display = 'block';        
      }
      else {
        loadingContainer.style.display = 'none';
      
      }
    }
  });
  this.templateServiceCommunication.loaderEnable.next(true);
    this.DefaultFormGenerator();
    this.productId = this.localization.GetPropertyInfo('ProductId');
    this.GetMasterData();
  }

  DefaultFormGenerator() {
    this.smsTemplatesFormGroup = this.fb.group({
      action: 0,
      smsType1: '',
      templateId1 : '',
      templateName1: '',
      name : ''
    });
  }

  async GetMasterData() {
    this.smsTypes = this.smsTemplatesBusiness.getSMSTypes();
    this.actions = await this.smsTemplatesBusiness.getActions(Type.SMS);

    if(this.actions && this.actions.length > 0){
      this.smsTemplatesFormGroup.controls.action.setValue(this.actions[0].id);
     await this.GetKeywordsAndTemplateData(this.actions[0].id);
    }
    this.templateServiceCommunication.loaderEnable.next(false);
  }

  async onSave($event) {
    if (!this.ValidateDefaultTemplate(this.smsTemplatesFormGroup.value)) {
      return;
    }

    this.templateServiceCommunication.loaderEnable.next(true);
    this.UpdateTemplateDataObject(this.smsTemplatesFormGroup.value);

    this.smsTemplatesFormGroup.markAsPristine();

    if (this.templateData && this.templateData.length > 0) {
      await this.smsTemplatesBusiness.CreateorUpdateTemplate(this.currentTopic, this.templateData);
    }

    if (this.isdefaultUpdateRequired) {
        if (!this.defaultTemplateIdValue) {
          await this.GetKeywordsAndTemplateData(this.smsTemplatesFormGroup.value.action); //To get the default template id from api
          this.getDefaultTemplateIdValue(this.templateForm);
        }
      await this.smsTemplatesBusiness.UpdateDefaultTemplate(this.currentTopic, this.defaultTemplateIdValue, Type.SMS);
    }

    await this.GetKeywordsAndTemplateData(this.smsTemplatesFormGroup.value.action);

    this.clearValues();
    this.templateServiceCommunication.loaderEnable.next(false);
  }

  clearValues() {
    this.currentTopic = 0;
    this.templateData = [];
    this.defaultTemplateIdSelectedValue = 0;
    this.isdefaultUpdateRequired = false;
    this.defaultTemplateIdValue = '';
  }

  onCancel($event) {
    this.PatchValue();
    this.smsTemplatesFormGroup.markAsPristine();
  }

  async GetKeywordsAndTemplateData(actionId: number){
    const templateDetailUI: TemplateDetailUI = await this.smsTemplatesBusiness.getTemplateDetails(actionId);
    this.keywords = templateDetailUI.keywords;
    this.templateForm = templateDetailUI.templateForm;
    this.PatchValue();
  }

  async actionChanged(event){
    this.templateServiceCommunication.loaderEnable.next(true);
    await this.GetKeywordsAndTemplateData(event.value);
    this.templateServiceCommunication.loaderEnable.next(false);
  }

  PatchValue() {
    if (this.templateForm){
      this.smsTemplatesFormGroup.patchValue(this.templateForm);
    }
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  elementClick(arg) {
    const appendData ="{{"+arg+"}}";
    const selection = this.currentEditor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    this.currentEditor.editorInstance.model.change(writer => {
      writer.insert(appendData, range.start);
    });
  }

  onChange(currentEditorInstance) {
    this.currentEditor = currentEditorInstance;
  }

  UpdateTemplateDataObject(formData: any) {
    this.currentTopic = formData.action;
    this.AddTemplateDataIfDirty(formData, 'smsType1', 'templateId1', 'templateName1', '_SMS1', 1);
  }

  AddTemplateDataIfDirty(formData: any, htmlContentcontrol: string, templateIdControl: string, templateNameControl: string, suffixTemplateName: string, templateNumber: number) {

    if (this.smsTemplatesFormGroup.controls[htmlContentcontrol].dirty) {
      const actionName = formData.name;
      const templateNameValue = formData[templateNameControl];
      const templateContentValue = formData[htmlContentcontrol];
      const templateIdValue = formData[templateIdControl];

      const templateData: TemplateData = {
        templateName: templateNameValue ? templateNameValue : actionName.slice(0, -3) + suffixTemplateName ,
        topicName: actionName,
        templateType: Type.SMS,
        isDefault:  true ,
        templateAsset: [],
        htmlContent: templateContentValue.replace(/<[^>]+>/g, ''),
        templateId: templateIdValue
      };

      this.templateData.push(templateData);
    }

  }

  ValidateDefaultTemplate(formData: any): boolean {
    let isValid:boolean = false;
   this.defaultTemplateIdSelectedValue = 1;
    const templateHtmlContentControl = 'smsType' +  this.defaultTemplateIdSelectedValue;
    const defaultTemplateHtmlContentValue = formData[templateHtmlContentControl];

    if (this.defaultTemplateIdSelectedValue  && defaultTemplateHtmlContentValue) {
      this.getDefaultTemplateIdValue(formData);
      isValid = true;
      this.isdefaultUpdateRequired = !this.defaultTemplateIdValue ? true : false;
    }
    if (!isValid) {
      this.utils.ShowError(this.captions.DefaultTemplateErrorTitle, this.captions.DefaultTemplateErrorMessage, ButtonType.Ok, null, null, false);
    }

    return isValid;
  }

  getDefaultTemplateIdValue(formData: any) {
    const defaultTemplateIdControl = 'templateId' +  this.defaultTemplateIdSelectedValue;
    this.defaultTemplateIdValue = formData[defaultTemplateIdControl];
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
}
